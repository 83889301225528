/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Flex layout
.k-scheduler-layout-flex {
    overflow: auto;

    .k-scheduler-head {
        position: sticky;
        top: 0;
        z-index: 3;
    }
    .k-scheduler-body {
        position: relative;
    }

    .k-scheduler-head,
    .k-scheduler-body {
        display: flex;
        flex: 100%;
        flex-wrap: wrap;
    }
}


// Year view
.k-scheduler-yearview {
    .k-scheduler-body {
        padding: @scheduler-yearview-padding-y @scheduler-yearview-padding-x;
        justify-content: center;
    }

    .k-calendar {
        width: 100%;
        border-width: 0;

        .k-calendar-view {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .k-content {
            flex: 0;
        }

        .k-month-header {
            font-size: @scheduler-yearview-month-header-font-size;
            font-weight: @scheduler-yearview-month-header-font-weight;
            opacity: @scheduler-yearview-month-header-opacity;
        }

        .k-link {
            position: relative;
        }

        td.k-state-selected {
            background-color: inherit;
        }
    }

    .k-day-indicator {
        width: @scheduler-yearview-indicator-size;
        height: @scheduler-yearview-indicator-size;
        border-radius: @scheduler-yearview-indicator-border-radius;
        position: absolute;
        top: @scheduler-yearview-indicator-calc-offset-top;
        left: @scheduler-yearview-indicator-calc-offset-left;
    }
}


// Tooltip
.k-scheduler-tooltip {
    padding: @scheduler-tooltip-padding-y @scheduler-tooltip-padding-x;
    color: inherit;

    .k-tooltip-title {
        margin-bottom: @scheduler-tooltip-title-margin-y;

        .k-month {
            font-size: @scheduler-tooltip-month-font-size;
            text-transform: uppercase;
        }

        .k-day {
            font-size: @scheduler-tooltip-day-font-size;
        }
    }

    .k-tooltip-events-container {
        overflow: auto;
    }

    .k-tooltip-events {
        max-height: @scheduler-tooltip-events-max-height;
        display: flex;
        flex-direction: column;
        gap: @scheduler-tooltip-events-gap;
    }

    .k-tooltip-event {
        padding: @scheduler-tooltip-event-padding-y @scheduler-tooltip-event-padding-x;
        border-radius: @scheduler-tooltip-event-border-radius;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-shrink: 0;
        align-items: center;
        position: relative;
        gap: @scheduler-tooltip-event-gap;
    }
    .k-tooltip-event .k-event-time {
        display: flex;
        flex-shrink: 0;
        font-size: inherit;
    }
}


// Compatibility
.k-ie {
    .k-scheduler-tooltip .k-tooltip-event:not(:last-child) {
        margin-bottom: @scheduler-tooltip-event-gap;
    }
}
