/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Widget
@widget-background-color: @base;
@widget-text-color: @normal-text-color;
@widget-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@widget-gradient: null;
@widget-shadow: null;
// Button
@button-background-color: @base;
@button-text-color: contrast(@base);
@button-border-color: contrast(@base, lighten(@base, 28%), darken(@base, 14%), 0.5);
@button-gradient: null;
@button-shadow: null;
@button-hover-text-color: #ffffff;
@button-hover-background-color: @hover-background;
@button-hover-border-color: @hover-background;
@button-hover-gradient: null;
@button-hover-shadow: null;
@button-active-background: darken(@accent, 3%);
@button-active-text-color: @selected-text-color;
@button-active-border-color: @button-active-background;
@button-active-gradient: null;
@button-active-shadow: null;
@button-focused-border-color: contrast(@base, lighten(@accent, 4%), lighten(@accent, 7%), 0.5);
@button-focused-shadow: none;
@button-focused-active-shadow: inset 0 0 3px 1px darken(@selected-border-color, 15%);
// Primary button
@primary-text-color: #ffffff;
@primary-background-color: @accent;
@primary-border-color: @accent;
@primary-gradient: null;
@primary-shadow: null;
@primary-hover-text-color: #ffffff;
@primary-hover-background-color: @hover-background;
@primary-hover-border-color: @hover-background;
@primary-hover-gradient: null;
@primary-hover-shadow: null;
@primary-active-text-color: #ffffff;
@primary-active-background-color: darken(@accent, 3%);
@primary-active-border-color: @primary-active-background-color;
@primary-active-gradient: null;
@primary-active-shadow: null;
@primary-focused-border-color: contrast(@base, lighten(@accent, 14%), darken(@accent, 7%), 0.5);
@primary-focused-shadow: none;
@primary-focused-active-shadow: none;
@primary-disabled-text-color: contrast(@base, lighten(@base, 1%), darken(@base, 17%), 0.5);
@primary-disabled-background-color: lighten(@accent, 2%);
@primary-disabled-border-color: lighten(@accent, 2%);
@primary-disabled-gradient: null;
// Input
@input-text: @normal-text-color;
@input-bg: @base;
@input-border: @drop-down-border-color;
@input-shadow: null;
@input-hovered-text: @input-text;
@input-hovered-bg: @input-bg;
@input-hovered-border: @drop-down-hover-border-color;
@input-hovered-shadow: null;
@input-focused-text: @input-text;
@input-focused-bg: @input-bg;
@input-focused-border: @drop-down-focused-border-color;
@input-focused-shadow: @focused-shadow;
@sprite-image: url('@{image-folder}/sprite.png');
@inverse-text-color: contrast(@normal-text-color);
@hover-border-color: @hover-background;
@selected-background-color: @accent;
@active-background-color: lighten(@base, 6%);
@active-border-color: @accent;
@active-text-color: contrast(@base);
@error-background-color: @error;
@error-border-color: #e20000;
@error-text-color: contrast(@base, lighten(@normal-text-color, 1%), darken(@normal-text-color, 20%), 0.5);
@disabled-text-color: contrast(@base, lighten(@base, 42%), darken(@base, 20%), 0.5);
@validation-background-color: @warning;
@validation-border-color: @warning;
@validation-text-color: contrast(@validation-background-color, lighten(@warning, 80%), darken(@warning, 60%), 0.5);
@notification-border-radius: @border-radius;
@notification-info-bg: @info;
@notification-info-text: contrast(@info);
@notification-info-border: @info;
@notification-success-bg: @success;
@notification-success-text: contrast(@success);
@notification-success-border: @success;
@notification-warning-bg: @warning;
@notification-warning-text: contrast(@warning);
@notification-warning-border: @warning;
@notification-error-bg: @error;
@notification-error-text: contrast(@error);
@notification-error-border: @error;
@notification-gradient: null;


// Tooltip
@tooltip-border-radius: @border-radius;
@tooltip-shadow: 0 2px 2px 0 @shadow-color;

@tooltip-bg: lighten(@hover-background, 1%);
@tooltip-text: #ffffff;
@tooltip-border: lighten(@hover-background, 1%);


@draghandle-border-color: saturate(@hover-background, 1%);
@draghandle-shadow: 0 1px 1px 0 fadeout(darken(@normal-text-color, 10%), 70%);
@draghandle-hover-border-color: @accent;
@draghandle-hover-shadow: @draghandle-shadow;
@alt-background-color: contrast(@base, lighten(@base, 6%), darken(@base, 4%), 0.5);
@nested-alt-background-color: contrst(@normal-text-color, darken(@alt-background-color, 8%), lighten(@alt-background-color, 8%), 0.5);
@column-highlight-background-color: contrast(@base, lighten(@base, 8%), darken(@base, 2%), 0.5);
@active-filter-background-color: contrast(@accent, @hover-background-color, darken(@base, 6%), 0.5);
@event-text-color: contrast( @event-background-color );
@event-inverse-text-color: contrast( @event-text-color );
@event-background-color: @accent;
@scheduler-nonwork-background-color: contrast(@base, lighten(@base, 17%), darken(@base, 4%), 0.5);
@resize-background-color: @normal-text-color;
@shadow-color: fadeout(contrast(@base), 80%);
@upload-progress-background-color: @info;
@upload-progress-text-color: contrast(@info, @info, darken(@info, 60%), 0.5);
@upload-success-background-color: contrast(@success, @success, darken(@success, 55%), 0.5);
@upload-success-text-color: contrast(@success, @success, darken(@success, 55%), 0.5);
@upload-error-background-color: contrast(@error, @error, darken(@error, 53%), 0.5);
@upload-error-text-color: contrast(@error, @error, darken(@error, 53%), 0.5);
@upload-invalid-color: @error;
@task-summary-color: contrast(@base, lighten(@widget-text-color, 25%), darken(@widget-text-color, 25%), 0.5);
@task-summary-selected-color: contrast(@base, lighten(@selected-background-color, 15%), darken(@selected-background-color, 25%), 0.5);
@loading-panel-color: @base;
@splitbar-background-color: contrast(@base, lighten(@base, 1%), darken(@base, 14%), 0.5);
@disabled-background-color: @base;
@disabled-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 10%), 0.5);
@disabled-gradient: none;
@tabstrip-active-background: lighten(@base, 6%);
@hover-shadow: none;
@selected-shadow: none;
@active-gradient: none;
@active-shadow: none;
@selected-border-color: @accent;
@focused-border-color: @hover-background;
@focused-shadow: none;
@focused-item-shadow: inset 0 0 0 1px @hover-border-color;
@focused-active-item-shadow: inset 0 0 3px 1px darken(@selected-border-color, 15%);
@list-border-radius: 0;
@inner-border-radius: 0;
@slider-border-radius: 13px;
@draghandle-border-radius: 0;
@default-icon-opacity: 1;
@header-background-color: @base;
@header-text-color: @normal-text-color;
@group-background-color: @base;
@group-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@content-background-color: @base;
@select-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@select-background-color: @base;
@select-group-background-color: @base;
@select-hover-background-color: @hover-background;
@hover-background-color: @hover-background;
@shadow-inset-color: transparent;
@link-text-color: @normal-text-color;
@cell-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@scheduler-background-color: @base;
@current-time-color: #e20000;
@draghandle-background-color: saturate(@hover-background, 1%);
@draghandle-hover-background-color: @accent;
@menu-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@tabstrip-items-border: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@tabstrip-tabs-color: @normal-text-color;
@tabstrip-content-border-focused: #656565;
@form-widget-color: @normal-text-color;
@drop-down-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@drop-down-hover-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@drop-down-focused-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@drop-down-background: @base;
@drop-down-text-color: @normal-text-color;
@secondary-border-color: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@secondary-text-color: @normal-text-color;
@numeric-selected-background: @accent;
@panelbar-items-border: contrast(@base, lighten(@base, 14%), darken(@base, 14%), 0.5);
@panelbar-content-background: @base;
@panelbar-content-color: @normal-text-color;
@filter-menu-content-background: @base;
@icon-background-color: transparent;
@input-validation-error: contrast(@base, lighten(@error, 15%), darken(@error, 20%), 0.5);


// Link
@link-text: @accent;
@link-hovered-text: contrast(@base, darken(@link-text, 4), lighten(@link-text, 4), .5);


// component
@component-bg: @widget-background-color;
@component-text: @widget-text-color;
@component-border: @widget-border-color;


// Toolbar
@toolbar-bg: @header-background-color;
@toolbar-text: @widget-text-color;
@toolbar-border: @widget-border-color;
@toolbar-gradient: @widget-gradient;
@toolbar-shadow: null;


// Avatar
@avatar-bg: @accent;
@avatar-text: contrast( @avatar-bg );


// Cards
@card-bg: @component-bg;
@card-text: @component-text;
@card-border: @component-border;

@card-focus-bg: null;
@card-focus-text: null;
@card-focus-border: null;
@card-focus-shadow: 0 3px 4px 0 contrast(@background, rgba(black, .06), rgba(white, .6), 0.5);

@card-header-bg: @header-background-color;
@card-header-text: @header-text-color;
@card-header-border: @card-border;

@card-footer-bg: @header-background-color;
@card-footer-text: @header-text-color;
@card-footer-border: @card-border;

@card-primary: @accent;
@card-primary-bg: mix( white, @card-primary, 70%);
@card-primary-text: mix(black, @card-primary);
@card-primary-border: mix(white, @card-primary);

@card-error: darken(@error, 30%);
@card-error-bg: mix( white, @card-error, 70%);
@card-error-text: mix(black, @card-error);
@card-error-border: mix(white, @card-error);

@card-warning: darken(@warning, 30%);
@card-warning-bg: mix( white, @card-warning, 70%);
@card-warning-text: mix(black, @card-warning);
@card-warning-border: mix(white, @card-warning);

@card-success: darken(@success, 30%);
@card-success-bg: mix( white, @card-success, 70%);
@card-success-text: mix(black, @card-success);
@card-success-border: mix(white, @card-success);

@card-info: darken(@info, 30%);
@card-info-bg: mix( white, @card-info, 70%);
@card-info-text: mix(black, @card-info);
@card-info-border: mix(white, @card-info);

@card-focus-callout-box-shadow-n: 1px -1px 4px 0 contrast(@background, rgba(black, .06), rgba(white, .6), 0.5);
@card-focus-callout-box-shadow-e: 2px -1px 4px 0 contrast(@background, rgba(black, .06), rgba(white, .6), 0.5);
@card-focus-callout-box-shadow-s: 2px -2px 4px 0 contrast(@background, rgba(black, .06), rgba(white, .6), 0.5);
@card-focus-callout-box-shadow-w: 2px -1px 4px 0 contrast(@background, rgba(black, .06), rgba(white, .6), 0.5);


//TileLayout
@tilelayout-bg: contrast(@base, lighten(@background, 4%), darken(@background, 4%), 0.5);

@tilelayout-hint-bg: rgba(255, 255, 255, .2);
@tilelayout-hint-border: @component-border;
@tilelayout-card-focus-shadow: 0 0 4px 2px rgba(contrast(@tilelayout-bg), 0.7);


// Chat
@chat-bg: @component-bg;
@chat-text: @component-text;
@chat-border: @component-border;

@chat-bubble-bg: @button-background-color;
@chat-bubble-text: @button-text-color;
@chat-bubble-border: @chat-bubble-bg;

@chat-alt-bubble-bg: @accent;
@chat-alt-bubble-text: contrast(@accent);
@chat-alt-bubble-border: @chat-alt-bubble-bg;

@chat-quick-reply-color: @accent;


// Grid
@grid-bg: @component-bg;
@grid-text: @component-text;
@grid-border: @component-border;

@grid-header-bg: @header-background-color;
@grid-header-text: @header-text-color;
@grid-header-border: @grid-border;
@grid-header-gradient: null;

@grid-alt-bg: @alt-background-color;
@grid-alt-text: @grid-text;
@grid-alt-border: @grid-border;

@grid-hover-bg: @hover-background-color;
@grid-hover-text: @hover-text-color;
@grid-hover-border: @grid-border;
@grid-hover-gradient: null;

@grid-selected-bg: @selected-background-color;
@grid-selected-text: @selected-text-color;
@grid-selected-border: @grid-border;
@grid-selected-gradient: null;

@grid-sticky-border: contrast(@base, lighten(@grid-border, 30%), darken(@grid-border, 30%), 0.5);

@grid-selected-hover-bg: null;

// Calendar
@calendar-bg: @component-bg;
@calendar-text: @component-text;
@calendar-border: @component-border;

@calendar-header-bg: @header-background-color;
@calendar-header-text: @header-text-color;
@calendar-header-border: @calendar-border;
@calendar-header-gradient: none;

@calendar-header-cell-bg: @calendar-bg;
@calendar-header-cell-text: @calendar-text;
@calendar-header-cell-border: @calendar-border;

@calendar-week-cell-bg: contrast(@calendar-bg, lighten(@calendar-bg, 4), darken(@calendar-bg, 4), .5);
@calendar-week-cell-text: @calendar-header-cell-text;
@calendar-week-cell-border: @calendar-header-cell-border;

@calendar-weekend-cell-bg: transparent;
@calendar-weekend-cell-text: null;
@calendar-weekend-cell-border: null;

@calendar-othermonth-cell-bg: null;
@calendar-othermonth-cell-text: @disabled-text-color;
@calendar-othermonth-cell-border: null;

@calendar-cell-hovered-bg: @button-hover-background-color;
@calendar-cell-hovered-text: @button-hover-text-color;
@calendar-cell-hovered-border: @button-hover-border-color;

@calendar-cell-selected-bg: @selected-background-color;
@calendar-cell-selected-text: @selected-text-color;
@calendar-cell-selected-border: @selected-border-color;

@calendar-cell-focused-shadow: inset 0 0 0 1px contrast(@calendar-bg, darken(@hover-background, 10%), lighten(@hover-background, 86%), 0.5);


// Timepicker
@time-list-highlight-color: @widget-border-color;
@time-list-focused-bg: rgba(contrast(@background), .04);
@time-list-title-focus: contrast(@background);


// Window
@window-border-radius: @border-radius;
@window-action-border-radius: @border-radius;

@window-bg: @component-bg;
@window-text: @component-text;
@window-border: @component-border;
@window-shadow: 1px 1px 7px 1px rgba(contrast(@window-bg), .12);
@window-focus-shadow: 1px 1px 7px 1px rgba(contrast(@window-bg), .25);

@window-titlebar-bg: @header-background-color;
@window-titlebar-text: @header-text-color;
@window-titlebar-border: @secondary-border-color;
@window-titlebar-gradient: false;


// Dialog


// ScrollView

@scrollview-bg: @component-bg;
@scrollview-text: @component-text;
@scrollview-border: @component-border;

@scrollview-pagebutton-bg: @button-background-color;
@scrollview-pagebutton-border: @button-border-color;
@scrollview-pagebutton-primary-bg: @primary-background-color;
@scrollview-pagebutton-primary-border: @primary-border-color;
@scrollview-pagebutton-shadow: 0 0 0 2px rgba(0, 0, 0, .13);

@scrollview-navigation-color: white;
@scrollview-navigation-icon-shadow: rgba(0, 0, 0, .3) 0 0 15px;
@scrollview-navigation-bg: rgba(0, 0, 0, 0);
@scrollview-navigation-default-opacity: .7;
@scrollview-navigation-hover-opacity: 1;
@scrollview-navigation-hover-span-bg: transparent;
@scrollview-arrow-tap-highlight-color: transparent;

@scrollview-light-bg: rgba(255, 255, 255, .2);
@scrollview-dark-bg: rgba(0, 0, 0, .2);


// Switch
@switch-container-shadow: null;
@switch-container-hovered-shadow: null;
@switch-container-focused-shadow: null;

@switch-on-bg: @normal-background;
@switch-on-text: @primary-background-color;
@switch-on-border: @button-border-color;
@switch-on-gradient: null;
@switch-on-shadow: null;
@switch-on-focused-shadow: null;

@switch-on-focused-bg: @switch-on-bg;
@switch-on-focused-text: @switch-on-text;
@switch-on-focused-border: @primary-border-color;
@switch-on-focused-gradient: @switch-on-gradient;

@switch-on-hovered-bg: @switch-on-bg;
@switch-on-hovered-text: @switch-on-text;
@switch-on-hovered-border: @button-hover-border-color;
@switch-on-hovered-gradient: null;

@switch-on-handle-bg: @primary-background-color;
@switch-on-handle-text: @switch-on-text;
@switch-on-handle-border: @primary-border-color;
@switch-on-handle-gradient: @primary-gradient;
@switch-on-handle-shadow: null;

@switch-on-handle-focused-bg: @switch-on-handle-bg;
@switch-on-handle-focused-text: @switch-on-handle-text;
@switch-on-handle-focused-border: @button-focused-border-color;
@switch-on-handle-focused-gradient: @switch-on-handle-gradient;

@switch-on-handle-hovered-bg: @primary-hover-background-color;
@switch-on-handle-hovered-text: @switch-on-text;
@switch-on-handle-hovered-border: @button-hover-border-color;
@switch-on-handle-hovered-gradient: @primary-hover-gradient;

@switch-off-bg: @switch-on-bg;
@switch-off-text: @normal-text-color;
@switch-off-border: @switch-on-border;
@switch-off-gradient: null;
@switch-off-shadow: null;
@switch-off-focused-shadow: @switch-on-focused-shadow;

@switch-off-focused-bg: @switch-off-bg;
@switch-off-focused-text: @switch-off-text;
@switch-off-focused-border: @switch-on-focused-border;
@switch-off-focused-gradient: @switch-off-gradient;

@switch-off-hovered-bg: @switch-off-bg;
@switch-off-hovered-text: @switch-off-text;
@switch-off-hovered-border: @switch-on-hovered-border;
@switch-off-hovered-gradient: null;

@switch-off-handle-bg: @button-background-color;
@switch-off-handle-text: @switch-off-text;
@switch-off-handle-border: @button-border-color;
@switch-off-handle-gradient: @button-gradient;
@switch-off-handle-shadow: null;

@switch-off-handle-focused-bg: @switch-off-handle-bg;
@switch-off-handle-focused-text: @switch-off-handle-text;
@switch-off-handle-focused-border: @button-focused-border-color;
@switch-off-handle-focused-gradient: @switch-off-handle-gradient;

@switch-off-handle-hovered-bg: @button-hover-background-color;
@switch-off-handle-hovered-text: @switch-off-text;
@switch-off-handle-hovered-border: @button-hover-border-color;
@switch-off-handle-hovered-gradient: @button-hover-gradient;


// PDF viewer
@pdf-viewer-bg: null;
@pdf-viewer-text: null;
@pdf-viewer-border: null;

@pdf-viewer-canvas-bg: @chat-bg;
@pdf-viewer-canvas-text: null;
@pdf-viewer-canvas-border: null;

@pdf-viewer-page-bg: white;
@pdf-viewer-page-text: @component-text;
@pdf-viewer-page-border: @component-border;
@pdf-viewer-page-shadow: 0 0 15px @pdf-viewer-page-border;

@pdf-viewer-toolbar-button-bg: @button-text-color;

@pdf-viewer-search-highlight-bg: darken(@background, 70%);


// Adaptive Common
@adaptive-content-bg: @header-background-color;
@adaptive-content-text: @accent;

@adaptive-menu-bg: @accent;
@adaptive-menu-text: @selected-text-color;

@adaptive-menu-clear-text: @accent;

@adaptive-menu-item-border: @cell-border-color;

@adaptive-menu-title-text: @normal-text-color;


// Adaptive Grid
@adaptive-grid-sort-text: @accent;

// Adaptive Scheduler
@adaptive-scheduler-current-text: @accent;
@adaptive-scheduler-base-text: inherit;


// Drawer
@drawer-text:  @widget-text-color;
@drawer-bg: @widget-background-color;
@drawer-border: @widget-border-color;

@drawer-scrollbar-color: rgba(156, 156, 156, 0.7);
@drawer-scrollbar-bg: #dedede;
@drawer-scrollbar-hovered-color: rgba(156, 156, 156, 1);

@drawer-hovered-text: @hover-text-color;
@drawer-hovered-bg: @hover-background;
@drawer-hovered-gradient: @hover-gradient;

@drawer-focused-bg: @drawer-bg;
@drawer-focused-shadow: @focused-item-shadow;

@drawer-selected-text: @selected-text-color;
@drawer-selected-bg: @selected-background;
@drawer-selected-hover-bg: contrast(@background, lighten(@selected-background, 5%), darken(@selected-background, 5%), 0.5);
@drawer-selected-hover-text: @selected-text-color;


// Rating
@rating-icon-text: contrast(@accent, @widget-border-color, @widget-text-color, 0.5);
@rating-icon-selected-text: @accent;
@rating-icon-hover-text: @accent;
@rating-icon-focused-text: @accent;

@rating-icon-focused-shadow: 0 2px 3.6px rgba(0, 0, 0, .1);
@rating-icon-focused-selected-shadow: 0 2px 3.6px fade(@accent, 50);


// Spreadsheet
@spreadsheet-insert-image-dialog-preview-overlay: inset 0 0 0 2000px rgba(0,0,0, 0.5);
@spreadsheet-insert-image-dialog-hovered-text: #fff;

@spreadsheet-drawing-handle-bg: @spreadsheet-selection-border;
@spreadsheet-drawing-handle-border-color: #fff;
@spreadsheet-drawing-handle-border-radius: 50%;
@spreadsheet-drawing-handle-outline: @spreadsheet-selection-border;

@spreadsheet-drawing-anchor-bg:  @spreadsheet-selection-background;


// Filter
@filter-preview-field-text: @accent;


// Timeline
@timeline-track-arrow-disabled-text: @disabled-text-color;
@timeline-track-arrow-disabled-bg: @disabled-background-color;
@timeline-track-arrow-disabled-border: @disabled-border-color;

@timeline-track-bg: @button-background-color;
@timeline-track-border-color: @button-border-color;

@timeline-track-item-focus-shadow: 0 3px 4px 0 contrast(@background, rgba(black, .06), rgba(white, .6), 0.5);

@timeline-flag-background: @accent;
@timeline-flag-text: contrast(@accent);

@timeline-circle-color: @accent;


// Breadcrumb
@breadcrumb-focused-shadow: inset 0 0 0 1px @focused-border-color;

@breadcrumb-link-text: @link-text;
@breadcrumb-link-bg: transparent;
@breadcrumb-link-border: transparent;

@breadcrumb-link-hovered-text: @link-hovered-text;
@breadcrumb-link-hovered-bg: rgba(0,0,0, 0.08);
@breadcrumb-link-hovered-border: transparent;

@breadcrumb-link-focused-text: @link-text;
@breadcrumb-link-focused-bg: false;
@breadcrumb-link-focused-border: false;
@breadcrumb-link-focused-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.12);

@breadcrumb-root-link-text: @button-text-color;
@breadcrumb-root-link-bg: false;
@breadcrumb-root-link-border: false;

@breadcrumb-root-link-hovered-text: false;
@breadcrumb-root-link-hovered-bg: @breadcrumb-link-hovered-bg;
@breadcrumb-root-link-hovered-border: false;

@breadcrumb-root-link-focused-text: false;
@breadcrumb-root-link-focused-bg: false;
@breadcrumb-root-link-focused-border: false;
@breadcrumb-root-link-focused-shadow: @breadcrumb-link-focused-shadow;

// Listgroup
@listgroup-border-radius: @border-radius;

@listgroup-bg: @component-bg;
@listgroup-text: @component-text;
@listgroup-border: @component-border;


// Badge
@badge-secondary-bg: darken(@normal-background, 5%);
@badge-secondary-text: @normal-text-color;
@badge-secondary-border: @badge-secondary-bg;

@badge-primary-bg: @primary-background-color;
@badge-primary-text: @primary-text-color;
@badge-primary-border: @badge-primary-bg;

@badge-info-bg: contrast(@base, darken(@info, 30%), @info, 0.5);
@badge-info-text: contrast( @badge-info-bg );
@badge-info-border: @badge-info-bg;

@badge-success-bg: contrast(@base, darken(@success, 30%), @success, 0.5);
@badge-success-text: contrast( @badge-success-bg );
@badge-success-border: @badge-success-bg;

@badge-warning-bg: contrast(@base, darken(@warning, 30%), @warning, 0.5);
@badge-warning-text: contrast( @badge-warning-bg );
@badge-warning-border: @badge-warning-bg;

@badge-error-bg: contrast(@base, darken(@error, 30%), @error, 0.5);
@badge-error-text: contrast( @badge-error-bg );
@badge-error-border: @badge-error-bg;

//Checkboxes
@checkbox-icon-size: 12px;
// @checkbox-size: @icon-size;
@checkbox-radius: @border-radius;
@checkbox-border-width: 1px;
// @checkbox-line-height: (@checkbox-size + @checkbox-border-width);

@checkbox-bg: @input-bg;
@checkbox-text: @accent;
@checkbox-border: contrast(@checkbox-bg, darken(@checkbox-bg, 20%), lighten(@checkbox-bg, 30%));
@checkbox-hovered-bg: @checkbox-bg;
@checkbox-hovered-text: @checkbox-text;
@checkbox-hovered-border: @checkbox-border;
@checkbox-checked-bg: @checkbox-bg;
@checkbox-checked-text: @checkbox-text;
@checkbox-checked-border: @checkbox-border;
@checkbox-indeterminate-bg: @checkbox-bg;
@checkbox-indeterminate-text: @checkbox-text;
@checkbox-indeterminate-border: @checkbox-border;
@checkbox-focused-border: @checkbox-text;
@checkbox-focused-shadow: none;
@checkbox-focused-checked-border: @checkbox-focused-border;
@checkbox-focused-checked-shadow: none;
@checkbox-disabled-bg: null;
@checkbox-disabled-text: null;
@checkbox-disabled-border: null;
@checkbox-disabled-checked-bg: null;
@checkbox-disabled-checked-text: null;
@checkbox-disabled-checked-border: null;
@checkbox-invalid-bg: null;
@checkbox-invalid-text: null;
@checkbox-invalid-border: @checkbox-border;


//Radio Buttons
@radio-icon-size: 12px;
// @radio-size: @icon-size;
@radio-radius: 50%;
@radio-border-width: 1px;
// @radio-line-height: calc(@radio-size + @radio-border-width);

@radio-bg: @checkbox-bg;
@radio-text: @checkbox-text;
@radio-border: @checkbox-border;
@radio-hovered-bg: @checkbox-hovered-bg;
@radio-hovered-text: @checkbox-hovered-text;
@radio-hovered-border: @checkbox-hovered-border;
@radio-checked-bg: @checkbox-checked-bg;
@radio-checked-text: @checkbox-checked-text;
@radio-checked-border: @checkbox-checked-border;
@radio-indeterminate-bg: @radio-checked-bg;
@radio-indeterminate-text: @radio-checked-text;
@radio-indeterminate-border: @radio-checked-border;
@radio-focused-border: @checkbox-hovered-border;
@radio-focused-shadow: @checkbox-focused-shadow;
@radio-focused-checked-border: @checkbox-checked-border;
@radio-focused-checked-shadow: @checkbox-focused-checked-shadow;
@radio-disabled-bg: null;
@radio-disabled-text: null;
@radio-disabled-border: null;
@radio-disabled-checked-bg: null;
@radio-disabled-checked-text: null;
@radio-disabled-checked-border: null;


// FileManager
@filemanager-bg: false;
@filemanager-text: false;
@filemanager-border: false;

@filemanager-toolbar-bg: false;
@filemanager-toolbar-text: false;
@filemanager-toolbar-border: @widget-border-color;

@filemanager-navigation-bg: false;
@filemanager-navigation-text: false;
@filemanager-navigation-border: @widget-border-color;

@filemanager-breadcrumb-bg: @base;
@filemanager-breadcrumb-text: false;
@filemanager-breadcrumb-border: @widget-border-color;

@filemanager-listview-bg: false;
@filemanager-listview-text: false;
@filemanager-listview-border: false;

@filemanager-listview-item-bg: false;
@filemanager-listview-item-text: false;
@filemanager-listview-item-border: false;

@filemanager-listview-item-icon-bg: false;
@filemanager-listview-item-icon-text: mix(@normal-text-color, @base, 41.5%);
@filemanager-listview-item-icon-border: false;
@filemanager-listview-item-icon-selected-bg: false;
@filemanager-listview-item-icon-selected-text: @selected-text-color;
@filemanager-listview-item-icon-selected-border: false;

@filemanager-grid-bg: false;
@filemanager-grid-text: false;
@filemanager-grid-border: false;

@filemanager-preview-bg: false;
@filemanager-preview-text: false;
@filemanager-preview-border: @widget-border-color;

@filemanager-preview-icon-bg: false;
@filemanager-preview-icon-text: mix(@normal-text-color, @base, 41.5%);
@filemanager-preview-icon-border: false;

@filemanager-drag-hint-bg: @accent;
@filemanager-drag-hint-text: contrast(@filemanager-drag-hint-bg);
@filemanager-drag-hint-border: false;

@filemanager-drag-hint-icon-bg: false;
@filemanager-drag-hint-icon-text: contrast(@filemanager-drag-hint-bg);
@filemanager-drag-hint-icon-border: false;

@filemanager-view-dropzone-hovered-text: inherit;
@filemanager-view-dropzone-hovered-bg: inherit;
@filemanager-view-dropzone-hovered-border: false;

// Stepper
@stepper-bg: false;
@stepper-text: false;
@stepper-border: false;

@stepper-indicator-bg: @component-bg;
@stepper-indicator-text: @component-text;
@stepper-indicator-border: @component-border;

@stepper-indicator-hover-bg: darken( @stepper-indicator-bg, 10% );
@stepper-indicator-hover-text: false;
@stepper-indicator-hover-border: false;

@stepper-indicator-disabled-bg: @stepper-indicator-bg;
@stepper-indicator-disabled-text: mix(@stepper-indicator-text, #ffffff, 60%);
@stepper-indicator-disabled-border: @stepper-indicator-border;

@stepper-indicator-done-bg: @accent;
@stepper-indicator-done-text: contrast( @stepper-indicator-done-bg );
@stepper-indicator-done-border: @stepper-indicator-done-bg;

@stepper-indicator-done-hover-bg: darken( @stepper-indicator-done-bg, 10% );
@stepper-indicator-done-hover-text: false;
@stepper-indicator-done-hover-border: false;

@stepper-indicator-done-disabled-bg: mix( @stepper-indicator-done-bg, @component-bg, 60%);
@stepper-indicator-done-disabled-text: contrast( @stepper-indicator-done-bg );
@stepper-indicator-done-disabled-border: @stepper-indicator-done-disabled-bg;

@stepper-indicator-current-bg: @stepper-indicator-done-bg;
@stepper-indicator-current-text: @stepper-indicator-done-text;
@stepper-indicator-current-border: @stepper-indicator-done-border;

@stepper-indicator-current-hover-bg: @stepper-indicator-done-hover-bg;
@stepper-indicator-current-hover-text: @stepper-indicator-done-hover-text;
@stepper-indicator-current-hover-border: @stepper-indicator-done-hover-border;

@stepper-indicator-current-disabled-bg: @stepper-indicator-done-disabled-bg;
@stepper-indicator-current-disabled-text: @stepper-indicator-done-disabled-text;
@stepper-indicator-current-disabled-border: @stepper-indicator-done-disabled-border;

@stepper-label-text: @component-text;
@stepper-label-success-text: contrast(@base, lighten(@success, 1%), darken(@success, 30%));
@stepper-label-error-text: contrast(@error, lighten(@error, 30%), darken(@error, 30%));
@stepper-label-disabled-text: @disabled-text-color;


// Wizard
@wizard-focused-shadow: inset 0 0 0 2px @widget-border-color;
@wizard-step-border-focused: #656565;


// Textarea
@textarea-bg: @input-bg;
@textarea-text: @input-text;
@textarea-border: @input-border;
@textarea-shadow: @input-shadow;

@textarea-hovered-text: @input-hovered-text;
@textarea-hovered-bg: @input-hovered-bg;
@textarea-hovered-border: @input-hovered-border;
@textarea-hovered-shadow: @input-hovered-shadow;

@textarea-focused-text: @input-focused-text;
@textarea-focused-bg: @input-focused-bg;
@textarea-focused-border: @input-focused-border;
@textarea-focused-shadow: @input-focused-shadow;

@textarea-placeholder-text: rgba( @input-text, .5 );
@textarea-placeholder-opacity: 1;

@textarea-selected-text: contrast(@accent);
@textarea-selected-bg: @accent;

@textarea-invalid-border: rgba( @error, .5 );
@textarea-invalid-shadow: null;


// Appbar
@appbar-box-shadow: 0px 1px 1px rgba(0, 0, 0, .16);
@appbar-bottom-box-shadow:  0px -1px 1px rgba(0, 0, 0, .16);


//Gantt
@gantt-task-selected-bg: lighten(@event-background-color, 7%);
@gantt-task-completed-selected-bg: @event-background-color;
@gantt-summary-completed-selected-bg: @selected-background-color;

@gantt-delayed-advanced-text: #FFFFFF;

@gantt-planned-bg: @primary;
@gantt-planned-border: @gantt-planned-bg;
@gantt-planned-tooltip-text: #FFFFFF;

@gantt-delayed-bg: @error;
@gantt-delayed-bg-lighter: tint(@gantt-delayed-bg, 20);
@gantt-delayed-tooltip-text: #FFFFFF;

@gantt-advanced-bg: @success;
@gantt-advanced-bg-lighter: tint(@gantt-advanced-bg, 20);

@gantt-task-offset-border: @gantt-delayed-bg;
@gantt-task-offset-text: #000000;


// ImageEditor
@imageeditor-bg: @component-bg;
@imageeditor-text: @component-text;
@imageeditor-border: @component-border;

@imageeditor-content-bg: darken(@component-bg, 22.5%);
@imageeditor-content-text: false;
@imageeditor-content-border: false;

@imageeditor-action-pane-text: false;
@imageeditor-action-pane-bg: @component-bg;
@imageeditor-action-pane-border: @component-border;

@imageeditor-crop-text: false;
@imageeditor-crop-bg: false;
@imageeditor-crop-border: white;

@imageeditor-resize-handle-text: false;
@imageeditor-resize-handle-bg: false;
@imageeditor-resize-handle-border: white;

@imageeditor-crop-overlay-bg: rgba(black, 0.3);


// Expansion panel
@expander-bg: @component-bg;
@expander-text: @component-text;
@expander-border: @component-border;

@expander-header-bg: @expander-bg;
@expander-header-text: @expander-text;
@expander-header-border: @expander-text;

@expander-title-text: if(@is-dark-theme, lighten(@primary, 30%), @primary);

@expander-header-sub-title-opacity: .65;

@expander-header-hover-bg: null;
@expander-header-focused-bg: null;
@expander-header-focused-shadow: inset 0 0 0 2px contrast(@background, rgba(black, .13), rgba(white, .6), 0.5);


// FloatingActionButton
@fab-shadow: 0 4px 5px rgba(0, 0, 0, .14), 0 1px 10px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .2);
@fab-focus-shadow: 0 1px 10px rgba(#000000, .22), 0 4px 5px rgba(#000000, .24), 0 2px 4px rgba(#000000, .2);
@fab-disabled-shadow:  0 4px 5px rgba(0, 0, 0, .06), 0 1px 10px rgba(0, 0, 0, .06), 0 2px 4px rgba(0, 0, 0, .08);

@fab-item-text: if(@is-dark-theme, @badge-secondary-text, @component-text);
@fab-item-bg: if(@is-dark-theme, lighten(@badge-secondary-bg, 30%), @component-bg);
@fab-item-border: if(@is-dark-theme, @badge-secondary-border, @component-border);

@fab-item-icon-text: if(@is-dark-theme, @badge-secondary-text, contrast( #ebebeb ));
@fab-item-icon-bg: if(@is-dark-theme, lighten(@badge-secondary-bg, 20%), #ebebeb);
@fab-item-icon-border: if(@is-dark-theme, @badge-secondary-border, #ebebeb);

@fab-item-shadow: if(@is-dark-theme, @focused-shadow, @fab-shadow);
@fab-item-focus-shadow: if(@is-dark-theme, @focused-item-shadow, @fab-focus-shadow);
@fab-item-disabled-shadow: @fab-disabled-shadow;


// ActionSheet
@actionsheet-padding-x: null;
@actionsheet-padding-y: null;
@actionsheet-bg: @component-bg;
@actionsheet-text: @component-text;
@actionsheet-border: @component-border;
@actionsheet-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);

@actionsheet-border-width: 0px;
@actionsheet-border-radius: 0px;

// Action sheet header
@actionsheet-header-bg: null;
@actionsheet-header-text: null;
@actionsheet-header-border: null;
@actionsheet-header-gradient: null;
@actionsheet-header-shadow: null;

// Action sheet item
@actionsheet-item-hover-bg: @hover-background;
@actionsheet-item-hover-text: null;
@actionsheet-item-hover-border: null;
@actionsheet-item-hover-gradient: null;
@actionsheet-item-hover-shadow: null;

@actionsheet-item-focus-bg: null;
@actionsheet-item-focus-text: null;
@actionsheet-item-focus-border: null;
@actionsheet-item-focus-gradient: null;
@actionsheet-item-focus-shadow: @focused-shadow;

@actionsheet-item-disabled-bg: null;
@actionsheet-item-disabled-text: null;
@actionsheet-item-disabled-border: null;
@actionsheet-item-disabled-gradient: null;
@actionsheet-item-disabled-shadow: null;


// BottomNavigation
@bottom-nav-flat-bg: @background;
@bottom-nav-flat-text: @component-text;
@bottom-nav-flat-border: @component-border;


// TaskBoard
@taskboard-bg: null;
@taskboard-text: null;
@taskboard-border: null;

@taskboard-toolbar-bg: null;
@taskboard-toolbar-text: null;
@taskboard-toolbar-border: null;

@taskboard-column-bg: @alt-background-color;
@taskboard-column-text: @component-text;
@taskboard-column-border: transparent;

@taskboard-column-focus-bg: null;
@taskboard-column-focus-text: null;
@taskboard-column-focus-border: shade( @component-border, 20% );

@taskboard-column-header-text: @component-text;

@taskboard-pane-bg: @component-bg;
@taskboard-pane-text: @component-text;
@taskboard-pane-border: @component-border;

@taskboard-card-bg: @card-bg;
@taskboard-card-text: @card-text;
@taskboard-card-border: @card-border;

@taskboard-card-header-bg: @taskboard-card-bg;
@taskboard-card-header-text: null;
@taskboard-card-header-border: null;

@taskboard-card-title-text: @primary;
@taskboard-card-title-focus-text: darken( @primary, 13% );
@taskboard-card-title-hover-text: lighten( @primary, 13% );

@taskboard-card-footer-bg: @taskboard-card-bg;
@taskboard-card-footer-text: null;
@taskboard-card-footer-border: null;

@taskboard-card-focus-border: shade( @taskboard-card-border, 18% );
@taskboard-card-hover-border: shade( @taskboard-card-border, 10% );

@taskboard-card-selected-text: @taskboard-card-text;
@taskboard-card-selected-bg: @taskboard-card-bg;
@taskboard-card-selected-border: lighten( @primary, 13% );

@taskboard-drag-placeholder-bg: rgba(255, 255, 255, .2);
@taskboard-drag-placeholder-border: @component-border;


// Scheduler
@scheduler-yearview-calendar-bg: @scheduler-background-color;

@scheduler-yearview-month-header-text: @component-text;

@scheduler-yearview-indicator-bg: @primary;
@scheduler-yearview-indicator-selected-bg: contrast(@primary);

@scheduler-tooltip-bg: null;
@scheduler-tooltip-day-text: @secondary-text-color;


// Captcha
@captcha-bg: @component-bg;
@captcha-text: @component-text;
@captcha-border: @component-border;


// Popover
@popover-bg: @component-bg;
@popover-text: @component-text;
@popover-border: @component-border;
@popover-shadow: @window-shadow;

@popover-header-bg: @popover-bg;
@popover-header-text: @card-header-text;
@popover-header-border: @card-header-border;

@popover-callout-bg: @popover-bg;
@popover-callout-border: @popover-border;

@popover-callout-shadow-n: @card-focus-callout-box-shadow-n;
@popover-callout-shadow-e: @card-focus-callout-box-shadow-e;
@popover-callout-shadow-s: @card-focus-callout-box-shadow-s;
@popover-callout-shadow-w: @card-focus-callout-box-shadow-w;


// Orgchart
@orgchart-bg: @component-bg;
@orgchart-text: @component-text;
@orgchart-border: @component-border;

@orgchart-node-group-bg: @alt-background-color;
@orgchart-node-group-text: @component-text;
@orgchart-node-group-border: @component-border;

@orgchart-node-group-focus-border: @card-focus-border;
@orgchart-node-group-focus-shadow: @card-focus-shadow;

@orgchart-card-focus-shadow: @card-focus-shadow;

@orgchart-line-fill: @component-border;


// PivotGrid
@pivotgrid-bg: @component-bg;
@pivotgrid-text: @component-text;
@pivotgrid-border: @component-border;

@pivotgrid-alt-border: shade(@pivotgrid-border, 20%);

@pivotgrid-headers-bg: @alt-background-color;
@pivotgrid-headers-text: @header-text-color;
@pivotgrid-headers-border: @component-border;

@pivotgrid-total-bg: shade( @pivotgrid-bg, 10% );
@pivotgrid-total-text: @header-text-color;
@pivotgrid-total-border: @component-border;

@pivotgrid-hover-bg: darken(@pivotgrid-bg, 7%);
@pivotgrid-hover-text: null;
@pivotgrid-hover-border: null;

@pivotgrid-selected-bg: rgba(@selected-background-color, .25);
@pivotgrid-selected-text: null;
@pivotgrid-selected-border: null;

@pivotgrid-focus-shadow: @focused-item-shadow;

// PivotGrid Configurator
@pivotgrid-configurator-bg: @component-bg;
@pivotgrid-configurator-text: @component-text;
@pivotgrid-configurator-border: @component-border;

@pivotgrid-configurator-header-bg: null;
@pivotgrid-configurator-header-text: @header-text-color;
@pivotgrid-configurator-header-border: null;

@pivotgrid-configurator-end-shadow: -3px 0px 6px rgba(0, 0, 0, .16);
@pivotgrid-configurator-start-shadow: 3px 0px 6px rgba(0, 0, 0, .16);
@pivotgrid-configurator-top-shadow: 0px -3px 6px rgba(0, 0, 0, .16);
@pivotgrid-configurator-bottom-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

// PivotGrid Calculated Field
@pivotgrid-calculated-field-bg: @component-bg;
@pivotgrid-calculated-field-text: @component-text;
@pivotgrid-calculated-field-border: @component-border;

@pivotgrid-calculated-field-header-bg: null;
@pivotgrid-calculated-field-header-text: @header-text-color;
@pivotgrid-calculated-field-header-border: null;


// Chip
@chip-base-bg: @button-text-color;

@chip-solid-bg: rgba( @chip-base-bg, .08 );
@chip-solid-text: @chip-base-bg;
@chip-solid-border: rgba( @chip-base-bg, .3 );

@chip-solid-hover-bg: rgba( @chip-base-bg, .16 );
@chip-solid-hover-text: null;
@chip-solid-hover-border: null;

@chip-solid-focus-bg: @chip-solid-bg;
@chip-solid-focus-shadow: 0 0 0 2px rgba( @chip-base-bg, .16 );

@chip-solid-selected-bg: rgba( @chip-base-bg, .24 );
@chip-solid-selected-text: null;
@chip-solid-selected-border: null;

@chip-solid-success-bg: rgba( @success, .08 );
@chip-solid-success-text: @success;
@chip-solid-success-border: rgba( @success, .3 );

@chip-solid-success-hover-bg: rgba( @success, .16 );
@chip-solid-success-hover-text: null;
@chip-solid-success-hover-border: null;

@chip-solid-success-focus-bg: null;
@chip-solid-success-focus-text: null;
@chip-solid-success-focus-border: null;

@chip-solid-success-selected-bg: rgba( @success, .24 );
@chip-solid-success-selected-text: null;
@chip-solid-success-selected-border: null;

@chip-solid-warning-bg: rgba( @warning, .08 );
@chip-solid-warning-text: @warning;
@chip-solid-warning-border: rgba( @warning, .3 );

@chip-solid-warning-hover-bg: rgba( @warning, .16 );
@chip-solid-warning-hover-text: null;
@chip-solid-warning-hover-border: null;

@chip-solid-warning-focus-bg: null;
@chip-solid-warning-focus-text: null;
@chip-solid-warning-focus-border: null;

@chip-solid-warning-selected-bg: rgba( @warning, .24 );
@chip-solid-warning-selected-text: null;
@chip-solid-warning-selected-border: null;

@chip-solid-error-bg: rgba( @error, .08 );
@chip-solid-error-text: @error;
@chip-solid-error-border: rgba( @error, .3 );

@chip-solid-error-hover-bg: rgba( @error, .16 );
@chip-solid-error-hover-text: null;
@chip-solid-error-hover-border: null;

@chip-solid-error-focus-bg: null;
@chip-solid-error-focus-text: null;
@chip-solid-error-focus-border: null;

@chip-solid-error-selected-bg: rgba( @error, .24 );
@chip-solid-error-selected-text: null;
@chip-solid-error-selected-border: null;

@chip-solid-info-bg: rgba( @info, .08 );
@chip-solid-info-text: @info;
@chip-solid-info-border: rgba( @info, .3 );

@chip-solid-info-hover-bg: rgba( @info, .16 );
@chip-solid-info-hover-text: null;
@chip-solid-info-hover-border: null;

@chip-solid-info-focus-bg: null;
@chip-solid-info-focus-text: null;
@chip-solid-info-focus-border: null;

@chip-solid-info-selected-bg: rgba( @info, .24 );
@chip-solid-info-selected-text: null;
@chip-solid-info-selected-border: null;

@chip-outline-bg: @component-bg;
@chip-outline-text: @chip-base-bg;
@chip-outline-border: @chip-base-bg;

@chip-outline-hover-bg: @chip-base-bg;
@chip-outline-hover-text: contrast( @chip-outline-hover-bg );
@chip-outline-hover-border: null;

@chip-outline-focus-bg: null;
@chip-outline-focus-text: null;
@chip-outline-focus-border: null;
@chip-outline-focus-shadow: 0 0 0 2px rgba( @chip-base-bg, .08 );

@chip-outline-selected-bg: @chip-outline-hover-bg;
@chip-outline-selected-text: @chip-outline-hover-text;

@chip-outline-success-bg: null;
@chip-outline-success-text: @success;
@chip-outline-success-border: @success;

@chip-outline-success-hover-bg: @success;
@chip-outline-success-hover-text: contrast( @chip-outline-success-hover-bg );
@chip-outline-success-hover-border: null;

@chip-outline-success-focus-bg: nul;
@chip-outline-success-focus-text: null;
@chip-outline-success-focus-border: null;

@chip-outline-success-selected-bg: @chip-outline-success-hover-bg;
@chip-outline-success-selected-text: @chip-outline-success-hover-text;
@chip-outline-success-selected-border: null;

@chip-outline-warning-bg: null;
@chip-outline-warning-text: @warning;
@chip-outline-warning-border: @warning;

@chip-outline-warning-hover-bg: @warning;
@chip-outline-warning-hover-text: contrast( @chip-outline-warning-hover-bg );
@chip-outline-warning-hover-border: null;

@chip-outline-warning-focus-bg: nul;
@chip-outline-warning-focus-text: null;
@chip-outline-warning-focus-border: null;

@chip-outline-warning-selected-bg: @chip-outline-warning-hover-bg;
@chip-outline-warning-selected-text: @chip-outline-warning-hover-text;
@chip-outline-warning-selected-border: null;

@chip-outline-error-bg: null;
@chip-outline-error-text: @error;
@chip-outline-error-border: @error;

@chip-outline-error-hover-bg: @error;
@chip-outline-error-hover-text: contrast( @chip-outline-error-hover-bg );
@chip-outline-error-hover-border: null;

@chip-outline-error-focus-bg: nul;
@chip-outline-error-focus-text: null;
@chip-outline-error-focus-border: null;

@chip-outline-error-selected-bg: @chip-outline-error-hover-bg;
@chip-outline-error-selected-text: @chip-outline-error-hover-text;
@chip-outline-error-selected-border: null;

@chip-outline-info-bg: null;
@chip-outline-info-text: @info;
@chip-outline-info-border: @info;

@chip-outline-info-hover-bg: @info;
@chip-outline-info-hover-text: contrast( @chip-outline-info-hover-bg );
@chip-outline-info-hover-border: null;

@chip-outline-info-focus-bg: nul;
@chip-outline-info-focus-text: null;
@chip-outline-info-focus-border: null;

@chip-outline-info-selected-bg: @chip-outline-info-hover-bg;
@chip-outline-info-selected-text: @chip-outline-info-hover-text;
@chip-outline-info-selected-border: null;

@chip-multiple-selected-icon-bg: rgba( black, .44 );
@chip-multiple-selected-icon-text: #ffffff;

@chip-primary-focus-shadow: 0 0 0 2px rgba( @primary, .16 );
@chip-secondary-focus-shadow: 0 0 0 2px rgba( @secondary, .16 );
@chip-info-focus-shadow: 0 0 0 2px rgba( @info, .16 );
@chip-success-focus-shadow: 0 0 0 2px rgba( @success, .16 );
@chip-warning-focus-shadow: 0 0 0 2px rgba( @warning, .16 );
@chip-error-focus-shadow: 0 0 0 2px rgba( @error, .16 );


// Panelbar
@panelbar-bg: @header-background-color;
@panelbar-text: @header-text-color;
@panelbar-border: @secondary-border-color;

@panelbar-header-bg: @header-background-color;
@panelbar-header-text: @header-text-color;
@panelbar-header-border: null;
@panelbar-header-gradient: null;

@panelbar-header-hovered-bg: @selected-background;
@panelbar-header-hovered-text: null;
@panelbar-header-hovered-border: null;
@panelbar-header-hovered-gradient: null;

@panelbar-header-focused-bg: null;
@panelbar-header-focused-text: null;
@panelbar-header-focused-border: null;
@panelbar-header-focused-gradient: null;
@panelbar-header-focused-shadow: @focused-item-shadow;

@panelbar-header-hovered-focused-bg: null;
@panelbar-header-hovered-focused-text: null;
@panelbar-header-hovered-focused-border: null;
@panelbar-header-hovered-focused-gradient: null;

@panelbar-header-selected-bg: @selected-background;
@panelbar-header-selected-text: @selected-text-color;
@panelbar-header-selected-border: null;
@panelbar-header-selected-gradient: null;

@panelbar-header-selected-hovered-bg: shade( @panelbar-header-selected-bg, 10% );
@panelbar-header-selected-hovered-text: null;
@panelbar-header-selected-hovered-border: null;
@panelbar-header-selected-hovered-gradient: null;

@panelbar-header-selected-focused-bg: null;
@panelbar-header-selected-focused-text: null;
@panelbar-header-selected-focused-border: null;
@panelbar-header-selected-focused-gradient: null;

@panelbar-header-selected-hovered-focused-bg: null;
@panelbar-header-selected-hovered-focused-text: null;
@panelbar-header-selected-hovered-focused-border: null;
@panelbar-header-selected-hovered-focused-gradient: null;

@panelbar-item-hovered-bg: @selected-background;
@panelbar-item-hovered-text: null;
@panelbar-item-hovered-border: null;
@panelbar-item-hovered-gradient: null;

@panelbar-item-focused-bg: null;
@panelbar-item-focused-text: null;
@panelbar-item-focused-border: null;
@panelbar-item-focused-gradient: null;
@panelbar-item-focused-shadow: @focused-item-shadow;

@panelbar-item-hovered-focused-bg: null;
@panelbar-item-hovered-focused-text: null;
@panelbar-item-hovered-focused-border: null;
@panelbar-item-hovered-focused-gradient: null;

@panelbar-item-selected-bg: @selected-background;
@panelbar-item-selected-text: @selected-text-color;
@panelbar-item-selected-border: null;
@panelbar-item-selected-gradient: null;

@panelbar-item-selected-hovered-bg: shade( @panelbar-item-selected-bg, 10% );
@panelbar-item-selected-hovered-text: null;
@panelbar-item-selected-hovered-border: null;
@panelbar-item-selected-hovered-gradient: null;

@panelbar-item-selected-focused-bg: null;
@panelbar-item-selected-focused-text: null;
@panelbar-item-selected-focused-border: null;
@panelbar-item-selected-focused-gradient: null;

@panelbar-item-selected-hovered-focused-bg: null;
@panelbar-item-selected-hovered-focused-text: null;
@panelbar-item-selected-hovered-focused-border: null;
@panelbar-item-selected-hovered-focused-gradient: null;


// ColorPalette
@colorpalette-tile-focus-shadow: 0 0 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, .5 );
@colorpalette-tile-hover-shadow: 0 0 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, .8 );
@colorpalette-tile-selected-shadow: 0 1px 3px 1px rgba( black, .3 ), inset 0 0 0 1px rgba( white, 1 );


// ColorGradient
@gradient-transparent-to-black: rgba( black, 0), black;
@gradient-transparent-to-white: rgba( white, 0), white;
@gradient-black-to-transparent: black, rgba( black, 0);
@gradient-white-to-transparent: white, rgba( white, 0);
@gradient-rainbow: #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000;

@colorgradient-bg: @component-bg;
@colorgradient-text: @component-text;
@colorgradient-border: @component-border;

@colorgradient-focus-border: null;
@colorgradient-focus-shadow: 1px 1px 7px 1px rgba(0, 0, 0, .3);

@colorgradient-draghandle-bg: transparent;
@colorgradient-draghandle-text: null;
@colorgradient-draghandle-border: rgba( white, .8);
@colorgradient-draghandle-shadow: 0 1px 4px rgba(0, 0, 0, .5);
@colorgradient-draghandle-focus-shadow: 0 1px 4px black;
@colorgradient-draghandle-hover-shadow: @colorgradient-draghandle-focus-shadow;

@colorgradient-input-label-text: @disabled-text-color;


// Color Preview
@color-preview-bg: null;
@color-preview-text: null;
@color-preview-border: @component-border;
@color-preview-hover-border: @hover-border-color;

@color-preview-no-color-bg: white;
@color-preview-no-color-text: @error;
@color-preview-no-color-border: null;


// ColorEditor/FlatColorPicker
@coloreditor-bg: @component-bg;
@coloreditor-text: @component-text;
@coloreditor-border: @component-border;

@coloreditor-focus-border: null;
@coloreditor-focus-shadow: 1px 1px 7px 1px rgba(0, 0, 0, .3);


// Kendo skin

@theme-name: ~"metro";

@import "common/mixins.less";
@import "mixins/gradients.less";
@import "mixins/border-radius.less";
@import "themes/type.less";
@import "themes/button.less";
@import "Default/avatar/_theme.less";
@import "Default/card/_theme.less";
@import "Default/tilelayout/_theme.less";
@import "Default/chat/_theme.less";
@import "Default/calendar/_theme.less";
@import "Default/multiviewcalendar/_theme.less";
@import "Default/pdf-viewer/_theme.less";
@import "Default/scrollview/_theme.less";
@import "Default/switch/_theme.less";
@import "common/adaptive/_theme.less";
@import "Default/filter/_theme.less";
@import "Default/drawer/_theme.less";
@import "Default/rating/_theme.less";
@import "Default/timeline/_theme.less";
@import "Default/breadcrumb/_theme.less";
@import "Default/listgroup/_theme.less";
@import "Default/messagebox/_theme.less";
@import "Default/badge/_theme.less";
@import "Default/checkbox/_theme.less";
@import "Default/radio/_theme.less";
@import "Default/filemanager/_theme.less";
@import "Default/toolbar/_theme.less";
@import "Default/window/_theme.less";
@import "Default/dialog/_theme.less";
@import "Default/stepper/_theme.less";
@import "Default/forms/_theme.less";
@import "Default/tooltip/_theme.less";
@import "Default/wizard/_theme.less";
@import "Default/textarea/_theme.less";
@import "Default/appbar/_theme.less";
@import "Default/loader/_theme.less";
@import "Default/gantt/_theme.less";
@import "Default/imageeditor/_theme.less";
@import "Default/expansion-panel/_theme.less";
@import "Default/floatingactionbutton/_theme.less";
@import "Default/action-sheet/_theme.less";
@import "Default/bottom-navigation/_theme.less";
@import "Default/searchbox/_theme.less";
@import "Default/skeleton/_theme.less";
@import "Default/taskboard/_theme.less";
@import "Default/scheduler/_theme.less";
@import "Default/captcha/_theme.less";
@import "Default/popover/_theme.less";
@import "Default/orgchart/_theme.less";
@import "Default/pivotgrid/_theme.less";
@import "Default/chip/_theme.less";
@import "Default/panelbar/_theme.less";
@import "Default/colorpicker/_theme.less";


.k-in,
.k-item
{
    border-color: transparent;
}

// main colors
.k-splitbar .k-resize-handle {
    background-color: @normal-text-color;
}

.k-block,
.k-widget
{
    background-color: @widget-background-color;
}

.k-block,
.k-widget,
.k-input,
.k-group,
.k-content,
.k-header,
.k-filter-row > th,
.k-separator,
.k-autocomplete,
.k-dropdown-wrap,
.k-toolbar,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-state-default,
.k-state-default .k-select,
.k-state-disabled,
.k-grid-header,
.k-grid-header-wrap,
.k-grid-header-locked,
.k-grid-footer-locked,
.k-grid-content-locked,
.k-grid td,
.k-grid td.k-state-selected,
.k-grid-footer-wrap,
.k-grouping-header,
.k-grouping-header .k-group-indicator,
.k-panelbar > .k-item > .k-link,
.k-panel > .k-item > .k-link,
.k-panelbar .k-panel,
.k-panelbar .k-content,
.k-treemap-tile,
.k-slider-track,
.k-splitbar,
.k-dropzone-active,
.k-upload-files,
.k-upload .k-action-buttons,
.k-popup.k-align .k-list .k-item:last-child,
.k-maskedtextbox.k-state-disabled > .k-textbox:hover,
.k-dateinput.k-state-disabled > .k-textbox:hover
{
    border-color: @widget-border-color;
}

.k-group,
.k-grouping-header,
.k-group-footer td,
.k-grid-footer,
.k-footer-template td,
.k-widget .k-status,
.k-dropzone-hovered,
.k-popup {
    background-color: @group-background-color;
}

.k-grouping-row td,
td.k-group-cell,
.k-resize-handle-inner
{
    background-color: @header-background-color;
}

.k-list-container
{
    border-color: @group-border-color;
    background-color: @select-group-background-color;
}

.k-content,
.k-panelbar > li.k-item,
.k-panel > li.k-item {
    background-color: @content-background-color;
}

.k-alt,
.k-separator,
.k-resource.k-alt,
.k-pivot-layout > tbody > tr:first-child > td:first-child {
    background-color: @alt-background-color;
}

.k-pivot-rowheaders .k-alt .k-alt,
.k-header.k-alt
{
    background-color: @nested-alt-background-color;
}

.k-textbox,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active
{
    border-color: @widget-border-color;
    background-color: @select-background-color;
}

.k-textbox > input,
.k-autocomplete .k-input,
.k-dropdown-wrap .k-input,
.k-textbox.k-state-focused,
.k-autocomplete.k-state-focused .k-input,
.k-dropdown-wrap.k-state-focused .k-input,
.k-picker-wrap.k-state-focused .k-input,
.k-numeric-wrap.k-state-focused .k-input
{
    border-color: @widget-border-color;
}

.k-input,
.k-textbox,
.k-textbox > input,
.k-multiselect-wrap {
    background-color: @input-bg;
    color: @input-text;
}

.k-input-label {
    color: @input-text;
}

.k-text-error {
    color: @error;
}

// Bootstrap readonly input styling has more specificity than ours.
.k-input[readonly]
{
    background-color: @input-bg;
    color: @input-text;
}

.k-block,
.k-widget,
.k-popup,
.k-content,
.k-toolbar,
.k-dropdown .k-input
{
    color: @widget-text-color;
}

.k-inverse
{
    color: @inverse-text-color;
}

.k-block
{
    color: @header-text-color;
}

.k-link {
    color: @link-text-color;
}

.k-tabstrip {
    border-color: @secondary-border-color;
    color: @header-text-color;
    background-color: @header-background-color;
}
.k-tabstrip > .k-content {
    border-color: @active-border-color;
}

.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items .k-item {
    border-radius: 0;
}

.k-tabstrip-items .k-link,
.k-panelbar > li > .k-link
{
    color: @widget-text-color;
}

.k-header,
.k-menu,
.k-tabstrip,
.k-drag-clue,
.k-treemap-title,
.k-grid-header .k-header > .k-link
{
    color: @header-text-color;
}

.k-header,
.k-grid-header,
.k-menu,
.k-tabstrip,
.k-drag-clue,
.k-toolbar,
.k-dropdown-wrap,
.k-picker-wrap,
.k-numeric-wrap,
.k-grouping-header,
.k-progressbar,
.k-draghandle,
.k-autocomplete,
.k-state-highlight,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item {
    .composite-background(@widget-gradient);
    background-position: 50% 50%;
    background-color: @header-background-color;
}

.k-block,
.k-treemap-tile
{
    background-color: @header-background-color;
}

.k-mediaplayer-toolbar {
    background: fade(@header-background-color, 85%);
}

.k-hr {
    border-color: @widget-border-color;
}

// icons
.k-icon:hover,
.k-state-hover .k-icon,
.k-state-selected .k-icon,
.k-state-focused .k-icon,
.k-column-menu .k-state-hover .k-sprite,
.k-column-menu .k-state-active .k-sprite {
    opacity:1;
}

.k-icon,
.k-state-disabled .k-icon,
.k-column-menu .k-sprite {
    opacity: @default-icon-opacity;
}

.k-clear-value {
    color: @input-text;
}

.k-i-loading {
    background-image: url('@{image-folder}/loading.gif');
}

.k-loading-image { background-image: url('@{image-folder}/loading-image.gif'); }
.k-loading-color { background-color: @loading-panel-color; }

.k-draghandle
{
    border-color: @draghandle-border-color;
    background-color: @draghandle-background-color;
    .box-shadow(@draghandle-shadow);
}

.k-draghandle:hover
{
    border-color: @draghandle-hover-border-color;
    background-color: @draghandle-hover-background-color;
    .box-shadow(@draghandle-hover-shadow);
}

// Drop Hint
.k-drop-hint-line {
    background-color: @accent;
}

.k-drop-hint-h {
    .k-drop-hint-start {
        border-left-color: @accent;
    }

    .k-drop-hint-end {
        border-right-color: @accent;
    }
}

.k-drop-hint-v {
    .k-drop-hint-start {
        border-top-color: @accent;
    }

    .k-drop-hint-end {
        border-bottom-color: @accent;
    }
}


// Scheduler

.k-scheduler
{
    color: @event-text-color;
    background-color: @scheduler-background-color;
}

.k-scheduler-layout
{
    color: @widget-text-color;
}

.k-scheduler-datecolumn,
.k-scheduler-groupcolumn
{
    background-color: @scheduler-background-color;
    color: @widget-text-color;
}

.k-scheduler-times tr,
.k-scheduler-times th,
.k-scheduler-table td,
.k-scheduler-header th,
.k-scheduler-header-wrap,
.k-scheduler-times
{
    border-color: @cell-border-color;
}

.k-scheduler-table .k-today,
.k-today > .k-scheduler-datecolumn,
.k-today > .k-scheduler-groupcolumn
{
    background-color: @column-highlight-background-color;
}

.k-scheduler-table .k-nonwork-hour
{
    background-color: @scheduler-nonwork-background-color;
}

.k-gantt .k-nonwork-hour
{
    background-color: rgba(0,0,0, .02);
}

.k-gantt .k-header.k-nonwork-hour
{
    background-color: rgba(0,0,0, .2);
}

.k-scheduler-now-arrow
{
    border-left-color: @current-time-color;
}

.k-scheduler-now-line
{
    background-color: @current-time-color;
}

.k-event,
.k-task-complete
{
    border-color: @event-background-color;
    background: @event-background-color;
    color: @event-text-color;
}

.k-event-inverse {
    color: @event-inverse-text-color;
}

.k-event.k-state-selected
{
    background-position: 0 0;
    .box-shadow(0 0 0 2px @normal-text-color);
}

.k-event .k-resize-handle:after,
.k-task-single .k-resize-handle:after
{
    background-color: @resize-background-color;
}

.k-scheduler-marquee:before,
.k-scheduler-marquee:after
{
    border-color: @selected-background-color;
}

.k-panelbar .k-content,
.k-panelbar .k-panel,
.k-panelbar .k-item
{
    background-color: @panelbar-content-background;
    color: @panelbar-content-color;
    border-color: @secondary-border-color;
}

.k-panelbar > li > .k-link
{
    color: @panelbar-content-color;
}

.k-panelbar > .k-item > .k-link
{
    border-color: @secondary-border-color;
}

.k-panel > li.k-item
{
    background-color: @panelbar-content-background;
}

// states
.k-state-active,
.k-state-active:hover,
.k-active-filter,
.k-header-column-menu.k-state-active {
    background-color: @active-background-color;
    border-color: @active-border-color;
    color: @accent;
}

.k-fieldselector .k-list-container
{
    background-color: @active-background-color;
}

.k-tabstrip-scrollable .k-button.k-flat:hover {
    background: @button-hover-background-color !important;
}

.k-menu .k-state-hover>.k-state-active
{
    background-color: transparent;
}

.k-mediaplayer-toolbar .k-button.k-flat {
    &:active,
    &.k-state-active,
    &.k-state-active:hover {
        color: @accent;
    }
}

.k-menu {
    .k-state-selected > .k-link {
        color: @selected-text-color;
        background-color: @selected-background-color;
        border-color: @selected-border-color;
        .composite-background(@selected-gradient);
    }

    .k-link.k-state-active {
        background-color: @active-background-color;
        border-color: @active-border-color;
        color: @active-text-color;
    }

    .k-state-hover > .k-link,
    .k-menu-scroll-button:hover {
        color: @hover-text-color;
        background-color: @hover-background-color;
        border-color: @hover-border-color;
        .composite-background(@hover-gradient);
    }
}

.k-state-highlight
{
    background: @active-background-color;
    color: @active-text-color;
}

.k-state-focused,
.k-grouping-row .k-state-focused
{
    border-color: @focused-border-color;
}

.k-calendar-container.k-group {
    border-color: @group-border-color;
}

.k-state-selected,
.k-state-selected:link,
.k-state-selected:visited,
.k-list>.k-state-selected,
.k-list>.k-state-highlight,
.k-panel > .k-state-selected,
.k-ghost-splitbar-vertical,
.k-ghost-splitbar-horizontal,
.k-draghandle.k-state-selected:hover,
.k-scheduler .k-today.k-state-selected,
.k-marquee-color
{
    color: @selected-text-color;
    background-color: @selected-background-color;
    border-color: @selected-border-color;
}

.k-virtual-item.k-first,
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first
{
    border-top-color: @hover-border-color;
}

.k-group-header + div > .k-list > .k-item.k-first:before {
    border-top-color: @hover-border-color;
}

.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header
{
    background: @hover-border-color;
    color: @selected-text-color;
}

.k-popup .k-list .k-item > .k-group
{
    background: @hover-border-color;
    color: @selected-text-color;
    border-bottom-left-radius: @inner-border-radius;
}

.k-marquee-text
{
    color: @selected-text-color;
}

.k-state-focused,
.k-list>.k-state-focused,
.k-listview>.k-state-focused,
.k-grid-header th.k-state-focused,
td.k-state-focused
{
    .box-shadow(@focused-item-shadow);
}

.k-state-focused.k-state-selected,
.k-list>.k-state-focused.k-state-selected,
.k-listview>.k-state-focused.k-state-selected,
td.k-state-focused.k-state-selected
{
    .box-shadow(@focused-active-item-shadow);
}

.k-list-optionlabel.k-state-selected.k-state-focused
{
    .box-shadow(none);
}

.k-state-selected>.k-link,
.k-panelbar > li > .k-state-selected,
.k-panelbar .k-item .k-link.k-state-selected,
.k-panelbar>li.k-state-default>.k-link.k-state-selected {
    color: @selected-text-color;
}

.k-state-hover,
.k-splitbar-horizontal-hover,
.k-splitbar-vertical-hover,
.k-list>.k-state-hover,
.k-dropdown .k-state-focused,
.k-filebrowser-dropzone
{
    color: @hover-text-color;
    background-color: @hover-background-color;
    border-color: @hover-border-color;
}

.k-state-hover > .k-select,
.k-state-focused > .k-select
{
    border-color: @hover-border-color;
}

.k-state-hover,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em,
.k-draghandle:hover,
.k-listbox .k-item:hover:not(.k-state-disabled)
{
    .composite-background(@hover-gradient);
}




// Pager
.k-pager-wrap {
    border-color: @secondary-border-color;
    color: @header-text-color;
    background-color: @header-background-color;


    .k-link {
        border-color: @secondary-border-color;
    }
    .k-link:hover {
        color: @hover-text-color;
        background-color: @hover-background-color;
        border-color: @hover-border-color;
    }
    .k-link.k-state-disabled {
        color: @normal-text-color;
        border-color: @widget-border-color;
    }
    .k-link.k-state-selected {
        color: @selected-text-color;
        background-color: @selected-background-color;
        border-color: @selected-border-color;
    }
    .k-link:focus {
        .box-shadow( @focused-item-shadow );
    }

    .k-pager-numbers .k-link {
        border-color: transparent;
    }

    .k-pager-refresh {
        border-color: transparent;
    }
}

.k-autocomplete.k-state-active,
.k-picker-wrap.k-state-active,
.k-numeric-wrap.k-state-active,
.k-dropdown-wrap.k-state-active,
.k-state-active,
.k-state-active:hover,
.k-state-active>.k-link,
.k-panelbar>.k-item>.k-state-focused
{
    .composite-background(@active-gradient);
}

.k-state-selected,
.k-draghandle.k-state-selected:hover
{
    .composite-background(@selected-gradient);
}

.k-draghandle.k-state-selected:hover
{
    background-position: 50% 50%;
}

.k-state-hover>.k-link,
.k-other-month.k-state-hover .k-link,
div.k-filebrowser-dropzone em
{
    color: @hover-text-color;
}

.k-autocomplete.k-state-hover,
.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-hover,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-hover,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-hover,
.k-dropdown-wrap.k-state-focused
{
    color: @hover-text-color;
    background-color: @select-hover-background-color;
    .composite-background(@hover-gradient);
    background-position: 50% 50%;
    border-color: @select-border-color;
}

.k-dropdown .k-state-hover .k-input,
.k-dropdown .k-state-focused .k-input
{
    color: @hover-text-color;
}

.k-state-error
{
    border-color: @error-border-color;
    background-color: @error-background-color;
    color: @error-text-color;
}

.k-state-disabled {
    opacity: .6;
}

.k-loading-mask.k-state-selected {
    border-width: 0;
    background-image: none;
    background-color: transparent;
}

.k-state-disabled,
.k-state-disabled .k-link,
.k-other-month,
.k-other-month .k-link,
.k-dropzone em,
.k-slider .k-draghandle
{
    color: @disabled-text-color;
}

.k-dropzone .k-upload-status
{
    color: @normal-text-color;
}
.k-file .k-upload-status {
    color: @normal-text-color;
}

.k-upload-status .k-button:hover .k-i-delete,
.k-upload-status .k-button:hover .k-i-delete {
    background-color: @accent;
}

// Progressbar

.k-progressbar-indeterminate
{
    background: url('@{image-folder}/indeterminate.gif');
}

.k-progressbar-indeterminate .k-progress-status-wrap,
.k-progressbar-indeterminate .k-state-selected
{
    display: none;
}

.k-progressbar-horizontal > .k-state-selected,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected,
.k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected,
.k-rtl .k-progressbar-horizontal > .k-state-selected,
.k-progressbar-vertical > .k-state-selected,
.k-progressbar-vertical.k-progressbar-reverse > .k-state-selected,
.k-progressbar > .k-state-selected.k-complete,
.k-rtl .k-progressbar > .k-state-selected.k-complete,
.k-progressbar-horizontal .k-first,
.k-rtl .k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last,
.k-progressbar-horizontal .k-last,
.k-rtl .k-progressbar-horizontal .k-first,
.k-progressbar-vertical .k-first,
.k-progressbar-vertical .k-last
{
    border-radius: 0;
}

// Slider
.k-slider-track
{
    background-color: @widget-border-color;
}

.k-slider-selection
{
    background-color: @selected-background-color;
}

.k-slider-horizontal .k-tick
{
    background-image:url('@{image-folder}/slider-h.gif');
}

.k-slider-vertical .k-tick
{
    background-image:url('@{image-folder}/slider-v.gif');
}

// Tooltip
.k-chart-crosshair-tooltip,
.k-chart-shared-tooltip
{
    border-color: @tooltip-border;
    background-color: @tooltip-bg;
    color: @tooltip-text;
}

// Splitter
.k-splitbar
{
    background-color: @splitbar-background-color;
}

.k-restricted-size-vertical,
.k-restricted-size-horizontal
{
    background-color: @error-text-color;
}

// Upload
.k-dropzone {}
.k-file {
    border-color: @cell-border-color;
}
.k-file:last-child {
    border-radius: 0 0 @border-radius @border-radius;
}

.k-file-progress .k-progress {
    background-color: @upload-progress-background-color;
}

.k-file-success {

    .k-file-validation-message,
    .k-upload-pct {
        color: @upload-success-text-color;
    }

    .k-progress {
        background-color: @upload-success-background-color;
    }
}

.k-file-error,
.k-file-invalid  {

    .k-file-validation-message,
    .k-upload-pct {
        color: @upload-error-text-color;
    }

    .k-progress {
        background-color: @upload-error-background-color;
    }
}

.k-file-extension-wrapper,
.k-multiple-files-extension-wrapper,
.k-file-group-wrapper,
.k-multiple-files-group-wrapper,
.k-file-invalid-group-wrapper {
    color: @disabled-text-color;
    border-color: @disabled-text-color;
}

.k-file-extension-wrapper:before,
.k-multiple-files-extension-wrapper:before {
    background-color: @base;
    border-color: transparent transparent @disabled-text-color @disabled-text-color;
}


.k-multiple-files-extension-wrapper:after {
    border-top-color: @disabled-text-color;
    border-left-color: @disabled-text-color;
}

.k-file-size,
.k-file-information,
.k-file-validation-message {
    color: @disabled-text-color;
}

.k-textbox:hover {
    border-color: @hover-border-color;
}

// TreeMap

.k-leaf,
.k-leaf.k-state-hover {
    color: #fff;
}

.k-leaf.k-inverse,
.k-leaf.k-inverse.k-state-hover {
    color: #000;
}

// Shadows
.k-widget
{
    .box-shadow(@widget-shadow);
}

.k-slider,
.k-treeview,
.k-upload
{
    .box-shadow(none);
}

.k-state-hover
{
    .box-shadow(@hover-shadow);
}

.k-textbox:focus,
.k-textbox.k-state-focused,
.k-autocomplete.k-state-focused,
.k-dropdown-wrap.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused
{
    .box-shadow(@focused-shadow);
}

.k-state-selected
{
    .box-shadow(@selected-shadow);
}

.k-state-active
{
    .box-shadow(@active-shadow);
}

.k-popup,
.k-menu .k-menu-group,
.k-grid .k-filter-options,
.k-time-popup,
.k-datepicker-calendar,
.k-autocomplete.k-state-border-down,
.k-autocomplete.k-state-border-up,
.k-dropdown-wrap.k-state-active,
.k-picker-wrap.k-state-active,
.k-multiselect.k-state-focused,
.k-filebrowser .k-image {
    .box-shadow(0 2px 2px 0 @shadow-color);
}

.k-treemap-tile.k-state-hover
{
    .box-shadow(inset 0 0 0 3px @widget-border-color);
}

.k-shadow
{
    .box-shadow(0 1px 2px 0 @shadow-color);
}

.k-inset
{
    .box-shadow(inset 0 1px 1px @shadow-color);
}

// Selection
.k-editor-inline {
    .selection(@selected-border-color);
}
.k-window-content.editorToolbarWindow {
    color: @toolbar-text;
    background-color: @toolbar-bg;
}

// Notification
@import "themes/notification.less";

// Gantt

.k-gantt .k-treelist
{
    background: @alt-background-color;
}

.k-gantt .k-treelist .k-alt
{
    background-color: darken(@alt-background-color, 10%);
}

.k-gantt .k-treelist tr:hover {
    background-color: @hover-background;
}

.k-gantt .k-treelist .k-state-selected,
.k-gantt .k-treelist .k-state-selected td,
.k-gantt .k-treelist .k-alt.k-state-selected,
.k-gantt .k-treelist .k-alt.k-state-selected > td
{
    background-color: @selected-background-color;
}

.k-gantt .k-treelist .k-state-selected:hover,
.k-gantt .k-treelist .k-state-selected:hover td
{
    background-color: @hover-background;
}

.k-task-dot:after
{
    background-color: @widget-text-color;
    border-color: @widget-text-color;
}

.k-task-dot:hover:after
{
    background-color: @active-background-color;
}

.k-task-summary
{
    border-color: @task-summary-color;
    background: @task-summary-color;
}

.k-task-milestone,
.k-task-summary-complete
{
    border-color: @widget-text-color;
    background: @widget-text-color;
}

.k-state-selected.k-task-summary
{
    border-color: @task-summary-selected-color;
    background: @task-summary-selected-color;
}

.k-state-selected.k-task-milestone,
.k-state-selected .k-task-summary-complete
{
    border-color: @selected-background-color;
    background: @selected-background-color;
}

.k-task-single
{
    background-color: lighten(@event-background-color, 7%);
    border-color: @event-background-color;
    color: @event-text-color;
}

.k-state-selected.k-task-single
{
    border-color: @selected-border-color;
}

.k-gantt-line
{
    background-color: @widget-text-color;
    color: @widget-text-color;
}

.k-state-selected.k-gantt-line
{
    background-color: @selected-background-color;
    color: @selected-background-color;
}

.k-resource
{
    background-color: @widget-background-color;
}

// Border radius
.k-block,
.k-textbox,
.k-drag-clue,
.k-touch-scrollbar,
.k-inline-block,
.k-grid .k-filter-options,
.k-grouping-header .k-group-indicator,
.k-autocomplete,
.k-multiselect,
.k-combobox,
.k-dropdown,
.k-dropdown-wrap,
.k-datepicker,
.k-timepicker,
.k-colorpicker,
.k-datetimepicker,
.k-numerictextbox,
.k-picker-wrap,
.k-numeric-wrap,
.k-list-container,
.k-calendar-container,
.k-treeview .k-in,
.k-editor-inline,
.k-slider-track,
.k-slider-selection,
.k-upload
{
    border-radius: @border-radius;
}

.k-calendar-container.k-state-border-up,
.k-list-container.k-state-border-up,
.k-autocomplete.k-state-border-up,
.k-multiselect.k-state-border-up,
.k-dropdown-wrap.k-state-border-up,
.k-picker-wrap.k-state-border-up,
.k-numeric-wrap.k-state-border-up,
.k-filter-menu
{
    border-radius: 0 0 @border-radius @border-radius;
}

.k-autocomplete.k-state-border-up .k-input,
.k-dropdown-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-input,
.k-picker-wrap.k-state-border-up .k-selected-color,
.k-numeric-wrap.k-state-border-up .k-input
{
    border-radius: 0 0 0 @border-radius;
}

.k-multiselect.k-state-border-up .k-multiselect-wrap
{
    border-radius: 0 0 @border-radius @border-radius;
}

.k-block > .k-header,
.k-tabstrip-items .k-item,
.k-panelbar .k-tabstrip-items .k-item,
.k-tabstrip-items .k-link,
.k-calendar-container.k-state-border-down,
.k-list-container.k-state-border-down,
.k-autocomplete.k-state-border-down,
.k-multiselect.k-state-border-down,
.k-dropdown-wrap.k-state-border-down,
.k-picker-wrap.k-state-border-down,
.k-numeric-wrap.k-state-border-down
{
    border-radius: @border-radius @border-radius 0 0;
}

.k-dropdown-wrap .k-input,
.k-picker-wrap .k-input,
.k-numeric-wrap .k-input
{
    border-radius: @inner-border-radius 0 0 @inner-border-radius;
}

.k-rtl .k-dropdown-wrap .k-input,
.k-rtl .k-picker-wrap .k-input,
.k-rtl .k-numeric-wrap .k-input
{
    border-radius: 0 @inner-border-radius @inner-border-radius 0;
}

.k-numeric-wrap .k-link
{
    border-radius: 0 @inner-border-radius 0 0;
}

.k-numeric-wrap .k-link + .k-link
{
    border-radius: 0 0 @inner-border-radius 0;
}

.k-colorpicker .k-selected-color
{
    border-radius: @inner-border-radius 0 0 @inner-border-radius;
}

.k-rtl .k-colorpicker .k-selected-color
{
    border-radius: 0 @inner-border-radius @inner-border-radius 0;
}

.k-autocomplete.k-state-border-down .k-input
{
    border-radius: @border-radius @border-radius 0 0;
}

.k-dropdown-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-input,
.k-picker-wrap.k-state-border-down .k-selected-color,
.k-numeric-wrap.k-state-border-down .k-input
{
    border-radius: @border-radius 0 0 0;
}

.k-numeric-wrap .k-link.k-state-selected
{
    background-color: @numeric-selected-background;
}

.k-multiselect.k-state-border-down .k-multiselect-wrap
{
    border-radius: @inner-border-radius @inner-border-radius 0 0;
}

.k-dropdown-wrap .k-select,
.k-picker-wrap .k-select,
.k-numeric-wrap .k-select,
.k-datetimepicker .k-select+.k-select,
.k-list-container.k-state-border-right
{
    border-radius: 0 @border-radius @border-radius 0;
}

.k-rtl .k-dropdown-wrap .k-select,
.k-rtl .k-picker-wrap .k-select,
.k-rtl .k-numeric-wrap .k-select,
.k-rtl .k-datetimepicker .k-select+.k-select,
.k-rtl .k-list-container.k-state-border-right
{
    border-radius: @border-radius 0 0 @border-radius;
}

.k-numeric-wrap.k-expand-padding .k-input
{
    .guarded-border-radius(@border-radius);
}

.k-textbox > input,
.k-autocomplete .k-input,
.k-multiselect-wrap
{
    .guarded-border-radius(@inner-border-radius);
}

.k-list .k-state-hover,
.k-list .k-state-focused,
.k-list .k-state-highlight,
.k-list .k-state-selected,
.k-fieldselector .k-list .k-item,
.k-list-optionlabel,
.k-dropzone,
.k-listbox .k-item
{
    .guarded-border-radius(@list-border-radius);
}

.k-slider .k-button,
.k-grid .k-slider .k-button
{
    border-radius: @slider-border-radius;
}

.k-draghandle
{
    border-radius: @draghandle-border-radius;
}

.k-more-events,
.k-event,
.k-task-single,
.k-task-complete,
.k-event .k-link
{
    border-radius: @inner-border-radius;
}

.k-scheduler-mobile .k-event
{
    border-radius: (@inner-border-radius - 1);
}

// /Column Menu

.k-autocomplete,
.k-picker-wrap.k-state-default,
.k-numeric-wrap.k-state-default,
.k-dropdown-wrap.k-state-default,
.k-colorpicker .k-picker-wrap
{
    .composite-background(@widget-gradient);
    background-position: 50% 50%;
    background-color: @drop-down-background;
    border-color: @drop-down-border-color;
}

.k-autocomplete.k-state-hover,
.k-picker-wrap.k-state-hover,
.k-numeric-wrap.k-state-hover,
.k-dropdown-wrap.k-state-hover
{
    background-color: @select-hover-background-color;
    .composite-background(@hover-gradient);
    background-position: 50% 50%;
    border-color: @drop-down-hover-border-color;
}

.k-multiselect-wrap
{
    border-color: @drop-down-border-color;
}

.k-multiselect-wrap.k-state-hover,
.k-state-hover > .k-multiselect-wrap,
.k-multiselect-wrap.k-state-hover
{
    border-color: @drop-down-hover-border-color;
    background: @input-bg;
    color: @input-text;
}

.k-autocomplete.k-state-focused,
.k-picker-wrap.k-state-focused,
.k-numeric-wrap.k-state-focused,
.k-dropdown-wrap.k-state-focused
{
    background-color: @select-hover-background-color;
    .composite-background(@hover-gradient);
    background-position: 50% 50%;
    border-color: @drop-down-focused-border-color;
    .box-shadow(@focused-shadow);
}
.k-multiselect-wrap.k-state-focused,
.k-state-focused > .k-multiselect-wrap
{
    border-color: @drop-down-focused-border-color;
    .box-shadow(@focused-shadow);
}

.k-list-container
{
    color: @drop-down-text-color;
}

.k-nodata {
    color: @disabled-text-color;
}

.k-dropdown .k-input,
.k-menu .k-popup
{
    color: @drop-down-text-color;
}

.k-state-default > .k-select
{
    border-color: @drop-down-border-color;
}

.k-state-hover > .k-select
{
    border-color: @drop-down-hover-border-color;
}

.k-state-focused > .k-select
{
    border-color: @drop-down-focused-border-color;
}

.k-tabstrip:focus
{
    .box-shadow(@focused-shadow);
}

.k-tabstrip-items .k-state-default .k-link,
.k-panelbar > li.k-state-default > .k-link
{
    color: @tabstrip-tabs-color;
}

.k-tabstrip-items .k-state-hover .k-link,
.k-panelbar > li > .k-link.k-state-hover,
.k-panelbar > li.k-state-hover > .k-link,
.k-panelbar>li.k-state-default>.k-link.k-state-hover
{
    color: @hover-text-color;
}

.k-panelbar .k-state-focused.k-state-hover
{
    background: @hover-background-color;
    .box-shadow(none);
}

.k-tabstrip-items .k-state-default
{
    border-color: @tabstrip-items-border;
}

.k-tabstrip-items .k-state-hover
{
    border-color: @hover-border-color;
}

.k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-items .k-state-active
{
    background-color: @active-background-color;
    .composite-background(@active-gradient);
    border-color: @active-border-color;
}

.k-tabstrip-top > .k-tabstrip-items .k-state-active,
.k-panelbar .k-tabstrip-top > .k-tabstrip-items .k-state-active {
    border-bottom-color: @active-background-color;
}

.k-tabstrip .k-content.k-state-active
{
    background-color: @tabstrip-active-background;
    color: @widget-text-color;
}

.k-tabstrip .k-content:focus,
.k-tabstrip .k-content.k-state-focused {
    outline-color: @tabstrip-content-border-focused;
}

.k-menu,
.k-menu .k-item
{
    border-color: @menu-border-color;
}

.k-column-menu,
.k-column-menu .k-item,
.k-grid-columnmenu-popup,
.k-columnmenu-item-wrapper {
    border-color: @secondary-border-color;
}

.k-column-menu .k-separator
{
    border-color: @secondary-border-color;
    background-color: transparent;
}

.k-column-menu-group-header-text {
    color: @normal-text-color;
    border-color: @normal-text-color;
}

.k-menu .k-group
{
    border-color: @group-border-color;
}

.k-grid-filter.k-state-active
{
    background-color: @active-filter-background-color;
}

.k-grouping-row td,
.k-group-footer td,
.k-grid-footer td
{
    color: @secondary-text-color;
    border-color: @secondary-border-color;
    font-weight: bold;
}

.k-grouping-header
{
    color: @secondary-text-color;
}

.k-grid tr:hover,
.k-grid td.k-state-selected:hover {
    color: @hover-text-color;
    background-color: @hover-background;
}

.k-grid .k-filter-row:hover,
.k-pivot-rowheaders .k-grid tr:hover {
    color: @widget-text-color;
    background: none;
}

.k-grid td.k-state-focused {
    box-shadow: inset 0 0 0 1px contrast(@base, darken(@hover-background, 6%), lighten(@hover-background, 86%), 0.5);
}

.k-grid tr:hover .k-state-focused,
.k-grid tr .k-state-focused.k-state-selected,
.k-grid tr.k-state-selected td.k-state-focused,
.k-grid tr:hover .k-state-focused.k-state-selected,
.k-grid tr.k-state-selected:hover td.k-state-focused {
    box-shadow: inset 0 0 0 1px contrast(@base, darken(@hover-background, 25%), lighten(@hover-background, 86%), 0.5);
}

.k-header,
.k-grid-header-wrap,
.k-grid .k-grouping-header,
.k-grid-header,
.k-grouping-header .k-group-indicator,
.k-gantt-toolbar .k-state-default
{
    border-color: @secondary-border-color;
}

// Fix for responsive design
.k-treeview .k-in
{
    border-color: transparent;
}

.k-treeview .k-icon,
.k-scheduler-table .k-icon,
.k-grid .k-hierarchy-cell .k-icon
{
    background-color: @icon-background-color;
    border-radius: @border-radius;
}

.k-scheduler-table .k-state-hover .k-icon
{
    background-color: transparent;
}

// Metro Theme

.k-chart .k-mask {
    background-color: @normal-background;
    opacity: 0.70;
}

.k-chart .k-selection {
    border-color: #c7c7c7;
    box-shadow: inset 0 1px 12px rgba(0,0,0,.05);
    transition: box-shadow .2s linear, border-color .2s linear;
}

.k-chart .k-selection:hover {
    border-color: @hover-background;
    box-shadow: inset 0 0 20px fadeout(@hover-background, 50%);
}

.k-chart .k-handle {
    background-color: #c7c7c7;
    width: 1px;
}

.k-chart .k-handle:hover {
    background-color: @hover-background;
    width: 3px;
    border-radius: @border-radius;
}

.k-chart .k-navigator-hint .k-tooltip {
    border: 0;
    box-shadow: 0 1px 3px rgba(0,0,0,.3);
    background: @hover-background;
    color: #ffffff;
    border-radius: @border-radius;
}

.k-chart .k-navigator-hint .k-scroll {
    background: fadeout(@hover-background, 50%);
    height: 4px;
    border-radius: @border-radius;
}

.k-chart-crosshair-tooltip,
.k-chart-shared-tooltip {
    border-radius: @border-radius;
}

// Map
.k-map {
    border-color: @widget-border-color;
    color: @widget-text-color;
    background-color: @widget-background-color;


    // Controls
    .k-navigator {
        border-color: @button-border-color;
        color: @button-text-color;
        background-color: @button-background-color;
        background-position: 50% 50%;
        .composite-background(@button-gradient);
        .box-shadow(0 1px 2px 0 @shadow-color);
    }
    .k-zoom-control {
        .box-shadow(0 1px 2px 0 @shadow-color);
    }


    // Attribution
    .k-attribution {}


    // Marker
    .k-marker {
        color: @accent;
    }
}

@import "themes/spreadsheet.less";

@spreadsheet-page-orientation-border-color: @button-border-color;

.k-numeric-wrap .k-i-warning {
    color: @input-validation-error;
    position: absolute;
    top: 0;
    right: 1.9em;
    width: 1.9em;
}

.k-numeric-wrap.k-state-invalid {
    border-color: @input-validation-error;

    input {
        color: @input-validation-error;
    }
}

.k-rtl .k-numeric-wrap.k-state-invalid .k-i-warning {
    right: auto;
    left: 1.9em;
}

.k-maskedtextbox.k-state-invalid {
    .k-textbox {
        border-color: @input-validation-error;
        color: @input-validation-error;
    }

    .k-i-warning {
        color: @input-validation-error;
    }
}

.k-dateinput.k-state-invalid {
    .k-textbox {
        color: @input-validation-error;
        border-color: @input-validation-error;
    }

    .k-i-warning {
        margin-left: 0;
        margin-right: 0.6em;
        color: @input-validation-error;
    }
}

.k-rtl .k-dateinput .k-i-warning {
    margin-right: 0;
    margin-left: 0.6em;
}

.k-datepicker .k-picker-wrap,
.k-timepicker .k-picker-wrap {
    &.k-state-invalid {
        border-color: @input-validation-error;

        .k-input {
            color: @input-validation-error;
        }
    }

    .k-i-warning {
        color: @input-validation-error;
        margin-left:0;
        margin-right: 2.1em;
    }
    .k-rtl & {
        .k-i-warning {
            margin-right: 0;
            margin-left: 2.1em;
        }
    }
}

.k-datetimepicker .k-picker-wrap {
    &.k-state-invalid {
        border-color: @input-validation-error;

        .k-input {
            color: @input-validation-error;
        }
    }

    .k-i-warning {
        color: @input-validation-error;
        margin-left:0;
        margin-right: 4.3em;
    }

    .k-rtl & {
        .k-icon.k-i-warning {
            margin-right: 0;
            margin-left: 4.3em;
        }
    }
}

// Timepicker infinite
// Timepicker header
.k-time-header {

    .k-time-now {
        color: @accent;
        background: transparent;

        &:hover,
        &:focus {
            color: @link-hovered-text;
        }
    }
}

// Timepicker content
.k-time-list-wrapper {
    background-color: @group-background-color;

    .k-title {
        .fill(
            @disabled-text-color,
            @header-background-color,
            @widget-border-color,
        );

        background: @group-background-color;
    }

    &.k-state-focused {
        .k-title {
            color: @time-list-title-focus;
            opacity: 1;
        }

        &::before,
        &::after {
            background-color: @time-list-focused-bg;
        }
    }
}

.k-time-list {
    &::before,
    &::after {
        @shadow-size: 3em;
        box-shadow: 0 0 @shadow-size (@shadow-size / 2) @group-background-color;
    }

    .k-item:hover {
        color: @accent;
    }
}

.k-time-container {
    background: transparent;
}

.k-time-highlight {
    background-color: @component-bg;
    border-color: @time-list-highlight-color;
}

.k-datetime-container {
    .k-date-tab {

        .k-datetime-buttongroup,
        .k-datetime-selector {
            background-color: @component-bg;
        }
    }
}

.k-listbox {
    .k-list-scroller {
        padding: 2px;
        border-color: @widget-border-color;
        background-color: @input-bg;
    }

    .k-item:hover:not(.k-state-disabled) {
        color: @hover-text-color;
        background-color: @hover-background-color;
        border-color: transparent;
    }

    .k-drop-hint {
        height: 0;
        border-top: 1px solid @accent;
    }
}

.k-grid-header {
    .k-i-sort-asc-sm,
    .k-i-sort-desc-sm,
    .k-sort-order {
        color: @accent;
    }
}


// Dropdowngrid
.k-dropdowngrid-popup {
    border-color: @grid-border;
    color: @grid-text;
    background-color: @grid-bg;
}
.k-dropdowngrid-popup .k-header {
    border-color: @grid-header-border;
    color: @grid-header-text;
    background-color: @grid-header-bg;
}
.k-dropdowngrid-popup .k-group-header {
    border-color: @grid-header-border;
    color: @grid-header-text;
    background-color: @grid-header-bg;
}
.k-dropdowngrid-popup .k-cell {
    border-color: @grid-border;
}
.k-dropdowngrid-popup .k-item:nth-child(2n) {
    background-color: @grid-alt-bg;
}
.k-dropdowngrid-popup .k-footer {
    border-color: @grid-header-border;
    color: @grid-header-text;
    background-color: @grid-header-bg;
}
.k-dropdowngrid-popup .k-item.k-state-hover {
    border-color: @grid-hover-border;
    color: @grid-hover-text;
    background-color: @grid-hover-bg;
}
.k-dropdowngrid-popup .k-item.k-state-selected {
    border-color: @grid-selected-border;
    color: @grid-selected-text;
    background-color: @grid-selected-bg;
}
.k-dropdowngrid-popup .k-group-cell span {
    background-color: @grid-text;
    color: @grid-bg;
}
.k-grid-list > .k-item.k-last > .k-cell,
.k-grid-list > .k-item.k-last > .k-group-cell,
.k-grid-list > .k-item.k-last > .k-spacer-cell {
    border-bottom-color: @grid-text;
}


.k-calendar {
    .k-header .k-link,
    .k-content td,
    .k-content .k-link {
        border-radius: 0;
    }
}

// Grid Sticky columns
.k-master-row {
    .k-grid-content-sticky {
        background-color: @grid-bg;
    }

    &.k-alt .k-grid-content-sticky {
        background-color: @grid-alt-bg;
    }

    &.k-state-selected .k-grid-content-sticky {
        .composite-background(@grid-selected-gradient);
        background-color: @grid-selected-bg;
        background-position: 50% 50%;
    }

    &:hover .k-grid-content-sticky,
    &.k-state-hover .k-grid-content-sticky {
        .composite-background(@grid-hover-gradient);
        background-color: @grid-hover-bg;
    }

    &.k-state-selected:hover .k-grid-content-sticky,
    &.k-state-selected.k-state-hover .k-grid-content-sticky {
        background-color: @grid-selected-hover-bg;
        .composite-background(none);
    }
}

.k-grid-header .k-grid-header-sticky,
.k-master-row .k-grid-content-sticky,
.k-grouping-row .k-grid-content-sticky,
.k-footer-template .k-grid-footer-sticky {
    border-left-color: @grid-sticky-border;
    border-right-color: @grid-sticky-border;
}

.k-grid-header .k-filter-row .k-grid-header-sticky {
    background-color: @header-background-color;
}

// Locked columns
.k-grid-header-locked,
.k-grid-content-locked,
.k-grid-header-locked .k-header,
.k-grid-content-locked td {
    border-left-color: @grid-sticky-border;
    border-right-color: @grid-sticky-border;
}

// Grid Multi Filter Select All Checkbox
.k-check-all-wrap {
    border-color: @widget-border-color;
}
