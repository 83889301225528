/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Dialog
.k-dialog {
    min-width: 90px;
    min-height: 3em;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    position: fixed;
    overflow: hidden;

    // Centered
    &-centered {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
}


// Titlebar
.k-dialog-titlebar {}
.k-dialog-title {}


// Titlebar actions
.k-dialog-titlebar .k-dialog-actions {}
.k-dialog-titlebar .k-dialog-close {}
.k-dialog-titleless .k-dialog-close {
    position: absolute;
    z-index: 9999;
    top: (@window-titlebar-padding-y / 2);
    right: (@window-titlebar-padding-y / 2);
}


// Content
.k-dialog-titleless .k-dialog-content {
    padding: 2.4em;
}


// Buttons
.k-dialog-buttongroup {
    margin: 0;
    padding: (@window-inner-padding-y / 2) (@window-inner-padding-x / 2);
    width: 100%;
    border-width: 1px 0 0;
    border-style: solid;
    box-sizing: border-box;

    .k-button {
        flex-shrink: 0;
        display: inline-block;
        text-overflow: ellipsis;
        box-sizing: border-box;
    }
}


// RTL
.k-rtl.k-dialog-titleless .k-dialog-close,
.k-rtl .k-dialog-titleless .k-dialog-close {
    right: auto;
    left: (@window-titlebar-padding-y / 2);
}
