/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Delayed tasks
.k-task-delayed {
    &.k-task-milestone {
        border-color: transparent;
        .repeating-striped-gradient( @gantt-delayed-bg, #FFFFFF, 90deg, 4px, 2px);
    }

    &.k-task-summary {
        background-color: @gantt-delayed-bg-lighter;
        border-color: @gantt-delayed-bg-lighter;

        .k-task-summary-complete {
            background-color: @gantt-delayed-bg;
            border-color: @gantt-delayed-bg;
        }

        &.k-state-selected .k-task-summary-complete {
            background-color: @gantt-summary-completed-selected-bg;
            border-color:@gantt-summary-completed-selected-bg;
        }
    }

    &.k-task-single {
        background-color: @gantt-delayed-bg-lighter;
        color: @gantt-delayed-advanced-text;

        &.k-state-selected {
            background-color: @gantt-task-selected-bg;
            color: @event-text-color;
        }
    }

    .k-task-complete {
        background-color: @gantt-delayed-bg;
    }

    &.k-state-selected .k-task-complete {
        background-color: @gantt-task-completed-selected-bg;
    }
}

// Advanced tasks
.k-task-advanced {
    &.k-task-milestone {
        background-color: @gantt-advanced-bg;
        border-color: transparent;
    }

    &.k-task-summary {
        background-color: @gantt-advanced-bg-lighter;
        border-color: @gantt-advanced-bg-lighter;

        .k-task-summary-complete {
            background-color: @gantt-advanced-bg;
            border-color: @gantt-advanced-bg;
        }

        &.k-state-selected .k-task-summary-complete {
            background-color: @gantt-summary-completed-selected-bg;
            border-color: @gantt-summary-completed-selected-bg;
        }
    }

    &.k-task-single {
        background-color: @gantt-advanced-bg-lighter;
        color: @gantt-delayed-advanced-text;

        &.k-state-selected {
            background-color: @gantt-task-selected-bg;
            color: @event-text-color;
        }
    }

    .k-task-complete {
        background-color: @gantt-advanced-bg;
    }

    &.k-state-selected .k-task-complete {
        background-color: @gantt-task-completed-selected-bg;
    }
}

// Planned element
.k-task-planned {
    .k-task-moment {
        border-color: @gantt-planned-border;
    }
    .k-task-duration {
        background-color: @gantt-planned-bg;
    }
    &:hover .k-task-moment {
        background-color: @gantt-planned-bg;
    }
}

// Delay offset
.k-task-offset {
    .repeating-striped-gradient( @gantt-delayed-bg, #FFF, 135deg, 4px, 2px);

    .k-resize-handle::after {
        background-color: @gantt-task-offset-text;
    }

    &:hover {
        .repeating-striped-gradient( @gantt-delayed-bg-lighter, #FFF, 135deg, 4px, 2px);
    }

    .k-task-actions .k-link {
        color: @gantt-task-offset-text;
    }
}

.k-task-offset-wrap {
    .k-task-summary-complete:after,
    .k-task-summary:after {
        border-right-color: transparent;
    }
}

.k-rtl .k-task-offset-wrap  {
    .k-task-summary-complete:after,
    .k-task-summary:after {
        border-right-color: inherit;
    }

    .k-task-summary-complete:before,
    .k-task-summary:before {
        border-left-color: transparent;
    }
}

.k-summary-wrap .k-task-offset:after {
    border-color: transparent;
    border-right-color: @gantt-task-offset-border;
}

.k-rtl .k-summary-wrap .k-task-offset{
    &:before {
        border-color: transparent;
        border-left-color: @gantt-task-offset-border;
    }

    &:after {
        border-right-color: transparent;
    }
}

.k-pdf-export .k-task-offset {
    background-color: @gantt-delayed-bg;
}

// Tooltips
.k-offset-tooltip-delayed {
    background-color: @gantt-delayed-bg;
    color: @gantt-delayed-tooltip-text;
}

.k-planned-tooltip {
    background-color: @gantt-planned-bg;
    color: @gantt-planned-tooltip-text;
}
