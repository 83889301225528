/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-appbar {
    padding: @appbar-padding-y @appbar-padding-x;
    border-width: @appbar-border-width;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: @appbar-font-family;
    font-size: @appbar-font-size;
    line-height: @appbar-line-height;
    list-style: none;
    display: flex;
    gap: @appbar-gap;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    background-clip: padding-box;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );

    > * {
        flex-shrink: 0;

    }

    // Appbar section
    .k-appbar-section {
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
    }

    // Appbar Spacer
    .k-appbar-spacer {
        flex: 1 0 auto;
    }

    .k-appbar-spacer-sized {
        flex-grow: 0;
    }

    // Separator
    .k-appbar-separator {
        margin: 0;
        width: 0;
        min-height: 1em;
        border-style: solid;
        border-width: 0 0 0 1px;
        display: inline-block;
    }
}

.k-appbar-static {
    position: static;
}

.k-appbar-sticky {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.k-appbar-fixed {
    width: 100%;
    position: fixed;
    z-index: 1;
}

.k-appbar-top {
    top: 0;
    bottom: auto;
}

.k-appbar-bottom {
    top: auto;
    bottom: 0;

    &.k-appbar-fixed {
        bottom: 0;
    }
}

// TODO: Remove once we drop IE support
.k-ie {
    background-clip: border-box;
}
