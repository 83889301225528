/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "Default/_variables.less";

@input-height: 1.65em;
@input-default-width: 12.4em;
@input-border-width: 1px;
@input-padding-x: 0;
@input-padding-y: 0.17857143em;
@input-indent: .571em;
@input-offset: (@input-height + .35em);

@line-height: (20 / 14);

@icon-size: 16px;
@input-icon-width: calc(@input-height + .35em);

@combobox-padding-x: @input-offset;

@input-clear-icon-offset: ~"( (@{input-offset} - @{grid-size})/2 )";
@combobox-clear-icon-offset: ~"( @{input-clear-icon-offset} + @{input-offset} )";
@multiselect-clear-icon-offset-with-arrow: @input-icon-width;
@multiselect-clear-icon-top-offset: ~"( (@{input-height} - @{grid-size}) / 2 + @{input-padding-y} + 1px )";

@editor-icon-size: 2em;
@menu-link-padding: .5em 1.1em .4em;
@menu-vertical-link-padding: 2em;


// Messagebox
@messagebox-margin: 0 0 16px 0;
@messagebox-padding-x: 8px; //@padding-x;
@messagebox-padding-y: 4px; //@padding-y;
@messagebox-border-width: 0 0 0 4px;

@messagebox-font-size: inherit; //@font-size;
@messagebox-line-height: (20 / 14);//@line-height;

@messagebox-link-font-style: italic;
@messagebox-link-text-decoration: underline;


// Form
@form-font-size: inherit;
@form-line-height: (20 / 14);

@form-label-margin: 0 0 4px;

@form-fieldset-margin: 32px 0 0;
@form-fieldset-padding-x: 0;
@form-fieldset-padding-y: 0;

@form-legend-margin: 0 0 16px;
@form-legend-padding-x: 0;
@form-legend-padding-y: 0;
@form-legend-font-size: inherit;
@form-legend-line-height: (20 / 14);

@form-field-margin: 0 0 16px;

@form-hint-margin: 0 0 4px;
@form-hint-font-size: 12px;

@form-buttons-margin: 16px 0 0;
@form-buttons-padding-x: 0;
@form-buttons-padding-y: 0;
@form-buttons-border-width: 0;
@form-buttons-spacing: 8px;

@horizontal-form-label-margin-x: 10px;
@horizontal-form-label-padding-top: 5px;
@horizontal-form-label-width: 25%;
@horizontal-form-label-align: flex-end;


// Button
@button-padding-x: 8px;
@button-padding-y: 4px;
@button-padding: @button-padding-y @button-padding-x;
@button-border-width: 1px;

@button-line-height: (20 / 14);

@button-calc-size: ~"calc( (@{button-line-height} * 1em) + (@{button-padding-y} * 2) + (@{button-border-width} * 2) )";

@disabled-button-opacity: 0.6;



// Toolbar
@toolbar-padding-x: 4px;
@toolbar-padding-y: 4px;
@toolbar-border-width: 1px;
@toolbar-border-radius: 0;

@toolbar-spacing: @toolbar-padding-x;

@toolbar-font-size: inherit;
@toolbar-line-height: (20 / 14);

@toolbar-calc-inner-size: ~"calc( @{button-calc-size} + (@{toolbar-padding-y} * 2) )";

@treeview-inner-padding: 1px 0.3333em 1px 0.25em;
@scheduler-link-padding: 0 1.1em;
@grid-header-padding: .22em .2em .28em;
@grid-th-padding: .5em .6em .4em .6em;
@icon-positioning: "default";

@window-border-width: 1px;
@window-border-style: solid;

@window-sizes: {
    sm: 300px;
    md: 800px;
    lg: 1200px;
};

@window-titlebar-padding-x: 12px;
@window-titlebar-padding-y: 8px;
@window-titlebar-border-width: 0 0 1px;
@window-titlebar-border-style: solid;

@window-title-font-size: 1.2em;
@window-title-line-height: 1.25;
@window-title-font-weight: normal;

@window-inner-padding-x: @window-titlebar-padding-x;
@window-inner-padding-y: @window-inner-padding-x;

@grid-padding-x: 8px;
@grid-padding-y: 6px;

@grid-header-padding-x: @grid-padding-x;
@grid-header-padding-y: @grid-padding-y;

@grid-footer-padding-x: @grid-header-padding-x;
@grid-footer-padding-y: @grid-header-padding-y;

@grid-cell-padding-x: @grid-padding-x;
@grid-cell-padding-y: @grid-padding-y;

@grid-search-width: @input-default-width;

// Calendar
@calendar-padding-x: 0;
@calendar-padding-y: 0;
@calendar-border-width: 1px;
@calendar-border-radius: 4px;
@calendar-font-size: 14px;
@calendar-line-height: (20 / 14);

@calendar-header-padding-x: 2px;
@calendar-header-padding-y: 2px;
@calendar-header-border-width: 1px;

@calendar-content-padding-x: 0;
@calendar-content-padding-y: 0;

@calendar-cell-padding-x: .5em;
@calendar-cell-padding-y: .5em;
@calendar-cell-border-radius: @calendar-border-radius;
@calendar-cell-line-height: (20 / 14);
@calendar-cell-size: ( (34px / 14px ) * 1em );

@calendar-header-cell-padding-x: 2px;
@calendar-header-cell-padding-y: (@calendar-cell-padding-y / 2);
@calendar-header-cell-border-width: 1px;
@calendar-header-cell-font-size: 12px;
@calendar-header-cell-line-height: (20 / 14);
@calendar-header-cell-size: @calendar-cell-size;

@calendar-meta-cell-size: ( (60px / @calendar-font-size) * 1em );

@calendar-modern-header-padding-x: 16px;
@calendar-modern-header-padding-y: 8px;


// Multiviewcalendar
@multiviewcalendar-font-size: @calendar-font-size;
@multiviewcalendar-line-height: @calendar-line-height;

@multiviewcalendar-header-padding-x: 16px;
@multiviewcalendar-header-padding-y: 8px;

@multiviewcalendar-content-padding-x: 16px;
@multiviewcalendar-content-padding-y: 8px;

@multiviewcalendar-cell-padding-x: @calendar-cell-padding-x;
@multiviewcalendar-cell-padding-y: @calendar-cell-padding-y;
@multiviewcalendar-cell-border-radius: @calendar-border-radius;
@multiviewcalendar-cell-line-height: @calendar-cell-line-height;
@multiviewcalendar-cell-size: @calendar-cell-size;

@multiviewcalendar-centuryview-cell-padding-x: @multiviewcalendar-cell-padding-x;
@multiviewcalendar-centuryview-cell-padding-y: @multiviewcalendar-cell-padding-y;

@multiviewcalendar-header-cell-font-size: 12px;


// DateTimePickers
@timepicker-padding-x: 8px;
@timepicker-padding-y: 4px;

@time-highlight-size: 1px;

@time-header-padding: null;
@timepicker-header-height: 2em;

@time-list-padding: 20px;
@time-list-focus-size: 2px;
@time-list-width: 5em;
@time-list-height: calc( 7 * (@line-height * 1em + 2 * @timepicker-padding-y));

@time-font-size-xs: 10px;

@datetime-width: ((4 * @time-list-width) + 1em);


// ScrollView
@scrollview-border-width: 1px;
@scrollview-font-family: inherit;
@scrollview-font-size: inherit;
@scrollview-line-height: @line-height;

@scrollview-pagebutton-size: 10px;
@scrollview-navigation-size: 4.5em;

@scrollview-pager-offset: 0;
@scrollview-pager-item-spacing: 20px;
@scrollview-pager-item-border-width: 0;
@scrollview-pager-height: calc( @scrollview-pagebutton-size + (2 * @scrollview-pager-item-border-width) + (2 * @scrollview-pager-item-spacing) );
// Switch
@switch-size: 5em;
@switch-border-radius: @switch-size;
@switch-font-size: 12px;

@switch-track-size: null;
@switch-container-border-width: 1px;
@switch-container-padding-x: 2px;
@switch-container-padding-y: @switch-container-padding-x;

@switch-handle-size: (.4 * @switch-size);
@switch-handle-border-radius: @switch-size;
@switch-handle-border-width: 1px;

@switch-label-display: inline;
@switch-label-outer-spacing: 8px;
@switch-label-inner-spacing: 2px;
@switch-label-spacing: calc(@switch-label-outer-spacing + @switch-label-inner-spacing);

@switch-label-position: calc(@switch-label-outer-spacing - @switch-container-border-width);
@switch-label-width: calc(100% - @switch-handle-size - @switch-label-spacing - @switch-container-padding-x);
@switch-on-left-x: calc(100% - @switch-handle-size);


// Pager
@pager-padding-x: 4px;
@pager-padding-y: @pager-padding-x;
@pager-border-width: 1px;

@pager-section-spacing: 1em;

@pager-item-padding-x: @button-padding-y;
@pager-item-padding-y: @pager-item-padding-x;
@pager-item-width: 2.14em;
@pager-item-height: @pager-item-width;
@pager-item-border-width: 1px;
@pager-item-border-radius: 3em;
@pager-item-spacing: 2px;

@pager-input-width: 3em;

@pager-page-size-dropdown-width: 5em;


// Pager -- old vars
@pager-button-border-radius-sm: 3px;
@pager-button-border-radius-lg: 1.0833em;
@pager-button-width: 2em;
@pager-button-height: @pager-button-width;
@pager-button-margin: 0.08333em;


// Pdf Viewer
@pdf-viewer-page-spacing: 30px;

@pdf-viewer-action-buttons-top-offset: 10px;
@pdf-viewer-action-buttons-padding: 10px;

@pdf-viewer-search-dialog-input-button-height: @input-height;
@pdf-viewer-search-dialog-textbox-padding-x: @input-padding-y;

@pdf-viewer-search-dialog-matches-width: 4.5em;

@pdf-viewer-search-dialog-spacing: 0.28571428em;
@pdf-viewer-search-dialog-button-spacing: @pdf-viewer-search-dialog-spacing;


// Avatar
@avatar-size: 32px;
@avatar-rounded-radius: 5px;


// Card
@form-line-height: (20 / 14);
@card-title-font-size: 19px;
@card-subtitle-font-size: 12px;
@card-border-radius: 4px;
@card-border-width: 1px;
@card-header-padding-x: 16px;
@card-header-padding-y: 12px;
@card-header-border-width: 1px;
@card-footer-padding-x: 16px;
@card-footer-padding-y: 12px;
@card-footer-border-width: 1px;
@card-body-padding-x: 16px;
@card-body-padding-y: 12px;
@paragraph-margin-bottom: 8px;
@card-title-margin-bottom: 8px;
@card-subtitle-margin-bottom: 8px;
@card-actions-padding-x: 16px;
@card-actions-padding-y: 8px;
@card-actions-border-width: 1px;
@card-button-padding-x: 8px;
@card-button-padding-y: 8px;
@card-img-max-width: 100px;
@card-deck-gap: 16px;

@card-avatar-size: 45px;
@card-avatar-spacing: @card-header-padding-x;

@card-deck-scroll-button-radius: 0;
@card-deck-scroll-button-offset: -1px;

@card-callout-width: 20px;
@card-callout-height: 20px;


// TileLayout
@tilelayout-border-width: 0;

@tilelayout-hint-border-width: 1px;
@tilelayout-hint-border-radius: @card-border-radius;


// Drawer
@drawer-border-width: 1px;
@drawer-content-padding-x: 8px;
@drawer-content-padding-y: 8px;

@drawer-scrollbar-width: 7px;
@drawer-scrollbar-radius: 20px;

@drawer-item-padding-x: 8px;
@drawer-item-padding-y: 8px;
@drawer-item-font-size: 16px;
@drawer-item-line-height: 1.5;

@drawer-icon-size: 18px;
@drawer-mini-initial-width: calc(4 * @drawer-item-padding-x + @drawer-icon-size);


// Rating
@rating-container-margin-x: 4px;

@rating-item-padding-x: 4px;
@rating-item-padding-y: 4px;

@rating-label-margin-x: 8px;
@rating-label-margin-y: 4px;
@rating-label-line-height: 1.5;

@rating-font-size: 14px;
@rating-icon-size: 24px;


// Spreadsheet
@spreadsheet-insert-image-dialog-margin: 0 1em;
@spreadsheet-insert-image-dialog-border-style: dashed;
@spreadsheet-insert-image-dialog-border-width: 2px;

@spreadsheet-insert-image-dialog-preview-width: 355px;
@spreadsheet-insert-image-dialog-preview-height: 200px;
@spreadsheet-insert-image-dialog-preview-padding: 10px;
@spreadsheet-insert-image-dialog-text-padding: 10px;

@spreadsheet-drawing-outline-style: solid;
@spreadsheet-drawing-outline-width: 2px;

@spreadsheet-drawing-handle-width: 6px;
@spreadsheet-drawing-handle-height: 6px;
@spreadsheet-drawing-handle-border-style: solid;
@spreadsheet-drawing-handle-border-width: 1px;


// Notification
@notification-border-width: 1px;

@notification-padding-x: 0.5em;
@notification-padding-y: 0.6em;
@notification-padding-end: 20px;

@notification-icon-spacing: 4px;


// Filter
@filter-bottom-margin: 2.1em;
@filter-line-size: 1px;
@filter-padding-x: 8px;
@filter-padding-y: 4px;
@filter-operator-dropdown-width: 15em;


// Timeline
@timeline-border-radius: 2px;
@timeline-border-radius-lg: 4px;
@timeline-spacing-x: 40px;
@timeline-spacing-y: 40px;
@timeline-items-padding: 16px;
@timeline-mobile-spacing-x: 16px;
@timeline-mobile-spacing-y: 16px;

@timeline-track-arrow-width: 30px;
@timeline-track-arrow-height: 30px;

@timeline-track-size: 6px;
@timeline-track-wrap-padding-bottom: 3px;
@timeline-track-border-width: 1px;
@timeline-track-margin-bottom: 16px;
@timeline-track-bottom-calc: calc((@timeline-track-arrow-width / 2) + @timeline-track-wrap-padding-bottom);
@timeline-track-start-calc: calc(@timeline-track-arrow-width - 2 * @timeline-track-border-width);
@timeline-track-end-calc: calc(@timeline-track-arrow-width - 2 * @timeline-track-border-width);

@timeline-track-event-offset: 36px;

@timeline-flag-padding-x: @button-padding-x;
@timeline-flag-padding-y: @button-padding-y;
@timeline-flag-line-height: @button-line-height;
@timeline-flag-min-width: 80px;
@timeline-flag-max-width: calc(@timeline-flag-min-width + 2 * @timeline-spacing-x);
@timeline-mobile-flag-max-width: calc(@timeline-flag-min-width + 2 * @timeline-mobile-spacing-x);
@timeline-horizontal-flag-min-width: 60px;

@timeline-flag-callout-width: 10px;
@timeline-flag-callout-height: 10px;

@timeline-flag-offset-bottom: 4px;
@timeline-flag-margin-bottom-calc: calc(@timeline-track-size + 2 * @timeline-track-border-width + @timeline-flag-offset-bottom + @timeline-flag-callout-height);

@timeline-date-margin-bottom: 8px;
@timeline-date-width: 50px;

@timeline-vertical-padding-calc: calc(@timeline-spacing-x + (@timeline-flag-min-width - @timeline-track-size) / 2);
@timeline-vertical-padding-with-dates-calc: calc(@timeline-vertical-padding-calc + @timeline-date-width);
@timeline-mobile-vertical-padding-calc: calc(@timeline-mobile-spacing-x + (@timeline-flag-min-width - @timeline-track-size) / 2);
@timeline-mobile-vertical-padding-with-dates-calc: calc(@timeline-mobile-vertical-padding-calc + @timeline-date-width);

@timeline-circle-width: 16px;
@timeline-circle-height: 16px;

@timeline-collapse-arrow-padding-x: 8px;

@timeline-event-width: 400px;
@timeline-event-height: 600px;
@timeline-event-min-height-calc: calc(2 * @timeline-track-event-offset);


// Breadcrumb
@breadcrumb-min-height: 2.11em;

@breadcrumb-container-padding-x: 0;
@breadcrumb-container-padding-y: 0;

@breadcrumb-container-margin-x: 0;
@breadcrumb-container-margin-y: 0;

@breadcrumb-link-padding-x: @button-padding-x;
@breadcrumb-link-padding-y: @button-padding-y;
@breadcrumb-link-line-height: 1.6em;
@breadcrumb-link-border-radius: 4px;

@breadcrumb-icon-link-padding-x: @breadcrumb-link-padding-x;
@breadcrumb-icon-link-padding-y: @breadcrumb-icon-link-padding-x;

@breadcrumb-root-link-spacing: @breadcrumb-link-padding-x;
@breadcrumb-link-icon-spacing: @button-padding-x;

@breadcrumb-root-icon-font-size: @icon-size;
@breadcrumb-delimiter-icon-font-size: 12px;
@breadcrumb-delimiter-icon-padding-x: calc(@button-padding-x / 2);
@breadcrumb-delimiter-icon-padding-y: 0;


// Listgroup
@listgroup-border-width: 1px;

@listgroup-item-padding-x: 1em;
@listgroup-item-padding-y: .5em;
@listgroup-item-border-width: 1px;


// Badge
@use-calc-badge-size: boolean(true);

@badge-padding-x: 4px;
@badge-padding-y: 4px;
@badge-border-width: 1px;
@badge-border-radius: 2px;
@badge-font-size: 10px;
@badge-line-height: 1;

@badge-padding-x-sm: ( @badge-padding-x / 2 );
@badge-padding-y-sm: ( @badge-padding-y / 2 );
@badge-border-width-sm: @badge-border-width;
@badge-border-radius-sm: 1px;
@badge-font-size-sm: 10px;
@badge-line-height-sm: 1;

@badge-padding-x-lg: ( @badge-padding-x * 1.5 );
@badge-padding-y-lg: ( @badge-padding-y * 1.5 );
@badge-border-width-lg: @badge-border-width;
@badge-border-radius-lg: 4px;
@badge-font-size-lg: 12px;
@badge-line-height-lg: 1;

@badge-fixed-size: 20px;
@badge-calc-size: calc( (@badge-line-height * 1em) + (@badge-padding-y * 2) + (@badge-border-width * 2) );
@badge-size: if( @use-calc-badge-size, @badge-calc-size, @badge-fixed-size );
@badge-dot-size: 10px;

@badge-fixed-size-sm: 16px;
@badge-calc-size-sm: calc( (@badge-line-height-sm * 1em) + (@badge-padding-y-sm * 2) + (@badge-border-width-sm * 2) );
@badge-size-sm: if( @use-calc-badge-size, @badge-calc-size-sm, @badge-fixed-size-sm );
@badge-dot-size-sm: 6px;

@badge-fixed-size-lg: 26px;
@badge-calc-size-lg: calc( (@badge-line-height-lg * 1em) + (@badge-padding-y-lg * 2) + (@badge-border-width-lg * 2) );
@badge-size-lg: if( @use-calc-badge-size, @badge-calc-size-lg, @badge-fixed-size-lg );
@badge-dot-size-lg: 12px;


// Checkbox
@icon-size: 16px;
@icon-spacing: 4px;

@checkbox-icon-size: 12px;
@checkbox-size: @icon-size;
// @checkbox-radius: @border-radius;
@checkbox-border-width: 1px;
@checkbox-line-height: (@checkbox-size + @checkbox-border-width);

@checkbox-list-margin: 0;
@checkbox-list-padding: 0;
@checkbox-list-item-margin-top: 16px;
@checkbox-list-horizontal-item-margin-x: 32px;

// Radio
@radio-icon-size: 12px;
@radio-size: @icon-size;
@radio-radius: 50%;
@radio-border-width: 1px;
@radio-line-height: calc(@radio-size + @radio-border-width);

@radio-list-margin: 0;
@radio-list-padding: 0;
@radio-list-item-margin-top: 16px;
@radio-list-horizontal-item-margin-x: 32px;


// Drop Hint
@equilateral-index: 1.7320508076;

@drop-hint-arrow-size: 8px;
@drop-hint-arrow-spacing: (@drop-hint-arrow-size / 2);
@drop-hint-line-h-width: 20px;
@drop-hint-line-h-height: 1px;
@drop-hint-line-v-width: @drop-hint-line-h-height;
@drop-hint-line-v-height: @drop-hint-line-h-width;


// Listview
@listview-padding-x: 8px;
@listview-padding-y: 4px;
@listview-border-width: 1px;

@listview-font-size: inherit;
@listview-line-height: (20 / 14);

@listview-item-padding-x: 8px;
@listview-item-padding-y: 4px;


// FileManager
@filemanager-spacer: 16px;
@filemanager-border-width: 1px;

@filemanager-toolbar-border-width: @filemanager-border-width;

@filemanager-navigation-padding-x: @filemanager-spacer;
@filemanager-navigation-padding-y: @filemanager-spacer;
@filemanager-navigation-width: 20%;
@filemanager-navigation-border-width: @filemanager-border-width;

@filemanager-breadcrumb-padding-x: (@filemanager-spacer / 2);
@filemanager-breadcrumb-padding-y: (@filemanager-spacer / 2);
@filemanager-breadcrumb-border-width: @filemanager-border-width;

@filemanager-listview-item-padding-x: @filemanager-spacer;
@filemanager-listview-item-padding-y: @filemanager-spacer;
@filemanager-listview-item-width: 120px;
@filemanager-listview-item-height: 120px;

@filemanager-listview-item-icon-size: (@icon-size * 3);

@filemanager-preview-padding-x: @filemanager-spacer;
@filemanager-preview-padding-y: @filemanager-spacer;
@filemanager-preview-width: 20%;
@filemanager-preview-border-width: @filemanager-border-width;
@filemanager-preview-spacing: @filemanager-spacer;

@filemanager-preview-icon-size: (@icon-size * 6);


// Tooltip
@tooltip-padding-x: 8px;
@tooltip-padding-y: 4px;
@tooltip-border-width: 1px;

@tooltip-callout-size: 6px;


// Stepper
@stepper-margin-x: 0;
@stepper-margin-y: 0;
@stepper-padding-x: 0;
@stepper-padding-y: 0;
@stepper-label-margin-x: 12px;
@stepper-label-padding-x: 12px;
@stepper-label-padding-y: 5px;
@stepper-border-width: 0;

@stepper-inline-content-padding-x: 20px;
@stepper-inline-content-padding-y: 10px;

@stepper-font-size: 14px;
@stepper-line-height: (20 / 14);

@stepper-indicator-width: 28px;
@stepper-indicator-height: @stepper-indicator-width;
@stepper-indicator-border-width: 1px;
@stepper-indicator-border-radius: 50%;

@stepper-indicator-focus-border-width: 1px;
@stepper-indicator-focus-size: 2px;
@stepper-indicator-focus-offset: 3px;
@stepper-indicator-focus-calc-offset: calc( @stepper-indicator-focus-border-width + @stepper-indicator-focus-offset );

@stepper-optional-label-opacity: .35;
@stepper-disabled-opacity: .6;

@stepper-progressbar-size: 2px;
@stepper-connector-calc-offset: calc(( @stepper-indicator-height / 2) + @stepper-progressbar-size / 2 + @stepper-indicator-focus-offset);

@stepper-content-transition-property: height;
@stepper-content-transition-duration: 300ms;
@stepper-content-transition-timing-function: cubic-bezier(.4, 0, .2, 1) 0ms;


// Wizard
@wizard-spacer: 8px;

@wizard-padding-x: calc( @wizard-spacer * 2 );
@wizard-padding-y: @wizard-padding-x;

@wizard-border-width: 0;
@wizard-font-size: inherit;
@wizard-line-height: @line-height;

@wizard-content-padding-x: calc( @wizard-spacer * 4 );
@wizard-content-padding-y: @wizard-content-padding-x;

@wizard-buttons-margin-x: @wizard-spacer;


// TextArea
@textarea-default-width: 18em;
@textarea-border-width: @input-border-width;
@textarea-padding-x: 8px;
@textarea-padding-y: 4px;
@textarea-line-height: (20 / 14);
@textarea-calc-height: calc( (@textarea-line-height * 1em) + (@textarea-padding-y * 2) + (@textarea-border-width * 2) );


// Appbar
@appbar-padding-y: 8px;
@appbar-padding-x: 16px;
@appbar-border-width: 0px;

@appbar-font-size: inherit;
@appbar-line-height: @line-height;
@appbar-font-family: inherit;
@appbar-gap: 8px;


// Loader
@loader-segment-border-radius: 50%;
@loader-segment-size-sm: 6px;
@loader-segment-size-md: 10px;
@loader-segment-size-lg: 20px;

@loader-padding-sm: calc( @loader-segment-size-sm / 2 );
@loader-padding-md: calc( @loader-segment-size-md / 2 );
@loader-padding-lg: calc( @loader-segment-size-lg / 2 );

@loader-pulsing-2-segment-margin-x-sm: 1.5px;
@loader-pulsing-2-segment-margin-x-md: 2.5px;
@loader-pulsing-2-segment-margin-x-lg: 5px;

@loader-spinner-3-width-sm: calc( @loader-segment-size-sm * 4 );
@loader-spinner-3-height-sm: 20px;
@loader-spinner-3-width-md: calc( @loader-segment-size-md * 4 );
@loader-spinner-3-height-md: 34px;
@loader-spinner-3-width-lg: calc( @loader-segment-size-lg * 4 );
@loader-spinner-3-height-lg: 65px;

@loader-spinner-4-width-sm: calc( @loader-segment-size-sm * 4 );
@loader-spinner-4-height-sm: @loader-spinner-4-width-sm;
@loader-spinner-4-width-md: calc( @loader-segment-size-md * 4 );
@loader-spinner-4-height-md: @loader-spinner-4-width-md;
@loader-spinner-4-width-lg: calc( @loader-segment-size-lg * 4 );
@loader-spinner-4-height-lg: @loader-spinner-4-width-lg;


// Gantt
@gantt-planned-row-height: 3.3em;

@gantt-planned-margin-y: .35em;
@gantt-planned-milestone-margin-y: .7em;
@gantt-planned-summary-margin-y: .6em;
@gantt-planned-border-width: 2px;
@gantt-planned-line-height: .75em;

@gantt-planned-dependency-margin-y: .6em;
@gantt-planned-resource-margin-y: 0.9em;
@gantt-planned-dot-margin-y: 1.1em;
@gantt-planned-dot-summary-milestone-margin-y: 1.3em;
@gantt-planned-dot-start-milestone-margin-x: -.2em;

@gantt-planned-moment-width: 6px;
@gantt-planned-moment-height: @gantt-planned-moment-width;
@gantt-planned-moment-border-radius: calc( ( @gantt-planned-border-width * 2 + @gantt-planned-moment-width ) / 2 );
@gantt-planned-moment-margin-x: calc( @gantt-planned-border-width / 2 - @gantt-planned-moment-border-radius );
@gantt-planned-milestone-moment-margin-x: @gantt-planned-moment-width / 2;

@gantt-planned-duration-height: @gantt-planned-border-width;
@gantt-planned-duration-hover-height: calc( @gantt-planned-border-width + 1px );

@gantt-task-offset-line-height: 1.46em;
@gantt-offset-height: 1.65em;


// ImageEditor
@imageeditor-spacer: 8px;

@imageeditor-margin-y: 0px;
@imageeditor-margin-x: 0px;
@imageeditor-padding-y: 0px;
@imageeditor-padding-x: 0px;

@imageeditor-border-width: 0px;
@imageeditor-font-size: inherit;
@imageeditor-line-height: @line-height;
@imageeditor-font-family: inherit;

@imageeditor-content-border-width: 1px;

@imageeditor-action-pane-padding-y: calc( @imageeditor-spacer * 4 );
@imageeditor-action-pane-padding-x: calc( @imageeditor-spacer * 2 );

@imageeditor-crop-border-width: 1px;
@imageeditor-crop-border-style: dashed;

@imageeditor-resize-handle-margin-x: 1px;
@imageeditor-resize-handle-margin-y: 1px;
@imageeditor-resize-handle-border-width: 0px 2px 2px 0px;
@imageeditor-resize-handle-size: 15px;


// Expansion panel
@expander-spacing-y: (@padding-y * 2);
@expander-font-family: @font-family;
@expander-font-size: @font-size;
@expander-line-height: @line-height;
@expander-border-width: 1px;

@expander-header-padding-x: (@padding-x * 2);
@expander-header-padding-y: (@padding-y * 3);
@expander-indicator-margin-x: (@padding-x * 1.5);

@expander-content-padding-x: (@padding-x * 2);
@expander-content-padding-y: (@padding-y * 4);


// FloatingActionButton
@fab-spacer: 16px;

@fab-padding-x: @fab-spacer;
@fab-padding-y: @fab-padding-x;
@fab-border-width: 0;
@fab-border-radius: 4px;
@fab-font-family: inherit;
@fab-font-size: inherit;
@fab-line-height: @line-height;

@fab-padding-x-sm: calc( @fab-padding-x / 2 );
@fab-padding-y-sm: calc( @fab-padding-y / 2 );

@fab-padding-x-lg: calc( @fab-padding-x * 1.5 );
@fab-padding-y-lg: calc( @fab-padding-y * 1.5 );

@fab-icon-width: 20px;
@fab-icon-height: @fab-icon-width;
@fab-icon-spacing: 8px;

@fab-items-padding-x: 0;
@fab-items-padding-y: @fab-spacer;

@fab-item-spacer: 8px;
@fab-item-text-padding-x: 4px;
@fab-item-text-padding-y: @fab-item-text-padding-x;
@fab-item-text-border-width: 1px;
@fab-item-text-border-radius: 2px;
@fab-item-text-font-size: 10px;
@fab-item-text-line-height: 1;

@fab-item-icon-padding-x: @fab-item-spacer;
@fab-item-icon-padding-y: @fab-item-icon-padding-x;
@fab-item-icon-border-width: 0;
@fab-item-icon-border-radius: 50%;


// BottomNavigation
@bottom-nav-padding-x: 4px;
@bottom-nav-padding-y: @bottom-nav-padding-x;
@bottom-nav-gap: @bottom-nav-padding-x;
@bottom-nav-border-width: 1px 0px 0px 0px;

@bottom-nav-font-family: inherit;
@bottom-nav-font-size: inherit;
@bottom-nav-line-height: @line-height;
@bottom-nav-letter-spacing: .2px;

@bottom-nav-item-padding-x: 8px;
@bottom-nav-item-padding-y: 0;
@bottom-nav-item-min-width: 72px;
@bottom-nav-item-min-height: calc( (@icon-size * 2.5) + (@bottom-nav-padding-y * 2) );
@bottom-nav-item-gap: 0 8px;
@bottom-nav-item-border-radius: 4px;

@bottom-nav-item-icon-margin-y: 8px;
@bottom-nav-item-icon-margin-x: @bottom-nav-item-icon-margin-y;
@bottom-nav-item-icon-size: 24px;
@bottom-nav-item-disabled-opacity: .5;

@bottom-nav-shadow: 0px 0px 5px rgba(0, 0, 0, .12);


// ActionSheet
@actionsheet-padding-x: null;
@actionsheet-padding-y: null;
@actionsheet-width: 360px;
@actionsheet-max-width: 100%;
@actionsheet-max-height: 50vh;

@actionsheet-border-width: 0;
@actionsheet-border-radius: 0;

@actionsheet-font-size: 14px;
@actionsheet-font-family: @font-family;
@actionsheet-line-height: ( 20 / 14 );

// Actionsheet header
@actionsheet-header-padding-x: 16px;
@actionsheet-header-padding-y: 8px;
@actionsheet-header-border-width: null;
@actionsheet-header-font-size: null;
@actionsheet-header-font-family: null;
@actionsheet-header-line-height: null;

// Actionsheet item
@actionsheet-item-min-height: 40px;
@actionsheet-item-padding-x: 16px;
@actionsheet-item-padding-y: 8px;
@actionsheet-item-border-width: 1px;
@actionsheet-item-spacing: 12px;

@actionsheet-item-icon-size: null;

@actionsheet-item-title-font-weight: null;
@actionsheet-item-title-text-transform: null;
@actionsheet-item-description-font-size: .875em;
@actionsheet-item-description-opacity: .75;


// Grid column menu
@grid-column-menu-popup-padding-x: 0;
@grid-column-menu-popup-padding-y: @padding-y-sm;

@grid-column-menu-item-padding-x: @padding-x;
@grid-column-menu-item-padding-y: @padding-y;

@grid-column-menu-list-item-padding-x: @padding-x;
@grid-column-menu-list-item-padding-y: @padding-y-sm;

@grid-column-menu-filter-container-padding-x: @padding-x;
@grid-column-menu-filter-container-padding-y: @padding-y;


// TaskBoard
@taskboard-spacer: 16px;
@taskboard-padding-y: null;
@taskboard-padding-x: null;
@taskboard-font-family: inherit;
@taskboard-font-size: inherit;
@taskboard-line-height: @line-height;

@taskboard-toolbar-padding-y: @taskboard-spacer;
@taskboard-toolbar-padding-x: @taskboard-toolbar-padding-y;

@taskboard-content-padding-y: @taskboard-spacer;
@taskboard-content-padding-x: @taskboard-content-padding-y;

@taskboard-column-container-spacing-y: calc(@taskboard-spacer / 2);
@taskboard-column-container-padding-y: 0;
@taskboard-column-container-padding-x: calc(@taskboard-spacer / 2);
@taskboard-columns-container-gap: @taskboard-spacer;

@taskboard-column-width: 320px;
@taskboard-column-border-width: 1px;
@taskboard-column-border-radius: 4px;

@taskboard-column-header-padding-y: calc(@taskboard-spacer / 2);
@taskboard-column-header-padding-x: @taskboard-column-header-padding-y;
@taskboard-column-header-gap: calc(@taskboard-spacer / 4);
@taskboard-column-header-actions-gap: calc(@taskboard-spacer / 2);
@taskboard-column-header-font-weight: 500;

@taskboard-column-cards-padding-y: null;
@taskboard-column-cards-padding-x: null;
@taskboard-column-cards-gap: calc(@taskboard-spacer / 2);

@taskboard-column-new-calc-height: calc( @input-offset + (@taskboard-column-header-padding-y * 2) );

@taskboard-pane-width: @taskboard-column-width;
@taskboard-pane-padding-y: null;
@taskboard-pane-padding-x: null;
@taskboard-pane-border-width: 1px;

@taskboard-pane-header-padding-y: calc(@taskboard-spacer * .75);
@taskboard-pane-header-padding-x: @taskboard-spacer;
@taskboard-pane-header-font-weight: 500;

@taskboard-pane-content-padding-y: 0px;
@taskboard-pane-content-padding-x: @taskboard-spacer;

@taskboard-pane-actions-padding-y: calc(@taskboard-spacer * .75);
@taskboard-pane-actions-padding-x: @taskboard-spacer;

@taskboard-card-padding-y: @taskboard-spacer;
@taskboard-card-padding-x: @taskboard-card-padding-y;
@taskboard-card-border-width: @card-border-width;
@taskboard-card-border-radius: 4px;

@taskboard-card-category-border-width: 4px;

@taskboard-drag-placeholder-border-width: 1px;
@taskboard-drag-placeholder-border-radius: @taskboard-card-border-radius;


// Scheduler
@scheduler-yearview-padding-x: 12px;
@scheduler-yearview-padding-y: @scheduler-yearview-padding-x;

@scheduler-yearview-month-header-font-weight: bold;
@scheduler-yearview-month-header-font-size: inherit;
@scheduler-yearview-month-header-opacity: 1;

@scheduler-yearview-indicator-size: 3px;
@scheduler-yearview-indicator-calc-offset-top: ~"calc( 100% - @{calendar-cell-padding-y} )";
@scheduler-yearview-indicator-calc-offset-left: ~"calc( 50% - (@{scheduler-yearview-indicator-size} / 2) )";
@scheduler-yearview-indicator-border-radius: 50%;

@scheduler-tooltip-padding-x: @padding-x;
@scheduler-tooltip-padding-y: @scheduler-tooltip-padding-x;
@scheduler-tooltip-border-width: 0;

@scheduler-tooltip-title-margin-y: @padding-y;
@scheduler-tooltip-month-font-size: @font-size-sm;
@scheduler-tooltip-day-font-size: calc( @scheduler-tooltip-month-font-size * 2 );

@scheduler-tooltip-events-max-height: 250px;
@scheduler-tooltip-events-gap: @padding-y;

@scheduler-tooltip-event-padding-x: @padding-x;
@scheduler-tooltip-event-padding-y: @padding-y;
@scheduler-tooltip-event-border-radius: @border-radius;
@scheduler-tooltip-event-gap: @padding-x-sm;


// Captcha
@captcha-spacer: @padding-x;

@captcha-width: 280px;
@captcha-font-family: inherit;
@captcha-font-size: inherit;
@captcha-line-height: @line-height;
@captcha-gap: @captcha-spacer;

@captcha-image-wrap-gap: @captcha-spacer;

@captcha-image-controls-gap: @captcha-spacer;

@captcha-validation-message-margin-top: calc( @captcha-spacer / 2 );
@captcha-validation-message-font-size: 12px;
@captcha-validation-message-font-style: italic;


// Popover
@popover-spacer: 16px;

@popover-border-width: @card-border-width;
@popover-border-style: solid;
@popover-border-radius: @card-border-radius;
@popover-font-size: inherit;
@popover-font-family: inherit;
@popover-line-height: @line-height;

@popover-header-padding-y: @card-header-padding-y;
@popover-header-padding-x: @card-header-padding-x;
@popover-header-border-width: @card-header-border-width;
@popover-header-border-style: @popover-border-style;

@popover-body-padding-y: @card-body-padding-y;
@popover-body-padding-x: @card-body-padding-x;

@popover-actions-border-width: @popover-border-width;

@popover-callout-width: @card-callout-width;
@popover-callout-height: @card-callout-height;
@popover-callout-border-width: @popover-border-width;
@popover-callout-border-style: @popover-border-style;


// Orgchart
@orgchart-spacer: 24px;
@orgchart-padding-y: @orgchart-spacer;
@orgchart-padding-x: @orgchart-padding-y ;
@orgchart-font-family: inherit;
@orgchart-font-size: inherit;
@orgchart-line-height: @line-height;

@orgchart-node-gap: @orgchart-spacer;
@orgchart-group-gap: @orgchart-spacer;
@orgchart-node-container-gap: @orgchart-spacer;

@orgchart-node-group-padding-y: @orgchart-spacer;
@orgchart-node-group-padding-x: @orgchart-node-group-padding-y;
@orgchart-node-group-border-width: 1px;
@orgchart-node-group-border-radius: @border-radius;

@orgchart-node-group-title-font-size: 16px;
@orgchart-node-group-title-margin-bottom: calc( @orgchart-spacer / 3 );
@orgchart-node-group-title-line-height: @line-height;

@orgchart-node-group-subtitle-font-size: 14px;
@orgchart-node-group-subtitle-margin-bottom: @orgchart-spacer;

@orgchart-card-width: 300px;
@orgchart-card-border-width: @card-border-width;

@orgchart-card-title-margin-bottom: 0px;
@orgchart-card-title-font-size: 16px;

@orgchart-card-subtitle-margin-bottom: 0px;
@orgchart-card-subtitle-font-size: 14px;

@orgchart-card-body-border-width: 2px 0 0;
@orgchart-card-body-border-color: transparent;

@orgchart-card-body-vbox-margin-right: calc( @orgchart-spacer / 2 );
@orgchart-card-body-vbox-min-height: @card-avatar-size;

@orgchart-line-size: 1px;
@orgchart-line-v-height: 25px;


// Tabstrip
@tabstrip-padding-x: 4px;
@tabstrip-padding-y: 4px;
@tabstrip-border-width: 1px;

@tabstrip-items-wrap-border-width: 1px;

@tabstrip-item-padding-x: 1em;
@tabstrip-item-padding-y: .5em;
@tabstrip-item-border-width: 1px;
@tabstrip-item-border-radius: 4px;
@tabstrip-item-gap: -1px;

@tabstrip-content-padding-x: 1em;
@tabstrip-content-padding-y: 1em;
@tabstrip-content-border-width: 1px;


// PivotGrid
@pivotgrid-spacer: 16px;
@pivotgrid-padding-x: null;
@pivotgrid-padding-y: null;
@pivotgrid-font-family: inherit;
@pivotgrid-font-size: inherit;
@pivotgrid-line-height: @line-height;
@pivotgrid-border-width: 1px;

@pivotgrid-row-header-width: 300px;
@pivotgrid-column-header-height: 250px;

@pivotgrid-cell-padding-x: @grid-cell-padding-x;
@pivotgrid-cell-padding-y: @grid-cell-padding-y;
@pivotgrid-cell-border-width: 1px;

// Pivotgrid Configurator
@pivotgrid-configurator-padding-x: null;
@pivotgrid-configurator-padding-y: null;
@pivotgrid-configurator-border-width: 1px;

@pivotgrid-configurator-header-padding-x: @pivotgrid-spacer;
@pivotgrid-configurator-header-padding-y: calc( @pivotgrid-spacer * .75 );
@pivotgrid-configurator-header-font-size: 18px;
@pivotgrid-configurator-header-font-weight: 500;

@pivotgrid-configurator-content-padding-x: @pivotgrid-spacer;
@pivotgrid-configurator-content-padding-y: 2px;

@pivotgrid-configurator-fields-margin-x: 0px;
@pivotgrid-configurator-fields-margin-y: calc( @pivotgrid-spacer / 2 );

@pivotgrid-configurator-actions-padding-x: calc( @pivotgrid-spacer / 2 );
@pivotgrid-configurator-actions-padding-y: calc( @pivotgrid-spacer * .75 );

@pivotgrid-configurator-vertical-width: 320px;
@pivotgrid-configurator-horizontal-height: 420px;

@pivotgrid-configurator-button-padding-x: @padding-x-sm;
@pivotgrid-configurator-button-padding-y: @pivotgrid-configurator-button-padding-x;
@pivotgrid-configurator-button-border-width: 1px;
@pivotgrid-configurator-button-size: ~"calc( (@{pivotgrid-line-height} * 1em) + (@{pivotgrid-configurator-button-padding-y} * 2) + (@{pivotgrid-configurator-button-border-width} * 2) )";

// Calculated fields
@pivotgrid-calculated-field-padding-x: @pivotgrid-spacer;
@pivotgrid-calculated-field-padding-y: @pivotgrid-spacer;
@pivotgrid-calculated-field-border-width: 1px;
@pivotgrid-calculated-field-border-radius: @border-radius;
@pivotgrid-calculated-field-gap: @pivotgrid-spacer;

@pivotgrid-treeview-padding-x: calc( @pivotgrid-spacer / 2 );
@pivotgrid-treeview-padding-y: 0px;


// Chip
@chips-margin: 8px;

@chip-padding-x: 0px;
@chip-padding-y: 4px;
@chip-height: 24px;
@chip-border-radius: calc( @chip-height / 2);
@chip-font-size: 14px;

@chip-selected-icon-size: 16px;
@chip-selected-icon-offset: 4px;
@chip-selected-icon-no-icon-offset: @chip-selected-icon-offset;
@chip-selected-icon-font-size: @chip-font-size;

@chip-content-padding-x: 8px;

@chip-icons-size: 16px;
@chip-icon-margin: 4px;
@chip-remove-icon-margin: 8px;
@chip-remove-icon-font-size: 14px;

@chip-avatar-size: 16px;


// Panelbar
@panelbar-padding-x: 0px;
@panelbar-padding-y: 0px;
@panelbar-font-family: inherit;
@panelbar-font-size: inherit;
@panelbar-line-height: @line-height;
@panelbar-border-width: 1px;

@panelbar-header-padding-x: @padding-x-lg;
@panelbar-header-padding-y: @padding-y-lg;

@panelbar-item-padding-x: @padding-x-lg;
@panelbar-item-padding-y: @padding-y;

@panelbar-item-level-count: 4;


// ColorPalette
@colorpalette-font-size: inherit;
@colorpalette-font-family: inherit;
@colorpalette-line-height: @line-height;

@colorpalette-tile-width: 24px;
@colorpalette-tile-height: 24px;


// ColorGradient
@colorgradient-spacer: 12px;

@colorgradient-width: 272px;
@colorgradient-border-width: 1px;
@colorgradient-border-radius: @border-radius;
@colorgradient-padding-y: @colorgradient-spacer;
@colorgradient-padding-x: @colorgradient-padding-y;
@colorgradient-gap: @colorgradient-spacer;
@colorgradient-font-family: inherit;
@colorgradient-font-size: inherit;
@colorgradient-line-height: @line-height;

@colorgradient-canvas-border-radius: @border-radius;
@colorgradient-canvas-gap: @colorgradient-spacer;
@colorgradient-canvas-rectangle-height: 180px;

@colorgradient-slider-track-size: 10px;
@colorgradient-slider-border-radius: 10px;
@colorgradient-slider-draghandle-border-width: 3px;

@colorgradient-slider-vertical-size: 180px;
@colorgradient-slider-horizontal-size: 100%;

@colorgradient-draghandle-width: 14px;
@colorgradient-draghandle-height: 14px;
@colorgradient-draghandle-border-width: 1px;
@colorgradient-draghandle-border-radius: 50%;
@colorgradient-draghandle-bg: transparent;
@colorgradient-draghandle-text: null;

@colorgradient-canvas-draghandle-margin-y: -( @colorgradient-draghandle-height / 2 );
@colorgradient-canvas-draghandle-margin-x: -( @colorgradient-draghandle-width / 2 );

@colorgradient-input-width: 46px;
@colorgradient-input-gap: ( @colorgradient-spacer / 1.5 );
@colorgradient-input-label-gap: ( @colorgradient-spacer / 3 );

@colorgradient-contrast-ratio-font-weight: bold;
@colorgradient-contrast-spacer: ( @colorgradient-spacer / 1.5 );

// Color Preview
@color-preview-border-radius: @border-radius;
@color-preview-border-width: 1px;


// ColorEditor/FlatColorPicker
@coloreditor-spacer: 12px;

@coloreditor-min-width: 272px;
@coloreditor-border-width: 1px;
@coloreditor-border-radius: @border-radius;
@coloreditor-font-family: inherit;
@coloreditor-font-size: inherit;
@coloreditor-line-height: @line-height;

@coloreditor-header-padding-y: @coloreditor-spacer;
@coloreditor-header-padding-x: @coloreditor-header-padding-y;
@coloreditor-header-actions-gap: ( @coloreditor-spacer / 1.5 );

@coloreditor-preview-gap: ( @coloreditor-spacer / 4 );
@coloreditor-color-preview-width: 34px;
@coloreditor-color-preview-height: 14px;

@coloreditor-views-padding-y: @coloreditor-spacer;
@coloreditor-views-padding-x: @coloreditor-views-padding-y;
@coloreditor-views-gap: @coloreditor-spacer;

// ColorPicker
@colorpicker-line-height: 2.00714286em;

@import "common/all.less";
