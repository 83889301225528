/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../mobile/common/icons.less";
@import "../../mobile/common/button.less";
@import "base.less";

// Button

.km-button
{
    margin: 0;
    padding: .3em .8em;
    border-width: @mobile-border-width;
    border-style: solid;
}

.km-button:hover
{
    text-decoration: none;
}

// Detail Button

.km-badge
{
    border: 0;
    min-width: 1.8em;
    height: 1.8em;
    line-height: 1.8em;
    border-radius: 5em;
}

.km-detail
{
    border-radius: 5em;
    min-width: 1.4em;
    height: 1.4em;
    line-height: 1.4em;
    border-width: @mobile-border-width;
    border-style: solid;
}

.km-detaildisclose
{
    min-width: 1.8em;
    height: 1.8em;
    line-height: 1.8em;
}

.km-detaildisclose:after,
.km-detaildisclose:before
{
    left: .1em;
    top: 0.35em;
}

.km-detail .km-icon:before
{
    display: none;
}
