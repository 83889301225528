/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Scheduler
.k-scheduler {

    .k-link {
        color: inherit;
    }

}


// Scheduler header
.k-scheduler-toolbar {
    border-width: 0 0 @toolbar-border-width;
    z-index: 2;

    .k-nav-current {
        color: inherit;
        text-decoration: none;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;

        .k-icon {
            margin-inline-end: 4px;
        }

        .k-lg-date-format,
        .k-sm-date-format {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .k-lg-date-format { display: inline-block; }
        .k-sm-date-format { display: none; }
    }


    // Scheduler search
    .k-scheduler-search {
        display: inline-flex;
        flex-flow: row nowrap;
    }


    // Scheduler views
    .k-scheduler-views-wrapper {
        margin-left: auto;

        .k-views-dropdown {
            width: auto;
            font: inherit;
            display: none;
        }
    }

    @media (max-width: 1400px) {
        .k-nav-current .k-lg-date-format { max-width: 200px; }
    }

    @media (max-width: 1024px) {

        .k-nav-current .k-lg-date-format { display: none; }
        .k-nav-current .k-sm-date-format { display: inline-block; }

        .k-scheduler-views-wrapper .k-scheduler-views { display: none; }
        .k-scheduler-views-wrapper .k-views-dropdown { display: inline-block; }
    }
}


// Scheduler footer
.k-scheduler-footer {
    border-width: @toolbar-border-width 0 0;
}



.k-edit-field.k-scheduler-toolbar {
    border-width: 0;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.k-scheduler-header {
    text-align:center;

    .k-link {
        color: inherit;
    }
}

.k-scheduler-header,
.k-scheduler-header-wrap
{
    overflow:hidden;
}

.k-scheduler-header-wrap
{
    position:relative;
    border-style: solid;
    border-width: 0;
}

.k-scheduler .k-scrollbar-v .k-scheduler-header-wrap
{
    border-right-width: 1px;
}

.k-scheduler-times,
.k-scheduler-content
{
    position:relative;
}

.k-scheduler-times
{
    overflow:hidden;
    border-style:solid;
    border-width:0;
}

.k-scheduler-content {
    overflow: auto;
}
.k-scheduler-monthview .k-scheduler-content {
    overflow-y: scroll;
}

.k-scheduler-layout,
.k-scheduler-table
{
    border-spacing:0;
    width:100%;
    margin:0;
    border-collapse:separate;
}

.k-scheduler-layout > tbody > tr > td
{
    padding: 0;
    vertical-align: top;
}

// fix smashed second layout column in iPad
.k-safari .k-scheduler-layout > tbody > tr > td + td
{
    width: 100%;
}

.k-scheduler-table
{
    table-layout:fixed;
    max-width: none;
}

.k-scheduler-times .k-scheduler-table
{
    table-layout:auto;
}

.k-scheduler-monthview .k-scheduler-content .k-scheduler-table
{
    height: 100%;
}

.k-scheduler-table td,
.k-scheduler-table th {
    height: 1.5em;
    padding: 4px .5em;
    font-size: 100%;
}

.k-scheduler .k-scheduler-table td,
.k-scheduler .k-scheduler-table th {
    .box-sizing(content-box);
}



.k-scheduler-dayview div:not(.k-scheduler-header) .k-today:not(.k-nonwork-hour):not(.k-state-selected),
.k-scheduler-timelineview .k-today:not(.k-nonwork-hour) {
    background-color: transparent;
}

.k-scheduler-monthview .k-hidden,
.k-scheduler-monthview .k-hidden > div
{
    width: 0 !important;
    overflow: hidden !important;
}

.k-scheduler-monthview .k-hidden
{
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-right-width: 0 !important;
}

.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times
{
    margin-right: 1px;
}

.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times .k-hidden
{
    height: auto;
}

.k-scheduler-monthview .k-scheduler-table td,
.k-scheduler-monthview .k-hidden {
    height: 85px;
    text-align: right;
}
.k-scheduler-monthview .k-scheduler-table-auto,
.k-scheduler-monthview .k-scheduler-table-auto td,
.k-scheduler-monthview .k-scheduler-content .k-scheduler-table-auto {
    height: auto;
}

.k-scheduler-table td,
.k-slot-cell
{
    vertical-align: top;
}

// separate due to old IEs
.k-scheduler-layout tr + tr .k-scheduler-times th:last-child
{
    vertical-align: top;
}

.k-scheduler-header th
{
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-scheduler-table td,
.k-scheduler-header th
{
    border-style: solid;
    border-width: 0 0 1px 1px;
}

.k-scheduler-table td:first-child,
.k-scheduler-header th:first-child
{
    border-left-width: 0;
}

.k-scheduler-agendaview .k-scheduler-table td:first-child
{
    border-left-width: 1px;
}

.k-scheduler-agendaview .k-scheduler-table td.k-first
{
    border-left-width: 0;
}

.k-scheduler-layout tr + tr .k-scheduler-times tr:last-child > th,
.k-scheduler-layout tr + tr .k-scheduler-table > tbody > tr:last-child > td,
.k-scheduler-table > tbody > tr > .k-last
{
    border-bottom-width: 0;
}

.k-scrollbar-h tr + tr .k-scheduler-times,
.k-scrollbar-h .k-scheduler-content .k-scheduler-table > tbody > tr:last-child > td,
.k-scheduler-agendaview.k-scrollbar-h .k-scheduler-table > tbody > tr > td.k-last
{
    border-bottom-width: 1px;
}

.k-scheduler-times th
{
    text-align:right;
    padding-right:.6em;
    border-style:solid;
    border-width:0 1px 1px 0;
    border-color:transparent;
    white-space: nowrap;
}

.k-scheduler-layout tr + tr .k-scheduler-times th
{
    border-bottom-color: transparent;
}

.k-scheduler-layout tr + tr .k-scheduler-times th.k-slot-cell,
.k-scheduler-layout tr + tr .k-scheduler-times th.k-scheduler-times-all-day
{
    border-bottom-color: inherit;
}

.k-scheduler .k-middle-row td
{
    border-bottom-style:dotted;
}

.k-scheduler-now-arrow,
.k-scheduler-now-line
{
    position:absolute;
}

.k-scheduler-now-arrow
{
    width:0;
    height:0;
    border:solid 5px transparent;
    left:0;
}

.k-scheduler-now-line
{
    left:5px;
    right:0;
    height:1px;
}

.k-task
{
    position:relative;
}

div.k-more-events {
    padding: 0;
    height: 13px;
}

.k-more-events > .k-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.k-event,
.k-more-events {
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
    position: absolute;
}
.k-event .k-nav-day {
    display: inline-block;
}

.k-event {
    cursor: default;
    min-height: 1.3em;
}

.k-event-drag-hint
{
    opacity: .6;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}

.k-scheduler-header .k-event {
    white-space: nowrap;
}

.k-event-template {
    padding: 4px 1.4em 4px .6em;
}

.k-event-time
{
    display: none;
    padding-bottom: 0;
    font-size: .9em;
}

.k-event-drag-hint .k-event-time
{
    display: block;
}

.k-event-actions,
.k-event > .k-link,
.k-task > .k-link
{
    position: absolute;
    top: 3px;
    right:4px;
    white-space: nowrap;
}

.k-event-actions
{
    z-index: 1;
}

.k-scheduler-agendaview .k-task > .k-link
{
    top: 0;
    right: 0;
}

.k-event-actions:first-child
{
    position: static;
    float: left;
    margin: 4px 2px 0 4px;
}

.k-webkit .k-event-actions:first-child
{
    margin-top: 3px;
}

.k-event-actions:first-child > .k-link
{
    display: inline-block;
}

.k-event-delete
{
    display: none;
}

.k-event:hover .k-event-delete,
tr:hover > td > .k-task .k-event-delete
{
    display: inline-block;
}

.k-event .k-event-top-actions,
.k-event .k-event-bottom-actions
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.k-event .k-event-bottom-actions
{
    top: auto;
    bottom: 0;
}

.k-event .k-resize-handle,
.k-scheduler-mobile .k-event:hover .k-resize-handle
{
    position: absolute;
    visibility: hidden;
    z-index: 2;
}

.k-event:hover .k-resize-handle,
.k-event-active .k-resize-handle,
.k-scheduler-mobile .k-event-active:hover .k-resize-handle
{
    visibility: visible;
}

.k-event .k-resize-handle:after
{
    content: "";
    position: absolute;
    opacity: .5;
}

.k-scheduler-mobile .k-event .k-resize-handle:after
{
    filter: none;
    opacity: 1;
}

.k-event > .k-resize-n
{
    top: 0;
    left: 0;
    right: 0;
    height: .4em;
}

.k-event > .k-resize-s
{
    bottom: 0;
    left: 0;
    right: 0;
    height: .4em;
}

.k-event > .k-resize-e
{
    right: 0;
    top: 0;
    bottom: 0;
    width: .4em;
}

.k-event > .k-resize-w
{
    left: 0;
    top: 0;
    bottom: 0;
    width: .4em;
}

.k-event > .k-resize-n:after,
.k-event > .k-resize-s:after
{
    top: 1px;
    left: 50%;
    margin-left: -1em;
    width: 2em;
    height: 1px;
}

.k-event > .k-resize-s:after
{
    top: auto;
    bottom: 1px;
}

.k-event > .k-resize-e:after,
.k-event > .k-resize-w:after
{
    left: 1px;
    top: 50%;
    margin-top: -.7em;
    height: 1.4em;
    width: 1px;
}

.k-event > .k-resize-e:after
{
    left: auto;
    right: 1px;
}

.k-scheduler-mobile .k-event > .k-resize-n,
.k-scheduler-mobile .k-event > .k-resize-s
{
    height: .6em;
}

.k-scheduler-mobile .k-event > .k-resize-e,
.k-scheduler-mobile .k-event > .k-resize-w
{
    width: .6em;
}

.k-scheduler-mobile .k-event > .k-resize-n:after,
.k-scheduler-mobile .k-event > .k-resize-s:after
{
    top: 0;
    margin-left: -3em;
    width: 4em;
    height: .6em;
}

.k-scheduler-mobile .k-event > .k-resize-s:after
{
    bottom: 0;
}

.k-scheduler-mobile .k-event > .k-resize-e:after,
.k-scheduler-mobile .k-event > .k-resize-w:after
{
    left: 0;
    margin-top: -.7em;
    height: 1.4em;
    width: .6em;
}

.k-scheduler-mobile .k-event > .k-resize-e:after
{
    right: 0;
}

.k-scheduler-mobile .k-event > .k-resize-n:after
{
    border-radius: 0 0 4px 4px;
}

.k-scheduler-mobile .k-event > .k-resize-s:after
{
    border-radius: 4px 4px 0 0;
}

.k-scheduler-mobile .k-event > .k-resize-w:after
{
    border-radius: 0 4px 4px 0;
}

.k-scheduler-mobile .k-event > .k-resize-e:after
{
    border-radius: 4px 0 0 4px;
}

.k-scheduler-marquee
{
    border-style: solid;
    border-width: 0;
}

.k-scheduler-marquee.k-first:before,
.k-scheduler-marquee.k-last:after
{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px;
}

div.k-scheduler-marquee:before
{
    top: 0;
    left: 0;
    border-right-color: transparent;
    border-bottom-color: transparent;
}

div.k-scheduler-marquee:after
{
    bottom: 0;
    right: 0;
    border-top-color: transparent;
    border-left-color: transparent;
}

.k-scheduler-marquee .k-label-top
{
    position: absolute;
    top: .3em;
    left: .8em;
    font-size: .8em;
}

.k-scheduler-marquee .k-label-bottom
{
    position: absolute;
    bottom: .3em;
    right: .81em;
    font-size: .8em;
}

.k-scheduler-quickedit .k-textbox
{
    width: 200px;
}

.k-scheduler-agendaview .k-scheduler-table th,
.k-scheduler-agendaview .k-scheduler-table td
{
    text-align:left;
}

.k-scheduler-times .k-slot-cell,
.k-scheduler-groupcolumn
{
    width: 6em;
}

.k-scheduler-datecolumn
{
    width: 12em;
}

.k-scheduler-timecolumn
{
    width: 11em;
}

.k-scheduler-timecolumn > div
{
    position:relative;
    vertical-align: top;
}

.k-webkit .k-scheduler-timecolumn > div > .k-icon
{
    vertical-align: top;
}

.k-scheduler-timecolumn > div > .k-i-arrow-60-right
{
    position: absolute;
    right: -4px;
}

.k-scheduler-timecolumn .k-i-arrow-60-left
{
    margin-left: -4px;
}

.k-scheduler-mark
{
    display:inline-block;
    width:1em;
    height:1em;
    vertical-align:middle;
    margin-right:.5em;
}

.k-scheduler-agendaday
{
    float:left;
    margin:0 .2em 0 0;
    font-size:3em;
    font-weight:normal;
}

.k-scheduler-agendaweek
{
    display:block;
    margin:.4em 0 0;
    font-size:1.1em;
    font-style:normal;
}

.k-scheduler-agendadate
{
    font-size:.8em;
}

.k-scheduler-timecolumn
{
    white-space:nowrap;
}

.k-scheduler-edit-form .k-edit-form-container,
.k-scheduler-timezones .k-edit-form-container
{
    width: 520px;
}

.k-scheduler-edit-form .k-edit-label
{
    width: 17%;
}

.k-scheduler-edit-form .k-edit-field
{
    width: 77%;
}

.k-scheduler-edit-form .k-textbox[name="title"],
.k-scheduler-edit-form textarea.k-textarea
{
    width: 100%;
}

.k-scheduler-edit-form textarea.k-textarea
{
    min-height: 4em;
    resize: vertical;
}

.k-scheduler-edit-form > .k-edit-box:first-child .k-datetimepicker
{
    margin-right: 1em;
}

.k-edit-box
{
    float: left;
}

.k-edit-box + .k-edit-box
{
    float: right;
}

.k-scheduler-edit-form label + input
{
    margin-left: 1em;
}

.k-edit-field > ul.k-reset > li
{
    margin: .2em 0 .4em;
    line-height: 2.4;
}

.k-edit-field > ul.k-reset.k-toolbar > li
{
    margin: 0;
}

.k-edit-field > ul.k-reset .k-widget
{
    margin-left: .8em;
}

.k-edit-field > ul.k-reset .k-numerictextbox,
.k-edit-field span.k-recur-interval
{
    width: 5em;
}

.k-edit-field > ul.k-reset .k-dropdown,
.k-edit-field > ul.k-reset .k-datepicker,
div[name="recurrenceRule"] > .k-dropdown
{
    width: 9em;
}

.k-scheduler-edit-form .k-edit-buttons .k-scheduler-delete,
.k-scheduler-edit-form .k-edit-buttons .k-scheduler-resetSeries
{
    float: left;
}

.k-popup-message
{
    margin:0;
    padding: 1em 0 2em;
    text-align: center;
}

.k-scheduler-timezones .k-dropdown:first-child
{
    width: 100%;
}

.k-scheduler-timezones .k-dropdown + .k-dropdown
{
    margin: .5em 0 .7em;
}

.k-scheduler-refresh
{
    float: right;
    padding: 0 .5em;
}

// Responsive styles

@media (max-width: 480px) {
    .k-scheduler-timecolumn {
        width: 5em;
    }

    .k-scheduler-datecolumn {
        width: 6em;
        overflow: hidden;
    }

    .k-scheduler-timecolumn > div,
    .k-scheduler-datecolumn > div {
        white-space: normal;
    }
}

// Remove scrollbars during PDF export
.k-scheduler-pdf-export {
    overflow: hidden;
}

.k-pdf-export-shadow .k-scheduler,
.k-scheduler-pdf-export .k-scheduler-content,
.k-scheduler-pdf-export .k-scheduler-times
{
    height: auto !important;
    overflow: visible !important;
}

.k-scheduler-pdf-export .k-scheduler-header
{
    padding: 0 !important;
}

.k-scheduler-pdf-export .k-scheduler-header-wrap
{
    border-width: 0 !important;
}

.k-scheduler-pdf-export .k-scheduler-header .k-scheduler-table,
.k-scheduler-pdf-export .k-scheduler-content .k-scheduler-table
{
    width: 100% !important;
}




// RTL
.k-rtl .k-scheduler .k-scheduler-navigation .k-i-arrow-60-right,
.k-rtl .k-scheduler .k-scheduler-navigation .k-i-arrow-60-left {
    transform: scaleX(-1);
}
