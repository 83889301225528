/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Wizard
.k-wizard {
    padding: @wizard-padding-y @wizard-padding-x;
    border-width: @wizard-border-width;
    font-size: @wizard-font-size;
    line-height: @wizard-line-height;
    position: relative;
    color: inherit;
    background: none;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    background: none;
}

// Stepper
.k-wizard .k-stepper {
    .k-step-list-vertical {
        .k-step {
            flex: 1 0 auto;
        }
    }

    .k-step-indicator {
        flex-shrink: 0;
    }
}

// Steps
.k-wizard-steps {
    display: flex;
    overflow: hidden;
}

.k-wizard-step {
    padding: calc( (@wizard-content-padding-x / 4) + 2px );
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    &:focus,
    &.k-state-focused {
        outline-width: 1px;
        outline-style: dotted;
        outline-offset: -1px;
    }
}

//  Content
.k-wizard-content {
    overflow-y: auto;
}

// Buttons
.k-wizard-buttons {
    margin-top: @wizard-content-padding-y;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    .k-button {
        margin-right: @wizard-buttons-margin-x;
    }

    .k-wizard-pager {
        vertical-align: middle;
    }
}

.k-wizard-buttons-right .k-button:last-of-type {
    margin-right: 0;
}

// Horizontal
.k-wizard-horizontal {
    flex-direction: column;

    .k-wizard-steps {
        margin-top: @wizard-content-padding-y;
    }

    .k-wizard-steps,
    .k-wizard-content {
        flex: 1 1 auto;
    }

}

// Vertical
.k-wizard-vertical {
    .k-wizard-steps {
        flex: 1 0 0%;
    }

    .k-wizard-content {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
    }

    .k-wizard-steps {
        margin-left: @wizard-content-padding-x;
    }

}

.k-wizard-left {
    flex-direction: row-reverse;
}
.k-wizard-left .k-wizard-steps {
    margin-right: @wizard-content-padding-x;
    margin-left: 0;
}
