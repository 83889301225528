/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Virtual List

.k-virtual-wrap {
    position: relative;
}

.k-virtual-wrap .k-list.k-virtual-list {
    height: auto;
}

.k-virtual-content {
    overflow-y: scroll; // has to be scroll, not auto
    -webkit-overflow-scrolling: touch;
    position: relative;
}

.k-virtual-list > .k-virtual-content {
    position: absolute;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.k-virtual-option-label {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.k-virtual-wrap > .k-virtual-header {
    text-align: right;
}

.k-popup .k-item.k-first
{
    position: relative;
}

.k-virtual-content > .k-virtual-list > .k-virtual-item {
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
}

.k-popup .k-list .k-item > .k-group,
.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header
{
    text-transform: uppercase;
    font-size: .857em;
}

.k-popup .k-list .k-item > .k-group {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 .5em;
    line-height: 1.8;
}

.k-popup .k-state-hover > .k-group {
    right: -1px;
}

.k-virtual-item.k-first,
.k-group-header + .k-list > .k-item.k-first,
.k-static-header + .k-list > .k-item.k-first
{
    border-top-style: solid;
    border-top-width: 1px;
    padding-top: 0;
}

.k-popup > .k-group-header,
.k-popup > .k-virtual-wrap > .k-group-header
{
    text-align: right;
}
