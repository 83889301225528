/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.mobile-alternate-background (@a) when (lightness(@a) >= 50%) {
    @mobile-alternate-background: darken(@a, 80%);
}
.mobile-alternate-background (@a) when (lightness(@a) < 50%) {
    @mobile-alternate-background: lighten(@a, 80%);
}

@mobile-second-color: #bababa;
.mobile-alternate-background(@selected-text-color);
@mobile-list-background: darken(@widget-background-color, 5%);
@mobile-button-background: fade(@widget-background-color, 20%);
@mobile-border-color: fade(@widget-text-color, 20%);
@mobile-second-active-background: #da2228;
@mobile-light-shadow: @mobile-button-background;
@mobile-overlay-background: rgba(0,0,0,.4);

@mobile-border-width: 1px;

// Border-radius

.km-buttongroup > *:first-child
{
    border-radius: @main-border-radius 0 0 @main-border-radius;
}

.km-buttongroup > *:last-child
{
    border-radius: 0 @main-border-radius @main-border-radius 0;
}

.km-listinset > li:first-child,
.km-popover-root .km-popup .km-header,
.km-listgroupinset .km-list > li:first-child
{
    border-radius: @main-border-radius @main-border-radius 0 0;
}

.km-listinset > li:last-child,
.km-listgroupinset .km-list > li:last-child
{
    border-radius: 0 0 @main-border-radius @main-border-radius;
}

.km-button,
.km-switch-handle,
.km-filter-wrap > input,
.km-popover-root .km-popup,
.km-actionsheet-wrapper.km-popup,
.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox],
.km-listinset > li:first-child:last-child,
.km-listgroupinset .km-list > li:first-child:last-child
{
    border-radius: @main-border-radius;
}

.km-switch-wrapper,
.km-switch-container,
.km-switch-background
{
    border-radius: (@main-border-radius + 1px);
}

.km-actionsheet-wrapper.km-popup
{
    padding: @main-border-radius 0;
    box-shadow: 0 0 10px @mobile-overlay-background;
}

.km-widget,
.km-content,
.km-popover-root .km-content .km-widget
{
    color: @widget-text-color;
    background: @widget-background-color;
}

.km-navbar
{
    color: @header-text-color;
    background: @header-background-color;
}

.km-view
{
    background: transparent;
}

// NavBar

.km-navbar
{
    border-color: @mobile-border-color;
}

// Button

.km-button
{
    background: @mobile-button-background;
    border-color: @mobile-border-color;
}

.km-state-active[style*=background]
{
    .box-shadow(inset 0 0 0 1000px lighten(@mobile-overlay-background, 50%));
}

// Badges and Details

.km-detail
{
    color: @widget-text-color;
    border-color: @mobile-border-color;
    background-color: @widget-background-color;
}

.km-detail:active,
.km-state-active .km-detail
{
    .box-shadow(inset 0 0 0 1000px @mobile-button-background);
}

// Switch

.km-switch-wrapper
{
    background-color: @mobile-second-color;
}

.km-switch-background
{
    .composite-background(~"@{mobile-second-color}, @{mobile-second-color}");
    background-position: 4.3em 0;
    background-repeat: no-repeat;
    background-color: currentcolor;
}

.km-switch-handle
{
    background: @widget-background-color;
}

.km-switch-off .km-switch-handle
{
    -webkit-box-shadow: 0 1px 0 1px @mobile-second-color, 0 -1px 0 1px @mobile-second-color;
    box-shadow: 0 1px 0 1px @mobile-second-color, 0 -1px 0 1px @mobile-second-color;
}

.km-switch-wrapper,
.km-switch-container,
.km-switch-background
{
    border-radius: (@main-border-radius + 1px);
}

.km-switch-handle
{
    border-radius: @main-border-radius;
}

.km-switch-container,
.km-switch-wrapper
{
    border-color: @widget-border-color;
}

// ListView

.km-list > li
{
    background: @mobile-list-background;
    border-color: @mobile-border-color;
    .box-shadow(inset 0 @mobile-border-width 0 @mobile-light-shadow);
}

.km-listinset > li:first-child,
.km-listgroupinset .km-list > li:first-child
{
    border-color: @mobile-border-color;
}

.km-listinset > li:last-child,
.km-listgroupinset .km-list > li:last-child
{
    border-color: @mobile-border-color;
}

.km-listview-link:after
{
    color: lighten(@widget-text-color, 30);
}

.km-group-title
{
    background: @widget-background-color;
    border-color: @mobile-border-color;
}

// ScrollView

.km-pages li
{
    background: @mobile-border-color;
}

// Filter box

.km-filter-wrap:before,
.km-filter-reset .km-clear
{
    color: @widget-text-color;
}

.km-filter-wrap > input
{
    color: @widget-text-color;
    border-color: @mobile-border-color;
}

.km-filter-wrap > input:focus
{
    border-color: #000;
}

// Forms

.km-list input[type=password],
.km-list input[type=search],
.km-list input[type=number],
.km-list input[type=tel],
.km-list input[type=url],
.km-list input[type=email],
.km-list input[type=month],
.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list input[type=text]:not(.k-input),
.km-list select:not([multiple]),
.km-list .k-dropdown-wrap,
.km-list textarea
{
    color: @widget-text-color;
}

.km-list .k-dropdown-wrap .k-input
{
    color: @widget-text-color;
}

.km-list select:not([multiple]) option
{
    color: #333;
}

.km-list .km-state-active input[type=password],
.km-list .km-state-active input[type=search],
.km-list .km-state-active input[type=number],
.km-list .km-state-active input[type=tel],
.km-list .km-state-active input[type=url],
.km-list .km-state-active input[type=email],
.km-list .km-state-active input[type=month],
.km-list .km-state-active input[type=color],
.km-list .km-state-active input[type=week],
.km-list .km-state-active input[type=date],
.km-list .km-state-active input[type=time],
.km-list .km-state-active input[type=datetime],
.km-list .km-state-active input[type=datetime-local],
.km-list .km-state-active input[type=text]:not(.k-input),
.km-list .km-state-active select:not([multiple]),
.km-list .km-state-active textarea
{
    color: #fff;
}

// Checkboxes and Radios

.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox]
{
    border-color: @mobile-border-color;
    background: @widget-background-color;
}

.km-listview-label input[type=checkbox]:checked:after
{
    color: #eee;
}

// Shim

.km-shim
{
    background: @mobile-overlay-background;
}

// PopUp + ActionSheet

.km-popup .k-item,
.km-widget.km-actionsheet-wrapper,
.km-widget.km-actionsheet > li > a
{
    background: @mobile-alternate-background;
    border-bottom-color: @mobile-light-shadow;
}

.km-actionsheet-cancel > a
{
    color: @mobile-second-color;
    .box-shadow(inset 0 0 300px rgba(128,128,128,.3));
}

.km-popup
{
    background: @mobile-overlay-background;
}

.km-actionsheet-wrapper,
.km-popup .k-list-container
{
    background: @mobile-overlay-background;
    border-top-color: @mobile-light-shadow;
}

// PopOver

.km-popup.km-pane
{
    border-color: @mobile-alternate-background;
    background-color: @mobile-alternate-background;
}

.km-popup-arrow:after,
.km-popup-arrow:before
{
    border-color: rgba(0,0,0,.5) transparent;
}

.km-popup-arrow:after
{
    border-color: @mobile-alternate-background transparent;
}

.km-left .km-popup-arrow:after,
.km-right .km-popup-arrow:after
{
    border-color: transparent @mobile-alternate-background;
}

.km-down .km-popup-arrow:after
{
    border-color: @mobile-alternate-background transparent;
}

// Loader & Pull-to-refresh

.km-loader
{
    background: rgba(0,0,0,.05);
}

.km-loader h1
{
    color: @widget-text-color;
}

// Active States

.km-tabstrip
{
    background: @mobile-alternate-background;
}

.km-badge,
.km-rowinsert,
.km-rowdelete,
html .km-state-active,
.km-switch-label-on,
.km-switch-label-off,
.km-tabstrip .km-button,
.km-popup .k-item,
.km-actionsheet > li > a,
.km-tabstrip .km-state-active,
.km-scroller-pull .km-icon,
.km-popover-root .km-widget,
.km-popup .k-state-hover,
.km-popup .k-state-focused,
.km-popup .k-state-selected,
.km-actionsheet > li > a:active,
.km-actionsheet > li > a:hover,
li.km-state-active .km-listview-link,
li.km-state-active .km-listview-label,
.km-state-active .km-listview-link:after
{
    color: @selected-text-color;
}

.km-loader > *:not(h1),
.km-filter-wrap > input,
.km-root .km-scroller-refresh span:not(.km-template)
{
    background-color: @selected-text-color;
}

.km-switch-handle.km-state-active
{
    background-color: lighten(@widget-background-color, 10%);
}

.km-switch-handle,
.km-switch-background
{
    color: @selected-background-color;
}

.km-rowinsert,
.km-state-active,
.km-scroller-pull,
.km-loader:before,
.km-touch-scrollbar,
.km-pages .km-current-page,
.km-tabstrip .km-state-active,
.km-scroller-refresh.km-load-more,
.km-popup .k-state-hover,
.km-popup .k-state-focused,
.km-popup .k-state-selected,
.km-actionsheet > li > a:active,
.km-actionsheet > li > a:hover,
li.km-state-active .km-listview-link,
li.km-state-active .km-listview-label,
.km-listview-label input[type=radio]:checked,
.km-listview-label input[type=checkbox]:checked
{
    background: @selected-background-color;
}

.km-filter-wrap > input:focus
{
    border-color: @selected-background-color;
}

.km-badge,
.km-rowdelete
{
    background: @mobile-second-active-background;
}
