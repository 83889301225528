/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-dropdown {}


// Native select
select.k-dropdown {
    // .border-radius( @input-border-radius );
    .border-radius( 4px );
    // padding: @input-padding-y @input-padding-x;
    padding: @button-padding-y @button-padding-x;
    border-width: 1px;
    border-style: solid;
    outline: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .1s ease; // sass-lint:disable-block no-transition-all
    cursor: pointer;
}
