/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@multiviewcalendar-bg: @component-bg;
@multiviewcalendar-text: @component-text;
@multiviewcalendar-border: @component-border;

@multiviewcalendar-cell-hovered-bg: @button-hover-background-color;
@multiviewcalendar-cell-hovered-text: @button-hover-text-color;
@multiviewcalendar-cell-hovered-border: @button-hover-border-color;

@multiviewcalendar-cell-selected-bg: @calendar-cell-selected-bg;
@multiviewcalendar-cell-selected-text: @calendar-cell-selected-text;
@multiviewcalendar-cell-selected-border: @calendar-cell-selected-border;

@multiviewcalendar-cell-focused-shadow: @calendar-cell-focused-shadow;

@multiviewcalendar-cell-range-bg: fade(@multiviewcalendar-cell-selected-bg, 30);

// Multiview calendar
.k-calendar-range {
    .fill(
        @multiviewcalendar-text,
        @multiviewcalendar-bg,
        @multiviewcalendar-border
    );

    th {
        color: inherit;
        background-color: transparent;
        text-transform: uppercase;
    }


    // States
    td.k-state-hover {
        .k-link {
            .fill(
                @multiviewcalendar-cell-hovered-text,
                @multiviewcalendar-cell-hovered-bg,
                @multiviewcalendar-cell-hovered-border
            )
        }
    }
    td.k-state-selected {
        .k-link {
            .fill(
                @multiviewcalendar-cell-selected-text,
                @multiviewcalendar-cell-selected-bg,
                @multiviewcalendar-cell-selected-border
            )
        }
    }
    td.k-state-focused {
        .k-link {
            box-shadow: @multiviewcalendar-cell-focused-shadow;
        }
    }


    // Range
    td.k-range-start,
    td.k-range-mid,
    td.k-range-end {
        background-image: linear-gradient( transparent 1px, @multiviewcalendar-cell-range-bg 1px, @multiviewcalendar-cell-range-bg calc(100% - 1px), transparent calc(100% - 1px) );

        .k-ie &,
        .k-edge & {
            background-image: none;
            background-color: @multiviewcalendar-cell-range-bg;
        }
    }
    td.k-range-split-start::after {
        background-image: linear-gradient( to left, @multiviewcalendar-cell-range-bg, transparent);
    }
    td.k-range-split-end::after {
        background-image: linear-gradient( to right, @multiviewcalendar-cell-range-bg, transparent);
    }


    // Footer
    .k-footer {
        .k-nav-today {
            color: @accent;
            background: none;
        }
    }
}
