/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// PopOver

.km-popover-root .km-popup-wrapper
{
    position: relative !important;
}

.km-popup-wrapper,
.km-modalview-wrapper
{
    z-index: 10001;
    position: relative;
    background: none;
    border: 0;
    .box-shadow(none);
}

.km-popup-overlay
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10002;
}

.km-popup-arrow,
.km-popup-arrow:after,
.km-popup-arrow:before
{
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
    z-index: 2;
}

.km-left .km-popup-arrow,
.km-right .km-popup-arrow
{
    margin-top: -8px;
}

.km-up .km-popup-arrow,
.km-down .km-popup-arrow
{
    margin-left: -8px;
}

.km-popup-arrow:after,
.km-popup-arrow:before
{
    display: block;
    content: "\a0";
    width: 0;
    height: 0;
}

.km-up .km-popup-arrow
{
    top: auto;
    bottom: 0;
}

.km-left .km-popup-arrow
{
    left: auto;
    right: 0;
}

.km-popup.km-pane
{
    .box-sizing(border-box);
    width: 100%;
    height: 100%;
    min-height: 100px;
    background: transparent;
}

.km-popover-root .km-view
{
    position: relative;
}

.km-popover-root .km-content
{
    -ms-flex: auto;
}
