/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-pdf-viewer {
    .fill(
        @pdf-viewer-text,
        @pdf-viewer-bg,
        @pdf-viewer-border
    );


    // Toolbar
    .k-toolbar {
        .k-icon,
        .k-pager-input {
            color: @pdf-viewer-toolbar-button-bg;
        }
    }


    // Canvas
    .k-canvas {
        .fill(
            @pdf-viewer-canvas-text,
            @pdf-viewer-canvas-bg,
            @pdf-viewer-canvas-border
        );
    }


    // Page
    .k-page {
        .fill(
            @pdf-viewer-page-text,
            @pdf-viewer-page-bg,
            @pdf-viewer-page-border
        );
        .box-shadow( @pdf-viewer-page-shadow );
    }


    // Search
    .k-search-highlight {
        background-color: @pdf-viewer-search-highlight-bg;
    }

}
