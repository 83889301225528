/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@utils-overflow: auto, hidden, visible, scroll, clip;

.overflow-shorthand( @overflow-x ) {

    each( @utils-overflow, {
        @overflow-y: @value;

        .k-overflow-@{overflow-x}-@{overflow-y} { overflow: @overflow-x @overflow-y !important; }
    });

}

each( @utils-overflow, {
    .k-overflow-@{value} { overflow: @value !important; }
    .k-overflow-x-@{value} { overflow-x: @value !important; }
    .k-overflow-y-@{value} { overflow-y: @value !important; }

    .overflow-shorthand(@value);
});
