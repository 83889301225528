/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// ScrollView
kendo-scrollview.k-scrollview-wrap,
div.k-scrollview {
    box-sizing: border-box;
    outline: 0;
    font-family: @scrollview-font-family;
    font-size: @scrollview-font-size;
    line-height: @scrollview-line-height;
    position: relative;
    overflow: hidden;
    display: block;
    -webkit-touch-callout: none;

    &.k-scrollview-multidot .k-scrollview-pageable > .k-button,
    &.k-scrollview-multidot .k-scrollview-nav > .k-link {

        &:nth-last-of-type(1),
        &:nth-of-type(1) {
            width: calc( @scrollview-pagebutton-size - 6px );
            height: calc( @scrollview-pagebutton-size - 6px );
        }

        &:nth-last-of-type(2),
        &:nth-of-type(2) {
            width: calc( @scrollview-pagebutton-size - 4px );
            height: calc( @scrollview-pagebutton-size - 4px );
        }

        &:nth-last-of-type(3),
        &:nth-of-type(3) {
            width: calc( @scrollview-pagebutton-size - 2px );
            height: calc( @scrollview-pagebutton-size - 2px );
        }
    }
}

kendo-scrollview.k-scrollview-wrap .k-scrollview,
div.k-scrollview .k-scrollview-wrap {
    list-style-type: none;
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    cursor: default;
    white-space: nowrap;

    img {
        user-select: none;
    }

    & > li {
        display: inline-block;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }

    & > [data-role="page"] {
        vertical-align: top;
        display: inline-block;
        min-height: 1px;
    }
}

kendo-scrollview.k-scrollview-wrap kendo-scrollview-pager,
.k-scrollview .k-scrollview-nav-wrap {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: @scrollview-pager-offset;
    height: @scrollview-pager-height;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable,
.k-scrollview .k-scrollview-nav {
    margin: 0;
    padding: @scrollview-pager-item-spacing;
    overflow-x: scroll;
    overflow-y: hidden;
    width: calc( 100% - (@scrollview-pager-item-spacing * 2));
    line-height: 0;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    list-style: none;
    white-space: nowrap;
    pointer-events: initial;

    &:focus,
    &.k-state-focused {
        outline: none;
    }
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button,
.k-scrollview .k-scrollview-nav > .k-link {
    margin: 0 (@scrollview-pager-item-spacing / 2);
    padding: 0;
    width: @scrollview-pagebutton-size;
    height: @scrollview-pagebutton-size;
    box-sizing: content-box;
    display: inline-block;
    position: relative;
    flex: 0 0 auto;
    border-width: @scrollview-pager-item-border-width;
    border-style: solid;
    border-radius: 50%;
    cursor: pointer;
    pointer-events: all;
    vertical-align: top;

    &::before {
        content: "";
        width: (@scrollview-pagebutton-size + @scrollview-pager-item-spacing);
        height: (@scrollview-pagebutton-size + @scrollview-pager-item-spacing);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: initial;
        border-radius: 0;
    }
}

.k-scrollview-next,
.k-scrollview-prev {
    display: table;
    position: absolute;
    padding: 0;
    height: 60%;
    top: 20%;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    .k-icon {
        display: table-cell;
        overflow: visible;
        margin: 0;
        padding: 0;
        vertical-align: middle;
        font-size: @scrollview-navigation-size;
        font-weight: normal;
    }
}

.k-scrollview-prev {
    left: 0;
}

.k-scrollview-next {
    right: 0;
}


.k-scrollview-animation {
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
}


@supports (-webkit-user-select: none) {
    kendo-scrollview.k-scrollview-wrap ul.k-scrollview li > *,
    div.k-scrollview ul.k-scrollview-wrap li > * {
        pointer-events: auto;
    }
}

@supports (not (-webkit-user-select: none)) {
    kendo-scrollview.k-scrollview-wrap ul.k-scrollview li > *,
    div.k-scrollview ul.k-scrollview-wrap li > * {
        pointer-events: none;
    }
}
