/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// .linear-gradient( @gradient ) when not ( @gradient = false ) {
//     background-image: linear-gradient( @gradient );
// }
.linear-gradient( @gradient ) when ( @gradient = none ) {
    background-image: none;
}


.striped-gradient( @color: rgba(255,255,255,.15), @angle: 45deg) {
    background-image: linear-gradient( @angle, @color 25%, transparent 25%, transparent 50%, @color 50%, @color 75%, transparent 75%, transparent);
}

.repeating-striped-gradient( @color: rgba(255,255,255,.15), @bg: #FFF, @angle: 45deg, @largeStep: 2px, @smallStep: 1px) {
    background-image: repeating-linear-gradient(@angle, @bg, @bg @smallStep, @color @smallStep, @color @largeStep);
}

.checkerboard-gradient ( @primary-color: white, @secondary-color: rgba(0, 0, 0, 0.09), @size: 10px ) {
    @position: calc(@size / 2);
    background: linear-gradient(45deg, @secondary-color 25%, transparent 25%, transparent 75%, @secondary-color 75%, @secondary-color 0),
                linear-gradient(45deg, @secondary-color 25%, transparent 25%, transparent 75%, @secondary-color 75%, @secondary-color 0),
                @primary-color;
    background-repeat: repeat, repeat;
    background-position: 0px 0px, @position @position;
    transform-origin: 0 0 0;
    background-origin: padding-box, padding-box;
    background-clip: border-box, border-box;
    background-size: @size @size, @size @size;
    box-shadow: none;
    text-shadow: none;
    transition: none;
    transform: scaleX(1) scaleY(1) scaleZ(1);
}
