/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../mobile/common/switch.less";
@import "base.less";

// Switch

.km-switch
{
    width: 4.2em;
    height: 1.9em;
    line-height: 1.76em;
    overflow: hidden;
    vertical-align: middle;
}

.km-switch-wrapper
{
    overflow: hidden;
}

.km-switch-background
{
    width: 118%;
    position: relative;
    left: -95%;
    margin-left: 0;
    background: currentcolor;
}

.km-switch .km-switch-background
{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.km-switch-container
{
    padding: 1px 0 1px 1px;
    border-width: 0;
}

.km-switch-handle
{
    width: 1.6em;
    margin: 0 4px 0 0;
    border: @mobile-border-width solid transparent;
    background-clip: padding-box;
    -webkit-box-shadow: 0 1px 0 1px currentcolor, 0 -1px 0 1px currentcolor;
    box-shadow:0 1px 0 1px currentcolor, 0 -1px 0 1px currentcolor;
}

.km-switch-label-off
{
    left: 101%;
}

.km-switch-label-on
{
    left: -171%;
}

.km-switch-label-on,
.km-switch-label-off
{
    text-shadow: none;
    width: 170%;
    font-size: 1em;
    line-height: 1.76em;
    vertical-align: middle;
    top: -2px;
}

.km-switch-container,
.km-switch-wrapper
{
    .box-sizing(border-box);
    border-width: @mobile-border-width;
    border-style: solid;
    background-clip: content-box;
}
