/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// animation classes

@duration: 350ms;

.k-fx-end .k-fx-next,
.k-fx-end .k-fx-current {
    transition: all @duration ease-out;
}

.k-fx {
    position: relative;

    .k-fx-current {
        z-index: 0;
    }

    .k-fx-next {
        z-index: 1;
    }
}

.k-fx-hidden,
.k-fx-hidden * {
    visibility: hidden !important;
}

.k-fx-reverse {
    .k-fx-current {
        z-index: 1;
    }

    .k-fx-next {
        z-index: 0;
    }
}

// Zoom

.k-fx-zoom {
    &.k-fx-start .k-fx-next {
        .transform(scale(0)) !important;
    }

    &.k-fx-end .k-fx-next {
        .transform(scale(1)) !important;
    }

    &.k-fx-reverse {
        &.k-fx-start .k-fx-next,
        &.k-fx-end .k-fx-next {
            .transform(scale(1)) !important;
        }

        &.k-fx-start .k-fx-current {
            .transform(scale(1)) !important;
        }

        &.k-fx-end .k-fx-current {
            .transform(scale(0)) !important;
        }
    }
}

// Fade
.k-fx-fade {
    &.k-fx-start .k-fx-next {
        will-change: opacity;
        opacity: 0;
    }

    &.k-fx-end .k-fx-next {
        opacity: 1;
    }

    &.k-fx-reverse {
        &.k-fx-start .k-fx-current {
            will-change: opacity;
            opacity: 1;
        }

        &.k-fx-end .k-fx-current {
            opacity: 0;
        }
    }
}

// Slide
.k-fx-slide {
    &.k-fx-end .k-fx-next .k-content,
    &.k-fx-end .k-fx-next .k-header,
    &.k-fx-end .k-fx-next .k-footer,
    &.k-fx-end .k-fx-current .k-content,
    &.k-fx-end .k-fx-current .k-header,
    &.k-fx-end .k-fx-current .k-footer,
    &.k-fx-end .k-fx-next .km-content,
    &.k-fx-end .k-fx-next .km-header,
    &.k-fx-end .k-fx-next .km-footer,
    &.k-fx-end .k-fx-current .km-content,
    &.k-fx-end .k-fx-current .km-header,
    &.k-fx-end .k-fx-current .km-footer {
        transition: all @duration ease-out;
    }

    // left
    &.k-fx-start .k-fx-next .k-content,
    &.k-fx-start .k-fx-next .km-content {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-start .k-fx-next .k-header,
    &.k-fx-start .k-fx-next .k-footer,
    &.k-fx-start .k-fx-next .km-header,
    &.k-fx-start .k-fx-next .km-footer {
        will-change: opacity;
        opacity: 0;
    }

    &.k-fx-end .k-fx-current .k-content,
    &.k-fx-end .k-fx-current .km-content {
        .transform(translatex(-100%));
    }

    &.k-fx-end .k-fx-next .k-header,
    &.k-fx-end .k-fx-next .k-footer,
    &.k-fx-end .k-fx-next .km-header,
    &.k-fx-end .k-fx-next .km-footer {
        opacity: 1;
    }

    // left reverse
    &.k-fx-reverse {
        &.k-fx-start .k-fx-current .k-content,
        &.k-fx-start .k-fx-current .km-content {
            will-change: transform;
            .transform(translatex(0));
        }

        &.k-fx-end .k-fx-current .k-content,
        &.k-fx-end .k-fx-current .km-content {
            .transform(translatex(100%));
        }

        &.k-fx-start .k-fx-next .k-content,
        &.k-fx-start .k-fx-next .km-content {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-next .k-content,
        &.k-fx-end .k-fx-next .km-content {
            .transform(translatex(0));
        }

        &.k-fx-start .k-fx-current .k-header,
        &.k-fx-start .k-fx-current .k-footer,
        &.k-fx-start .k-fx-current .km-header,
        &.k-fx-start .k-fx-current .km-footer {
            will-change: opacity;
            opacity: 1;
        }

        &.k-fx-start .k-fx-next .k-header,
        &.k-fx-start .k-fx-next .k-footer,
        &.k-fx-start .k-fx-next .km-header,
        &.k-fx-start .k-fx-next .km-footer {
            opacity: 1;
        }

        &.k-fx-end .k-fx-current .k-header,
        &.k-fx-end .k-fx-current .k-footer,
        &.k-fx-end .k-fx-current .km-header,
        &.k-fx-end .k-fx-current .km-footer {
            opacity: 0;
        }

        &.k-fx-end .k-fx-next .k-header,
        &.k-fx-end .k-fx-next .k-footer,
        &.k-fx-end .k-fx-next .km-header,
        &.k-fx-end .k-fx-next .km-footer {
            opacity: 1;
        }
    }

    // right
    &.k-fx-right {
        &.k-fx-start .k-fx-next .k-content,
        &.k-fx-start .k-fx-next .km-content {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-current .k-content,
        &.k-fx-end .k-fx-current .km-content {
            .transform(translatex(100%));
        }

        // right reverse
        &.k-fx-reverse {
            &.k-fx-start .k-fx-current .k-content,
            &.k-fx-start .k-fx-current .km-content {
                .transform(translatex(0));
            }

            &.k-fx-end .k-fx-current .k-content,
            &.k-fx-end .k-fx-current .km-content {
                .transform(translatex(-100%));
            }

            &.k-fx-start .k-fx-next .k-content,
            &.k-fx-start .k-fx-next .km-content {
                .transform(translatex(100%));
            }

            &.k-fx-end .k-fx-next .k-content,
            &.k-fx-end .k-fx-next .km-content {
                .transform(translatex(0%));
            }
        }
    }
}

// Tile
.k-fx-tile {
    // left
    &.k-fx-start .k-fx-next {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-end .k-fx-current {
        .transform(translatex(-100%));
    }

    // left reverse
    &.k-fx-reverse {
        &.k-fx-start .k-fx-current {
            will-change: transform;
            .transform(translatex(0));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-next {
            .transform(translatex(0));
        }
    }

    // right
    &.k-fx-right {
        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        // right reverse
        &.k-fx-reverse {
            &.k-fx-start .k-fx-current {
                .transform(translatex(0));
            }

            &.k-fx-end .k-fx-current {
                .transform(translatex(-100%));
            }

            &.k-fx-start .k-fx-next {
                .transform(translatex(100%));
            }

            &.k-fx-end .k-fx-next {
                .transform(translatex(0%));
            }
        }
    }
}

// Tile
.k-fx-tile {
    // left
    &.k-fx-start .k-fx-next {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-end .k-fx-current {
        .transform(translatex(-100%));
    }

    // left reverse
    &.k-fx-reverse {
        &.k-fx-start .k-fx-current {
            will-change: transform;
            .transform(translatex(0));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-next {
            .transform(translatex(0));
        }
    }

    // right
    &.k-fx-right {
        &.k-fx-start .k-fx-next {
            .transform(translatex(-100%));
        }

        &.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        // right reverse
        &.k-fx-reverse {
            &.k-fx-start .k-fx-current {
                .transform(translatex(0));
            }

            &.k-fx-end .k-fx-current {
                .transform(translatex(-100%));
            }

            &.k-fx-start .k-fx-next {
                .transform(translatex(100%));
            }

            &.k-fx-end .k-fx-next {
                .transform(translatex(0%));
            }
        }
    }
}

// Overlay
.k-fx.k-fx-overlay {
    &.k-fx-start .k-fx-next,
    &.k-fx-left.k-fx-start .k-fx-next {
        will-change: transform;
        .transform(translatex(100%));
    }

    &.k-fx-right.k-fx-start .k-fx-next {
        .transform(translatex(-100%));
    }

    &.k-fx-up.k-fx-start .k-fx-next {
        .transform(translatey(100%));
    }

    &.k-fx-down.k-fx-start .k-fx-next {
        .transform(translatey(-100%));
    }

    &.k-fx-reverse {
        &.k-fx-start .k-fx-next {
            .transform(none);
        }

        &.k-fx-start .k-fx-current {
            will-change: transform;
            .transform(none);
        }

        &.k-fx-end .k-fx-current,
        &.k-fx-left.k-fx-end .k-fx-current {
            .transform(translatex(100%));
        }

        &.k-fx-right.k-fx-end .k-fx-current {
            .transform(translatex(-100%));
        }

        &.k-fx-up.k-fx-end .k-fx-current {
            .transform(translatey(100%));
        }

        &.k-fx-down.k-fx-end .k-fx-current {
            .transform(translatey(-100%));
        }
    }
}
