/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-breadcrumb {

    &:focus,
    &.k-state-focused {
        .box-shadow(@breadcrumb-focused-shadow);
    }

    // Normal state
    .k-breadcrumb-link {
        .fill(
            @breadcrumb-link-text,
            @breadcrumb-link-bg,
            @breadcrumb-link-border
        );

        // Hovered state
        &:hover,
        &.k-state-hover {
            .fill(
                @breadcrumb-link-hovered-text,
                @breadcrumb-link-hovered-bg,
                @breadcrumb-link-hovered-border
            );
        }

        // Focused state
        &:focus,
        &.k-state-focused {
            .fill(
                @breadcrumb-link-focused-text,
                @breadcrumb-link-focused-bg,
                @breadcrumb-link-focused-border
            );
            .box-shadow(@breadcrumb-link-focused-shadow);
        }
    }

    .k-breadcrumb-root-link {
       .fill(
            @breadcrumb-root-link-text,
            @breadcrumb-root-link-bg,
            @breadcrumb-root-link-border
        );

        // Hovered state
        &:hover,
        &.k-state-hover {
            .fill(
                @breadcrumb-root-link-hovered-text,
                @breadcrumb-root-link-hovered-bg,
                @breadcrumb-root-link-hovered-border
            );
        }

        // Focused state
        &:focus,
        &.k-state-focused {
            .fill(
                @breadcrumb-root-link-focused-text,
                @breadcrumb-root-link-focused-bg,
                @breadcrumb-root-link-focused-border
            );
            .box-shadow(@breadcrumb-root-link-focused-shadow);
        }
    }

    .k-breadcrumb-root-link,
    .k-breadcrumb-delimiter-icon {
        color: inherit;
    }
}
