/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-rating {
    display: inline-flex;
    box-sizing: border-box;
    white-space: nowrap;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: @rating-font-size;
    box-shadow: none;
    outline: 0;
}

.k-rating-container {
    flex: 0 0 auto;
    position: relative;
    box-sizing: border-box;
    margin: 0 @rating-container-margin-x;

    .k-rating-item {
        display: inline-flex;
        position: relative;
        padding: @rating-item-padding-y @rating-item-padding-x;
        align-items: center;
        vertical-align: middle;
        margin: 0;
        outline: 0;
        overflow: hidden;
        transition: color 200ms ease-out 0s;

        * {
            pointer-events: none;
        }

        .k-icon {
            font-size: @rating-icon-size;
            overflow: visible;
        }

        .k-rating-precision-part,
        .k-rating-precision-complement {
            display: inline-block;
            position: absolute;
            top: @rating-item-padding-y;
            left: @rating-item-padding-x;
            overflow: hidden;
        }

        .k-rating-precision-complement {
            direction: rtl;
        }
    }
}

.k-rating-label {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    line-height: @rating-label-line-height;
    margin: @rating-label-margin-y @rating-label-margin-x;
}

.k-ie9 {
    .k-rating-label,
    .k-rating-container,
    .k-rating-item {
        display: inline-block;
    }
}

.k-rtl,
[dir="rtl"] {
    .k-rating-precision-part  {
        left: auto;
        right: @rating-item-padding-x;
    }

    .k-rating-item .k-rating-precision-complement {
        direction: ltr;
    }
}
