/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.km-listview-link:after
{
    width: .5rem;
    height: .5rem;
    content: "\a0";
    display: inline-block;
    vertical-align: middle;
    margin-left: -.2rem;
    border-style: solid;
    border-width: .24rem .24rem 0 0;
    .transform(rotate(45deg));
}

.km-listview-wrapper > ul:not(.km-listview)
{
    margin: 0 auto;
}

.km-list,
.km-listview
{
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.km-listinset,
.km-listgroupinset
{
    margin: 1em;
}

.k-ff .km-listinset:after,
.k-ff .km-listgroupinset:after
{
    display: block;
    height: 0;
    content: "\a0";
}

.km-listinset,
.km-listgroupinset .km-list
{
    overflow: hidden;
}

.km-listview .km-switch
{
    margin-top: -.95rem;
    position: absolute;
    right: .8rem;
    top: 50%;
}

.km-listview .km-list
{
    text-indent: 0;
}

.km-list > li,
.km-widget .km-listview-link,
.km-widget .km-listview-label
{
    margin: 0;
    display: block;
    position: relative;
    list-style-type: none;
    vertical-align: middle;
    .box-sizing(border-box);
    padding: .5em .7em;
}

.km-list > li
{
    line-height: 1.6em;
    overflow: hidden;
}

.km-virtual-list
{
    position: relative;
    width: 100%;
    -webkit-transform: translateZ(0);
}

.km-virtual-list > li
{
    width: 100%;
    position: absolute;
    top: 0;
    -webkit-transform: translateZ(0);
}

.km-widget.km-list .km-load-more,
.km-widget .km-list .km-load-more
{
    border-bottom: 0;
}

.km-list > li > *
{
    line-height: normal;
}

.km-group-title
{
    display: block;
    font-weight: bold;
    padding: .2em 0;
    text-indent: .8em;
}

.km-listgroupinset .km-group-title
{
    margin-top: .65em;
    line-height: 2em;
}

.km-list:not(.km-virtual-list) > li:first-child
{
    border-top-width: 0;
}

.km-list:not(.km-virtual-list) > li:last-child
{
    border-bottom-width: 0;
}

.km-widget .km-listview-link,
.km-widget .km-listview-label
{
    line-height: inherit;
    text-decoration: none;
    margin: -.5em -.7em;
}

.km-listview-link:after,
.km-listview-label:after
{
    border-color: #777;
    content: "\a0";
    display: block;
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -.32rem;
}

// Filtering

.km-filter-form
{
    width: 100%;
    padding: .5em 0;
    border: 1px solid transparent;
    border-width: 1px 0;
    .transform(translatez(0));
}

.km-filter-wrap
{
    position: relative;
    margin: 0 .7em;
    padding: .2em .4em;
    border: 1px solid transparent;
}

.km-widget .km-filter-wrap:before
{
    display: inline-block;
    vertical-align: middle;
    content: "\e0e9";
    font-size: 1.6em;
    width: 1em;
    height: 1em;
    margin-right: -1em;
    color: inherit;
}

.km-tablet .km-filter-wrap
{
    max-width: 24em;
    margin: 0 auto;
}

.km-filter-wrap > input[type="search"]::-webkit-search-cancel-button
{
    display: none;
}

.km-filter-wrap input
{
    width: 100%;
    .box-sizing(border-box);
    border: 0;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    vertical-align: middle;
    padding: 0 1.4em;
}

.km-filter-reset
{
    display: inline-block;
    margin-left: -1.6em;
    vertical-align: middle;
    text-align: center;
    z-index: 1;
    text-decoration: none;
    height: 100%;
}

.km-filter-reset .km-clear
{
    font-size: 1.6em;
    width: 1em;
    height: 1em;
    display: block;
}

.km-filter-reset > .km-text
{
    position: absolute;
    top: -3333px;
    left: -3333px;
}

// Load more

.km-load-more
{
    display: block;
    padding: .3em 0 1.2em;
    height: 3.2em;
    text-align: center;
}

.km-list .km-listview-item-badge.km-badge,
.km-list .km-listview-link>.km-badge{
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    right: 4em;
}
