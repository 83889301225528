/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Calendar
.k-calendar {
    padding: @calendar-padding-y @calendar-padding-x;
    border-width: @calendar-border-width;
    border-style: solid;
    line-height: @calendar-line-height;
    position: relative;
    overflow: hidden;
    display: inline-block;


    // Common
    .k-link {
        color: inherit;
        text-decoration: none;
        outline: 0;
    }
    .k-action-link {
        text-decoration: underline;
    }
    .k-state-focused,
    .k-state-focused.k-state-selected {
        box-shadow: none;
    }

    table {
        margin: 0;
        padding: 0;
        border-spacing: 0;
        border-width: 0;
        outline: 0;
        table-layout: fixed;
        float: left;
    }
    td,
    th {
        border-width: 0;
        padding: 0;
        text-align: center;
        border-style: solid;
        border-color: inherit;
        color: inherit;
        background: none;
        font-weight: normal;
        cursor: default;
    }


    // Header
    .k-header {
        padding: @calendar-header-padding-y @calendar-header-padding-x;
        border-bottom-width: @calendar-header-border-width;
        border-bottom-style: solid;
        text-align: center;
        position: relative;
        z-index: 1;
    }


    // Footer
    .k-footer {
        padding: .5em;
        text-align: center;
        position: relative;
        clear: both;

        .k-nav-today:hover {
            text-decoration: underline;
        }
    }


    // View wrapper
    .k-calendar-view {
        // setting width / height prevents layout changes in meta views
        width: (7 * @calendar-cell-size);
        height: (7 * @calendar-cell-size);
        position: relative;
        z-index: 1;
        overflow: hidden;
    }
    &.k-week-number .k-calendar-view {
        width: (8 * @calendar-cell-size);
        padding: 0;
    }


    // Content
    .k-content {
        width: 100%;
        background-color: transparent;

        th {
            padding: @calendar-header-cell-padding-y @calendar-header-cell-padding-x;
            border-bottom-width: @calendar-header-cell-border-width;
            box-sizing: border-box;
        }
        td {
            border-radius: @calendar-cell-border-radius;
        }
        td.k-alt {
            width: @calendar-cell-size;
            border-radius: 0;
        }
        .k-link {
            margin: auto;
            padding: @calendar-cell-padding-y @calendar-cell-padding-x;
            box-sizing: border-box;
            border-radius: @calendar-cell-border-radius;
            line-height: @calendar-cell-line-height;
            display: block;
        }
    }


    // Month view
    .k-month {
        .k-link {
            width: @calendar-cell-size;
            height: @calendar-cell-size;
        }

        th {
            height: @calendar-header-cell-size;
        }
    }


    // Year view
    .k-year {
        .k-link {
            width: @calendar-meta-cell-size;
            height: @calendar-meta-cell-size;
            line-height: ( @calendar-meta-cell-size - (@calendar-cell-padding-y * 2) );
        }
    }


    // Decade view
    .k-decade {
        .k-link {
            width: @calendar-meta-cell-size;
            height: @calendar-meta-cell-size;
            line-height: ( @calendar-meta-cell-size - (@calendar-cell-padding-y * 2) );
        }
    }


    // Century view
    .k-century {
        .k-link {
            width: @calendar-meta-cell-size;
            height: @calendar-meta-cell-size;
            text-align: left;
            line-height: (( @calendar-meta-cell-size - (@calendar-cell-padding-y * 2) ) / 2);
            white-space: normal;
        }
    }


    // Animations
    .k-animation-container .k-content {
        height: 100%;
    }

    // Calendar Modern

    .k-calendar-header {
        padding: @calendar-modern-header-padding-y @calendar-modern-header-padding-x;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0 0 auto;

        .k-button {
            white-space: nowrap;
        }
    }
    .k-calendar-nav {
        align-items: center;
    }
    .k-nav-today {
        cursor: pointer;
    }

    .k-calendar-monthview,
    .k-calendar-yearview,
    .k-calendar-decadeview,
    .k-calendar-decadeview {
        padding: 0 @calendar-modern-header-padding-x;

        th {
            border-bottom-width: 0;
            background-color: transparent;
        }

        .k-alt {
            opacity: .5;
            background-color: transparent;
        }

    }

}
