/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Solid
each( @theme-colors, {
    @name: @key;
    @color: @value;

    .k-bottom-nav-solid.k-bottom-nav-@{name} {
        .fill(
            @text: mix( @color, contrast( @color ), 35%),
            @bg: @color
        );

        .k-bottom-nav-item.k-state-selected,
        .k-bottom-nav-item.k-state-active,
        .k-bottom-nav-item:active {
            .fill(
                @text: contrast( @color ),
                @bg: inherit
            );
            .box-shadow(none);
            background-image: none;

        }

        .k-bottom-nav-item.k-state-focus,
        .k-bottom-nav-item.k-state-focused,
        .k-bottom-nav-item:focus {
            .fill( @bg: rgba( mix( @color, contrast( @color ), 35%), .2));
        }
    }
});

.k-bottom-nav-solid .k-bottom-nav-item.k-state-disabled {
    color: inherit;
}

// Flat
.k-bottom-nav-flat {
    .fill(
        @bottom-nav-flat-text,
        @bottom-nav-flat-bg,
        @bottom-nav-flat-border
    );

    each( @theme-colors, {
        @name: @key;
        @color: @value;

        &.k-bottom-nav-@{name} .k-bottom-nav-item.k-state-selected,
        &.k-bottom-nav-@{name} .k-bottom-nav-item.k-state-active,
        &.k-bottom-nav-@{name} .k-bottom-nav-item:active {
            .box-shadow(none);
            background-color: inherit;
            background-image: none;

            & when (@name = light) {
                .fill( @text: shade(@color, 20%) );
            }

            & when not (@name = light) {
                .fill( @text: @color );
            }
        }
    });

    .k-bottom-nav-item.k-state-focus,
    .k-bottom-nav-item.k-state-focused,
    .k-bottom-nav-item:focus,
    .k-bottom-nav-item.k-state-focus.k-state-selected,
    .k-bottom-nav-item.k-state-focused.k-state-selected,
    .k-bottom-nav-item.k-state-selected:focus  {
        .fill( @bg: rgba(@bottom-nav-flat-text, .05) );
    }
}

// Legacy
.k-ie .k-bottom-nav-item {
    background-color: transparent;
}


