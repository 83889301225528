/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Pivot start

.k-pivot
{
    position: relative;
}



.k-pivot-toolbar {
    border-width: 0 0 1px;
    border-style: solid;
    align-items: start;
    flex-wrap: wrap;

    > * {
        max-width: 100%;
    }

    &::before {
        display: none;
    }
}

.k-pivot .k-pivot-toolbar
{
    padding: .6em;
}

.k-pivot-toolbar .k-button
{
    margin-right: .4em;
    line-height: 1.2em;
    font-size: .9em;
    text-align: left;
    position: relative;
    padding: .3em 5em .3em .3em;
    cursor: move;
}

.k-field-actions
{
    position: absolute;
    right: 2px;
    top: 3px;
    cursor: pointer;
}

// IE7 requires the following style to be applied to cells directly
.k-pivot .k-grid td
{
    white-space: nowrap;
}

.k-pivot-layout
{
    border-spacing: 0;
    table-layout: auto;
}

.k-pivot-layout > tbody > tr > td
{
    vertical-align: top;
    padding: 0;
}

.k-pivot td
{
    vertical-align: top;
}

.k-pivot-rowheaders > .k-grid,
.k-pivot-table > .k-grid
{
    border-width: 0;
}

.k-pivot-rowheaders > .k-grid td:first-child,
.k-pivot-table .k-grid-header .k-header.k-first
{
    border-left-width: 1px;
}

.k-pivot-rowheaders > .k-grid td.k-first
{
    border-left-width: 0;
}

.k-pivot-rowheaders > .k-grid
{
    overflow: hidden;
}

.k-pivot-table
{
    border-left-width: 1px;
    border-left-style: solid;
}

.k-pivot-table .k-grid-header-wrap > table
{
    height: 100%;
}

.k-pivot .k-grid-header .k-header
{
    vertical-align: top;
}

.k-header.k-alt,
td.k-alt
{
    font-weight: bold;
}

.k-header.k-alt
{
    background-image: none;
}

.k-pivot-layout .k-grid td
{
    border-bottom-width: 1px;
}

.k-pivot-layout .k-grid-footer > td
{
    border-top-width: 0;
}

.k-pivot-filter-window .k-treeview
{
    max-height: 600px;
}

// selector

.k-fieldselector .k-edit-buttons
{
    bottom: auto;
}

.k-fieldselector .k-edit-label
{
    width: 16%;
}

.k-fieldselector .k-edit-field
{
    width: 77%;
}

.k-fieldselector .k-edit-field > .k-widget,
.k-fieldselector .k-edit-field > .k-textbox
{
    width: 99%;
}

.k-fieldselector .k-edit-buttons > input,
.k-fieldselector .k-edit-buttons > label
{
    float: left;
    margin-top: .4em;
}

.k-fieldselector p
{
    margin: 0 0 .2em .5em;
    text-transform: uppercase;
}

.k-fieldselector p .k-icon
{
    margin: 0 5px 0 0;
}

.k-fieldselector .k-columns
{
    border-style: solid;
    border-width: 0;
}

.k-fieldselector .k-columns > div
{
    overflow: auto;
    padding: .6em;
    border-style: solid;
    border-width: 0 0 0 1px;
    float: left;
    width: 45%;
}

.k-fieldselector .k-columns > div:first-child
{
    border-width: 0;
    margin-right: -1px;
}

.k-fieldselector .k-columns > div + div
{
    float: right;
    border-width: 0;
}

.k-fieldselector div.k-treeview
{
    border-width: 0;
    margin-right: -1px;
    padding-left: 4px;
    overflow: visible;
}

.k-fieldselector .k-list-container
{
    margin-left: .5em;
    margin-bottom: 1em;
    padding: .2em 0 0;
    border-style: solid;
    border-width: 1px;
}

.k-fieldselector .k-list
{
    padding-bottom: 2em;
}

.k-fieldselector .k-list li.k-item
{
    padding: .3em 3.3em .3em .3em;
    margin: 0 .2em .2em;
    position: relative;
    font-size: .9em;
    line-height: 1.2em;
    min-height: 1em;
}

// KPI icons */
.k-i-kpi-trend-increase,
.k-i-kpi-trend-decrease,
.k-i-kpi-trend-equal {
    color: #858585;
}

.k-i-kpi-status-hold {
    color: #f9ce1d;
}

.k-i-kpi-status-deny {
    color: #f44336;
}

.k-i-kpi-status-open {
    color: #4caf50;
}

// Pivot PDF Export styles

.k-pdf-export-shadow .k-pivot .k-grid-content
{
    overflow: visible !important;
}

.k-pdf-export-shadow .k-pivot .k-pivot-rowheaders .k-grid,
.k-pdf-export-shadow .k-pivot .k-grid-content
{
    height: 100% !important;
}

.k-pdf-export-shadow .k-pivot .k-grid-header
{
    padding-right: 0 !important;
}

.k-pdf-export-shadow .k-pivot
{
    width: auto !important;
    height: auto !important
}

.k-pdf-export-shadow .k-pivot .k-grid-header-wrap > table,
.k-pdf-export-shadow .k-pivot .k-grid-content > table
{
    width: 100% !important;
    height: auto !important
}

.k-pivot .k-field-actions .k-icon {
    pointer-events: all;
}

// Pivot end
