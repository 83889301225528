/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@pos: static, relative, absolute, fixed, sticky;
@side: top, right, bottom, left;

each( @pos, {
    .k-@{value},
    .k-pos-@{value} {
        position: @value !important;
    }
});

each( @side, {
    .k-@{value},
    .k-pos-@{value} {
        @{value}: 0;
    }
});

.k-top-start,
.k-pos-top-start {
    top: 0;
    left: 0;

    .k-rtl &,
    &[dir="rtl"],
    [dir="rtl"] & {
        left: auto;
        right: 0;
    }
}
.k-top-center,
.k-pos-top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.k-top-end,
.k-pos-top-end {
    top: 0;
    right: 0;

    .k-rtl &,
    &[dir="rtl"],
    [dir="rtl"] & {
        right: auto;
        left: 0;
    }
}
.k-bottom-start,
.k-pos-bottom-start {
    bottom: 0;
    left: 0;

    .k-rtl &,
    &[dir="rtl"],
    [dir="rtl"] & {
        left: auto;
        right: 0;
    }
}
.k-bottom-center,
.k-pos-bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.k-bottom-end,
.k-pos-bottom-end {
    bottom: 0;
    right: 0;

    .k-rtl &,
    &[dir="rtl"],
    [dir="rtl"] & {
        right: auto;
        left: 0;
    }
}

.k-middle-start,
.k-pos-middle-start {
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}
.k-middle-end ,
.k-pos-middle-end {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}
