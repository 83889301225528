/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Searchbox
.k-searchbox {
    .fill(
        @textarea-text,
        @textarea-bg,
        @textarea-border
    );


    // Hover state
    &:hover,
    &.k-state-hover {
        .fill(
            @textarea-hovered-text,
            @textarea-hovered-bg,
            @textarea-hovered-border
        );
    }


    // Focus state
    &:focus,
    // &:focus-within,
    &.k-state-focus {
        .fill(
            @textarea-focused-text,
            @textarea-focused-bg,
            @textarea-focused-border
        );
        .box-shadow( @textarea-focused-shadow );
    }
    &:focus-within {
        .fill(
            @textarea-focused-text,
            @textarea-focused-bg,
            @textarea-focused-border
        );
        .box-shadow( @textarea-focused-shadow );
    }


    // Disabled
    &:disabled,
    &.k-state-disabled {
        outline: none;
        cursor: default;
        opacity: .6;
        filter: grayscale(.1);
        pointer-events: none;
        box-shadow: none;
    }

}
