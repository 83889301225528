/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-avatar {
    width: @avatar-size;
    height: @avatar-size;
    border-width: 0;
    border-style: solid;
    box-sizing: border-box;
    flex-shrink: 0;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    overflow: hidden;
}

.k-avatar-circle {
    border-radius: 50%;

    &.k-avatar-image {
        > * {
            border-radius: 50%;
        }
    }
}

.k-avatar-rounded {
    border-radius: @avatar-rounded-radius;

    &.k-avatar-image {
        > * {
            border-radius: @avatar-rounded-radius;
        }
    }
}

.k-avatar-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
