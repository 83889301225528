/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// ScrollView
kendo-scrollview.k-scrollview-wrap,
div.k-scrollview {
    .fill( @scrollview-text, @scrollview-bg, @scrollview-border );
    -webkit-tap-highlight-color: transparent;

    &.k-scrollview-dark {
        .k-scrollview-pageable,
        .k-scrollview-nav {
            background-color: @scrollview-dark-bg;
        }

        .k-scrollview-next,
        .k-scrollview-prev {
            color: @scrollview-dark-bg;
        }
    }

    &.k-scrollview-light {
        .k-scrollview-pageable,
        .k-scrollview-nav {
            background-color: @scrollview-light-bg;
        }
    }
}

kendo-scrollview.k-scrollview-wrap .k-scrollview,
.k-widget.k-scrollview .k-scrollview-wrap {
    &:focus,
    &.k-state-focused,
    &:hover,
    &.k-state-hovered {
        outline: none;

        ~ .k-scrollview-elements .k-scrollview-pageable,
        ~ .k-scrollview-elements .k-scrollview-nav {
            opacity: @scrollview-navigation-hover-opacity;
        }
    }
}

.k-scrollview-next,
.k-scrollview-prev {
    color: @scrollview-navigation-color;
    background-color: @scrollview-navigation-bg;
    text-shadow: @scrollview-navigation-icon-shadow;
    opacity: @scrollview-navigation-default-opacity;
    outline-width: 0;
    -webkit-tap-highlight-color: @scrollview-arrow-tap-highlight-color;

    &:focus,
    &.k-state-focused {
        color: @scrollview-navigation-color;
        opacity: @scrollview-navigation-hover-opacity;

        .k-icon::before {
            box-shadow: @scrollview-pagebutton-shadow;
            background-color: @scrollview-navigation-hover-span-bg;
        }
    }

    &:hover,
    &.k-state-hovered {
        color: @scrollview-navigation-color;
        opacity: @scrollview-navigation-hover-opacity;

        .k-icon::before {
            background-color: @scrollview-navigation-hover-span-bg;
        }
    }
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable,
.k-scrollview .k-scrollview-nav {
    opacity: @scrollview-navigation-default-opacity;

    &:focus-within,
    &:focus,
    &.k-state-focused,
    &:hover,
    &.k-state-hovered {
        opacity: @scrollview-navigation-hover-opacity;
        background-color: @scrollview-navigation-hover-span-bg;
    }
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button,
.k-scrollview .k-scrollview-nav > .k-link {
    background: @scrollview-pagebutton-bg;
    border: @scrollview-pagebutton-border;
    background-clip: padding-box;

    &.k-primary {
        background: @scrollview-pagebutton-primary-bg;
        border: @scrollview-pagebutton-primary-border;
    }

    &:focus,
    &.k-state-focused,
    &:hover,
    &.k-state-hovered {
        box-shadow: @scrollview-pagebutton-shadow;
    }
}
