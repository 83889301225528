/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-treelist {
    .k-status {
        padding: .4em .6em;
        line-height: 1.6em;

        .k-loading {
            vertical-align: baseline;
            margin-right: 5px;
        }
    }

    tr.k-hidden {
        display: none;
    }

    &.k-treelist-dragging {
        &, .k-state-hover {
            cursor: default;
        }
    }

    .k-drop-hint {
        transform: translate(0, -50%);
    }

    .k-i-arrow-60-right,
    .k-i-arrow-45-down-right
    {
        cursor: pointer;
    }

    .k-i-arrow-45-down-right
    {
        margin-top: -.5em;
    }

}

.k-drag-separator {
    display: inline-block;
    border-right: 1px solid;
    height: 1em;
    vertical-align: top;
    margin: 0 .5em;
}
