/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// PopUp + ActionSheet

.km-root .km-popup .k-item,
.km-widget.km-actionsheet > li
{
    list-style-type: none;
    padding: inherit 1em;
    border-bottom: 1px solid #555;
    .box-sizing(border-box);
}

.km-widget.km-actionsheet > li
{
    line-height: 2em;
    border-bottom: 0;
}

.km-widget.km-actionsheet > li > a
{
    line-height: 1.5em;
    text-align: left;
    background: transparent;
}

.km-root .km-popup .k-list,
.km-widget.km-actionsheet
{
    padding: 0;
    margin: 0;
}

.km-root .km-popup .k-item:last-child,
.km-widget.km-actionsheet > li:last-child
{
    border: 0;
}

.km-widget.km-actionsheet-wrapper
{
    width: 100%;
    .box-shadow(none);
    border: 0;
}

.km-actionsheet-root.km-shim .k-animation-container
{
    width: 100% !important;
    height: 100% !important;
}

.km-tablet .km-pane div.km-actionsheet-phone
{
    background: transparent;
}

.km-tablet .km-actionsheet-phone li.km-actionsheet-title,
.km-tablet div.km-actionsheet-phone li.km-actionsheet-cancel
{
    display: block;
}
