/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.flexbox() {
    display: flex;
}

.flexbox(@type) {
    display: ~"@{type}-flex";
}

.flex-direction(@direction) {
    flex-direction: @direction;
}

.flex-wrap(@wrap) {
    flex-wrap: @wrap;
}

.flex(@amount) {
    flex: @amount;
}

.flex-align(@alignment) {
    flex-align: @alignment;
}

.flex-align-items(@alignment) {
    align-items: @alignment;
}

.flex-align-content(@alignment) {
    align-content: @alignment;
}

.flex-pack(@pack) {
    flex-pack: @pack;
}

.box-shadow(...) {
    box-shadow: @arguments;
}

.box-sizing(@type) {
    box-sizing: @type;
}

.background-clip(@type) {
    background-clip: @type;
}

.transform(...) {
    transform: @arguments;
}

.transform-origin(...) {
    transform-origin: @arguments;
}

.transform-style(...) {
    transform-style: @arguments;
}

.transition(...) {
    transition: @arguments;
}

.pTransition(...) {
    transition: @arguments;
}

.user-select(...) {
    user-select: @arguments;
}

.animation(...) {
    animation: @arguments;
}

.animation-name(...) {
    animation-name: @arguments;
}

.animation-delay(...) {
    animation-delay: @arguments;
}

.appearance(...) {
    appearance: @arguments;
}

.text-size-adjust(...) {
    text-size-adjust: @arguments;
}
