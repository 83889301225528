/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Flex layout
.k-d-flex                        { display: flex; }
.k-flex-row                      { flex-direction: row; }
.k-flex-row-reverse              { flex-direction: row-reverse; }
.k-flex-col                      { flex-direction: column; }
.k-flex-col-reverse              { flex-direction: column-reverse; }
.k-flex-column                   { flex-direction: column; }
.k-flex-column-reverse           { flex-direction: column-reverse; }


// Flex wrap
.k-flex-wrap                     { flex-wrap: wrap; }
.k-flex-nowrap                   { flex-wrap: nowrap; }
.k-flex-wrap-reverse             { flex-wrap: wrap-reverse; }


// Flex, shrink, grow, basis
.k-flex-initial                  { flex: 0 1 auto; }
.k-flex-1                        { flex: 1 1 0%; }
.k-flex-auto                     { flex: 1 1 auto; }
.k-flex-none                     { flex: none; }
.k-flex-grow                     { flex-grow: 1; }
.k-flex-grow-0                   { flex-grow: 0; }
.k-flex-shrink                   { flex-shrink: 1; }
.k-flex-shrink-0                 { flex-shrink: 0; }
.k-flex-basis-auto               { flex-basis: auto; }
.k-flex-basis-0                  { flex-basis: 0%; }


// Flex align
.k-align-items-start            { align-items: flex-start; }
.k-align-items-end              { align-items: flex-end; }
.k-align-items-center           { align-items: center; }
.k-align-items-stretch          { align-items: stretch; }
.k-align-items-baseline         { align-items: baseline; }

.k-align-content-start          { align-content: flex-start; }
.k-align-content-end            { align-content: flex-end; }
.k-align-content-center         { align-content: center; }
.k-align-content-stretch        { align-content: stretch; }
.k-align-content-baseline       { align-content: baseline; }

.k-align-self-start             { align-self: flex-start; }
.k-align-self-end               { align-self: flex-end; }
.k-align-self-center            { align-self: center; }
.k-align-self-stretch           { align-self: stretch; }
.k-align-self-baseline          { align-self: baseline; }


// Flex justify
.k-justify-content-start        { justify-content: flex-start; }
.k-justify-content-end          { justify-content: flex-end; }
.k-justify-content-center       { justify-content: center; }
.k-justify-content-between      { justify-content: space-between; }
.k-justify-content-around       { justify-content: space-around; }
.k-justify-content-evenly       { justify-content: space-evenly; }
.k-justify-content-stretch > *  { flex: 1 0 0%; }


// Justify items
.k-justify-items-start          { justify-items: flex-start; }
.k-justify-items-end            { justify-items: flex-end; }
.k-justify-items-center         { justify-items: center; }
.k-justify-items-stretch        { justify-items: stretch; }
