/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Timeline

.k-timeline {
    .k-timeline-flag {
        background-color: @timeline-flag-background;
        color: @timeline-flag-text;
    }

    .k-timeline-circle {
        background-color: @timeline-circle-color;
    }

    .k-timeline-card {
        .k-card-header {
            background-color: @card-bg;
            color: @card-text;
        }

        .k-card-body {

            // Scrollbar styles for Mozilla
            scrollbar-color: @card-border @card-bg;

            // Scrollbar styles for Chrome, Safari and Opera
            &::-webkit-scrollbar-track {
                background: @card-bg;
            }

            &::-webkit-scrollbar-thumb {
                background: @card-border;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: @button-hover-border-color;
            }
        }
    }

    .k-timeline-date {
        color: @normal-text-color;
    }

    .k-timeline-arrow {
        &.k-button:not(.k-flat):not(.k-bare):not(.k-outline):not(.k-overflow-button).k-state-disabled {
            opacity: 1; // The arrow button in disabled mode should have a solid background
            color: @timeline-track-arrow-disabled-text;
            background-color: @timeline-track-arrow-disabled-bg;
            border-color: @timeline-track-arrow-disabled-border;
        }
    }
}

.k-timeline-vertical,
.k-timeline-horizontal .k-timeline-track-wrap {
    &::after {
        background-color: @timeline-track-bg;
        border-color: @timeline-track-border-color;
    }
}

.k-timeline-horizontal .k-timeline-flag::after {
    background-color: @timeline-flag-background;
}

.k-timeline-track-item.k-state-focus .k-timeline-circle {
    .box-shadow( @timeline-track-item-focus-shadow );
}
