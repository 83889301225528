/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Card
.k-card {
    .fill( @card-text, @card-bg, @card-border );

    .k-card-callout {
        border-color: @card-border;
        background-color: @card-bg;
    }

    // Card actions
    .k-card-actions {
        border-color: @card-border;
    }

    &:focus,
    &.k-state-focus {
        .fill(
            @card-focus-text,
            @card-focus-bg,
            @card-focus-border
        );
        .box-shadow( @card-focus-shadow );
    }
}


// Header
.k-card-header {
    .fill( @card-header-text, @card-header-bg, @card-header-border );
}

// Footer
.k-card-footer {
    .fill( @card-footer-text, @card-footer-bg, @card-footer-border );
}

// Separator
.k-card-separator {
    border-color: @card-border;
}


// Theme variantsmix
.k-card.k-state-primary,
.k-card.k-card-primary {
    .fill(
        @card-primary-text,
        @card-primary-bg,
        @card-primary-border
    )
}
.k-card.k-state-info,
.k-card.k-card-info {
    .fill(
        @card-info-text,
        @card-info-bg,
        @card-info-border
    )
}
.k-card.k-state-success,
.k-card.k-card-success {
    .fill(
        @card-success-text,
        @card-success-bg,
        @card-success-border
    )
}
.k-card.k-state-warning,
.k-card.k-card-warning {
    .fill(
        @card-warning-text,
        @card-warning-bg,
        @card-warning-border
    )
}
.k-card.k-state-error,
.k-card.k-card-error {
    .fill(
        @card-error-text,
        @card-error-bg,
        @card-error-border
    )
}

.k-card:focus .k-card-callout,
.k-card.k-state-focus .k-card-callout {
    .fill(
        @card-focus-text,
        @card-focus-bg,
        @card-focus-border
    );

    &.k-callout-n { .box-shadow( @card-focus-callout-box-shadow-n ); }
    &.k-callout-e { .box-shadow( @card-focus-callout-box-shadow-e ); }
    &.k-callout-s { .box-shadow( @card-focus-callout-box-shadow-s ); }
    &.k-callout-w { .box-shadow( @card-focus-callout-box-shadow-w ); }
}
