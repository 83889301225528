/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Captcha
.k-captcha {
    width: @captcha-width;
    border: none;
    box-sizing: border-box;
    font-size: @captcha-font-size;
    font-family: @captcha-font-family;
    line-height: @captcha-line-height;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: @captcha-gap;
}

// Image
.k-captcha-image-wrap {
    position: relative;
    gap: @captcha-image-wrap-gap;
}

.k-captcha-image-controls {
    position: relative;
    gap: @captcha-image-controls-gap;
}

.k-captcha-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.k-captcha-image > img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

// Loading
.k-captcha-loading .k-captcha-image {
    height: auto;
}

// Volume Control
.k-captcha-volume-control {
    height: 100%;
}

// Input
.k-captcha-input > .k-textbox {
    width: 100%;
}

// Verification Success
.k-captcha-validation-message {
    margin-top: @captcha-validation-message-margin-top;
    font-size: @captcha-validation-message-font-size;
    font-style: @captcha-validation-message-font-style;
}
