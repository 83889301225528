/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Pull to refresh

.km-scroller-pull
{
    width: 100%;
    display: block;
    position: absolute;
    line-height: 3em;
    font-size: 1.4em;
    text-align: center;
    .transform(translate3d(0,-3em,0));
}

.km-scroller-pull .km-template
{
    display: inline-block;
    min-width: 200px;
    text-align: left;
}

.km-load-more .km-icon,
.km-widget .km-scroller-pull .km-icon
{
    display: inline-block;
    height: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
    width: 2rem;
    font-size: 2rem;
    .transform(rotate(0deg));
    .pTransition(~"transform 300ms linear");
}

.km-widget .km-scroller-release .km-icon
{
    .transform(rotate(180deg));
}

.km-widget .km-scroller-refresh .km-icon
{
    .transition(none);
}

// Scroller

.km-touch-scrollbar
{
    position: absolute;
    visibility: hidden;
    z-index: 200000;
    height: .4em;
    width: .4em;
    background-color: #333;
    opacity: 0;
    .transform-origin(~"0 0");
    .transition(~"opacity 0.3s linear");
}

.km-vertical-scrollbar
{
    height: 100%;
    right: 2px;
    top: 0;
}

.km-horizontal-scrollbar
{
    width: 100%;
    left: 0;
    bottom: 2px;
}

.km-scrollview,
.km-scroll-container
{
    .user-select(none);
    -moz-user-select: -moz-none;
    -webkit-margin-collapse: separate;
    margin-collapse: separate;
}

.km-scroll-wrapper
{
    position: relative;
}

.km-scroll-header {
    position: absolute;
    z-index: 1001;
    width: 100%;
    top: 0;
    left: 0;
}
