/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Toolbar
.k-toolbar {
    margin: 0;
    padding: (@toolbar-padding-y /2) @toolbar-padding-x;
    border-width: @toolbar-border-width;
    border-style: solid;
    .border-radius( @toolbar-border-radius );
    box-sizing: border-box;
    outline: 0;
    font-size: @toolbar-font-size;
    line-height: @toolbar-line-height;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    &::before {
        content: "";
        height: @button-calc-size;
    }

    &.k-toolbar-resizable {
       flex-wrap: nowrap;
    }

    > * {
        // Until flex gap is available in chrome
        margin-top: (@toolbar-padding-y / 2);
        margin-bottom: (@toolbar-padding-y / 2);
        margin-right: @toolbar-spacing;
        flex-shrink: 0;
        display: inline-flex;
        align-items: stretch;
        align-content: center;
        vertical-align: middle;

        > label {
            margin-right: @toolbar-spacing;
        }
    }
    > :last-child {
        margin-right: 0;
    }

    .k-button,
    .k-button-group,
    .k-separator,
    .k-split-button,
    .k-textbox,
    .k-widget,
    label {
        align-self: center;
    }
    .k-split-button > .k-button {
        align-self: stretch;
    }


    // Overflow anchor
    .k-overflow-anchor {
        margin: 0;
        width: @toolbar-calc-inner-size;
        height: 100%;
        border-width: 0;
        border-left-width: @button-border-width;
        border-radius: 0;
        border-color: inherit;
        box-sizing: border-box;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
    }


    // Separator
    .k-separator,
    .k-toolbar-separator {
        width: 0;
        height: (20 / 14 * 1em);
        border-width: 0 0 0 1px;
        border-style: solid;
    }


    // Spacer
    .k-spacer,
    .k-toolbar-spacer {
        height: (20 / 14 * 1em);
        flex: 1 1 auto;
    }

}


// Overflow container
.k-overflow-container {

    > .k-item {
        padding: 1px;
    }

    // Group
    .k-overflow-tool-group {
        display: block;
    }

    // Button
    .k-overflow-button {
        width: 100%;
        border-color: transparent;
        background-color: transparent;
        background-image: none !important;
        text-align: left;
        justify-content: flex-start;
    }

    // Button group
    .k-button-group {
        display: flex;
        flex-direction: column;

        .k-button ~ .k-button {
            margin: 0;
        }
    }

    // Split button
    .k-split-button {
        display: flex;
        flex-direction: column;

        .k-button {
            padding: @button-padding-y @button-padding-x;
        }

        .k-button ~ .k-button {
            margin: 0;
        }
    }

    // Separators
    .k-separator {
        display: none;
    }

    // Hidden items
    .k-overflow-hidden {
        display: none;
    }
}




// RTL
.k-rtl {
    .k-toolbar {
        > * {
            margin-right: 0;
            margin-left: @toolbar-spacing;
        }
        > :last-child {
            margin-left: 0;
        }
        .k-overflow-anchor {
            border-left-width: 0;
            border-right-width: @button-border-width;
            right: auto;
            left: 0;
        }
    }
    .k-overflow-container {
        .k-overflow-button {
            text-align: right;
        }
    }
}




// No flexbox
.k-no-flexbox .k-toolbar {
    display: block;

    &.k-toolbar-resizable {
        white-space: nowrap;
    }

    > * {
        display: inline-block;
        vertical-align: middle;
    }

    > .k-align-left {
        float: none;
    }

    > .k-align-right {
        float: right;
    }
}
