/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// sans-serif
@font-face {
  font-family: "DejaVu Sans";
  src: url("DejaVuSans.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("DejaVuSans-Bold.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("DejaVuSans-Oblique.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSans-BoldOblique.ttf?v=1.1") format("truetype");
}

// serif
@font-face {
  font-family: "DejaVu Serif";
  src: url("DejaVuSerif.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-weight: bold;
  src: url("DejaVuSerif-Bold.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-style: italic;
  src: url("DejaVuSerif-Italic.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Serif";
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSerif-BoldItalic.ttf?v=1.1") format("truetype");
}

// monospace
@font-face {
  font-family: "DejaVu Mono";
  src: url("DejaVuSansMono.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-weight: bold;
  src: url("DejaVuSansMono-Bold.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-style: italic;
  src: url("DejaVuSansMono-Oblique.ttf?v=1.1") format("truetype");
}
@font-face {
  font-family: "DejaVu Mono";
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSansMono-BoldOblique.ttf?v=1.1") format("truetype");
}
