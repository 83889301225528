/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Display modes
.k-display-inline           { display: inline !important; }
.k-display-block            { display: block !important; }
.k-display-inline-block     { display: inline-block !important; }
.k-display-flex             { display: flex !important; }
.k-display-inline-flex      { display: inline-flex !important; }
.k-display-table            { display: table !important; }
.k-display-inline-table     { display: inline-table !important; }
.k-display-none             { display: none !important; }


// Aliases
.k-hidden { &:extend(.k-display-none); }
