/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-breadcrumb {
    min-height: @breadcrumb-min-height;
    border-width: 0;
    box-sizing: border-box;
    border-style: solid;
    background-color: transparent;
    display: flex;
    flex-direction: row;

    // Items
    .k-breadcrumb-container {
        margin: @breadcrumb-container-margin-y @breadcrumb-container-margin-x;
        padding: @breadcrumb-container-padding-y @breadcrumb-container-padding-x;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        list-style: none;
        overflow: hidden;
    }

    .k-breadcrumb-item {
        vertical-align: middle;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
    }

    .k-breadcrumb-root-item {
        flex: 1 0 auto;
    }

    .k-breadcrumb-link,
    .k-breadcrumb-root-link {
        padding: @breadcrumb-link-padding-y @breadcrumb-link-padding-x;
        border-radius: @breadcrumb-link-border-radius;
        position: relative;
        line-height: @breadcrumb-link-line-height;
        display: inline-flex;
        align-items: center;
        align-self: stretch;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        outline: none;
    }

    .k-breadcrumb-root-link {
        margin-right: @breadcrumb-root-link-spacing;
    }

    .k-breadcrumb-icon-link {
        padding: @breadcrumb-icon-link-padding-y @breadcrumb-icon-link-padding-x;
    }

    .k-breadcrumb-icontext-link .k-icon {
        margin-right: @breadcrumb-link-icon-spacing;
    }

    .k-breadcrumb-delimiter-icon {
        font-size: @breadcrumb-delimiter-icon-font-size;
    }

    // Editing
    .k-breadcrumb-input-container,
    .k-breadcrumb-input-container .k-textbox {
        width: 100%;
        height: 100%;
    }
}

.k-no-flexbox {
    .k-breadcrumb,
    .k-breadcrumb-container {
        display: block;
    }

    .k-breadcrumb-item {
        display: inline-block;
    }
}
