/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-actionsheet-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: hidden;
}

// Actionsheet
.k-actionsheet {
    // padding: @actionsheet-padding-y @actionsheet-padding-y;
    padding: 0;
    width: @actionsheet-width;
    max-width: @actionsheet-max-width;
    max-height: @actionsheet-max-height;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    font-size: @actionsheet-font-size;
    font-family: @actionsheet-font-family;
    line-height: @actionsheet-line-height;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: 10002;
}


// Actionsheet header
.k-actionsheet-header {
    padding: @actionsheet-header-padding-y @actionsheet-header-padding-x;
    border-width: 0;
    border-bottom-width: if( isnumber(@actionsheet-header-border-width), @actionsheet-header-border-width, 0 );
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    font-size: if( isnumber(@actionsheet-header-font-size), @actionsheet-header-font-size, inherit );
    font-family: if( isstring(@actionsheet-header-font-family), @actionsheet-header-font-family, inherit );
    line-height: if( isnumber(@actionsheet-header-line-height), @actionsheet-header-line-height, inherit );
}


// Actionsheet items
.k-actionsheet-items {
    margin: 0;
    padding: 0;
    list-style: none;
}


// Actionsheet item
.k-actionsheet-item {
    padding: 0;
    box-sizing: border-box;
}
.k-actionsheet-action {
    margin: 0;
    padding: @actionsheet-item-padding-y @actionsheet-item-padding-x;
    min-height: @actionsheet-item-min-height;
    box-sizing: border-box;
    color: inherit;
    text-decoration: none;
    outline: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: @actionsheet-item-spacing;
}
.k-actionsheet-item-icon {
    font-size: if( isnumber(@actionsheet-item-icon-size), @actionsheet-item-icon-size, 16px );
}
.k-actionsheet-item-text {
    display: flex;
    flex-flow: column nowrap;
}
.k-actionsheet-item-title {
    font-weight: if( not( @actionsheet-item-title-font-weight = null), @actionsheet-item-title-font-weight, normal);
    text-transform: if( not(@actionsheet-item-title-text-transform = null) @actionsheet-item-title-text-transform, none );
}
.k-actionsheet-item-description {
    font-size: @actionsheet-item-description-font-size;
    opacity: @actionsheet-item-description-opacity;
}


// Actionsheet separator
.k-actionsheet > .k-hr {
    margin: 0;
    border-color: inherit;
}


// Action sheet position
.k-actionsheet-top {
    .border-bottom-radius( @actionsheet-border-radius );
    border-width: @actionsheet-border-width;
    border-top-width: 0;
    top: 0;
    left: 50%;
    transform: translateX( -50% );
}
.k-actionsheet-bottom {
    .border-top-radius( @actionsheet-border-radius );
    border-width: @actionsheet-border-width;
    border-bottom-width: 0;
    bottom: 0;
    left: 50%;
    transform: translateX( -50% );
}
.k-actionsheet-left {
    .border-right-radius( @actionsheet-border-radius );
    border-width: @actionsheet-border-width;
    border-left-width: 0;
    left: 0;
    top: 50%;
    transform: translatey( -50% );
}
.k-actionsheet-right {
    .border-left-radius( @actionsheet-border-radius );
    border-width: @actionsheet-border-width;
    border-right-width: 0;
    right: 0;
    top: 50%;
    transform: translatey( -50% );
}


// Action sheet in animation container
.k-animation-container > .k-actionsheet {
    transform: none;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
}
