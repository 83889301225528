/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Tooltip
.k-tooltip {
    padding: @tooltip-padding-y @tooltip-padding-x;
    min-width: 30px;
    border-width: @tooltip-border-width;
    border-style: solid;
    box-sizing: border-box;
    background-position: 0 0;
    background-repeat: repeat-x;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    position: absolute;
    z-index: 12000;
}


// Tooltip icon
.k-tooltip-icon {
    margin-inline-end: 4px;
    flex-shrink: 0;
}


// Tooltip title
.k-tooltip-title {
    display: block;
}


// Tooltip content
.k-tooltip-content {
    align-self: stretch;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}


// Closable
.k-tooltip-button {
    flex-shrink: 0;
}


// Callout
.k-callout {
    width: (@tooltip-callout-size * 2);
    height: (@tooltip-callout-size * 2);
    border-width: @tooltip-callout-size;
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    position: absolute;
    pointer-events: none;
}

.k-callout-n {
    border-bottom-color: inherit;
    top: (-1 * @tooltip-border-width);
    left: 50%;
    transform: translate(-50%, -100%);
    pointer-events: none;
}
.k-callout-e {
    border-left-color: inherit;
    top: 50%;
    right: (-1 * @tooltip-border-width);
    transform: translate(100%, -50%);
    pointer-events: none;
}
.k-callout-s {
    border-top-color: inherit;
    bottom: (-1 * @tooltip-border-width);
    left: 50%;
    transform: translate(-50%, 100%);
    pointer-events: none;
}
.k-callout-w {
    border-right-color: inherit;
    top: 50%;
    left: (-1 * @tooltip-border-width);
    transform: translate(-100%, -50%);
    pointer-events: none;
}




// Validator tooltip
span.k-tooltip {
    position: relative;
}
.k-validator-tooltip {
    margin-top: @tooltip-callout-size;
    width: auto;
    white-space: normal;
    display: flex;
    align-items: center;
    // NOTE: This works around popup / tooltip stackin issue
    z-index: 9999;

    .k-callout-n { inset-inline-start: 16px; }
}
.k-grid .k-validation-tooltip {
    max-width: 300px;
}
// .k-invalid-msg {
//     display: none;
// }
