/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Pivotgrid
.k-pivotgrid {
    padding: @pivotgrid-padding-y @pivotgrid-padding-x;
    border-width: @pivotgrid-border-width;
    border-style: solid;
    box-sizing: content-box;
    font-size: @pivotgrid-font-size;
    font-family: @pivotgrid-font-family;
    line-height: @pivotgrid-line-height;
    text-align: left;
    vertical-align: top;
    display: grid;
    grid-template-columns: @pivotgrid-row-header-width auto;
    grid-template-rows: @pivotgrid-column-header-height auto;
    position: relative;

    table {
        margin: 0;
        width: 100%;
        max-width: none;
        border-width: 0;
        border-color: inherit;
        border-collapse: separate;
        border-spacing: 0;
        outline: 0;
        empty-cells: show;
    }

    thead,
    tbody,
    th,
    tr,
    td {
        border-color: inherit;
    }
}


// Table Layout
.k-pivotgrid-table {
    table-layout: fixed;

    th {
        font-weight: 400;
        text-transform: initial;
    }

    .k-pivotgrid-header-root {
        font-weight: 600;
        text-transform: uppercase;
    }
}


// Header empty cell
.k-pivotgrid-empty-cell {
    border-right-style: solid;
    border-right-width: @pivotgrid-cell-border-width;
    border-bottom-style: solid;
    border-bottom-width: @pivotgrid-cell-border-width;
}


// Column Headers
.k-pivotgrid-column-headers {
    overflow: hidden;
}

.k-pivotgrid-column-headers .k-pivotgrid-table {
    padding-inline-end: 17px;
    box-sizing: border-box;
}

.k-pivotgrid-column-headers .k-pivotgrid-cell {
    border-bottom-style: solid;
    border-bottom-width: @pivotgrid-cell-border-width;
    border-left-style: solid;
    border-left-width: @pivotgrid-cell-border-width;

    &:first-child {
        border-left-width: 0;
    }

    &.k-first {
        border-left-width: @pivotgrid-cell-border-width;
    }
}


// Row Headers
.k-pivotgrid-row-headers {
    overflow: hidden;
}

.k-pivotgrid-row-headers .k-pivotgrid-cell {
    border-right-style: solid;
    border-right-width: @pivotgrid-cell-border-width;
    border-bottom-style: solid;
    border-bottom-width: @pivotgrid-cell-border-width;
    white-space: nowrap;
}


// Values
.k-pivotgrid-values {
    border-color: inherit;
    overflow: auto;
}

.k-pivotgrid-values .k-pivotgrid-cell {
    border-bottom-style: solid;
    border-bottom-width: @pivotgrid-cell-border-width;
    border-left-style: solid;
    border-left-width: @pivotgrid-cell-border-width;

    &:first-child {
        border-left-width: 0;
    }
}

.k-pivotgrid-cell {
    padding: @pivotgrid-cell-padding-y @pivotgrid-cell-padding-x;
    text-overflow: ellipsis;
    vertical-align: top;
    overflow: hidden;
}

.k-pivotgrid-cell .k-icon {
    cursor: pointer;
}

.k-pivotgrid-total {
    font-weight: 600;
}

// Column menus
.k-column-menu .k-treeview {
    padding: @pivotgrid-treeview-padding-y @pivotgrid-treeview-padding-x;
    overflow: auto;
}


// Configurator
.k-pivotgrid-configurator {
    display: flex;
    border: none;
    box-shadow: none;
}

.k-pivotgrid-configurator-panel {
    padding: @pivotgrid-configurator-padding-y @pivotgrid-configurator-padding-x;
    border-width: @pivotgrid-configurator-border-width;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 2;
}

// Configurator Button
.k-pivotgrid-configurator-button {
    padding: @pivotgrid-configurator-button-padding-y @pivotgrid-configurator-button-padding-x;
    border-width: @pivotgrid-configurator-button-border-width;
    border-style: solid;
    box-sizing: border-box;
    cursor: pointer;

    > span {
        align-items: center;
        justify-content: center;
        display: flex;
        gap: calc( @pivotgrid-spacer / 2 );
    }

    .k-flex-row &,
    .k-flex-row-reverse & {
        display: flex;
        align-items: center;

        span {
            writing-mode: vertical-rl;
        }
        .k-icon {
            vertical-align: baseline;
        }
    }
    .k-flex-row-reverse & > span {
        transform: rotate(-180deg);
    }

    .k-flex-col &,
    .k-flex-col-reverse & {
        text-align: center;
    }
}


// Modes
.k-pivotgrid-configurator-overlay {
    position: absolute;
    overflow: hidden;
    z-index: 2;

    .k-flex-row & {
        right: @pivotgrid-configurator-button-size;
    }
    .k-flex-row-reverse & {
        left: @pivotgrid-configurator-button-size;
    }
    .k-flex-col & {
        bottom: @pivotgrid-configurator-button-size;
    }
    .k-flex-col-reverse & {
        top: @pivotgrid-configurator-button-size;
    }
}


// Panel Content
.k-pivotgrid-configurator-header {
    padding: @pivotgrid-configurator-header-padding-y @pivotgrid-configurator-header-padding-x;
    font-size: @pivotgrid-configurator-header-font-size;
    font-weight: @pivotgrid-configurator-header-font-weight;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.k-pivotgrid-configurator-header-text {
    word-break: normal;
    overflow-wrap: anywhere;
}

.k-pivotgrid-configurator-content {
    padding: @pivotgrid-configurator-content-padding-y @pivotgrid-configurator-content-padding-x;
    flex: 1 1 auto;
    overflow: auto;

    .k-form {
        .k-label {
            font-size: 16px;
            font-weight: 500;
            align-items: flex-start;
        }
        .k-dropdown {
            max-width: 90px;
        }
        .k-textbox {
            flex-basis: 50%;
        }
    }

    // Rows, Cols and Filters
    .k-row-fields,
    .k-column-fields,
    .k-filter-fields {
        margin-top: @pivotgrid-configurator-fields-margin-y;
        flex-wrap: wrap;

        > * {
            margin-bottom: @pivotgrid-configurator-fields-margin-y;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    // Values
    .k-value-fields {
        margin: @pivotgrid-configurator-fields-margin-y @pivotgrid-configurator-fields-margin-x;
        display: flex;
        flex-direction: column;
        gap: calc( @pivotgrid-spacer / 2 );
    }

    .k-select-all .k-checkbox {
        margin-inline-end: calc( @pivotgrid-spacer / 4 );
    }

    .k-fields-list-wrapper {
        max-height: 200px;
        border-width: 1px;
        border-style: solid;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .k-settings-description {
        margin-top: 1rem;
        text-align: center;
    }

    .k-chip-content {
        padding-inline-end: calc( @pivotgrid-spacer / 4 );
    }

    .k-treeview {
        padding: @pivotgrid-treeview-padding-y @pivotgrid-treeview-padding-x;
        overflow: auto;
    }

    .k-treeview .k-in .k-checkbox-wrapper {
        display: inline-flex;
    }
}

.k-pivotgrid-configurator-actions {
    padding: @pivotgrid-configurator-actions-padding-y @pivotgrid-configurator-actions-padding-x;
}


// Calculated field
.k-calculated-field {
    padding: @pivotgrid-calculated-field-padding-y @pivotgrid-calculated-field-padding-x;
    border-width: @pivotgrid-calculated-field-border-width;
    border-radius: @pivotgrid-calculated-field-border-radius;
    border-style: solid;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: @pivotgrid-calculated-field-gap;
}
.k-calculated-field-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.k-calculated-field-header .k-textbox {
    min-width: 200px;
}

.k-calculated-field-header-text {
    margin-right: 5px;
    word-break: normal;
    overflow-wrap: anywhere;
}

.k-calculated-field-header-actions {
    flex-shrink: 0;
    align-self: flex-start;
}

.k-calculated-field-content .k-textbox {
    width: 100%;
}

.k-calculated-field-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .k-icon {
        margin-right: @padding-x;
    }
    .k-dropdown {
        max-width: 100px;
    }
}

.k-filter-menu .k-calculated-item {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}


// Vertical Layout
.k-pivotgrid-configurator-vertical {
    min-width: @pivotgrid-configurator-vertical-width;
}

.k-pivotgrid-configurator-vertical.k-pivotgrid-configurator-push .k-pivotgrid-configurator-panel {
    .k-flex-row &,
    .k-flex-row-reverse & {
        border-right-width: 0;
        border-left-width: 0;
    }
}


// Horizontal Layout
.k-pivotgrid-configurator-horizontal {
    width: 100%;
    height: @pivotgrid-configurator-horizontal-height;

    .k-pivotgrid-configurator-content {
        .k-form {
            display: flex;
            flex-direction: row;
        }

        .k-form-field-wrapper {
            padding-left: @pivotgrid-spacer;

            & > *:first-child {
                margin-top: 0;
            }
            &:first-child {
                padding-left: 0;
                flex: 1 1 30%;
            }
            &:nth-child(2) {
                flex: 1 1 30%;
            }

            &:last-child {
                border-left-width: 1px;
                border-left-style: solid;
                flex: 1 1 40%;
            }
        }

        .k-row-fields,
        .k-column-fields,
        .k-filter-fields {
            max-height: 100px;
            overflow-x: hidden;
            overflow-y: auto;
        }
        .k-value-fields {
            max-height: 250px;
            flex-direction: row;
            flex-wrap: wrap;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    &.k-pivotgrid-configurator-push .k-pivotgrid-configurator-panel {
        .k-flex-col &,
        .k-flex-col-reverse & {
            border-top-width: 0;
            border-bottom-width: 0;
        }
    }
}

// Textboxes
.k-pivotgrid-configurator .k-textbox {
    display: inline-flex;
}

.k-pivotgrid-configurator .k-input-suffix .k-icon,
.k-pivotgrid-configurator .k-input-prefix .k-icon {
    margin: 0;
    top: auto;
    position: relative;
}

// Column Menu
.k-pivotgrid-column-menu {
    border: none;
}

.k-pivotgrid-column-menu .k-item {
    float: initial;
    border: none;
}

.k-pivotgrid-column-menu-popup.k-state-focused {
    box-shadow: none;
}

.k-pivotgrid-column-menu .k-actions {
    padding: calc( @pivotgrid-configurator-actions-padding-y / 2) @pivotgrid-configurator-actions-padding-x;
}

.k-pivotgrid-column-menu.k-column-menu .k-animation-container-relative {
    position: relative;
    display: inline-block;
}

.k-pivotgrid-column-menu-popup .k-filter-menu {
    margin: 0;
    padding: 0;
    background-color: inherit;
}

.k-pivotgrid-column-menu-popup .k-filer-menu .k-filter-menu-container {
    padding: calc( @pivotgrid-configurator-actions-padding-y / 2) @pivotgrid-configurator-actions-padding-x
}

.k-pivotgrid-column-menu-popup .k-filter-menu-container .k-actions {
    margin: 0;
    padding: calc( @pivotgrid-configurator-actions-padding-y / 2) 0 0;
}


// Export
.k-pdf-export-shadow {

    .k-pivotgrid {
        height: auto !important;
    }

    .k-pivotgrid-values  {
        height: auto !important;
        overflow: visible !important;
    }

    .k-pivotgrid-column-headers .k-pivotgrid-table {
        padding-inline-end: 0;
    }

}

// Legacy
.k-ie11 {
    .k-pivotgrid {
        display: flex;
        flex-direction: column;

        & > div {
            display: flex;
            flex-direction: row;
            overflow: hidden;
        }

        .k-pivotgrid-empty-cell,
        .k-pivotgrid-row-headers {
            flex: 1 1 30%;
        }

        .k-pivotgrid-column-headers,
        .k-pivotgrid-values {
            flex: 1 1 70%;
        }
    }
}
