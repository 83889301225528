/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Flip
.k-flip-h               { transform: scaleX( -1 ); }
.k-flip-v               { transform: scaleY( -1 ); }
.k-flip-h.k-flip-v      { transform: scale( -1, -1 ); }


// Rotate
.k-rotate-45            { transform: rotate( 45deg ); }
.k-rotate-90            { transform: rotate( 90deg ); }
.k-rotate-135           { transform: rotate( 135deg ); }
.k-rotate-180           { transform: rotate( 180deg ); }
.k-rotate-225           { transform: rotate( 225deg ); }
.k-rotate-270           { transform: rotate( 270deg ); }
.k-rotate-315           { transform: rotate( 315deg ); }


// Scale
.k-scale-0              { transform: scale( 0, 0 ); }
.k-scale-1              { transform: scale( 1, 1 ); }
.k-scale-2              { transform: scale( 2, 2 ); }


// Translate
.k-translate-0          { transform: translate( 0, 0 ); }
.k-translate-0-50       { transform: translate( 0, 50% ); }
.k-translate-0-100      { transform: translate( 0, 100% ); }
.k-translate-50-0       { transform: translate( 50%, 0 ); }
.k-translate-50-50      { transform: translate( 50%, 50% ); }
.k-translate-50-100     { transform: translate( 50%, 100% ); }
.k-translate-100-0      { transform: translate( 100%, 0 ); }
.k-translate-100-50     { transform: translate( 100%, 50% ); }
.k-translate-100-100    { transform: translate( 100%, 100% ); }
