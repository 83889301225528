/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Border radius
.border-radius( @radius: @border-radius ) when (@enable-radius) {
    border-radius: @radius;
}

.border-top-radius( @radius: @border-radius ) when (@enable-radius) {
    border-top-left-radius: @radius;
    border-top-right-radius: @radius;
}

.border-right-radius( @radius: @border-radius ) when (@enable-radius) {
    border-top-right-radius: @radius;
    border-bottom-right-radius: @radius;
}

.border-bottom-radius( @radius: @border-radius ) when (@enable-radius) {
    border-bottom-right-radius: @radius;
    border-bottom-left-radius: @radius;
}

.border-left-radius( @radius: @border-radius ) when (@enable-radius) {
    border-top-left-radius: @radius;
    border-bottom-left-radius: @radius;
}

.border-top-radius-only( @radius: @border-radius ) when (@enable-radius) {
    border-radius: @radius @radius 0 0;
}

.border-right-radius-only( @radius: @border-radius ) when (@enable-radius) {
    border-radius: 0 @radius @radius 0;
}

.border-bottom-radius-only( @radius: @border-radius ) when (@enable-radius) {
    border-radius: 0 0 @radius @radius;
}

.border-left-radius-only( @radius: @border-radius ) when (@enable-radius) {
    border-radius: @radius 0 0 @radius;
}


// @enable-radius
@enable-radius: true;