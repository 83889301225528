/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Year view
.k-scheduler-yearview {
    .k-calendar {
        .fill( @bg: @scheduler-yearview-calendar-bg );
    }

    .k-month-header {
        .fill( @text: @scheduler-yearview-month-header-text );
    }

    .k-day-indicator {
        .fill( @bg: @scheduler-yearview-indicator-bg );
    }

    .k-state-selected .k-day-indicator {
        .fill( @bg: @scheduler-yearview-indicator-selected-bg );
    }
}

// Tooltip
.k-tooltip.k-scheduler-tooltip {

    .k-tooltip-title .k-day {
        .fill( @text: @scheduler-tooltip-day-text );
    }

    .fill( @bg: @scheduler-tooltip-bg );

}
