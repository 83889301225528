/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Solid badges
each( @theme-colors, {
    @name: @key;
    @color: @value;

    .k-badge-solid.k-badge-@{name} {
        border-color: @color;
        color: contrast( @color );
        background-color: @color;
    }
});

// Outline badges
.k-badge-outline {
    border-color: currentColor;
    background-color: @component-bg;
}

each( @theme-colors, {
    @name: @key;
    @color: @value;

    .k-badge-outline.k-badge-@{name} {
        color: @color;
    }
});


// Border cutout
.k-badge-border-cutout {
    box-shadow: 0 0 0 2px @component-bg;
}
