/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@spacing: {
    0: 0;
    1: 4px;
    2: 8px;
    3: 12px;
    4: 16px;
    5: 20px;
    6: 24px;
    7: 28px;
    8: 32px;
    9: 36px;
    10: 40px;
    11: 44px;
    12: 48px;
    13: 52px;
    14: 56px;
    15: 60px;
    16: 64px;
    17: 68px;
    18: 72px;
    19: 76px;
    20: 80px;
    21: 84px;
    22: 88px;
    23: 92px;
    24: 96px;
    xs: 4px;
    sm: 8px;
    md: 12px;
    lg: 16px;
    xl: 24px;
    thin: 2px;
    hair: 1px;
}

@paddings:
    p padding,
    pt padding-top,
    pr padding-right,
    pb padding-bottom,
    pl padding-left,
;

@margins:
    m margin,
    mt margin-top,
    mr margin-right,
    mb margin-bottom,
    ml margin-left,
;

.props( @array, @size, @val, @i: 1 ) when ( @i <= length(@array) ) {
    @pair: extract(@array, @i);
    @name: extract(@pair, 1);
    @prop: extract(@pair, 2);

    .k-@{name}-@{size} { @{prop}: @val !important; };

    .props( @array, @size, @val, (@i + 1) );
}

each( @spacing, {
    @size: @key;
    @val: @value;

    .props( @paddings, @size, @val );
    .props( @margins, @size, @val );

    .k-px-@{size} {
        padding-left: @val !important;
        padding-right: @val !important;
    }
    .k-py-@{size} {
        padding-top: @val !important;
        padding-bottom: @val !important;
    }

    .k-mx-@{size} {
        padding-left: @val !important;
        padding-right: @val !important;
    }
    .k-my-@{size} {
        padding-top: @val !important;
        padding-bottom: @val !important;
    }
});
