/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Checkbox
.k-checkbox {
    .border-radius( @checkbox-radius );
    .fill(
        @checkbox-text,
        @checkbox-bg,
        @checkbox-border
    );

    &:checked::before {
        .border-radius( @checkbox-radius );
    }
}


// Invalid
.k-checkbox.k-invalid,
.k-checkbox.k-state-invalid {
    border-color: @checkbox-invalid-border;
}


// Hover state
.k-checkbox:hover {
    .fill(
        @checkbox-hovered-text,
        @checkbox-hovered-bg,
        @checkbox-hovered-border
    );
}


// Focus state
.k-checkbox:focus {
    .box-shadow( @checkbox-focused-shadow );
    border-color: @checkbox-focused-border;
}


// Indeterminate
.k-checkbox:indeterminate,
.k-checkbox.k-state-indeterminate {
    .fill(
        @checkbox-indeterminate-text,
        @checkbox-indeterminate-bg,
        @checkbox-indeterminate-border
    );
}


// Checked
.k-checkbox:checked {
    .fill(
        @checkbox-checked-text,
        @checkbox-checked-bg,
        @checkbox-checked-border
    );
}
.k-checkbox:checked:focus {
    .box-shadow( @checkbox-focused-checked-shadow );
    border-color: @checkbox-focused-checked-border;
}


// Disabled
.k-checkbox:disabled {
    .fill(
        @checkbox-disabled-text,
        @checkbox-disabled-bg,
        @checkbox-disabled-border
    );
}
.k-checkbox:checked:disabled,
.k-checkbox:indeterminate:disabled,
.k-checkbox.k-state-indeterminate:disabled {
    .fill(
        @checkbox-disabled-checked-text,
        @checkbox-disabled-checked-bg,
        @checkbox-disabled-checked-border
    );
}
