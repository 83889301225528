/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// SplitView
div.km-splitview > .km-content,
kendo-mobile-split-view.km-splitview > .km-content
{
    .flex-direction(row);
}

div.km-split-vertical > .km-content,
kendo-mobile-split-view.km-split-vertical > .km-content
{
    .flex-direction(column);
}

div.km-split-content > .km-pane
{
    .flex(2);
    width: auto;
    height: auto;
}

div.km-split-content > .km-pane:first-child
{
    .flex(1);
}

div.km-split-horizontal > .km-content > .km-pane
{
    top: 0;
    bottom: 0;
}

// Hopefully temporary
.km-split-vertical > .km-content > .km-pane > .km-view
{
    display: -webkit-box;
}
