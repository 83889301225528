/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Notification
.k-notification {
    border-width: @notification-border-width;
    border-style: solid;
    cursor: default;
    position: relative;
    white-space: nowrap;
    box-sizing: border-box;

    > .k-notification-wrap {
        padding: @notification-padding-y @notification-padding-x;
    }

    &-closable > .k-notification-wrap {
        padding-right: @notification-padding-end;
    }

    .k-notification-content {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
    }
}

.k-notification-wrap > .k-i-info,
.k-notification-wrap > .k-i-success,
.k-notification-wrap > .k-i-warning,
.k-notification-wrap > .k-i-error {
    margin-right: @notification-icon-spacing;
    vertical-align: text-bottom;
}

.k-notification-wrap > .k-i-close {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
    display: none;
}

.k-notification-closable .k-notification-wrap > .k-i-close {
    display: block;
}




// RTL
.k-rtl .k-notification-closable .k-notification-wrap,
.k-rtl.k-notification-closable .k-notification-wrap {
    padding-right: @notification-padding-x;
    padding-left: @notification-padding-end;
}

.k-rtl .k-notification-wrap > .k-i-info,
.k-rtl .k-notification-wrap > .k-i-success,
.k-rtl .k-notification-wrap > .k-i-warning,
.k-rtl .k-notification-wrap > .k-i-error {
    margin-right: 0;
    margin-left: @notification-icon-spacing;
}

.k-rtl .k-notification-wrap > .k-i-close {
    right: auto;
    left: 4px;
}
