/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-theme-test-class, .k-common-test-class {
  opacity: 0;
}

[hidden], script, .k-hidden {
  display: none !important;
}

.k-rtl {
  direction: rtl;
}

.k-hbox, .k-hstack {
  flex-flow: row;
  display: flex;
}

.k-vbox, .k-vstack {
  flex-flow: column;
  display: flex;
}

.k-flex, .k-spacer {
  flex: 1 0;
}

.k-flex-layout {
  display: flex;
}

.k-grid-layout {
  display: grid;
}

.k-separator {
  border-style: solid;
  border-width: 1px 0 0;
  border-color: inherit;
  align-self: stretch;
  width: auto;
  height: 0;
  display: block;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}

.k-separator-horizontal, .k-vstack > .k-separator {
  border-width: 1px 0 0;
  width: auto;
  height: 0;
}

.k-separator-vertical, .k-hstack > .k-separator {
  border-width: 0 0 0 1px;
  width: 0;
  height: auto;
}

hr.k-separator {
  margin: 0;
  padding: 0;
}

.k-actions, .k-action-buttons, .k-columnmenu-actions {
  border-style: solid;
  border-width: 1px 0 0;
  border-color: inherit;
  clear: both;
  flex-flow: row;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
  margin: 1em 0 0;
  padding: 6px 8px;
  display: flex;
  overflow: hidden;
}

.k-popup .k-actions {
  padding: 6px 0 0;
}

.k-colorpicker-popup .k-actions {
  padding: 6px 8px;
}

.k-ie .k-actions .k-button ~ .k-button {
  flex-shrink: 0;
  margin-left: 8px;
}

.k-rtl .k-ie .k-actions .k-button ~ .k-button, [dir="rtl"] .k-ie .k-actions .k-button ~ .k-button {
  margin-left: 0;
  margin-right: 8px;
}

.k-actions-start {
  justify-content: flex-start;
}

.k-actions-center {
  justify-content: center;
}

.k-actions-end {
  justify-content: flex-end;
}

.k-actions-stretched > * {
  flex: 1 0;
}

.k-sr-only {
  width: 1px;
  height: 1px;
  position: absolute;
  left: -1px;
  overflow: hidden;
}

.k-display-inline {
  display: inline !important;
}

.k-display-block {
  display: block !important;
}

.k-display-inline-block {
  display: inline-block !important;
}

.k-display-flex {
  display: flex !important;
}

.k-display-inline-flex {
  display: inline-flex !important;
}

.k-display-table {
  display: table !important;
}

.k-display-inline-table {
  display: inline-table !important;
}

.k-display-none, .k-hidden {
  display: none !important;
}

.k-d-flex {
  display: flex;
}

.k-flex-row {
  flex-direction: row;
}

.k-flex-row-reverse {
  flex-direction: row-reverse;
}

.k-flex-col {
  flex-direction: column;
}

.k-flex-col-reverse {
  flex-direction: column-reverse;
}

.k-flex-column {
  flex-direction: column;
}

.k-flex-column-reverse {
  flex-direction: column-reverse;
}

.k-flex-wrap {
  flex-wrap: wrap;
}

.k-flex-nowrap {
  flex-wrap: nowrap;
}

.k-flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.k-flex-initial {
  flex: 0 auto;
}

.k-flex-1 {
  flex: 1;
}

.k-flex-auto {
  flex: auto;
}

.k-flex-none {
  flex: none;
}

.k-flex-grow {
  flex-grow: 1;
}

.k-flex-grow-0 {
  flex-grow: 0;
}

.k-flex-shrink {
  flex-shrink: 1;
}

.k-flex-shrink-0 {
  flex-shrink: 0;
}

.k-flex-basis-auto {
  flex-basis: auto;
}

.k-flex-basis-0 {
  flex-basis: 0%;
}

.k-align-items-start {
  align-items: flex-start;
}

.k-align-items-end {
  align-items: flex-end;
}

.k-align-items-center {
  align-items: center;
}

.k-align-items-stretch {
  align-items: stretch;
}

.k-align-items-baseline {
  align-items: baseline;
}

.k-align-content-start {
  align-content: flex-start;
}

.k-align-content-end {
  align-content: flex-end;
}

.k-align-content-center {
  align-content: center;
}

.k-align-content-stretch {
  align-content: stretch;
}

.k-align-content-baseline {
  align-content: baseline;
}

.k-align-self-start {
  align-self: flex-start;
}

.k-align-self-end {
  align-self: flex-end;
}

.k-align-self-center {
  align-self: center;
}

.k-align-self-stretch {
  align-self: stretch;
}

.k-align-self-baseline {
  align-self: baseline;
}

.k-justify-content-start {
  justify-content: flex-start;
}

.k-justify-content-end {
  justify-content: flex-end;
}

.k-justify-content-center {
  justify-content: center;
}

.k-justify-content-between {
  justify-content: space-between;
}

.k-justify-content-around {
  justify-content: space-around;
}

.k-justify-content-evenly {
  justify-content: space-evenly;
}

.k-justify-content-stretch > * {
  flex: 1 0;
}

.k-justify-items-start {
  justify-items: flex-start;
}

.k-justify-items-end {
  justify-items: flex-end;
}

.k-justify-items-center {
  justify-items: center;
}

.k-justify-items-stretch {
  justify-items: stretch;
}

.k-float-left {
  float: left !important;
}

.k-float-right {
  float: right !important;
}

.k-float-none {
  float: none !important;
}

.k-clear-left {
  float: left !important;
}

.k-clear-right {
  float: right !important;
}

.k-clear-both {
  float: both !important;
}

.k-clear-none {
  float: none !important;
}

.k-floatwrap:after {
  content: "";
  clear: both;
  display: block;
}

.k-d-grid {
  display: grid;
}

.k-grid-cols-none {
  grid-template-columns: none;
}

.k-grid-rows-none {
  grid-template-rows: none;
}

.k-grid-flow-col {
  grid-auto-flow: column;
}

.k-grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.k-grid-flow-row {
  grid-auto-flow: row;
}

.k-grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.k-colspan-all {
  grid-column: 1 / -1;
}

.k-colspan-auto {
  grid-column: auto;
}

.k-rowspan-all {
  grid-row: 1 / -1;
}

.k-rowspan-auto {
  grid-row: auto;
}

.k-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.k-colspan-1 {
  grid-column: span 1;
}

.k-col-start-1 {
  grid-column-start: 1;
}

.k-col-end-1 {
  grid-column-end: 1;
}

.k-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.k-colspan-2 {
  grid-column: span 2;
}

.k-col-start-2 {
  grid-column-start: 2;
}

.k-col-end-2 {
  grid-column-end: 2;
}

.k-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.k-colspan-3 {
  grid-column: span 3;
}

.k-col-start-3 {
  grid-column-start: 3;
}

.k-col-end-3 {
  grid-column-end: 3;
}

.k-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.k-colspan-4 {
  grid-column: span 4;
}

.k-col-start-4 {
  grid-column-start: 4;
}

.k-col-end-4 {
  grid-column-end: 4;
}

.k-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.k-colspan-5 {
  grid-column: span 5;
}

.k-col-start-5 {
  grid-column-start: 5;
}

.k-col-end-5 {
  grid-column-end: 5;
}

.k-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.k-colspan-6 {
  grid-column: span 6;
}

.k-col-start-6 {
  grid-column-start: 6;
}

.k-col-end-6 {
  grid-column-end: 6;
}

.k-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.k-colspan-7 {
  grid-column: span 7;
}

.k-col-start-7 {
  grid-column-start: 7;
}

.k-col-end-7 {
  grid-column-end: 7;
}

.k-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.k-colspan-8 {
  grid-column: span 8;
}

.k-col-start-8 {
  grid-column-start: 8;
}

.k-col-end-8 {
  grid-column-end: 8;
}

.k-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.k-colspan-9 {
  grid-column: span 9;
}

.k-col-start-9 {
  grid-column-start: 9;
}

.k-col-end-9 {
  grid-column-end: 9;
}

.k-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.k-colspan-10 {
  grid-column: span 10;
}

.k-col-start-10 {
  grid-column-start: 10;
}

.k-col-end-10 {
  grid-column-end: 10;
}

.k-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.k-colspan-11 {
  grid-column: span 11;
}

.k-col-start-11 {
  grid-column-start: 11;
}

.k-col-end-11 {
  grid-column-end: 11;
}

.k-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.k-colspan-12 {
  grid-column: span 12;
}

.k-col-start-12 {
  grid-column-start: 12;
}

.k-col-end-12 {
  grid-column-end: 12;
}

.k-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.k-rowspan-1 {
  grid-row: span 1;
}

.k-row-start-1 {
  grid-row-start: 1;
}

.k-row-end-1 {
  grid-row-end: 1;
}

.k-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.k-rowspan-2 {
  grid-row: span 2;
}

.k-row-start-2 {
  grid-row-start: 2;
}

.k-row-end-2 {
  grid-row-end: 2;
}

.k-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.k-rowspan-3 {
  grid-row: span 3;
}

.k-row-start-3 {
  grid-row-start: 3;
}

.k-row-end-3 {
  grid-row-end: 3;
}

.k-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.k-rowspan-4 {
  grid-row: span 4;
}

.k-row-start-4 {
  grid-row-start: 4;
}

.k-row-end-4 {
  grid-row-end: 4;
}

.k-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.k-rowspan-5 {
  grid-row: span 5;
}

.k-row-start-5 {
  grid-row-start: 5;
}

.k-row-end-5 {
  grid-row-end: 5;
}

.k-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.k-rowspan-6 {
  grid-row: span 6;
}

.k-row-start-6 {
  grid-row-start: 6;
}

.k-row-end-6 {
  grid-row-end: 6;
}

.k-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.k-rowspan-7 {
  grid-row: span 7;
}

.k-row-start-7 {
  grid-row-start: 7;
}

.k-row-end-7 {
  grid-row-end: 7;
}

.k-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.k-rowspan-8 {
  grid-row: span 8;
}

.k-row-start-8 {
  grid-row-start: 8;
}

.k-row-end-8 {
  grid-row-end: 8;
}

.k-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

.k-rowspan-9 {
  grid-row: span 9;
}

.k-row-start-9 {
  grid-row-start: 9;
}

.k-row-end-9 {
  grid-row-end: 9;
}

.k-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.k-rowspan-10 {
  grid-row: span 10;
}

.k-row-start-10 {
  grid-row-start: 10;
}

.k-row-end-10 {
  grid-row-end: 10;
}

.k-order-first {
  order: -9999 !important;
}

.k-order-last {
  order: 9999 !important;
}

.k-order-none {
  order: 0 !important;
}

.k-order-1 {
  order: 1 !important;
}

.k-order-2 {
  order: 2 !important;
}

.k-order-3 {
  order: 3 !important;
}

.k-order-4 {
  order: 4 !important;
}

.k-order-5 {
  order: 5 !important;
}

.k-order-6 {
  order: 6 !important;
}

.k-order-7 {
  order: 7 !important;
}

.k-order-8 {
  order: 8 !important;
}

.k-order-9 {
  order: 9 !important;
}

.k-order-10 {
  order: 10 !important;
}

.k-order-11 {
  order: 11 !important;
}

.k-order-12 {
  order: 12 !important;
}

.k-overflow-auto {
  overflow: auto !important;
}

.k-overflow-x-auto {
  overflow-x: auto !important;
}

.k-overflow-y-auto {
  overflow-y: auto !important;
}

.k-overflow-auto-auto {
  overflow: auto !important;
}

.k-overflow-auto-hidden {
  overflow: auto hidden !important;
}

.k-overflow-auto-visible {
  overflow: auto visible !important;
}

.k-overflow-auto-scroll {
  overflow: auto scroll !important;
}

.k-overflow-auto-clip {
  overflow: auto clip !important;
}

.k-overflow-hidden {
  overflow: hidden !important;
}

.k-overflow-x-hidden {
  overflow-x: hidden !important;
}

.k-overflow-y-hidden {
  overflow-y: hidden !important;
}

.k-overflow-hidden-auto {
  overflow: hidden auto !important;
}

.k-overflow-hidden-hidden {
  overflow: hidden !important;
}

.k-overflow-hidden-visible {
  overflow: hidden visible !important;
}

.k-overflow-hidden-scroll {
  overflow: hidden scroll !important;
}

.k-overflow-hidden-clip {
  overflow: hidden clip !important;
}

.k-overflow-visible {
  overflow: visible !important;
}

.k-overflow-x-visible {
  overflow-x: visible !important;
}

.k-overflow-y-visible {
  overflow-y: visible !important;
}

.k-overflow-visible-auto {
  overflow: visible auto !important;
}

.k-overflow-visible-hidden {
  overflow: visible hidden !important;
}

.k-overflow-visible-visible {
  overflow: visible !important;
}

.k-overflow-visible-scroll {
  overflow: visible scroll !important;
}

.k-overflow-visible-clip {
  overflow: visible clip !important;
}

.k-overflow-scroll {
  overflow: scroll !important;
}

.k-overflow-x-scroll {
  overflow-x: scroll !important;
}

.k-overflow-y-scroll {
  overflow-y: scroll !important;
}

.k-overflow-scroll-auto {
  overflow: scroll auto !important;
}

.k-overflow-scroll-hidden {
  overflow: scroll hidden !important;
}

.k-overflow-scroll-visible {
  overflow: scroll visible !important;
}

.k-overflow-scroll-scroll {
  overflow: scroll !important;
}

.k-overflow-scroll-clip {
  overflow: scroll clip !important;
}

.k-overflow-clip {
  overflow: clip !important;
}

.k-overflow-x-clip {
  overflow-x: clip !important;
}

.k-overflow-y-clip {
  overflow-y: clip !important;
}

.k-overflow-clip-auto {
  overflow: clip auto !important;
}

.k-overflow-clip-hidden {
  overflow: clip hidden !important;
}

.k-overflow-clip-visible {
  overflow: clip visible !important;
}

.k-overflow-clip-scroll {
  overflow: clip scroll !important;
}

.k-overflow-clip-clip {
  overflow: clip !important;
}

.k-pointer-events-none {
  pointer-events: none !important;
}

.k-pointer-events-auto {
  pointer-events: auto !important;
}

.k-static, .k-pos-static {
  position: static !important;
}

.k-relative, .k-pos-relative {
  position: relative !important;
}

.k-absolute, .k-pos-absolute {
  position: absolute !important;
}

.k-fixed, .k-pos-fixed {
  position: fixed !important;
}

.k-sticky, .k-pos-sticky {
  position: sticky !important;
}

.k-top, .k-pos-top {
  top: 0;
}

.k-right, .k-pos-right {
  right: 0;
}

.k-bottom, .k-pos-bottom {
  bottom: 0;
}

.k-left, .k-pos-left {
  left: 0;
}

.k-top-start, .k-pos-top-start {
  top: 0;
  left: 0;
}

.k-rtl .k-top-start, .k-rtl .k-pos-top-start, .k-top-start[dir="rtl"], .k-pos-top-start[dir="rtl"], [dir="rtl"] .k-top-start, [dir="rtl"] .k-pos-top-start {
  left: auto;
  right: 0;
}

.k-top-center, .k-pos-top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-top-end, .k-pos-top-end {
  top: 0;
  right: 0;
}

.k-rtl .k-top-end, .k-rtl .k-pos-top-end, .k-top-end[dir="rtl"], .k-pos-top-end[dir="rtl"], [dir="rtl"] .k-top-end, [dir="rtl"] .k-pos-top-end {
  left: 0;
  right: auto;
}

.k-bottom-start, .k-pos-bottom-start {
  bottom: 0;
  left: 0;
}

.k-rtl .k-bottom-start, .k-rtl .k-pos-bottom-start, .k-bottom-start[dir="rtl"], .k-pos-bottom-start[dir="rtl"], [dir="rtl"] .k-bottom-start, [dir="rtl"] .k-pos-bottom-start {
  left: auto;
  right: 0;
}

.k-bottom-center, .k-pos-bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-bottom-end, .k-pos-bottom-end {
  bottom: 0;
  right: 0;
}

.k-rtl .k-bottom-end, .k-rtl .k-pos-bottom-end, .k-bottom-end[dir="rtl"], .k-pos-bottom-end[dir="rtl"], [dir="rtl"] .k-bottom-end, [dir="rtl"] .k-pos-bottom-end {
  left: 0;
  right: auto;
}

.k-middle-start, .k-pos-middle-start {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.k-middle-end, .k-pos-middle-end {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-resize-none {
  resize: none !important;
}

.k-resize-both {
  resize: both !important;
}

.k-resize-horizontal {
  resize: horizontal !important;
}

.k-resize-vertical {
  resize: vertical !important;
}

.k-spacer {
  flex: 1 0 auto;
}

.k-spacer-sized {
  flex-grow: 0;
}

.k-p-0 {
  padding: 0 !important;
}

.k-pt-0 {
  padding-top: 0 !important;
}

.k-pr-0 {
  padding-right: 0 !important;
}

.k-pb-0 {
  padding-bottom: 0 !important;
}

.k-pl-0 {
  padding-left: 0 !important;
}

.k-m-0 {
  margin: 0 !important;
}

.k-mt-0 {
  margin-top: 0 !important;
}

.k-mr-0 {
  margin-right: 0 !important;
}

.k-mb-0 {
  margin-bottom: 0 !important;
}

.k-ml-0 {
  margin-left: 0 !important;
}

.k-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.k-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.k-mx-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.k-my-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.k-p-1 {
  padding: 4px !important;
}

.k-pt-1 {
  padding-top: 4px !important;
}

.k-pr-1 {
  padding-right: 4px !important;
}

.k-pb-1 {
  padding-bottom: 4px !important;
}

.k-pl-1 {
  padding-left: 4px !important;
}

.k-m-1 {
  margin: 4px !important;
}

.k-mt-1 {
  margin-top: 4px !important;
}

.k-mr-1 {
  margin-right: 4px !important;
}

.k-mb-1 {
  margin-bottom: 4px !important;
}

.k-ml-1 {
  margin-left: 4px !important;
}

.k-px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.k-py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.k-mx-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.k-my-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.k-p-2 {
  padding: 8px !important;
}

.k-pt-2 {
  padding-top: 8px !important;
}

.k-pr-2 {
  padding-right: 8px !important;
}

.k-pb-2 {
  padding-bottom: 8px !important;
}

.k-pl-2 {
  padding-left: 8px !important;
}

.k-m-2 {
  margin: 8px !important;
}

.k-mt-2 {
  margin-top: 8px !important;
}

.k-mr-2 {
  margin-right: 8px !important;
}

.k-mb-2 {
  margin-bottom: 8px !important;
}

.k-ml-2 {
  margin-left: 8px !important;
}

.k-px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.k-py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.k-mx-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.k-my-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.k-p-3 {
  padding: 12px !important;
}

.k-pt-3 {
  padding-top: 12px !important;
}

.k-pr-3 {
  padding-right: 12px !important;
}

.k-pb-3 {
  padding-bottom: 12px !important;
}

.k-pl-3 {
  padding-left: 12px !important;
}

.k-m-3 {
  margin: 12px !important;
}

.k-mt-3 {
  margin-top: 12px !important;
}

.k-mr-3 {
  margin-right: 12px !important;
}

.k-mb-3 {
  margin-bottom: 12px !important;
}

.k-ml-3 {
  margin-left: 12px !important;
}

.k-px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.k-py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.k-mx-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.k-my-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.k-p-4 {
  padding: 16px !important;
}

.k-pt-4 {
  padding-top: 16px !important;
}

.k-pr-4 {
  padding-right: 16px !important;
}

.k-pb-4 {
  padding-bottom: 16px !important;
}

.k-pl-4 {
  padding-left: 16px !important;
}

.k-m-4 {
  margin: 16px !important;
}

.k-mt-4 {
  margin-top: 16px !important;
}

.k-mr-4 {
  margin-right: 16px !important;
}

.k-mb-4 {
  margin-bottom: 16px !important;
}

.k-ml-4 {
  margin-left: 16px !important;
}

.k-px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.k-py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.k-mx-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.k-my-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.k-p-5 {
  padding: 20px !important;
}

.k-pt-5 {
  padding-top: 20px !important;
}

.k-pr-5 {
  padding-right: 20px !important;
}

.k-pb-5 {
  padding-bottom: 20px !important;
}

.k-pl-5 {
  padding-left: 20px !important;
}

.k-m-5 {
  margin: 20px !important;
}

.k-mt-5 {
  margin-top: 20px !important;
}

.k-mr-5 {
  margin-right: 20px !important;
}

.k-mb-5 {
  margin-bottom: 20px !important;
}

.k-ml-5 {
  margin-left: 20px !important;
}

.k-px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.k-py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.k-mx-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.k-my-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.k-p-6 {
  padding: 24px !important;
}

.k-pt-6 {
  padding-top: 24px !important;
}

.k-pr-6 {
  padding-right: 24px !important;
}

.k-pb-6 {
  padding-bottom: 24px !important;
}

.k-pl-6 {
  padding-left: 24px !important;
}

.k-m-6 {
  margin: 24px !important;
}

.k-mt-6 {
  margin-top: 24px !important;
}

.k-mr-6 {
  margin-right: 24px !important;
}

.k-mb-6 {
  margin-bottom: 24px !important;
}

.k-ml-6 {
  margin-left: 24px !important;
}

.k-px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.k-py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.k-mx-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.k-my-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.k-p-7 {
  padding: 28px !important;
}

.k-pt-7 {
  padding-top: 28px !important;
}

.k-pr-7 {
  padding-right: 28px !important;
}

.k-pb-7 {
  padding-bottom: 28px !important;
}

.k-pl-7 {
  padding-left: 28px !important;
}

.k-m-7 {
  margin: 28px !important;
}

.k-mt-7 {
  margin-top: 28px !important;
}

.k-mr-7 {
  margin-right: 28px !important;
}

.k-mb-7 {
  margin-bottom: 28px !important;
}

.k-ml-7 {
  margin-left: 28px !important;
}

.k-px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.k-py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.k-mx-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.k-my-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.k-p-8 {
  padding: 32px !important;
}

.k-pt-8 {
  padding-top: 32px !important;
}

.k-pr-8 {
  padding-right: 32px !important;
}

.k-pb-8 {
  padding-bottom: 32px !important;
}

.k-pl-8 {
  padding-left: 32px !important;
}

.k-m-8 {
  margin: 32px !important;
}

.k-mt-8 {
  margin-top: 32px !important;
}

.k-mr-8 {
  margin-right: 32px !important;
}

.k-mb-8 {
  margin-bottom: 32px !important;
}

.k-ml-8 {
  margin-left: 32px !important;
}

.k-px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.k-py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.k-mx-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.k-my-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.k-p-9 {
  padding: 36px !important;
}

.k-pt-9 {
  padding-top: 36px !important;
}

.k-pr-9 {
  padding-right: 36px !important;
}

.k-pb-9 {
  padding-bottom: 36px !important;
}

.k-pl-9 {
  padding-left: 36px !important;
}

.k-m-9 {
  margin: 36px !important;
}

.k-mt-9 {
  margin-top: 36px !important;
}

.k-mr-9 {
  margin-right: 36px !important;
}

.k-mb-9 {
  margin-bottom: 36px !important;
}

.k-ml-9 {
  margin-left: 36px !important;
}

.k-px-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.k-py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.k-mx-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.k-my-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.k-p-10 {
  padding: 40px !important;
}

.k-pt-10 {
  padding-top: 40px !important;
}

.k-pr-10 {
  padding-right: 40px !important;
}

.k-pb-10 {
  padding-bottom: 40px !important;
}

.k-pl-10 {
  padding-left: 40px !important;
}

.k-m-10 {
  margin: 40px !important;
}

.k-mt-10 {
  margin-top: 40px !important;
}

.k-mr-10 {
  margin-right: 40px !important;
}

.k-mb-10 {
  margin-bottom: 40px !important;
}

.k-ml-10 {
  margin-left: 40px !important;
}

.k-px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.k-py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.k-mx-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.k-my-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.k-p-11 {
  padding: 44px !important;
}

.k-pt-11 {
  padding-top: 44px !important;
}

.k-pr-11 {
  padding-right: 44px !important;
}

.k-pb-11 {
  padding-bottom: 44px !important;
}

.k-pl-11 {
  padding-left: 44px !important;
}

.k-m-11 {
  margin: 44px !important;
}

.k-mt-11 {
  margin-top: 44px !important;
}

.k-mr-11 {
  margin-right: 44px !important;
}

.k-mb-11 {
  margin-bottom: 44px !important;
}

.k-ml-11 {
  margin-left: 44px !important;
}

.k-px-11 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.k-py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.k-mx-11 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.k-my-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.k-p-12 {
  padding: 48px !important;
}

.k-pt-12 {
  padding-top: 48px !important;
}

.k-pr-12 {
  padding-right: 48px !important;
}

.k-pb-12 {
  padding-bottom: 48px !important;
}

.k-pl-12 {
  padding-left: 48px !important;
}

.k-m-12 {
  margin: 48px !important;
}

.k-mt-12 {
  margin-top: 48px !important;
}

.k-mr-12 {
  margin-right: 48px !important;
}

.k-mb-12 {
  margin-bottom: 48px !important;
}

.k-ml-12 {
  margin-left: 48px !important;
}

.k-px-12 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.k-py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.k-mx-12 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.k-my-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.k-p-13 {
  padding: 52px !important;
}

.k-pt-13 {
  padding-top: 52px !important;
}

.k-pr-13 {
  padding-right: 52px !important;
}

.k-pb-13 {
  padding-bottom: 52px !important;
}

.k-pl-13 {
  padding-left: 52px !important;
}

.k-m-13 {
  margin: 52px !important;
}

.k-mt-13 {
  margin-top: 52px !important;
}

.k-mr-13 {
  margin-right: 52px !important;
}

.k-mb-13 {
  margin-bottom: 52px !important;
}

.k-ml-13 {
  margin-left: 52px !important;
}

.k-px-13 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.k-py-13 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.k-mx-13 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.k-my-13 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.k-p-14 {
  padding: 56px !important;
}

.k-pt-14 {
  padding-top: 56px !important;
}

.k-pr-14 {
  padding-right: 56px !important;
}

.k-pb-14 {
  padding-bottom: 56px !important;
}

.k-pl-14 {
  padding-left: 56px !important;
}

.k-m-14 {
  margin: 56px !important;
}

.k-mt-14 {
  margin-top: 56px !important;
}

.k-mr-14 {
  margin-right: 56px !important;
}

.k-mb-14 {
  margin-bottom: 56px !important;
}

.k-ml-14 {
  margin-left: 56px !important;
}

.k-px-14 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.k-py-14 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.k-mx-14 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.k-my-14 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.k-p-15 {
  padding: 60px !important;
}

.k-pt-15 {
  padding-top: 60px !important;
}

.k-pr-15 {
  padding-right: 60px !important;
}

.k-pb-15 {
  padding-bottom: 60px !important;
}

.k-pl-15 {
  padding-left: 60px !important;
}

.k-m-15 {
  margin: 60px !important;
}

.k-mt-15 {
  margin-top: 60px !important;
}

.k-mr-15 {
  margin-right: 60px !important;
}

.k-mb-15 {
  margin-bottom: 60px !important;
}

.k-ml-15 {
  margin-left: 60px !important;
}

.k-px-15 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.k-py-15 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.k-mx-15 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.k-my-15 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.k-p-16 {
  padding: 64px !important;
}

.k-pt-16 {
  padding-top: 64px !important;
}

.k-pr-16 {
  padding-right: 64px !important;
}

.k-pb-16 {
  padding-bottom: 64px !important;
}

.k-pl-16 {
  padding-left: 64px !important;
}

.k-m-16 {
  margin: 64px !important;
}

.k-mt-16 {
  margin-top: 64px !important;
}

.k-mr-16 {
  margin-right: 64px !important;
}

.k-mb-16 {
  margin-bottom: 64px !important;
}

.k-ml-16 {
  margin-left: 64px !important;
}

.k-px-16 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.k-py-16 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.k-mx-16 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.k-my-16 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.k-p-17 {
  padding: 68px !important;
}

.k-pt-17 {
  padding-top: 68px !important;
}

.k-pr-17 {
  padding-right: 68px !important;
}

.k-pb-17 {
  padding-bottom: 68px !important;
}

.k-pl-17 {
  padding-left: 68px !important;
}

.k-m-17 {
  margin: 68px !important;
}

.k-mt-17 {
  margin-top: 68px !important;
}

.k-mr-17 {
  margin-right: 68px !important;
}

.k-mb-17 {
  margin-bottom: 68px !important;
}

.k-ml-17 {
  margin-left: 68px !important;
}

.k-px-17 {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.k-py-17 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.k-mx-17 {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.k-my-17 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.k-p-18 {
  padding: 72px !important;
}

.k-pt-18 {
  padding-top: 72px !important;
}

.k-pr-18 {
  padding-right: 72px !important;
}

.k-pb-18 {
  padding-bottom: 72px !important;
}

.k-pl-18 {
  padding-left: 72px !important;
}

.k-m-18 {
  margin: 72px !important;
}

.k-mt-18 {
  margin-top: 72px !important;
}

.k-mr-18 {
  margin-right: 72px !important;
}

.k-mb-18 {
  margin-bottom: 72px !important;
}

.k-ml-18 {
  margin-left: 72px !important;
}

.k-px-18 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.k-py-18 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.k-mx-18 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.k-my-18 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.k-p-19 {
  padding: 76px !important;
}

.k-pt-19 {
  padding-top: 76px !important;
}

.k-pr-19 {
  padding-right: 76px !important;
}

.k-pb-19 {
  padding-bottom: 76px !important;
}

.k-pl-19 {
  padding-left: 76px !important;
}

.k-m-19 {
  margin: 76px !important;
}

.k-mt-19 {
  margin-top: 76px !important;
}

.k-mr-19 {
  margin-right: 76px !important;
}

.k-mb-19 {
  margin-bottom: 76px !important;
}

.k-ml-19 {
  margin-left: 76px !important;
}

.k-px-19 {
  padding-left: 76px !important;
  padding-right: 76px !important;
}

.k-py-19 {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.k-mx-19 {
  padding-left: 76px !important;
  padding-right: 76px !important;
}

.k-my-19 {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.k-p-20 {
  padding: 80px !important;
}

.k-pt-20 {
  padding-top: 80px !important;
}

.k-pr-20 {
  padding-right: 80px !important;
}

.k-pb-20 {
  padding-bottom: 80px !important;
}

.k-pl-20 {
  padding-left: 80px !important;
}

.k-m-20 {
  margin: 80px !important;
}

.k-mt-20 {
  margin-top: 80px !important;
}

.k-mr-20 {
  margin-right: 80px !important;
}

.k-mb-20 {
  margin-bottom: 80px !important;
}

.k-ml-20 {
  margin-left: 80px !important;
}

.k-px-20 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.k-py-20 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.k-mx-20 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.k-my-20 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.k-p-21 {
  padding: 84px !important;
}

.k-pt-21 {
  padding-top: 84px !important;
}

.k-pr-21 {
  padding-right: 84px !important;
}

.k-pb-21 {
  padding-bottom: 84px !important;
}

.k-pl-21 {
  padding-left: 84px !important;
}

.k-m-21 {
  margin: 84px !important;
}

.k-mt-21 {
  margin-top: 84px !important;
}

.k-mr-21 {
  margin-right: 84px !important;
}

.k-mb-21 {
  margin-bottom: 84px !important;
}

.k-ml-21 {
  margin-left: 84px !important;
}

.k-px-21 {
  padding-left: 84px !important;
  padding-right: 84px !important;
}

.k-py-21 {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.k-mx-21 {
  padding-left: 84px !important;
  padding-right: 84px !important;
}

.k-my-21 {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.k-p-22 {
  padding: 88px !important;
}

.k-pt-22 {
  padding-top: 88px !important;
}

.k-pr-22 {
  padding-right: 88px !important;
}

.k-pb-22 {
  padding-bottom: 88px !important;
}

.k-pl-22 {
  padding-left: 88px !important;
}

.k-m-22 {
  margin: 88px !important;
}

.k-mt-22 {
  margin-top: 88px !important;
}

.k-mr-22 {
  margin-right: 88px !important;
}

.k-mb-22 {
  margin-bottom: 88px !important;
}

.k-ml-22 {
  margin-left: 88px !important;
}

.k-px-22 {
  padding-left: 88px !important;
  padding-right: 88px !important;
}

.k-py-22 {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.k-mx-22 {
  padding-left: 88px !important;
  padding-right: 88px !important;
}

.k-my-22 {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.k-p-23 {
  padding: 92px !important;
}

.k-pt-23 {
  padding-top: 92px !important;
}

.k-pr-23 {
  padding-right: 92px !important;
}

.k-pb-23 {
  padding-bottom: 92px !important;
}

.k-pl-23 {
  padding-left: 92px !important;
}

.k-m-23 {
  margin: 92px !important;
}

.k-mt-23 {
  margin-top: 92px !important;
}

.k-mr-23 {
  margin-right: 92px !important;
}

.k-mb-23 {
  margin-bottom: 92px !important;
}

.k-ml-23 {
  margin-left: 92px !important;
}

.k-px-23 {
  padding-left: 92px !important;
  padding-right: 92px !important;
}

.k-py-23 {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.k-mx-23 {
  padding-left: 92px !important;
  padding-right: 92px !important;
}

.k-my-23 {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.k-p-24 {
  padding: 96px !important;
}

.k-pt-24 {
  padding-top: 96px !important;
}

.k-pr-24 {
  padding-right: 96px !important;
}

.k-pb-24 {
  padding-bottom: 96px !important;
}

.k-pl-24 {
  padding-left: 96px !important;
}

.k-m-24 {
  margin: 96px !important;
}

.k-mt-24 {
  margin-top: 96px !important;
}

.k-mr-24 {
  margin-right: 96px !important;
}

.k-mb-24 {
  margin-bottom: 96px !important;
}

.k-ml-24 {
  margin-left: 96px !important;
}

.k-px-24 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.k-py-24 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.k-mx-24 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.k-my-24 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.k-p-xs {
  padding: 4px !important;
}

.k-pt-xs {
  padding-top: 4px !important;
}

.k-pr-xs {
  padding-right: 4px !important;
}

.k-pb-xs {
  padding-bottom: 4px !important;
}

.k-pl-xs {
  padding-left: 4px !important;
}

.k-m-xs {
  margin: 4px !important;
}

.k-mt-xs {
  margin-top: 4px !important;
}

.k-mr-xs {
  margin-right: 4px !important;
}

.k-mb-xs {
  margin-bottom: 4px !important;
}

.k-ml-xs {
  margin-left: 4px !important;
}

.k-px-xs {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.k-py-xs {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.k-mx-xs {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.k-my-xs {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.k-p-sm {
  padding: 8px !important;
}

.k-pt-sm {
  padding-top: 8px !important;
}

.k-pr-sm {
  padding-right: 8px !important;
}

.k-pb-sm {
  padding-bottom: 8px !important;
}

.k-pl-sm {
  padding-left: 8px !important;
}

.k-m-sm {
  margin: 8px !important;
}

.k-mt-sm {
  margin-top: 8px !important;
}

.k-mr-sm {
  margin-right: 8px !important;
}

.k-mb-sm {
  margin-bottom: 8px !important;
}

.k-ml-sm {
  margin-left: 8px !important;
}

.k-px-sm {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.k-py-sm {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.k-mx-sm {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.k-my-sm {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.k-p-md {
  padding: 12px !important;
}

.k-pt-md {
  padding-top: 12px !important;
}

.k-pr-md {
  padding-right: 12px !important;
}

.k-pb-md {
  padding-bottom: 12px !important;
}

.k-pl-md {
  padding-left: 12px !important;
}

.k-m-md {
  margin: 12px !important;
}

.k-mt-md {
  margin-top: 12px !important;
}

.k-mr-md {
  margin-right: 12px !important;
}

.k-mb-md {
  margin-bottom: 12px !important;
}

.k-ml-md {
  margin-left: 12px !important;
}

.k-px-md {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.k-py-md {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.k-mx-md {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.k-my-md {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.k-p-lg {
  padding: 16px !important;
}

.k-pt-lg {
  padding-top: 16px !important;
}

.k-pr-lg {
  padding-right: 16px !important;
}

.k-pb-lg {
  padding-bottom: 16px !important;
}

.k-pl-lg {
  padding-left: 16px !important;
}

.k-m-lg {
  margin: 16px !important;
}

.k-mt-lg {
  margin-top: 16px !important;
}

.k-mr-lg {
  margin-right: 16px !important;
}

.k-mb-lg {
  margin-bottom: 16px !important;
}

.k-ml-lg {
  margin-left: 16px !important;
}

.k-px-lg {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.k-py-lg {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.k-mx-lg {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.k-my-lg {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.k-p-xl {
  padding: 24px !important;
}

.k-pt-xl {
  padding-top: 24px !important;
}

.k-pr-xl {
  padding-right: 24px !important;
}

.k-pb-xl {
  padding-bottom: 24px !important;
}

.k-pl-xl {
  padding-left: 24px !important;
}

.k-m-xl {
  margin: 24px !important;
}

.k-mt-xl {
  margin-top: 24px !important;
}

.k-mr-xl {
  margin-right: 24px !important;
}

.k-mb-xl {
  margin-bottom: 24px !important;
}

.k-ml-xl {
  margin-left: 24px !important;
}

.k-px-xl {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.k-py-xl {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.k-mx-xl {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.k-my-xl {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.k-p-thin {
  padding: 2px !important;
}

.k-pt-thin {
  padding-top: 2px !important;
}

.k-pr-thin {
  padding-right: 2px !important;
}

.k-pb-thin {
  padding-bottom: 2px !important;
}

.k-pl-thin {
  padding-left: 2px !important;
}

.k-m-thin {
  margin: 2px !important;
}

.k-mt-thin {
  margin-top: 2px !important;
}

.k-mr-thin {
  margin-right: 2px !important;
}

.k-mb-thin {
  margin-bottom: 2px !important;
}

.k-ml-thin {
  margin-left: 2px !important;
}

.k-px-thin {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.k-py-thin {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.k-mx-thin {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.k-my-thin {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.k-p-hair {
  padding: 1px !important;
}

.k-pt-hair {
  padding-top: 1px !important;
}

.k-pr-hair {
  padding-right: 1px !important;
}

.k-pb-hair {
  padding-bottom: 1px !important;
}

.k-pl-hair {
  padding-left: 1px !important;
}

.k-m-hair {
  margin: 1px !important;
}

.k-mt-hair {
  margin-top: 1px !important;
}

.k-mr-hair {
  margin-right: 1px !important;
}

.k-mb-hair {
  margin-bottom: 1px !important;
}

.k-ml-hair {
  margin-left: 1px !important;
}

.k-px-hair {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.k-py-hair {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.k-mx-hair {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.k-my-hair {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.k-table-layout-fixed {
  table-layout: fixed;
}

.k-table-layout-auto {
  table-layout: auto;
}

.k-text-nowrap {
  white-space: nowrap !important;
}

.k-text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-text-left {
  text-align: left !important;
}

.k-text-right {
  text-align: right !important;
}

.k-text-center {
  text-align: center !important;
}

.k-text-justify {
  text-align: justify !important;
}

.k-text-lowercase {
  text-transform: lowercase !important;
}

.k-text-uppercase {
  text-transform: uppercase !important;
}

.k-text-capitalize {
  text-transform: capitalize !important;
}

.k-font-weight-light {
  font-weight: 300 !important;
}

.k-font-weight-normal {
  font-weight: 400 !important;
}

.k-font-weight-bold {
  font-weight: 700 !important;
}

.k-touch-action-none {
  touch-action: none !important;
}

.k-touch-action-auto {
  touch-action: auto !important;
}

.k-rotate-45 {
  transform: rotate(45deg);
}

.k-rotate-90 {
  transform: rotate(90deg);
}

.k-rotate-135 {
  transform: rotate(135deg);
}

.k-rotate-180 {
  transform: rotate(180deg);
}

.k-rotate-225 {
  transform: rotate(225deg);
}

.k-rotate-270 {
  transform: rotate(270deg);
}

.k-rotate-315 {
  transform: rotate(315deg);
}

.k-scale-0 {
  transform: scale(0);
}

.k-scale-1 {
  transform: scale(1);
}

.k-scale-2 {
  transform: scale(2);
}

.k-translate-0 {
  transform: translate(0);
}

.k-translate-0-50 {
  transform: translate(0, 50%);
}

.k-translate-0-100 {
  transform: translate(0, 100%);
}

.k-translate-50-0 {
  transform: translate(50%);
}

.k-translate-50-50 {
  transform: translate(50%, 50%);
}

.k-translate-50-100 {
  transform: translate(50%, 100%);
}

.k-translate-100-0 {
  transform: translate(100%);
}

.k-translate-100-50 {
  transform: translate(100%, 50%);
}

.k-translate-100-100 {
  transform: translate(100%, 100%);
}

.k-user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.k-user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.k-user-select-text {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.k-user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.k-user-select-contain {
  -webkit-user-select: contain !important;
  user-select: contain !important;
}

.fake {
  color: red;
}

.k-common-test-class {
  opacity: 0;
}

.k-reset {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  text-decoration: none;
  list-style: none;
}

.k-no-click {
  pointer-events: none;
}

.k-floatwrap:after, .k-slider-items:after {
  content: "";
  clear: both;
  visibility: hidden;
  height: 0;
  display: block;
  overflow: hidden;
}

.k-floatwrap, .k-slider-items {
  display: block;
}

.k-block, .k-header, .k-grid-header, .k-toolbar, .k-grouping-header, .k-pager-wrap, .k-tabstrip-items .k-item, .k-link.k-state-hover, .k-dropdown-wrap, .k-picker-wrap, .k-numeric-wrap, .k-dropdown-wrap.k-state-hover, .k-picker-wrap.k-state-hover, .k-numeric-wrap.k-state-hover, .k-draghandle {
  background-position: 0;
  background-repeat: repeat;
}

.k-link:hover {
  text-decoration: none;
}

.k-state-highlight > .k-link {
  color: inherit;
}

.k-textbox, .k-textbox > input, .k-textbox > .k-input, .k-textarea, .k-textarea > textarea, .k-textarea > .k-input .k-multiselect .k-input, .k-input[type="text"], .k-input[type="number"], .k-picker-wrap .k-input {
  -webkit-appearance: none;
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 100%;
}

.k-textbox > .k-input, .k-textarea > .k-input, .k-searchbox > .k-input {
  box-sizing: border-box;
  box-shadow: none;
  color: inherit;
  font: inherit;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-flow: row;
  flex: 0 auto;
  align-items: center;
  width: 100%;
  min-width: 0;
  margin: 0;
  display: flex;
  overflow: hidden;
}

.k-widget, .k-block, .k-inline-block, .k-draghandle {
  -webkit-appearance: none;
  border-style: solid;
  border-width: 1px;
}

.k-block, .k-widget {
  outline: 0;
  line-height: normal;
}

.k-widget .k-input::-ms-clear {
  width: 0;
  height: 0;
}

.k-list-filter ::-ms-clear {
  width: 0;
  height: 0;
}

.k-block, .k-widget, .k-grid, .k-slider, .k-splitter, .k-treeview, .k-panelbar, .k-content, .k-header-column-menu {
  -webkit-tap-highlight-color: #0000;
  outline: 0;
}

.k-block, .k-slider, .k-splitbar, .k-calendar, .k-treeview, .k-pager-wrap, .k-grid-header .k-i-link-horizontal, .k-header-column-menu {
  -webkit-touch-callout: none;
}

.k-header-column-menu {
  color: inherit;
}

.k-block {
  padding: 2px;
}

.k-block > .k-header {
  white-space: nowrap;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: 100%;
  min-height: 16px;
  margin: -2px 0 10px -2px;
  padding: .3em 2px;
  font-size: 1.2em;
  position: relative;
}

.k-toolbar .k-button-icontext {
  padding-right: .8em;
}

.k-link {
  cursor: pointer;
  outline: 0;
  text-decoration: none;
}

.k-grid-header span.k-link {
  cursor: default;
}

.k-state-disabled, .k-state-disabled .k-link, .k-state-disabled .k-icon, .k-state-disabled .k-draghandle, .k-state-disabled .k-upload-button input {
  pointer-events: none;
  outline: 0;
  cursor: default !important;
}

.k-state-disabled > .k-button {
  opacity: 1;
}

@media print {
  .k-state-disabled, .k-state-disabled .k-input {
    opacity: 1 !important;
  }
}

.k-state-error {
  border-style: ridge;
}

.k-state-empty {
  font-style: italic;
}

.k-icon.k-i-none {
  background-image: none !important;
}

.k-image {
  border: 0;
}

.k-widget .k-icon.k-i-expand, .k-widget .k-icon.k-i-collapse {
  cursor: pointer;
}

html .k-success-colored {
  color: #507f50;
  background-color: #f0fff0;
  border-color: #d0dfd0;
}

html .k-info-colored {
  color: #50607f;
  background-color: #f0f9ff;
  border-color: #d0d9df;
}

html .k-error-colored {
  color: #7f5050;
  background-color: #fff0f0;
  border-color: #dfd0d0;
}

.k-inline-block {
  padding: 0 2px;
}

.k-loading, .k-loading-image, .k-icon.k-i-loading {
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
}

.k-icon.k-i-loading:before {
  content: "";
  display: none;
}

.k-loading-mask, .k-loading-image, .k-loading-text {
  position: absolute;
}

.k-loading-mask {
  z-index: 100;
}

.k-loading-mask.k-opaque .k-loading-color {
  opacity: 1;
}

.k-loading-mask .k-loading-progress {
  margin: auto;
  position: absolute;
  inset: 0;
}

.k-loading-text {
  text-indent: -4000px;
  text-align: center;
}

.k-loading-image, .k-loading-color {
  width: 100%;
  height: 100%;
}

.k-loading-image {
  z-index: 2;
  top: 0;
  left: 0;
}

.k-loading-color {
  opacity: .3;
}

.k-content-frame {
  border: 0;
  width: 100%;
  height: 100%;
}

.k-pane > .k-splitter-overlay {
  opacity: 0;
  position: absolute;
}

.k-drag-clue {
  white-space: nowrap;
  cursor: default;
  z-index: 10003;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: 4px 8px;
  font-size: 1em;
  line-height: normal;
  list-style: none;
  position: absolute;
  overflow: hidden;
}

.k-drag-status {
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 4px;
}

.k-reorder-cue {
  width: 1px;
  position: absolute;
  overflow: visible;
}

.k-reorder-cue .k-icon {
  width: 8px;
  height: 4px;
  position: absolute;
  left: -4px;
}

.k-reorder-cue .k-i-arrow-60-down {
  top: -4px;
}

.k-reorder-cue .k-i-arrow-60-up {
  bottom: -4px;
}

.k-scrollbar {
  position: absolute;
  overflow: scroll;
}

.k-scrollbar-vertical {
  width: 17px;
  height: 100%;
  top: 0;
  right: 0;
  overflow-x: hidden;
}

.k-touch-scrollbar {
  z-index: 200000;
  background-color: #858585;
  border: 1px solid #8a8a8a;
  width: 8px;
  height: 8px;
  display: none;
  position: absolute;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  body .k-touch-scrollbar {
    border-radius: 7px;
    width: 12px;
    height: 12px;
  }
}

.k-virtual-scrollable-wrap {
  overflow-x: auto;
}

.k-current-time {
  background: red;
  position: absolute;
}

.k-current-time-arrow-down {
  background: none;
  border: 4px solid #0000;
  border-top-color: red;
  width: 0;
  height: 0;
}

.k-current-time-arrow-left {
  background: none;
  border: 4px solid #0000;
  border-right-color: red;
  width: 0;
  height: 0;
}

.k-current-time-arrow-right {
  background: none;
  border: 4px solid #0000;
  border-left-color: red;
  width: 0;
  height: 0;
}

.k-animation-container, .k-widget, .k-widget *, .k-animation-container *, .k-widget :before, .k-animation-container :after, .k-block .k-header, .k-list-container {
  box-sizing: content-box;
}

.k-textbox, .k-autocomplete, .k-tabstrip > .k-content > .km-scroll-container, .k-block, .k-edit-cell .k-widget, .k-grid-edit-row .k-widget, .k-grid-edit-row .text-box, .km-actionsheet > li, .km-shim, .input-group .form-control {
  box-sizing: border-box;
}

.form-control.k-widget {
  padding: 0;
}

.km-widget, .km-widget * {
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box;
}

.k-pdf-export-shadow {
  width: 14400px;
  position: absolute;
  left: -15000px;
  overflow: hidden;
}

.k-hr {
  float: none;
  clear: both;
  border-style: solid;
  border-width: 1px 0 0;
  height: 0;
  margin: .5em auto;
  padding: 0;
  display: block;
}

.k-icon, .k-tool-icon {
  text-align: center;
  vertical-align: middle;
  speak: none;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
  background-image: none;
  width: 1em;
  height: 1em;
  font: 16px / 1 WebComponentsIcons;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.k-drop-hint {
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  display: flex;
  position: absolute;
  transform: translate(-50%, -50%);
}

.k-drop-hint-start, .k-drop-hint-end {
  border: 4px solid #0000;
  flex-shrink: 0;
}

.k-drop-hint-line {
  flex-grow: 1;
}

.k-drop-hint-h .k-drop-hint-start {
  border-left-width: 6.9282px;
  border-right-width: 0;
}

.k-drop-hint-h .k-drop-hint-line {
  width: 20px;
  height: 1px;
}

.k-drop-hint-h .k-drop-hint-end {
  border-left-width: 0;
  border-right-width: 6.9282px;
}

.k-drop-hint-v {
  flex-direction: column;
}

.k-drop-hint-v .k-drop-hint-start {
  border-top-width: 6.9282px;
  border-bottom-width: 0;
}

.k-drop-hint-v .k-drop-hint-line {
  width: 1px;
  height: 20px;
}

.k-drop-hint-v .k-drop-hint-end {
  border-top-width: 0;
  border-bottom-width: 6.9282px;
}

.k-column-menu .k-sprite, .k-grid-mobile .k-resize-handle-inner:before, .k-grid-mobile .k-resize-handle-inner:after, .k-pager-numbers .k-current-page .k-link:after, .k-gantt-views > .k-current-view > .k-link:after {
  text-align: center;
  vertical-align: middle;
  speak: none;
  font-variant: normal;
  text-transform: none;
  text-indent: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: none;
  width: 1em;
  height: 1em;
  font: 16px / 1 WebComponentsIcons;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.k-icon:before {
  width: 1em;
  height: 1em;
  margin: auto;
  line-height: 1;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.k-button.k-button-icon .k-icon, .k-grid-filter .k-icon, .k-header .k-icon {
  text-indent: -99999px;
  overflow: hidden;
}

.k-button.k-button-icon .k-icon:before, .k-grid-filter .k-icon:before, .k-header .k-icon:before {
  text-indent: 0;
}

.k-sprite {
  text-align: center;
  vertical-align: middle;
  -ms-high-contrast-adjust: none;
  background-color: #0000;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  font-size: 0;
  line-height: 0;
  display: inline-block;
  overflow: hidden;
}

.k-list-container .k-footer:before, .k-list-container .k-footer:after {
  content: "";
  display: block;
  overflow: hidden;
}

.k-reorder-cue {
  position: absolute;
}

.k-reorder-cue:before, .k-reorder-cue:after {
  content: "";
  border: 3px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  transform: translateX(-50%);
}

.k-reorder-cue:before {
  border-top-color: currentColor;
  border-bottom-width: 0;
  top: -4px;
}

.k-reorder-cue:after {
  border-top-width: 0;
  border-bottom-color: currentColor;
  bottom: -4px;
}

.k-flip-h {
  transform: scaleX(-1);
}

.k-flip-v {
  transform: scaleY(-1);
}

.k-flip-h.k-flip-v {
  transform: scale(-1);
}

.k-text-align-left, .k-text-left {
  text-align: left;
}

.k-text-align-center, .k-text-center {
  text-align: center;
}

.k-text-align-right, .k-text-right {
  text-align: right;
}

.k-text-align-justify, .k-text-justify {
  text-align: justify;
}

script {
  display: none !important;
}

.k-button {
  box-sizing: border-box;
  font: inherit;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-position: 0;
  background-repeat: repeat-x;
  border-style: solid;
  border-width: 1px;
  outline: none;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 4px 8px;
  line-height: 1.42857;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.k-button::-moz-focus-inner {
  border: 0;
  outline: 0;
  padding: 0;
}

.k-button:hover, .k-button:focus {
  outline: 0;
  text-decoration: none;
}

.k-button .k-text, .k-button .k-button-text {
  white-space: nowrap;
  overflow: hidden;
}

.k-button .k-icon, .k-button .k-image, .k-button .k-sprite {
  color: inherit;
  pointer-events: none;
  align-self: center;
}

.k-button-icontext {
  overflow: visible;
}

.k-button-icontext .k-icon, .k-button-icontext .k-image, .k-button-icontext .k-sprite {
  margin-left: -.125em;
  margin-right: .1875em;
}

.k-button[disabled], .k-state-disabled .k-button, .k-button.k-state-disabled {
  cursor: default;
  box-shadow: none;
  opacity: .6;
  pointer-events: none;
  outline: 0;
}

.k-icon-button, .k-button.k-button-icon {
  width: calc(1.42857em + 10px);
  height: calc(1.42857em + 10px);
  padding: 4px;
}

.k-icon-button .k-text, .k-button.k-button-icon .k-text, .k-icon-button .k-button-text, .k-button.k-button-icon .k-button-text {
  display: none;
}

a.k-button-expand {
  display: block;
}

button.k-button-expand, input[type="submit"].k-button-expand, input[type="button"].k-button-expand, input[type="reset"].k-button-expand {
  width: 100%;
}

.k-button.k-flat, .k-button.k-button-clear {
  color: inherit;
  transition: color .2s ease-in-out;
  box-shadow: none !important;
  background: none !important;
  border-color: #0000 !important;
}

.k-button.k-flat:hover, .k-button.k-button-clear:hover, .k-button.k-flat.k-state-hover, .k-button.k-button-clear.k-state-hover, .k-button.k-flat:active, .k-button.k-button-clear:active, .k-button.k-flat.k-state-active, .k-button.k-button-clear.k-state-active, .k-button.k-flat:hover:active, .k-button.k-button-clear:hover:active, .k-button.k-flat:hover.k-state-active, .k-button.k-button-clear:hover.k-state-active {
  color: inherit;
}

.k-button.k-flat:before, .k-button.k-button-clear:before, .k-button.k-flat:after, .k-button.k-button-clear:after {
  display: block;
}

.k-button.k-flat:focus:after, .k-button.k-button-clear:focus:after, .k-button.k-flat.k-state-focused:after, .k-button.k-button-clear.k-state-focused:after {
  opacity: .12;
  box-shadow: inset 0 0 0 2px;
}

.k-button.k-flat[disabled], .k-button.k-button-clear[disabled], .k-state-disabled .k-button.k-flat, .k-state-disabled .k-button.k-button-clear, .k-button.k-flat.k-state-disabled, .k-button.k-button-clear.k-state-disabled {
  pointer-events: none;
}

.k-button:before {
  border-radius: inherit;
  content: "";
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  background: currentColor;
  transition: opacity .2s ease-in-out;
  display: none;
  position: absolute;
  inset: 0;
}

.k-button:hover:before, .k-button.k-state-hover:before {
  opacity: .08;
}

.k-button.k-no-focus:not(:hover):before, .k-button.k-no-focus:not(.k-state-hover):before {
  opacity: 0;
}

.k-button:active:before, .k-button.k-state-active:before {
  opacity: .16;
}

.k-button.k-state-selected:before {
  opacity: .2;
}

.k-button:after {
  border-radius: inherit;
  content: "";
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  transition: opacity .2s ease-in-out;
  display: none;
  position: absolute;
  inset: 0;
}

.k-button-group {
  white-space: nowrap;
  vertical-align: middle;
  border-width: 0;
  flex-flow: row;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
  position: relative;
}

.k-button-group.k-button-group-flat .k-button.k-state-selected {
  color: inherit;
}

.k-button-group .k-button {
  position: relative;
}

.k-button-group .k-button + .k-button {
  margin-left: -1px;
}

.k-button-group.k-button-group-stretched {
  display: flex;
}

.k-button-group.k-button-group-stretched .k-button {
  flex: 1 0 auto;
}

.k-button-group .k-button:active, .k-button-group .k-button.k-state-active {
  z-index: 2;
}

.k-button-group .k-button:hover, .k-button-group .k-button.k-state-hover {
  z-index: 3;
}

.k-button-group .k-button.k-state-disabled, .k-button-group.k-state-disabled .k-button {
  z-index: auto;
}

.k-button-group .k-button:focus, .k-button-group .k-button.k-state-focused {
  z-index: 4;
}

.k-button-group > input[type="radio"], .k-button-group > input[type="checkbox"], .k-button-group label input[type="radio"], .k-button-group label input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  margin: 0;
  padding: 0;
  position: absolute;
}

.k-split-button {
  flex: row nowrap;
  vertical-align: middle;
  border-width: 0;
  border-radius: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-flex;
}

.k-split-button > .k-split-button-arrow, .k-split-button > .k-button:last-child {
  flex: none;
  width: auto;
  margin-left: -1px;
  padding: 4px;
}

.k-split-container > li {
  padding: 1px;
}

.k-split-container .k-button {
  text-align: left;
  white-space: nowrap;
  background-color: #0000;
  border-color: #0000;
  justify-content: flex-start;
  width: 100%;
  background-image: none !important;
}

.k-action-buttons {
  text-align: right;
  clear: both;
  margin: 1em 0 0;
  padding: 6px 8px;
  position: relative;
}

.k-action-buttons .k-button {
  min-width: 75px;
}

.k-action-buttons .k-button + .k-button {
  margin-left: 6px;
}

.k-action-buttons .k-button.k-left {
  float: left;
  margin: 0 0 0 1em;
}

.k-rtl .k-button-group .k-button {
  margin-left: 0;
}

.k-rtl .k-button-group .k-button + .k-button {
  margin-right: -1px;
}

.k-rtl .k-split-button .k-split-button-arrow {
  margin-left: 0;
  margin-right: -1px;
}

.k-rtl .k-split-container .k-button {
  text-align: right;
}

.k-no-flexbox .k-button, .k-no-flexbox .k-button-group {
  display: inline-block;
}

.k-no-flexbox .k-button-group:after {
  content: "";
  clear: both;
  display: block;
}

.k-no-flexbox .k-button-group .k-button {
  vertical-align: top;
  display: inline-block;
}

.k-toolbar {
  font-size: inherit;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  outline: 0;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 2px 4px;
  line-height: 1.42857;
  display: flex;
  position: relative;
  overflow: hidden;
}

.k-toolbar:before {
  content: "";
  height: calc(1.42857em + 10px);
}

.k-toolbar.k-toolbar-resizable {
  flex-wrap: nowrap;
}

.k-toolbar > * {
  vertical-align: middle;
  flex-shrink: 0;
  align-content: center;
  align-items: stretch;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 4px;
  display: inline-flex;
}

.k-toolbar > * > label {
  margin-right: 4px;
}

.k-toolbar > :last-child {
  margin-right: 0;
}

.k-toolbar .k-button, .k-toolbar .k-button-group, .k-toolbar .k-separator, .k-toolbar .k-split-button, .k-toolbar .k-textbox, .k-toolbar .k-widget, .k-toolbar label {
  align-self: center;
}

.k-toolbar .k-split-button > .k-button {
  align-self: stretch;
}

.k-toolbar .k-overflow-anchor {
  border-width: 0 0 0 1px;
  border-color: inherit;
  box-sizing: border-box;
  border-radius: 0;
  justify-content: center;
  width: calc(1.42857em + 18px);
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.k-toolbar .k-separator, .k-toolbar .k-toolbar-separator {
  border-style: solid;
  border-width: 0 0 0 1px;
  width: 0;
  height: 1.42857em;
}

.k-toolbar .k-spacer, .k-toolbar .k-toolbar-spacer {
  flex: auto;
  height: 1.42857em;
}

.k-overflow-container > .k-item {
  padding: 1px;
}

.k-overflow-container .k-overflow-tool-group {
  display: block;
}

.k-overflow-container .k-overflow-button {
  text-align: left;
  background-color: #0000;
  border-color: #0000;
  justify-content: flex-start;
  width: 100%;
  background-image: none !important;
}

.k-overflow-container .k-button-group {
  flex-direction: column;
  display: flex;
}

.k-overflow-container .k-button-group .k-button ~ .k-button {
  margin: 0;
}

.k-overflow-container .k-split-button {
  flex-direction: column;
  display: flex;
}

.k-overflow-container .k-split-button .k-button {
  padding: 4px 8px;
}

.k-overflow-container .k-split-button .k-button ~ .k-button {
  margin: 0;
}

.k-overflow-container .k-separator, .k-overflow-container .k-overflow-hidden {
  display: none;
}

.k-rtl .k-toolbar > * {
  margin-left: 4px;
  margin-right: 0;
}

.k-rtl .k-toolbar > :last-child {
  margin-left: 0;
}

.k-rtl .k-toolbar .k-overflow-anchor {
  border-left-width: 0;
  border-right-width: 1px;
  left: 0;
  right: auto;
}

.k-rtl .k-overflow-container .k-overflow-button {
  text-align: right;
}

.k-no-flexbox .k-toolbar {
  display: block;
}

.k-no-flexbox .k-toolbar.k-toolbar-resizable {
  white-space: nowrap;
}

.k-no-flexbox .k-toolbar > * {
  vertical-align: middle;
  display: inline-block;
}

.k-no-flexbox .k-toolbar > .k-align-left {
  float: none;
}

.k-no-flexbox .k-toolbar > .k-align-right {
  float: right;
}

.k-rpanel-left {
  left: 0;
  transform: translateX(-100%)translateZ(0);
}

.k-rpanel-right {
  right: 0;
  transform: translateX(100%)translateZ(0);
}

.k-rpanel-left, .k-rpanel-right {
  min-width: 320px;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  overflow: auto;
}

.k-rpanel-left.k-rpanel-expanded, .k-rpanel-right.k-rpanel-expanded {
  transform: translateX(0)translateZ(0);
}

.k-rpanel-left + *, .k-rpanel-right + * {
  overflow: auto;
}

.k-ie9 .k-rpanel-left {
  left: -100%;
}

.k-ie9 .k-rpanel-left.k-rpanel-expanded {
  left: 0;
}

.k-rpanel-top {
  max-height: 0;
  position: static;
}

.k-rpanel-top.k-rpanel-expanded {
  max-height: 568px;
  overflow: visible !important;
}

.k-rpanel-toggle {
  touch-action: manipulation;
}

.k-form {
  box-sizing: border-box;
  font-size: inherit;
  border-width: 0;
  line-height: 1.42857;
}

.k-form-fieldset {
  box-sizing: border-box;
  border-width: 0;
  margin: 32px 0 0;
  padding: 0;
}

.k-form-fieldset > :first-child {
  margin-top: 0;
}

.k-form-legend {
  box-sizing: border-box;
  font-size: inherit;
  text-transform: uppercase;
  border-style: solid;
  border-width: 0 0 2px;
  width: 100%;
  margin: 0 0 16px;
  padding: 0;
  line-height: 1.42857;
}

.k-form-field {
  box-sizing: border-box;
  flex-flow: column;
  margin: 0 0 16px;
  display: flex;
}

.k-form-field:first-child {
  margin-top: 0;
}

.k-form-field > .k-label, .k-form-field > .k-form-label {
  flex-direction: row;
  flex-shrink: 0;
  margin: 0 0 4px;
  display: inline-flex;
}

.k-form-field .k-checkbox-label, .k-form-field .k-radio-label {
  display: inline-flex;
}

.k-form-field .k-textbox, .k-form-field .k-textarea, .k-form-field .k-autocomplete, .k-form-field .k-combobox, .k-form-field .k-dropdown, .k-form-field .k-dropdowntree, .k-form-field .k-dateinput, .k-form-field .k-datepicker, .k-form-field .k-datetimepicker, .k-form-field .k-daterangepicker-wrap, .k-form-field .k-maskedtextbox, .k-form-field .k-multiselect, .k-form-field .k-numerictextbox, .k-form-field .k-timepicker, .k-form-field .k-textbox-container, .k-form-field .k-floating-label-container {
  width: 100%;
}

.k-form-hint, .k-form-error {
  flex-flow: row;
  margin: 0 0 4px;
  padding: 4px 0 0;
  font-size: 12px;
  display: flex;
}

.k-form-horizontal .k-form-field {
  flex-flow: row;
  display: flex;
}

.k-form-horizontal .k-form-field > .k-label, .k-form-horizontal .k-form-field > .k-form-label {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 25%;
  margin-right: 10px;
  padding-top: 5px;
}

.k-form-horizontal .k-form-field .k-label-optional {
  align-self: inherit;
  margin: 0;
}

.k-form-horizontal .k-form-field-wrap {
  flex: 1;
}

.k-form-buttons {
  border-style: solid;
  border-width: 0;
  flex-flow: row;
  margin: 16px 0 0;
  padding: 0;
  display: flex;
}

.k-form-buttons > * {
  margin-inline-start: 8px;
}

.k-form-buttons > :first-child {
  margin-inline-start: 0;
}

.k-form-buttons.k-buttons-end {
  justify-content: flex-end;
}

.k-edit-form {
  margin: 0;
  padding: 0;
}

.k-window > div.k-popup-edit-form {
  padding: 1em 0;
}

.k-grid-edit-row .k-edit-form td {
  border-bottom-width: 0;
}

.k-edit-form-container {
  width: 400px;
  position: relative;
}

.k-edit-label, .k-edit-form-container .editor-label {
  float: left;
  clear: both;
  text-align: right;
  width: 30%;
  margin-left: 2%;
  padding: .4em 0 1em;
}

.k-edit-field, .k-edit-form-container .editor-field {
  float: right;
  clear: right;
  width: 60%;
  margin-right: 2%;
  padding: 0 0 .6em;
}

.k-edit-field.k-no-editor, .k-edit-form-container .editor-field.k-no-editor {
  padding: .4em 0 1em;
}

.k-edit-form-container .k-button {
  margin: 0 .16em;
}

.k-edit-form-container .k-button-group .k-button {
  margin-right: 0;
}

.k-edit-field > .k-button:first-child {
  margin-left: 0;
}

.k-edit-form-container .k-edit-buttons {
  clear: both;
  text-align: right;
  border-style: solid;
  border-width: 1px 0 0;
  padding: .6em;
  position: relative;
  bottom: -1em;
}

.k-tabstrip {
  box-sizing: border-box;
  border: 1px solid #0000;
  outline: 0;
  flex-flow: column;
  margin: 0;
  padding: 4px;
  display: flex;
  position: relative;
}

.k-tabstrip-items-wrapper {
  border-style: solid;
  border-width: 0;
  border-color: inherit;
}

.k-tabstrip-items {
  outline: 0;
  flex-direction: row;
  flex: none;
  display: flex;
  position: relative;
}

.k-tabstrip-items .k-item {
  border: 1px solid #0000;
  outline: 0;
  flex-direction: row;
  flex-shrink: 0;
  place-items: stretch stretch;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.k-tabstrip-items .k-tab-on-top {
  z-index: 1;
}

.k-tabstrip-items .k-link {
  color: inherit;
  cursor: pointer;
  vertical-align: middle;
  flex-direction: row;
  flex: auto;
  align-content: center;
  align-items: center;
  padding: .5em 1em;
  display: inline-flex;
}

.k-tabstrip-content, .k-tabstrip > .k-content {
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
  flex: auto;
  padding: 1em;
  display: none;
  overflow: auto;
}

.k-tabstrip-content.k-state-active, .k-tabstrip > .k-content.k-state-active {
  display: block;
}

.k-tabstrip-content:focus, .k-tabstrip > .k-content:focus, .k-tabstrip-content.k-state-focused, .k-tabstrip > .k-content.k-state-focused {
  outline-offset: -1px;
  outline-width: 1px;
  outline-style: dotted;
}

.k-tabstrip-items .k-loading {
  border-top: 1px solid #0000;
  border-color: inherit;
  background: none;
  width: 20%;
  height: 0;
  transition: width .2s linear;
  animation: 1s ease-in-out infinite k-tab-loader;
  position: absolute;
  top: 0;
  left: 0;
}

.k-tabstrip-items .k-progress {
  animation: none;
}

.k-tabstrip-items .k-loading.k-complete {
  width: 100%;
  animation: none;
}

@keyframes k-tab-loader {
  0% {
    left: 0;
  }

  50% {
    left: 80%;
  }

  100% {
    left: 0;
  }
}

.k-tabstrip-top {
  flex-direction: column;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper {
  border-bottom-width: 1px;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item {
  border-radius: 4px 4px 0 0;
  margin-bottom: -1px;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item + .k-item {
  margin-left: -1px;
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-state-active {
  border-bottom-color: #0000;
}

.k-tabstrip-top > .k-content, .k-tabstrip-top > .k-tabstrip-content {
  border-top-width: 0;
}

.k-tabstrip-bottom {
  flex-direction: column;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper {
  border-top-width: 1px;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item {
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item + .k-item {
  margin-left: -1px;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-item.k-state-active {
  border-top-color: #0000;
}

.k-tabstrip-bottom > .k-tabstrip-items-wrapper .k-loading {
  top: auto;
  bottom: 0;
}

.k-tabstrip-bottom > .k-content, .k-tabstrip-bottom > .k-tabstrip-content {
  border-bottom-width: 0;
}

.k-tabstrip-left {
  flex-direction: row;
}

.k-tabstrip-left > .k-tabstrip-items-wrapper {
  border-right-width: 1px;
}

.k-tabstrip-left > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  flex-direction: column;
  display: inline-flex;
}

.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item {
  border-radius: 4px 0 0 4px;
  margin-right: -1px;
}

.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item + .k-item {
  margin-top: -1px;
}

.k-tabstrip-left > .k-tabstrip-items-wrapper .k-item.k-state-active {
  border-right-color: #0000;
}

.k-tabstrip-left > .k-content, .k-tabstrip-left > .k-tabstrip-content {
  border-left-width: 0;
}

.k-tabstrip-right {
  flex-direction: row-reverse;
}

.k-tabstrip-right > .k-tabstrip-items-wrapper {
  border-left-width: 1px;
}

.k-tabstrip-right > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  flex-direction: column;
  display: inline-flex;
}

.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}

.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item + .k-item {
  margin-top: -1px;
}

.k-tabstrip-right > .k-tabstrip-items-wrapper .k-item.k-state-active {
  border-left-color: #0000;
}

.k-tabstrip-right > .k-content, .k-tabstrip-right > .k-tabstrip-content {
  border-right-width: 0;
}

.k-tabstrip.k-tabstrip-scrollable > .k-tabstrip-items-wrapper {
  border-width: 0;
}

.k-tabstrip.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-tabstrip-items {
  white-space: nowrap;
  flex: auto;
  overflow: hidden;
}

.k-tabstrip.k-tabstrip-scrollable > .k-tabstrip-items-wrapper > .k-button {
  flex-shrink: 0;
  align-self: stretch;
}

.k-tabstrip.k-tabstrip-scrollable > .k-tabstrip-items-wrapper.k-hstack > .k-button {
  height: auto;
}

.k-tabstrip.k-tabstrip-scrollable > .k-tabstrip-items-wrapper.k-vstack > .k-button {
  width: auto;
}

.k-tabstrip.k-tabstrip-scrollable > .k-content, .k-tabstrip.k-tabstrip-scrollable > .k-tabstrip-content {
  border-width: 1px;
}

.k-tabstrip .k-button.k-disabled {
  pointer-events: none;
  opacity: 0;
}

.k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-items-wrapper, .k-tabstrip.k-rtl.k-tabstrip-left > .k-tabstrip-items-wrapper, .k-tabstrip[dir="rtl"].k-tabstrip-left > .k-tabstrip-items-wrapper, .k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-items-wrapper, .k-tabstrip.k-rtl.k-tabstrip-right > .k-tabstrip-items-wrapper, .k-tabstrip[dir="rtl"].k-tabstrip-right > .k-tabstrip-items-wrapper {
  order: 1;
}

.k-rtl .k-tabstrip.k-tabstrip-left > .k-content, .k-tabstrip.k-rtl.k-tabstrip-left > .k-content, .k-tabstrip[dir="rtl"].k-tabstrip-left > .k-content, .k-rtl .k-tabstrip.k-tabstrip-right > .k-content, .k-tabstrip.k-rtl.k-tabstrip-right > .k-content, .k-tabstrip[dir="rtl"].k-tabstrip-right > .k-content, .k-rtl .k-tabstrip.k-tabstrip-left > .k-tabstrip-content, .k-tabstrip.k-rtl.k-tabstrip-left > .k-tabstrip-content, .k-tabstrip[dir="rtl"].k-tabstrip-left > .k-tabstrip-content, .k-rtl .k-tabstrip.k-tabstrip-right > .k-tabstrip-content, .k-tabstrip.k-rtl.k-tabstrip-right > .k-tabstrip-content, .k-tabstrip[dir="rtl"].k-tabstrip-right > .k-tabstrip-content {
  order: 0;
}

.k-rtl .k-tabstrip .k-tabstrip-prev, .k-tabstrip.k-rtl .k-tabstrip-prev, .k-tabstrip[dir="rtl"] .k-tabstrip-prev, .k-rtl .k-tabstrip .k-tabstrip-next, .k-tabstrip.k-rtl .k-tabstrip-next, .k-tabstrip[dir="rtl"] .k-tabstrip-next {
  transform: scaleX(-1);
}

.k-pager-wrap {
  cursor: default;
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  flex: none;
  align-items: center;
  padding: 4px;
  display: flex;
  overflow: hidden;
}

.k-pager-wrap .k-link {
  color: inherit;
  font: inherit;
  background-color: inherit;
  outline: 0;
  text-decoration: none;
}

.k-pager-wrap .k-widget, .k-pager-wrap select.k-dropdown {
  font-size: inherit;
}

.k-rtl .k-pager-wrap .k-i-arrow-end-left, .k-pager-wrap[dir="rtl"] .k-i-arrow-end-left, [dir="rtl"] .k-pager-wrap .k-i-arrow-end-left, .k-rtl .k-pager-wrap .k-i-arrow-60-left, .k-pager-wrap[dir="rtl"] .k-i-arrow-60-left, [dir="rtl"] .k-pager-wrap .k-i-arrow-60-left, .k-rtl .k-pager-wrap .k-i-arrow-60-right, .k-pager-wrap[dir="rtl"] .k-i-arrow-60-right, [dir="rtl"] .k-pager-wrap .k-i-arrow-60-right, .k-rtl .k-pager-wrap .k-i-arrow-end-right, .k-pager-wrap[dir="rtl"] .k-i-arrow-end-right, [dir="rtl"] .k-pager-wrap .k-i-arrow-end-right, .k-rtl .k-pager-wrap .k-i-seek-w, .k-pager-wrap[dir="rtl"] .k-i-seek-w, [dir="rtl"] .k-pager-wrap .k-i-seek-w, .k-rtl .k-pager-wrap .k-i-arrow-w, .k-pager-wrap[dir="rtl"] .k-i-arrow-w, [dir="rtl"] .k-pager-wrap .k-i-arrow-w, .k-rtl .k-pager-wrap .k-i-arrow-e, .k-pager-wrap[dir="rtl"] .k-i-arrow-e, [dir="rtl"] .k-pager-wrap .k-i-arrow-e, .k-rtl .k-pager-wrap .k-i-seek-e, .k-pager-wrap[dir="rtl"] .k-i-seek-e, [dir="rtl"] .k-pager-wrap .k-i-seek-e {
  transform: scaleX(-1);
}

.k-pager-nav {
  box-sizing: border-box;
  color: inherit;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 3em;
  outline: 0;
  justify-content: center;
  align-items: center;
  min-width: 2.14em;
  height: 2.14em;
  padding: 4px;
  display: inline-flex;
  position: relative;
}

.k-pager-nav:hover {
  z-index: 2;
}

.k-pager-nav.k-state-disabled {
  color: inherit;
}

.k-pager-numbers-wrap {
  flex-direction: row;
  align-items: center;
  display: flex;
  position: relative;
}

.k-pager-numbers-wrap select.k-dropdown {
  cursor: pointer;
  height: 100%;
  display: none;
}

.k-pager-numbers {
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.k-pager-numbers li {
  flex-direction: row;
  display: inline-flex;
}

.k-pager-numbers .k-link {
  box-sizing: border-box;
  color: inherit;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 3em;
  outline: 0;
  justify-content: center;
  align-items: center;
  min-width: 2.14em;
  height: 2.14em;
  padding: 4px;
  display: inline-flex;
  position: relative;
}

.k-pager-numbers .k-link:hover {
  z-index: 2;
}

.k-pager-numbers .k-link.k-state-disabled {
  color: inherit;
}

.k-pager-numbers .k-state-selected {
  cursor: inherit;
  z-index: 2;
}

.k-pager-nav + .k-pager-nav, .k-pager-nav + .k-pager-numbers-wrap, .k-pager-numbers-wrap + .k-pager-nav, .k-pager-numbers li + li {
  margin-left: 2px;
}

.k-rtl .k-pager-nav + .k-pager-nav, .k-rtl .k-pager-nav + .k-pager-numbers-wrap, .k-rtl .k-pager-numbers-wrap + .k-pager-nav, .k-rtl .k-pager-numbers li + li, [dir="rtl"] .k-pager-nav + .k-pager-nav, [dir="rtl"] .k-pager-nav + .k-pager-numbers-wrap, [dir="rtl"] .k-pager-numbers-wrap + .k-pager-nav, [dir="rtl"] .k-pager-numbers li + li {
  margin-left: 0;
  margin-right: 2px;
}

.k-pager-input {
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
}

.k-pager-input .k-textbox {
  width: 3em;
  margin: 0 1ex;
}

.k-pager-sizes {
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
}

.k-pager-sizes .k-dropdown, .k-pager-sizes > select {
  width: 5em;
  margin-right: 1ex;
}

.k-rtl .k-pager-sizes .k-dropdown, [dir="rtl"] .k-pager-sizes .k-dropdown, .k-rtl .k-pager-sizes > select, [dir="rtl"] .k-pager-sizes > select {
  margin-left: 1ex;
  margin-right: 0;
}

.k-pager-info {
  text-align: right;
  flex-direction: row;
  flex: 1;
  order: 9;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
}

.k-pager-refresh {
  box-sizing: border-box;
  color: inherit;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 3em;
  outline: 0;
  order: 10;
  justify-content: center;
  align-items: center;
  min-width: 2.14em;
  height: 2.14em;
  margin-left: auto;
  padding: 4px;
  display: inline-flex;
  position: relative;
}

.k-pager-refresh:hover {
  z-index: 2;
}

.k-pager-refresh.k-state-disabled {
  color: inherit;
}

.k-rtl .k-pager-refresh, [dir="rtl"] .k-pager-refresh {
  margin-left: 0;
  margin-right: auto;
}

.k-pager-sm {
  overflow: visible;
}

.k-pager-sm.k-pager-wrap .k-pager-numbers-wrap {
  align-self: center;
  margin-top: 0;
  margin-bottom: 0;
}

.k-pager-sm .k-pager-numbers-wrap {
  box-sizing: border-box;
  border-color: inherit;
  width: 5em;
  height: 2.14em;
  margin-left: 1em;
  margin-right: 1em;
}

.k-pager-sm .k-pager-numbers-wrap select.k-dropdown {
  box-sizing: border-box;
  width: 100%;
  display: flex;
}

.k-rtl .k-pager-sm .k-pager-numbers-wrap, .k-pager-sm[dir="rtl"] .k-pager-numbers-wrap, [dir="rtl"] .k-pager-sm .k-pager-numbers-wrap {
  margin-left: 1em;
  margin-right: 1em;
}

.k-pager-md .k-pager-info, .k-pager-sm .k-pager-info, .k-pager-sm .k-pager-sizes, .k-pager-sm .k-pager-numbers {
  display: none;
}

.k-menu {
  cursor: default;
}

.k-menu .k-link {
  white-space: nowrap;
}

.k-menu .k-item > .k-link {
  color: inherit;
}

.k-menu, .k-menu-scroll-wrapper .k-menu-group, .k-popups-wrapper .k-menu-group, .k-menu .k-menu-group {
  zoom: 1;
  margin: 0;
  padding: 0;
  list-style: none;
}

.k-menu:after {
  content: "";
  float: inherit;
  clear: both;
  width: 99%;
  height: 0;
  display: block;
}

.k-menu-scroll-wrapper .k-item, .k-popups-wrapper .k-item, .k-menu .k-item {
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: -moz-none;
}

.k-popups-wrapper .k-item .k-item, .k-popups-wrapper.vertical > .k-item, .k-menu-scroll-wrapper .k-item .k-item, .k-menu-scroll-wrapper.vertical > .k-item, .k-menu .k-item .k-item, ul.k-menu-vertical > .k-item {
  float: none;
  border-width: 0;
  display: block;
}

.k-menu-scroll-wrapper .k-item > .k-link > .k-icon, .k-menu-scroll-wrapper .k-image, .k-menu-scroll-wrapper .k-sprite, .k-popups-wrapper .k-item > .k-link > .k-icon, .k-popups-wrapper .k-image, .k-popups-wrapper .k-sprite, .k-menu .k-item > .k-link > .k-icon, .k-menu .k-image, .k-menu .k-sprite {
  vertical-align: middle;
  margin: -2px 4px 0 -4px;
}

.k-menu-scroll-wrapper .k-item > .k-link, .k-popups-wrapper .k-item > .k-link, .k-menu .k-item > .k-link {
  -webkit-user-select: none;
  user-select: none;
  padding: .5em 1.1em .4em;
  line-height: 1.34em;
  display: block;
}

.k-menu-vertical > .k-menu-item > .k-menu-link {
  padding-right: 2em;
}

.k-menu-scroll-wrapper .k-menu-group, .k-popups-wrapper .k-menu-group, .k-menu .k-menu-group {
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  display: none;
  overflow: visible;
}

.k-menu-scroll-wrapper .k-menu-group > .k-item, .k-popups-wrapper .k-menu-group > .k-item, .k-menu .k-menu-group > .k-item {
  border-width: 0;
  display: block;
}

.k-menu-scroll-wrapper .k-item, .k-menu-scroll-wrapper.horizontal > .k-item, .k-popups-wrapper .k-item, .k-popups-wrapper.horizontal > .k-item, .k-menu .k-item, .k-widget.k-menu-horizontal > .k-item {
  float: left;
  vertical-align: top;
  zoom: 1;
  box-sizing: content-box;
  border-style: solid;
  border-width: 0 1px 0 0;
  position: relative;
}

.k-column-menu-group-header, .k-menu-scroll-wrapper .k-menu-group .k-item > .k-link, .k-popups-wrapper.vertical > .k-item > .k-link, .k-context-menu.k-menu-vertical > .k-item > .k-link, .k-menu .k-menu-group .k-item > .k-link {
  padding: .28em 1.8em .38em .9em;
}

.k-popups-wrapper.horizontal > .k-separator, .k-context-menu.k-menu-horizontal > .k-separator {
  display: none;
}

.k-popups-wrapper.horizontal > .k-item, .k-context-menu.k-menu-horizontal > .k-item {
  box-sizing: border-box;
}

.k-popups-wrapper.horizontal > .k-last, .k-context-menu.k-menu-horizontal > .k-last {
  border: 0;
}

.k-popups-wrapper .k-item > .k-link > .k-menu-expand-arrow, .k-menu-horizontal > .k-item > .k-link > .k-menu-expand-arrow {
  margin: -2px 0 0;
}

.k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-down, .k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-down, .k-menu .k-item > .k-link > .k-i-arrow-60-down {
  margin-right: -8px;
}

.k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-right, .k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-right, .k-menu-group .k-item > .k-link > .k-menu-expand-arrow, .k-menu-vertical .k-item > .k-link > .k-menu-expand-arrow {
  margin-top: -8px;
  position: absolute;
  top: 50%;
  right: .2rem;
}

.k-menu-scroll-wrapper .k-animation-container, .k-popups-wrapper .k-animation-container, .k-menu .k-animation-container {
  border: 0;
}

.k-menu-scroll-wrapper .k-animation-container, .k-menu-scroll-wrapper .k-menu-group, .k-popups-wrapper .k-animation-container, .k-popups-wrapper .k-menu-group, .k-menu .k-animation-container, .k-menu .k-menu-group {
  position: absolute;
  left: 0;
}

.k-menu-scroll-wrapper .k-animation-container .k-animation-container, .k-menu-scroll-wrapper .k-menu-group .k-menu-group, .k-menu-scroll-wrapper.vertical .k-animation-container, .k-menu-scroll-wrapper.vertical .k-menu-group, .k-popups-wrapper .k-animation-container .k-animation-container, .k-popups-wrapper .k-menu-group .k-menu-group, .k-popups-wrapper.vertical .k-animation-container, .k-popups-wrapper.vertical .k-menu-group, .k-menu .k-animation-container .k-animation-container, .k-menu .k-menu-group .k-menu-group, .k-menu-vertical .k-animation-container, .k-menu-vertical .k-menu-group {
  top: 0;
  left: 0;
}

.k-menu-scroll-wrapper .k-animation-container .k-menu-group, .k-popups-wrapper .k-animation-container .k-menu-group, .k-menu .k-animation-container .k-menu-group {
  margin-left: -1px;
  top: auto;
  left: auto;
}

.k-menu .k-animation-container, .k-menu-scroll-wrapper .k-animation-container, .k-popups-wrapper .k-animation-container, .k-popup .k-animation-container {
  margin-top: -1px;
  padding-left: 1px;
}

.k-ie .k-menu .k-animation-container, .k-ie .k-menu-scroll-wrapper .k-animation-container, .k-ie .k-popups-wrapper .k-animation-container, .k-ie .k-popup .k-animation-container {
  margin-top: -2px;
}

.k-popup .k-animation-container .k-popup {
  margin-left: -1px;
}

.k-menu-scroll-wrapper .k-separator, .k-popups-wrapper .k-separator, ul.k-menu .k-separator {
  border-width: 0 1px 0 0;
  width: 1px;
  height: 100%;
  padding: .25em 0;
  font-size: 0;
  line-height: 0;
}

.k-menu-scroll-wrapper.vertical .k-separator, .k-menu-scroll-wrapper.vertical .k-menu-group .k-separator, .k-popups-wrapper.vertical .k-separator, .k-popups-wrapper.vertical .k-menu-group .k-separator, ul.k-menu-vertical .k-separator, .k-menu .k-menu-group .k-separator {
  border-width: 1px 0 0;
  width: 100%;
  height: 1px;
  padding: 0;
}

.k-popups-wrapper .k-widget.k-menu, .k-menu-scroll-wrapper .k-widget.k-menu {
  white-space: nowrap;
  overflow: hidden;
}

.k-popups-wrapper.vertical .k-menu.k-menu-vertical, .k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical {
  box-sizing: border-box;
  height: 100%;
}

.k-popups-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item, .k-menu-scroll-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item {
  float: none;
  display: inline-block;
  overflow: hidden;
}

.k-popups-wrapper.vertical .k-menu.k-menu-vertical > .k-item, .k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical > .k-item {
  overflow: hidden;
}

.k-popups-wrapper, .k-menu-scroll-wrapper {
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.k-popups-wrapper.vertical, .k-menu-scroll-wrapper.vertical {
  height: 100%;
}

.k-popups-wrapper > .k-animation-container .k-item, .k-menu-scroll-wrapper > .k-animation-container .k-item {
  float: none;
}

.k-popups-wrapper .k-menu-scroll-button, .k-menu-scroll-wrapper .k-menu-scroll-button {
  border-radius: 0;
  display: none;
  position: absolute;
}

.k-popups-wrapper .k-scroll-up, .k-menu-scroll-wrapper .k-scroll-up {
  top: 0;
  left: 0;
}

.k-popups-wrapper .k-scroll-down, .k-menu-scroll-wrapper .k-scroll-down {
  bottom: 0;
  left: 0;
}

.k-popups-wrapper .k-scroll-up, .k-menu-scroll-wrapper .k-scroll-up, .k-popups-wrapper .k-scroll-down, .k-menu-scroll-wrapper .k-scroll-down {
  width: 100%;
  height: 16px;
  padding: 0 .7em;
  line-height: .2em;
}

.k-popups-wrapper .k-scroll-left, .k-menu-scroll-wrapper .k-scroll-left {
  top: 0;
  left: 0;
}

.k-popups-wrapper .k-scroll-right, .k-menu-scroll-wrapper .k-scroll-right {
  top: 0;
  right: 0;
}

.k-popups-wrapper .k-scroll-left, .k-menu-scroll-wrapper .k-scroll-left, .k-popups-wrapper .k-scroll-right, .k-menu-scroll-wrapper .k-scroll-right {
  width: 16px;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.k-popups-wrapper .k-scroll-left .k-icon, .k-menu-scroll-wrapper .k-scroll-left .k-icon, .k-popups-wrapper .k-scroll-right .k-icon, .k-menu-scroll-wrapper .k-scroll-right .k-icon {
  margin-top: -.5em;
  position: absolute;
  top: 50%;
  left: 0;
}

.k-rtl .k-menu .k-item, [dir="rtl"] .k-menu .k-item, .k-rtl .k-widget.k-menu-horizontal > .k-item, [dir="rtl"] .k-widget.k-menu-horizontal > .k-item {
  border-width: 0 0 0 1px;
}

.k-rtl .k-menu-scroll-wrapper .k-item > .k-link > .k-icon, [dir="rtl"] .k-menu-scroll-wrapper .k-item > .k-link > .k-icon, .k-rtl .k-popups-wrapper .k-item > .k-link > .k-icon, [dir="rtl"] .k-popups-wrapper .k-item > .k-link > .k-icon, .k-rtl .k-menu .k-item > .k-link > .k-icon, [dir="rtl"] .k-menu .k-item > .k-link > .k-icon, .k-rtl .k-menu-scroll-wrapper .k-image, [dir="rtl"] .k-menu-scroll-wrapper .k-image, .k-rtl .k-popups-wrapper .k-image, [dir="rtl"] .k-popups-wrapper .k-image, .k-rtl .k-menu .k-image, [dir="rtl"] .k-menu .k-image, .k-rtl .k-menu-scroll-wrapper .k-sprite, [dir="rtl"] .k-menu-scroll-wrapper .k-sprite, .k-rtl .k-popups-wrapper .k-sprite, [dir="rtl"] .k-popups-wrapper .k-sprite, .k-rtl .k-menu .k-sprite, [dir="rtl"] .k-menu .k-sprite {
  margin-left: 4px;
  margin-right: -4px;
}

.k-rtl .k-menu-scroll-wrapper .k-menu-group > .k-item, [dir="rtl"] .k-menu-scroll-wrapper .k-menu-group > .k-item, .k-rtl .k-popups-wrapper .k-menu-group > .k-item, [dir="rtl"] .k-popups-wrapper .k-menu-group > .k-item, .k-rtl .k-menu .k-menu-group > .k-item, [dir="rtl"] .k-menu .k-menu-group > .k-item {
  border: 0;
}

.k-rtl .k-column-menu-group-header, [dir="rtl"] .k-column-menu-group-header, .k-rtl .k-menu-scroll-wrapper .k-menu-group .k-item > .k-link, [dir="rtl"] .k-menu-scroll-wrapper .k-menu-group .k-item > .k-link, .k-rtl .k-popups-wrapper.vertical > .k-item > .k-link, [dir="rtl"] .k-popups-wrapper.vertical > .k-item > .k-link, .k-rtl .k-context-menu.k-menu-vertical > .k-item > .k-link, [dir="rtl"] .k-context-menu.k-menu-vertical > .k-item > .k-link, .k-rtl .k-menu .k-menu-group .k-item > .k-link, [dir="rtl"] .k-menu .k-menu-group .k-item > .k-link {
  padding: .28em .9em .38em 1.8em;
}

.k-rtl .k-menu-vertical > .k-menu-item > .k-menu-link, [dir="rtl"] .k-menu-vertical > .k-menu-item > .k-menu-link {
  padding: .5em 1.1em .4em 2em;
}

.k-context-menu {
  -webkit-user-select: none;
  user-select: none;
  border: 0;
}

.k-listview {
  box-sizing: border-box;
  font-size: inherit;
  border-style: solid;
  border-width: 0;
  outline: 0;
  flex-flow: column;
  margin: 0;
  padding: 0;
  line-height: 1.42857;
  position: relative;
}

.k-listview-bordered {
  border-width: 1px;
}

.k-listview-header {
  border-style: solid;
  border-width: 0;
  border-color: inherit;
}

.k-listview-bordered > .k-listview-header {
  border-bottom-width: 1px;
}

.k-listview-footer {
  border-style: solid;
  border-width: 0;
  border-color: inherit;
}

.k-listview-bordered > .k-listview-footer {
  border-top-width: 1px;
}

.k-listview-content {
  border-color: inherit;
  flex: auto;
  align-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: auto;
}

.k-listview-content:after {
  clear: both;
  height: 0;
  display: block;
}

.k-listview-content.k-d-flex:after, .k-listview-content.k-d-grid:after {
  display: none;
}

.k-listview-content-padding-rectangle {
  padding: 4px 8px;
}

.k-listview-content-padding-square {
  padding: 4px;
}

.k-listview-item {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  flex-shrink: 0;
  padding: 0;
}

.k-listview-item-padding-rectangle {
  padding: 4px 8px;
}

.k-listview-item-padding-rectangle .k-listview-link {
  margin: -4px -8px;
  padding: 4px 8px;
}

.k-listview-item-padding-square {
  padding: 4px;
}

.k-listview-item-padding-square .k-listview-link {
  margin: -4px;
  padding: 4px;
}

.k-listview-borders-all .k-listview-item {
  border-width: 1px;
}

.k-listview-borders-horizontal .k-listview-item {
  border-top-width: 1px;
}

.k-listview-borders-horizontal .k-listview-item:first-child {
  border-top-width: 0;
}

.k-listview-borders-vertical .k-listview-item {
  border-left-width: 1px;
}

.k-listview-borders-vertical .k-listview-item:first-child {
  border-left-width: 0;
}

.k-listview-pager {
  border-width: 0;
  border-color: inherit;
  font: inherit;
}

.k-listview-bordered > .k-listview-pager-top {
  border-bottom-width: 1px;
}

.k-listview-bordered > .k-listview-pager-bottom {
  border-top-width: 1px;
}

.k-grid {
  position: relative;
}

.k-grid table {
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  border-width: 0;
  outline: none;
  width: 100%;
  max-width: none;
  margin: 0;
}

.k-grid-header th.k-header, .k-filter-row th {
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  border-style: solid;
  border-width: 0 0 1px 1px;
  padding: .5em .6em .4em;
  font-weight: normal;
  overflow: hidden;
}

.k-grid-header th.k-header {
  vertical-align: bottom;
  cursor: default;
}

.k-grid-header th.k-header .k-checkbox {
  vertical-align: top;
}

.k-filtercell, .k-filtercell > span, .k-filtercell .k-widget {
  width: auto;
  display: block;
}

.k-filtercell > span {
  min-height: 2em;
  padding-right: 4.8em;
  line-height: 2em;
  position: relative;
}

.k-filtercell > .k-operator-hidden {
  padding-right: 2.3em;
}

.k-filtercell > span > .k-button, .k-filter-row .k-dropdown-operator {
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.k-filter-row .k-dropdown-operator {
  width: 2.1em;
  right: 2.5em;
}

.k-filtercell > span > label {
  vertical-align: middle;
}

.k-filter-row label > input[type="radio"] {
  vertical-align: middle;
  position: relative;
  bottom: 2px;
}

.k-ff .k-grid .k-filter-row .k-i-filter-clear {
  top: 1px;
}

.k-ie10 .k-grid-header a:active {
  background-color: #0000;
}

.k-grid-header th.k-header > .k-link {
  text-overflow: ellipsis;
  min-height: 18px;
  margin: -.5em -.6em -.4em 0;
  padding: .5em .6em .4em 0;
  line-height: 18px;
  display: block;
  overflow: hidden;
}

.k-grid-header th.k-with-icon .k-link {
  margin-right: 18px;
}

.k-grid-header th > .k-link > .k-icon {
  vertical-align: text-top;
}

.k-grid .k-state-hover {
  cursor: pointer;
}

.k-grid-column-resizing, .k-grid-column-resizing .k-grid-filter, .k-grid-column-resizing .k-link, .k-grid-column-resizing .k-button, .k-grid-column-resizing .k-grid-toolbar {
  cursor: col-resize;
}

.k-grid td {
  vertical-align: middle;
  text-overflow: ellipsis;
  border-style: solid;
  border-width: 0 0 0 1px;
  padding: .4em .6em;
  line-height: 1.6em;
  overflow: hidden;
}

.k-grid .k-grouping-row td, .k-grid .k-hierarchy-cell {
  overflow: visible;
}

.k-grid-edit-row td {
  text-overflow: clip;
}

.k-grid-edit-row .k-textbox, .k-grid-edit-row .text-box {
  margin-top: 0;
  margin-bottom: 0;
}

.k-grid-header-wrap, .k-grid-footer-wrap {
  zoom: 1;
  border-style: solid;
  border-width: 0 1px 0 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}

div.k-grid-header, div.k-grid-footer {
  zoom: 1;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-right: 17px;
}

.k-grid-header-wrap > table, .k-grid-header-locked > table {
  margin-bottom: -1px;
}

.k-grid-content {
  zoom: 1;
  width: 100%;
  min-height: 0%;
  position: relative;
  overflow: auto scroll;
}

.k-mobile .k-grid tbody {
  -webkit-backface-visibility: hidden;
}

.k-mobile .k-grid-backface tbody {
  -webkit-backface-visibility: visible;
}

.k-grid-content-expander {
  visibility: hidden;
  height: 1px;
  position: absolute;
}

.k-grid-norecords {
  text-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.k-grid-norecords-template {
  vertical-align: middle;
  width: 20em;
  height: 4em;
  margin: 0 auto;
  line-height: 4em;
}

.k-grid-content > .k-grid-norecords > .k-grid-norecords-template {
  margin-top: -2em;
  margin-left: -10em;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media print {
  .k-grid {
    height: auto !important;
  }

  .k-grid-header {
    padding: 0 !important;
  }

  .k-grid-header-wrap, .k-grid-content {
    overflow: visible;
    height: auto !important;
  }
}

.k-grid .k-scrollbar {
  -ms-overflow-style: scrollbar;
}

.k-virtual-scrollable-wrap {
  height: 100%;
  position: relative;
  overflow-y: hidden;
}

.k-grid-header table, .k-grid-content table, .k-grid-footer table, .k-grid-content-locked > table {
  table-layout: fixed;
}

.k-grid-lockedcolumns {
  white-space: nowrap;
}

.k-grid-content-locked, .k-grid-content, .k-pager-wrap {
  white-space: normal;
}

.k-grid-header-locked, .k-grid-content-locked, .k-grid-footer-locked {
  vertical-align: top;
  border-style: solid;
  border-width: 0 1px 0 0;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.k-grid-header-locked + .k-grid-header-wrap, .k-grid-content-locked + .k-grid-content, .k-grid-footer-locked + .k-grid-footer-wrap {
  vertical-align: top;
  display: inline-block;
}

.k-grid-toolbar {
  border-width: 0 0 1px;
  border-color: inherit;
  flex-shrink: 0;
}

.k-grid-header th.k-header:first-child, .k-grid tbody td:first-child, .k-grid tfoot td:first-child, .k-filter-row > th:first-child {
  border-left-width: 0;
}

.k-grid-header th.k-header.k-first {
  border-left-width: 1px;
}

.k-footer-template td {
  border-style: solid;
  border-width: 1px 0 0 1px;
}

.k-group-footer td {
  border-style: solid;
  border-width: 1px 0;
}

.k-group-footer .k-group-cell + td {
  border-left-width: 1px;
}

.k-grid-footer {
  border-style: solid;
  border-width: 1px 0 0;
}

.k-grid-footer td {
  border-top-width: 0;
}

.k-grid-footer > td {
  border-top-width: 1px;
}

.k-grid-pager {
  border-width: 1px 0 0;
}

.k-grid-pager-top {
  border-width: 0 0 1px;
}

.k-header > .k-grid-filter, .k-header > .k-header-column-menu {
  float: right;
  z-index: 1;
  color: inherit;
  margin: -.5em -.6em -.4em 0;
  padding: .5em .2em .4em;
  position: relative;
}

.k-grid .k-animation-container {
  position: absolute;
}

.k-filter-menu {
  padding: .5em;
}

.k-list-filter, form.k-filter-menu .k-widget, form.k-filter-menu .k-textbox {
  display: block;
}

form.k-filter-menu .k-textbox {
  width: 100%;
  margin-bottom: 3px;
}

.k-column-menu, .k-filter-menu {
  font-size: 14px;
}

.k-filter-help-text, .k-filter-menu .k-widget, .k-filter-menu .k-textbox {
  margin: .19em 0 0;
}

.k-filter-menu span.k-filter-and {
  width: 6em;
  margin: .5em 0;
}

.k-filter-menu .k-action-buttons {
  text-align: initial;
  border-width: 0;
  padding: 0;
}

.k-filter-menu .k-action-buttons .k-button {
  width: 48%;
  min-width: 0;
  margin: .5em 4% 0 0;
}

.k-filter-menu .k-action-buttons .k-button + .k-button {
  margin-right: 0;
}

.k-filter-menu .k-filter-selected-items {
  margin: .5em;
  font-weight: bold;
}

.k-multicheck-wrap {
  white-space: nowrap;
  max-height: 300px;
  overflow: auto;
}

.k-multicheck-wrap .k-item {
  line-height: 2.2em;
}

.k-grouping-row .k-icon {
  margin: -3px 4px 0 2px;
}

.k-grouping-row p {
  vertical-align: middle;
  margin-left: -.6em;
  padding: 0 .6em;
  display: inline-block;
}

.k-grouping-row + tr td {
  border-top-width: 1px;
}

.k-grouping-row .k-group-cell, .k-grouping-row + tr .k-group-cell {
  text-overflow: clip;
  border-top-width: 0;
}

.k-grid .k-hierarchy-cell + td {
  border-left-width: 0;
}

.k-grid .k-group-col, .k-grid .k-hierarchy-col {
  width: 27px;
}

.k-grouping-header {
  white-space: normal;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  line-height: 2;
  position: relative;
}

.k-grouping-dropclue {
  background-position: -165px -148px;
  background-repeat: no-repeat;
  width: 6px;
  height: 25px;
  position: absolute;
  top: 3px;
}

.k-grouping-dropclue:before, .k-grouping-dropclue:after {
  content: "";
  border: 3px solid;
  border-color: currentColor #0000;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
}

.k-grouping-dropclue:before {
  border-bottom: 0;
  top: 0;
}

.k-grouping-dropclue:after {
  border-top: 0;
  bottom: 0;
}

.k-group-indicator {
  vertical-align: top;
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: .15em .15em .15em .4em;
  line-height: 1.5em;
  display: inline-flex;
}

.k-grouping-header .k-group-indicator {
  margin: 0 3px;
}

.k-group-indicator .k-link, .k-group-indicator .k-button.k-flat {
  border-width: 0;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.k-group-indicator .k-link .k-icon {
  margin-right: 4px;
}

.k-group-indicator .k-button.k-flat {
  opacity: .5;
  width: auto;
  height: auto;
  margin-left: 8px;
  padding: 0;
}

.k-group-indicator .k-button.k-flat:before, .k-group-indicator .k-button.k-flat:after {
  display: none;
}

.k-group-indicator .k-button.k-flat:hover {
  opacity: 1;
}

.k-no-flexbox .k-group-indicator {
  display: inline-block;
}

.k-no-flexbox .k-group-indicator .k-link, .k-no-flexbox .k-group-indicator .k-button {
  vertical-align: middle;
  display: inline-block;
}

.k-dirty-cell:before {
  content: " ";
  float: left;
  width: 0;
  display: inline-block;
}

.k-dirty-cell {
  position: relative;
}

.k-dirty-cell.k-edit-cell {
  position: static;
}

.k-dirty-cell .k-dirty {
  margin: 0;
  top: 0;
  left: 0;
}

.k-dirty {
  vertical-align: top;
  border: 3px solid #0000;
  border-color: red #0000 #0000 red;
  width: 0;
  height: 0;
  margin: -.45em 0 0 -.6em;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.k-grouping-header {
  cursor: default;
  margin: 0;
  padding: .22em .2em .28em;
}

.k-grid .k-edit-container {
  padding: 0;
}

.k-grid .field-validation-error {
  display: block;
}

.k-grid .input-validation-error {
  background-color: pink;
  border-style: ridge;
  border-color: red;
}

.k-grid-actions {
  display: inline-block;
}

.k-grid .k-button {
  margin: .16em;
}

.k-grid tbody .k-button {
  min-width: 64px;
}

.k-grid tbody button.k-button {
  min-width: 78px;
}

.k-grid tbody .k-button.k-button-icon {
  width: auto;
  min-width: 0;
}

.k-detail-row {
  position: relative;
}

.k-grid .k-detail-cell {
  overflow: visible;
}

.k-grid .k-edit-cell {
  white-space: nowrap;
  padding: 0 .3em;
}

.k-edit-cell > .k-textbox, .k-grid-edit-row > td > .k-textbox, .k-edit-cell > .k-widget:not(.k-switch), .k-grid-edit-row > td > .k-widget:not(.k-switch), .k-edit-cell > .text-box, .k-grid-edit-row > td > .text-box {
  width: 100%;
}

.k-edit-cell input[type="checkbox"] {
  margin-left: .6em;
}

.k-grid-resize-indicator {
  background-color: #aaa;
  width: 2px;
  position: absolute;
}

.k-grid-header .k-resize-handle, .k-grid > .k-resize-handle {
  cursor: col-resize;
  z-index: 2;
  height: 25px;
  position: absolute;
}

.k-marquee {
  z-index: 100000;
  position: absolute;
}

.k-marquee-color, .k-marquee-text {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.k-marquee-color {
  opacity: .6;
}

.k-ie9 .k-column-menu {
  width: 160px;
}

.k-column-menu {
  min-width: 160px;
}

.k-column-menu .k-sprite {
  margin-right: 10px;
}

.k-column-menu > .k-menu {
  border-width: 0;
}

.k-column-menu .k-calendar .k-link {
  white-space: normal;
}

.k-columns-item .k-group {
  max-height: 200px;
  overflow: auto;
}

.k-filter-menu.k-popup, .k-grid-filter-popup.k-popup {
  padding: 2px 0;
}

.k-filter-menu.k-popup .k-filter-menu-container, .k-grid-filter-popup.k-popup .k-filter-menu-container {
  width: 230px;
}

.k-filter-menu.k-popup .k-multicheck-wrap, .k-grid-filter-popup.k-popup .k-multicheck-wrap {
  padding: 0 3px;
}

.k-filter-menu.k-popup .k-multicheck-wrap .k-item, .k-grid-filter-popup.k-popup .k-multicheck-wrap .k-item {
  padding: 2px 0;
}

.k-filter-menu.k-popup .k-multicheck-wrap .k-check-all-wrap, .k-grid-filter-popup.k-popup .k-multicheck-wrap .k-check-all-wrap {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding-bottom: 4px;
}

.k-filter-menu .k-filter-menu-container {
  box-sizing: border-box;
}

.k-filter-menu.k-popup .k-filter-menu-container {
  padding: 4px 8px;
}

.k-filter-menu .k-searchbox {
  width: 100%;
  margin-bottom: 8px;
}

.k-grid-columnmenu-popup {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  align-items: stretch;
  width: 230px;
  margin: 0;
  font-size: 14px;
  line-height: 1.42857;
  display: flex;
}

.k-grid-columnmenu-popup.k-popup {
  padding: 2px 0;
}

.k-grid-columnmenu-popup .k-filter-menu {
  border-radius: 0;
  padding: 0;
}

.k-grid-columnmenu-popup .k-filter-menu .k-filter-menu-container {
  padding: 2px 0;
}

.k-grid-columnmenu-popup .k-filter-menu .k-widget, .k-grid-columnmenu-popup .k-filter-menu .k-radio-list, .k-grid-columnmenu-popup .k-filter-menu .k-textbox {
  width: 100%;
  margin: .5em 0;
  display: block;
}

.k-grid-columnmenu-popup .k-filter-menu .k-widget.k-filter-and {
  width: 6em;
  margin: 1em 0;
}

.k-grid-columnmenu-popup .k-filter-menu .k-widget.k-button-group {
  width: auto;
  margin: 0;
  display: inline-flex;
}

.k-grid-columnmenu-popup .k-filter-menu .k-actions, .k-grid-columnmenu-popup .k-filter-menu .k-action-buttons, .k-grid-columnmenu-popup .k-filter-menu .k-columnmenu-actions {
  margin-bottom: -1em;
  margin-left: -1em;
  margin-right: -1em;
  padding: 0 1em 1em;
}

.k-grid-columnmenu-popup .k-filter-menu .k-actions .k-button, .k-grid-columnmenu-popup .k-filter-menu .k-action-buttons .k-button, .k-grid-columnmenu-popup .k-filter-menu .k-columnmenu-actions .k-button {
  flex: 50%;
}

.k-grid-columnmenu-popup .k-filter-menu .k-actions.k-actions-start .k-button, .k-grid-columnmenu-popup .k-filter-menu .k-actions.k-actions-center .k-button, .k-grid-columnmenu-popup .k-filter-menu .k-actions.k-actions-end .k-button {
  flex: 0 auto;
}

.k-grid-columnmenu-popup .k-multicheck-wrap {
  white-space: nowrap;
  max-height: 300px;
  overflow: hidden auto;
}

.k-grid-columnmenu-popup .k-filter-selected-items {
  white-space: nowrap;
  margin: 1em 0 .5em;
  font-weight: bold;
  line-height: normal;
}

.k-column-list {
  max-height: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden auto;
}

.k-column-chooser-title, .k-column-list-item {
  padding: 2px 8px;
}

.k-column-list-item {
  cursor: pointer;
  margin: 0;
  display: block;
  position: relative;
}

.k-columnmenu-item {
  cursor: pointer;
  outline: 0;
  padding: 4px 8px;
}

.k-columnmenu-item > .k-icon {
  margin-right: 4px;
}

.k-columnmenu-item-wrapper + .k-columnmenu-item-wrapper {
  border-top-style: solid;
  border-top-width: 1px;
}

.k-columnmenu-item-content {
  overflow: hidden;
}

.k-columnmenu-item-content .k-filter-menu .k-filter-menu-container {
  padding: 4px 8px;
}

.k-columnmenu-actions {
  clear: both;
  flex-flow: row;
  place-content: flex-end;
  align-items: center;
  padding: 4px 8px;
  display: flex;
}

.k-columnmenu-actions .k-button + .k-button {
  flex-shrink: 0;
  margin-left: 8px;
}

.k-rtl .k-columnmenu-actions .k-button + .k-button, [dir="rtl"] .k-columnmenu-actions .k-button + .k-button {
  margin-left: 0;
  margin-right: 8px;
}

.k-column-list-wrapper .k-actions .k-button, .k-columnmenu-item-content .k-actions .k-button, .k-column-list-wrapper .k-action-buttons .k-button, .k-columnmenu-item-content .k-action-buttons .k-button, .k-column-list-wrapper .k-columnmenu-actions .k-button, .k-columnmenu-item-content .k-columnmenu-actions .k-button {
  flex: 50%;
}

.k-column-list-wrapper .k-actions.k-actions-start .k-button, .k-columnmenu-item-content .k-actions.k-actions-start .k-button, .k-column-list-wrapper .k-actions.k-actions-center .k-button, .k-columnmenu-item-content .k-actions.k-actions-center .k-button, .k-column-list-wrapper .k-actions.k-actions-end .k-button, .k-columnmenu-item-content .k-actions.k-actions-end .k-button {
  flex: 0 auto;
}

.k-column-menu .k-menu:not(.k-context-menu) {
  font-weight: 400;
}

.k-column-menu .k-expander {
  background: inherit;
  border: 0;
}

.k-column-menu .k-expander .k-columnmenu-item {
  align-items: center;
  display: flex;
}

[dir="rtl"] .k-columnmenu-item > .k-icon, .k-rtl .k-columnmenu-item > .k-icon {
  margin-left: 4px;
  margin-right: 0;
}

.k-column-menu-group-header {
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  display: flex;
  position: relative;
}

.k-column-menu-group-header-text {
  text-transform: uppercase;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  flex: auto;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.42857;
}

.k-pdf-export-shadow .k-grid {
  float: left;
  width: auto !important;
}

.k-pdf-export-shadow .k-grid-content.k-auto-scrollable {
  padding-right: 0 !important;
}

.k-pdf-export-shadow .k-grid, .k-pdf-export-shadow .k-grid-content, .k-pdf-export-shadow .k-grid-content-locked {
  overflow: visible;
  height: auto !important;
}

.k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap, .k-pdf-export-shadow .k-grid-content-locked + .k-grid-content, .k-pdf-export-shadow .k-grid-footer-locked + .k-grid-footer-wrap {
  width: auto !important;
}

.k-pdf-export-shadow .k-grid-header, .k-pdf-export-shadow .k-grid[data-role="grid"] .k-grid-footer {
  padding: 0 !important;
}

.k-loading-pdf-mask {
  z-index: 100;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.k-loading-pdf-mask .k-loading-color {
  opacity: .5;
}

.k-loading-pdf-mask .k-loading-pdf-progress {
  margin: auto;
  position: absolute;
  inset: 0;
}

.k-pdf-export .k-loading-pdf-mask {
  display: none;
}

.k-pdf-export .k-grid-filter {
  text-decoration: none;
}

.k-autofitting {
  table-layout: auto !important;
  width: auto !important;
}

.k-autofitting th.k-header, .k-autofitting td {
  white-space: nowrap !important;
}

.k-autofitting .k-detail-row {
  display: none !important;
}

.k-grid .k-grouping-row td {
  border-bottom-width: 0;
}

.k-grid .k-grouping-row + tr td {
  border-top-width: 1px;
}

.k-grid .k-grouping-row + tr td.k-group-cell {
  border-top-width: 0;
}

.k-grid .k-group-cell {
  border-top-width: 0;
  border-bottom-width: 0;
}

.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm {
  margin-left: 7px;
}

.k-grid-header .k-sort-order {
  vertical-align: text-top;
  height: 16px;
  margin-top: 1px;
  margin-left: -1px;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
}

.k-grid-header .k-link .k-icon.k-i-sort-asc-sm, .k-grid-header .k-link .k-icon.k-i-sort-desc-sm {
  vertical-align: text-top;
  margin-top: 1px;
}

.k-grid .k-grid-search {
  width: 12.4em;
  display: flex;
}

.k-grid .k-grid-header .k-header.k-grid-header-sticky, .k-grid .k-grid-header .k-filter-row .k-grid-header-sticky, .k-grid .k-grid-content-sticky, .k-grid .k-grid-footer-sticky {
  z-index: 2;
  position: sticky;
}

.k-grid .k-grid-header .k-header.k-grid-header-sticky.k-edit-cell, .k-grid .k-grid-header .k-filter-row .k-grid-header-sticky.k-edit-cell, .k-grid .k-grid-content-sticky.k-edit-cell, .k-grid .k-grid-footer-sticky.k-edit-cell {
  z-index: 3;
  overflow: visible;
}

.k-grid .k-hierarchy-cell + td.k-grid-content-sticky {
  border-left-width: 1px;
}

.k-grid th.k-grid-header-sticky, .k-grid td.k-grid-content-sticky, .k-grid td.k-grid-footer-sticky {
  border-right-width: 1px;
}

.k-grid th.k-grid-header-sticky:not([style*="display: none"]) + td, .k-grid td.k-grid-content-sticky:not([style*="display: none"]) + td, .k-grid td.k-grid-footer-sticky:not([style*="display: none"]) + td, .k-grid th.k-grid-header-sticky:not([style*="display: none"]) + th, .k-grid td.k-grid-content-sticky:not([style*="display: none"]) + th, .k-grid td.k-grid-footer-sticky:not([style*="display: none"]) + th, .k-grid .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
  border-left-width: 0;
}

.k-grid[dir="rtl"] td.k-grid-content-sticky, .k-rtl .k-grid td.k-grid-content-sticky, .k-grid[dir="rtl"] td.k-grid-footer-sticky, .k-rtl .k-grid td.k-grid-footer-sticky, .k-grid[dir="rtl"] .k-grid-header th.k-grid-header-sticky, .k-rtl .k-grid .k-grid-header th.k-grid-header-sticky, .k-grid[dir="rtl"] th.k-grid-header-sticky:last-child, .k-rtl .k-grid th.k-grid-header-sticky:last-child {
  border-left-width: 1px;
}

.k-grid[dir="rtl"] th.k-grid-header-sticky:not([style*="display: none"]) + td, .k-rtl .k-grid th.k-grid-header-sticky:not([style*="display: none"]) + td, .k-grid[dir="rtl"] th.k-grid-header-sticky:not([style*="display: none"]) + th, .k-rtl .k-grid th.k-grid-header-sticky:not([style*="display: none"]) + th, .k-grid[dir="rtl"] td.k-grid-content-sticky:not([style*="display: none"]) + td, .k-rtl .k-grid td.k-grid-content-sticky:not([style*="display: none"]) + td, .k-grid[dir="rtl"] td.k-grid-content-sticky:not([style*="display: none"]) + th, .k-rtl .k-grid td.k-grid-content-sticky:not([style*="display: none"]) + th {
  border-left-width: 1px;
  border-right-width: 0;
}

.k-grid[dir="rtl"] .k-grid-header-sticky.k-header.k-first, .k-rtl .k-grid .k-grid-header-sticky.k-header.k-first, .k-grid[dir="rtl"] .k-grid-header-sticky.k-header:first-child, .k-rtl .k-grid .k-grid-header-sticky.k-header:first-child {
  border-left-width: 1px;
}

.k-grid[dir="rtl"] .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first, .k-rtl .k-grid .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
  border-left-width: 1px;
  border-right-width: 0;
}

.k-treelist .k-status {
  padding: .4em .6em;
  line-height: 1.6em;
}

.k-treelist .k-status .k-loading {
  vertical-align: baseline;
  margin-right: 5px;
}

.k-treelist tr.k-hidden {
  display: none;
}

.k-treelist.k-treelist-dragging, .k-treelist.k-treelist-dragging .k-state-hover {
  cursor: default;
}

.k-treelist .k-drop-hint {
  transform: translate(0, -50%);
}

.k-treelist .k-i-arrow-60-right, .k-treelist .k-i-arrow-45-down-right {
  cursor: pointer;
}

.k-treelist .k-i-arrow-45-down-right {
  margin-top: -.5em;
}

.k-drag-separator {
  vertical-align: top;
  border-right: 1px solid;
  height: 1em;
  margin: 0 .5em;
  display: inline-block;
}

.k-gantt {
  box-sizing: border-box;
  white-space: nowrap;
  flex-flow: column;
  display: flex;
  position: relative;
}

.k-gantt .k-splitbar {
  cursor: e-resize;
  vertical-align: top;
  background-repeat: repeat-y;
  border-width: 0 1px;
  flex-shrink: 0;
  width: 5px;
  display: inline-block;
  position: relative;
}

.k-gantt-header {
  z-index: 2;
  border-width: 0 0 1px;
  flex: none;
}

.k-gantt-footer {
  border-width: 1px 0 0;
  flex: none;
}

.k-gantt-toggle {
  display: none;
}

.k-gantt-views-wrapper select.k-dropdown {
  font: inherit;
  cursor: pointer;
  width: auto;
  display: none;
}

@media (max-width: 1024px) {
  .k-gantt-views-wrapper select.k-dropdown {
    display: inline-flex;
  }

  .k-gantt-views-wrapper .k-gantt-views {
    display: none;
  }
}

.k-gantt-content {
  flex-direction: row;
  flex: auto;
  align-items: stretch;
  display: flex;
}

.k-gantt .k-gantt-content th, .k-gantt .k-gantt-content .k-grid-header td {
  vertical-align: bottom;
}

.k-gantt td {
  white-space: nowrap;
  vertical-align: top;
  overflow: hidden;
}

.k-gantt .k-grid .k-edit-cell {
  vertical-align: middle;
}

.k-gantt-treelist > .k-treelist, .k-gantt-timeline > .k-timeline {
  border-width: 0;
  height: 100%;
}

.k-gantt-treelist {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.k-gantt-treelist .k-grid-header tr {
  height: 5em;
}

.k-gantt .k-treelist .k-grid-header {
  padding: 0 !important;
}

.k-gantt .k-treelist .k-grid-content {
  overflow: scroll hidden;
}

.k-treelist-group > tr > span {
  font-weight: bold;
}

.k-treelist-group .k-widget {
  font-weight: normal;
}

.k-gantt-timeline {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.k-gantt-timeline .k-grid-header tr, .k-gantt-treelist .k-gantt-treelist-nested-columns .k-grid-header tr {
  height: 2.5em;
}

.k-gantt-timeline .k-grid-header .k-header {
  vertical-align: bottom;
  cursor: default;
}

.k-gantt-tasks {
  position: relative;
}

.k-gantt-rows tr, .k-gantt-tasks tr, .k-gantt .k-grid-content tr {
  height: 2.3em;
}

.k-gantt .k-gantt-tasks td:after {
  content: " ";
}

.k-gantt-timeline {
  background: none;
}

.k-gantt-rows, .k-gantt-columns, .k-gantt-dependencies {
  position: absolute;
  top: 0;
  left: 0;
}

.k-gantt-tables {
  position: relative;
}

.k-gantt .k-timeline .k-grid-content {
  overflow-x: scroll;
}

.k-gantt .k-gantt-timeline th, .k-gantt-timeline .k-grid-header td {
  text-align: center;
}

.k-gantt .k-gantt-timeline tr:first-child th, .k-gantt .k-gantt-timeline .k-grid-header tr:first-child td {
  border-bottom-width: 1px;
}

.k-gantt-timeline td.k-header {
  white-space: nowrap;
  text-overflow: ellipsis;
  border-style: solid;
  border-width: 0 0 1px 1px;
  padding: .5em .6em .4em;
  font-weight: 400;
  overflow: hidden;
}

.k-task-summary {
  vertical-align: top;
  height: 10px;
  margin-top: 3px;
  display: inline-block;
}

.k-task-summary-complete {
  z-index: 2;
  height: 10px;
  position: relative;
}

.k-task-summary-progress {
  height: 15px;
  overflow: hidden;
}

.k-task-summary:before, .k-task-summary-complete:before, .k-task-summary:after, .k-task-summary-complete:after {
  content: "";
  border: 8px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.k-task-summary:before, .k-task-summary-complete:before {
  border-left-color: inherit;
  left: 0;
}

.k-task-summary:after, .k-task-summary-complete:after {
  border-right-color: inherit;
  right: 0;
}

.k-gantt-line-h, .k-gantt-line-v {
  position: absolute;
}

.k-gantt-line-h {
  height: 2px;
}

.k-gantt-line-v {
  width: 2px;
}

.k-arrow-e, .k-arrow-w {
  border-style: solid;
  border-width: 5px;
  width: 0;
  height: 0;
  position: absolute;
  top: -4px;
}

.k-arrow-e {
  border-top-color: #0000;
  border-bottom-color: #0000;
  border-right-color: #0000;
  right: -6px;
}

.k-arrow-w {
  border-top-color: #0000;
  border-bottom-color: #0000;
  border-left-color: #0000;
  left: -6px;
}

.k-task-milestone {
  border-style: solid;
  border-width: 1px;
  width: 13px;
  height: 13px;
  margin-top: 3px;
  margin-left: -.2em;
  transform: rotate(45deg);
}

.k-gantt .k-gantt-treelist .k-button, .k-gantt .k-gantt-tasks .k-button.k-button-icon {
  padding-top: 0;
  padding-bottom: 0;
}

.k-gantt .k-gantt-tasks .k-button.k-button-icon {
  margin-top: 4px;
}

.k-gantt .k-gantt-treelist .k-button {
  margin-top: -4px;
  margin-bottom: -2px;
}

.k-gantt .k-gantt-tasks .k-button.k-button-icon {
  padding-left: 2px;
  padding-right: 2px;
}

.k-gantt .k-gantt-treelist .k-button .k-icon, .k-gantt .k-gantt-tasks .k-button .k-icon {
  vertical-align: text-top;
}

.k-rel .k-button-icon {
  position: absolute;
  left: 200px;
}

.k-rel {
  height: 0;
  position: relative;
  top: -.3em;
}

.k-task-wrap {
  z-index: 2;
  margin: -1px -23px 0;
  padding: 0 23px 5px;
  display: inline;
  position: absolute;
}

.k-task-wrap:hover, .k-gantt-line.k-state-selected {
  z-index: 3;
}

.k-milestone-wrap {
  margin: 0 -13px 0 -27px;
}

.k-task-content {
  z-index: 2;
  position: relative;
}

.k-task-complete {
  z-index: 1;
  width: 20%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.k-task-dot {
  cursor: pointer;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: none;
  position: absolute;
  top: 0;
}

.k-task-dot.k-state-hover {
  background-color: #0000;
}

.k-task-single + .k-task-dot, .k-task-single + .k-task-dot + .k-task-dot {
  top: .2em;
}

.k-task-wrap:hover .k-task-dot, .k-task-wrap-active .k-task-dot {
  display: block;
}

.k-task-dot:before {
  content: " ";
  width: 0;
  height: 16px;
  display: inline-block;
}

.k-task-dot:after {
  content: "";
  vertical-align: middle;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  margin-left: 4px;
  display: inline-block;
}

.k-task-dot:hover:after, .k-task-dot.k-state-hover:after, .k-task-wrap-active .k-task-dot:after {
  border-style: solid;
  border-width: 1px;
  margin-left: 3px;
}

.k-task-start {
  left: 0;
}

.k-task-end {
  right: 0;
}

.k-task-single {
  text-align: left;
  cursor: default;
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  min-height: 1.3em;
  overflow: hidden;
}

.k-task-template {
  padding: .2em 1.4em .2em .6em;
  line-height: normal;
}

.k-task-actions, .k-task-content > .k-link {
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 4px;
}

.k-task-actions {
  z-index: 1;
}

.k-task-actions:first-child {
  float: left;
  margin: 4px 2px 0 4px;
  position: static;
}

.k-webkit .k-task-actions:first-child {
  margin-top: 3px;
}

.k-task-actions:first-child > .k-link {
  display: inline-block;
}

.k-task-delete {
  display: none;
}

.k-task-wrap:hover .k-task-delete, .k-task-wrap-active .k-task-delete {
  display: inline-block;
}

.k-task-single .k-resize-handle {
  visibility: hidden;
  z-index: 2;
  height: auto;
  position: absolute;
}

.k-task-single:hover .k-resize-handle, .k-task-wrap-active .k-resize-handle {
  visibility: visible;
}

.k-task-single .k-resize-handle:after {
  content: "";
  opacity: .5;
  position: absolute;
}

.k-task-content > .k-resize-e {
  width: .4em;
  top: 0;
  bottom: 0;
  right: 0;
}

.k-task-content > .k-resize-w {
  width: .4em;
  top: 0;
  bottom: 0;
  left: 0;
}

.k-task-content > .k-resize-e:after, .k-task-content > .k-resize-w:after {
  width: 1px;
  height: 1.4em;
  margin-top: -.7em;
  top: 50%;
  left: 1px;
}

.k-task-content > .k-resize-e:after {
  left: auto;
  right: 1px;
}

.k-task-draghandle {
  cursor: e-resize;
  border-style: solid;
  border-width: 5px;
  border-top-color: #0000;
  border-left-color: #0000;
  border-right-color: #0000;
  width: 0;
  height: 0;
  margin: 0 18px;
  display: none;
  position: absolute;
  bottom: 0;
}

.k-task-wrap:hover .k-task-draghandle, .k-task-wrap-active .k-task-draghandle {
  display: block;
}

.k-gantt-dependency-hint {
  z-index: 4;
}

.k-gantt-rowheight .k-task-summary, .k-gantt-rowheight .k-task-milestone, .k-gantt-rowheight .k-task-dot, .k-gantt-rowheight .k-task-single + .k-task-dot, .k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot {
  top: 50%;
}

.k-gantt-rowheight .k-task-summary, .k-gantt-rowheight .k-task-milestone {
  margin-top: -6px;
}

.k-gantt-rowheight .k-task-dot, .k-gantt-rowheight .k-task-single + .k-task-dot, .k-gantt-rowheight .k-task-single + .k-task-dot + .k-task-dot {
  margin-top: -11px;
}

.k-gantt-rowheight .k-task-single {
  height: calc(100% - 2px);
}

.k-ie .k-gantt-rowheight .k-task-single {
  height: 99%;
}

.k-gantt-rowheight .k-task-content {
  height: 100%;
}

.k-gantt-rowheight .k-task-content > .k-resize-e:after, .k-gantt-rowheight .k-task-content > .k-resize-w:after {
  height: 100%;
  margin-top: 0;
  top: 0;
}

.k-task-details {
  text-align: left;
  white-space: nowrap;
  padding: .4em;
}

.k-task-details > strong {
  font-size: 120%;
  display: block;
}

.k-task-pct {
  margin: .5em 0 .1em;
  font-size: 170%;
}

.k-task-details > ul {
  line-height: 1.2;
}

.k-resources-wrap {
  z-index: 2;
  zoom: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: -2px;
  margin-left: 20px;
  display: inline;
  position: absolute;
  overflow: hidden;
}

.k-resources-wrap .k-resource {
  margin: 0 5px;
}

.k-gantt-edit-form > .k-edit-form-container {
  width: 430px;
}

.k-gantt-edit-form > .k-resources-form-container {
  width: 506px;
}

.k-resources-form-container > .k-grid {
  margin: 0 .9em;
}

.k-gantt-edit-form > .k-edit-form-container .k-textbox, .k-gantt-edit-form > .k-edit-form-container .k-numerictextbox {
  width: 15em;
}

.k-gantt-edit-form .k-edit-buttons .k-gantt-delete, .k-pdf-export-shadow .k-gantt {
  float: left;
}

.k-pdf-export-shadow .k-gantt, .k-pdf-export-shadow .k-gantt-timeline, .k-pdf-export-shadow .k-gantt .k-grid-content {
  width: auto !important;
  height: auto !important;
  overflow: visible !important;
}

.k-pdf-export-shadow .k-gantt-treelist {
  height: auto !important;
  overflow: visible !important;
}

.k-pdf-export-shadow .k-gantt-timeline .k-grid-header {
  padding: 0 !important;
}

.k-pdf-export-shadow .k-gantt .k-splitbar, .k-pdf-export-shadow .k-pdf-export {
  display: none;
}

@media (max-width: 480px) {
  .k-gantt-toolbar .k-gantt-toggle {
    display: inline-flex;
  }

  .k-gantt-toolbar .k-button {
    width: calc(1.42857em + 10px);
    height: calc(1.42857em + 10px);
    padding: 4px;
  }

  .k-gantt-toolbar .k-button .k-icon {
    margin: 0;
  }

  .k-gantt-toolbar .k-button .k-button-text {
    display: none;
  }

  .k-gantt .k-treelist .k-grid-content {
    overflow-y: scroll;
  }
}

.k-gantt-resize-hint {
  white-space: nowrap;
}

.k-gantt-planned .k-gantt-rows tr, .k-gantt-planned .k-gantt-tasks tr, .k-gantt.k-gantt-planned .k-grid-content tr {
  height: 3.3em;
}

.k-gantt-planned .k-gantt-dependencies {
  margin-top: .6em;
}

.k-gantt-planned .k-task-dot {
  margin-top: 1.1em;
}

.k-gantt-planned .k-resources-wrap {
  margin-top: .9em;
}

.k-gantt-planned .k-milestone-wrap .k-task-dot {
  margin-top: 1.3em;
}

.k-gantt-planned .k-milestone-wrap .k-task-dot.k-task-start {
  margin-left: -.2em;
}

.k-gantt-planned .k-summary-wrap .k-task-dot {
  margin-top: 1.3em;
}

.k-task-planned {
  align-items: center;
  margin: 0 0 .35em;
  line-height: .75em;
  display: flex;
}

.k-task-planned .k-task-moment {
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  width: 6px;
  height: 6px;
}

.k-task-planned .k-task-moment.k-moment-left {
  margin-left: -4px;
}

.k-task-planned .k-task-duration {
  height: 2px;
}

.k-task-planned:hover .k-task-duration {
  height: 3px;
}

.k-milestone-wrap .k-task-planned {
  margin-bottom: .7em;
}

.k-summary-wrap .k-task-planned {
  margin-bottom: .6em;
}

.k-planned-tooltip .k-task-content {
  display: block;
}

.k-task-offset-wrap .k-task {
  display: inline-block;
}

.k-task-offset-wrap .k-task .k-task-content .k-resize-e {
  display: none;
}

.k-task-offset-wrap .k-task-single + .k-task-offset {
  height: 1.65em;
}

.k-task-offset {
  vertical-align: top;
  flex-direction: row-reverse;
  line-height: 1.46em;
  display: inline-flex;
}

.k-task-offset .k-resize-handle {
  visibility: hidden;
  z-index: 2;
  width: .4em;
  height: auto;
  margin-right: 1.5em;
  position: absolute;
  top: 0;
  bottom: 0;
}

.k-task-offset .k-resize-handle:after {
  content: "";
  width: 1px;
  height: 1.4em;
  margin-top: -.55em;
  position: absolute;
  top: 50%;
}

.k-summary-wrap .k-task-offset {
  height: 10px;
  margin: 3px 0 0;
}

.k-summary-wrap .k-task-offset:after {
  content: "";
  border-style: solid;
  border-width: 8px;
  width: 0;
  height: 0;
  position: absolute;
  right: 1.4em;
}

.k-rtl .k-summary-wrap .k-task-offset:before {
  content: "";
  border-style: solid;
  border-width: 8px;
  width: 0;
  height: 0;
  position: absolute;
}

.k-rtl .k-task-offset-wrap .k-task .k-task-content .k-resize-e {
  display: block;
}

.k-rtl .k-task-offset-wrap .k-task .k-task-content .k-resize-w {
  display: none;
}

.k-rtl .k-milestone-wrap {
  margin-left: -1.8em;
}

.k-rtl .k-task-milestone {
  margin-right: -.15em;
}

.k-ie .k-summary-wrap .k-task-offset:after {
  right: 20px;
}

.k-ie .k-rtl .k-summary-wrap .k-task-offset:before {
  left: 20px;
}

.k-task-offset .k-task-actions {
  visibility: hidden;
}

.k-task-single:hover + .k-task-offset .k-task-actions, .k-task-offset:hover .k-task-actions, .k-task-single:hover + .k-task-offset .k-resize-handle, .k-task-offset:hover .k-resize-handle {
  visibility: visible;
}

.k-pivot {
  position: relative;
}

.k-pivot-toolbar {
  border-style: solid;
  border-width: 0 0 1px;
  flex-wrap: wrap;
  align-items: start;
}

.k-pivot-toolbar > * {
  max-width: 100%;
}

.k-pivot-toolbar:before {
  display: none;
}

.k-pivot .k-pivot-toolbar {
  padding: .6em;
}

.k-pivot-toolbar .k-button {
  text-align: left;
  cursor: move;
  margin-right: .4em;
  padding: .3em 5em .3em .3em;
  font-size: .9em;
  line-height: 1.2em;
  position: relative;
}

.k-field-actions {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 2px;
}

.k-pivot .k-grid td {
  white-space: nowrap;
}

.k-pivot-layout {
  border-spacing: 0;
  table-layout: auto;
}

.k-pivot-layout > tbody > tr > td {
  vertical-align: top;
  padding: 0;
}

.k-pivot td {
  vertical-align: top;
}

.k-pivot-rowheaders > .k-grid, .k-pivot-table > .k-grid {
  border-width: 0;
}

.k-pivot-rowheaders > .k-grid td:first-child, .k-pivot-table .k-grid-header .k-header.k-first {
  border-left-width: 1px;
}

.k-pivot-rowheaders > .k-grid td.k-first {
  border-left-width: 0;
}

.k-pivot-rowheaders > .k-grid {
  overflow: hidden;
}

.k-pivot-table {
  border-left-style: solid;
  border-left-width: 1px;
}

.k-pivot-table .k-grid-header-wrap > table {
  height: 100%;
}

.k-pivot .k-grid-header .k-header {
  vertical-align: top;
}

.k-header.k-alt, td.k-alt {
  font-weight: bold;
}

.k-header.k-alt {
  background-image: none;
}

.k-pivot-layout .k-grid td {
  border-bottom-width: 1px;
}

.k-pivot-layout .k-grid-footer > td {
  border-top-width: 0;
}

.k-pivot-filter-window .k-treeview {
  max-height: 600px;
}

.k-fieldselector .k-edit-buttons {
  bottom: auto;
}

.k-fieldselector .k-edit-label {
  width: 16%;
}

.k-fieldselector .k-edit-field {
  width: 77%;
}

.k-fieldselector .k-edit-field > .k-widget, .k-fieldselector .k-edit-field > .k-textbox {
  width: 99%;
}

.k-fieldselector .k-edit-buttons > input, .k-fieldselector .k-edit-buttons > label {
  float: left;
  margin-top: .4em;
}

.k-fieldselector p {
  text-transform: uppercase;
  margin: 0 0 .2em .5em;
}

.k-fieldselector p .k-icon {
  margin: 0 5px 0 0;
}

.k-fieldselector .k-columns {
  border-style: solid;
  border-width: 0;
}

.k-fieldselector .k-columns > div {
  float: left;
  border-style: solid;
  border-width: 0 0 0 1px;
  width: 45%;
  padding: .6em;
  overflow: auto;
}

.k-fieldselector .k-columns > div:first-child {
  border-width: 0;
  margin-right: -1px;
}

.k-fieldselector .k-columns > div + div {
  float: right;
  border-width: 0;
}

.k-fieldselector div.k-treeview {
  border-width: 0;
  margin-right: -1px;
  padding-left: 4px;
  overflow: visible;
}

.k-fieldselector .k-list-container {
  border-style: solid;
  border-width: 1px;
  margin-bottom: 1em;
  margin-left: .5em;
  padding: .2em 0 0;
}

.k-fieldselector .k-list {
  padding-bottom: 2em;
}

.k-fieldselector .k-list li.k-item {
  min-height: 1em;
  margin: 0 .2em .2em;
  padding: .3em 3.3em .3em .3em;
  font-size: .9em;
  line-height: 1.2em;
  position: relative;
}

.k-i-kpi-trend-increase, .k-i-kpi-trend-decrease, .k-i-kpi-trend-equal {
  color: #858585;
}

.k-i-kpi-status-hold {
  color: #f9ce1d;
}

.k-i-kpi-status-deny {
  color: #f44336;
}

.k-i-kpi-status-open {
  color: #4caf50;
}

.k-pdf-export-shadow .k-pivot .k-grid-content {
  overflow: visible !important;
}

.k-pdf-export-shadow .k-pivot .k-pivot-rowheaders .k-grid, .k-pdf-export-shadow .k-pivot .k-grid-content {
  height: 100% !important;
}

.k-pdf-export-shadow .k-pivot .k-grid-header {
  padding-right: 0 !important;
}

.k-pdf-export-shadow .k-pivot {
  width: auto !important;
  height: auto !important;
}

.k-pdf-export-shadow .k-pivot .k-grid-header-wrap > table, .k-pdf-export-shadow .k-pivot .k-grid-content > table {
  width: 100% !important;
  height: auto !important;
}

.k-pivot .k-field-actions .k-icon {
  pointer-events: all;
}

div.k-treeview {
  white-space: nowrap;
  background: none;
  border-width: 0;
  overflow: auto;
}

.k-treeview .k-item {
  border-width: 0;
  margin: 0;
  padding: 0 0 0 16px;
  display: block;
}

.k-treeview > .k-group, .k-treeview .k-item > .k-group, .k-treeview .k-content {
  background: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.k-treeview .k-icon, .k-treeview .k-image, .k-treeview .k-sprite, .k-treeview .k-checkbox, .k-treeview .k-in {
  vertical-align: top;
  display: inline-block;
}

.k-checkbox-wrapper {
  vertical-align: middle;
  display: inline-block;
}

.k-treeview .k-checkbox-wrapper + .k-in {
  margin-left: 4px;
}

.k-treeview .k-icon, .k-treeview .k-in {
  vertical-align: middle;
}

.k-treeview .k-request-retry {
  vertical-align: baseline;
}

.k-treeview .k-i-plus, .k-treeview .k-i-minus, .k-treeview .k-i-expand, .k-treeview .k-i-collapse {
  cursor: pointer;
  margin-left: -16px;
}

.k-treeview .k-i-collapse {
  margin-top: -4px;
}

.k-treeview .k-sprite, .k-treeview .k-image {
  margin-right: 3px;
}

.k-treeview .k-in {
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: 1px .3333em 1px .25em;
  line-height: 1.3333em;
  text-decoration: none;
}

.k-treeview span.k-in {
  cursor: default;
}

.k-treeview .k-state-hover {
  cursor: pointer;
}

.k-rtl .k-treeview .k-i-expand, .k-rtl .k-treeview .k-i-collapse {
  margin-left: 0;
  margin-right: -16px;
}

.k-rtl .k-treeview .k-checkbox-wrapper + .k-in {
  margin-left: 0;
  margin-right: 4px;
}

span.k-datepicker, span.k-timepicker, span.k-datetimepicker, span.k-colorpicker, span.k-numerictextbox, span.k-combobox, span.k-dropdown, span.k-dropdowntree {
  background-image: none;
}

.k-autocomplete, .k-combobox, .k-datepicker, .k-timepicker, .k-datetimepicker, .k-numerictextbox, .k-dropdown, .k-dropdowntree, .k-selectbox, .k-multiselect, .k-textbox, .k-listbox {
  vertical-align: middle;
  border-width: 0;
  width: 12.4em;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.k-autocomplete > .k-clear-value, .k-dropdown-wrap > .k-clear-value, .k-multiselect-wrap > .k-clear-value {
  visibility: hidden;
  cursor: pointer;
  outline: 0;
  font-size: 100%;
  display: inline-block;
  position: absolute;
}

.k-autocomplete > .k-clear-value {
  right: calc(1em - 8px);
}

.k-multiselect-wrap > .k-clear-value {
  right: calc(1em - 7px);
}

.k-multiselect-wrap.k-multiselect-wrap-arrow > .k-select {
  cursor: pointer;
  outline: 0;
  padding: 0;
  font-size: 100%;
  display: inline-block;
  position: absolute;
  top: calc(1.00357em - 7px);
  bottom: auto;
  right: calc(1em - 7px);
}

.k-multiselect-wrap.k-multiselect-wrap-arrow > .k-clear-value {
  right: 2em;
}

.k-autocomplete > .k-clear-value, .k-dropdown-wrap > .k-clear-value {
  top: 50%;
  transform: translateY(-50%);
}

.k-dropdown-wrap > .k-clear-value {
  right: calc(3em - 8px);
}

.k-multiselect-wrap > .k-clear-value, .k-dropdowntree > .k-multiselect-wrap > .k-clear-value {
  top: calc(1.00357em - 7px);
}

.k-multiselect-wrap .k-readonly, .k-dropdown-wrap .k-readonly {
  opacity: .5;
}

.k-autocomplete.k-state-hover > .k-clear-value, .k-dropdown-wrap.k-state-hover > .k-clear-value, .k-multiselect-wrap.k-state-hover > .k-clear-value, .k-autocomplete.k-state-focused > .k-clear-value, .k-dropdown-wrap.k-state-focused > .k-clear-value, .k-multiselect-wrap.k-state-focused > .k-clear-value, .k-multiselect.k-state-hover > .k-multiselect-wrap > .k-clear-value, .k-multiselect.k-state-focused > .k-multiselect-wrap > .k-clear-value {
  visibility: visible;
}

.k-autocomplete > .k-hidden, .k-dropdown-wrap .k-hidden, .k-multiselect-wrap .k-hidden {
  display: none !important;
}

.k-autocomplete, .k-combobox, .k-datepicker, .k-timepicker, .k-datetimepicker, .k-numerictextbox, .k-dropdown, .k-dropdowntree, .k-selectbox {
  white-space: nowrap;
}

.k-filter-menu .k-combobox, .k-filter-menu .k-datepicker, .k-filter-menu .k-timepicker, .k-filter-menu .k-datetimepicker, .k-filter-menu .k-numerictextbox, .k-filter-menu .k-dropdown, .k-filter-menu .k-dropdowntree, .k-filter-menu .k-autocomplete, .k-filter-menu .k-textbox {
  width: 100%;
}

.k-datetimepicker {
  width: 15em;
}

.k-autocomplete, .k-picker-wrap, .k-numeric-wrap {
  cursor: default;
  position: relative;
}

.k-dropdown-wrap, .k-picker-wrap, .k-numeric-wrap, .k-multiselect-wrap {
  display: block;
}

.k-list-scroller {
  position: relative;
  overflow: auto;
}

.k-popup.k-list-container, .k-popup.k-calendar-container {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  border-style: solid;
  border-width: 1px;
  padding: 2px;
}

.k-list-container.k-popup-dropdowntree {
  padding: 0;
}

.k-list-container.k-popup-dropdowntree .k-check-all {
  margin: 10px 10px 0;
}

.k-list-container.k-popup-dropdowntree .k-treeview {
  box-sizing: border-box;
  padding: 10px;
}

.k-list-container.k-state-border-down, .k-autocomplete.k-state-border-down, .k-picker-wrap.k-state-border-down, .k-numeric-wrap.k-state-border-down {
  border-bottom-width: 0;
  padding-bottom: 1px;
}

.k-list-container .km-scroll-container {
  padding-bottom: 6px;
}

.k-textbox, .k-textarea, .k-autocomplete, .k-dropdown-wrap, .k-multiselect-wrap, .k-picker-wrap, .k-numeric-wrap {
  border-style: solid;
  border-width: 1px;
}

.k-dropdown-wrap {
  position: relative;
}

.k-dropdown-wrap, .k-numeric-wrap, .k-picker-wrap {
  padding-right: 2em;
}

.k-numeric-wrap.k-expand-padding {
  padding-right: 0;
}

.k-numeric-wrap.k-expand-padding .k-i-warning {
  right: 0;
}

.k-textbox, .k-autocomplete {
  padding: 0;
}

.k-textbox.k-space-left {
  padding-left: 2em;
}

.k-textbox.k-space-right {
  padding-right: 2em;
}

.k-picker-wrap .k-input {
  margin: 0;
}

.k-textbox .k-icon {
  margin: -8px 0 0;
  position: absolute;
  top: 50%;
}

.k-space-left .k-icon {
  left: 3px;
}

.k-space-right .k-icon {
  right: 3px;
}

.k-autocomplete, .k-dropdown-wrap, .k-picker-wrap, .k-numeric-wrap {
  transition: box-shadow .15s ease-out;
}

.k-textbox > input, .k-picker-wrap .k-input, .k-numeric-wrap .k-input, .k-dropdown-wrap .k-input {
  box-sizing: border-box;
  width: 100%;
}

.k-picker-wrap .k-input, .k-numeric-wrap .k-input, .k-dropdown-wrap .k-input, .k-selectbox .k-input, .k-multiselect-wrap .k-input {
  border-width: 0;
  outline: 0;
  font-family: inherit;
}

.k-dropdown .k-input, .k-dropdowntree .k-dropdown-wrap .k-input, .k-selectbox .k-input {
  color: inherit;
  background: none;
}

.k-picker-wrap .k-select, .k-numeric-wrap .k-select, .k-dropdown-wrap .k-select {
  vertical-align: top;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.k-combobox .k-select, .k-picker-wrap .k-select, .k-numeric-wrap .k-select {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: inherit;
}

span.k-datetimepicker .k-select, span.k-datetimepicker .k-select + .k-select {
  right: 0;
}

.k-textbox > input, .k-autocomplete .k-input {
  width: 100%;
  display: block;
}

.k-dropdown-wrap .k-select, .k-selectbox .k-select {
  color: inherit;
  border: 0;
  text-decoration: none;
  overflow: hidden;
}

.k-dropdown .k-input, .k-dropdowntree .k-dropdown-wrap .k-input, .k-selectbox .k-input {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.k-textbox > input, .k-textbox > .k-input, .k-autocomplete .k-input, .k-multiselect-wrap .k-input, .k-picker-wrap .k-input, .k-numeric-wrap .k-input, .k-dropdown-wrap .k-input, .k-selectbox .k-input {
  text-indent: .571em;
  text-overflow: ellipsis;
  border: 0;
  margin: 0;
  padding: .178571em 0;
  line-height: 1.65em;
  overflow: hidden;
}

.k-textbox::placeholder, .k-textarea::placeholder, .k-textbox > input::placeholder, .k-textarea > textarea::placeholder, .k-input::placeholder {
  -webkit-user-select: none;
  user-select: none;
}

.k-dropdown-wrap .k-input:before {
  content: "";
  width: 0;
  display: inline-block;
}

.k-ie .k-autocomplete .k-input {
  height: 1.65em;
}

.k-ie .k-textbox > input, .k-ie .k-picker-wrap .k-input, .k-ie .k-numeric-wrap .k-input, .k-ie .k-combobox .k-input {
  height: 2em;
}

.k-ie input.k-textbox {
  height: calc(2em + 2px);
}

.k-webkit .k-combobox .k-dropdown-wrap:before, .k-webkit .k-picker-wrap:before, .k-webkit .k-numeric-wrap:before {
  padding-bottom: .38em;
}

.km.root .k-combobox .k-dropdown-wrap:before, .km.root .k-picker-wrap:before, .km.root .k-numeric-wrap:before {
  content: none;
}

.k-combobox .k-input, .k-picker-wrap .k-input, .k-numeric-wrap .k-input {
  display: inline;
}

.k-picker-wrap .k-select, .k-numeric-wrap .k-select, .k-dropdown-wrap .k-select {
  vertical-align: middle;
  -moz-box-sizing: border-box;
  text-align: center;
  width: 2em;
  height: 100%;
  line-height: 2em;
}

.k-numeric-wrap .k-select {
  padding: 0;
}

body .k-datetimepicker .k-select {
  border-radius: 0;
}

.k-combobox .k-select, .k-dropdown, .k-dropdowntree .k-dropdown-wrap, .k-selectbox .k-icon {
  cursor: pointer;
}

.k-popup {
  border-style: solid;
  border-width: 1px;
}

.k-popup .k-item, .k-list-optionlabel {
  cursor: default;
}

.k-popup .k-calendar {
  border: 0;
}

.k-list {
  height: auto;
}

.k-nodata {
  text-transform: uppercase;
  width: 100%;
  min-height: 138px;
  font-size: .85em;
  font-weight: lighter;
  display: table;
}

.k-nodata > div {
  text-align: center;
  vertical-align: middle;
  padding: 11px;
  display: table-cell;
}

.k-popup .k-list .k-item, .k-fieldselector .k-list .k-item, .k-list-optionlabel, .k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header, .k-listbox .k-item {
  min-height: 1.8em;
  padding: 1px 5px;
  line-height: 1.8em;
}

.k-popup .k-list .k-item, .k-listbox .k-item {
  border: 1px solid #0000;
  padding: 0 4px;
}

.k-popup .k-list .k-item > .k-group {
  top: -1px;
}

.k-group-header + div > .k-list > .k-item.k-first:before {
  content: " ";
  border-top-style: solid;
  border-top-width: 1px;
  display: block;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
}

.k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
  padding-right: 22px;
}

.k-popup .k-list .k-state-hover, .k-popup .k-list .k-state-focused, .k-popup .k-list .k-state-selected, .k-fieldselector .k-list .k-item, .k-list-optionlabel.k-state-focused, .k-list-optionlabel.k-state-selected, .k-listbox .k-item {
  border-style: solid;
  border-width: 1px;
  padding: 0 4px;
}

.k-list-filter {
  margin-bottom: 2px;
  position: relative;
}

.k-list-filter > .k-textbox {
  width: 100%;
  padding-right: 20px;
}

.k-list-filter > .k-icon {
  position: absolute;
  top: 50%;
  right: 6px;
  transform: translateY(-50%);
}

.km-root .k-list-filter > .k-textbox {
  border-left-width: 0;
  border-right-width: 0;
  padding-left: 0;
  padding-right: 0;
}

.k-multiselect {
  width: auto;
  display: block;
}

.k-multiselect-wrap {
  border-radius: 4px;
  padding-right: 22px;
}

.k-multiselect-wrap .k-input {
  text-indent: .571em;
  float: left;
  background-color: #0000;
  border: 0;
  min-width: 25px;
  min-height: 1.65em;
  margin: 0;
  padding: .178571em;
}

.k-multiselect-wrap .k-input::-ms-clear {
  display: none;
}

.k-multiselect-wrap li {
  float: left;
  min-height: calc(1.7em + 2px);
  margin: 1px 0 1px 1px;
  padding: .1em 1.6em .1em .4em;
  line-height: 1.5em;
  position: relative;
}

.k-autocomplete .k-i-loading, .k-multiselect .k-i-loading {
  position: absolute;
  top: auto;
  bottom: .4em;
  right: .4em;
}

.k-multiselect-wrap .k-select {
  padding: .03em .2em;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.k-autocomplete-clearable .k-input, .k-multiselect-clearable .k-input, .k-dropdowntree-clearable .k-input, .k-combobox-clearable .k-input {
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-combobox-clearable .k-input, .k-multiselect-clearable .k-multiselect-wrap, .k-dropdowntree-clearable .k-multiselect-wrap, .k-dropdowntree-clearable .k-dropdown-wrap .k-input {
  padding-right: 2em;
}

.k-datetimepicker .k-picker-wrap {
  padding-right: 4em;
}

.k-datetimepicker .k-select {
  width: 4em;
}

.k-datetimepicker .k-select .k-link {
  width: 50%;
  display: inline-block;
}

.k-datetimepicker .k-link {
  color: inherit;
}

.k-datetimepicker .k-picker-wrap .k-icon {
  margin: 0 2px;
}

.k-picker-wrap .k-icon {
  cursor: pointer;
}

.k-textbox, .k-timepicker, .k-datepicker, .k-datetimepicker {
  vertical-align: middle;
  display: inline-block;
}

.k-time-popup .k-item {
  padding: 1px 3px;
}

.k-datetime-container .k-datetime-wrap {
  width: 21em;
  overflow: hidden;
}

.k-datetime-container .k-datetime-buttongroup {
  padding: 8px;
}

.k-datetime-container .k-datetime-selector {
  transition: transform .2s;
  display: flex;
}

.k-datetime-container .k-datetime-calendar-wrap, .k-datetime-container .k-datetime-time-wrap {
  text-align: center;
  flex: 0 0 21em;
}

.k-datetime-container .k-time-list-container {
  justify-content: center;
}

.k-datetime-container .k-timeselector {
  outline: none;
}

.k-datetime-container .k-time-tab .k-datetime-selector {
  transform: translateX(-100%);
}

.k-datetime-container > .k-popup {
  padding-bottom: 0;
}

.k-datetime-container > .k-popup .k-action-buttons {
  margin-bottom: 0;
}

.k-rtl .k-datetime-container .k-time-tab .k-datetime-selector, .k-datetime-container.k-rtl .k-time-tab .k-datetime-selector, [dir="rtl"] .k-datetime-container .k-time-tab .k-datetime-selector, .k-datetime-container[dir="rtl"] .k-time-tab .k-datetime-selector {
  transform: translateX(100%);
}

.k-time-header {
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  line-height: 2em;
  display: flex;
}

.k-time-header .k-title {
  font-weight: bold;
}

.k-time-header .k-time-now {
  line-height: inherit;
  cursor: pointer;
  border-width: 0;
}

.k-time-list-wrapper {
  box-sizing: content-box;
  text-align: center;
  width: 5em;
  height: calc(10em + 56px);
  padding: 20px 0;
  display: inline-block;
  position: relative;
  overflow: hidden auto;
}

.k-time-list-wrapper .k-title {
  text-align: center;
  text-transform: capitalize;
  z-index: 12;
  min-width: 100%;
  height: 1.5em;
  margin-top: -20px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.5em;
  display: block;
  position: absolute;
}

.k-time-list-wrapper.k-state-focused:before, .k-time-list-wrapper.k-state-focused:after {
  content: " ";
  pointer-events: none;
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  width: 100%;
  height: calc(50% - 1em);
  display: block;
  position: absolute;
  left: 0;
}

.k-time-list-wrapper.k-state-focused:before {
  top: 0;
}

.k-time-list-wrapper.k-state-focused:after {
  bottom: 0;
}

.k-time-container {
  margin-left: -100px;
  margin-right: -117px;
  padding-left: 100px;
  padding-right: 100px;
  line-height: 1.42857;
  display: block;
  position: absolute;
  inset: 20px 0;
  overflow: hidden scroll;
}

.k-time-container > ul {
  width: 5em;
  height: auto;
}

.k-rtl .k-time-container, .k-time-container.k-rtl, [dir="rtl"] .k-time-container, .k-time-container[dir="rtl"] {
  margin-left: -117px;
  margin-right: -100px;
  padding-left: 100px;
  padding-right: 100px;
}

.k-time-list-container {
  display: flex;
  position: relative;
}

.k-time-list {
  z-index: 10;
  outline: 0;
  display: flex;
  position: absolute;
  inset: 0;
}

.k-time-list:before, .k-time-list:after {
  content: " ";
  z-index: 1;
  width: 200%;
  height: 0;
  line-height: 0;
  display: block;
  position: absolute;
  left: -50%;
}

.k-time-list:before {
  top: 0;
}

.k-time-list:after {
  bottom: 0;
}

.k-time-list .k-item {
  padding: 4px 8px;
}

.k-time-highlight {
  z-index: 1;
  border-style: solid;
  border-width: 1px 0;
  border-radius: .1px;
  width: 100%;
  height: calc(1.42857em + 8px);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.k-time-container .k-scrollable-placeholder {
  width: 1px;
  position: absolute;
  top: 0;
  right: 0;
}

.k-time-separator {
  z-index: 11;
  justify-content: center;
  align-self: center;
  height: 100%;
  display: inline-flex;
}

.k-time-footer {
  margin-top: 0;
}

.k-input {
  font-size: inherit;
  padding: .25em 0;
}

.k-input-separator {
  border-style: solid;
  border-width: 0 0 0 1px;
  width: 0;
  height: 16px;
  margin: 0;
}

.k-input, .k-textbox > input, .k-textarea > textarea {
  -webkit-tap-highlight-color: #0000;
  outline: 0;
}

.k-textbox {
  outline: 0;
}

input.k-textbox, textarea.k-textarea {
  padding: .178571em 0;
}

input.k-textbox {
  text-indent: .571em;
  line-height: 1.65em;
}

.k-ie input.k-textbox {
  text-indent: .2855em;
}

textarea.k-textarea {
  height: auto;
}

.k-input-icon {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 2em;
  display: inline-flex;
  position: relative;
}

.k-input-icon .k-icon {
  margin: 0;
  position: static;
}

.k-ie9 .k-input-icon {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.k-ie9 .k-rtl .k-input-icon, .k-ie9 [dir="rtl"] .k-input-icon {
  left: 0;
  right: auto;
}

span.k-numerictextbox {
  background-color: #0000;
}

.k-numerictextbox .k-input {
  margin: 0;
}

.k-numerictextbox .k-link {
  color: inherit;
  vertical-align: middle;
  border-width: 0;
  height: 1em;
  padding: 0;
  line-height: 1em;
  display: block;
  overflow: hidden;
}

.k-numerictextbox .k-icon {
  height: 100%;
  margin: auto;
  display: block;
}

.k-numerictextbox .k-i-arrow-60-up {
  top: 0;
}

.k-numerictextbox .k-i-arrow-60-down {
  bottom: 0;
}

.k-numeric-wrap .k-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.k-rtl .k-multiselect-wrap {
  padding-left: 22px;
  padding-right: 0;
}

.k-maskedtextbox {
  background-color: #0000;
  border-width: 0;
  display: inline-block;
  position: relative;
}

.k-maskedtextbox ::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.k-maskedtextbox .k-i-warning {
  width: 2em;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-dateinput {
  border-width: 0;
  display: inline-block;
  position: relative;
}

.k-dateinput.k-widget {
  background-color: #0000;
}

.k-dateinput ::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

.k-dateinput .k-i-warning, .k-datepicker .k-picker-wrap .k-i-warning, .k-timepicker .k-picker-wrap .k-i-warning, .k-datetimepicker .k-picker-wrap .k-i-warning {
  position: absolute;
  top: 50%;
  right: 0;
  overflow: visible;
  transform: translateY(-50%);
}

.k-listbox {
  box-sizing: border-box;
  height: 200px;
}

.k-listbox.k-widget {
  background-color: #0000;
}

.k-listbox .k-list-scroller {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  height: 100%;
  overflow: auto;
}

.k-listbox .k-list-scroller li {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-touch-callout: none;
}

.k-listbox .k-listbox-toolbar {
  box-sizing: border-box;
}

.k-listbox.k-listbox-toolbar-top {
  padding: 15px 0 1.42857em;
}

.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar {
  margin-top: -15px;
  margin-bottom: 5px;
}

.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li {
  display: inline-block;
}

.k-listbox.k-listbox-toolbar-top .k-listbox-toolbar li + li {
  margin-left: 5px;
}

.k-listbox.k-listbox-toolbar-left .k-listbox-toolbar {
  float: left;
  margin-right: 5px;
}

.k-listbox.k-listbox-toolbar-left .k-listbox-toolbar li + li {
  margin-top: 5px;
}

.k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
  float: right;
  margin-left: 5px;
}

.k-listbox.k-listbox-toolbar-right .k-listbox-toolbar li:not(:last-child) {
  margin-bottom: 5px;
}

.k-listbox.k-listbox-toolbar-bottom {
  padding: 15px 0 1.45em;
}

.k-listbox.k-listbox-toolbar-bottom .k-list-scroller {
  margin-top: -15px;
}

.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar {
  margin-top: 5px;
}

.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li {
  display: inline-block;
}

.k-listbox.k-listbox-toolbar-bottom .k-listbox-toolbar li + li {
  margin-left: 5px;
}

.k-listbox .k-ghost {
  opacity: .5;
}

.k-rtl .k-dropdown-wrap, .k-rtl .k-picker-wrap, .k-rtl .k-numeric-wrap, .k-rtl .k-combobox-clearable .k-input, .k-rtl .k-multiselect-clearable .k-multiselect-wrap, .k-rtl .k-dropdowntree-clearable .k-multiselect-wrap, .k-rtl .k-dropdowntree-clearable .k-dropdown-wrap .k-input {
  padding-left: 2em;
  padding-right: 0;
}

.k-rtl .k-dropdown-wrap > .k-clear-value {
  left: calc(3em - 8px);
}

.k-rtl .k-autocomplete > .k-clear-value {
  left: calc(1em - 8px);
}

.k-rtl .k-multiselect-wrap > .k-clear-value {
  left: calc(1em - 7px);
}

.k-rtl .k-multiselect-wrap.k-multiselect-wrap-arrow > .k-select {
  left: calc(1em - 7px);
  right: auto;
}

.k-rtl .k-multiselect-wrap.k-multiselect-wrap-arrow > .k-clear-value {
  left: 2em;
  right: auto;
}

.k-rtl .k-numeric-wrap.k-state-invalid.k-expand-padding .k-i-warning {
  left: 0;
}

.k-rtl .k-listbox.k-listbox-toolbar-left .k-listbox-toolbar, .k-rtl .k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
  transform: scaleX(-1);
}

.k-rtl .k-listbox.k-listbox-toolbar-left .k-listbox-toolbar {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}

.k-rtl .k-listbox.k-listbox-toolbar-right .k-listbox-toolbar {
  float: left;
  margin-left: 0;
  margin-right: 5px;
}

.k-multiple-selection .k-in.k-state-selected {
  color: inherit;
  background: none;
  border-color: #0000;
}

.k-popup-flush, .k-popup-flush.k-list-container {
  padding: 0;
}

.k-dropdowngrid-popup {
  overflow: hidden;
}

.k-dropdowngrid-popup .k-grid-header, .k-dropdowngrid-popup .k-group-header, .k-dropdowngrid-popup .k-list-scroller, .k-dropdowngrid-popup .k-virtual-content, .k-dropdowngrid-popup .k-footer {
  line-height: 1.42857;
}

.k-dropdowngrid-popup .k-virtual-wrap {
  margin: 0;
}

.k-dropdowngrid-popup .k-grid-header table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  empty-cells: show;
  border-width: 0;
  outline: none;
  width: 100%;
  max-width: none;
  margin: 0 0 -1px;
}

.k-grid-list {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  empty-cells: show;
  border-width: 0;
  outline: none;
  width: 100%;
  max-width: none;
  display: table;
}

.k-grid-list > .k-item {
  box-sizing: border-box;
  display: table-row;
  position: relative;
}

.k-grid-list > .k-item > .k-cell, .k-grid-list > .k-item > .k-group, .k-grid-list > .k-item > .k-spacer-group {
  box-sizing: border-box;
  vertical-align: middle;
  display: table-cell;
}

.k-grid-list.k-virtual-list > .k-item > .k-cell, .k-grid-list.k-virtual-list > .k-item > .k-group, .k-grid-list.k-virtual-list > .k-item > .k-spacer-group {
  display: inline-block;
}

.k-dropdowngrid-popup .k-grid-header .k-header, .k-grid-list > .k-item > .k-cell {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-style: solid;
  border-width: 0 0 1px 1px;
  padding: 6px 8px;
  overflow: hidden;
}

.k-dropdowngrid-popup .k-grid-header .k-header:first-child, .k-grid-list > .k-item > .k-cell:first-child {
  border-left-width: 0;
}

div.k-dropdowngrid-popup .k-group-header {
  text-align: left;
  min-height: auto;
  margin: 0;
  padding: 6px 8px;
}

.k-grid-list > .k-item > .k-group-cell, .k-grid-list > .k-item > .k-spacer-cell {
  border-left-width: 0;
  border-right-width: 0;
  width: 0;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  overflow: visible;
}

.k-grid-list > .k-item > .k-group-cell > span {
  padding: 0 4px;
  font-size: .875em;
  position: absolute;
  top: 0;
  right: 0;
}

.k-grid-list > .k-item:last-child > .k-cell, .k-grid-list > .k-item:last-child > .k-group-cell, .k-grid-list > .k-item:last-child > .k-spacer-cell {
  border-bottom-width: 0;
}

.k-dropdowngrid-popup .k-footer {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-style: solid;
  border-width: 1px 0 0;
  padding: 6px 8px;
  position: relative;
  overflow: hidden;
}

.k-rtl .k-grid-list > .k-item > .k-cell {
  text-align: right;
  border-left-width: 1px;
  border-right-width: 1px;
}

.k-rtl .k-grid-list > .k-item > .k-cell:first-child {
  border-right-width: 0;
}

.k-rtl div.k-dropdowngrid-popup .k-group-header {
  text-align: right;
}

.k-rtl .k-grid-list > .k-item > .k-group-cell > span {
  left: 0;
  right: auto;
}

.k-textbox-container, .k-floating-label-container {
  vertical-align: middle;
  flex-direction: column;
  justify-content: stretch;
  width: 12.4em;
  padding-top: 1.2375em;
  display: inline-flex;
  position: relative;
}

.k-textbox-container > .k-label, .k-floating-label-container > .k-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: text;
  pointer-events: none;
  max-width: 90%;
  line-height: 1.65em;
  transition: transform .2s ease-out, color .2s ease-out;
  position: absolute;
  top: calc(1.41607em + 1px);
  left: calc(0 + 1px + .571em);
  overflow: hidden;
}

.k-textbox-container > .k-textbox, .k-floating-label-container > .k-textbox, .k-textbox-container > .k-textarea, .k-floating-label-container > .k-textarea, .k-textbox-container > .k-widget, .k-floating-label-container > .k-widget {
  flex: auto;
  width: auto;
}

.k-textbox-container.k-state-empty > .k-label, .k-floating-label-container.k-state-empty > .k-label {
  font-style: normal;
  transform: translate(0)scale(1);
}

.k-textbox-container.k-state-focused, .k-floating-label-container.k-state-focused {
  box-shadow: none;
}

.k-textbox-container > .k-label, .k-floating-label-container > .k-label, .k-textbox-container.k-state-focused > .k-label, .k-floating-label-container.k-state-focused > .k-label {
  transform: translate(calc(0 - .571em), -.133929em)translate(-1px, -1.2375em)translate(-12.5%, -9.375%)scale(.75);
}

.k-textbox-container.k-state-empty:not(.k-state-focused) ::placeholder, .k-floating-label-container.k-state-empty:not(.k-state-focused) ::placeholder {
  color: #0000;
}

.k-rtl .k-textbox-container > .k-label, .k-rtl .k-floating-label-container > .k-label, .k-textbox-container[dir="rtl"] > .k-label, .k-floating-label-container[dir="rtl"] > .k-label {
  left: auto;
  right: calc(0 + 1px + .571em);
}

.k-rtl .k-textbox-container.k-state-empty > .k-label, .k-rtl .k-floating-label-container.k-state-empty > .k-label, .k-textbox-container[dir="rtl"].k-state-empty > .k-label, .k-floating-label-container[dir="rtl"].k-state-empty > .k-label {
  transform: translate(0)scale(1);
}

.k-rtl .k-textbox-container > .k-label, .k-rtl .k-floating-label-container > .k-label, .k-textbox-container[dir="rtl"] > .k-label, .k-floating-label-container[dir="rtl"] > .k-label, .k-rtl .k-textbox-container.k-state-focused > .k-label, .k-rtl .k-floating-label-container.k-state-focused > .k-label, .k-textbox-container[dir="rtl"].k-state-focused > .k-label, .k-floating-label-container[dir="rtl"].k-state-focused > .k-label {
  transform: translate(calc(0 + .571em), -.133929em)translate(1px, -1.2375em)translate(12.5%, -9.375%)scale(.75);
}

.k-input-label {
  z-index: 1;
  margin-right: .571em;
}

.k-rtl .k-input-label, .k-input-label.k-rtl, [dir="rtl"] .k-input-label, .k-input-label[dir="rtl"] {
  margin-left: .571em;
  margin-right: 0;
}

.k-input-prefix, .k-input-suffix {
  flex-shrink: 0;
  align-items: center;
  height: calc(1.42857em + 8px);
  display: flex;
}

.k-input-prefix > *, .k-input-suffix > * {
  flex-shrink: 0;
}

.k-input-prefix > .k-icon, .k-input-suffix > .k-icon {
  flex-flow: row;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: calc(20px + .357143em);
  height: calc(20px + .357143em);
  display: inline-flex;
}

.k-input-prefix > .k-clear-value, .k-input-suffix > .k-clear-value {
  width: calc(20px + .357143em);
  height: calc(20px + .357143em);
  position: relative;
}

.k-input-prefix > .k-clear-value:after, .k-input-suffix > .k-clear-value:after {
  content: "";
  opacity: 0;
  pointer-events: none;
  display: none;
  position: absolute;
  inset: -1px;
}

.k-input-prefix > .k-clear-value.k-state-focused:after, .k-input-suffix > .k-clear-value.k-state-focused:after, .k-input-prefix > .k-clear-value:focus:after, .k-input-suffix > .k-clear-value:focus:after {
  display: block;
}

.k-cursor-auto {
  cursor: auto;
}

.k-cursor-default {
  cursor: default;
}

.k-cursor-none {
  cursor: none;
}

.k-cursor-context-menu {
  cursor: context-menu;
}

.k-cursor-help {
  cursor: help;
}

.k-cursor-pointer {
  cursor: pointer;
}

.k-cursor-progress {
  cursor: progress;
}

.k-cursor-wait {
  cursor: wait;
}

.k-cursor-cell {
  cursor: cell;
}

.k-cursor-crosshair {
  cursor: crosshair;
}

.k-cursor-text {
  cursor: text;
}

.k-cursor-vertical-text {
  cursor: vertical-text;
}

.k-cursor-alias {
  cursor: alias;
}

.k-cursor-copy {
  cursor: copy;
}

.k-cursor-move {
  cursor: move;
}

.k-cursor-no-drop {
  cursor: no-drop;
}

.k-cursor-not-allowed {
  cursor: not-allowed;
}

.k-cursor-e-resize {
  cursor: e-resize;
}

.k-cursor-n-resize {
  cursor: n-resize;
}

.k-cursor-ne-resize {
  cursor: ne-resize;
}

.k-cursor-nw-resize {
  cursor: nw-resize;
}

.k-cursor-s-resize {
  cursor: s-resize;
}

.k-cursor-se-resize {
  cursor: se-resize;
}

.k-cursor-sw-resize {
  cursor: sw-resize;
}

.k-cursor-w-resize {
  cursor: w-resize;
}

.k-cursor-ew-resize {
  cursor: ew-resize;
}

.k-cursor-ns-resize {
  cursor: ns-resize;
}

.k-cursor-nesw-resize {
  cursor: nesw-resize;
}

.k-cursor-nwse-resize {
  cursor: nwse-resize;
}

.k-cursor-col-resize {
  cursor: col-resize;
}

.k-cursor-row-resize {
  cursor: row-resize;
}

.k-cursor-all-scroll {
  cursor: all-scroll;
}

.k-cursor-zoom-in {
  cursor: zoom-in;
}

.k-cursor-zoom-out {
  cursor: zoom-out;
}

.k-cursor-grab {
  cursor: grab;
}

.k-cursor-grabbing {
  cursor: grabbing;
}

select.k-dropdown {
  text-overflow: ellipsis;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: 0;
  padding: 4px 8px;
  transition: all .1s;
  overflow: hidden;
}

.k-notification {
  cursor: default;
  white-space: nowrap;
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  position: relative;
}

.k-notification > .k-notification-wrap {
  padding: .6em .5em;
}

.k-notification-closable > .k-notification-wrap {
  padding-right: 20px;
}

.k-notification .k-notification-content {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.k-notification-wrap > .k-i-info, .k-notification-wrap > .k-i-success, .k-notification-wrap > .k-i-warning, .k-notification-wrap > .k-i-error {
  vertical-align: text-bottom;
  margin-right: 4px;
}

.k-notification-wrap > .k-i-close {
  display: none;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
}

.k-notification-closable .k-notification-wrap > .k-i-close {
  display: block;
}

.k-rtl .k-notification-closable .k-notification-wrap, .k-rtl.k-notification-closable .k-notification-wrap {
  padding-left: 20px;
  padding-right: .5em;
}

.k-rtl .k-notification-wrap > .k-i-info, .k-rtl .k-notification-wrap > .k-i-success, .k-rtl .k-notification-wrap > .k-i-warning, .k-rtl .k-notification-wrap > .k-i-error {
  margin-left: 4px;
  margin-right: 0;
}

.k-rtl .k-notification-wrap > .k-i-close {
  left: 4px;
  right: auto;
}

.k-progressbar {
  vertical-align: middle;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.k-progressbar-horizontal {
  width: 27em;
  height: 1.9em;
}

.k-progressbar-vertical {
  width: 1.9em;
  height: 27em;
}

.k-progressbar > .k-state-selected {
  border-style: solid;
  border-width: 1px;
  position: absolute;
  overflow: hidden;
}

.k-progressbar-horizontal > .k-state-selected, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected {
  border-radius: 4px 0 0 4px;
  height: 100%;
  top: -1px;
  left: -1px;
  right: auto;
}

.k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected, .k-rtl .k-progressbar-horizontal > .k-state-selected {
  border-radius: 0 4px 4px 0;
  left: auto;
  right: -1px;
}

.k-progressbar-vertical > .k-state-selected {
  border-radius: 0 0 4px 4px;
  width: 100%;
  bottom: -1px;
  left: -1px;
}

.k-progressbar-vertical.k-progressbar-reverse > .k-state-selected {
  border-radius: 4px 4px 0 0;
  top: -1px;
  bottom: auto;
}

.k-progressbar > .k-state-selected.k-complete, .k-rtl .k-progressbar > .k-state-selected.k-complete {
  border-radius: 4px;
}

.k-progressbar > .k-reset {
  white-space: nowrap;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: -1px;
  left: -1px;
}

.k-progressbar-horizontal .k-item {
  border-style: solid;
  height: 100%;
  margin-left: -1px;
  display: inline-block;
}

.k-progressbar-horizontal .k-item.k-first {
  margin-left: 0;
}

.k-progressbar-horizontal .k-item.k-last {
  border-right-width: 0;
}

.k-progressbar-horizontal .k-item, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-item, .k-progressbar-horizontal.k-progressbar-reverse .k-item, .k-rtl .k-progressbar-horizontal .k-item {
  border-width: 1px 0 1px 1px;
}

.k-progressbar-horizontal .k-first, .k-rtl .k-progressbar-horizontal .k-last, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last {
  border-left-width: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.k-progressbar-horizontal .k-last, .k-rtl .k-progressbar-horizontal .k-first {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.k-progressbar-horizontal.k-progressbar-reverse .k-last, .k-rtl .k-progressbar-horizontal .k-first, .k-progressbar-horizontal .k-last.k-state-selected {
  border-right-width: 1px;
}

.k-progressbar-vertical .k-item {
  border-style: solid;
  border-width: 1px 1px 0;
  width: 100%;
  margin-top: -1px;
}

.k-progressbar-vertical .k-item.k-first {
  margin-top: 0;
}

.k-progressbar-vertical li.k-item.k-last {
  border-bottom-width: 0;
}

.k-progressbar-vertical .k-first {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.k-progressbar-vertical .k-last {
  border-bottom-width: 1px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.k-progressbar-vertical.k-progressbar-reverse .k-item {
  border-width: 0 1px 1px;
}

.k-progressbar-vertical.k-progressbar-reverse .k-first {
  border-top-width: 1px;
}

.k-progress-status-wrap {
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
  line-height: 2em;
  position: absolute;
  top: -1px;
}

.k-progress-status-wrap, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap {
  text-align: right;
  left: -1px;
  right: auto;
}

.k-progressbar-horizontal.k-progressbar-reverse .k-progress-status-wrap, .k-rtl .k-progressbar-horizontal .k-progress-status-wrap {
  text-align: left;
  left: auto;
  right: -1px;
}

.k-progressbar-vertical .k-progress-status-wrap {
  top: auto;
  bottom: -1px;
}

.k-progressbar-vertical.k-progressbar-reverse .k-progress-status-wrap {
  top: -1px;
  bottom: auto;
}

.k-progress-status {
  white-space: nowrap;
  min-width: 10px;
  padding: 0 .5em;
  display: inline-block;
}

.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  position: absolute;
  bottom: 0;
  left: 0;
}

.k-progressbar-vertical .k-progress-status {
  transform-origin: 0 0;
  transform: rotate(-90deg)translateX(-100%);
}

.k-progressbar-vertical.k-progressbar-reverse .k-progress-status {
  transform-origin: 0 100%;
  transform: rotate(90deg)translateX(-100%);
}

div.k-slider {
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border-width: 0;
  position: relative;
}

.k-slider-vertical {
  width: 26px;
  height: 200px;
}

.k-slider-horizontal {
  width: 200px;
  height: 26px;
  display: inline-block;
}

.k-slider-wrap {
  width: 100%;
  height: 100%;
}

.k-slider .k-button, .k-grid .k-slider .k-button {
  outline: 0;
  width: 24px;
  min-width: 0;
  height: 24px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

.k-state-disabled .k-slider-wrap {
  opacity: .6;
}

.k-state-disabled .k-slider-wrap .k-slider-items {
  color: #333;
}

.k-slider .k-button-decrease {
  left: 0;
}

.k-slider-vertical .k-button-decrease, .k-grid .k-slider-vertical .k-button-decrease {
  top: auto;
  bottom: 0;
}

.k-slider .k-button-increase {
  right: 0;
}

.k-slider .k-icon, .k-slider-track, .k-slider .k-tick {
  cursor: pointer;
}

.k-slider-track, .k-slider-selection {
  margin: 0;
  padding: 0;
  position: absolute;
}

.k-slider-horizontal .k-slider-track, .k-slider-horizontal .k-slider-selection {
  background-repeat: repeat-x;
  height: 8px;
  margin-top: -4px;
  top: 50%;
  left: 0;
}

.k-slider-horizontal .k-slider-buttons .k-slider-track {
  left: 34px;
}

.k-slider-vertical .k-slider-track, .k-slider-vertical .k-slider-selection {
  background-repeat: repeat-y;
  width: 8px;
  margin-left: -4px;
  bottom: 0;
  left: 50%;
}

.k-slider-vertical .k-slider-buttons .k-slider-track {
  bottom: 34px;
}

.k-draghandle {
  text-indent: -3333px;
  text-align: center;
  background-color: #0000;
  background-repeat: no-repeat;
  outline: 0;
  text-decoration: none;
  position: absolute;
  overflow: hidden;
}

.k-slider-horizontal .k-draghandle {
  width: 13px;
  height: 14px;
  top: -4px;
}

.k-slider-vertical .k-draghandle {
  width: 14px;
  height: 13px;
  left: -4px;
}

.k-slider-buttons .k-slider-items {
  margin-left: 34px;
}

.k-slider-horizontal .k-slider-items {
  height: 100%;
}

.k-slider-vertical .k-slider-items {
  padding-top: 1px;
}

.k-slider-vertical .k-slider-buttons .k-slider-items {
  margin: 0;
  padding-top: 35px;
}

.k-slider .k-tick {
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  position: relative;
}

.k-slider-horizontal .k-tick {
  float: left;
  text-align: center;
  background-position: 50% -92px;
  height: 100%;
}

.k-slider-horizontal .k-slider-topleft .k-tick {
  background-position: 50% -122px;
}

.k-slider-horizontal .k-slider-bottomright .k-tick {
  background-position: 50% -152px;
}

.k-slider-horizontal .k-tick-large {
  background-position: 50% -2px;
}

.k-slider-horizontal .k-slider-topleft .k-tick-large {
  background-position: 50% -32px;
}

.k-slider-horizontal .k-slider-bottomright .k-tick-large {
  background-position: 50% -62px;
}

.k-slider-vertical .k-tick {
  background-position: -92px;
}

.k-slider-vertical .k-slider-topleft .k-tick {
  background-position: -122px;
}

.k-slider-vertical .k-slider-bottomright .k-tick {
  background-position: -152px;
}

.k-slider-vertical .k-tick-large {
  background-position: -2px;
}

.k-slider-vertical .k-slider-topleft .k-tick-large {
  background-position: -32px;
}

.k-slider-vertical .k-slider-bottomright .k-tick-large {
  background-position: -62px;
}

.k-slider-horizontal .k-first {
  background-position: 0 -92px;
}

.k-slider-horizontal .k-tick-large.k-first {
  background-position: 0 -2px;
}

.k-slider-horizontal .k-slider-topleft .k-first {
  background-position: 0 -122px;
}

.k-slider-horizontal .k-slider-topleft .k-tick-large.k-first {
  background-position: 0 -32px;
}

.k-slider-horizontal .k-slider-bottomright .k-first {
  background-position: 0 -152px;
}

.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-first {
  background-position: 0 -62px;
}

.k-slider-horizontal .k-last {
  background-position: 100% -92px;
}

.k-slider-horizontal .k-tick-large.k-last {
  background-position: 100% -2px;
}

.k-slider-horizontal .k-slider-topleft .k-last {
  background-position: 100% -122px;
}

.k-slider-horizontal .k-slider-topleft .k-tick-large.k-last {
  background-position: 100% -32px;
}

.k-slider-horizontal .k-slider-bottomright .k-last {
  background-position: 100% -152px;
}

.k-slider-horizontal .k-slider-bottomright .k-tick-large.k-last {
  background-position: 100% -62px;
}

.k-slider-vertical .k-first {
  background-position: -92px 100%;
}

.k-slider-vertical .k-tick-large.k-first {
  background-position: -2px 100%;
}

.k-slider-vertical .k-slider-topleft .k-first {
  background-position: -122px 100%;
}

.k-slider-vertical .k-slider-topleft .k-tick-large.k-first {
  background-position: -32px 100%;
}

.k-slider-vertical .k-slider-bottomright .k-first {
  background-position: -152px 100%;
}

.k-slider-vertical .k-slider-bottomright .k-tick-large.k-first {
  background-position: -62px 100%;
}

.k-slider-vertical .k-last {
  background-position: -92px 0;
}

.k-slider-vertical .k-tick-large.k-last {
  background-position: -2px 0;
}

.k-slider-vertical .k-slider-topleft .k-last {
  background-position: -122px 0;
}

.k-slider-vertical .k-slider-topleft .k-tick-large.k-last {
  background-position: -32px 0;
}

.k-slider-vertical .k-slider-bottomright .k-last {
  background-position: -152px 0;
}

.k-slider-vertical .k-slider-bottomright .k-tick-large.k-last {
  background-position: -62px 0;
}

.k-slider-vertical .k-tick {
  text-align: right;
}

.k-slider-vertical .k-slider-topleft .k-tick {
  text-align: left;
}

.k-slider .k-label {
  white-space: nowrap;
  font-size: .92em;
  position: absolute;
}

.k-slider-horizontal .k-label {
  width: 100%;
  line-height: 1;
  left: 0;
}

.k-slider-horizontal .k-first .k-label {
  left: -50%;
}

.k-slider-horizontal .k-last .k-label {
  left: auto;
  right: -50%;
}

.k-slider-horizontal .k-label {
  bottom: -1.2em;
}

.k-slider-horizontal .k-slider-topleft .k-label {
  top: -1.2em;
}

.k-slider-vertical .k-label {
  text-align: left;
  display: block;
  left: 120%;
}

.k-slider-vertical .k-last .k-label {
  top: -.5em;
}

.k-slider-vertical .k-first .k-label {
  bottom: -.5em;
}

.k-slider-vertical .k-slider-topleft .k-label {
  left: auto;
  right: 120%;
}

.k-slider-tooltip {
  text-align: center;
  display: inline-block;
}

.k-scheduler .k-link {
  color: inherit;
}

.k-scheduler-toolbar {
  z-index: 2;
  border-width: 0 0 1px;
}

.k-scheduler-toolbar .k-nav-current {
  color: inherit;
  cursor: pointer;
  flex-flow: row;
  align-items: center;
  text-decoration: none;
  display: inline-flex;
}

.k-scheduler-toolbar .k-nav-current .k-icon {
  margin-inline-end: 4px;
}

.k-scheduler-toolbar .k-nav-current .k-lg-date-format, .k-scheduler-toolbar .k-nav-current .k-sm-date-format {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-scheduler-toolbar .k-nav-current .k-lg-date-format {
  display: inline-block;
}

.k-scheduler-toolbar .k-nav-current .k-sm-date-format {
  display: none;
}

.k-scheduler-toolbar .k-scheduler-search {
  flex-flow: row;
  display: inline-flex;
}

.k-scheduler-toolbar .k-scheduler-views-wrapper {
  margin-left: auto;
}

.k-scheduler-toolbar .k-scheduler-views-wrapper .k-views-dropdown {
  font: inherit;
  width: auto;
  display: none;
}

@media (max-width: 1400px) {
  .k-scheduler-toolbar .k-nav-current .k-lg-date-format {
    max-width: 200px;
  }
}

@media (max-width: 1024px) {
  .k-scheduler-toolbar .k-nav-current .k-lg-date-format {
    display: none;
  }

  .k-scheduler-toolbar .k-nav-current .k-sm-date-format {
    display: inline-block;
  }

  .k-scheduler-toolbar .k-scheduler-views-wrapper .k-scheduler-views {
    display: none;
  }

  .k-scheduler-toolbar .k-scheduler-views-wrapper .k-views-dropdown {
    display: inline-block;
  }
}

.k-scheduler-footer {
  border-width: 1px 0 0;
}

.k-edit-field.k-scheduler-toolbar {
  border-width: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.k-scheduler-header {
  text-align: center;
}

.k-scheduler-header .k-link {
  color: inherit;
}

.k-scheduler-header, .k-scheduler-header-wrap {
  overflow: hidden;
}

.k-scheduler-header-wrap {
  border-style: solid;
  border-width: 0;
  position: relative;
}

.k-scheduler .k-scrollbar-v .k-scheduler-header-wrap {
  border-right-width: 1px;
}

.k-scheduler-times, .k-scheduler-content {
  position: relative;
}

.k-scheduler-times {
  border-style: solid;
  border-width: 0;
  overflow: hidden;
}

.k-scheduler-content {
  overflow: auto;
}

.k-scheduler-monthview .k-scheduler-content {
  overflow-y: scroll;
}

.k-scheduler-layout, .k-scheduler-table {
  border-spacing: 0;
  border-collapse: separate;
  width: 100%;
  margin: 0;
}

.k-scheduler-layout > tbody > tr > td {
  vertical-align: top;
  padding: 0;
}

.k-safari .k-scheduler-layout > tbody > tr > td + td {
  width: 100%;
}

.k-scheduler-table {
  table-layout: fixed;
  max-width: none;
}

.k-scheduler-times .k-scheduler-table {
  table-layout: auto;
}

.k-scheduler-monthview .k-scheduler-content .k-scheduler-table {
  height: 100%;
}

.k-scheduler-table td, .k-scheduler-table th {
  height: 1.5em;
  padding: 4px .5em;
  font-size: 100%;
}

.k-scheduler .k-scheduler-table td, .k-scheduler .k-scheduler-table th {
  box-sizing: content-box;
}

.k-scheduler-dayview div:not(.k-scheduler-header) .k-today:not(.k-nonwork-hour):not(.k-state-selected), .k-scheduler-timelineview .k-today:not(.k-nonwork-hour) {
  background-color: #0000;
}

.k-scheduler-monthview .k-hidden, .k-scheduler-monthview .k-hidden > div {
  width: 0 !important;
  overflow: hidden !important;
}

.k-scheduler-monthview .k-hidden {
  border-right-width: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times {
  margin-right: 1px;
}

.k-scheduler-monthview > tbody > tr:first-child .k-scheduler-times .k-hidden {
  height: auto;
}

.k-scheduler-monthview .k-scheduler-table td, .k-scheduler-monthview .k-hidden {
  text-align: right;
  height: 85px;
}

.k-scheduler-monthview .k-scheduler-table-auto, .k-scheduler-monthview .k-scheduler-table-auto td, .k-scheduler-monthview .k-scheduler-content .k-scheduler-table-auto {
  height: auto;
}

.k-scheduler-table td, .k-slot-cell, .k-scheduler-layout tr + tr .k-scheduler-times th:last-child {
  vertical-align: top;
}

.k-scheduler-header th {
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-scheduler-table td, .k-scheduler-header th {
  border-style: solid;
  border-width: 0 0 1px 1px;
}

.k-scheduler-table td:first-child, .k-scheduler-header th:first-child {
  border-left-width: 0;
}

.k-scheduler-agendaview .k-scheduler-table td:first-child {
  border-left-width: 1px;
}

.k-scheduler-agendaview .k-scheduler-table td.k-first {
  border-left-width: 0;
}

.k-scheduler-layout tr + tr .k-scheduler-times tr:last-child > th, .k-scheduler-layout tr + tr .k-scheduler-table > tbody > tr:last-child > td, .k-scheduler-table > tbody > tr > .k-last {
  border-bottom-width: 0;
}

.k-scrollbar-h tr + tr .k-scheduler-times, .k-scrollbar-h .k-scheduler-content .k-scheduler-table > tbody > tr:last-child > td, .k-scheduler-agendaview.k-scrollbar-h .k-scheduler-table > tbody > tr > td.k-last {
  border-bottom-width: 1px;
}

.k-scheduler-times th {
  text-align: right;
  white-space: nowrap;
  border: 1px solid #0000;
  border-width: 0 1px 1px 0;
  padding-right: .6em;
}

.k-scheduler-layout tr + tr .k-scheduler-times th {
  border-bottom-color: #0000;
}

.k-scheduler-layout tr + tr .k-scheduler-times th.k-slot-cell, .k-scheduler-layout tr + tr .k-scheduler-times th.k-scheduler-times-all-day {
  border-bottom-color: inherit;
}

.k-scheduler .k-middle-row td {
  border-bottom-style: dotted;
}

.k-scheduler-now-arrow, .k-scheduler-now-line {
  position: absolute;
}

.k-scheduler-now-arrow {
  border: 5px solid #0000;
  width: 0;
  height: 0;
  left: 0;
}

.k-scheduler-now-line {
  height: 1px;
  left: 5px;
  right: 0;
}

.k-task {
  position: relative;
}

div.k-more-events {
  height: 13px;
  padding: 0;
}

.k-more-events > .k-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-event, .k-more-events {
  box-sizing: border-box;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  overflow: hidden;
}

.k-event .k-nav-day {
  display: inline-block;
}

.k-event {
  cursor: default;
  min-height: 1.3em;
}

.k-event-drag-hint {
  opacity: .6;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.k-scheduler-header .k-event {
  white-space: nowrap;
}

.k-event-template {
  padding: 4px 1.4em 4px .6em;
}

.k-event-time {
  padding-bottom: 0;
  font-size: .9em;
  display: none;
}

.k-event-drag-hint .k-event-time {
  display: block;
}

.k-event-actions, .k-event > .k-link, .k-task > .k-link {
  white-space: nowrap;
  position: absolute;
  top: 3px;
  right: 4px;
}

.k-event-actions {
  z-index: 1;
}

.k-scheduler-agendaview .k-task > .k-link {
  top: 0;
  right: 0;
}

.k-event-actions:first-child {
  float: left;
  margin: 4px 2px 0 4px;
  position: static;
}

.k-webkit .k-event-actions:first-child {
  margin-top: 3px;
}

.k-event-actions:first-child > .k-link {
  display: inline-block;
}

.k-event-delete {
  display: none;
}

.k-event:hover .k-event-delete, tr:hover > td > .k-task .k-event-delete {
  display: inline-block;
}

.k-event .k-event-top-actions, .k-event .k-event-bottom-actions {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.k-event .k-event-bottom-actions {
  top: auto;
  bottom: 0;
}

.k-event .k-resize-handle, .k-scheduler-mobile .k-event:hover .k-resize-handle {
  visibility: hidden;
  z-index: 2;
  position: absolute;
}

.k-event:hover .k-resize-handle, .k-event-active .k-resize-handle, .k-scheduler-mobile .k-event-active:hover .k-resize-handle {
  visibility: visible;
}

.k-event .k-resize-handle:after {
  content: "";
  opacity: .5;
  position: absolute;
}

.k-scheduler-mobile .k-event .k-resize-handle:after {
  filter: none;
  opacity: 1;
}

.k-event > .k-resize-n {
  height: .4em;
  top: 0;
  left: 0;
  right: 0;
}

.k-event > .k-resize-s {
  height: .4em;
  bottom: 0;
  left: 0;
  right: 0;
}

.k-event > .k-resize-e {
  width: .4em;
  top: 0;
  bottom: 0;
  right: 0;
}

.k-event > .k-resize-w {
  width: .4em;
  top: 0;
  bottom: 0;
  left: 0;
}

.k-event > .k-resize-n:after, .k-event > .k-resize-s:after {
  width: 2em;
  height: 1px;
  margin-left: -1em;
  top: 1px;
  left: 50%;
}

.k-event > .k-resize-s:after {
  top: auto;
  bottom: 1px;
}

.k-event > .k-resize-e:after, .k-event > .k-resize-w:after {
  width: 1px;
  height: 1.4em;
  margin-top: -.7em;
  top: 50%;
  left: 1px;
}

.k-event > .k-resize-e:after {
  left: auto;
  right: 1px;
}

.k-scheduler-mobile .k-event > .k-resize-n, .k-scheduler-mobile .k-event > .k-resize-s {
  height: .6em;
}

.k-scheduler-mobile .k-event > .k-resize-e, .k-scheduler-mobile .k-event > .k-resize-w {
  width: .6em;
}

.k-scheduler-mobile .k-event > .k-resize-n:after, .k-scheduler-mobile .k-event > .k-resize-s:after {
  width: 4em;
  height: .6em;
  margin-left: -3em;
  top: 0;
}

.k-scheduler-mobile .k-event > .k-resize-s:after {
  bottom: 0;
}

.k-scheduler-mobile .k-event > .k-resize-e:after, .k-scheduler-mobile .k-event > .k-resize-w:after {
  width: .6em;
  height: 1.4em;
  margin-top: -.7em;
  left: 0;
}

.k-scheduler-mobile .k-event > .k-resize-e:after {
  right: 0;
}

.k-scheduler-mobile .k-event > .k-resize-n:after {
  border-radius: 0 0 4px 4px;
}

.k-scheduler-mobile .k-event > .k-resize-s:after {
  border-radius: 4px 4px 0 0;
}

.k-scheduler-mobile .k-event > .k-resize-w:after {
  border-radius: 0 4px 4px 0;
}

.k-scheduler-mobile .k-event > .k-resize-e:after {
  border-radius: 4px 0 0 4px;
}

.k-scheduler-marquee {
  border-style: solid;
  border-width: 0;
}

.k-scheduler-marquee.k-first:before, .k-scheduler-marquee.k-last:after {
  content: "";
  border-style: solid;
  border-width: 3px;
  width: 0;
  height: 0;
  position: absolute;
}

div.k-scheduler-marquee:before {
  border-bottom-color: #0000;
  border-right-color: #0000;
  top: 0;
  left: 0;
}

div.k-scheduler-marquee:after {
  border-top-color: #0000;
  border-left-color: #0000;
  bottom: 0;
  right: 0;
}

.k-scheduler-marquee .k-label-top {
  font-size: .8em;
  position: absolute;
  top: .3em;
  left: .8em;
}

.k-scheduler-marquee .k-label-bottom {
  font-size: .8em;
  position: absolute;
  bottom: .3em;
  right: .81em;
}

.k-scheduler-quickedit .k-textbox {
  width: 200px;
}

.k-scheduler-agendaview .k-scheduler-table th, .k-scheduler-agendaview .k-scheduler-table td {
  text-align: left;
}

.k-scheduler-times .k-slot-cell, .k-scheduler-groupcolumn {
  width: 6em;
}

.k-scheduler-datecolumn {
  width: 12em;
}

.k-scheduler-timecolumn {
  width: 11em;
}

.k-scheduler-timecolumn > div {
  vertical-align: top;
  position: relative;
}

.k-webkit .k-scheduler-timecolumn > div > .k-icon {
  vertical-align: top;
}

.k-scheduler-timecolumn > div > .k-i-arrow-60-right {
  position: absolute;
  right: -4px;
}

.k-scheduler-timecolumn .k-i-arrow-60-left {
  margin-left: -4px;
}

.k-scheduler-mark {
  vertical-align: middle;
  width: 1em;
  height: 1em;
  margin-right: .5em;
  display: inline-block;
}

.k-scheduler-agendaday {
  float: left;
  margin: 0 .2em 0 0;
  font-size: 3em;
  font-weight: normal;
}

.k-scheduler-agendaweek {
  margin: .4em 0 0;
  font-size: 1.1em;
  font-style: normal;
  display: block;
}

.k-scheduler-agendadate {
  font-size: .8em;
}

.k-scheduler-timecolumn {
  white-space: nowrap;
}

.k-scheduler-edit-form .k-edit-form-container, .k-scheduler-timezones .k-edit-form-container {
  width: 520px;
}

.k-scheduler-edit-form .k-edit-label {
  width: 17%;
}

.k-scheduler-edit-form .k-edit-field {
  width: 77%;
}

.k-scheduler-edit-form .k-textbox[name="title"], .k-scheduler-edit-form textarea.k-textarea {
  width: 100%;
}

.k-scheduler-edit-form textarea.k-textarea {
  resize: vertical;
  min-height: 4em;
}

.k-scheduler-edit-form > .k-edit-box:first-child .k-datetimepicker {
  margin-right: 1em;
}

.k-edit-box {
  float: left;
}

.k-edit-box + .k-edit-box {
  float: right;
}

.k-scheduler-edit-form label + input {
  margin-left: 1em;
}

.k-edit-field > ul.k-reset > li {
  margin: .2em 0 .4em;
  line-height: 2.4;
}

.k-edit-field > ul.k-reset.k-toolbar > li {
  margin: 0;
}

.k-edit-field > ul.k-reset .k-widget {
  margin-left: .8em;
}

.k-edit-field > ul.k-reset .k-numerictextbox, .k-edit-field span.k-recur-interval {
  width: 5em;
}

.k-edit-field > ul.k-reset .k-dropdown, .k-edit-field > ul.k-reset .k-datepicker, div[name="recurrenceRule"] > .k-dropdown {
  width: 9em;
}

.k-scheduler-edit-form .k-edit-buttons .k-scheduler-delete, .k-scheduler-edit-form .k-edit-buttons .k-scheduler-resetSeries {
  float: left;
}

.k-popup-message {
  text-align: center;
  margin: 0;
  padding: 1em 0 2em;
}

.k-scheduler-timezones .k-dropdown:first-child {
  width: 100%;
}

.k-scheduler-timezones .k-dropdown + .k-dropdown {
  margin: .5em 0 .7em;
}

.k-scheduler-refresh {
  float: right;
  padding: 0 .5em;
}

@media (max-width: 480px) {
  .k-scheduler-timecolumn {
    width: 5em;
  }

  .k-scheduler-datecolumn {
    width: 6em;
    overflow: hidden;
  }

  .k-scheduler-timecolumn > div, .k-scheduler-datecolumn > div {
    white-space: normal;
  }
}

.k-scheduler-pdf-export {
  overflow: hidden;
}

.k-pdf-export-shadow .k-scheduler, .k-scheduler-pdf-export .k-scheduler-content, .k-scheduler-pdf-export .k-scheduler-times {
  height: auto !important;
  overflow: visible !important;
}

.k-scheduler-pdf-export .k-scheduler-header {
  padding: 0 !important;
}

.k-scheduler-pdf-export .k-scheduler-header-wrap {
  border-width: 0 !important;
}

.k-scheduler-pdf-export .k-scheduler-header .k-scheduler-table, .k-scheduler-pdf-export .k-scheduler-content .k-scheduler-table {
  width: 100% !important;
}

.k-rtl .k-scheduler .k-scheduler-navigation .k-i-arrow-60-right, .k-rtl .k-scheduler .k-scheduler-navigation .k-i-arrow-60-left {
  transform: scaleX(-1);
}

.k-tooltip {
  box-sizing: border-box;
  z-index: 12000;
  background-position: 0 0;
  background-repeat: repeat-x;
  border-style: solid;
  border-width: 1px;
  flex-flow: row;
  align-items: flex-start;
  min-width: 30px;
  padding: 4px 8px;
  display: inline-flex;
  position: absolute;
}

.k-tooltip-icon {
  flex-shrink: 0;
  margin-inline-end: 4px;
}

.k-tooltip-title {
  display: block;
}

.k-tooltip-content {
  text-overflow: ellipsis;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
}

.k-tooltip-button {
  flex-shrink: 0;
}

.k-callout {
  box-sizing: border-box;
  pointer-events: none;
  border: 6px solid #0000;
  width: 12px;
  height: 12px;
  position: absolute;
}

.k-callout-n {
  border-bottom-color: inherit;
  pointer-events: none;
  top: -1px;
  left: 50%;
  transform: translate(-50%, -100%);
}

.k-callout-e {
  border-left-color: inherit;
  pointer-events: none;
  top: 50%;
  right: -1px;
  transform: translate(100%, -50%);
}

.k-callout-s {
  border-top-color: inherit;
  pointer-events: none;
  bottom: -1px;
  left: 50%;
  transform: translate(-50%, 100%);
}

.k-callout-w {
  border-right-color: inherit;
  pointer-events: none;
  top: 50%;
  left: -1px;
  transform: translate(-100%, -50%);
}

span.k-tooltip {
  position: relative;
}

.k-validator-tooltip {
  white-space: normal;
  z-index: 9999;
  align-items: center;
  width: auto;
  margin-top: 6px;
  display: flex;
}

.k-validator-tooltip .k-callout-n {
  inset-inline-start: 16px;
}

.k-grid .k-validation-tooltip {
  max-width: 300px;
}

.k-splitter {
  height: 300px;
  position: relative;
}

.k-pane > .k-splitter {
  border-width: 0;
  overflow: hidden;
}

.k-splitter .k-pane {
  overflow: hidden;
}

.k-splitter .k-scrollable {
  overflow: auto;
}

.k-splitter .k-pane-loading {
  margin: -8px 0 0 -8px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.k-ghost-splitbar, .k-splitbar {
  -webkit-user-select: none;
  user-select: none;
  border-style: solid;
  outline: 0;
  font-size: 0;
  position: absolute;
}

.k-ghost-splitbar-horizontal, .k-splitbar-horizontal {
  background-repeat: repeat-y;
  border-width: 0 1px;
  width: 5px;
  top: 0;
}

.k-ghost-splitbar-vertical, .k-splitbar-vertical {
  background-repeat: repeat-x;
  border-width: 1px 0;
  height: 5px;
  left: 0;
}

.k-splitbar-draggable-horizontal {
  cursor: w-resize;
}

.k-splitbar-draggable-vertical {
  cursor: n-resize;
}

.k-splitbar .k-resize-handle {
  display: none;
}

.k-restricted-size-horizontal, .k-restricted-size-vertical {
  background-color: red;
}

.k-splitbar-horizontal .k-icon {
  margin-top: -10px;
  position: absolute;
  top: 50%;
}

.k-splitbar-horizontal .k-i-arrow-60-right {
  margin-left: -2px;
}

.k-splitbar-horizontal.k-splitbar-draggable-horizontal .k-i-arrow-60-right {
  margin-top: 13px;
}

.k-splitbar-horizontal .k-i-arrow-60-left {
  margin-left: -3px;
}

.k-splitbar-horizontal.k-splitbar-draggable-horizontal .k-i-arrow-60-left {
  margin-top: -28px;
}

.k-splitbar-vertical .k-i-arrow-60-up, .k-splitbar-vertical .k-i-arrow-60-down {
  margin-top: -2px;
}

.k-splitbar-vertical.k-splitbar-draggable-vertical .k-i-arrow-60-up {
  margin-left: -17px;
}

.k-splitbar-vertical.k-splitbar-draggable-vertical .k-i-arrow-60-down {
  margin-left: 23px;
}

.k-i-arrow-60-up, .k-i-arrow-60-right, .k-i-arrow-60-down, .k-i-arrow-60-left {
  cursor: pointer;
}

.k-splitbar-horizontal .k-icon {
  width: 7px;
  height: 20px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}

.k-splitbar-static-horizontal {
  width: 1px;
}

.k-splitbar-static-vertical {
  height: 1px;
}

.k-splitbar-vertical .k-icon {
  position: absolute;
  left: 50%;
}

.k-splitbar-draggable-vertical .k-resize-handle, .k-splitbar-draggable-horizontal .k-resize-handle {
  border-radius: 1px;
  display: inline-block;
}

.k-splitbar-draggable-horizontal .k-resize-handle {
  width: 3px;
  height: 20px;
}

.k-splitbar-draggable-vertical .k-resize-handle {
  width: 20px;
  height: 3px;
}

.k-splitbar .k-i-arrow-60-up, .k-splitbar .k-i-arrow-60-right, .k-splitbar .k-i-arrow-60-down, .k-splitbar .k-i-arrow-60-left {
  font-size: 10px;
}

.k-splitter-resizing {
  overflow: hidden;
}

.k-rtl .k-splitbar-horizontal .k-i-arrow-60-right {
  margin-top: 13px;
  margin-right: -3px;
}

.k-rtl .k-splitbar-horizontal .k-i-arrow-60-left {
  margin-top: -28px;
  margin-right: -2px;
}

.k-rtl .k-splitbar-vertical .k-i-arrow-60-up {
  margin-top: -2px;
  margin-left: -17px;
}

.k-rtl .k-splitbar-vertical .k-i-arrow-60-down {
  margin-top: -2px;
  margin-left: 23px;
}

html .k-upload {
  position: relative;
}

.k-dropzone .k-dropzone-hint, .k-upload-button {
  vertical-align: middle;
}

.k-dropzone, .k-file {
  position: relative;
}

.k-dropzone {
  background-color: #0000;
  border-style: solid;
  border-width: 0;
  padding: .8em;
}

.k-dropzone .k-dropzone-hint {
  margin-left: .6em;
}

.k-dropzone-active .k-dropzone-hint {
  display: inline-block;
}

.k-dropzone-active .k-upload-status {
  display: none;
}

.k-upload-button {
  direction: ltr;
  position: relative;
  overflow: hidden;
}

.k-upload .k-upload-button {
  min-width: 7.167em;
  margin-right: 1.6em;
}

.k-upload-button input {
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  font: 170px monospace !important;
}

.k-upload-files {
  border-style: solid;
  border-width: 1px 0 0;
}

.k-upload-files .k-button {
  padding: 0;
}

.k-upload-status .k-i-warning {
  display: none;
}

.k-upload-status-total .k-icon {
  vertical-align: text-bottom;
  margin-right: 4px;
  display: inline-block;
}

.k-upload-files ~ .k-clear-selected, .k-upload-files ~ .k-upload-selected {
  margin-top: -5px;
}

.k-upload .k-fail {
  background-position: -161px -111px;
}

.k-file {
  white-space: nowrap;
  border-style: solid;
  border-width: 0 0 1px;
  outline: none;
  padding: .65em 1em calc(.65em + 2px);
}

.k-file .k-upload-action {
  box-shadow: none;
  background: none;
  border-width: 0;
  margin-right: 3px;
}

.k-file:last-child {
  border-bottom-width: 0;
}

.k-file .k-icon {
  display: inline-block;
  position: relative;
}

.k-upload-files .k-file {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.k-file-extension-wrapper, .k-file-name-size-wrapper, .k-file-group-wrapper, .k-multiple-files-group-wrapper, .k-multiple-files-extension-wrapper, .k-file-invalid-extension-wrapper, .k-multiple-files-invalid-extension-wrapper {
  display: block;
}

.k-file-extension-wrapper, .k-multiple-files-extension-wrapper, .k-file-invalid-extension-wrapper, .k-multiple-files-invalid-extension-wrapper {
  vertical-align: top;
  text-transform: uppercase;
  border-style: solid;
  border-width: 2px;
  width: 24px;
  height: 34px;
  font-size: .57em;
  position: relative;
}

.k-file-invalid-extension-wrapper, .k-multiple-files-invalid-extension-wrapper {
  font-size: 1.2em;
}

.k-multiple-files-extension-wrapper, .k-multiple-files-invalid-extension-wrapper {
  margin-top: 4px;
}

.k-file-group-wrapper .k-file-group, .k-file-invalid-group-wrapper .k-file-group, .k-multiple-files-group-wrapper .k-file-group, .k-multiple-files-invalid-group-wrapper .k-file-group {
  font-size: 32px;
}

.k-file-group-wrapper .k-file-state, .k-file-invalid-group-wrapper .k-file-state, .k-multiple-files-group-wrapper .k-file-state, .k-multiple-files-invalid-group-wrapper .k-file-state {
  position: absolute;
}

.k-multiple-files-group-wrapper, .k-multiple-files-invalid-group-wrapper {
  vertical-align: top;
}

.k-file-state {
  visibility: hidden;
}

.k-multiple-files-wrapper, .k-file-single > .k-file-name-size-wrapper, .k-file > .k-file-name-size-wrapper {
  flex: 1 0 0;
  margin-left: 1em;
  overflow: hidden;
}

.k-multiple-files-wrapper .k-file-name-size-wrapper {
  margin-bottom: 1em;
}

.k-file-name-size-wrapper {
  vertical-align: middle;
}

.k-file-extension-wrapper:before, .k-multiple-files-extension-wrapper:before, .k-multiple-files-extension-wrapper:after, .k-file-invalid-extension-wrapper:before, .k-multiple-files-invalid-extension-wrapper:before, .k-multiple-files-invalid-extension-wrapper:after {
  content: "";
  border-style: solid;
  display: inline-block;
  position: absolute;
}

.k-file-extension-wrapper:before, .k-multiple-files-extension-wrapper:before, .k-file-invalid-extension-wrapper:before, .k-multiple-files-invalid-extension-wrapper:before {
  border-width: 6px;
  width: 0;
  height: 0;
  margin-top: -1px;
  margin-right: -1px;
  top: -1px;
  right: -1px;
}

.k-multiple-files-extension-wrapper:after, .k-multiple-files-invalid-extension-wrapper:after {
  border-width: 2px 0 0 2px;
  width: 15px;
  height: 35px;
  top: -6px;
  left: -6px;
}

.k-file-extension, .k-file-invalid-icon {
  line-height: normal;
  position: absolute;
  bottom: 0;
}

.k-file-extension {
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: .3em;
  margin-left: .4em;
  overflow: hidden;
}

.k-file-invalid-icon {
  margin-left: 5px;
}

.k-file-name, .k-file-size, .k-file-validation-message, .k-file-information {
  display: block;
}

.k-file-name {
  vertical-align: middle;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
}

.k-file-size, .k-file-information, .k-file-validation-message {
  font-size: .78em;
  line-height: 1.5em;
}

.k-file-information, .k-file-validation-message {
  line-height: 1.5em;
}

.k-file-size {
  line-height: 1.2em;
}

.k-upload-files .k-upload-status {
  opacity: .65;
  line-height: 1;
}

.k-upload-action, .k-button.k-upload-action:hover, .k-button.k-upload-action:active:hover, .k-button.k-upload-action.k-state-active:hover {
  color: inherit;
  background: none;
  border: 0;
}

.k-upload-action {
  opacity: .6;
}

.k-upload-action:hover {
  opacity: 1;
}

.k-upload-action .k-icon {
  vertical-align: top;
}

.k-file .k-upload-status .k-button, .k-file .k-upload-status > .k-icon {
  vertical-align: middle;
  border-radius: 50%;
}

.k-dropzone {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.k-dropzone .k-upload-status {
  margin-left: 10px;
  position: static;
}

.k-upload-pct {
  margin-right: .75em;
}

.k-ie9 .k-upload-status-total {
  top: 1.5em;
}

.k-ie9 .k-upload-status-total > .k-icon {
  margin-top: -3px;
}

.k-upload-action {
  line-height: normal;
}

.k-upload .k-progress {
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.k-upload .k-progressbar {
  width: calc(100% - 2em);
  height: 2px;
  position: absolute;
  bottom: .32em;
}

.k-upload .k-progressbar .k-progress {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.k-file-invalid > .k-progress {
  width: 100%;
}

.k-rtl .k-file-extension-wrapper {
  margin-left: 14px;
}

.k-upload .k-action-buttons {
  border-style: solid;
  border-width: 1px 0 0;
}

.k-no-flexbox .k-upload .k-action-buttons .k-button {
  border-width: 0;
}

.k-mediaplayer {
  position: relative;
}

.k-mediaplayer > video, .k-mediaplayer > iframe {
  vertical-align: top;
}

.k-mediaplayer .k-mediaplayer-overlay {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.k-mediaplayer-titlebar {
  z-index: 2;
  color: #fff;
  background: linear-gradient(#000000b3, #0000000d 80%, #0000);
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.k-mediaplayer-toolbar-wrap {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.k-mediaplayer-toolbar {
  border-width: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
  display: flex;
  position: relative;
}

.k-mediaplayer-toolbar > * {
  align-content: center;
  align-items: center;
  display: inline-flex;
}

.k-mediaplayer-toolbar > * + *, .k-mediaplayer-toolbar .k-align-right > * + * {
  margin-left: 5px;
}

.k-mediaplayer-toolbar .k-mediaplayer-quality {
  background: none;
  width: auto;
  padding: 0;
}

.k-mediaplayer-toolbar .k-mediaplayer-quality .k-dropdown-wrap {
  box-shadow: none;
  color: inherit;
  background: none;
  border: 0;
  padding: 0;
}

.k-mediaplayer-toolbar .k-mediaplayer-quality .k-input {
  display: none;
}

.k-mediaplayer-toolbar .k-mediaplayer-quality .k-select {
  line-height: inherit;
  width: auto;
  min-height: 0;
  position: relative;
}

.k-mediaplayer-volume-wrap, .k-mediaplayer-quality-wrap {
  align-items: center;
  display: inline-flex;
}

.k-quality-list {
  width: 80px !important;
  margin-left: -32px !important;
}

.k-slider.k-mediaplayer-seekbar {
  z-index: 3;
  width: 100%;
  position: absolute;
  top: -17px;
  left: 0;
}

.k-slider.k-mediaplayer-seekbar .k-slider-track {
  border-radius: 0;
  width: 100% !important;
}

.k-slider.k-mediaplayer-seekbar .k-slider-selection {
  border-radius: 0;
}

.k-slider.k-mediaplayer-seekbar .k-draghandle {
  opacity: 0;
  transition: opacity .2s;
}

.k-slider.k-mediaplayer-seekbar .k-draghandle.k-state-selected, .k-slider.k-mediaplayer-seekbar:hover .k-draghandle {
  opacity: 1;
}

.k-mediaplayer-fullscreen {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.k-no-flexbox .k-mediaplayer-toolbar .k-align-right > * {
  vertical-align: middle;
  display: inline-block;
}

.k-no-flexbox .k-mediaplayer-toolbar .k-flat.k-button {
  height: auto;
}

.k-no-flexbox .k-mediaplayer-toolbar .k-toolbar-spacer {
  display: none;
}

.k-toolbar-wrap {
  float: left;
}

.k-toolbar-wrap .k-dropzone em, .k-toolbar-wrap .k-upload-files {
  display: none;
}

.k-toolbar-wrap .k-dropzone {
  border: 0;
  padding: 0;
}

.k-toolbar-wrap .k-dropzone-active {
  text-align: center;
}

.k-toolbar-wrap .k-dropzone-active em {
  margin: 0;
  font-size: 5em;
  font-style: normal;
  display: inline;
}

.k-toolbar-wrap .k-dropzone-active .k-upload-button {
  display: none;
}

.k-filebrowser-dropzone {
  z-index: 10010;
  opacity: .4;
  position: fixed;
}

.k-search-wrap {
  width: 20%;
  padding: 0;
  position: relative;
}

.k-search-wrap label {
  font-style: italic;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 4px;
}

.k-search-wrap input.k-input {
  padding-left: 0;
  padding-right: 0;
}

.k-search-wrap .k-search {
  margin: -8px 0 0;
  position: absolute;
  top: 50%;
  right: 2px;
}

.k-filebrowser .k-filebrowser-toolbar {
  white-space: nowrap;
  border-style: solid;
  border-width: 1px;
  margin: 8px 0 0;
  padding: .25em;
  line-height: 23px;
}

.k-filebrowser .k-tiles-arrange {
  float: right;
}

.k-filebrowser .k-tiles-arrange .k-dropdown {
  width: 5.3em;
}

.k-filebrowser .k-upload {
  background-color: #0000;
  border-width: 0;
}

.k-filebrowser .k-upload .k-upload-status, .k-filebrowser .k-upload .k-upload-files {
  display: none;
}

.k-filebrowser .k-upload .k-upload-button {
  width: auto;
  min-width: auto;
  margin: 0;
}

.k-filebrowser .k-breadcrumb {
  float: left;
  width: 79%;
  margin: 0;
  position: relative;
}

.k-filebrowser .k-filemanager-listview {
  height: 390px;
  max-height: 50vh;
  margin-bottom: 2em;
}

.k-filebrowser .k-filemanager-listview .k-listview-content {
  height: 100%;
}

.k-filebrowser .k-form-text-row {
  text-align: right;
}

.k-filebrowser .k-form-text-row label {
  width: 14%;
}

.k-filebrowser .k-form-text-row input {
  width: 80%;
}

.k-chart, .k-gauge, .k-sparkline, .k-stockchart {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
}

.k-chart, .k-stockchart {
  height: 400px;
}

.k-chart > canvas {
  vertical-align: top;
}

div.k-chart, div.k-gauge, span.k-sparkline, .k-stockchart {
  background-color: #0000;
}

.k-gauge {
  text-align: left;
  position: relative;
}

.k-arcgauge {
  display: inline-block;
}

.k-arcgauge-label {
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
}

.k-baseline-marker {
  zoom: 1;
}

.k-chart-tooltip {
  color: #fff;
  white-space: nowrap;
  z-index: 12000;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAYAAADAQbwGAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAADNJREFUeNpi/P//vwMDFQELEP8beQb+HTWQYgP/DHoD/466cAR4edRAyg38P6hLbIAAAwCnWhhVsxvdCAAAAABJRU5ErkJggg==");
  background-position: 0 0;
  background-repeat: repeat-x;
  border-radius: 4px;
  padding: 4px 8px;
  line-height: normal;
}

.k-chart-tooltip table {
  border-spacing: 0;
  border-collapse: collapse;
}

.k-chart-tooltip th {
  text-align: center;
  width: auto;
  padding: 1px;
}

.k-chart-tooltip td {
  text-align: left;
  width: auto;
  padding: .1em .2em;
}

.k-chart-shared-tooltip-marker {
  width: 15px;
  height: 3px;
  display: block;
}

.k-selector {
  position: absolute;
  -webkit-transform: translateZ(0);
}

.k-selection {
  border: 1px solid #d2d2d2;
  border-bottom: 0;
  height: 100%;
  position: absolute;
}

.k-selection-bg {
  background-color: #ffffff03;
  width: 100%;
  height: 100%;
  position: absolute;
}

.k-handle {
  cursor: e-resize;
  z-index: 1;
  background: #d2d2d2;
  border-radius: 6px;
  width: 7px;
  height: 26px;
  position: absolute;
}

.k-handle div {
  background-color: #0000;
  width: 100%;
  height: 100%;
}

.k-left-handle {
  left: -4px;
}

.k-right-handle {
  right: -4px;
}

.k-left-handle div {
  margin: -20px 0 0 -15px;
  padding: 40px 30px 0 0;
}

.k-left-handle.k-handle-active div {
  margin-left: -40px;
  padding-right: 55px;
}

.k-right-handle div {
  margin: -20px 0 0 -15px;
  padding: 40px 0 0 30px;
}

.k-right-handle.k-handle-active div {
  padding-left: 55px;
}

.k-mask {
  opacity: .8;
  background-color: #fff;
  height: 100%;
  position: absolute;
}

.k-navigator-hint div {
  position: absolute;
}

.k-navigator-hint .k-scroll {
  background: #d2d2d2;
  border-radius: 4px;
  height: 4px;
  position: absolute;
}

.k-navigator-hint .k-tooltip {
  opacity: 1;
  text-align: center;
  background: #fff;
  border: 0;
  min-width: 160px;
  margin-top: 20px;
  box-shadow: 0 1px 2px #00000080;
}

.k-sparkline, .k-sparkline span {
  vertical-align: top;
  display: inline-block;
}

.k-sparkline span {
  width: 100%;
  height: 100%;
}

.k-map, .k-diagram {
  height: 600px;
}

.k-map .km-scroll-wrapper, .k-diagram .km-scroll-wrapper {
  -webkit-user-select: none;
  user-select: none;
  padding-bottom: 0;
}

.k-map .km-scroll-wrapper, .k-diagram .km-scroll-wrapper, .k-map .km-scroll-container, .k-diagram .km-scroll-container {
  width: 100%;
  height: 100%;
  position: absolute;
}

.k-map .k-layer, .k-diagram .k-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.k-map .km-touch-scrollbar, .k-diagram .km-touch-scrollbar {
  display: none;
}

.k-map {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
}

.k-map .k-map-controls {
  position: absolute;
}

.k-map .k-pos-top {
  top: 0;
}

.k-map .k-pos-bottom {
  bottom: 0;
}

.k-map .k-pos-left {
  left: 0;
}

.k-map .k-pos-right {
  right: 0;
}

.k-map .k-navigator {
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-radius: 80px;
  width: 50px;
  height: 50px;
  margin: 1em;
  display: inline-block;
  position: relative;
}

.k-pdf-export .k-map .k-navigator {
  display: none;
}

.k-map .k-zoom-control {
  vertical-align: middle;
  margin: 1em;
}

.k-map .k-zoom-control .k-button {
  width: auto;
  height: auto;
}

.k-pdf-export .k-map .k-zoom-control {
  display: none;
}

.k-map .k-attribution {
  z-index: 1000;
  background-color: #fffc;
  padding: 2px 4px;
  font-size: 10px;
}

.k-map .k-marker {
  cursor: pointer;
  width: 28px;
  height: 40px;
  margin: -40px 0 0 -14px;
  font-size: 28px;
  position: absolute;
  overflow: visible;
}

.k-navigator > .k-button {
  background: none;
  border-color: #0000;
  border-radius: 16px;
  margin: 0;
  padding: 0;
  line-height: 10px;
  position: absolute;
}

.k-navigator .k-navigator-n, .k-navigator .k-navigator-up {
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.k-navigator .k-navigator-e, .k-navigator .k-navigator-right {
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
}

.k-navigator .k-navigator-s, .k-navigator .k-navigator-down {
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%);
}

.k-navigator .k-navigator-w, .k-navigator .k-navigator-left {
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
}

.k-pdf-export .k-navigator {
  display: none;
}

.k-button-wrap {
  border-radius: 4px;
  display: inline-block;
}

.k-button-wrap .k-button {
  padding: 4px;
  line-height: 16px;
  position: relative;
}

.k-button-wrap .k-button .k-icon {
  vertical-align: top;
}

.k-buttons-horizontal :first-child {
  border-radius: 4px 0 0 4px;
}

.k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: -1px;
}

.k-buttons-horizontal .k-zoom-out {
  border-radius: 0 4px 4px 0;
  margin-left: -1px;
}

.k-button-wrap .k-button:hover {
  z-index: 1;
}

.k-buttons-vertical .k-button {
  display: block;
}

.k-buttons-vertical :first-child {
  border-radius: 4px 4px 0 0;
}

.k-buttons-vertical .k-zoom-out {
  border-radius: 0 0 4px 4px;
  margin-top: -1px;
}

.k-zoom-text {
  vertical-align: top;
  width: 4.3em;
  margin: 0;
}

.k-rtl .k-buttons-horizontal :first-child {
  border-radius: 0 4px 4px 0;
}

.k-rtl .k-buttons-horizontal :first-child + .k-zoom-in {
  border-radius: 0;
  margin-left: 0;
  margin-right: -1px;
}

.k-rtl .k-buttons-horizontal .k-zoom-out {
  border-radius: 4px 0 0 4px;
  margin-left: 0;
  margin-right: -1px;
}

.k-diagram {
  height: 600px;
}

.k-diagram .km-scroll-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.k-canvas-container {
  width: 100%;
  height: 100%;
}

.k-diagram img {
  box-sizing: content-box;
}

.k-treemap {
  height: 400px;
  overflow: hidden;
}

.k-treemap-tile {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  margin: -1px 0 0 -1px;
  position: absolute;
  overflow: hidden;
}

.k-treemap-tile.k-leaf {
  padding: .6em;
}

.k-treemap-wrap.k-last > .k-treemap-tile {
  padding: .3em;
}

.k-treemap-tile.k-state-hover {
  z-index: 2;
  background-image: none;
}

.k-treemap > .k-treemap-tile {
  height: 100%;
  position: relative;
}

.k-treemap-title {
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 2.42em;
  padding: 0 .6em;
  line-height: 2.42em;
  overflow: hidden;
}

.k-treemap-wrap .k-treemap-title {
  border-style: solid;
  border-width: 0 0 1px;
}

.k-treemap-wrap {
  position: absolute;
  inset: 0;
}

.k-treemap-title + .k-treemap-wrap {
  top: 2.42em;
}

.k-treemap-title-vertical {
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 2.42em;
  padding: .6em 0;
  line-height: 2.42em;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.k-treemap-title-vertical > div {
  transform-origin: 100%;
  position: absolute;
  top: 0;
  right: 1.23em;
  transform: rotate(-90deg);
}

.k-treemap-title-vertical + .k-treemap-wrap {
  left: 2.42em;
}

.k-pane-wrapper {
  font-size: 14px;
  position: relative;
}

.k-pane-wrapper .k-pane {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  overflow-x: hidden;
}

.k-pane-wrapper .k-view {
  vertical-align: top;
  border: 0;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
}

.k-pane-wrapper .k-content {
  flex: 1;
  align-items: stretch;
  width: auto;
  min-height: 1px;
  display: block;
  position: relative;
  overflow: hidden;
}

.k-pane-wrapper .k-grid-edit-form > .k-header, .k-pane-wrapper .k-grid-column-menu > .k-header, .k-pane-wrapper .k-grid-filter-menu > .k-header, .k-pane-wrapper .k-scheduler-edit-form > .k-header {
  justify-content: space-between;
  width: auto;
  padding: .3em .6em;
  line-height: 2em;
  display: flex;
}

.k-pane-wrapper .k-grid-edit-form > .k-header .k-header-done .k-icon, .k-pane-wrapper .k-grid-column-menu > .k-header .k-header-done .k-icon, .k-pane-wrapper .k-grid-filter-menu > .k-header .k-header-done .k-icon, .k-pane-wrapper .k-scheduler-edit-form > .k-header .k-header-done .k-icon, .k-pane-wrapper .k-grid-edit-form > .k-header .k-header-cancel .k-icon, .k-pane-wrapper .k-grid-column-menu > .k-header .k-header-cancel .k-icon, .k-pane-wrapper .k-grid-filter-menu > .k-header .k-header-cancel .k-icon, .k-pane-wrapper .k-scheduler-edit-form > .k-header .k-header-cancel .k-icon {
  font-size: 1.5em;
}

.k-pane-wrapper .k-grid-edit-row .k-textbox {
  box-sizing: border-box;
}

.k-ie .k-pane-wrapper .k-scheduler .k-scheduler-toolbar, .k-ie .k-pane-wrapper .k-scheduler .k-scheduler-footer {
  line-height: 2em;
}

.k-pane-wrapper > div.k-pane {
  box-shadow: none;
  font-weight: normal;
}

.k-pane-wrapper .k-popup-edit-form .k-content, .k-pane-wrapper .k-grid-edit-form .k-content, .k-pane-wrapper .k-grid-column-menu .k-content, .k-pane-wrapper .k-grid-filter-menu .k-content {
  overflow-y: auto;
}

.k-pane-wrapper .k-popup-edit-form .k-content > .k-scroll-container, .k-pane-wrapper .k-grid-edit-form .k-content > .k-scroll-container, .k-pane-wrapper .k-grid-column-menu .k-content > .k-scroll-container, .k-pane-wrapper .k-grid-filter-menu .k-content > .k-scroll-container {
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  position: absolute;
}

.k-pane-wrapper .k-grid-filter-menu .k-filter-selected-items {
  margin: 1em;
  font-weight: normal;
}

.k-pane-wrapper .k-grid-edit-form .k-popup-edit-form, .k-pane-wrapper .k-grid-edit-form .k-edit-form-container {
  width: auto;
}

.k-pane-wrapper .k-grid .k-grid-search {
  width: 100%;
}

.k-grid-mobile {
  border-width: 0;
}

.k-grid-mobile .k-resize-handle-inner:before {
  content: "";
  padding: .2em;
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-grid-mobile .k-edit-cell > input, .k-grid-mobile .k-edit-cell > select, .k-grid-mobile .k-grid-edit-row > td > input, .k-grid-mobile .k-grid-edit-row > td > select {
  box-sizing: border-box;
  width: 100%;
}

.k-grid-mobile .k-header a {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
}

.k-pane-wrapper .k-scheduler-edit-form .k-recur-view {
  flex-direction: column;
  align-items: stretch;
  padding: 0;
}

.k-pane-wrapper .k-scheduler-edit-form .k-recur-view > .k-listgroup-form-row {
  margin: 0;
}

.k-pane-wrapper .k-scheduler-edit-form .k-recur-items-wrap {
  width: 100%;
  margin: -1px 0;
}

.k-pane-wrapper .k-scheduler-edit-form .k-scheduler-recur-end-wrap {
  white-space: nowrap;
}

.k-scheduler-mobile {
  border-width: 0;
}

.k-scheduler-mobile th {
  font-weight: normal;
}

.k-scheduler-mobile .k-scheduler-toolbar > * {
  margin: 0;
}

.k-scheduler-mobile .k-scheduler-toolbar:before {
  display: none;
}

.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation {
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  display: flex;
}

.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current {
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
}

.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-y-date-format {
  font-size: 12px;
}

.k-scheduler-mobile .k-scheduler-toolbar .k-scheduler-navigation .k-nav-current {
  flex-direction: column;
}

.k-scheduler-mobile .k-scheduler-footer {
  justify-content: space-between;
  display: flex;
}

.k-scheduler-mobile .k-scheduler-footer > * {
  margin: 0;
}

.k-scheduler-mobile .k-scheduler-footer:before {
  display: none;
}

.k-scheduler-mobile .k-scheduler-monthview .k-hidden {
  height: 40px;
}

.k-scheduler-mobile .k-scheduler-monthview .k-scheduler-table td {
  vertical-align: top;
  text-align: center;
  height: 40px;
}

.k-scheduler-mobile .k-scheduler-monthview .k-events-container {
  text-align: center;
  height: 6px;
  line-height: 6px;
  position: absolute;
}

.k-scheduler-mobile .k-scheduler-monthview .k-event {
  width: 4px;
  height: 4px;
  min-height: 0;
  margin: 1px;
  display: inline-block;
  position: static;
}

.k-scheduler-mobile .k-scheduler-dayview .k-mobile-header.k-mobile-horizontal-header .k-scheduler-times table tr:first-child, .k-scheduler-mobile .k-scheduler-dayview .k-mobile-header .k-scheduler-header .k-scheduler-date-group, .k-scheduler-mobile .k-scheduler-agendaview .k-mobile-header {
  display: none;
}

.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table {
  table-layout: auto;
}

.k-scheduler-mobile .k-scheduler-agendaview .k-scheduler-table .k-scheduler-groupcolumn {
  width: 1%;
}

.k-scheduler-mobile .k-mobile-header .k-scheduler-table td, .k-scheduler-mobile .k-mobile-header .k-scheduler-table th {
  height: 1.5em;
}

.k-scheduler-mobile .k-scheduler-table td, .k-scheduler-mobile .k-scheduler-table th {
  vertical-align: middle;
  height: 2em;
}

.k-scheduler-mobile .k-scheduler-times th {
  font-size: 1em;
}

.k-scheduler-mobile .k-scheduler-datecolumn-wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.k-scheduler-mobile .k-task {
  align-items: center;
  display: flex;
}

.k-scheduler-mobile .k-task .k-scheduler-mark {
  border-radius: 50%;
}

.k-scheduler-mobile .k-task .k-i-reload {
  margin-right: .5em;
  font-size: 1em;
}

.k-scheduler-mobile .k-task .k-scheduler-task-text {
  flex: 1;
}

.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell, .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell, .k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn, .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn {
  vertical-align: top;
}

.k-scheduler-mobile .k-scheduler-times .k-scheduler-group-cell .k-scheduler-group-text, .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-group-cell .k-scheduler-group-text, .k-scheduler-mobile .k-scheduler-times .k-scheduler-groupcolumn .k-scheduler-group-text, .k-scheduler-mobile .k-scheduler-agenda .k-scheduler-groupcolumn .k-scheduler-group-text {
  writing-mode: vertical-lr;
  white-space: nowrap;
  transform: rotate(180deg);
}

.k-scheduler-mobile .k-scrollbar-h tr + tr .k-scheduler-times {
  border-bottom-width: 0;
}

.k-pane-wrapper .k-pane * {
  background-clip: border-box;
}

.k-pane-wrapper .k-list-title, .k-pane-wrapper .k-filter-help-text {
  padding: .5em 1em;
  display: block;
}

.k-pane-wrapper .k-listgroup .k-listgroup-item {
  border-color: inherit;
}

.k-pane-wrapper .k-listgroup + .k-listgroup {
  margin-top: 2em;
}

.k-pane-wrapper .k-column-menu .k-filter-item .k-filterable * {
  pointer-events: none;
}

.k-pane-wrapper .k-filter-menu {
  padding: 0;
}

.k-pane-wrapper .k-filter-menu .k-space-right {
  background: 0;
  border: 0;
  padding: 0 10px;
}

.k-pane-wrapper .k-filter-menu .k-space-right > input {
  text-indent: 1em;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  width: 100%;
  height: 2em;
  margin: 0;
  padding: 1px 0;
}

.k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom {
  z-index: 2;
  right: 15px;
}

.k-pane-wrapper .k-filter-menu .k-filter-tools {
  justify-content: space-between;
  margin: 1em;
  display: flex;
}

.k-pane-wrapper .k-popup-edit-form .k-recur-editor-wrap {
  display: flex;
}

.k-pane-wrapper .k-popup-edit-form .k-mobiletimezoneeditor {
  flex: auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: relative;
}

.k-pane-wrapper .k-multicheck-wrap.k-listgroup {
  overflow-y: auto;
}

.k-pane-wrapper .k-state-disabled {
  opacity: 1;
}

.k-pane-wrapper .k-state-disabled > * {
  opacity: .7;
}

.k-ie .k-pane-wrapper .k-grid .k-icon {
  text-indent: 0;
}

.k-pane-wrapper .k-pager-wrap.k-pager-sm {
  justify-content: center;
}

.k-pane-wrapper .k-pager-wrap.k-pager-sm .k-pager-refresh {
  display: none;
}

.km-scroll-container {
  -webkit-user-select: none;
  user-select: none;
  -webkit-margin-collapse: separate;
  -webkit-transform: translateZ(0);
}

.k-widget .km-scroll-wrapper {
  padding-bottom: 0;
  position: relative;
}

.km-touch-scrollbar {
  visibility: hidden;
  z-index: 200000;
  opacity: 0;
  background-color: #000000b3;
  width: .3em;
  height: .3em;
  transition: opacity .3s linear;
  position: absolute;
}

.k-rtl .k-pane-wrapper .k-grid-edit-form > .k-header, .k-pane-wrapper[dir="rtl"] .k-grid-edit-form > .k-header, [dir="rtl"] .k-pane-wrapper .k-grid-edit-form > .k-header, .k-rtl .k-pane-wrapper .k-grid-column-menu > .k-header, .k-pane-wrapper[dir="rtl"] .k-grid-column-menu > .k-header, [dir="rtl"] .k-pane-wrapper .k-grid-column-menu > .k-header, .k-rtl .k-pane-wrapper .k-grid-filter-menu > .k-header, .k-pane-wrapper[dir="rtl"] .k-grid-filter-menu > .k-header, [dir="rtl"] .k-pane-wrapper .k-grid-filter-menu > .k-header, .k-rtl .k-pane-wrapper .k-scheduler-edit-form > .k-header, .k-pane-wrapper[dir="rtl"] .k-scheduler-edit-form > .k-header, [dir="rtl"] .k-pane-wrapper .k-scheduler-edit-form > .k-header {
  flex-direction: row-reverse;
}

.k-rtl .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom, .k-pane-wrapper[dir="rtl"] .k-filter-menu .k-space-right > .k-i-zoom, [dir="rtl"] .k-pane-wrapper .k-filter-menu .k-space-right > .k-i-zoom {
  left: 15px;
  right: auto;
}

.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li, .k-pane-wrapper[dir="rtl"] .k-scheduler-mobile .k-scheduler-toolbar > ul > li, [dir="rtl"] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar > ul > li {
  border: 0;
}

.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-prev, .k-pane-wrapper[dir="rtl"] .k-scheduler-mobile .k-scheduler-toolbar .k-nav-prev, [dir="rtl"] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-prev, .k-rtl .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-next, .k-pane-wrapper[dir="rtl"] .k-scheduler-mobile .k-scheduler-toolbar .k-nav-next, [dir="rtl"] .k-pane-wrapper .k-scheduler-mobile .k-scheduler-toolbar .k-nav-next {
  transform: scaleX(-1);
}

.k-rtl .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload, .k-pane-wrapper[dir="rtl"] .k-scheduler-mobile .k-task .k-i-reload, [dir="rtl"] .k-pane-wrapper .k-scheduler-mobile .k-task .k-i-reload {
  margin-left: .5em;
  margin-right: 0;
}

.k-rtl .k-pane-wrapper .k-scheduler-edit-form .k-item .k-i-arrow-chevron-right, .k-pane-wrapper[dir="rtl"] .k-scheduler-edit-form .k-item .k-i-arrow-chevron-right, [dir="rtl"] .k-pane-wrapper .k-scheduler-edit-form .k-item .k-i-arrow-chevron-right {
  transform: scaleX(-1);
}

.k-fx-end .k-fx-next, .k-fx-end .k-fx-current {
  transition: all .35s ease-out;
}

.k-fx {
  position: relative;
}

.k-fx .k-fx-current {
  z-index: 0;
}

.k-fx .k-fx-next {
  z-index: 1;
}

.k-fx-hidden, .k-fx-hidden * {
  visibility: hidden !important;
}

.k-fx-reverse .k-fx-current {
  z-index: 1;
}

.k-fx-reverse .k-fx-next {
  z-index: 0;
}

.k-fx-zoom.k-fx-start .k-fx-next {
  transform: scale(0) !important;
}

.k-fx-zoom.k-fx-end .k-fx-next, .k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-next, .k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-next, .k-fx-zoom.k-fx-reverse.k-fx-start .k-fx-current {
  transform: scale(1) !important;
}

.k-fx-zoom.k-fx-reverse.k-fx-end .k-fx-current {
  transform: scale(0) !important;
}

.k-fx-fade.k-fx-start .k-fx-next {
  will-change: opacity;
  opacity: 0;
}

.k-fx-fade.k-fx-end .k-fx-next {
  opacity: 1;
}

.k-fx-fade.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: opacity;
  opacity: 1;
}

.k-fx-fade.k-fx-reverse.k-fx-end .k-fx-current {
  opacity: 0;
}

.k-fx-slide.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-content, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer, .k-fx-slide.k-fx-end .k-fx-current .km-content, .k-fx-slide.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-end .k-fx-current .km-footer {
  transition: all .35s ease-out;
}

.k-fx-slide.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-start .k-fx-next .km-content {
  will-change: transform;
  transform: translateX(100%);
}

.k-fx-slide.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-start .k-fx-next .km-footer {
  will-change: opacity;
  opacity: 0;
}

.k-fx-slide.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}

.k-fx-slide.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  will-change: transform;
  transform: translateX(0);
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0);
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-current .km-footer {
  will-change: opacity;
  opacity: 1;
}

.k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-start .k-fx-next .km-footer {
  opacity: 1;
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-current .km-footer {
  opacity: 0;
}

.k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .k-footer, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-header, .k-fx-slide.k-fx-reverse.k-fx-end .k-fx-next .km-footer {
  opacity: 1;
}

.k-fx-slide.k-fx-right.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-start .k-fx-next .km-content {
  transform: translateX(-100%);
}

.k-fx-slide.k-fx-right.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-end .k-fx-current .km-content {
  transform: translateX(100%);
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current .km-content {
  transform: translateX(0);
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current .km-content {
  transform: translateX(-100%);
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next .km-content {
  transform: translateX(100%);
}

.k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .k-content, .k-fx-slide.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next .km-content {
  transform: translateX(0%);
}

.k-fx-tile.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}

.k-fx-tile.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}

.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: translateX(0);
}

.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(100%);
}

.k-fx-tile.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}

.k-fx-tile.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0);
}

.k-fx-tile.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}

.k-fx-tile.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(100%);
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-current {
  transform: translateX(0);
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-start .k-fx-next {
  transform: translateX(100%);
}

.k-fx-tile.k-fx-right.k-fx-reverse.k-fx-end .k-fx-next {
  transform: translateX(0%);
}

.k-fx.k-fx-overlay.k-fx-start .k-fx-next, .k-fx.k-fx-overlay.k-fx-left.k-fx-start .k-fx-next {
  will-change: transform;
  transform: translateX(100%);
}

.k-fx.k-fx-overlay.k-fx-right.k-fx-start .k-fx-next {
  transform: translateX(-100%);
}

.k-fx.k-fx-overlay.k-fx-up.k-fx-start .k-fx-next {
  transform: translateY(100%);
}

.k-fx.k-fx-overlay.k-fx-down.k-fx-start .k-fx-next {
  transform: translateY(-100%);
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-next {
  transform: none;
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-start .k-fx-current {
  will-change: transform;
  transform: none;
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-end .k-fx-current, .k-fx.k-fx-overlay.k-fx-reverse.k-fx-left.k-fx-end .k-fx-current {
  transform: translateX(100%);
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-right.k-fx-end .k-fx-current {
  transform: translateX(-100%);
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-up.k-fx-end .k-fx-current {
  transform: translateY(100%);
}

.k-fx.k-fx-overlay.k-fx-reverse.k-fx-down.k-fx-end .k-fx-current {
  transform: translateY(-100%);
}

.k-virtual-wrap {
  position: relative;
}

.k-virtual-wrap .k-list.k-virtual-list {
  height: auto;
}

.k-virtual-content {
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow-y: scroll;
}

.k-virtual-list > .k-virtual-content {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  top: 0;
}

.k-virtual-option-label {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.k-virtual-wrap > .k-virtual-header {
  text-align: right;
}

.k-popup .k-item.k-first {
  position: relative;
}

.k-virtual-content > .k-virtual-list > .k-virtual-item {
  box-sizing: border-box;
  white-space: nowrap;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.k-popup .k-list .k-item > .k-group, .k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
  text-transform: uppercase;
  font-size: .857em;
}

.k-popup .k-list .k-item > .k-group {
  padding: 0 .5em;
  line-height: 1.8;
  position: absolute;
  top: 0;
  right: 0;
}

.k-popup .k-state-hover > .k-group {
  right: -1px;
}

.k-virtual-item.k-first, .k-group-header + .k-list > .k-item.k-first, .k-static-header + .k-list > .k-item.k-first {
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 0;
}

.k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
  text-align: right;
}

@font-face {
  font-family: WebComponentsIcons;
  src: url("WebComponentsIcons.8d5b0358.eot");
  src: url("WebComponentsIcons.8d5b0358.eot#iefix") format("embedded-opentype"), url("WebComponentsIcons.fb08cfcd.ttf") format("truetype"), url("WebComponentsIcons.70fcb8b2.woff") format("woff"), url("WebComponentsIcons.f0a64f5c.svg#WebComponentsIcons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.k-i-arrowhead-e:before {
  content: "";
}

.k-i-arrowhead-w:before {
  content: "";
}

.k-i-arrow-45-up-right:before, .k-i-collapse-ne:before, .k-i-resize-ne:before {
  content: "";
}

.k-i-arrow-45-down-right:before, .k-i-collapse-se:before, .k-i-collapse:before, .k-i-resize-se:before {
  content: "";
}

.k-i-arrow-45-down-left:before, .k-i-collapse-sw:before, .k-i-resize-sw:before {
  content: "";
}

.k-i-arrow-45-up-left:before, .k-i-collapse-nw:before, .k-i-resize-new:before {
  content: "";
}

.k-i-arrow-60-up:before, .k-i-arrow-n:before, .k-i-kpi-trend-increase:before {
  content: "";
}

.k-i-arrow-60-right:before, .k-i-arrow-e:before, .k-i-expand:before {
  content: "";
}

.k-i-arrow-60-down:before, .k-i-arrow-s:before, .k-i-kpi-trend-decrease:before {
  content: "";
}

.k-i-arrow-60-left:before, .k-i-arrow-w:before {
  content: "";
}

.k-i-arrow-end-up:before, .k-i-seek-n:before {
  content: "";
}

.k-i-arrow-end-right:before, .k-i-seek-e:before {
  content: "";
}

.k-i-arrow-end-down:before, .k-i-seek-s:before {
  content: "";
}

.k-i-arrow-end-left:before, .k-i-seek-w:before {
  content: "";
}

.k-i-arrow-double-60-up:before, .k-i-arrow-seek-up:before {
  content: "";
}

.k-i-arrow-double-60-right:before, .k-i-arrow-seek-right:before, .k-i-forward-sm:before {
  content: "";
}

.k-i-arrow-double-60-down:before, .k-i-arrow-seek-down:before {
  content: "";
}

.k-i-arrow-double-60-left:before, .k-i-arrow-seek-left:before, .k-i-rewind-sm:before {
  content: "";
}

.k-i-arrows-kpi:before, .k-i-kpi:before {
  content: "";
}

.k-i-arrows-no-change:before {
  content: "";
}

.k-i-arrow-overflow-down:before {
  content: "";
}

.k-i-arrow-chevron-up:before {
  content: "";
}

.k-i-arrow-chevron-right:before {
  content: "";
}

.k-i-arrow-chevron-down:before {
  content: "";
}

.k-i-arrow-chevron-left:before {
  content: "";
}

.k-i-arrow-up:before {
  content: "";
}

.k-i-arrow-right:before {
  content: "";
}

.k-i-arrow-down:before {
  content: "";
}

.k-i-arrow-left:before {
  content: "";
}

.k-i-arrow-drill:before {
  content: "";
}

.k-i-arrow-parent:before {
  content: "";
}

.k-i-arrow-root:before {
  content: "";
}

.k-i-arrows-resizing:before {
  content: "";
}

.k-i-arrows-dimensions:before {
  content: "";
}

.k-i-arrows-swap:before {
  content: "";
}

.k-i-drag-and-drop:before {
  content: "";
}

.k-i-categorize:before {
  content: "";
}

.k-i-grid:before {
  content: "";
}

.k-i-grid-layout:before {
  content: "";
}

.k-i-group:before {
  content: "";
}

.k-i-ungroup:before {
  content: "";
}

.k-i-handler-drag:before {
  content: "";
}

.k-i-layout:before {
  content: "";
}

.k-i-layout-1-by-4:before {
  content: "";
}

.k-i-layout-2-by-2:before {
  content: "";
}

.k-i-layout-side-by-side:before {
  content: "";
}

.k-i-layout-stacked:before {
  content: "";
}

.k-i-columns:before {
  content: "";
}

.k-i-rows:before {
  content: "";
}

.k-i-reorder:before {
  content: "";
}

.k-i-menu:before {
  content: "";
}

.k-i-more-vertical:before {
  content: "";
}

.k-i-more-horizontal:before {
  content: "";
}

.k-i-undo:before {
  content: "";
}

.k-i-redo:before {
  content: "";
}

.k-i-reset:before {
  content: "";
}

.k-i-reload:before, .k-i-refresh:before, .k-i-recurrence:before {
  content: "";
}

.k-i-non-recurrence:before {
  content: "";
}

.k-i-reset-sm:before {
  content: "";
}

.k-i-reload-sm:before, .k-i-refresh-sm:before, .k-i-recurrence-sm:before {
  content: "";
}

.k-i-clock:before {
  content: "";
}

.k-i-calendar:before {
  content: "";
}

.k-i-save:before, .k-i-floppy:before {
  content: "";
}

.k-i-print:before, .k-i-printer:before {
  content: "";
}

.k-i-edit:before, .k-i-pencil:before {
  content: "";
}

.k-i-delete:before, .k-i-trash:before {
  content: "";
}

.k-i-attachment:before, .k-i-clip:before {
  content: "";
}

.k-i-attachment-45:before, .k-i-clip-45:before {
  content: "";
}

.k-i-link-horizontal:before, .k-i-hyperlink:before {
  content: "";
}

.k-i-unlink-horizontal:before, .k-i-hyperlink-remove:before {
  content: "";
}

.k-i-link-vertical:before {
  content: "";
}

.k-i-unlink-vertical:before {
  content: "";
}

.k-i-lock:before {
  content: "";
}

.k-i-unlock:before {
  content: "";
}

.k-i-cancel:before {
  content: "";
}

.k-i-cancel-outline:before {
  content: "";
}

.k-i-cancel-circle:before {
  content: "";
}

.k-i-check:before, .k-i-checkmark:before, .k-i-tick:before {
  content: "";
}

.k-i-check-outline:before, .k-i-checkmark-outline:before, .k-i-success:before {
  content: "";
}

.k-i-check-circle:before, .k-i-checkmark-circle:before {
  content: "";
}

.k-i-close:before, .k-i-x:before {
  content: "";
}

.k-i-close-outline:before, .k-i-x-outline:before, .k-i-error:before {
  content: "";
}

.k-i-close-circle:before, .k-i-x-circle:before {
  content: "";
}

.k-i-plus:before, .k-i-add:before {
  content: "";
}

.k-i-plus-outline:before {
  content: "";
}

.k-i-plus-circle:before {
  content: "";
}

.k-i-minus:before, .k-i-kpi-trend-equal:before {
  content: "";
}

.k-i-minus-outline:before {
  content: "";
}

.k-i-minus-circle:before {
  content: "";
}

.k-i-sort-asc:before {
  content: "";
}

.k-i-sort-desc:before {
  content: "";
}

.k-i-unsort:before, .k-i-sort-clear:before {
  content: "";
}

.k-i-sort-asc-sm:before {
  content: "";
}

.k-i-sort-desc-sm:before {
  content: "";
}

.k-i-filter:before {
  content: "";
}

.k-i-filter-clear:before {
  content: "";
}

.k-i-filter-sm:before {
  content: "";
}

.k-i-filter-sort-asc-sm:before {
  content: "";
}

.k-i-filter-sort-desc-sm:before {
  content: "";
}

.k-i-filter-add-expression:before {
  content: "";
}

.k-i-filter-add-group:before {
  content: "";
}

.k-i-login:before {
  content: "";
}

.k-i-logout:before {
  content: "";
}

.k-i-download:before {
  content: "";
}

.k-i-upload:before {
  content: "";
}

.k-i-hyperlink-open:before {
  content: "";
}

.k-i-hyperlink-open-sm:before {
  content: "";
}

.k-i-launch:before {
  content: "";
}

.k-i-window:before, .k-i-window-maximize:before {
  content: "";
}

.k-i-windows:before, .k-i-window-restore:before, .k-i-tiles:before {
  content: "";
}

.k-i-window-minimize:before {
  content: "";
}

.k-i-gear:before, .k-i-cog:before, .k-i-custom:before {
  content: "";
}

.k-i-gears:before, .k-i-cogs:before {
  content: "";
}

.k-i-wrench:before, .k-i-settings:before {
  content: "";
}

.k-i-preview:before, .k-i-eye:before {
  content: "";
}

.k-i-zoom:before, .k-i-search:before {
  content: "";
}

.k-i-zoom-in:before {
  content: "";
}

.k-i-zoom-out:before {
  content: "";
}

.k-i-pan:before, .k-i-move:before {
  content: "";
}

.k-i-calculator:before {
  content: "";
}

.k-i-cart:before, .k-i-shopping-cart:before {
  content: "";
}

.k-i-connector:before {
  content: "";
}

.k-i-plus-sm:before {
  content: "";
}

.k-i-minus-sm:before {
  content: "";
}

.k-i-kpi-status-deny:before {
  content: "";
}

.k-i-kpi-status-hold:before {
  content: "";
}

.k-i-kpi-status-open:before {
  content: "";
}

.k-i-play:before {
  content: "";
}

.k-i-pause:before {
  content: "";
}

.k-i-stop:before {
  content: "";
}

.k-i-rewind:before {
  content: "";
}

.k-i-forward:before {
  content: "";
}

.k-i-volume-down:before {
  content: "";
}

.k-i-volume-up:before {
  content: "";
}

.k-i-volume-off:before {
  content: "";
}

.k-i-hd:before {
  content: "";
}

.k-i-subtitles:before {
  content: "";
}

.k-i-playlist:before {
  content: "";
}

.k-i-audio:before {
  content: "";
}

.k-i-play-sm:before {
  content: "";
}

.k-i-pause-sm:before {
  content: "";
}

.k-i-stop-sm:before {
  content: "";
}

.k-i-heart-outline:before, .k-i-fav-outline:before, .k-i-favorite-outline:before {
  content: "";
}

.k-i-heart:before, .k-i-fav:before, .k-i-favorite:before {
  content: "";
}

.k-i-star-outline:before, .k-i-bookmark-outline:before {
  content: "";
}

.k-i-star:before, .k-i-bookmark:before {
  content: "";
}

.k-i-checkbox:before, .k-i-shape-rect:before {
  content: "";
}

.k-i-checkbox-checked:before {
  content: "";
}

.k-i-tri-state-indeterminate:before {
  content: "";
}

.k-i-tri-state-null:before {
  content: "";
}

.k-i-circle:before {
  content: "";
}

.k-i-radiobutton:before, .k-i-shape-circle:before {
  content: "";
}

.k-i-radiobutton-checked:before {
  content: "";
}

.k-i-notification:before, .k-i-bell:before {
  content: "";
}

.k-i-information:before, .k-i-info:before, .k-i-note:before {
  content: "";
}

.k-i-question:before, .k-i-help:before {
  content: "";
}

.k-i-warning:before, .k-i-exception:before {
  content: "";
}

.k-i-photo-camera:before {
  content: "";
}

.k-i-image:before, .k-i-photo:before {
  content: "";
}

.k-i-image-export:before, .k-i-photo-export:before {
  content: "";
}

.k-i-zoom-actual-size:before {
  content: "";
}

.k-i-zoom-best-fit:before {
  content: "";
}

.k-i-image-resize:before {
  content: "";
}

.k-i-crop:before {
  content: "";
}

.k-i-mirror:before {
  content: "";
}

.k-i-flip-horizontal:before {
  content: "";
}

.k-i-flip-vertical:before {
  content: "";
}

.k-i-rotate:before {
  content: "";
}

.k-i-rotate-right:before {
  content: "";
}

.k-i-rotate-left:before {
  content: "";
}

.k-i-brush:before {
  content: "";
}

.k-i-palette:before {
  content: "";
}

.k-i-paint:before, .k-i-droplet:before, .k-i-background:before {
  content: "";
}

.k-i-line:before, .k-i-shape-line:before {
  content: "";
}

.k-i-brightness-contrast:before {
  content: "";
}

.k-i-saturation:before {
  content: "";
}

.k-i-invert-colors:before {
  content: "";
}

.k-i-transperancy:before, .k-i-opacity:before {
  content: "";
}

.k-i-greyscale:before {
  content: "";
}

.k-i-blur:before {
  content: "";
}

.k-i-sharpen:before {
  content: "";
}

.k-i-shape:before {
  content: "";
}

.k-i-round-corners:before {
  content: "";
}

.k-i-front-element:before {
  content: "";
}

.k-i-back-element:before {
  content: "";
}

.k-i-forward-element:before {
  content: "";
}

.k-i-backward-element:before {
  content: "";
}

.k-i-align-left-element:before {
  content: "";
}

.k-i-align-center-element:before {
  content: "";
}

.k-i-align-right-element:before {
  content: "";
}

.k-i-align-top-element:before {
  content: "";
}

.k-i-align-middle-element:before {
  content: "";
}

.k-i-align-bottom-element:before {
  content: "";
}

.k-i-thumbnails-up:before {
  content: "";
}

.k-i-thumbnails-right:before {
  content: "";
}

.k-i-thumbnails-down:before {
  content: "";
}

.k-i-thumbnails-left:before {
  content: "";
}

.k-i-full-screen:before, .k-i-fullscreen:before {
  content: "";
}

.k-i-full-screen-exit:before, .k-i-fullscreen-exit:before {
  content: "";
}

.k-i-reset-color:before, .k-i-paint-remove:before, .k-i-background-remove:before {
  content: "";
}

.k-i-align-stretch-element-horizontal:before {
  content: "";
}

.k-i-align-stretch-element-vertical:before {
  content: "";
}

.k-i-align-align-left-elements:before {
  content: "";
}

.k-i-align-center-elements:before {
  content: "";
}

.k-i-align-right-elements:before {
  content: "";
}

.k-i-align-stretch-elements-horizontal:before {
  content: "";
}

.k-i-align-baseline-horizontal:before {
  content: "";
}

.k-i-align-top-elements:before {
  content: "";
}

.k-i-align-middle-elements:before {
  content: "";
}

.k-i-align-bottom-elements:before {
  content: "";
}

.k-i-align-stretch-elements-vertical:before {
  content: "";
}

.k-i-align-baseline-vertical:before {
  content: "";
}

.k-i-justify-start-horizontal:before {
  content: "";
}

.k-i-justify-center-horizontal:before {
  content: "";
}

.k-i-justify-end-horizontal:before {
  content: "";
}

.k-i-justify-between-horizontal:before {
  content: "";
}

.k-i-justify-around-horizontal:before {
  content: "";
}

.k-i-justify-start-vertical:before {
  content: "";
}

.k-i-justify-center-vertical:before {
  content: "";
}

.k-i-justify-end-vertical:before {
  content: "";
}

.k-i-justify-between-vertical:before {
  content: "";
}

.k-i-justify-around-vertical:before {
  content: "";
}

.k-i-color-canvas:before {
  content: "";
}

.k-i-page-properties:before {
  content: "";
}

.k-i-bold:before {
  content: "";
}

.k-i-italic:before {
  content: "";
}

.k-i-underline:before {
  content: "";
}

.k-i-font-family:before {
  content: "";
}

.k-i-foreground-color:before {
  content: "";
}

.k-i-convert-lowercase:before {
  content: "";
}

.k-i-convert-uppercase:before {
  content: "";
}

.k-i-strikethrough:before {
  content: "";
}

.k-i-sub-script:before {
  content: "";
}

.k-i-sup-script:before {
  content: "";
}

.k-i-div:before {
  content: "";
}

.k-i-all:before {
  content: "";
}

.k-i-h1:before {
  content: "";
}

.k-i-h2:before {
  content: "";
}

.k-i-h3:before {
  content: "";
}

.k-i-h4:before {
  content: "";
}

.k-i-h5:before {
  content: "";
}

.k-i-h6:before {
  content: "";
}

.k-i-list-ordered:before, .k-i-list-numbered:before {
  content: "";
}

.k-i-list-unordered:before, .k-i-list-bulleted:before {
  content: "";
}

.k-i-indent-increase:before, .k-i-indent:before {
  content: "";
}

.k-i-indent-decrease:before, .k-i-outdent:before {
  content: "";
}

.k-i-insert-n:before, .k-i-insert-up:before, .k-i-insert-top:before {
  content: "";
}

.k-i-insert-m:before, .k-i-insert-middle:before {
  content: "";
}

.k-i-insert-s:before, .k-i-insert-down:before, .k-i-insert-bottom:before {
  content: "";
}

.k-i-align-top:before {
  content: "";
}

.k-i-align-middle:before {
  content: "";
}

.k-i-align-bottom:before {
  content: "";
}

.k-i-align-left:before {
  content: "";
}

.k-i-align-center:before {
  content: "";
}

.k-i-align-right:before {
  content: "";
}

.k-i-align-justify:before {
  content: "";
}

.k-i-align-remove:before {
  content: "";
}

.k-i-text-wrap:before {
  content: "";
}

.k-i-rule-horizontal:before {
  content: "";
}

.k-i-table-align-top-left:before {
  content: "";
}

.k-i-table-align-top-center:before {
  content: "";
}

.k-i-table-align-top-right:before {
  content: "";
}

.k-i-table-align-middle-left:before {
  content: "";
}

.k-i-table-align-middle-center:before {
  content: "";
}

.k-i-table-align-middle-right:before {
  content: "";
}

.k-i-table-align-bottom-left:before {
  content: "";
}

.k-i-table-align-bottom-center:before {
  content: "";
}

.k-i-table-align-bottom-right:before {
  content: "";
}

.k-i-table-align-remove:before {
  content: "";
}

.k-i-borders-all:before, .k-i-all-borders:before {
  content: "";
}

.k-i-borders-outside:before, .k-i-outside-borders:before {
  content: "";
}

.k-i-borders-inside:before, .k-i-inside-borders:before {
  content: "";
}

.k-i-borders-inside-horizontal:before, .k-i-inside-horizontal-borders:before {
  content: "";
}

.k-i-borders-inside-vertical:before, .k-i-inside-vertical-borders:before {
  content: "";
}

.k-i-border-top:before, .k-i-top-border:before {
  content: "";
}

.k-i-border-bottom:before, .k-i-bottom-border:before {
  content: "";
}

.k-i-border-left:before, .k-i-left-border:before {
  content: "";
}

.k-i-border-right:before, .k-i-right-border:before {
  content: "";
}

.k-i-border-no:before, .k-i-no-borders:before {
  content: "";
}

.k-i-borders-show-hide:before {
  content: "";
}

.k-i-form:before, .k-i-border:before {
  content: "";
}

.k-i-form-element:before {
  content: "";
}

.k-i-code-snippet:before {
  content: "";
}

.k-i-select-all:before {
  content: "";
}

.k-i-button:before {
  content: "";
}

.k-i-select-box:before {
  content: "";
}

.k-i-calendar-date:before {
  content: "";
}

.k-i-group-box:before {
  content: "";
}

.k-i-textarea:before {
  content: "";
}

.k-i-textbox:before {
  content: "";
}

.k-i-textbox-hidden:before {
  content: "";
}

.k-i-password:before {
  content: "";
}

.k-i-paragraph-add:before {
  content: "";
}

.k-i-edit-tools:before {
  content: "";
}

.k-i-template-manager:before {
  content: "";
}

.k-i-change-manually:before {
  content: "";
}

.k-i-track-changes:before {
  content: "";
}

.k-i-track-changes-enable:before {
  content: "";
}

.k-i-track-changes-accept:before {
  content: "";
}

.k-i-track-changes-accept-all:before {
  content: "";
}

.k-i-track-changes-reject:before {
  content: "";
}

.k-i-track-changes-reject-all:before {
  content: "";
}

.k-i-document-manager:before {
  content: "";
}

.k-i-custom-icon:before {
  content: "";
}

.k-i-dictionary-add:before {
  content: "";
}

.k-i-image-light-dialog:before, .k-i-image-insert:before {
  content: "";
}

.k-i-image-edit:before {
  content: "";
}

.k-i-image-map-editor:before {
  content: "";
}

.k-i-comment:before {
  content: "";
}

.k-i-comment-remove:before {
  content: "";
}

.k-i-comments-remove-all:before {
  content: "";
}

.k-i-silverlight:before {
  content: "";
}

.k-i-media-manager:before {
  content: "";
}

.k-i-video-external:before {
  content: "";
}

.k-i-flash-manager:before {
  content: "";
}

.k-i-find-and-replace:before, .k-i-find:before {
  content: "";
}

.k-i-copy:before, .k-i-files:before {
  content: "";
}

.k-i-cut:before {
  content: "";
}

.k-i-paste:before {
  content: "";
}

.k-i-paste-as-html:before {
  content: "";
}

.k-i-paste-from-word:before {
  content: "";
}

.k-i-paste-from-word-strip-file:before {
  content: "";
}

.k-i-paste-html:before {
  content: "";
}

.k-i-paste-markdown:before {
  content: "";
}

.k-i-paste-plain-text:before {
  content: "";
}

.k-i-apply-format:before {
  content: "";
}

.k-i-clear-css:before {
  content: "";
}

.k-i-copy-format:before {
  content: "";
}

.k-i-strip-all-formating:before {
  content: "";
}

.k-i-strip-css-format:before {
  content: "";
}

.k-i-strip-font-elements:before {
  content: "";
}

.k-i-strip-span-elements:before {
  content: "";
}

.k-i-strip-word-formatting:before {
  content: "";
}

.k-i-format-code-block:before {
  content: "";
}

.k-i-style-builder:before {
  content: "";
}

.k-i-module-manager:before {
  content: "";
}

.k-i-hyperlink-light-dialog:before, .k-i-hyperlink-insert:before {
  content: "";
}

.k-i-hyperlink-globe:before {
  content: "";
}

.k-i-hyperlink-globe-remove:before {
  content: "";
}

.k-i-hyperlink-email:before {
  content: "";
}

.k-i-anchor:before {
  content: "";
}

.k-i-table-light-dialog:before, .k-i-table-insert:before {
  content: "";
}

.k-i-table:before {
  content: "";
}

.k-i-table-properties:before, .k-i-table-wizard:before {
  content: "";
}

.k-i-table-cell:before {
  content: "";
}

.k-i-table-cell-properties:before {
  content: "";
}

.k-i-table-column-insert-left:before {
  content: "";
}

.k-i-table-column-insert-right:before {
  content: "";
}

.k-i-table-row-insert-above:before {
  content: "";
}

.k-i-table-row-insert-below:before {
  content: "";
}

.k-i-table-column-delete:before {
  content: "";
}

.k-i-table-row-delete:before {
  content: "";
}

.k-i-table-cell-delete:before {
  content: "";
}

.k-i-table-delete:before {
  content: "";
}

.k-i-cells-merge:before {
  content: "";
}

.k-i-cells-merge-horizontally:before {
  content: "";
}

.k-i-cells-merge-vertically:before {
  content: "";
}

.k-i-cell-split-horizontally:before {
  content: "";
}

.k-i-cell-split-vertically:before {
  content: "";
}

.k-i-table-unmerge:before {
  content: "";
}

.k-i-pane-freeze:before {
  content: "";
}

.k-i-row-freeze:before {
  content: "";
}

.k-i-column-freeze:before {
  content: "";
}

.k-i-toolbar-float:before {
  content: "";
}

.k-i-spell-checker:before {
  content: "";
}

.k-i-validation-xhtml:before {
  content: "";
}

.k-i-validation-data:before {
  content: "";
}

.k-i-toggle-full-screen-mode:before {
  content: "";
}

.k-i-formula-fx:before {
  content: "";
}

.k-i-sum:before {
  content: "";
}

.k-i-symbol:before {
  content: "";
}

.k-i-dollar:before, .k-i-currency:before {
  content: "";
}

.k-i-percent:before {
  content: "";
}

.k-i-custom-format:before {
  content: "";
}

.k-i-decimal-increase:before {
  content: "";
}

.k-i-decimal-decrease:before {
  content: "";
}

.k-i-font-size:before {
  content: "";
}

.k-i-image-absolute-position:before {
  content: "";
}

.k-i-globe-outline:before {
  content: "";
}

.k-i-globe:before {
  content: "";
}

.k-i-marker-pin:before {
  content: "";
}

.k-i-marker-pin-target:before {
  content: "";
}

.k-i-pin:before {
  content: "";
}

.k-i-unpin:before {
  content: "";
}

.k-i-share:before {
  content: "";
}

.k-i-user:before {
  content: "";
}

.k-i-inbox:before {
  content: "";
}

.k-i-blogger:before {
  content: "";
}

.k-i-blogger-box:before {
  content: "";
}

.k-i-delicious:before {
  content: "";
}

.k-i-delicious-box:before {
  content: "";
}

.k-i-digg:before {
  content: "";
}

.k-i-digg-box:before {
  content: "";
}

.k-i-email:before, .k-i-envelop:before, .k-i-letter:before {
  content: "";
}

.k-i-email-box:before, .k-i-envelop-box:before, .k-i-letter-box:before {
  content: "";
}

.k-i-facebook:before {
  content: "";
}

.k-i-facebook-box:before {
  content: "";
}

.k-i-google:before {
  content: "";
}

.k-i-google-box:before {
  content: "";
}

.k-i-google-plus:before {
  content: "";
}

.k-i-google-plus-box:before {
  content: "";
}

.k-i-linkedin:before {
  content: "";
}

.k-i-linkedin-box:before {
  content: "";
}

.k-i-myspace:before {
  content: "";
}

.k-i-myspace-box:before {
  content: "";
}

.k-i-pinterest:before {
  content: "";
}

.k-i-pinterest-box:before {
  content: "";
}

.k-i-reddit:before {
  content: "";
}

.k-i-reddit-box:before {
  content: "";
}

.k-i-stumble-upon:before {
  content: "";
}

.k-i-stumble-upon-box:before {
  content: "";
}

.k-i-tell-a-friend:before {
  content: "";
}

.k-i-tell-a-friend-box:before {
  content: "";
}

.k-i-tumblr:before {
  content: "";
}

.k-i-tumblr-box:before {
  content: "";
}

.k-i-twitter:before {
  content: "";
}

.k-i-twitter-box:before {
  content: "";
}

.k-i-yammer:before {
  content: "";
}

.k-i-yammer-box:before {
  content: "";
}

.k-i-behance:before {
  content: "";
}

.k-i-behance-box:before {
  content: "";
}

.k-i-dribbble:before {
  content: "";
}

.k-i-dribbble-box:before {
  content: "";
}

.k-i-rss:before {
  content: "";
}

.k-i-rss-box:before {
  content: "";
}

.k-i-vimeo:before {
  content: "";
}

.k-i-vimeo-box:before {
  content: "";
}

.k-i-youtube:before {
  content: "";
}

.k-i-youtube-box:before {
  content: "";
}

.k-i-folder:before {
  content: "";
}

.k-i-folder-open:before {
  content: "";
}

.k-i-folder-add:before {
  content: "";
}

.k-i-folder-up:before {
  content: "";
}

.k-i-folder-more:before, .k-i-fields-more:before {
  content: "";
}

.k-i-aggregate-fields:before {
  content: "";
}

.k-i-file:before, .k-i-file-vertical:before {
  content: "";
}

.k-i-file-add:before {
  content: "";
}

.k-i-file-txt:before, .k-i-txt:before {
  content: "";
}

.k-i-file-csv:before, .k-i-csv:before {
  content: "";
}

.k-i-file-excel:before, .k-i-file-xls:before, .k-i-excel:before, .k-i-xls:before {
  content: "";
}

.k-i-file-word:before, .k-i-file-doc:before, .k-i-word:before, .k-i-doc:before {
  content: "";
}

.k-i-file-mdb:before, .k-i-mdb:before {
  content: "";
}

.k-i-file-ppt:before, .k-i-ppt:before {
  content: "";
}

.k-i-file-pdf:before, .k-i-pdf:before {
  content: "";
}

.k-i-file-psd:before, .k-i-psd:before {
  content: "";
}

.k-i-file-flash:before, .k-i-flash:before {
  content: "";
}

.k-i-file-config:before, .k-i-config:before {
  content: "";
}

.k-i-file-ascx:before, .k-i-ascx:before {
  content: "";
}

.k-i-file-bac:before, .k-i-bac:before {
  content: "";
}

.k-i-file-zip:before, .k-i-zip:before {
  content: "";
}

.k-i-film:before {
  content: "";
}

.k-i-css3:before {
  content: "";
}

.k-i-html5:before {
  content: "";
}

.k-i-html:before, .k-i-source-code:before, .k-i-view-source:before {
  content: "";
}

.k-i-css:before {
  content: "";
}

.k-i-js:before {
  content: "";
}

.k-i-exe:before {
  content: "";
}

.k-i-csproj:before {
  content: "";
}

.k-i-vbproj:before {
  content: "";
}

.k-i-cs:before {
  content: "";
}

.k-i-vb:before {
  content: "";
}

.k-i-sln:before {
  content: "";
}

.k-i-cloud:before {
  content: "";
}

.k-i-file-horizontal:before {
  content: "";
}

.k-i-equal:before {
  content: "";
}

.k-i-not-equal:before {
  content: "";
}

.k-i-less-or-equal:before {
  content: "";
}

.k-i-greater-or-equal:before {
  content: "";
}

.k-i-divide:before {
  content: "";
}

.k-i-accessibility:before {
  content: "";
}

.k-i-barcode-outline:before {
  content: "";
}

.k-i-barcode:before {
  content: "";
}

.k-i-barcode-scanner:before {
  content: "";
}

.k-i-qr-code-outline:before {
  content: "";
}

.k-i-qr-code:before {
  content: "";
}

.k-i-qr-code-scanner:before {
  content: "";
}

.k-i-barcode-qr-code-scanner:before {
  content: "";
}

.k-i-signature:before {
  content: "";
}

.k-i-hand:before {
  content: "";
}

.k-i-cursor:before {
  content: "";
}

.k-i-attachment-45:before {
  content: "";
}

.k-i-images:before {
  content: "";
}

.k-i-strip-all-formatting:before {
  content: "";
}

.k-i-home:before {
  content: "";
}

.k-i-overlap:before {
  content: "";
}

.k-i-align-to-grid:before {
  content: "";
}

.k-i-size-to-grid:before {
  content: "";
}

.k-i-make-same-size:before {
  content: "";
}

.k-i-make-same-width:before {
  content: "";
}

.k-i-make-same-height:before {
  content: "";
}

.k-i-make-horizontal-spacing-equal:before {
  content: "";
}

.k-i-increase-horizontal-spacing:before {
  content: "";
}

.k-i-decrease-horizontal-spacing:before {
  content: "";
}

.k-i-remove-horizontal-spacing:before {
  content: "";
}

.k-i-make-vertical-spacing-equal:before {
  content: "";
}

.k-i-increase-vertical-spacing:before {
  content: "";
}

.k-i-decrease-vertical-spacing:before {
  content: "";
}

.k-i-remove-vertical-spacing:before {
  content: "";
}

.k-i-eyedropper:before {
  content: "";
}

.k-i-snap-grid:before {
  content: "";
}

.k-i-snap-to-gridlines:before {
  content: "";
}

.k-i-snap-to-snaplines:before {
  content: "";
}

.k-i-dimensions:before {
  content: "";
}

.k-i-crosstab:before {
  content: "";
}

.k-i-table-body:before {
  content: "";
}

.k-i-table-column-groups:before {
  content: "";
}

.k-i-table-corner:before {
  content: "";
}

.k-i-table-row-groups:before {
  content: "";
}

.k-i-table-position-left:before {
  content: "";
}

.k-i-table-position-center:before {
  content: "";
}

.k-i-table-position-right:before {
  content: "";
}

.k-i-list-roman-upper:before {
  content: "";
}

.k-i-list-roman-lower:before {
  content: "";
}

.k-i-import:before {
  content: "";
}

.k-i-export:before {
  content: "";
}

.k-i-subreport:before {
  content: "";
}

.k-i-data:before {
  content: "";
}

.k-i-report-header-section:before {
  content: "";
}

.k-i-report-footer-section:before {
  content: "";
}

.k-i-group-header-section:before {
  content: "";
}

.k-i-group-footer-section:before {
  content: "";
}

.k-i-page-header-section:before {
  content: "";
}

.k-i-page-footer-section:before {
  content: "";
}

.k-i-detail-section:before {
  content: "";
}

.k-i-toc-section:before {
  content: "";
}

.k-i-group-section:before {
  content: "";
}

.k-i-parameters:before {
  content: "";
}

.k-i-data-csv:before {
  content: "";
}

.k-i-data-json:before {
  content: "";
}

.k-i-data-sql:before {
  content: "";
}

.k-i-data-web:before {
  content: "";
}

.k-i-group-collection:before {
  content: "";
}

.k-i-parameter-boolean:before {
  content: "";
}

.k-i-parameter-date-time:before {
  content: "";
}

.k-i-parameter-float:before {
  content: "";
}

.k-i-parameter-integer:before {
  content: "";
}

.k-i-parameter-string:before {
  content: "";
}

.k-i-toc-section-level:before {
  content: "";
}

.k-i-inherited:before {
  content: "";
}

.k-i-file-video:before {
  content: "";
}

.k-i-file-audio:before {
  content: "";
}

.k-i-file-image:before {
  content: "";
}

.k-i-file-presentation:before {
  content: "";
}

.k-i-file-data:before {
  content: "";
}

.k-i-file-disc-image:before {
  content: "";
}

.k-i-file-programming:before {
  content: "";
}

.k-i-parameters-byte-array:before {
  content: "";
}

.k-i-parameters-unknown:before {
  content: "";
}

.k-i-file-error:before {
  content: "";
}

.k-i-files-error:before {
  content: "";
}

.k-i-data-rest:before {
  content: "";
}

.k-i-file-typescript:before {
  content: "";
}

.k-i-graph:before {
  content: "";
}

.k-i-column-clustered:before {
  content: "";
}

.k-i-column-stacked:before {
  content: "";
}

.k-i-column-stacked100:before {
  content: "";
}

.k-i-column-range:before {
  content: "";
}

.k-i-bar-clustered:before {
  content: "";
}

.k-i-bar-stacked:before {
  content: "";
}

.k-i-bar-stacked100:before {
  content: "";
}

.k-i-bar-range:before {
  content: "";
}

.k-i-area-clustered:before {
  content: "";
}

.k-i-area-stacked:before {
  content: "";
}

.k-i-area-stacked100:before {
  content: "";
}

.k-i-area-range:before {
  content: "";
}

.k-i-line-stacked:before {
  content: "";
}

.k-i-line-stacked100:before {
  content: "";
}

.k-i-line-markers:before {
  content: "";
}

.k-i-line-stacked-markers:before {
  content: "";
}

.k-i-line-stacked100-markers:before {
  content: "";
}

.k-i-pie:before {
  content: "";
}

.k-i-doughnut:before {
  content: "";
}

.k-i-scatter:before {
  content: "";
}

.k-i-scatter-smooth-lines-markers:before {
  content: "";
}

.k-i-scatter-smooth-lines:before {
  content: "";
}

.k-i-scatter-straight-lines-markers:before {
  content: "";
}

.k-i-scatter-straight-lines:before {
  content: "󦨙";
}

.k-i-bubble:before {
  content: "";
}

.k-i-candlestick:before {
  content: "";
}

.k-i-bar:before {
  content: "";
}

.k-i-radar:before {
  content: "";
}

.k-i-radar-markers:before {
  content: "";
}

.k-i-radar-filled:before {
  content: "";
}

.k-i-rose:before {
  content: "";
}

.k-i-choropleth:before {
  content: "";
}

.k-i-set-column-position:before {
  content: "";
}

.k-i-stick:before {
  content: "";
}

.k-i-unstick:before {
  content: "";
}

.k-i-page-portrait:before {
  content: "";
}

.k-i-page-landscape:before {
  content: "";
}

.k-rtl .k-i-indent-increase, .k-rtl .k-i-indent-decrease, .k-rtl .k-i-expand, .k-rtl .k-i-collapse {
  transform: scaleX(-1);
}

.k-spreadsheet {
  cursor: default;
  border-style: solid;
  border-width: 1px;
  width: 800px;
  height: 600px;
  position: relative;
}

.k-spreadsheet .k-spreadsheet-cell-comment {
  white-space: pre-wrap;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  max-width: 200px;
  position: absolute;
}

.k-spreadsheet .k-spreadsheet-pane {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px 0 0 1px;
  padding-right: 1px;
  position: absolute;
  overflow: hidden;
}

.k-spreadsheet .k-spreadsheet-pane.k-top {
  border-top-width: 0;
}

.k-spreadsheet .k-spreadsheet-pane.k-left {
  border-left-width: 0;
}

.k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell {
  white-space: pre;
  box-sizing: border-box;
  background-clip: padding-box;
  padding: 1px 3px;
  position: absolute;
  overflow: hidden;
}

.k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell.k-spreadsheet-has-comment:after {
  content: "";
  border-style: solid;
  border-width: 3px;
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

.k-spreadsheet .k-spreadsheet-vaxis, .k-spreadsheet .k-spreadsheet-haxis {
  border-style: solid;
  position: absolute;
}

.k-spreadsheet .k-spreadsheet-vaxis {
  border-width: 0 0 0 1px;
  top: 0;
}

.k-spreadsheet .k-spreadsheet-haxis {
  border-width: 1px 0 0;
  left: 0;
}

.k-spreadsheet .k-filter-range, .k-spreadsheet .k-spreadsheet-selection {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  position: absolute;
}

.k-spreadsheet .k-spreadsheet-active-cell {
  box-sizing: border-box;
  z-index: 10;
  position: absolute;
  outline-color: #0000 !important;
}

.k-spreadsheet .k-spreadsheet-action-bar {
  border-style: solid;
  border-width: 0 0 1px;
  width: 100%;
  display: table;
  position: relative;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor, .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar {
  vertical-align: middle;
  display: table-cell;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor {
  vertical-align: top;
  border-style: solid;
  border-width: 0 1px 0 0;
  position: relative;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox {
  border-color: #0000;
  border-radius: 0;
  width: 9.5em;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap {
  box-shadow: none;
  background-image: none;
  border-width: 0;
  border-color: #0000;
  border-radius: 0;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-input, .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor .k-combobox .k-dropdown-wrap .k-select {
  border-radius: 0;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar {
  width: 100%;
  padding-left: 31px;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar > .k-i-formula-fx {
  content: "";
  border-style: solid;
  border-width: 0 1px 0 0;
  width: 24px;
  margin-left: -24px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar .k-spreadsheet-formula-input.k-spreadsheet-array-formula:before {
  content: "{";
  font-weight: bold;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar .k-spreadsheet-formula-input.k-spreadsheet-array-formula:after {
  content: "}";
  font-weight: bold;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-input {
  text-indent: .571em;
  border: 0;
  margin: 0;
  overflow: hidden;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-state-border-down {
  padding-bottom: 0;
}

.k-spreadsheet .k-spreadsheet-formula-input {
  box-sizing: border-box;
  white-space: pre;
  border-width: 0;
  outline: none;
  width: 100%;
  height: 26px;
  padding: 3px .5em;
  line-height: 20px;
}

.k-spreadsheet .k-spreadsheet-cell-editor {
  z-index: 2000;
  padding: 0 3px;
  line-height: 20px;
  display: none;
  position: absolute;
  overflow: hidden;
}

.k-spreadsheet > .k-spreadsheet-view {
  font-family: Arial, Verdana, sans-serif;
  font-size: 12px;
  position: relative;
}

.k-spreadsheet > .k-spreadsheet-view .k-state-disabled {
  pointer-events: auto;
}

.k-spreadsheet .k-tabstrip-wrapper {
  line-height: 1.7em;
  position: relative;
}

.k-spreadsheet .k-tabstrip-wrapper .k-tabstrip-items {
  padding: .3em 0 0;
}

.k-spreadsheet .k-spreadsheet-quick-access-toolbar {
  z-index: 1;
  padding: .3em .3em 0;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.k-spreadsheet .k-spreadsheet-quick-access-toolbar .k-button {
  height: auto;
  padding: .3em;
  line-height: 1.4em;
}

.k-spreadsheet .k-merged-cells-wrapper, .k-spreadsheet .k-selection-wrapper, .k-spreadsheet .k-filter-wrapper, .k-spreadsheet .k-spreadsheet-row-header {
  position: relative;
}

.k-spreadsheet .k-spreadsheet-column-header {
  position: absolute;
}

.k-spreadsheet .k-filter-wrapper {
  pointer-events: none;
  z-index: 50;
}

.k-spreadsheet .k-spreadsheet-sheets-bar {
  border-width: 1px 0 0;
  flex-flow: row;
  align-items: center;
  gap: 8px;
  padding: 0 4px 4px;
  display: flex;
  position: relative;
}

.k-spreadsheet .k-spreadsheet-sheets-bar > .k-button {
  flex-shrink: 0;
}

.k-spreadsheet .k-spreadsheet-sheets-bar .k-tabstrip {
  background-color: #0000;
  border-width: 0;
  flex: 1;
  padding: 0;
  overflow: hidden;
}

.k-spreadsheet .k-spreadsheet-sheets-bar .k-tabstrip .k-tabstrip-items li .k-link[data-type="remove"] {
  padding-left: 6px;
  padding-right: 6px;
}

.k-spreadsheet .k-vertical-align-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.k-spreadsheet .k-vertical-align-bottom {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
}

.k-spreadsheet .k-dirty {
  top: 0;
  left: 0;
  right: unset;
  position: absolute;
}

.k-spreadsheet .k-single-selection {
  cursor: crosshair;
}

.k-spreadsheet .k-single-selection:after {
  content: " ";
  z-index: 100;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin-bottom: -4px;
  margin-right: -4px;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.k-spreadsheet .k-spreadsheet-edit-container .k-single-selection:after {
  display: none;
}

.k-spreadsheet .k-auto-fill, .k-spreadsheet .k-auto-fill-punch, .k-spreadsheet .k-auto-fill-br-hint, .k-spreadsheet .k-auto-fill-tr-hint, .k-spreadsheet .k-auto-fill-bl-hint {
  box-sizing: border-box;
  position: absolute;
}

.k-spreadsheet .k-auto-fill {
  cursor: crosshair;
  border-style: solid;
  border-width: 1px;
}

.k-spreadsheet .k-auto-fill-wrapper {
  position: relative;
}

.k-spreadsheet .k-spreadsheet-vborder {
  border-left-style: solid;
  border-left-width: 1px;
  position: absolute;
}

.k-spreadsheet .k-spreadsheet-hborder {
  border-top-style: solid;
  border-top-width: 1px;
  position: absolute;
}

.k-spreadsheet-list-popup {
  margin-right: -1px;
}

.k-spreadsheet-names-popup .k-item {
  position: relative;
}

.k-spreadsheet-names-popup .k-icon {
  opacity: .7;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-spreadsheet-names-popup .k-icon:hover {
  opacity: 1;
}

.k-horizontal-resize {
  cursor: col-resize;
}

.k-vertical-resize {
  cursor: row-resize;
}

.k-spreadsheet-data, .k-merged-cells-wrapper, .k-selection-wrapper, .k-spreadsheet-active-cell {
  cursor: cell;
}

.k-horizontal-resize .k-spreadsheet-data, .k-horizontal-resize .k-merged-cells-wrapper, .k-horizontal-resize .k-selection-wrapper, .k-horizontal-resize .k-spreadsheet-active-cell {
  cursor: col-resize;
}

.k-vertical-resize .k-spreadsheet-data, .k-vertical-resize .k-merged-cells-wrapper, .k-vertical-resize .k-selection-wrapper, .k-vertical-resize .k-spreadsheet-active-cell {
  cursor: row-resize;
}

.k-spreadsheet .k-colorpicker .k-tool-icon {
  overflow: visible;
}

.k-button.k-reset-color, .k-button.k-custom-color {
  text-align: left;
  border: none;
  border-radius: 0;
  width: 100%;
  line-height: 2em;
  display: block;
}

.k-spreadsheet-colorpicker .k-colorpalette {
  border: 1px solid #dbdbdb;
  border-left: none;
  border-right: none;
  padding: .4em;
}

.k-spreadsheet-filter {
  cursor: pointer;
  pointer-events: all;
  position: absolute;
}

.k-spreadsheet-sample {
  float: right;
}

.k-spreadsheet-clipboard-paste, .k-spreadsheet-clipboard {
  opacity: 0;
  box-sizing: border-box;
  border: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

textarea.k-spreadsheet-clipboard {
  position: fixed;
}

.k-spreadsheet-top-corner {
  z-index: 10000;
  border-style: solid;
  border-width: 0 1px 1px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.k-spreadsheet-top-corner:after {
  content: "";
  border-style: solid;
  border-width: 6px;
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.k-spreadsheet-scroller {
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: scroll;
}

@media (hover: none), (pointer: coarse) {
  .k-spreadsheet-scroller {
    z-index: 3;
  }
}

.k-spreadsheet-fixed-container {
  box-sizing: border-box;
  z-index: 2;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.k-spreadsheet-view-size {
  position: relative;
}

.k-spreadsheet-column-header, .k-spreadsheet-row-header {
  text-align: center;
  z-index: 100;
}

.k-spreadsheet-column-header .k-spreadsheet-cell, .k-spreadsheet-row-header .k-spreadsheet-cell {
  border-style: solid;
  border-width: 0 1px 1px 0;
}

div.k-spreadsheet-window {
  padding: 1.167em .75em .75em;
}

.k-spreadsheet-window .k-edit-form-container {
  width: 100%;
}

.k-spreadsheet-window .k-root-tabs {
  border-width: 0;
  margin: -1em -1em 0;
  padding: .5em 1em 0;
}

.k-spreadsheet-window .k-root-tabs .k-loading {
  display: none;
}

.k-spreadsheet-window .k-list-wrapper {
  height: 190px;
  padding: .15em 0;
}

.k-spreadsheet-window .k-list .k-item {
  cursor: pointer;
  border-radius: 0;
  padding: .5em .6em .4em;
  line-height: 1.1em;
}

.k-spreadsheet-window .k-format-filter {
  width: 100%;
}

.k-spreadsheet-window .k-format-filter + .k-group-header + .k-list-wrapper {
  margin-top: 1em;
}

.k-spreadsheet-window .k-action-buttons {
  margin-bottom: calc(10px - 1em);
  padding: 10px;
  top: 10px;
}

.k-spreadsheet-window .k-edit-label {
  width: 25%;
  margin: 0 0 0 .75em;
}

.k-spreadsheet-window .k-edit-field {
  width: 67%;
  margin: 0 .917em 0 0;
}

.k-spreadsheet-window .k-edit-label, .k-spreadsheet-window .k-edit-field {
  padding: 0;
  line-height: 3em;
}

.k-spreadsheet-window .k-edit-field .k-textbox, .k-spreadsheet-window .k-edit-field .k-dropdown {
  width: 100%;
}

.k-spreadsheet-window .k-export-config .k-hr {
  margin-left: 15px;
  margin-right: 15px;
}

.k-spreadsheet-window .k-export-config .k-edit-field {
  float: left;
  width: 40%;
  margin-left: 30px;
}

.k-spreadsheet-window .k-page-orientation {
  position: absolute;
  top: 25%;
  right: 2em;
}

.k-spreadsheet-window .k-page-orientation .k-icon {
  font-size: 6em;
}

.k-spreadsheet-window .k-export-config:after, .k-spreadsheet-window > div:after {
  content: "";
  clear: both;
  height: 0;
  display: block;
}

.k-spreadsheet-format-cells .k-spreadsheet-preview {
  text-align: center;
  border-style: solid;
  border-width: 1px 0;
  margin: 0 -.863em .72em;
  font-size: 1.16em;
  font-weight: bold;
  line-height: 3.64em;
}

.k-spreadsheet-border-palette {
  width: 153px;
}

.k-spreadsheet-border-palette .k-spreadsheet-border-type-palette .k-button {
  box-sizing: border-box;
  width: 20%;
}

.k-spreadsheet-border-palette .k-colorpalette {
  vertical-align: bottom;
}

.k-spreadsheet-popup .k-separator {
  vertical-align: middle;
  width: 1px;
  height: 1.8em;
  display: inline-block;
}

.k-spreadsheet-popup .k-colorpalette {
  vertical-align: top;
}

.k-spreadsheet-popup .k-button.k-button-icon {
  border-width: 0;
  border-radius: 0;
  padding: .6em;
}

.k-spreadsheet-popup .k-button.k-button-icontext {
  text-align: left;
  text-transform: initial;
  border-width: 0;
  border-radius: 0;
  padding: .3em 1.5em .3em .9em;
  display: block;
}

.k-spreadsheet-filter-menu {
  width: 280px;
}

.k-spreadsheet-filter-menu > .k-menu {
  border-width: 0;
}

.k-spreadsheet-filter-menu > .k-menu .k-link {
  padding-left: 26px;
}

.k-spreadsheet-filter-menu > .k-menu .k-link .k-icon {
  width: 26px;
  margin-left: -26px;
}

.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
  border-style: solid;
  border-width: 1px;
  height: 200px;
  overflow: auto scroll;
}

.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper .k-treeview {
  padding: 6px 7px;
  overflow: visible;
}

.k-spreadsheet-filter-menu .k-details {
  border-top-style: solid;
  border-top-width: 1px;
  padding: 4px 0;
}

.k-spreadsheet-filter-menu .k-details-summary {
  cursor: pointer;
  line-height: 26px;
}

.k-spreadsheet-filter-menu .k-details-summary .k-icon {
  margin: 0 5px;
}

.k-spreadsheet-filter-menu .k-details-content {
  padding: 0 8px 0 26px;
}

.k-spreadsheet-filter-menu .k-details-content > .k-textbox, .k-spreadsheet-filter-menu .k-details-content > .k-widget {
  width: 100%;
  margin-bottom: 3px;
}

.k-spreadsheet-filter-menu .k-details-content .k-space-right {
  background-image: none;
}

.k-spreadsheet-filter-menu .k-details-content .k-filter-and {
  width: 75px;
  margin: 8px 0;
}

.k-spreadsheet-filter-menu .k-action-buttons {
  border-top-width: 0;
  margin: 8px;
  padding: 0;
  position: static;
}

.k-resize-handle, .k-resize-hint {
  z-index: 200;
  position: absolute;
}

.k-resize-hint-handle {
  width: 100%;
  height: 20px;
}

.k-resize-hint-marker {
  width: 2px;
  height: 100%;
  margin: 0 auto;
}

.k-resize-hint-vertical .k-resize-hint-handle {
  float: left;
  width: 20px;
  height: 100%;
}

.k-resize-hint-vertical .k-resize-hint-marker {
  width: 100%;
  height: 2px;
}

.k-spreadsheet-quick-access-toolbar .k-button, .k-spreadsheet-sheets-bar .k-button {
  background-color: #0000;
  background-image: none;
  border-color: #0000;
}

.k-spreadsheet-tabstrip {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.k-spreadsheet-tabstrip > .k-content {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  margin: 0;
  padding: 0;
}

.k-spreadsheet-tabstrip .k-loading {
  display: none;
}

.k-spreadsheet-toolbar {
  border-width: 0;
}

.k-spreadsheet-toolbar > .k-widget[data-property="fontSize"] {
  width: 75px;
}

.k-spreadsheet-toolbar > .k-widget[data-property="format"] {
  width: 100px;
}

.k-spreadsheet-toolbar > .k-widget[data-property="fontFamily"] {
  width: 130px;
}

.k-spreadsheet-toolbar [data-tool="alignment"], .k-spreadsheet-toolbar [data-tool="backgroundColor"], .k-spreadsheet-toolbar [data-tool="borders"], .k-spreadsheet-toolbar [data-tool="freeze"], .k-spreadsheet-toolbar [data-tool="merge"], .k-spreadsheet-toolbar [data-tool="textColor"] {
  width: auto;
}

.k-spreadsheet-formula-input > .k-syntax-func.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-bool.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-ref.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-str.k-syntax-at-point, .k-spreadsheet-formula-input > .k-syntax-num.k-syntax-at-point {
  text-decoration: underline;
}

.k-spreadsheet-formula-input > .k-series-a, .k-spreadsheet-formula-input > .k-series-b, .k-spreadsheet-formula-input > .k-series-c, .k-spreadsheet-formula-input > .k-series-d {
  background-color: #0000;
}

.k-spreadsheet-selection-highlight {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  position: absolute;
}

.k-spreadsheet-formula-list {
  min-width: 100px;
}

.k-spreadsheet-formula-list .k-item {
  box-shadow: none;
  padding: 0 .3em;
}

.k-spreadsheet-sheets-items-hint {
  margin-top: .1em;
  background: none !important;
  border-width: 0 !important;
}

.k-spreadsheet-editor-button {
  z-index: 10;
  width: 20px;
  margin-left: 2px;
  position: absolute;
  padding: 0 !important;
}

.k-spreadsheet-editor-button.k-spreadsheet-last-column {
  margin-left: -21px;
}

.k-spreadsheet-editor-button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hint-wrapper {
  margin-top: 1em;
}

.k-edit-form-container > div:after {
  content: "";
  clear: both;
  display: block;
}

.k-spreadsheet-filter-menu .k-i-arrow-45-down-right {
  cursor: pointer;
}

.k-spreadsheet-import-errors .k--header-message {
  border-bottom: 1px solid #888;
  margin-bottom: 5px;
  padding-bottom: 5px;
  font-weight: bold;
}

.k-spreadsheet-import-errors .k--errors {
  max-height: 350px;
  overflow: auto;
}

.k-spreadsheet-import-errors .k--errors table {
  border-collapse: collapse;
  font-size: 90%;
}

.k-spreadsheet-import-errors .k--errors table td, .k-spreadsheet-import-errors .k--errors table th {
  border-bottom: 1px solid #bbb;
  padding: 2px 4px;
}

.k-spreadsheet-import-errors .k--errors table th {
  text-align: left;
  border-bottom: 1px solid #000;
}

.k-spreadsheet-insert-image-dialog {
  border-style: dashed;
  border-width: 2px;
  margin: 0 1em;
}

.k-spreadsheet-insert-image-dialog label {
  background-image: url("image-default.c55ebdcd.png");
  background-position: 50% 30%;
  background-repeat: no-repeat;
  background-size: auto 70%;
  width: 355px;
  height: 200px;
  padding: 10px;
  display: block;
  position: relative;
}

.k-spreadsheet-insert-image-dialog label div {
  text-align: center;
  pointer-events: none;
  padding: 10px;
  position: relative;
  top: 75%;
}

.k-spreadsheet-insert-image-dialog label input {
  display: none;
}

.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image {
  background-position: 50%;
  background-size: auto 90%;
}

.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image:hover div, .k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image.k-state-hovered div {
  opacity: 1;
  top: 50%;
}

.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image div {
  opacity: 0;
}

.k-spreadsheet-drawing {
  box-sizing: border-box;
  z-index: 101;
  position: absolute;
}

.k-spreadsheet-drawing.k-spreadsheet-active-drawing {
  outline-width: 2px;
  outline-style: solid;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-image {
  cursor: move;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle {
  border-style: solid;
  border-width: 1px;
  width: 6px;
  height: 6px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.N {
  cursor: ns-resize;
  top: 0;
  left: 50%;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.NE {
  cursor: nesw-resize;
  top: 0;
  left: 100%;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.E {
  cursor: ew-resize;
  top: 50%;
  left: 100%;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.SE {
  cursor: nwse-resize;
  top: 100%;
  left: 100%;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.S {
  cursor: ns-resize;
  top: 100%;
  left: 50%;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.SW {
  cursor: nesw-resize;
  top: 100%;
  left: 0;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.W {
  cursor: ew-resize;
  top: 50%;
  left: 0;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle.NW {
  cursor: nwse-resize;
  top: 0;
  left: 0;
}

.k-avatar {
  box-sizing: border-box;
  vertical-align: middle;
  border-style: solid;
  border-width: 0;
  flex-flow: row;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: inline-flex;
  overflow: hidden;
}

.k-avatar-circle, .k-avatar-circle.k-avatar-image > * {
  border-radius: 50%;
}

.k-avatar-rounded, .k-avatar-rounded.k-avatar-image > * {
  border-radius: 5px;
}

.k-avatar-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.k-avatar-image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.k-card {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.k-card .k-card-callout {
  border-style: solid;
  border-width: 1px 1px 0 0;
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
}

.k-card .k-card-callout.k-callout-n {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.k-card .k-card-callout.k-callout-e {
  top: 50%;
  left: auto;
  right: 0;
  transform: translate(50%, -50%)rotate(135deg)scaleX(-1);
}

.k-card .k-card-callout.k-callout-s {
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%)rotate(135deg);
}

.k-card .k-card-callout.k-callout-w {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%)rotate(-135deg);
}

.k-card .k-avatar {
  width: 45px;
  height: 45px;
  margin-right: 16px;
}

.k-card .k-avatar.k-avatar-image > * {
  width: 45px;
  height: 45px;
}

.k-card.k-card-flat {
  box-shadow: none;
}

.k-card-horizontal {
  flex-direction: row;
}

.k-card-inner {
  flex-direction: inherit;
  display: flex;
  overflow: hidden;
}

.k-card-header {
  z-index: 1;
  border-style: solid;
  border-width: 0 0 1px;
  outline: 0;
  padding: 12px 16px;
  position: relative;
  overflow: hidden;
}

.k-card > .k-card-header:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.k-card > .k-card-header:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.k-card-header h1, .k-card-header h2, .k-card-header h3, .k-card-header h4, .k-card-header h5, .k-card-header h6, .k-card-header > div {
  margin: 0;
}

.k-card-header .k-card-title + .k-card-subtitle {
  margin-top: 0;
}

.k-card-footer {
  border-style: solid;
  border-width: 1px 0 0;
  outline: 0;
  padding: 12px 16px;
  display: block;
}

.k-card-body {
  outline: 0;
  flex: auto;
  padding: 12px 16px;
}

.k-card > .k-card-body:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.k-card > .k-card-body:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.k-card-body p {
  margin: 0 0 8px;
}

.k-card-body > .k-last, .k-card-body > :last-child {
  margin-bottom: 0;
}

.k-card-image, .k-card-media {
  border: 0;
  max-width: 100%;
  overflow: hidden;
}

.k-card > .k-card-image:first-child, .k-card > .k-card-media:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.k-card > .k-card-image:last-child, .k-card > .k-card-media:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.k-card-image > img, .k-card-media > img {
  border: 0;
  max-width: 100%;
}

.k-card-horizontal .k-card-image, .k-card-horizontal .k-card-media {
  object-fit: cover;
  flex-shrink: 0;
  max-width: 100px;
}

.k-card-title {
  margin: 0 0 8px;
  font-family: inherit;
  font-size: 19px;
  font-weight: 400;
  line-height: normal;
}

.k-card-subtitle {
  opacity: .6;
  margin: 0 0 8px;
  font-size: 12px;
}

.k-card-separator {
  border-style: solid;
  border-width: 1px 0 0;
  flex: none;
  margin: 0;
  display: block;
}

.k-card-separator.k-separator-vertical {
  border-width: 0 0 0 1px;
  width: 0;
}

.k-card > .k-hr {
  border-color: inherit;
  flex: none;
  margin: 0;
}

.k-card-actions {
  border-style: solid;
  border-width: 0;
  border-color: inherit;
  flex-wrap: wrap;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 8px 16px;
  display: flex;
  overflow: hidden;
}

.k-card > .k-card-actions:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.k-card > .k-card-actions:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.k-card-horizontal .k-card-actions, .k-card-vertical .k-card-actions {
  border: 0;
}

.k-card-actions .k-button {
  white-space: normal;
  max-width: 100%;
}

.k-card-actions > .k-button.k-flat:first-child {
  margin-left: -8px;
}

.k-card-actions.k-card-actions-start {
  justify-content: flex-start;
}

.k-card-actions.k-card-actions-end {
  justify-content: flex-end;
}

.k-card-actions.k-card-actions-center {
  justify-content: center;
}

.k-card-action {
  border-style: solid;
  border-width: 0;
  border-color: inherit;
  flex: auto;
  display: inline-flex;
}

.k-card-action > .k-button {
  border-radius: 0;
  flex: auto;
  padding: 16px;
}

.k-card-actions-vertical {
  flex-direction: column;
  padding: 0;
  display: flex;
}

.k-card-actions-vertical .k-button {
  padding: 12px 16px;
}

.k-card-actions-vertical .k-button.k-flat:first-child {
  margin-left: 0;
}

.k-card-actions-vertical .k-card-action + .k-card-action {
  border-top-width: 1px;
}

.k-card-horizontal .k-card-actions-vertical {
  border-top-width: 0;
  border-left-width: 1px;
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-start {
  align-items: flex-start;
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-end {
  align-items: flex-end;
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-center {
  align-items: center;
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-start, .k-card-vertical .k-card-actions-vertical.k-card-actions-end, .k-card-vertical .k-card-actions-vertical.k-card-actions-center {
  padding: 8px 16px;
}

.k-card-vertical .k-card-actions-vertical.k-card-actions-start .k-button, .k-card-vertical .k-card-actions-vertical.k-card-actions-end .k-button, .k-card-vertical .k-card-actions-vertical.k-card-actions-center .k-button {
  padding: 4px 8px;
}

.k-card-actions-stretched {
  flex-direction: row;
  padding: 0;
  display: flex;
}

.k-card-actions-stretched .k-button {
  border-radius: 0;
  flex-grow: 1;
  padding: 12px 16px;
}

.k-card-actions-stretched .k-button.k-flat:first-child {
  margin-left: 0;
}

.k-card-actions-stretched .k-card-action + .k-card-action {
  border-left-width: 1px;
}

.k-card .k-card-actions-stretched {
  border-style: solid;
  border-top-width: 1px;
}

.k-card-horizontal > .k-card-actions-stretched {
  border-top-width: 0;
  border-left-width: 1px;
}

.k-card-actions-stretched.k-card-actions-vertical {
  flex-direction: column;
}

.k-card-actions-stretched.k-card-actions-vertical .k-card-action + .k-card-action {
  border-top-width: 1px;
  border-left-width: 0;
}

.k-card-list {
  flex-flow: column;
  flex: none;
  align-items: stretch;
  display: flex;
}

.k-card-list .k-card {
  flex: none;
}

.k-card-list .k-card + .k-card {
  margin-top: 16px;
}

.k-card-deck {
  flex-flow: row;
  flex: none;
  align-items: stretch;
  margin-top: 16px;
  display: flex;
}

.k-card-deck .k-card {
  flex: none;
}

.k-card-deck .k-card + .k-card {
  margin-left: 16px;
}

.k-card-deck-scrollwrap {
  align-items: center;
  display: flex;
  position: relative;
}

.k-card-deck-scrollwrap > .k-button {
  z-index: 1;
  border-radius: 0;
  flex: none;
  position: absolute;
}

.k-card-deck-scrollwrap > .k-button:first-child {
  left: -1px;
}

.k-card-deck-scrollwrap > .k-button:last-child {
  right: -1px;
}

.k-card-deck-scrollwrap > .k-card-deck {
  flex: auto;
}

.k-card-group {
  flex-flow: row;
  flex: none;
  align-items: stretch;
  display: flex;
}

.k-card-group .k-card {
  border-radius: 0;
  flex: none;
}

.k-card-group .k-card > .k-card-header {
  border-radius: 0;
}

.k-card-group .k-card + .k-card {
  margin-left: -1px;
}

.k-card-group .k-card.k-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.k-card-group .k-card.k-first > .k-card-header {
  border-top-left-radius: 4px;
}

.k-card-group .k-card.k-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.k-card-group .k-card.k-last > .k-card-header {
  border-top-right-radius: 4px;
}

.k-card-group .k-card.k-only {
  border-radius: 4px;
}

.k-card-group .k-card.k-only > .k-card-header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.k-rtl .k-card-deck .k-card + .k-card, [dir="rtl"] .k-card-deck .k-card + .k-card {
  margin-left: 0;
  margin-right: 16px;
}

.k-rtl .k-card-actions-stretched .k-card-action + .k-card-action, [dir="rtl"] .k-card-actions-stretched .k-card-action + .k-card-action, .k-rtl .k-card-horizontal .k-card-actions-vertical, [dir="rtl"] .k-card-horizontal .k-card-actions-vertical, .k-rtl .k-card-horizontal > .k-card-actions-stretched, [dir="rtl"] .k-card-horizontal > .k-card-actions-stretched {
  border-left-width: 0;
  border-right-width: 1px;
}

.k-ie11 .k-card-body {
  flex: auto;
}

.k-tilelayout {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  outline: 0;
  display: grid;
}

.k-layout-item-hint {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  position: relative;
}

.k-layout-item-hint.k-layout-item-hint-resize {
  z-index: 3;
}

.k-chat {
  flex-direction: column;
  max-width: 500px;
  height: 600px;
  max-height: 100%;
  margin: auto;
  display: flex;
  overflow: hidden;
}

.k-chat .k-message-list {
  scroll-behavior: smooth;
  flex-direction: column;
  flex: auto;
  align-items: flex-start;
  display: flex;
  overflow: hidden auto;
}

.k-chat .k-message-list-content {
  box-sizing: border-box;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.k-chat .k-message-list-content > * + * {
  margin-top: 16px;
}

.k-chat .k-message-group {
  box-sizing: border-box;
  background: none;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 80%;
  display: flex;
  position: relative;
}

.k-chat .k-message-group:not(.k-alt) {
  text-align: left;
  align-items: flex-start;
}

.k-chat .k-message-group:not(.k-alt) .k-message-time {
  margin-left: 8px;
  left: 100%;
}

.k-chat .k-message-group:not(.k-alt) .k-message-status {
  left: 0;
}

.k-chat .k-message-group:not(.k-alt) .k-first .k-bubble, .k-chat .k-message-group:not(.k-alt) .k-only .k-bubble {
  border-bottom-left-radius: 2px;
}

.k-chat .k-message-group:not(.k-alt) .k-middle .k-bubble, .k-chat .k-message-group:not(.k-alt) .k-last .k-bubble {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.k-chat .k-message-group.k-alt {
  text-align: right;
  align-self: flex-end;
  align-items: flex-end;
}

.k-chat .k-message-group.k-alt .k-message-time {
  margin-right: 8px;
  right: 100%;
}

.k-chat .k-message-group.k-alt .k-message-status {
  right: 0;
}

.k-chat .k-message-group.k-alt .k-first .k-bubble, .k-chat .k-message-group.k-alt .k-only .k-bubble {
  border-bottom-right-radius: 2px;
}

.k-chat .k-message-group.k-alt .k-middle .k-bubble, .k-chat .k-message-group.k-alt .k-last .k-bubble {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.k-chat .k-message {
  max-width: 100%;
  margin: 2px 0 0;
  transition: margin .2s ease-in-out;
  position: relative;
}

.k-chat .k-message-time, .k-chat .k-message-status {
  white-space: nowrap;
  pointer-events: none;
  font-size: smaller;
  line-height: normal;
  position: absolute;
}

.k-chat .k-message-time {
  opacity: 0;
  transition: opacity .2s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
}

.k-chat .k-message-status {
  height: 0;
  margin-top: 2px;
  transition: height .2s ease-in-out;
  top: 100%;
  overflow: hidden;
}

.k-chat .k-bubble {
  word-wrap: break-word;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  padding: 8px 12px;
  line-height: 18px;
}

.k-chat .k-message.k-state-selected {
  color: inherit;
  background: none;
  border: 0;
  margin-bottom: 16px;
}

.k-chat .k-message.k-state-selected .k-message-time {
  opacity: 1;
}

.k-chat .k-message.k-state-selected .k-message-status {
  height: 1.2em;
}

.k-chat .k-message-error, .k-chat .k-message-sending {
  margin-bottom: 16px;
}

.k-chat .k-message-error .k-message-status, .k-chat .k-message-sending .k-message-status {
  height: 1.2em;
}

.k-chat .k-avatar {
  border-radius: 100%;
  width: 32px;
  height: 32px;
  position: absolute;
}

.k-chat .k-avatars .k-message-group:not(.k-alt):not(.k-no-avatar) {
  padding-left: 40px;
}

.k-chat .k-avatars .k-message-group.k-alt:not(.k-no-avatar) {
  padding-right: 40px;
}

.k-chat .k-message-group:not(.k-alt) > .k-avatar {
  bottom: 0;
  left: 0;
}

.k-chat .k-message-group.k-alt > .k-avatar {
  bottom: 0;
  right: 0;
}

.k-author {
  margin: 0;
  font-size: smaller;
  line-height: normal;
}

.k-chat .k-author {
  margin: 0;
}

.k-timestamp {
  text-align: center;
  align-self: stretch;
  font-size: smaller;
  line-height: normal;
}

.k-quick-replies {
  max-width: 100%;
  display: block;
}

.k-quick-reply {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  flex: none;
  margin-bottom: 4px;
  margin-right: 8px;
  padding: 8px 12px;
  line-height: 18px;
  transition-property: color, background-color, border-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: inline-block;
}

.k-message-box {
  border-style: solid;
  border-width: 1px 0 0;
  flex-flow: row;
  flex: none;
  padding: 10px 16px;
  display: flex;
}

.k-message-box .k-input {
  font: inherit;
  background: none;
  border: 0;
  flex: auto;
  margin: 0;
  padding: 0;
}

.k-message-box .k-button {
  padding: 0;
}

.k-message-box .k-button svg {
  fill: currentColor;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.k-message-box .k-button:before, .k-message-box .k-button:after {
  display: none;
}

.k-chat .k-card-list {
  margin: 2px 0 0;
}

.k-chat .k-card-deck {
  box-sizing: border-box;
  scroll-behavior: smooth;
  max-width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px;
  overflow: auto hidden;
}

.k-chat .k-card-deck .k-card-wrap + .k-card-wrap {
  margin-left: 16px;
}

.k-chat .k-card-deck .k-card, .k-chat .k-card-deck .k-card-wrap {
  width: 200px;
}

.k-card-deck-scrollwrap {
  box-sizing: content-box;
  flex: none;
  width: 100%;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  overflow: hidden;
}

.k-card-deck-scrollwrap > .k-card-deck {
  margin-bottom: -20px;
  padding-bottom: 20px;
}

.k-chat .k-card-deck .k-card-wrap {
  flex-flow: row;
  flex: none;
  align-items: stretch;
  display: flex;
}

.k-chat .k-card-deck .k-card-wrap .k-card {
  flex: none;
}

.k-chat .k-card-deck .k-card-wrap .k-card + .k-card {
  margin-left: 16px;
}

.k-chat .k-card-deck .k-card-wrap.k-state-selected {
  background: none;
}

.k-typing-indicator {
  border-radius: 50px;
  flex-flow: row;
  padding: 0;
  display: inline-flex;
}

.k-typing-indicator span {
  background-color: currentColor;
  border-radius: 50%;
  flex: 0 0 8px;
  width: 8px;
  height: 8px;
}

.k-typing-indicator span + span {
  margin-left: 5px;
}

@keyframes k-animation-blink {
  50% {
    opacity: 1;
  }
}

.k-chat .k-toolbar-box {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px 0 0;
  flex: none;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.k-chat .k-toolbar-box .k-button-list {
  scroll-behavior: smooth;
  flex-flow: row;
  padding: 10px 16px;
  display: flex;
  overflow: hidden;
}

.k-chat .k-toolbar-box .k-button-list:after {
  content: "";
  padding-right: 16px;
}

.k-chat .k-toolbar-box .k-button-list .k-button + .k-button {
  flex-shrink: 0;
  margin-left: 20px;
}

.k-chat .k-toolbar-box .k-scroll-button {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.k-chat .k-toolbar-box .k-scroll-button-left {
  left: 0;
}

.k-chat .k-toolbar-box .k-scroll-button-right {
  right: 0;
}

.k-chat .k-toolbar-box .k-button {
  color: inherit;
  box-shadow: none;
  background: none;
  border-width: 0;
  flex-shrink: 0;
}

.k-rtl .k-message-group:not(.k-alt), [dir="rtl"] .k-message-group:not(.k-alt) {
  text-align: right;
}

.k-rtl .k-message-group:not(.k-alt) .k-message-time, [dir="rtl"] .k-message-group:not(.k-alt) .k-message-time {
  margin-left: 0;
  margin-right: 8px;
  left: auto;
  right: 100%;
}

.k-rtl .k-message-group:not(.k-alt) .k-message-status, [dir="rtl"] .k-message-group:not(.k-alt) .k-message-status {
  left: auto;
  right: 0;
}

.k-rtl .k-message-group.k-alt, [dir="rtl"] .k-message-group.k-alt {
  text-align: left;
}

.k-rtl .k-message-group.k-alt .k-message-time, [dir="rtl"] .k-message-group.k-alt .k-message-time {
  margin-left: 8px;
  margin-right: 0;
  left: 100%;
  right: auto;
}

.k-rtl .k-message-group.k-alt .k-message-status, [dir="rtl"] .k-message-group.k-alt .k-message-status {
  left: 0;
  right: auto;
}

.k-rtl .k-avatars .k-message-group:not(.k-alt):not(.k-no-avatar), [dir="rtl"] .k-avatars .k-message-group:not(.k-alt):not(.k-no-avatar) {
  padding-left: 0;
  padding-right: 40px;
}

.k-rtl .k-avatars .k-message-group.k-alt:not(.k-no-avatar), [dir="rtl"] .k-avatars .k-message-group.k-alt:not(.k-no-avatar) {
  padding-left: 40px;
  padding-right: 0;
}

.k-rtl .k-message-box .k-button svg, [dir="rtl"] .k-message-box .k-button svg {
  transform: rotate(180deg);
}

.k-rtl .k-quick-reply, [dir="rtl"] .k-quick-reply {
  margin-left: 8px;
  margin-right: 0;
}

.k-calendar {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  line-height: 1.42857;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.k-calendar .k-link {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

.k-calendar .k-action-link {
  text-decoration: underline;
}

.k-calendar .k-state-focused, .k-calendar .k-state-focused.k-state-selected {
  box-shadow: none;
}

.k-calendar table {
  border-spacing: 0;
  table-layout: fixed;
  float: left;
  border-width: 0;
  outline: 0;
  margin: 0;
  padding: 0;
}

.k-calendar td, .k-calendar th {
  text-align: center;
  border-style: solid;
  border-width: 0;
  border-color: inherit;
  color: inherit;
  cursor: default;
  background: none;
  padding: 0;
  font-weight: normal;
}

.k-calendar .k-header {
  text-align: center;
  z-index: 1;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 2px;
  position: relative;
}

.k-calendar .k-footer {
  text-align: center;
  clear: both;
  padding: .5em;
  position: relative;
}

.k-calendar .k-footer .k-nav-today:hover {
  text-decoration: underline;
}

.k-calendar .k-calendar-view {
  z-index: 1;
  width: 17em;
  height: 17em;
  position: relative;
  overflow: hidden;
}

.k-calendar.k-week-number .k-calendar-view {
  width: 19.4286em;
  padding: 0;
}

.k-calendar .k-content {
  background-color: #0000;
  width: 100%;
}

.k-calendar .k-content th {
  box-sizing: border-box;
  border-bottom-width: 1px;
  padding: .25em 2px;
}

.k-calendar .k-content td {
  border-radius: 4px;
}

.k-calendar .k-content td.k-alt {
  border-radius: 0;
  width: 2.42857em;
}

.k-calendar .k-content .k-link {
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
  padding: .5em;
  line-height: 1.42857;
  display: block;
}

.k-calendar .k-month .k-link {
  width: 2.42857em;
  height: 2.42857em;
}

.k-calendar .k-month th {
  height: 2.42857em;
}

.k-calendar .k-year .k-link, .k-calendar .k-decade .k-link {
  width: 4.28571em;
  height: 4.28571em;
  line-height: 3.28571em;
}

.k-calendar .k-century .k-link {
  text-align: left;
  white-space: normal;
  width: 4.28571em;
  height: 4.28571em;
  line-height: 1.64286em;
}

.k-calendar .k-animation-container .k-content {
  height: 100%;
}

.k-calendar .k-calendar-header {
  flex: none;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.k-calendar .k-calendar-header .k-button {
  white-space: nowrap;
}

.k-calendar .k-calendar-nav {
  align-items: center;
}

.k-calendar .k-nav-today {
  cursor: pointer;
}

.k-calendar .k-calendar-monthview, .k-calendar .k-calendar-yearview, .k-calendar .k-calendar-decadeview, .k-calendar .k-calendar-decadeview {
  padding: 0 16px;
}

.k-calendar .k-calendar-monthview th, .k-calendar .k-calendar-yearview th, .k-calendar .k-calendar-decadeview th, .k-calendar .k-calendar-decadeview th {
  background-color: #0000;
  border-bottom-width: 0;
}

.k-calendar .k-calendar-monthview .k-alt, .k-calendar .k-calendar-yearview .k-alt, .k-calendar .k-calendar-decadeview .k-alt, .k-calendar .k-calendar-decadeview .k-alt {
  opacity: .5;
  background-color: #0000;
}

.k-calendar-range {
  text-align: left;
  width: auto;
  line-height: 1.42857;
}

.k-calendar-range .k-calendar-header {
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  display: flex;
}

.k-calendar-range .k-calendar-header .k-button {
  white-space: nowrap;
  color: inherit;
  transition: color .2s ease-in-out;
  box-shadow: none !important;
  background: none !important;
  border-color: #0000 !important;
}

.k-calendar-range .k-calendar-header .k-button:hover, .k-calendar-range .k-calendar-header .k-button.k-state-hover, .k-calendar-range .k-calendar-header .k-button:active, .k-calendar-range .k-calendar-header .k-button.k-state-active, .k-calendar-range .k-calendar-header .k-button:hover:active, .k-calendar-range .k-calendar-header .k-button:hover.k-state-active {
  color: inherit;
}

.k-calendar-range .k-calendar-header .k-button:before, .k-calendar-range .k-calendar-header .k-button:after {
  display: block;
}

.k-calendar-range .k-calendar-header .k-button:focus:after, .k-calendar-range .k-calendar-header .k-button.k-state-focused:after {
  opacity: .12;
  box-shadow: inset 0 0 0 2px;
}

.k-calendar-range .k-calendar-header .k-button[disabled], .k-state-disabled .k-calendar-range .k-calendar-header .k-button, .k-calendar-range .k-calendar-header .k-button.k-state-disabled {
  pointer-events: none;
}

.k-calendar-range .k-calendar-header .k-button.k-primary, .k-calendar-range .k-calendar-header .k-button.k-primary:hover, .k-calendar-range .k-calendar-header .k-button.k-primary.k-state-hover, .k-calendar-range .k-calendar-header .k-button.k-primary:active, .k-calendar-range .k-calendar-header .k-button.k-primary.k-state-active, .k-calendar-range .k-calendar-header .k-button.k-primary:hover:active, .k-calendar-range .k-calendar-header .k-button.k-primary:hover.k-state-active {
  color: #7ea700;
}

.k-calendar-range .k-calendar-nav {
  white-space: nowrap;
  display: inline-block;
}

.k-calendar-range .k-calendar-view {
  text-align: center;
  white-space: nowrap;
  width: auto;
  height: auto;
  min-height: 17em;
}

.k-animation-container-sm .k-calendar-range .k-calendar-view {
  flex-direction: column;
  display: flex;
}

.k-calendar-range.k-week-number .k-calendar-view {
  width: auto;
}

.k-calendar-range .k-content {
  text-align: center;
  vertical-align: top;
  float: none;
  color: inherit;
  background-color: #0000;
  width: auto;
  height: auto;
  padding: 0 16px 8px;
  display: inline-table;
}

.k-calendar-range .k-content th, .k-calendar-range .k-content td {
  color: inherit;
  text-align: inherit;
  vertical-align: middle;
  background-color: #0000;
  background-image: none;
  border-width: 0;
  padding: 0;
}

.k-calendar-range .k-content th {
  text-transform: uppercase;
  opacity: .5;
  padding: .5em 0;
  font-size: 12px;
  line-height: 1.5;
}

.k-calendar-range .k-content td {
  border-radius: 4px;
}

.k-calendar-range .k-content td.k-alt {
  opacity: .5;
}

.k-calendar-range .k-content .k-link {
  box-sizing: border-box;
  line-height: inherit;
  border-radius: 4px;
  min-height: 0;
  padding: .5em;
}

.k-calendar-range .k-content .k-meta-header, .k-calendar-range .k-content .k-month-header {
  text-transform: uppercase;
  text-align: left;
  opacity: .5;
  margin: 0 16px;
  padding: .5em 8px;
  font-size: 12px;
  line-height: 1.5;
}

.k-calendar-range .k-content td.k-range-start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.k-calendar-range .k-content td.k-range-mid {
  border-radius: 0;
}

.k-calendar-range .k-content td.k-range-end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.k-calendar-range .k-content td.k-range-split-start, .k-calendar-range .k-content td.k-range-split-end {
  position: relative;
}

.k-calendar-range .k-content td.k-range-split-start:after, .k-calendar-range .k-content td.k-range-split-end:after {
  content: "";
  width: 5px;
  display: block;
  position: absolute;
  top: 1px;
  bottom: 1px;
}

.k-calendar-range .k-content td.k-range-split-start:after {
  left: -5px;
  right: auto;
}

.k-calendar-range .k-content td.k-range-split-end:after {
  left: auto;
  right: -5px;
}

.k-calendar-range .k-content td.k-weekend {
  background-color: #0000;
}

.k-calendar-range .k-state-selected {
  color: inherit;
  background: none;
  border-color: #0000;
}

.k-calendar-range .k-state-focused, .k-calendar-range .k-state-focused.k-state-selected {
  box-shadow: none;
}

.k-calendar-range td.k-state-disabled {
  opacity: .5;
}

.k-calendar-range td.k-state-disabled .k-link {
  color: inherit;
}

.k-calendar-range .k-footer .k-nav-today {
  width: auto;
  height: auto;
  padding: 8px 16px;
  display: block;
}

.k-calendar-range .k-calendar-monthview, .k-calendar-range .k-calendar-yearview, .k-calendar-range .k-calendar-decadeview, .k-calendar-range .k-calendar-decadeview {
  padding: 0;
}

.k-widget.k-daterangepicker {
  box-shadow: none;
  border: 0;
}

.k-calendar-monthview .k-content .k-link {
  width: 2.42857em;
}

.k-calendar-monthview .k-content tbody tr {
  height: 2.42857em;
}

.k-calendar-yearview .k-content {
  margin: 0 -1px;
}

.k-calendar-yearview .k-content .k-link {
  width: 4.28571em;
  height: 4.28571em;
  line-height: 3.25;
}

.k-calendar-decadeview .k-content {
  margin: 0 -1px;
}

.k-calendar-decadeview .k-content .k-link {
  width: 4.28571em;
  height: 4.28571em;
  line-height: 3.25;
}

.k-calendar-centuryview .k-content {
  margin: 0 -1px;
}

.k-calendar-centuryview .k-content .k-link {
  text-align: left;
  white-space: normal;
  width: 4.28571em;
  height: 4.28571em;
  padding: .5em;
  line-height: 1.625;
}

.k-rtl .k-calendar-range {
  text-align: right;
}

.k-rtl .k-calendar-range .k-calendar-nav {
  float: left;
}

.k-pdf-viewer {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.k-pdf-viewer .k-toolbar {
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-color: inherit;
  z-index: 2;
  flex: none;
}

.k-pdf-viewer .k-pager-wrap {
  color: inherit;
  background: none;
  border-width: 0;
  flex: none;
  align-items: center;
  min-height: auto;
  padding: 0;
  display: flex;
}

.k-pdf-viewer .k-list-scroller {
  outline: none;
  flex: auto;
}

.k-pdf-viewer .k-list-scroller .k-enable-text-select {
  -webkit-user-select: text;
  user-select: text;
  cursor: text;
}

.k-pdf-viewer .k-list-scroller .k-enable-panning {
  cursor: grab;
}

.k-pdf-viewer .k-list-scroller .k-enable-panning span::selection {
  background-color: #0000;
}

.k-pdf-viewer .k-page {
  margin: 30px auto;
  position: relative;
}

.k-pdf-viewer .k-page canvas {
  direction: ltr;
}

.k-pdf-viewer .k-page .k-text-layer {
  opacity: .2;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.k-pdf-viewer .k-page .k-text-layer > span {
  transform-origin: 0 0;
  color: #0000;
  position: absolute;
}

.k-pdf-viewer .k-page .k-text-layer mark {
  color: #0000;
}

.k-pdf-viewer-window {
  flex: auto;
}

.k-pdf-viewer-window .k-edit-field .k-textbox, .k-pdf-viewer-window .k-edit-field .k-file-format {
  width: 100%;
}

.k-pdf-viewer-window .k-action-buttons {
  margin-bottom: calc(10px - 1em);
  padding: 10px;
  top: 10px;
}

.k-pdf-viewer-search-dialog {
  padding: .285714em;
}

.k-pdf-viewer-search-dialog .k-search-container {
  flex: none;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.k-pdf-viewer-search-dialog .k-search-container .k-textbox {
  background: none;
  align-items: center;
  margin: 0 .285714em;
  padding-right: .178571em;
  display: inline-flex;
}

.k-pdf-viewer-search-dialog .k-search-container .k-textbox .k-icon {
  font-size: inherit;
  margin: 0;
  position: relative;
  top: auto;
}

.k-pdf-viewer-search-dialog .k-search-container .k-textbox .k-search-dialog-input::-ms-clear {
  display: none;
}

.k-pdf-viewer-search-dialog .k-search-container .k-textbox:after {
  content: "";
}

.k-pdf-viewer-search-dialog .k-search-container .k-match-case-button {
  height: 1.65em;
}

.k-pdf-viewer-search-dialog .k-search-container .k-search-matches {
  vertical-align: middle;
  min-width: 4.5em;
  margin: 0 .285714em;
  display: inline-block;
}

.k-pdf-viewer-search-dialog .k-search-container > .k-button {
  margin-left: .285714em;
}

.k-pdf-viewer-search-dialog .k-search-container .k-search-dialog-draghandle {
  cursor: move;
  margin-left: 0;
}

kendo-scrollview.k-scrollview-wrap, div.k-scrollview {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  -webkit-touch-callout: none;
  outline: 0;
  line-height: 1.42857;
  display: block;
  position: relative;
  overflow: hidden;
}

kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-pageable > .k-button:last-of-type, div.k-scrollview.k-scrollview-multidot .k-scrollview-pageable > .k-button:last-of-type, kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-nav > .k-link:last-of-type, div.k-scrollview.k-scrollview-multidot .k-scrollview-nav > .k-link:last-of-type, kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-pageable > .k-button:first-of-type, div.k-scrollview.k-scrollview-multidot .k-scrollview-pageable > .k-button:first-of-type, kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-nav > .k-link:first-of-type, div.k-scrollview.k-scrollview-multidot .k-scrollview-nav > .k-link:first-of-type {
  width: 4px;
  height: 4px;
}

kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-last-of-type(2), div.k-scrollview.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-last-of-type(2), kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-last-of-type(2), div.k-scrollview.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-last-of-type(2), kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-of-type(2), div.k-scrollview.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-of-type(2), kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-of-type(2), div.k-scrollview.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-of-type(2) {
  width: 6px;
  height: 6px;
}

kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-last-of-type(3), div.k-scrollview.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-last-of-type(3), kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-last-of-type(3), div.k-scrollview.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-last-of-type(3), kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-of-type(3), div.k-scrollview.k-scrollview-multidot .k-scrollview-pageable > .k-button:nth-of-type(3), kendo-scrollview.k-scrollview-wrap.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-of-type(3), div.k-scrollview.k-scrollview-multidot .k-scrollview-nav > .k-link:nth-of-type(3) {
  width: 8px;
  height: 8px;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview, div.k-scrollview .k-scrollview-wrap {
  cursor: default;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview img, div.k-scrollview .k-scrollview-wrap img {
  -webkit-user-select: none;
  user-select: none;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview > li, div.k-scrollview .k-scrollview-wrap > li {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview > [data-role="page"], div.k-scrollview .k-scrollview-wrap > [data-role="page"] {
  vertical-align: top;
  min-height: 1px;
  display: inline-block;
}

kendo-scrollview.k-scrollview-wrap kendo-scrollview-pager, .k-scrollview .k-scrollview-nav-wrap {
  height: calc(50px + 0);
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable, .k-scrollview .k-scrollview-nav {
  text-align: center;
  white-space: nowrap;
  pointer-events: initial;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  margin: 0;
  padding: 20px;
  line-height: 0;
  list-style: none;
  display: block;
  overflow: scroll hidden;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable:focus, .k-scrollview .k-scrollview-nav:focus, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable.k-state-focused, .k-scrollview .k-scrollview-nav.k-state-focused {
  outline: none;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button, .k-scrollview .k-scrollview-nav > .k-link {
  box-sizing: content-box;
  cursor: pointer;
  pointer-events: all;
  vertical-align: top;
  border-style: solid;
  border-width: 0;
  border-radius: 50%;
  flex: none;
  width: 10px;
  height: 10px;
  margin: 0 10px;
  padding: 0;
  display: inline-block;
  position: relative;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button:before, .k-scrollview .k-scrollview-nav > .k-link:before {
  content: "";
  pointer-events: initial;
  border-radius: 0;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-scrollview-next, .k-scrollview-prev {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 60%;
  padding: 0;
  text-decoration: none;
  display: table;
  position: absolute;
  top: 20%;
}

.k-scrollview-next .k-icon, .k-scrollview-prev .k-icon {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  font-size: 4.5em;
  font-weight: normal;
  display: table-cell;
  overflow: visible;
}

.k-scrollview-prev {
  left: 0;
}

.k-scrollview-next {
  right: 0;
}

.k-scrollview-animation {
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
}

@supports ((-webkit-user-select: none)) {
  kendo-scrollview.k-scrollview-wrap ul.k-scrollview li > *, div.k-scrollview ul.k-scrollview-wrap li > * {
    pointer-events: auto;
  }
}

@supports not ((-webkit-user-select: none)) {
  kendo-scrollview.k-scrollview-wrap ul.k-scrollview li > *, div.k-scrollview ul.k-scrollview-wrap li > * {
    pointer-events: none;
  }
}

.k-switch {
  cursor: pointer;
  box-shadow: none;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  text-align: left;
  background: none;
  border: 0;
  border-radius: 5em;
  outline: 0;
  width: 5em;
  font-size: 12px;
  display: inline-flex;
  overflow: hidden;
}

.k-switch [type="checkbox"] {
  display: none;
}

.k-ie .k-switch {
  display: inline-block;
}

.k-switch, .k-switch-container, .k-switch-handle {
  box-sizing: border-box;
}

.k-switch-container {
  border-style: solid;
  border-width: 1px;
  border-radius: 5em;
  outline: 0;
  flex: 1 0 auto;
  padding: 2px;
  transition: background-color .2s ease-out;
  position: relative;
}

.k-ie .k-switch-container {
  display: block;
}

.k-switch-handle {
  vertical-align: middle;
  background-position: 50%;
  border-style: solid;
  border-width: 1px;
  border-radius: 5em;
  width: 2em;
  height: 2em;
  transition: left .2s ease-out;
  display: inline-block;
  position: relative;
  left: 0;
}

.k-ie11 .k-switch-handle {
  display: block;
}

.k-switch-on .k-switch-handle {
  left: calc(100% - 2em);
}

.k-ie .k-switch-on .k-switch-handle, .k-edge .k-switch-on .k-switch-handle {
  margin-left: -2em;
  left: 100%;
}

.k-switch-off .k-switch-handle {
  left: 0;
}

.k-switch-label-on, .k-switch-label-off {
  text-shadow: none;
  width: calc(100% - 2em - 12px);
  line-height: 2em;
  display: inline;
  position: absolute;
  top: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}

.k-switch-label-on {
  text-align: left;
  left: 7px;
}

.k-switch-label-off {
  text-align: right;
  right: 7px;
}

.k-rtl .k-switch.k-switch-on .k-switch-handle, .k-switch[dir="rtl"].k-switch-on .k-switch-handle {
  left: 0;
}

.k-rtl .k-switch.k-switch-off .k-switch-handle, .k-switch[dir="rtl"].k-switch-off .k-switch-handle {
  left: calc(100% - 2em);
}

.k-rtl .k-switch .k-switch-label-on, .k-switch[dir="rtl"] .k-switch-label-on {
  text-align: right;
  left: initial;
  right: 7px;
}

.k-rtl .k-switch .k-switch-label-off, .k-switch[dir="rtl"] .k-switch-label-off {
  text-align: left;
  left: 7px;
  right: initial;
}

.k-filter {
  box-shadow: none;
  border-width: 0;
  display: inline-block;
}

.k-filter ul {
  padding: 0;
}

.k-filter ul li {
  list-style-type: none;
}

.k-filter .k-filter-container, .k-filter .k-filter-preview {
  margin-bottom: 2.1em;
}

.k-filter .k-filter-lines, .k-filter .k-filter-item {
  padding-left: 8px;
}

.k-filter .k-filter-toolbar {
  padding: 4px 0;
  display: inline-flex;
  position: relative;
}

.k-filter .k-filter-toolbar:before {
  content: "";
  width: 8px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: -8px;
}

.k-filter .k-filter-toolbar .k-toolbar {
  border-style: solid;
  border-width: 1px;
}

.k-filter .k-filter-toolbar .k-filter-operator .k-dropdown {
  width: 15em;
}

.k-filter .k-filter-toolbar .k-filter-toolbar-item > .k-textbox {
  vertical-align: middle;
  margin: 0 .2em;
}

.k-filter .k-filter-item {
  position: relative;
}

.k-filter .k-filter-item:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: -4px;
  left: 0;
}

.k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar:after {
  content: "";
  width: 1px;
  position: absolute;
  top: -5px;
  bottom: 50%;
  left: -8px;
}

.k-filter .k-filter-group-main:before, .k-filter .k-filter-group-main > .k-filter-toolbar:before, .k-filter .k-filter-group-main > .k-filter-toolbar:after, .k-filter .k-filter-lines .k-filter-item:last-child:before {
  display: none;
}

.k-rtl .k-filter .k-filter-lines, .k-filter[dir="rtl"] .k-filter-lines, [dir="rtl"] .k-filter .k-filter-lines, .k-rtl .k-filter .k-filter-item, .k-filter[dir="rtl"] .k-filter-item, [dir="rtl"] .k-filter .k-filter-item {
  padding-left: 0;
  padding-right: 8px;
}

.k-rtl .k-filter .k-filter-toolbar:before, .k-filter[dir="rtl"] .k-filter-toolbar:before, [dir="rtl"] .k-filter .k-filter-toolbar:before {
  left: auto;
  right: -8px;
}

.k-rtl .k-filter .k-filter-item:before, .k-filter[dir="rtl"] .k-filter-item:before, [dir="rtl"] .k-filter .k-filter-item:before {
  left: auto;
  right: 0;
}

.k-rtl .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar:after, .k-filter[dir="rtl"] .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar:after, [dir="rtl"] .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar:after {
  left: auto;
  right: -8px;
}

.k-drawer-container {
  flex-flow: row;
  align-items: flex-start;
  display: flex;
}

.k-drawer {
  border-width: 0;
  flex-direction: column;
  flex: none;
  height: 100%;
  transition: all .3s ease-in-out;
  display: flex;
}

.k-drawer.k-drawer-start, .k-drawer-left.k-drawer-mini .k-drawer, .k-drawer-left.k-drawer-expanded .k-drawer {
  border-right-width: 1px;
}

.k-drawer.k-drawer-end, .k-drawer-right.k-drawer-mini .k-drawer, .k-drawer-right.k-drawer-expanded .k-drawer {
  border-left-width: 1px;
}

.k-drawer-left .k-drawer, .k-drawer.k-drawer-start {
  top: 0;
  left: 0;
}

.k-drawer-right .k-drawer, .k-drawer.k-drawer-end {
  top: 0;
  right: 0;
}

.k-drawer-content {
  flex: auto;
}

.k-drawer-overlay .k-drawer {
  z-index: 10002;
  max-width: 80vw;
  position: fixed;
}

.k-drawer-overlay .k-overlay {
  display: none;
}

.k-drawer-push {
  flex-direction: row;
  display: flex;
}

.k-drawer-push .k-drawer {
  align-self: stretch;
  height: auto;
  position: relative;
}

.k-drawer-push.k-drawer-right .k-drawer {
  order: 1;
}

.k-drawer-wrapper {
  scrollbar-width: thin;
  transition: all .3s ease-in-out;
  overflow: hidden auto;
}

.k-drawer-wrapper::-webkit-scrollbar {
  width: 7px;
}

.k-drawer-wrapper::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

.k-drawer-items, .k-drawer-items ul {
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.k-drawer-item {
  color: inherit;
  cursor: pointer;
  flex-flow: row;
  padding: 8px;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
}

.k-drawer-item .k-icon {
  flex-shrink: 0;
  margin: 0 8px;
  font-size: 18px;
}

.k-drawer-item .k-icon.k-i-none {
  margin: 0;
}

.k-drawer-item .k-item-text {
  white-space: nowrap;
  padding: 0 8px;
  display: inline-block;
}

.k-drawer-separator {
  height: 1px;
  padding: 0;
}

.k-drawer-mini .k-drawer-wrapper {
  width: 50px;
}

.k-rtl .k-drawer-left.k-drawer-push .k-drawer, [dir="rtl"] .k-drawer-left.k-drawer-push .k-drawer {
  order: 1;
}

.k-rtl .k-drawer-right.k-drawer-push .k-drawer, [dir="rtl"] .k-drawer-right.k-drawer-push .k-drawer {
  order: 0;
}

.k-rating {
  box-sizing: border-box;
  white-space: nowrap;
  box-shadow: none;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  display: inline-flex;
}

.k-rating-container {
  box-sizing: border-box;
  flex: none;
  margin: 0 4px;
  position: relative;
}

.k-rating-container .k-rating-item {
  vertical-align: middle;
  outline: 0;
  align-items: center;
  margin: 0;
  padding: 4px;
  transition: color .2s ease-out;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.k-rating-container .k-rating-item * {
  pointer-events: none;
}

.k-rating-container .k-rating-item .k-icon {
  font-size: 24px;
  overflow: visible;
}

.k-rating-container .k-rating-item .k-rating-precision-part, .k-rating-container .k-rating-item .k-rating-precision-complement {
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 4px;
  overflow: hidden;
}

.k-rating-container .k-rating-item .k-rating-precision-complement {
  direction: rtl;
}

.k-rating-label {
  vertical-align: middle;
  align-items: center;
  margin: 4px 8px;
  line-height: 1.5;
  display: inline-flex;
}

.k-ie9 .k-rating-label, .k-ie9 .k-rating-container, .k-ie9 .k-rating-item {
  display: inline-block;
}

.k-rtl .k-rating-precision-part, [dir="rtl"] .k-rating-precision-part {
  left: auto;
  right: 4px;
}

.k-rtl .k-rating-item .k-rating-precision-complement, [dir="rtl"] .k-rating-item .k-rating-precision-complement {
  direction: ltr;
}

.k-timeline ul {
  margin: 0;
  padding: 0;
}

.k-timeline ul li {
  list-style-type: none;
}

.k-timeline .k-timeline-flag {
  text-align: center;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 2px;
  min-width: 80px;
  max-width: 160px;
  padding: 4px 8px;
  line-height: 1.42857;
  display: inline-block;
  position: relative;
}

.k-timeline .k-timeline-circle {
  z-index: 1;
  border-radius: 50%;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  position: relative;
}

.k-timeline.k-timeline-dates-hidden .k-timeline-date, .k-timeline .k-event-collapse {
  display: none;
}

.k-timeline.k-timeline-collapsible .k-card-header {
  cursor: pointer;
}

.k-timeline.k-timeline-collapsible .k-event-collapse {
  display: flex;
}

.k-timeline .k-timeline-arrow {
  z-index: 3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 11px;
}

.k-timeline .k-timeline-arrow-left {
  left: 0;
}

.k-timeline .k-timeline-arrow-right {
  right: 0;
}

.k-timeline-card .k-card {
  position: relative;
  overflow: visible;
}

.k-timeline-card .k-card .k-card-header {
  border-bottom: 0;
  border-radius: 4px;
  overflow: visible;
}

.k-timeline-card .k-card .k-card-header + .k-card-body {
  padding-top: 0;
}

.k-timeline-card .k-card .k-card-body {
  scrollbar-width: thin;
  overflow-y: auto;
}

.k-timeline-card .k-card .k-card-body::-webkit-scrollbar {
  width: 5px;
}

.k-timeline-card .k-card .k-card-body::-webkit-scrollbar-thumb {
  border-radius: 2px;
}

.k-timeline-card .k-timeline-card-callout.k-callout-w, .k-timeline-card .k-timeline-card-callout.k-callout-e {
  top: 36px;
}

.k-timeline-vertical, .k-timeline-horizontal {
  box-sizing: border-box;
  border: 0;
  width: 100%;
  margin: 40px 0;
  padding: 0 40px;
  position: relative;
}

.k-timeline-vertical:after, .k-timeline-horizontal .k-timeline-track-wrap:after {
  content: "";
  background-clip: padding-box;
  border-style: solid;
  border-width: 1px;
  position: absolute;
  top: 0;
}

.k-timeline-vertical {
  padding-left: 127px;
}

.k-timeline-vertical.k-timeline-dates-hidden {
  padding-left: 77px;
}

.k-timeline-vertical:after {
  border-radius: 4px;
  width: 6px;
  height: 100%;
  transform: translateX(-50%);
}

.k-timeline-vertical .k-timeline-flag-wrap {
  align-items: center;
  display: flex;
}

.k-timeline-vertical .k-timeline-flag-wrap:first-child {
  padding-top: 40px;
}

.k-timeline-vertical .k-timeline-flag {
  transform: translateX(-50%);
}

.k-timeline-vertical .k-timeline-date-wrap {
  text-align: right;
  margin-top: 36px;
  padding-right: 16px;
  position: absolute;
  transform: translate(-100%, -50%);
}

.k-timeline-vertical .k-timeline-event {
  align-items: flex-start;
  padding: 40px 0;
  display: flex;
}

.k-timeline-vertical .k-timeline-card {
  padding-left: 16px;
}

.k-timeline-vertical .k-timeline-card .k-card {
  width: 400px;
  max-width: 100%;
  min-height: 72px;
  margin-left: 16px;
}

.k-timeline-vertical .k-timeline-card .k-card .k-card-title {
  justify-content: space-between;
  display: flex;
}

.k-timeline-vertical .k-timeline-card .k-card .k-card-title .k-event-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.k-timeline-vertical .k-timeline-card .k-event-collapse {
  margin: -4px 0;
  transition: transform .2s ease-in-out;
  transform: rotate(90deg);
}

.k-timeline-vertical .k-timeline-card.k-collapsed .k-card-body, .k-timeline-vertical .k-timeline-card.k-collapsed .k-card-actions {
  display: none;
}

.k-timeline-vertical .k-timeline-card.k-collapsed .k-event-collapse {
  transform: rotate(0);
}

.k-timeline-vertical .k-timeline-circle {
  margin-top: 36px;
  margin-right: -8px;
  transform: translate(-50%, -50%);
}

.k-timeline-vertical.k-timeline-alternating {
  padding-left: 40px;
}

.k-timeline-vertical.k-timeline-alternating:after {
  left: 50%;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-flag-wrap {
  justify-content: center;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-flag {
  transform: translateX(0);
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-event {
  justify-content: space-between;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse {
  flex-direction: row-reverse;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse .k-timeline-date-wrap {
  text-align: left;
  padding-left: 16px;
  padding-right: 0;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse .k-timeline-card {
  padding-left: 0;
  padding-right: 16px;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-event.k-reverse .k-timeline-card .k-card {
  margin-left: auto;
  margin-right: 16px;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-card, .k-timeline-vertical.k-timeline-alternating .k-timeline-date-wrap {
  flex-basis: 50%;
  min-width: 0;
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-date-wrap {
  position: static;
  transform: translateY(-50%);
}

.k-timeline-vertical.k-timeline-alternating .k-timeline-circle {
  margin-right: 0;
  transform: translateY(-50%);
}

.k-timeline-horizontal .k-timeline-track-item {
  flex-direction: column;
  flex: 1 0 20%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.k-timeline-horizontal .k-timeline-track-item:hover {
  cursor: pointer;
}

.k-timeline-horizontal .k-timeline-track-item.k-timeline-flag-wrap:hover {
  cursor: default;
}

.k-timeline-horizontal .k-timeline-date-wrap {
  margin-top: auto;
  margin-bottom: 8px;
}

.k-timeline-horizontal .k-timeline-card {
  box-sizing: border-box;
  height: 100%;
}

.k-timeline-horizontal .k-card {
  box-sizing: border-box;
  max-height: 100%;
}

.k-timeline-horizontal .k-timeline-events-list {
  padding-top: 16px;
  overflow-x: hidden;
}

.k-timeline-horizontal .k-timeline-events-list .k-timeline-scrollable-wrap {
  height: 600px;
  position: relative;
}

.k-timeline-horizontal .k-timeline-events-list .k-timeline-scrollable-wrap .k-timeline-event {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.k-timeline-horizontal .k-timeline-track-wrap {
  padding: 15px 0;
  position: relative;
}

.k-timeline-horizontal .k-timeline-track-wrap:after {
  height: 6px;
  inset: auto 28px 18px;
  transform: translateY(-50%);
}

.k-timeline-horizontal .k-timeline-track-wrap .k-timeline-track {
  z-index: 2;
  margin: 0 30px;
  position: relative;
  overflow: hidden;
}

.k-timeline-horizontal .k-timeline-track-wrap .k-timeline-track .k-timeline-scrollable-wrap {
  transition: transform 1s ease-in-out;
}

.k-timeline-horizontal .k-timeline-track-wrap .k-timeline-flag {
  min-width: 60px;
  margin-bottom: 22px;
  position: relative;
}

.k-timeline-horizontal .k-timeline-track-wrap .k-timeline-flag:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%)rotate(45deg);
}

.k-timeline-horizontal .k-timeline-scrollable-wrap {
  outline: 0;
  padding-bottom: 3px;
  display: flex;
}

.k-timeline-mobile {
  margin: 16px 0;
  padding: 0 16px;
}

.k-timeline-mobile .k-timeline-flag {
  max-width: 112px;
}

.k-timeline-mobile.k-timeline-horizontal .k-timeline-flag-wrap {
  display: none;
}

.k-timeline-mobile.k-timeline-vertical {
  padding-left: 103px;
}

.k-timeline-mobile.k-timeline-vertical.k-timeline-dates-hidden {
  padding-left: 53px;
}

.k-timeline-mobile.k-timeline-vertical.k-timeline-alternating {
  padding-left: 16px;
}

.k-timeline-mobile.k-timeline-vertical .k-timeline-card {
  width: 100%;
}

.k-timeline-mobile.k-timeline-vertical .k-timeline-card .k-card {
  width: auto;
}

.k-timeline-mobile.k-timeline-vertical .k-card-actions > .k-button.k-flat:first-child {
  margin-left: 0;
}

.k-breadcrumb {
  box-sizing: border-box;
  background-color: #0000;
  border-style: solid;
  border-width: 0;
  flex-direction: row;
  min-height: 2.11em;
  display: flex;
}

.k-breadcrumb .k-breadcrumb-container {
  flex-flow: row;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.k-breadcrumb .k-breadcrumb-item {
  vertical-align: middle;
  flex-direction: row;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
}

.k-breadcrumb .k-breadcrumb-root-item {
  flex: 1 0 auto;
}

.k-breadcrumb .k-breadcrumb-link, .k-breadcrumb .k-breadcrumb-root-link {
  white-space: nowrap;
  border-radius: 4px;
  outline: none;
  align-self: stretch;
  align-items: center;
  padding: 4px 8px;
  line-height: 1.6em;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.k-breadcrumb .k-breadcrumb-root-link {
  margin-right: 8px;
}

.k-breadcrumb .k-breadcrumb-icon-link {
  padding: 8px;
}

.k-breadcrumb .k-breadcrumb-icontext-link .k-icon {
  margin-right: 8px;
}

.k-breadcrumb .k-breadcrumb-delimiter-icon {
  font-size: 12px;
}

.k-breadcrumb .k-breadcrumb-input-container, .k-breadcrumb .k-breadcrumb-input-container .k-textbox {
  width: 100%;
  height: 100%;
}

.k-no-flexbox .k-breadcrumb, .k-no-flexbox .k-breadcrumb-container {
  display: block;
}

.k-no-flexbox .k-breadcrumb-item {
  display: inline-block;
}

.k-listgroup {
  font: inherit;
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  margin: 0;
  padding: 0;
  line-height: 1.42857;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.k-listgroup > ul {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.k-listgroup .k-listgroup-item + .k-listgroup-item {
  border-top-width: 1px;
}

.k-listgroup-flush {
  border-left-width: 0;
  border-right-width: 0;
}

.k-listgroup-item {
  border-style: solid;
  border-width: 0;
  border-color: inherit;
  flex-direction: row;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  padding: .5em 1em;
  display: flex;
  position: relative;
}

.k-listgroup-item > .k-link {
  color: inherit;
  outline: 0;
  flex-direction: row;
  flex: auto;
  justify-content: flex-start;
  align-items: center;
  margin: -.5em -1em;
  padding: .5em 1em;
  text-decoration: none;
  display: flex;
  position: relative;
}

.k-listgroup-item > .k-link > .k-select {
  flex: none;
  align-items: center;
  padding: .5em;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-listgroup-item-segmented {
  align-items: stretch;
}

.k-listgroup-item-segmented > .k-link {
  margin-right: 0;
}

.k-listgroup-item-segmented > .k-select {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: inherit;
  cursor: pointer;
  margin: -.5em -1em -.5em 0;
  padding: .5em;
}

.k-listgroup-form-row {
  flex-direction: row;
  flex: auto;
  justify-content: space-between;
  align-items: center;
  margin: -.5em -1em;
  padding: .5em 1em;
  display: flex;
  position: relative;
}

.k-listgroup-form-row .k-listgroup-form-field-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 40%;
  overflow: hidden;
}

.k-listgroup-form-row .k-listgroup-form-field-wrapper {
  text-align: end;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  display: flex;
  position: relative;
}

.k-listgroup-form-row .k-listgroup-form-field-wrapper select, .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type="text"], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type="password"], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type="date"], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type="time"], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type="datetime-local"], .k-listgroup-form-row .k-listgroup-form-field-wrapper input[type="number"] {
  box-sizing: border-box;
  flex: 100%;
  width: 100%;
}

.k-listgroup-item.k-listgroup-form-row {
  margin: 0;
}

.k-rtl .k-listgroup-item > .k-link > .k-select, [dir="rtl"] .k-listgroup-item > .k-link > .k-select {
  left: 0;
  right: auto;
}

.k-rtl .k-listgroup-item > .k-link > .k-select .k-icon, [dir="rtl"] .k-listgroup-item > .k-link > .k-select .k-icon {
  transform: scaleX(-1);
}

.k-rtl .k-listgroup-item-segmented > .k-link, [dir="rtl"] .k-listgroup-item-segmented > .k-link {
  margin-left: 0;
  margin-right: -1em;
}

.k-rtl .k-listgroup-item-segmented > .k-select, [dir="rtl"] .k-listgroup-item-segmented > .k-select {
  border-left-width: 0;
  border-right-width: 1px;
  margin-left: -1em;
  margin-right: 0;
}

.k-rtl .k-listgroup-item-segmented > .k-select .k-icon, [dir="rtl"] .k-listgroup-item-segmented > .k-select .k-icon {
  transform: scaleX(-1);
}

.k-noflexbox .k-listgroup, .k-noflexbox .k-listgroup > ul .k-listgroup-item, .k-noflexbox .k-listgroup-item > .k-link {
  display: block;
}

.k-messagebox {
  box-sizing: border-box;
  font-size: inherit;
  border-style: solid;
  border-width: 0 0 0 4px;
  margin: 0 0 16px;
  padding: 4px 8px;
  line-height: 1.42857;
}

.k-messagebox a {
  color: inherit;
  font-style: italic;
  text-decoration: underline;
}

.k-badge {
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-overflow: ellipsis;
  background-color: #0000;
  border: 0 solid #0000;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
  overflow: hidden;
}

.k-badge > * {
  color: inherit;
}

a.k-badge, .k-badge > a {
  text-decoration: none;
}

a.k-badge:hover, .k-badge > a:hover {
  text-decoration: underline;
}

.k-badge-icon {
  font-size: inherit;
  max-width: 1em;
  max-height: 1em;
}

.k-badge-icon.k-svg-icon svg {
  fill: currentColor;
}

.k-badge-sm {
  border-width: 1px;
  padding: 2px;
  font-size: 10px;
  line-height: 1;
}

.k-badge-sm:empty {
  padding: 2px;
}

.k-badge-md {
  border-width: 1px;
  padding: 4px;
  font-size: 10px;
  line-height: 1;
}

.k-badge-md:empty {
  padding: 4px;
}

.k-badge-lg {
  border-width: 1px;
  padding: 6px;
  font-size: 12px;
  line-height: 1;
}

.k-badge-lg:empty {
  padding: 6px;
}

.k-badge-rounded.k-badge-sm {
  border-radius: 1px;
}

.k-badge-rounded.k-badge-md {
  border-radius: 2px;
}

.k-badge-rounded.k-badge-lg {
  border-radius: 4px;
}

.k-badge-pill {
  border-radius: 5rem;
}

.k-badge-circle {
  border-radius: 100%;
  padding: 0 !important;
}

.k-badge-circle.k-badge-sm {
  width: calc(1em + 6px);
  height: calc(1em + 6px);
}

.k-badge-circle.k-badge-md {
  width: calc(1em + 10px);
  height: calc(1em + 10px);
}

.k-badge-circle.k-badge-lg {
  width: calc(1em + 14px);
  height: calc(1em + 14px);
}

.k-badge-dot {
  border-radius: 100%;
  font-size: 0;
  padding: 0 !important;
}

.k-badge-dot.k-badge-sm {
  width: 6px;
  height: 6px;
}

.k-badge-dot.k-badge-md {
  width: 10px;
  height: 10px;
}

.k-badge-dot.k-badge-lg {
  width: 12px;
  height: 12px;
}

.k-badge-container {
  position: relative;
  overflow: visible;
}

.k-badge-inside, .k-badge-edge {
  z-index: 9999;
  position: absolute;
}

.k-badge-edge.k-top-start {
  transform: translate(-50%, -50%);
}

.k-badge-edge.k-top-end {
  transform: translate(50%, -50%);
}

.k-badge-edge.k-bottom-start {
  transform: translate(-50%, 50%);
}

.k-badge-edge.k-bottom-end {
  transform: translate(50%, 50%);
}

.k-rtl .k-badge-edge.k-top-start, [dir="rtl"].k-badge-edge.k-top-start, [dir="rtl"] .k-badge-edge.k-top-start {
  transform: translate(50%, -50%);
}

.k-rtl .k-badge-edge.k-top-end, [dir="rtl"].k-badge-edge.k-top-end, [dir="rtl"] .k-badge-edge.k-top-end {
  transform: translate(-50%, -50%);
}

.k-rtl .k-badge-edge.k-bottom-start, [dir="rtl"].k-badge-edge.k-bottom-start, [dir="rtl"] .k-badge-edge.k-bottom-start {
  transform: translate(50%, 50%);
}

.k-rtl .k-badge-edge.k-bottom-end, [dir="rtl"].k-badge-edge.k-bottom-end, [dir="rtl"] .k-badge-edge.k-bottom-end {
  transform: translate(-50%, 50%);
}

.k-badge-outside {
  z-index: 9999;
  position: absolute;
}

.k-badge-outside.k-top-start {
  transform: translate(-100%, -100%);
}

.k-badge-outside.k-top-end {
  transform: translate(100%, -100%);
}

.k-badge-outside.k-bottom-start {
  transform: translate(-100%, 100%);
}

.k-badge-outside.k-bottom-end {
  transform: translate(100%, 100%);
}

.k-rtl .k-badge-outside.k-top-start, [dir="rtl"].k-badge-outside.k-top-start, [dir="rtl"] .k-badge-outside.k-top-start {
  transform: translate(100%, -100%);
}

.k-rtl .k-badge-outside.k-top-end, [dir="rtl"].k-badge-outside.k-top-end, [dir="rtl"] .k-badge-outside.k-top-end {
  transform: translate(-100%, -100%);
}

.k-rtl .k-badge-outside.k-bottom-start, [dir="rtl"].k-badge-outside.k-bottom-start, [dir="rtl"] .k-badge-outside.k-bottom-start {
  transform: translate(100%, 100%);
}

.k-rtl .k-badge-outside.k-bottom-end, [dir="rtl"].k-badge-outside.k-bottom-end, [dir="rtl"] .k-badge-outside.k-bottom-end {
  transform: translate(-100%, 100%);
}

.k-checkbox {
  line-height: initial;
  box-sizing: border-box;
  vertical-align: middle;
  -webkit-appearance: none;
  border-style: solid;
  border-width: 1px;
  outline: 0;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.k-checkbox:before {
  content: "";
  width: 12px;
  height: 12px;
  font-family: WebComponentsIcons, monospace;
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: scale(0)translate(-50%, -50%);
}

.k-checkbox:hover {
  cursor: pointer;
}

.k-checkbox:checked:before {
  transform: scale(1)translate(-50%, -50%);
}

.k-checkbox:indeterminate:before, .k-checkbox.k-state-indeterminate:before {
  content: "";
  background-color: currentColor;
  width: 8px;
  height: 8px;
  transform: scale(1)translate(-50%, -50%);
}

.k-checkbox:disabled, .k-checkbox:disabled + .k-checkbox-label {
  opacity: .6;
  filter: grayscale(.8);
  cursor: default;
}

.k-checkbox + .k-checkbox-label {
  display: inline;
}

.k-checkbox-label {
  vertical-align: middle;
  cursor: pointer;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  line-height: 17px;
  display: inline-flex;
  position: relative;
}

.k-checkbox-label:empty {
  display: none;
}

.k-checkbox-label.k-no-text {
  min-width: 1px;
}

.k-checkbox-label .k-ripple {
  visibility: hidden !important;
}

.k-checkbox + .k-checkbox-label, .k-checkbox-label + .k-checkbox {
  margin-left: 4px;
}

.k-checkbox-label > .k-checkbox {
  flex-shrink: 0;
  margin-right: 4px;
}

.k-checkbox-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.k-checkbox-list .k-checkbox-item {
  margin-top: 16px;
}

.k-checkbox-list .k-checkbox-item:first-child {
  margin-top: 0;
}

.k-list-horizontal .k-checkbox-item {
  margin: 0 32px 0 0;
  display: inline-block;
}

.k-list-horizontal .k-checkbox-item:last-child {
  margin-right: 0;
}

.k-rtl .k-checkbox + .k-checkbox-label, [dir="rtl"] .k-checkbox + .k-checkbox-label {
  margin-left: 0;
  margin-right: 4px;
}

.k-rtl .k-checkbox-label > .k-checkbox, [dir="rtl"] .k-checkbox-label > .k-checkbox {
  margin-left: 4px;
  margin-right: 0;
}

.k-rtl .k-list-horizontal .k-checkbox-item, [dir="rtl"] .k-list-horizontal .k-checkbox-item {
  margin-left: 32px;
  margin-right: 0;
}

.k-rtl .k-list-horizontal .k-checkbox-item:last-child, [dir="rtl"] .k-list-horizontal .k-checkbox-item:last-child {
  margin-left: 0;
}

.k-checkbox::-ms-check {
  border-width: 0;
  border-color: inherit;
  border-radius: inherit;
  color: inherit;
  background-color: inherit;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .k-checkbox::-ms-check {
    border-width: 1px;
  }
}

.k-radio {
  box-sizing: border-box;
  vertical-align: middle;
  -webkit-appearance: none;
  border-style: solid;
  border-width: 1px;
  outline: 0;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.k-radio:before {
  content: "";
  background-color: currentColor;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: scale(0)translate(-50%, -50%);
}

.k-radio:hover {
  cursor: pointer;
}

.k-radio:checked:before {
  transform: scale(1)translate(-50%, -50%);
}

.k-radio:disabled, .k-radio:disabled + .k-radio-label {
  opacity: .6;
  filter: grayscale(.8);
  cursor: default;
}

.k-radio + .k-radio-label {
  display: inline;
}

.k-radio-label {
  vertical-align: middle;
  cursor: pointer;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  line-height: 17px;
  display: inline-flex;
  position: relative;
}

.k-radio-label:empty, .k-radio-label.k-no-text {
  display: none;
}

.k-radio-label .k-ripple {
  display: none !important;
}

.k-radio + .k-radio-label, .k-radio-label + .k-radio {
  margin-left: 4px;
}

.k-radio-label > .k-radio {
  flex-shrink: 0;
  margin-right: 4px;
}

.k-radio-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.k-radio-list .k-radio-item {
  margin-top: 16px;
}

.k-radio-list .k-radio-item:first-child {
  margin-top: 0;
}

.k-list-horizontal .k-radio-item {
  margin: 0 32px 0 0;
  display: inline-block;
}

.k-list-horizontal .k-radio-item:last-child {
  margin-right: 0;
}

.k-rtl .k-radio + .k-radio-label, [dir="rtl"] .k-radio + .k-radio-label, .k-rtl .k-radio-label + .k-radio, [dir="rtl"] .k-radio-label + .k-radio {
  margin-left: 0;
  margin-right: 4px;
}

.k-rtl .k-radio-label > .k-radio, [dir="rtl"] .k-radio-label > .k-radio {
  margin-left: 4px;
  margin-right: 0;
}

.k-rtl .k-list-horizontal .k-radio-item, [dir="rtl"] .k-list-horizontal .k-radio-item {
  margin-left: 32px;
  margin-right: 0;
}

.k-rtl .k-list-horizontal .k-radio-item:last-child, [dir="rtl"] .k-list-horizontal .k-radio-item:last-child {
  margin-left: 0;
}

.k-radio::-ms-check {
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background-color: inherit;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .k-radio::-ms-check {
    border-width: 1px;
  }
}

.k-filemanager {
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  display: flex;
}

.k-filemanager-header {
  border-color: inherit;
}

.k-filemanager-toolbar {
  border-width: 0 0 1px;
  border-color: inherit;
  flex-shrink: 0;
}

.k-filemanager-search-tool {
  flex-flow: row;
  display: inline-flex;
}

.k-filemanager-content-container {
  border-color: inherit;
  box-sizing: border-box;
  flex-flow: row;
  flex: 1;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.k-filemanager-navigation {
  border-style: solid;
  border-width: 0 1px 0 0;
  border-color: inherit;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 20%;
  overflow: hidden;
}

.k-filemanager-navigation > .k-treeview {
  box-sizing: border-box;
  height: 100%;
  padding: 16px;
}

.k-filemanager-treeview > .k-group > .k-item {
  padding: 0;
}

.k-filemanager-content {
  border-color: inherit;
  flex-flow: column;
  flex: 1;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.k-filemanager-breadcrumb {
  border-width: 0 0 1px;
  flex-shrink: 0;
  padding: 8px;
}

.k-filemanager-view {
  flex-flow: row;
  flex: auto;
  display: flex;
}

.k-filemanager-view > .k-filemanager-listview, .k-filemanager-view > .k-filemanager-grid {
  border-width: 0;
}

.k-filemanager-listview {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.k-filemanager-listview .k-listview-item {
  text-align: center;
  width: 120px;
  height: 120px;
  padding: 16px;
}

.k-filemanager-listview .k-file-preview {
  flex-direction: row;
  justify-content: center;
  height: 48px;
  display: flex;
}

.k-filemanager-listview .k-file-icon {
  font-size: 48px;
}

.k-filemanager-listview .k-file-image {
  max-width: 100%;
  max-height: 48px;
  display: block;
}

.k-filemanager-listview .k-file-name, .k-filemanager-listview .k-file-size {
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
  display: block;
  overflow: hidden;
}

.k-filemanager-listview .k-edit-item .k-textbox {
  width: 100%;
}

.k-filemanager-grid {
  flex: 1;
}

.k-filemanager-grid .k-file-preview {
  vertical-align: middle;
  flex-direction: row;
  margin-right: 4px;
  display: inline-flex;
}

.k-filemanager-grid .k-file-name {
  vertical-align: middle;
  flex-direction: row;
  display: inline-flex;
}

.k-filemanager-preview {
  border-style: solid;
  border-width: 0 0 0 1px;
  border-color: inherit;
  box-sizing: border-box;
  text-align: center;
  flex-shrink: 0;
  width: 20%;
  min-height: 304px;
  padding: 48px 16px 16px;
  overflow-x: auto;
}

.k-filemanager-preview .k-file-preview {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.k-filemanager-preview .k-file-icon {
  font-size: 96px;
}

.k-filemanager-preview .k-file-name {
  margin-top: 16px;
  display: block;
}

.k-filemanager-preview .k-single-file-selected, .k-filemanager-preview .k-multiple-files-selected {
  font-weight: bold;
}

.k-filemanager-preview .k-file-size {
  font-size: inherit;
  line-height: normal;
}

.k-filemanager-preview .k-file-meta {
  box-sizing: border-box;
  text-align: start;
  flex-flow: wrap;
  margin: 16px 0 0;
  display: flex;
}

.k-filemanager-preview .k-file-meta-label {
  display: inline-block;
}

.k-filemanager-preview .k-file-meta-value {
  margin: 0;
}

.k-filemanager-preview .k-file-type {
  text-transform: capitalize;
}

.k-filemanager-preview .k-line-break {
  flex-flow: row;
  flex: 100%;
  width: 100%;
  margin: 16px 0 0;
}

.k-filemanager-resizable .k-filemanager-navigation, .k-filemanager-resizable .k-filemanager-preview {
  border-width: 0;
}

.k-filemanager .k-splitbar {
  flex-shrink: 0;
  display: inline-flex;
  position: relative;
}

.k-filemanager .k-splitbar-horizontal {
  flex-direction: column;
  align-items: center;
  width: 6px;
}

.k-filemanager .k-splitbar-horizontal .k-resize-handle {
  width: 2px;
}

.k-filemanager-upload-dialog .k-upload-files {
  max-height: 200px;
  overflow-y: auto;
}

.k-filemanager-drag-hint {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.k-filemanager-drag-hint .k-icon {
  margin-right: 4px;
}

.k-window {
  box-sizing: border-box;
  z-index: 10001;
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  padding: 0;
  line-height: 1.42857;
  display: inline-flex;
  position: absolute;
}

.k-window .k-overlay {
  opacity: 0;
  position: absolute;
}

.k-window.k-window-maximized {
  box-shadow: none;
  border-radius: 0;
}

.k-window.k-window-maximized .k-window-titlebar, .k-window.k-window-maximized .k-window-content {
  border-radius: 0;
}

.k-window-sm {
  width: 300px;
}

.k-window-md {
  width: 800px;
}

.k-window-lg {
  width: 1200px;
}

.k-window-titlebar {
  box-sizing: border-box;
  white-space: nowrap;
  background-position: 0 0;
  background-repeat: repeat-x;
  border-style: solid;
  border-width: 0 0 1px;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  min-height: 16px;
  padding: 8px 12px;
  position: relative;
}

.k-window-title {
  text-overflow: ellipsis;
  cursor: default;
  flex-flow: row;
  flex: 1;
  font-size: 1.2em;
  font-weight: normal;
  line-height: 1.25;
  display: flex;
  overflow: hidden;
}

.k-window-title:before {
  content: "";
  display: inline-block;
}

.k-window-titlebar .k-window-actions {
  margin: 0 -6px;
  flex-shrink: 0;
  align-items: center;
  margin-inline-end: -6px;
}

.k-window-titlebar .k-window-action {
  color: inherit;
  box-sizing: content-box;
  opacity: .7;
  border: 0 solid #0000;
  width: 20px;
  height: 20px;
  padding: 2px;
}

.k-window-titlebar .k-window-action:hover, .k-window-titlebar .k-window-action .k-icon {
  opacity: 1;
}

.k-window-content, .k-prompt-container {
  box-sizing: border-box;
  outline: 0;
  flex: auto;
  padding: 12px;
  position: relative;
  overflow: auto;
}

.k-window-content + .k-prompt-container {
  margin-top: -12px;
}

.k-prompt-container .k-textbox {
  width: 100%;
}

.k-window-iframecontent {
  padding: 0;
  overflow: visible;
}

.k-window-iframecontent .k-content-frame {
  vertical-align: top;
  border: 0;
  width: 100%;
  height: 100%;
}

.k-window-content > .km-scroll-container {
  height: 100%;
}

.k-window > .k-resize-handle {
  z-index: 1;
  opacity: 0;
  zoom: 1;
  background-color: #fff;
  font-size: 0;
  line-height: 6px;
  position: absolute;
}

.k-resize-n {
  cursor: n-resize;
  width: 100%;
  height: 6px;
  top: 0;
  left: 0;
}

.k-resize-e {
  cursor: e-resize;
  width: 6px;
  height: 100%;
  top: 0;
  right: 0;
}

.k-resize-s {
  cursor: s-resize;
  width: 100%;
  height: 6px;
  bottom: 0;
  left: 0;
}

.k-resize-w {
  cursor: w-resize;
  width: 6px;
  height: 100%;
  top: 0;
  left: 0;
}

.k-resize-se {
  cursor: se-resize;
  width: 6px;
  height: 6px;
  bottom: 0;
  right: 0;
}

.k-resize-sw {
  cursor: sw-resize;
  width: 6px;
  height: 6px;
  bottom: 0;
  left: 0;
}

.k-resize-ne {
  cursor: ne-resize;
  width: 6px;
  height: 6px;
  top: 0;
  right: 0;
}

.k-resize-nw {
  cursor: nw-resize;
  width: 6px;
  height: 6px;
  top: 0;
  left: 0;
}

.k-overlay {
  z-index: 10001;
  opacity: .5;
  -webkit-backface-visibility: hidden;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.k-window > .k-action-buttons, .k-window-content > .k-action-buttons {
  border-style: solid;
  border-width: 1px 0 0;
}

.k-ie9 .k-window-content {
  border-radius: 0;
  height: calc(100% + -1.25em - 16px);
}

.k-ie9 .k-window-titlebar .k-window-actions, .k-ie9 .k-window-title {
  display: inline-block;
}

.k-rtl .k-window-titlebar .k-window-actions {
  left: 6px;
  right: auto;
}

.k-dialog {
  box-sizing: border-box;
  min-width: 90px;
  max-width: 100%;
  min-height: 3em;
  max-height: 100%;
  position: fixed;
  overflow: hidden;
}

.k-dialog-centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k-dialog-titleless .k-dialog-close {
  z-index: 9999;
  position: absolute;
  top: 4px;
  right: 4px;
}

.k-dialog-titleless .k-dialog-content {
  padding: 2.4em;
}

.k-dialog-buttongroup {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px 0 0;
  width: 100%;
  margin: 0;
  padding: 6px;
}

.k-dialog-buttongroup .k-button {
  text-overflow: ellipsis;
  box-sizing: border-box;
  flex-shrink: 0;
  display: inline-block;
}

.k-rtl.k-dialog-titleless .k-dialog-close, .k-rtl .k-dialog-titleless .k-dialog-close {
  left: 4px;
  right: auto;
}

.k-stepper {
  color: inherit;
  box-shadow: none;
  background: none;
  border-style: solid;
  border-width: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.42857;
  position: relative;
}

.k-stepper .k-step-list {
  z-index: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.k-stepper .k-step-link {
  white-space: nowrap;
  outline: none;
  align-items: center;
  text-decoration: none;
  display: flex;
  overflow: hidden;
}

.k-stepper .k-step-disabled {
  pointer-events: none;
}

.k-stepper .k-step-disabled .k-step-link {
  cursor: default;
}

.k-stepper .k-step-indicator {
  z-index: 1;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  margin: 3px;
  transition-property: color, background-color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
  overflow: visible;
}

.k-stepper .k-step-indicator:after {
  content: "";
  border-style: solid;
  border-width: 1px;
  border-color: inherit;
  pointer-events: none;
  z-index: 2;
  border-radius: 100%;
  display: none;
  position: absolute;
  inset: -4px;
}

.k-stepper .k-step-focus .k-step-indicator:after, .k-stepper .k-step-link:focus .k-step-indicator:after {
  display: block;
}

.k-stepper .k-step-label {
  z-index: 1;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 10em;
  display: inline-flex;
}

.k-stepper .k-step-label:only-child {
  border-width: 0;
  border-radius: 15px;
  padding: 5px 12px;
}

.k-stepper .k-step-label .k-step-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: calc(10em - 20px);
  overflow: hidden;
}

.k-stepper .k-step-label .k-icon {
  margin-left: 4px;
}

.k-stepper .k-step-current .k-step-label, .k-stepper .k-step-hover .k-step-label, .k-stepper .k-step:hover .k-step-label {
  font-weight: bold;
}

.k-stepper .k-step-label .k-step-label-optional {
  flex-basis: 100%;
  font-style: italic;
}

.k-stepper .k-progressbar {
  pointer-events: none;
  z-index: 0;
  position: absolute;
}

.k-step-list-horizontal {
  flex-direction: row;
}

.k-step-list-horizontal .k-step {
  flex: 1 0 auto;
}

.k-step-list-horizontal .k-step-link {
  flex-direction: column;
}

.k-step-list-horizontal .k-step-label {
  text-align: center;
}

.k-step-list-horizontal .k-step-indicator + .k-step-label {
  margin-top: 5px;
}

.k-step-list-horizontal ~ .k-progressbar {
  width: 100%;
  height: 2px;
  top: 16px;
}

.k-step-list-vertical {
  flex-direction: column;
}

.k-step-list-vertical .k-step {
  min-height: 54px;
}

.k-step-list-vertical .k-step-label {
  text-align: left;
}

.k-step-list-vertical .k-step-indicator + .k-step-label {
  margin-left: 12px;
}

.k-step-list-vertical ~ .k-progressbar {
  width: 2px;
  height: 100%;
  min-height: 20px;
  left: 17px;
}

.k-step-list-vertical .k-step-content {
  height: 0;
  transition: height .3s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.k-step-list-vertical .k-step-current .k-step-content {
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  margin-left: 30px;
  padding: 20px 10px;
  display: flex;
  overflow: visible;
}

.k-rtl .k-stepper, .k-stepper[dir="rtl"] {
  direction: rtl;
}

.k-rtl .k-stepper .k-step-label .k-icon, .k-stepper[dir="rtl"] .k-step-label .k-icon {
  margin-left: 0;
  margin-right: 4px;
}

.k-rtl .k-stepper .k-step-list-vertical .k-step-indicator + .k-step-label, .k-stepper[dir="rtl"] .k-step-list-vertical .k-step-indicator + .k-step-label {
  margin-left: 0;
  margin-right: 12px;
}

.k-rtl .k-stepper .k-step-list-vertical ~ .k-progressbar, .k-stepper[dir="rtl"] .k-step-list-vertical ~ .k-progressbar {
  left: auto;
  right: 17px;
}

.k-wizard {
  font-size: inherit;
  color: inherit;
  background: none;
  border-width: 0;
  align-items: stretch;
  padding: 16px;
  line-height: 1.42857;
  display: flex;
  position: relative;
  overflow: hidden;
}

.k-wizard .k-stepper .k-step-list-vertical .k-step {
  flex: 1 0 auto;
}

.k-wizard .k-stepper .k-step-indicator {
  flex-shrink: 0;
}

.k-wizard-steps {
  display: flex;
  overflow: hidden;
}

.k-wizard-step {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  padding: 10px;
  display: flex;
}

.k-wizard-step:focus, .k-wizard-step.k-state-focused {
  outline-offset: -1px;
  outline-width: 1px;
  outline-style: dotted;
}

.k-wizard-content {
  overflow-y: auto;
}

.k-wizard-buttons {
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  display: flex;
}

.k-wizard-buttons .k-button {
  margin-right: 8px;
}

.k-wizard-buttons .k-wizard-pager {
  vertical-align: middle;
}

.k-wizard-buttons-right .k-button:last-of-type {
  margin-right: 0;
}

.k-wizard-horizontal {
  flex-direction: column;
}

.k-wizard-horizontal .k-wizard-steps {
  margin-top: 32px;
}

.k-wizard-horizontal .k-wizard-steps, .k-wizard-horizontal .k-wizard-content {
  flex: auto;
}

.k-wizard-vertical .k-wizard-steps {
  flex: 1 0;
}

.k-wizard-vertical .k-wizard-content {
  flex: auto;
}

.k-wizard-vertical .k-wizard-steps {
  margin-left: 32px;
}

.k-wizard-left {
  flex-direction: row-reverse;
}

.k-wizard-left .k-wizard-steps {
  margin-left: 0;
  margin-right: 32px;
}

.k-textarea {
  box-sizing: border-box;
  box-shadow: none;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: 0;
  flex-flow: row;
  margin: 0;
  line-height: 1.42857;
  display: inline-flex;
  position: relative;
}

.k-textarea::-ms-clear {
  display: none;
}

.k-textarea > .k-input {
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex: auto;
  width: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.k-textarea > .k-input::-ms-clear {
  display: none;
}

.k-textarea:disabled, .k-textarea[disabled], .k-textarea.k-state-disabled {
  cursor: default;
  opacity: .6;
  filter: grayscale(.1);
  pointer-events: none;
  box-shadow: none;
  outline: none;
}

textarea.k-textarea {
  resize: both;
  background-clip: padding-box;
  width: 18em;
  min-height: calc(1.42857em + 10px);
  padding: 4px 8px;
  display: inline-block;
}

.k-textarea > .k-input {
  resize: both;
  background-clip: padding-box;
  width: 18em;
  min-height: calc(1.42857em + 10px);
  padding: 4px 8px;
  overflow-y: auto;
}

.k-textbox-container > .k-textarea, .k-floating-label-container > .k-textarea {
  flex: auto;
  width: auto;
}

.k-ie .k-textarea > .k-input, .k-ie textarea.k-textarea {
  background-clip: border-box;
}

.k-editor {
  vertical-align: top;
  border-style: solid;
  border-width: 1px;
  font-size: 100%;
  position: relative;
}

.k-editor > .k-content, .k-editor iframe.k-content, .k-editor .k-editor-content {
  box-sizing: border-box;
  background: none;
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
}

table.k-editor {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 250px;
}

table.k-editor tbody, table.k-editor tr, table.k-editor td {
  border-color: inherit;
}

table.k-editor .k-editable-area {
  color: #000;
  background: #fff;
}

.k-editor-inline {
  word-wrap: break-word;
  background: none;
  padding: .3em .5em;
  position: relative;
  overflow: auto;
}

.k-editor-inline.k-editor {
  border: 1px solid #0000;
}

.k-window.k-editor-widget {
  min-height: 0;
}

.editorToolbarWindow {
  align-content: flex-start;
  padding: 4px;
  font-size: 14px;
  display: flex;
}

.editorToolbarWindow .k-editor-toolbar {
  background: none;
  border-width: 0;
  padding: 0;
}

.k-editortoolbar-dragHandle {
  cursor: move;
  flex-flow: row;
  align-items: center;
  margin: 0 4px 0 0;
  padding: 0;
  display: flex;
  position: relative;
}

.k-editor-toolbar {
  border-width: 0 0 1px;
  border-color: inherit;
}

.k-editor-toolbar .k-tool-group .k-widget ~ .k-widget, .k-editor-toolbar .k-tool-group .k-colorpicker ~ .k-colorpicker {
  margin-inline-start: 4px;
}

.k-editor-toolbar .k-tool-group > .k-tool {
  width: calc(1.42857em + 10px);
  height: calc(1.42857em + 10px);
  padding: 4px;
}

.k-editor-toolbar .k-tool-text {
  display: none;
}

.k-editor-toolbar .k-overflow-tools {
  border-color: inherit;
  visibility: hidden;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.k-editor-toolbar .k-overflow-anchor {
  position: relative;
}

.k-editor-toolbar .k-break, .k-editor-toolbar .k-row-break {
  height: 1px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.k-editor-toolbar .k-editor-export .k-icon {
  margin-top: -.25em;
  margin-right: .25em;
}

.k-editable-area {
  outline: 0;
  width: 100%;
  height: 100%;
}

.k-resizable .k-editable-area {
  padding-bottom: 16px;
}

.k-editor .k-raw-content, .k-editor-dialog .k-editor-textarea {
  font-size: inherit;
  font-family: consolas, courier new, monospace;
}

.k-editor-dialog {
  width: 400px;
  padding: 1em;
}

.k-editor-dialog .k-edit-label {
  width: 25%;
}

.k-editor-dialog .k-edit-field {
  width: 66%;
}

.k-editor-dialog .k-edit-field .k-textbox {
  width: 96%;
}

.k-filebrowser-dialog {
  width: auto;
  min-width: 350px;
}

.k-filebrowser-dialog .k-filebrowser {
  margin: 0 1em;
}

.k-filebrowser-dialog .k-edit-label {
  width: 18%;
}

.k-filebrowser-dialog .k-edit-field {
  width: 75%;
}

.k-filebrowser-dialog .k-edit-field .k-textbox {
  width: 70%;
}

#k-editor-image-width, #k-editor-image-height {
  width: 5em;
}

.k-editor-dialog .k-button {
  display: inline-block;
}

.k-button-wrapper .k-link:hover {
  text-decoration: underline;
}

.k-ct-popup {
  width: 180.4px;
  padding: .65em .5em .5em;
}

.k-ct-popup .k-button {
  width: 100%;
}

.k-ct-popup .k-status {
  margin: .3em 0;
}

.k-ct-popup .k-ct-cell {
  vertical-align: top;
  pointer-events: all;
  -ms-high-contrast-adjust: none;
  border-style: solid;
  border-width: 1px;
  width: 18px;
  height: 18px;
  margin: 1px;
  display: inline-block;
  overflow: hidden;
}

.k-editor .k-resize-handle {
  cursor: se-resize;
  padding: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.k-editor .k-resize-handle .k-icon {
  vertical-align: bottom;
}

.k-editor .k-overlay {
  opacity: 0;
  background-color: #fff;
  position: absolute;
}

.k-editor-inline .k-element-resize-handle-wrapper {
  z-index: 100;
  background-color: #fff;
  border: 1px solid #000;
  width: 5px;
  height: 5px;
  position: absolute;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle {
  width: 100%;
  height: 100%;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-east {
  cursor: e-resize;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-north {
  cursor: n-resize;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-northeast {
  cursor: ne-resize;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-northwest {
  cursor: nw-resize;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-south {
  cursor: s-resize;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-southeast {
  cursor: se-resize;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-southwest {
  cursor: sw-resize;
}

.k-editor-inline .k-element-resize-handle-wrapper .k-element-resize-handle.k-resize-west {
  cursor: w-resize;
}

.k-editor-inline .k-column-resize-handle-wrapper {
  cursor: col-resize;
  z-index: 2;
  width: 10px;
  height: 10px;
  position: absolute;
}

.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle {
  width: 100%;
  height: 100%;
}

.k-editor-inline .k-column-resize-handle-wrapper .k-column-resize-handle .k-column-resize-marker {
  opacity: .8;
  background-color: #00b0ff;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  display: none;
}

.k-editor-inline .k-table {
  border-spacing: 0;
  width: 100%;
  margin: 0 0 1em;
}

.k-editor-inline .k-table, .k-editor-inline .k-table td {
  border: 1px dotted #ccc;
  outline: 0;
}

.k-editor-inline .k-table td {
  min-width: 1px;
  padding: .2em .3em;
}

.k-table.k-element-resizing {
  opacity: .6;
}

.k-editor-inline .k-row-resize-handle-wrapper {
  z-index: 2;
  cursor: row-resize;
  width: 10px;
  height: 10px;
  position: absolute;
}

.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-handle {
  width: 100%;
  height: 100%;
  display: table;
}

.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker-wrapper {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: table-cell;
}

.k-editor-inline .k-row-resize-handle-wrapper .k-row-resize-marker {
  opacity: .8;
  background-color: #00b0ff;
  width: 100%;
  height: 2px;
  margin: 0;
  padding: 0;
  display: none;
}

.k-viewhtml-dialog {
  box-sizing: border-box;
  flex-flow: column;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.k-viewhtml-dialog .k-editor-textarea {
  box-sizing: border-box;
  font: inherit;
  resize: none;
  border-style: solid;
  border-width: 1px;
  flex-grow: 1;
  width: calc(100% - 2em);
  height: 100%;
  margin: 1em;
  padding: .178571em;
  font-family: consolas, monospace;
  overflow: auto;
}

.k-viewhtml-dialog .k-edit-buttons {
  text-align: right;
  border-style: solid;
  border-width: 1px 0 0;
  padding: .6em;
  position: relative;
  bottom: -1em;
}

.k-viewhtml-dialog .k-edit-buttons .k-button {
  margin-right: .5em;
}

.k-editor-dialog.k-editor-table-wizard-dialog.k-window-content {
  width: 24em;
  min-width: 325px;
  padding: 1em 0;
  overflow: hidden;
}

.k-editor-dialog .k-edit-form-container {
  width: auto;
}

.k-editor-dialog .k-tabstrip {
  margin: -1.333em 0 0;
}

.k-editor-dialog .k-root-tabs {
  border-width: 0;
  margin: -1em -1em -1.5em;
  padding: .5em 1em 0;
}

.k-editor-dialog ul.k-tabstrip-items {
  border-style: solid;
  border-width: 0 0 1px;
  padding: .5em 1em 0;
}

.k-editor-dialog ul.k-tabstrip-items .k-loading {
  border-top-width: 0;
}

.k-editor-dialog .k-tabstrip:focus {
  box-shadow: none;
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-tabstrip-wrapper .k-content {
  border-width: 0;
  height: 35em;
  min-height: 440px;
  margin: 0;
  padding: 1em .5em 0;
  overflow: hidden;
}

.k-editor-dialog .k-edit-field .k-numerictextbox {
  width: 10.6em;
  margin: 0 .357143em 0 0;
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-numerictextbox + .k-dropdown {
  width: 4em;
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-textbox, .k-editor-dialog.k-editor-table-wizard-dialog .k-edit-field .k-dropdown {
  width: 15em;
}

.k-editor-table-wizard-dialog .k-numerictextbox + .k-dropdown {
  width: 5.35714em;
}

.k-editor-dialog textarea.k-textbox {
  height: 14.2857em;
}

.k-editor-dialog textarea.k-textbox.k-editor-accessibility-summary {
  height: auto;
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-dropdown.k-align {
  width: 4em;
}

.k-popup.k-list-container.k-align {
  text-align: center;
  padding: 0;
}

.k-align-group {
  vertical-align: text-top;
}

.k-popup.k-align .k-list .k-item {
  border: 0;
  border-radius: 0;
  width: 2.57143em;
  height: 2.57143em;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.k-popup.k-align .k-list .k-icon {
  vertical-align: bottom;
  vertical-align: -webkit-baseline-middle;
}

.k-popup.k-align .k-list .k-item:last-child {
  border-style: solid;
  border-width: 1px 0 0;
  width: auto;
  display: block;
}

.k-popup.k-align .k-list .k-item:before {
  width: 2.57143em;
  height: 2.57143em;
  line-height: 2.57143em;
}

.k-editor-dialog.k-editor-table-wizard-dialog .k-edit-buttons {
  margin: .5em 0 0;
  padding: 1em .8em;
}

.k-appbar {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  background-clip: padding-box;
  border-style: solid;
  border-width: 0;
  outline: 0;
  flex-flow: wrap;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  line-height: 1.42857;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.k-appbar > * {
  flex-shrink: 0;
}

.k-appbar .k-appbar-section {
  flex-wrap: nowrap;
  align-items: center;
  display: inline-flex;
}

.k-appbar .k-appbar-spacer {
  flex: 1 0 auto;
}

.k-appbar .k-appbar-spacer-sized {
  flex-grow: 0;
}

.k-appbar .k-appbar-separator {
  border-style: solid;
  border-width: 0 0 0 1px;
  width: 0;
  min-height: 1em;
  margin: 0;
  display: inline-block;
}

.k-appbar-static {
  position: static;
}

.k-appbar-sticky {
  z-index: 1;
  width: 100%;
  position: sticky;
  top: 0;
}

.k-appbar-fixed {
  z-index: 1;
  width: 100%;
  position: fixed;
}

.k-appbar-top {
  top: 0;
  bottom: auto;
}

.k-appbar-bottom {
  top: auto;
  bottom: 0;
}

.k-appbar-bottom.k-appbar-fixed {
  bottom: 0;
}

.k-ie {
  background-clip: border-box;
}

.k-loader {
  vertical-align: middle;
  box-sizing: border-box;
  box-shadow: none;
  border-width: 0;
  line-height: 0;
  display: inline-block;
  position: relative;
}

.k-widget.k-loader {
  box-shadow: none;
  background: none;
}

.k-loader-canvas {
  position: relative;
}

.k-loader-segment {
  display: inline-block;
  position: absolute;
}

.k-loader-segment:after {
  content: "";
  border-radius: 50%;
  display: block;
}

.k-loader-sm {
  padding: 3px;
}

.k-loader-sm .k-loader-segment:after {
  width: 6px;
  height: 6px;
}

.k-loader-md {
  padding: 5px;
}

.k-loader-md .k-loader-segment:after {
  width: 10px;
  height: 10px;
}

.k-loader-lg {
  padding: 10px;
}

.k-loader-lg .k-loader-segment:after {
  width: 20px;
  height: 20px;
}

.k-loader-pulsing-2 .k-loader-segment {
  position: relative;
}

.k-loader-pulsing-2 .k-loader-segment:first-child {
  animation: 1s infinite pulsing-2-first-child;
}

.k-loader-pulsing-2 .k-loader-segment:nth-child(2) {
  animation: 1s infinite pulsing-2-second-child;
}

.k-loader-pulsing-2.k-loader-sm .k-loader-segment:first-child {
  margin-right: 1.5px;
}

.k-loader-pulsing-2.k-loader-sm .k-loader-segment:last-child {
  margin-left: 1.5px;
}

.k-loader-pulsing-2.k-loader-md .k-loader-segment:first-child {
  margin-right: 2.5px;
}

.k-loader-pulsing-2.k-loader-md .k-loader-segment:last-child {
  margin-left: 2.5px;
}

.k-loader-pulsing-2.k-loader-lg .k-loader-segment:first-child {
  margin-right: 5px;
}

.k-loader-pulsing-2.k-loader-lg .k-loader-segment:last-child {
  margin-left: 5px;
}

.k-loader-spinner-3 .k-loader-segment:first-child {
  animation: 2s infinite spinner-3-first-child;
}

.k-loader-spinner-3 .k-loader-segment:nth-child(2) {
  animation: 2s infinite spinner-3-second-child;
}

.k-loader-spinner-3 .k-loader-segment:nth-child(3) {
  animation: 2s infinite spinner-3-third-child;
}

.k-loader-spinner-3.k-loader-sm .k-loader-canvas {
  width: 24px;
  height: 20px;
}

.k-loader-spinner-3.k-loader-md .k-loader-canvas {
  width: 40px;
  height: 34px;
}

.k-loader-spinner-3.k-loader-lg .k-loader-canvas {
  width: 80px;
  height: 65px;
}

.k-loader-spinner-4 .k-loader-canvas {
  animation: 1.833s infinite spinner-4;
}

.k-loader-spinner-4 .k-loader-segment:first-child {
  animation: 1.833s infinite spinner-4-first-child;
}

.k-loader-spinner-4 .k-loader-segment:nth-child(2) {
  animation: 1.833s infinite spinner-4-second-child;
}

.k-loader-spinner-4 .k-loader-segment:nth-child(3) {
  animation: 1.833s infinite spinner-4-third-child;
}

.k-loader-spinner-4 .k-loader-segment:nth-child(4) {
  animation: 1.833s infinite spinner-4-fourth-child;
}

.k-loader-spinner-4.k-loader-sm .k-loader-canvas {
  width: 24px;
  height: 24px;
}

.k-loader-spinner-4.k-loader-md .k-loader-canvas {
  width: 40px;
  height: 40px;
}

.k-loader-spinner-4.k-loader-lg .k-loader-canvas {
  width: 80px;
  height: 80px;
}

@keyframes pulsing-2-first-child {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulsing-2-second-child {
  0% {
    transform: scale(1.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}

@keyframes spinner-3-first-child {
  0% {
    top: 0;
    left: 50%;
    transform: translateX(-50%)translateY(0);
  }

  33.3% {
    top: 100%;
    left: 0;
    transform: translateX(0)translateY(-100%);
  }

  66.6% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%)translateY(-100%);
  }

  100% {
    top: 0;
    left: 50%;
    transform: translateX(-50%)translateY(0);
  }
}

@keyframes spinner-3-second-child {
  0% {
    top: 100%;
    left: 0;
    transform: translateX(0)translateY(-100%);
  }

  33.3% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%)translateY(-100%);
  }

  66.6% {
    top: 0;
    left: 50%;
    transform: translateX(-50%)translateY(0);
  }

  100% {
    top: 100%;
    left: 0;
    transform: translateX(0)translateY(-100%);
  }
}

@keyframes spinner-3-third-child {
  0% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%)translateY(-100%);
  }

  33.3% {
    top: 0;
    left: 50%;
    transform: translateX(-50%)translateY(0);
  }

  66.6% {
    top: 100%;
    left: 0;
    transform: translateX(0)translateY(-100%);
  }

  100% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%)translateY(-100%);
  }
}

@keyframes spinner-4-first-child {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }

  33.3% {
    top: 0;
    left: 0;
    transform: translateX(0)translateY(0);
  }

  66.6% {
    top: 0;
    left: 0;
    transform: translateX(0)translateY(0);
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@keyframes spinner-4-second-child {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }

  33.3% {
    top: 0;
    left: 100%;
    transform: translateX(-100%)translateY(0);
  }

  66.6% {
    top: 0;
    left: 100%;
    transform: translateX(-100%)translateY(0);
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@keyframes spinner-4-third-child {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }

  33.3% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%)translateY(-100%);
  }

  66.6% {
    top: 100%;
    left: 100%;
    transform: translateX(-100%)translateY(-100%);
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@keyframes spinner-4-fourth-child {
  0% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }

  33.3% {
    top: 100%;
    left: 0;
    transform: translateX(0)translateY(-100%);
  }

  66.6% {
    top: 100%;
    left: 0;
    transform: translateX(0)translateY(-100%);
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@keyframes spinner-4 {
  0% {
    transform: rotate(0);
  }

  33.3% {
    transform: rotate(0);
  }

  66.6% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.k-imageeditor {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  border-style: solid;
  border-width: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  line-height: 1.42857;
}

.k-imageeditor-content {
  flex-flow: row;
  display: flex;
}

.k-imageeditor-canvas-container {
  flex-flow: row;
  flex: 100%;
  display: flex;
  overflow: auto;
}

.k-imageeditor-canvas {
  flex-direction: column;
  margin: auto;
  display: flex;
  position: relative;
}

.k-imageeditor-action-pane {
  border-style: solid;
  border-width: 0 1px 1px;
  flex-shrink: 0;
  overflow-y: auto;
}

.k-imageeditor-action-pane > .k-form {
  padding: 0 16px 32px;
}

.k-imageeditor-crop-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.k-imageeditor-crop {
  box-sizing: border-box;
  border-style: dashed;
  border-width: 1px;
  width: 100%;
  height: 100%;
  position: absolute;
}

.k-imageeditor-crop .k-resize-handle {
  border-style: solid;
  border-width: 0 2px 2px 0;
  margin: 1px;
}

.k-imageeditor-crop .k-resize-sw, .k-imageeditor-crop .k-resize-se, .k-imageeditor-crop .k-resize-nw, .k-imageeditor-crop .k-resize-ne {
  width: 15px;
  height: 15px;
}

.k-imageeditor-crop .k-resize-n, .k-imageeditor-crop .k-resize-s {
  width: 15px;
  height: 0;
  left: calc(50% - 7.5px);
}

.k-imageeditor-crop .k-resize-w, .k-imageeditor-crop .k-resize-e {
  width: 0;
  height: 15px;
  top: calc(50% - 7.5px);
}

.k-imageeditor-crop .k-resize-ne {
  transform: rotate(-90deg);
}

.k-imageeditor-crop .k-resize-nw {
  transform: rotate(-180deg);
}

.k-imageeditor-crop .k-resize-sw {
  transform: rotate(90deg);
}

.k-imageeditor-crop .k-resize-n, .k-imageeditor-crop .k-resize-nw, .k-imageeditor-crop .k-resize-ne {
  top: 0;
}

.k-imageeditor-crop .k-resize-s, .k-imageeditor-crop .k-resize-sw, .k-imageeditor-crop .k-resize-se {
  bottom: 0;
}

.k-imageeditor-crop .k-resize-w, .k-imageeditor-crop .k-resize-nw, .k-imageeditor-crop .k-resize-sw {
  left: 0;
}

.k-imageeditor-crop .k-resize-e, .k-imageeditor-crop .k-resize-ne, .k-imageeditor-crop .k-resize-se {
  right: 0;
}

.k-ie .k-imageeditor-canvas {
  flex-direction: row;
  align-self: flex-start;
}

.k-ie .k-imageeditor-action-pane {
  flex: none;
  min-width: 200px;
}

.k-expander {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  background-clip: padding-box;
  border-style: solid;
  border-width: 1px;
  outline: 0;
  flex-direction: column;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.42857;
  display: flex;
  overflow: hidden;
}

.k-rtl .k-expander .k-expander-indicator, .k-expander.k-rtl .k-expander-indicator, .k-expander[dir="rtl"] .k-expander-indicator {
  margin-left: 0;
  margin-right: 12px;
}

.k-expander-header {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.k-expander-spacer {
  flex: auto;
}

.k-expander-title {
  text-transform: uppercase;
  font-weight: 500;
}

.k-expander-indicator {
  margin-left: 12px;
}

.k-expander-content {
  box-sizing: border-box;
  padding: 16px;
}

.k-expander + .k-expander.k-expanded, .k-expander.k-expanded + .k-expander {
  margin-top: 8px;
}

.k-expander:not(.k-expanded) + .k-expander:not(.k-expanded) {
  border-top-width: 0;
}

.k-fab {
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  text-overflow: ellipsis;
  cursor: pointer;
  background-color: #0000;
  border: 0 solid #0000;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: inherit;
  line-height: 1.42857;
  display: inline-flex;
  overflow: hidden;
}

.k-fab:focus {
  outline: none;
}

.k-fab-icon {
  width: 20px;
  height: 20px;
}

.k-fab-icon:not(:only-child) {
  margin-right: 8px;
}

.k-fab-sm {
  padding: 8px;
}

.k-fab-md {
  padding: 16px;
}

.k-fab-lg {
  padding: 24px;
}

.k-fab-circle {
  border-radius: 50%;
}

.k-fab-pill {
  border-radius: 5rem;
}

.k-fab-rounded {
  border-radius: 4px;
}

.k-fab-items {
  margin: 0;
  padding: 16px 0;
  display: flex;
}

.k-fab-items-bottom {
  flex-direction: column;
}

.k-fab-items-bottom .k-fab-item:last-child {
  margin-bottom: 0;
}

.k-fab-items-top {
  flex-direction: column-reverse;
}

.k-fab-items-top .k-fab-item:first-child {
  margin-bottom: 0;
}

.k-fab-item {
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
  list-style-type: none;
  display: flex;
}

.k-fab-item.k-text-right {
  flex-direction: row;
}

.k-fab-item.k-text-right .k-fab-item-text {
  margin-right: 8px;
}

.k-fab-item.k-text-left {
  flex-direction: row-reverse;
}

.k-fab-item.k-text-left .k-fab-item-text {
  margin-left: 8px;
}

.k-fab-item-text {
  border-width: 1px;
  border-radius: 2px;
  padding: 4px;
  font-size: 10px;
  line-height: 1;
}

.k-fab-item-icon {
  box-sizing: content-box;
  border-width: 0;
  border-radius: 50%;
  outline: none;
  padding: 8px;
}

.k-popup.k-fab-popup {
  box-sizing: content-box;
  background-color: #0000;
  border-width: 0;
  position: absolute;
}

.k-rtl .k-fab-icon:not(:only-child) {
  margin-left: 8px;
  margin-right: 0;
}

.k-rtl .k-fab-item.k-text-left {
  flex-direction: row;
}

.k-rtl .k-fab-item.k-text-right {
  flex-direction: row-reverse;
}

.k-actionsheet-container {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.k-actionsheet {
  box-sizing: border-box;
  z-index: 10002;
  border: 0 solid #0000;
  width: 360px;
  max-width: 100%;
  max-height: 50vh;
  padding: 0;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.42857;
  position: fixed;
  overflow: hidden auto;
}

.k-actionsheet-header {
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  border: 0 solid #0000;
  padding: 8px 16px;
}

.k-actionsheet-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.k-actionsheet-item {
  box-sizing: border-box;
  padding: 0;
}

.k-actionsheet-action {
  box-sizing: border-box;
  color: inherit;
  outline: 0;
  flex-flow: row;
  align-items: center;
  gap: 12px;
  min-height: 40px;
  margin: 0;
  padding: 8px 16px;
  text-decoration: none;
  display: flex;
}

.k-actionsheet-item-icon {
  font-size: 16px;
}

.k-actionsheet-item-text {
  flex-flow: column;
  display: flex;
}

.k-actionsheet-item-title {
  text-transform: none;
  font-weight: normal;
}

.k-actionsheet-item-description {
  opacity: .75;
  font-size: .875em;
}

.k-actionsheet > .k-hr {
  border-color: inherit;
  margin: 0;
}

.k-actionsheet-top {
  border-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-actionsheet-bottom {
  border-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-actionsheet-left {
  border-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.k-actionsheet-right {
  border-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.k-animation-container > .k-actionsheet {
  inset: auto;
  transform: none;
}

.k-bottom-nav {
  box-sizing: border-box;
  font-size: inherit;
  letter-spacing: .2px;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  background-clip: padding-box;
  border: 0 solid #0000;
  outline: none;
  flex-flow: row;
  gap: 4px;
  width: 100%;
  padding: 4px;
  font-family: inherit;
  line-height: 1.42857;
  display: flex;
  overflow: hidden;
}

.k-bottom-nav-border {
  border-width: 1px 0 0;
}

.k-bottom-nav-shadow {
  box-shadow: 0 0 5px #0000001f;
}

.k-bottom-nav-item {
  box-sizing: border-box;
  font: inherit;
  cursor: pointer;
  color: inherit;
  background-color: inherit;
  border-width: 0;
  border-color: inherit;
  border-radius: 4px;
  outline: none;
  flex: auto;
  justify-content: center;
  align-items: center;
  gap: 0 8px;
  min-width: 72px;
  min-height: 48px;
  padding: 0 8px;
  text-decoration: none;
  display: inline-flex;
}

.k-bottom-nav-item.k-state-disabled {
  background-color: initial;
}

.k-bottom-nav-item:focus {
  outline: none;
}

.k-bottom-nav-item:hover {
  color: inherit;
  text-decoration: none;
}

.k-bottom-nav-item-icon {
  font-size: 24px;
}

.k-bottom-nav-item-text {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}

.k-bottom-nav-item-flow-horizontal .k-bottom-nav-item {
  flex-flow: row;
}

.k-bottom-nav-item-flow-vertical .k-bottom-nav-item {
  flex-direction: column;
}

.k-bottom-nav.k-pos-fixed, .k-bottom-nav.k-pos-absolute {
  z-index: 1;
  bottom: 0;
}

.k-searchbox {
  vertical-align: middle;
  background-clip: padding-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  flex-flow: row;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.42857;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.k-searchbox > .k-input {
  padding: 4px 8px;
}

.k-skeleton {
  display: block;
}

.k-skeleton-text {
  border-radius: 0;
  transform: scale(1, .6);
}

.k-skeleton-text:empty:before {
  content: " ";
}

.k-skeleton-circle {
  border-radius: 9999px;
}

@keyframes k-skeleton-wave {
  0% {
    transform: translateX(-100%);
  }

  60% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.k-skeleton-wave .k-skeleton, .k-skeleton-wave.k-skeleton {
  position: relative;
  overflow: hidden;
}

.k-skeleton-wave .k-skeleton:after, .k-skeleton-wave.k-skeleton:after {
  content: "";
  animation: 1.6s linear .5s infinite k-skeleton-wave;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes k-skeleton-pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}

.k-skeleton-pulse .k-skeleton, .k-skeleton-pulse.k-skeleton {
  animation: 1.5s ease-in-out .5s infinite k-skeleton-pulse;
}

.k-taskboard {
  padding: null null;
  box-sizing: border-box;
  font-size: inherit;
  flex-direction: column;
  font-family: inherit;
  line-height: 1.42857;
  display: flex;
  position: relative;
}

.k-toolbar.k-taskboard-toolbar {
  box-shadow: none;
  background: none;
  border-width: 0;
  padding: 16px;
}

.k-taskboard-content {
  flex: auto;
  padding: 0 16px 16px;
  display: flex;
  position: relative;
  overflow-x: auto;
}

.k-taskboard-columns-container {
  outline: none;
  flex-direction: row;
  gap: 16px;
  display: flex;
  overflow: visible hidden;
}

.k-taskboard-column {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: none;
  flex-direction: column;
  flex-shrink: 0;
  width: 320px;
  display: flex;
  overflow: hidden;
}

.k-taskboard-column-header {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 8px;
  font-weight: 500;
  display: flex;
}

.k-taskboard-column-header-actions {
  flex-shrink: 0;
  align-self: flex-start;
  gap: 8px;
  display: inline-flex;
}

.k-taskboard-column-cards-container {
  outline: none;
  flex: auto;
  margin-bottom: 8px;
  padding: 0 8px;
  overflow: auto;
}

.k-taskboard-column-cards {
  padding: null null;
  flex-direction: column;
  gap: 8px 0;
  min-height: 100%;
  display: flex;
}

.k-taskboard-column-new {
  max-height: calc(2em + 16px);
}

.k-taskboard-column-new .k-taskboard-column-header, .k-taskboard-column-edit .k-taskboard-column-header {
  font-weight: inherit;
}

.k-taskboard-column-new .k-taskboard-column-header-text, .k-taskboard-column-edit .k-taskboard-column-header-text {
  flex: 100%;
}

.k-taskboard-column-new .k-textbox, .k-taskboard-column-edit .k-textbox {
  width: 100%;
}

.k-taskboard-pane {
  padding: null null;
  box-sizing: border-box;
  z-index: 2;
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  width: 320px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.k-taskboard-pane-header {
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  font-weight: 500;
  display: flex;
}

.k-taskboard-pane-header-text {
  word-break: break-word;
}

.k-taskboard-pane-header-actions {
  flex-shrink: 0;
  align-self: flex-start;
}

.k-taskboard-pane-content {
  flex: auto;
  padding: 0 16px;
  overflow: auto;
}

.k-taskboard-pane-actions {
  padding: 12px 16px;
}

.k-taskboard-pane-start {
  left: 0;
  right: auto;
}

.k-taskboard-card {
  border-width: 1px;
  border-radius: 4px;
}

.k-taskboard-card.k-taskboard-card-category {
  border-left-width: 4px;
}

.k-rtl .k-taskboard-card.k-taskboard-card-category, .k-taskboard-card.k-taskboard-card-category.k-rtl, [dir="rtl"] .k-taskboard-card.k-taskboard-card-category, .k-taskboard-card.k-taskboard-card-category[dir="rtl"] {
  border-left-width: 1px;
  border-right-width: 4px;
}

.k-taskboard-card .k-card-header .k-card-body, .k-taskboard-card .k-card-footer {
  padding: 16px;
}

.k-taskboard-card .k-card-header {
  align-items: center;
  display: flex;
}

.k-taskboard-card .k-card-title {
  font-size: inherit;
  font-weight: inherit;
  word-break: break-word;
  margin: 0;
}

.k-taskboard-card .k-card-title:focus, .k-taskboard-card .k-card-title.k-state-focus, .k-taskboard-card .k-card-title:hover, .k-taskboard-card .k-card-title.k-state-hover {
  text-decoration: underline;
}

.k-taskboard-card .k-card-header-actions {
  align-self: flex-start;
}

.k-taskboard-drag-placeholder {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  position: relative;
}

.k-ie .k-taskboard-column:not(:last-child) {
  margin-right: 16px;
}

.k-ie .k-rtl .k-taskboard-column:not(:last-child) {
  margin-left: 16px;
  margin-right: 0;
}

.k-ie .k-taskboard-column-cards-container {
  margin-bottom: 24px;
}

.k-ie .k-taskboard-column-cards > .k-taskboard-card:not(:last-child) {
  margin-bottom: 8px;
}

.k-ie .k-taskboard-column-header-actions > .k-button:not(:last-child) {
  margin-right: 8px;
}

.k-ie .k-rtl .k-taskboard-column-header-actions > .k-button:not(:last-child) {
  margin-left: 8px;
  margin-right: 0;
}

.k-scheduler-layout-flex {
  overflow: auto;
}

.k-scheduler-layout-flex .k-scheduler-head {
  z-index: 3;
  position: sticky;
  top: 0;
}

.k-scheduler-layout-flex .k-scheduler-body {
  position: relative;
}

.k-scheduler-layout-flex .k-scheduler-head, .k-scheduler-layout-flex .k-scheduler-body {
  flex-wrap: wrap;
  flex: 100%;
  display: flex;
}

.k-scheduler-yearview .k-scheduler-body {
  justify-content: center;
  padding: 12px;
}

.k-scheduler-yearview .k-calendar {
  border-width: 0;
  width: 100%;
}

.k-scheduler-yearview .k-calendar .k-calendar-view {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.k-scheduler-yearview .k-calendar .k-content {
  flex: 0;
}

.k-scheduler-yearview .k-calendar .k-month-header {
  font-size: inherit;
  opacity: 1;
  font-weight: bold;
}

.k-scheduler-yearview .k-calendar .k-link {
  position: relative;
}

.k-scheduler-yearview .k-calendar td.k-state-selected {
  background-color: inherit;
}

.k-scheduler-yearview .k-day-indicator {
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: calc(100% - .5em);
  left: calc(50% - 1.5px);
}

.k-scheduler-tooltip {
  color: inherit;
  padding: 8px;
}

.k-scheduler-tooltip .k-tooltip-title {
  margin-bottom: 4px;
}

.k-scheduler-tooltip .k-tooltip-title .k-month {
  text-transform: uppercase;
  font-size: 12px;
}

.k-scheduler-tooltip .k-tooltip-title .k-day {
  font-size: 24px;
}

.k-scheduler-tooltip .k-tooltip-events-container {
  overflow: auto;
}

.k-scheduler-tooltip .k-tooltip-events {
  flex-direction: column;
  gap: 4px;
  max-height: 250px;
  display: flex;
}

.k-scheduler-tooltip .k-tooltip-event {
  box-sizing: border-box;
  border-radius: 0;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  display: flex;
  position: relative;
}

.k-scheduler-tooltip .k-tooltip-event .k-event-time {
  font-size: inherit;
  flex-shrink: 0;
  display: flex;
}

.k-ie .k-scheduler-tooltip .k-tooltip-event:not(:last-child) {
  margin-bottom: 4px;
}

.k-captcha {
  box-sizing: border-box;
  font-size: inherit;
  border: none;
  flex-flow: column;
  gap: 8px;
  width: 280px;
  font-family: inherit;
  line-height: 1.42857;
  display: flex;
}

.k-captcha-image-wrap, .k-captcha-image-controls {
  gap: 8px;
  position: relative;
}

.k-captcha-image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.k-captcha-image > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.k-captcha-loading .k-captcha-image {
  height: auto;
}

.k-captcha-volume-control {
  height: 100%;
}

.k-captcha-input > .k-textbox {
  width: 100%;
}

.k-captcha-validation-message {
  margin-top: 4px;
  font-size: 12px;
  font-style: italic;
}

.k-popover {
  box-sizing: border-box;
  font-size: inherit;
  z-index: 12000;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  outline: 0;
  flex-flow: column;
  font-family: inherit;
  line-height: 1.42857;
  display: flex;
  position: absolute;
}

.k-popover-header {
  z-index: 1;
  border-style: solid;
  border-width: 0 0 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 12px 16px;
}

.k-popover-body {
  z-index: 1;
  padding: 12px 16px;
}

.k-popover-actions.k-actions {
  border-width: 1px 0 0;
  margin: 0;
  padding: 6px 8px;
}

.k-popover-callout {
  border-style: solid;
  border-width: 1px 1px 0 0;
  width: 20px;
  height: 20px;
  margin: 0;
  position: absolute;
}

.k-popover-callout.k-callout-n {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.k-popover-callout.k-callout-e {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%)rotate(135deg)scaleX(-1);
}

.k-popover-callout.k-callout-s {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%)rotate(135deg);
}

.k-popover-callout.k-callout-w {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%)rotate(-135deg);
}

.k-orgchart {
  box-sizing: border-box;
  font-size: inherit;
  width: 100%;
  padding: 24px;
  font-family: inherit;
  line-height: 1.42857;
  overflow: auto;
}

.k-orgchart-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.k-orgchart-group {
  gap: 24px;
}

.k-orgchart-group.k-vstack > .k-orgchart-node-container {
  gap: 0;
}

.k-orgchart-node-container {
  gap: 24px;
}

.k-orgchart-node-group-container {
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  outline: 0;
  padding: 24px;
}

.k-orgchart-node-group-title {
  margin: 0 0 8px;
  font-size: 16px;
  line-height: 1.42857;
}

.k-orgchart-node-group-subtitle {
  opacity: .6;
  margin: 0 0 24px;
  font-size: 14px;
}

.k-orgchart-card {
  border-width: 1px;
  width: 300px;
}

.k-orgchart-card .k-card-title {
  margin: 0;
  font-size: 16px;
}

.k-orgchart-card .k-card-subtitle {
  margin: 0;
  font-size: 14px;
}

.k-orgchart-card .k-card-body {
  border-style: solid;
  border-width: 2px 0 0;
  flex-grow: 0;
}

.k-orgchart-card .k-card-body .k-card-title-wrap {
  min-width: 0;
  min-height: 45px;
  margin: 0 12px 0 0;
}

.k-orgchart-line {
  background-color: currentColor;
}

.k-orgchart-line-h {
  height: 1px;
}

.k-orgchart-line-v {
  width: 1px;
  height: 25px;
  margin: 0 auto;
}

.k-orgchart-button {
  z-index: 1;
}

.k-pivotgrid {
  padding: null null;
  box-sizing: content-box;
  font-size: inherit;
  text-align: left;
  vertical-align: top;
  border-style: solid;
  border-width: 1px;
  grid-template-rows: 250px auto;
  grid-template-columns: 300px auto;
  font-family: inherit;
  line-height: 1.42857;
  display: grid;
  position: relative;
}

.k-pivotgrid table {
  border-width: 0;
  border-color: inherit;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  outline: 0;
  width: 100%;
  max-width: none;
  margin: 0;
}

.k-pivotgrid thead, .k-pivotgrid tbody, .k-pivotgrid th, .k-pivotgrid tr, .k-pivotgrid td {
  border-color: inherit;
}

.k-pivotgrid-table {
  table-layout: fixed;
}

.k-pivotgrid-table th {
  text-transform: initial;
  font-weight: 400;
}

.k-pivotgrid-table .k-pivotgrid-header-root {
  text-transform: uppercase;
  font-weight: 600;
}

.k-pivotgrid-empty-cell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
}

.k-pivotgrid-column-headers {
  overflow: hidden;
}

.k-pivotgrid-column-headers .k-pivotgrid-table {
  box-sizing: border-box;
  padding-inline-end: 17px;
}

.k-pivotgrid-column-headers .k-pivotgrid-cell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
}

.k-pivotgrid-column-headers .k-pivotgrid-cell:first-child {
  border-left-width: 0;
}

.k-pivotgrid-column-headers .k-pivotgrid-cell.k-first {
  border-left-width: 1px;
}

.k-pivotgrid-row-headers {
  overflow: hidden;
}

.k-pivotgrid-row-headers .k-pivotgrid-cell {
  white-space: nowrap;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
}

.k-pivotgrid-values {
  border-color: inherit;
  overflow: auto;
}

.k-pivotgrid-values .k-pivotgrid-cell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-width: 1px;
}

.k-pivotgrid-values .k-pivotgrid-cell:first-child {
  border-left-width: 0;
}

.k-pivotgrid-cell {
  text-overflow: ellipsis;
  vertical-align: top;
  padding: 6px 8px;
  overflow: hidden;
}

.k-pivotgrid-cell .k-icon {
  cursor: pointer;
}

.k-pivotgrid-total {
  font-weight: 600;
}

.k-column-menu .k-treeview {
  padding: 0 8px;
  overflow: auto;
}

.k-pivotgrid-configurator {
  box-shadow: none;
  border: none;
  display: flex;
}

.k-pivotgrid-configurator-panel {
  padding: null null;
  box-sizing: border-box;
  z-index: 2;
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.k-pivotgrid-configurator-button {
  box-sizing: border-box;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  padding: 4px;
}

.k-pivotgrid-configurator-button > span {
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.k-flex-row .k-pivotgrid-configurator-button, .k-flex-row-reverse .k-pivotgrid-configurator-button {
  align-items: center;
  display: flex;
}

.k-flex-row .k-pivotgrid-configurator-button span, .k-flex-row-reverse .k-pivotgrid-configurator-button span {
  writing-mode: vertical-rl;
}

.k-flex-row .k-pivotgrid-configurator-button .k-icon, .k-flex-row-reverse .k-pivotgrid-configurator-button .k-icon {
  vertical-align: baseline;
}

.k-flex-row-reverse .k-pivotgrid-configurator-button > span {
  transform: rotate(-180deg);
}

.k-flex-col .k-pivotgrid-configurator-button, .k-flex-col-reverse .k-pivotgrid-configurator-button {
  text-align: center;
}

.k-pivotgrid-configurator-overlay {
  z-index: 2;
  position: absolute;
  overflow: hidden;
}

.k-flex-row .k-pivotgrid-configurator-overlay {
  right: calc(1.42857em + 10px);
}

.k-flex-row-reverse .k-pivotgrid-configurator-overlay {
  left: calc(1.42857em + 10px);
}

.k-flex-col .k-pivotgrid-configurator-overlay {
  bottom: calc(1.42857em + 10px);
}

.k-flex-col-reverse .k-pivotgrid-configurator-overlay {
  top: calc(1.42857em + 10px);
}

.k-pivotgrid-configurator-header {
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.k-pivotgrid-configurator-header-text {
  word-break: normal;
  overflow-wrap: anywhere;
}

.k-pivotgrid-configurator-content {
  flex: auto;
  padding: 2px 16px;
  overflow: auto;
}

.k-pivotgrid-configurator-content .k-form .k-label {
  align-items: flex-start;
  font-size: 16px;
  font-weight: 500;
}

.k-pivotgrid-configurator-content .k-form .k-dropdown {
  max-width: 90px;
}

.k-pivotgrid-configurator-content .k-form .k-textbox {
  flex-basis: 50%;
}

.k-pivotgrid-configurator-content .k-row-fields, .k-pivotgrid-configurator-content .k-column-fields, .k-pivotgrid-configurator-content .k-filter-fields {
  flex-wrap: wrap;
  margin-top: 8px;
}

.k-pivotgrid-configurator-content .k-row-fields > *, .k-pivotgrid-configurator-content .k-column-fields > *, .k-pivotgrid-configurator-content .k-filter-fields > * {
  margin-bottom: 8px;
}

.k-pivotgrid-configurator-content .k-row-fields > :last-child, .k-pivotgrid-configurator-content .k-column-fields > :last-child, .k-pivotgrid-configurator-content .k-filter-fields > :last-child {
  margin-bottom: 0;
}

.k-pivotgrid-configurator-content .k-value-fields {
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
  display: flex;
}

.k-pivotgrid-configurator-content .k-select-all .k-checkbox {
  margin-inline-end: 4px;
}

.k-pivotgrid-configurator-content .k-fields-list-wrapper {
  border-style: solid;
  border-width: 1px;
  flex: auto;
  max-height: 200px;
  overflow: hidden auto;
}

.k-pivotgrid-configurator-content .k-settings-description {
  text-align: center;
  margin-top: 1rem;
}

.k-pivotgrid-configurator-content .k-chip-content {
  padding-inline-end: 4px;
}

.k-pivotgrid-configurator-content .k-treeview {
  padding: 0 8px;
  overflow: auto;
}

.k-pivotgrid-configurator-content .k-treeview .k-in .k-checkbox-wrapper {
  display: inline-flex;
}

.k-pivotgrid-configurator-actions {
  padding: 12px 8px;
}

.k-calculated-field {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  display: flex;
}

.k-calculated-field-header {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.k-calculated-field-header .k-textbox {
  min-width: 200px;
}

.k-calculated-field-header-text {
  word-break: normal;
  overflow-wrap: anywhere;
  margin-right: 5px;
}

.k-calculated-field-header-actions {
  flex-shrink: 0;
  align-self: flex-start;
}

.k-calculated-field-content .k-textbox {
  width: 100%;
}

.k-calculated-field-actions {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.k-calculated-field-actions .k-icon {
  margin-right: 8px;
}

.k-calculated-field-actions .k-dropdown {
  max-width: 100px;
}

.k-filter-menu .k-calculated-item {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.k-pivotgrid-configurator-vertical {
  min-width: 320px;
}

.k-flex-row .k-pivotgrid-configurator-vertical.k-pivotgrid-configurator-push .k-pivotgrid-configurator-panel, .k-flex-row-reverse .k-pivotgrid-configurator-vertical.k-pivotgrid-configurator-push .k-pivotgrid-configurator-panel {
  border-left-width: 0;
  border-right-width: 0;
}

.k-pivotgrid-configurator-horizontal {
  width: 100%;
  height: 420px;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-form {
  flex-direction: row;
  display: flex;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-form-field-wrapper {
  padding-left: 16px;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-form-field-wrapper > :first-child {
  margin-top: 0;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-form-field-wrapper:first-child {
  flex: 30%;
  padding-left: 0;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-form-field-wrapper:nth-child(2) {
  flex: 30%;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-form-field-wrapper:last-child {
  border-left-style: solid;
  border-left-width: 1px;
  flex: 40%;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-row-fields, .k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-column-fields, .k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-filter-fields {
  max-height: 100px;
  overflow: hidden auto;
}

.k-pivotgrid-configurator-horizontal .k-pivotgrid-configurator-content .k-value-fields {
  flex-flow: wrap;
  max-height: 250px;
  overflow: hidden auto;
}

.k-flex-col .k-pivotgrid-configurator-horizontal.k-pivotgrid-configurator-push .k-pivotgrid-configurator-panel, .k-flex-col-reverse .k-pivotgrid-configurator-horizontal.k-pivotgrid-configurator-push .k-pivotgrid-configurator-panel {
  border-top-width: 0;
  border-bottom-width: 0;
}

.k-pivotgrid-configurator .k-textbox {
  display: inline-flex;
}

.k-pivotgrid-configurator .k-input-suffix .k-icon, .k-pivotgrid-configurator .k-input-prefix .k-icon {
  margin: 0;
  position: relative;
  top: auto;
}

.k-pivotgrid-column-menu {
  border: none;
}

.k-pivotgrid-column-menu .k-item {
  float: initial;
  border: none;
}

.k-pivotgrid-column-menu-popup.k-state-focused {
  box-shadow: none;
}

.k-pivotgrid-column-menu .k-actions {
  padding: 6px 8px;
}

.k-pivotgrid-column-menu.k-column-menu .k-animation-container-relative {
  display: inline-block;
  position: relative;
}

.k-pivotgrid-column-menu-popup .k-filter-menu {
  background-color: inherit;
  margin: 0;
  padding: 0;
}

.k-pivotgrid-column-menu-popup .k-filer-menu .k-filter-menu-container {
  padding: 6px 8px;
}

.k-pivotgrid-column-menu-popup .k-filter-menu-container .k-actions {
  margin: 0;
  padding: 6px 0 0;
}

.k-pdf-export-shadow .k-pivotgrid {
  height: auto !important;
}

.k-pdf-export-shadow .k-pivotgrid-values {
  height: auto !important;
  overflow: visible !important;
}

.k-pdf-export-shadow .k-pivotgrid-column-headers .k-pivotgrid-table {
  padding-inline-end: 0;
}

.k-ie11 .k-pivotgrid {
  flex-direction: column;
  display: flex;
}

.k-ie11 .k-pivotgrid > div {
  flex-direction: row;
  display: flex;
  overflow: hidden;
}

.k-ie11 .k-pivotgrid .k-pivotgrid-empty-cell, .k-ie11 .k-pivotgrid .k-pivotgrid-row-headers {
  flex: 30%;
}

.k-ie11 .k-pivotgrid .k-pivotgrid-column-headers, .k-ie11 .k-pivotgrid .k-pivotgrid-values {
  flex: 70%;
}

.k-chip-list {
  display: inline-flex;
}

.k-chip-list > * {
  margin-right: 8px;
}

.k-chip-list > :last-child {
  margin-right: 0;
}

.k-selection-single .k-selected-icon-wrapper {
  display: none;
}

.k-selection-multiple .k-selected-icon-wrapper {
  visibility: hidden;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 16px;
  display: flex;
  position: relative;
  left: 4px;
}

.k-selection-multiple .k-selected-icon {
  font-size: 14px;
}

.k-selection-multiple .k-chip.k-selected .k-selected-icon-wrapper {
  visibility: visible;
  width: 16px;
}

.k-selection-multiple > .k-chip-has-icon .k-selected-icon-wrapper {
  border-radius: 50%;
  position: absolute;
}

.k-selection-multiple :not(.k-chip-has-icon) .k-selected-icon-wrapper {
  opacity: 0;
  transition: width .2s, opacity .2s;
  left: 4px;
}

.k-selection-multiple :not(.k-chip-has-icon).k-selected .k-selected-icon-wrapper {
  opacity: 1;
}

.k-chip {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  height: 24px;
  padding: 4px 0;
  font-size: 14px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.k-chip:focus, .k-chip.k-chip-focus {
  outline: 0;
}

.k-chip-content {
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: auto;
  min-width: 0;
  padding: 0 8px;
  overflow: hidden;
}

.k-chip-icon, .k-remove-icon {
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  display: flex;
}

.k-chip-icon {
  margin-left: 4px;
}

.k-remove-icon {
  margin-right: 8px;
  font-size: 14px;
}

.k-chip-avatar {
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.k-chip-list[dir="rtl"] > *, .k-rtl .k-chip-list > *, .k-chip-list.k-rtl > * {
  margin-left: 8px;
  margin-right: 0;
}

.k-chip-list[dir="rtl"] > :last-child, .k-rtl .k-chip-list > :last-child, .k-chip-list.k-rtl > :last-child {
  margin-left: 0;
}

.k-chip[dir="rtl"] .k-chip-icon, .k-rtl .k-chip .k-chip-icon, .k-chip.k-rtl .k-chip-icon {
  margin-left: 0;
  margin-right: 4px;
}

.k-chip[dir="rtl"] .k-remove-icon, .k-rtl .k-chip .k-remove-icon, .k-chip.k-rtl .k-remove-icon {
  margin-left: 8px;
  margin-right: 0;
}

.k-selection-multiple .k-chip[dir="rtl"] .k-selected-icon-wrapper, .k-selection-multiple .k-rtl .k-chip .k-selected-icon-wrapper, .k-selection-multiple .k-chip.k-rtl .k-selected-icon-wrapper, .k-selection-multiple .k-chip[dir="rtl"]:not(.k-chip-has-icon) .k-selected-icon-wrapper, .k-selection-multiple .k-rtl .k-chip:not(.k-chip-has-icon) .k-selected-icon-wrapper, .k-selection-multiple .k-chip.k-rtl:not(.k-chip-has-icon) .k-selected-icon-wrapper {
  left: 0;
  right: 4px;
}

.k-panelbar {
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  border-style: solid;
  border-width: 1px;
  outline: 0;
  margin: 0;
  padding: 0;
  line-height: 1.42857;
  list-style: none;
  display: block;
}

.k-panelbar > .k-item, .k-panelbar > .k-panelbar-header {
  border-style: solid;
  border-width: 0;
  border-color: inherit;
  display: block;
}

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  color: inherit;
  border-color: inherit;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  background: none;
  flex-flow: row;
  align-content: center;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  font-weight: 500;
  line-height: 1.42857;
  text-decoration: none;
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
}

.k-panelbar > .k-item + .k-item, .k-panelbar > .k-panelbar-header + .k-panelbar-header {
  border-top-width: 1px;
  border-color: inherit;
}

.k-panelbar .k-group, .k-panelbar .k-panelbar-group {
  border-width: 0;
  border-color: inherit;
  color: inherit;
  background-color: #0000;
  margin: 0;
  padding: 0;
  list-style: none;
}

.k-panelbar .k-group > .k-item, .k-panelbar .k-panelbar-group > .k-panelbar-item {
  display: block;
}

.k-panelbar .k-group > .k-item > .k-link, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link {
  color: inherit;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  flex-flow: row;
  align-content: center;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  line-height: 1.42857;
  text-decoration: none;
  transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out;
  display: flex;
  position: relative;
}

.k-panelbar .k-group > .k-item.k-level-1 .k-link, .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
  padding-left: 12px;
}

.k-panelbar .k-group > .k-item.k-level-2 .k-link, .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
  padding-left: 24px;
}

.k-panelbar .k-group > .k-item.k-level-3 .k-link, .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
  padding-left: 36px;
}

.k-panelbar .k-group > .k-item.k-level-4 .k-link, .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
  padding-left: 48px;
}

.k-panelbar .k-content, .k-panelbar .k-panelbar-content {
  display: flow-root;
}

.k-panelbar .k-panelbar-expand, .k-panelbar .k-panelbar-collapse, .k-panelbar .k-panelbar-toggle {
  margin-inline-start: auto;
}

.k-panelbar .k-group .k-panelbar-expand, .k-panelbar .k-group .k-panelbar-collapse, .k-panelbar .k-panelbar-group .k-panelbar-toggle {
  margin-inline-end: 0;
}

.k-rtl .k-panelbar .k-group > .k-item.k-level-1 .k-link, .k-panelbar.k-rtl .k-group > .k-item.k-level-1 .k-link, .k-panelbar[dir="rtl"] .k-group > .k-item.k-level-1 .k-link, .k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link, .k-panelbar[dir="rtl"] .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
  padding-left: 12px;
  padding-right: 12px;
}

.k-rtl .k-panelbar .k-group > .k-item.k-level-2 .k-link, .k-panelbar.k-rtl .k-group > .k-item.k-level-2 .k-link, .k-panelbar[dir="rtl"] .k-group > .k-item.k-level-2 .k-link, .k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link, .k-panelbar[dir="rtl"] .k-panelbar-group > .k-panelbar-item.k-level-2 .k-link {
  padding-left: 12px;
  padding-right: 24px;
}

.k-rtl .k-panelbar .k-group > .k-item.k-level-3 .k-link, .k-panelbar.k-rtl .k-group > .k-item.k-level-3 .k-link, .k-panelbar[dir="rtl"] .k-group > .k-item.k-level-3 .k-link, .k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link, .k-panelbar[dir="rtl"] .k-panelbar-group > .k-panelbar-item.k-level-3 .k-link {
  padding-left: 12px;
  padding-right: 36px;
}

.k-rtl .k-panelbar .k-group > .k-item.k-level-4 .k-link, .k-panelbar.k-rtl .k-group > .k-item.k-level-4 .k-link, .k-panelbar[dir="rtl"] .k-group > .k-item.k-level-4 .k-link, .k-rtl .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link, .k-panelbar.k-rtl .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link, .k-panelbar[dir="rtl"] .k-panelbar-group > .k-panelbar-item.k-level-4 .k-link {
  padding-left: 12px;
  padding-right: 48px;
}

.k-panelbar .k-no-flexbox .k-panelbar > .k-item > .k-link, .k-panelbar .k-no-flexbox .k-panelbar .k-group > .k-link {
  display: block;
}

.k-panelbar .k-no-flexbox .k-panelbar .k-link > .k-image, .k-panelbar .k-no-flexbox .k-panelbar .k-link > .k-sprite, .k-panelbar .k-no-flexbox .k-panelbar .k-link > .k-icon {
  margin-right: 4px;
}

.k-panelbar .k-no-flexbox .k-panelbar .k-panelbar-expand, .k-panelbar .k-no-flexbox .k-panelbar .k-panelbar-collapse {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  margin: 0 !important;
}

.k-no-flexbox .k-panelbar[dir="rtl"] .k-link > .k-image, .k-no-flexbox .k-rtl .k-panelbar .k-link > .k-image, .k-no-flexbox .k-panelbar[dir="rtl"] .k-link > .k-sprite, .k-no-flexbox .k-rtl .k-panelbar .k-link > .k-sprite, .k-no-flexbox .k-panelbar[dir="rtl"] .k-link > .k-icon, .k-no-flexbox .k-rtl .k-panelbar .k-link > .k-icon {
  margin-left: 4px;
  margin-right: 0;
}

.k-no-flexbox .k-panelbar[dir="rtl"] .k-panelbar-expand, .k-no-flexbox .k-rtl .k-panelbar .k-panelbar-expand, .k-no-flexbox .k-panelbar[dir="rtl"] .k-panelbar-collapse, .k-no-flexbox .k-rtl .k-panelbar .k-panelbar-collapse {
  left: 12px;
  right: auto;
}

.k-ie .k-panelbar-expand, .k-ie .k-panelbar-collapse, .k-ie .k-panelbar-toggle {
  margin-left: auto;
}

.k-ie .k-group .k-panelbar-expand, .k-ie .k-group .k-panelbar-collapse, .k-ie .k-panelbar-group .k-panelbar-toggle {
  margin-right: 0;
}

.k-ie .k-rtl .k-panelbar-expand, .k-rtl .k-ie .k-panelbar-expand, .k-ie.k-rtl .k-panelbar-expand, .k-ie[dir="rtl"] .k-panelbar-expand, [dir="rtl"] .k-ie .k-panelbar-expand, .k-ie .k-rtl .k-panelbar-collapse, .k-rtl .k-ie .k-panelbar-collapse, .k-ie.k-rtl .k-panelbar-collapse, .k-ie[dir="rtl"] .k-panelbar-collapse, [dir="rtl"] .k-ie .k-panelbar-collapse, .k-ie .k-rtl .k-panelbar-toggle, .k-rtl .k-ie .k-panelbar-toggle, .k-ie.k-rtl .k-panelbar-toggle, .k-ie[dir="rtl"] .k-panelbar-toggle, [dir="rtl"] .k-ie .k-panelbar-toggle {
  margin-left: 0;
  margin-right: auto;
}

.k-ie .k-rtl .k-group .k-panelbar-expand, .k-rtl .k-ie .k-group .k-panelbar-expand, .k-ie.k-rtl .k-group .k-panelbar-expand, .k-ie[dir="rtl"] .k-group .k-panelbar-expand, [dir="rtl"] .k-ie .k-group .k-panelbar-expand, .k-ie .k-rtl .k-group .k-panelbar-collapse, .k-rtl .k-ie .k-group .k-panelbar-collapse, .k-ie.k-rtl .k-group .k-panelbar-collapse, .k-ie[dir="rtl"] .k-group .k-panelbar-collapse, [dir="rtl"] .k-ie .k-group .k-panelbar-collapse, .k-ie .k-rtl .k-panelbar-group .k-panelbar-toggle, .k-rtl .k-ie .k-panelbar-group .k-panelbar-toggle, .k-ie.k-rtl .k-panelbar-group .k-panelbar-toggle, .k-ie[dir="rtl"] .k-panelbar-group .k-panelbar-toggle, [dir="rtl"] .k-ie .k-panelbar-group .k-panelbar-toggle {
  margin-left: 0;
}

.k-colorpalette {
  box-sizing: border-box;
  font-size: inherit;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  border-width: 0;
  outline: 0;
  font-family: inherit;
  line-height: 1.42857;
  display: inline-flex;
  position: relative;
}

.k-colorpalette-table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  position: relative;
}

.k-colorpalette-tile {
  box-sizing: border-box;
  cursor: pointer;
  -ms-high-contrast-adjust: none;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

.k-colorpalette-tile:hover, .k-colorpalette-tile.k-state-hover, .k-colorpalette-tile.k-hover {
  z-index: 101;
  position: relative;
}

.k-colorpalette-tile:focus, .k-colorpalette-tile.k-state-focus, .k-colorpalette-tile.k-state-selected, .k-colorpalette-tile.k-state-selected:hover {
  z-index: 100;
  position: relative;
}

.k-colorgradient {
  box-sizing: border-box;
  font-size: inherit;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  outline: 0;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  width: 272px;
  padding: 12px;
  font-family: inherit;
  line-height: 1.42857;
  display: flex;
}

.k-colorgradient-canvas {
  gap: 12px;
}

.k-colorgradient-canvas .k-hsv-rectangle {
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: pinch-zoom double-tap-zoom;
  border-radius: 0;
  flex: auto;
  position: relative;
}

.k-colorgradient-canvas .k-hsv-gradient {
  border-radius: 0;
  height: 180px;
}

.k-colorgradient-canvas .k-hsv-draghandle {
  z-index: 10;
  cursor: pointer;
  margin-top: -7px;
  margin-left: -7px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.k-colorgradient-canvas .k-hsv-controls {
  flex-shrink: 0;
  gap: 12px;
  position: relative;
}

.k-colorgradient .k-draghandle {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}

.k-colorgradient-slider.k-slider-vertical {
  flex: 0 0 10px;
  width: 10px;
  height: 180px;
}

.k-colorgradient-slider.k-slider-vertical .k-slider-track {
  border-radius: 10px;
  width: 10px;
  margin-left: -5px;
}

.k-colorgradient-slider.k-slider-vertical .k-draghandle {
  width: 14px;
  height: 14px;
  left: -2px;
}

.k-colorgradient-slider.k-slider-horizontal {
  flex: 0 0 10px;
  width: 100%;
  height: 10px;
}

.k-colorgradient-slider.k-slider-horizontal .k-slider-track {
  border-radius: 10px;
  height: 10px;
  margin-top: -5px;
}

.k-colorgradient-slider.k-alpha-slider .k-slider-track:before {
  content: "";
  z-index: -1;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.k-colorgradient-slider .k-slider-wrap, .k-colorgradient-slider .k-slider-track-wrap {
  z-index: 1;
  position: relative;
}

.k-colorgradient-slider .k-slider-wrap > .k-slider-track {
  height: 180px;
}

.k-colorgradient-slider .k-slider-selection {
  display: none;
}

.k-colorgradient-slider .k-draghandle {
  border-width: 3px;
}

.k-colorgradient-inputs {
  gap: 8px;
}

.k-colorgradient-inputs .k-colorgradient-input-label {
  text-transform: uppercase;
}

.k-colorgradient-inputs > .k-vstack {
  gap: 4px;
}

.k-colorgradient-inputs .k-numerictextbox {
  width: 46px;
}

.k-colorgradient-inputs .k-textbox {
  width: 100%;
}

.k-colorgradient-color-contrast > div {
  flex-flow: row;
  gap: 8px;
  display: flex;
}

.k-colorgradient-color-contrast .k-contrast-ratio {
  margin-bottom: 8px;
}

.k-colorgradient-color-contrast .k-contrast-ratio-text {
  font-weight: bold;
}

.k-colorgradient-color-contrast .k-contrast-validation {
  align-items: center;
  gap: 4px;
  display: inline-flex;
}

.k-colorgradient-color-contrast .k-contrast-validation .k-icon {
  vertical-align: middle;
}

.k-colorgradient-color-contrast .k-contrast-validation .k-i-check + .k-i-check {
  margin-left: -13px;
}

.k-rtl .k-colorgradient-color-contrast .k-i-check + .k-i-check, [dir="rtl"] .k-colorgradient-color-contrast .k-i-check + .k-i-check {
  margin-left: 0;
  margin-right: -13px;
}

.k-color-preview {
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  flex-flow: row;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.k-color-preview:before {
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.k-no-color:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.k-coloreditor {
  box-sizing: border-box;
  font-size: inherit;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  outline: 0;
  flex-direction: column;
  align-items: stretch;
  min-width: 272px;
  font-family: inherit;
  line-height: 1.42857;
  display: inline-flex;
}

.k-coloreditor-header {
  padding: 12px 12px 0;
}

.k-coloreditor-header-actions {
  gap: 8px;
}

.k-coloreditor-preview {
  z-index: 1;
  gap: 3px;
  position: relative;
}

.k-coloreditor-preview .k-color-preview {
  width: 34px;
  height: 14px;
}

.k-coloreditor-views {
  min-width: calc(var(--kendo-color-preview-width, 24) * var(--kendo-color-preview-columns, 10));
  gap: 12px;
  padding: 12px;
}

.k-coloreditor-views .k-colorgradient {
  border-width: 0;
  width: auto;
  padding: 0;
}

.k-coloreditor-views .k-colorpalette {
  align-self: center;
}

.k-coloreditor-views .k-colorpalette-tile {
  width: var(--kendo-color-preview-width, 24px);
  height: var(--kendo-color-preview-height, 24px);
}

.k-coloreditor-footer.k-actions {
  margin: 0;
}

.k-colorpicker {
  vertical-align: middle;
  border-width: 0;
  outline: 0;
  width: auto;
  line-height: 2.00714em;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.k-colorpicker .k-picker-wrap {
  overflow: hidden;
}

.k-colorpicker .k-selected-color {
  vertical-align: top;
  width: 2em;
  height: 2em;
  display: inline-block;
  position: relative;
}

.k-colorpicker .k-tool-icon {
  vertical-align: middle;
  -ms-high-contrast-adjust: none;
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: 3px;
  margin-left: 2px;
  margin-right: 3px;
  padding: 3px 3px 2px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.k-colorpicker .k-tool-icon .k-selected-color {
  width: 16px;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 3px;
  border-radius: 0 !important;
}

.k-colorpicker .k-select {
  cursor: pointer;
}

.k-colorpicker .k-picker-wrap .k-icon {
  overflow: visible;
}

.k-colorpicker-popup .k-coloreditor {
  border-color: #0000;
}

.k-colorpicker-popup {
  padding: 0;
}

@font-face {
  font-family: DejaVu Sans;
  src: url("DejaVuSans.12fc78da.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Sans;
  font-weight: bold;
  src: url("DejaVuSans-Bold.4744ec5b.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Sans;
  font-style: italic;
  src: url("DejaVuSans-Oblique.6ddadb29.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Sans;
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSans-BoldOblique.f4c31739.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Serif;
  src: url("DejaVuSerif.2e0e2228.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Serif;
  font-weight: bold;
  src: url("DejaVuSerif-Bold.513e3aa8.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Serif;
  font-style: italic;
  src: url("DejaVuSerif-Italic.fe44e462.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Serif;
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSerif-BoldItalic.e6f0e154.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Mono;
  src: url("DejaVuSansMono.2a5828a3.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Mono;
  font-weight: bold;
  src: url("DejaVuSansMono-Bold.ee8e77e5.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Mono;
  font-style: italic;
  src: url("DejaVuSansMono-Oblique.ad8985e1.ttf") format("truetype");
}

@font-face {
  font-family: DejaVu Mono;
  font-weight: bold;
  font-style: italic;
  src: url("DejaVuSansMono-BoldOblique.7442343d.ttf") format("truetype");
}

.kendo-pdf-hide-pseudo-elements:before, .kendo-pdf-hide-pseudo-elements:after {
  display: none !important;
}

.k-pdf-export sub, .k-pdf-export sup {
  display: inline-block;
}

.k-theme-test-class, .ktb-theme-id-metro {
  opacity: 0;
}

.ktb-var-accent {
  color: #7ea700;
}

.ktb-var-base, .ktb-var-background {
  color: #fff;
}

.ktb-var-border-radius {
  border-radius: 0;
}

.ktb-var-normal-background {
  color: #fff;
}

.ktb-var-normal-gradient {
  background-image: none;
}

.ktb-var-normal-text-color {
  color: #787878;
}

.ktb-var-hover-background {
  color: #8ebc00;
}

.ktb-var-hover-gradient {
  background-image: none, linear-gradient(#8ebc00 0% 100%);
}

.ktb-var-hover-text-color {
  color: #fff;
}

.ktb-var-selected-background {
  color: #7ea700;
}

.ktb-var-selected-gradient {
  background-image: none;
}

.ktb-var-selected-text-color {
  color: #fff;
}

.ktb-var-is-dark-theme {
  opacity: 0;
}

.ktb-var-primary {
  color: #7ea700;
}

.ktb-var-secondary {
  color: #fff;
}

.ktb-var-tertiary {
  color: #309b46;
}

.ktb-var-error {
  color: #e20000;
}

.ktb-var-warning {
  color: #ffb137;
}

.ktb-var-success {
  color: #2b893c;
}

.ktb-var-info {
  color: #0c779b;
}

.ktb-var-dark {
  color: #000;
}

.ktb-var-light {
  color: #fff;
}

.ktb-var-inverse {
  color: #000;
}

.ktb-var-series-a {
  color: #8ebc00;
}

.ktb-var-series-b {
  color: #309b46;
}

.ktb-var-series-c {
  color: #25a0da;
}

.ktb-var-series-d {
  color: #ff6900;
}

.ktb-var-series-e {
  color: #e61e26;
}

.ktb-var-series-f {
  color: #d8e404;
}

.k-grid-norecords-template {
  background-color: #f4f4f4;
  border: 1px solid #dbdbdb;
}

.k-button {
  color: #000;
  box-shadow: null;
  background-color: #fff;
  background-position: 50%;
  border-color: #dbdbdb;
  border-radius: 0;
}

.k-button.k-state-default {
  border-color: #dbdbdb;
}

.k-button:hover, .k-button.k-state-hover {
  color: #fff;
  box-shadow: null;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-button:active, .k-button.k-state-active, .k-button.k-state-selected {
  color: #fff;
  box-shadow: null;
  background-color: #729800;
  border-color: #729800;
}

.k-button.k-state-active:hover, .k-button.k-state-selected:hover {
  color: #fff;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-button:focus:active {
  box-shadow: inset 0 0 3px 1px #445b00;
}

.k-button:focus, .k-button:focus:hover, .k-button.k-state-focused, .k-button.k-state-focused.k-state-disabled, .k-state-disabled .k-button.k-state-focused {
  box-shadow: none;
  border-color: #8dbb00;
}

.k-button[disabled], .k-state-disabled .k-button, .k-button.k-state-disabled, .k-button[disabled]:hover, .k-state-disabled .k-button:hover, .k-button.k-state-disabled:hover, .k-button[disabled]:focus, .k-state-disabled .k-button:focus, .k-button.k-state-disabled:focus, .k-button[disabled]:active, .k-state-disabled .k-button:active, .k-button.k-state-disabled:active {
  color: #000;
  box-shadow: none;
  background-color: #fff;
  background-image: none;
  border-color: #dbdbdb;
}

.k-primary {
  color: #fff;
  box-shadow: null;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-primary.k-state-default {
  border-color: #7ea700;
}

.k-primary:hover, .k-primary.k-state-hover {
  color: #fff;
  box-shadow: null;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-primary:active, .k-primary.k-state-active {
  color: #fff;
  box-shadow: null;
  background-color: #729800;
  border-color: #729800;
}

.k-primary:focus:active:not(.k-state-disabled):not([disabled]) {
  box-shadow: none;
}

.k-primary:focus, .k-primary:focus:hover, .k-primary.k-state-focused, .k-primary.k-state-focused.k-state-disabled, .k-state-disabled .k-primary.k-state-focused {
  box-shadow: none;
  border-color: #638300;
}

.k-primary[disabled], .k-state-disabled .k-primary, .k-primary.k-state-disabled, .k-primary[disabled]:hover, .k-state-disabled .k-primary:hover, .k-primary.k-state-disabled:hover {
  color: #fff;
  box-shadow: none;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-button-group .k-button {
  border-radius: 0;
}

.k-button-group .k-group-start, .k-button-group .k-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.k-button-group .k-group-end, .k-button-group .k-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.k-button-group .k-group-start.k-group-end, .k-button-group .k-button:first-child:last-child {
  border-radius: 0;
}

.k-widget.k-button-group {
  background: none;
}

.k-split-button, .k-split-button .k-button {
  border-radius: 0;
}

.k-split-button .k-button:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.k-split-button .k-button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.k-split-button.k-state-border-down > .k-button, .k-split-button.k-state-border-up > .k-button {
  color: #fff;
  box-shadow: none;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-split-button:focus {
  box-shadow: none;
  border-color: #8dbb00;
}

.k-split-button:focus .k-button {
  box-shadow: none;
  border-color: inherit;
}

.k-edit-buttons {
  background: #f4f4f4;
  border-color: #dbdbdb;
}

.k-button.k-flat.k-primary, .k-button.k-bare.k-primary, .k-button.k-button-clear.k-primary, .k-button.k-flat.k-primary:hover, .k-button.k-bare.k-primary:hover, .k-button.k-button-clear.k-primary:hover, .k-button.k-flat.k-primary.k-state-hover, .k-button.k-bare.k-primary.k-state-hover, .k-button.k-button-clear.k-primary.k-state-hover, .k-button.k-flat.k-primary:active, .k-button.k-bare.k-primary:active, .k-button.k-button-clear.k-primary:active, .k-button.k-flat.k-primary.k-state-active, .k-button.k-bare.k-primary.k-state-active, .k-button.k-button-clear.k-primary.k-state-active, .k-button.k-flat.k-primary:hover:active, .k-button.k-bare.k-primary:hover:active, .k-button.k-button-clear.k-primary:hover:active, .k-button.k-flat.k-primary:hover.k-state-active, .k-button.k-bare.k-primary:hover.k-state-active, .k-button.k-button-clear.k-primary:hover.k-state-active {
  color: #7ea700;
}

.k-rtl .k-button-group .k-button {
  border-radius: 0;
}

.k-rtl .k-button-group .k-group-start, .k-rtl .k-button-group .k-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.k-rtl .k-button-group .k-group-end, .k-rtl .k-button-group .k-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.k-rtl .k-button-group .k-group-start.k-group-end, .k-rtl .k-button-group .k-button:first-child:last-child, .k-rtl .k-split-button .k-button {
  border-radius: 0;
}

.k-rtl .k-split-button .k-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.k-rtl .k-split-button .k-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.k-avatar-icon, .k-avatar-initials {
  color: #fff;
  background-color: #7ea700;
}

.k-card {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-card .k-card-callout {
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-card .k-card-actions {
  border-color: #dbdbdb;
}

.k-card:focus, .k-card.k-state-focus {
  box-shadow: 0 3px 4px #0000000f;
}

.k-card-header, .k-card-footer {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-card-separator {
  border-color: #dbdbdb;
}

.k-card.k-state-primary, .k-card.k-card-primary {
  color: #3f5400;
  background-color: #d8e5b3;
  border-color: #bfd380;
}

.k-card.k-state-info, .k-card.k-card-info {
  color: #010506;
  background-color: #b3b5b6;
  border-color: #808486;
}

.k-card.k-state-success, .k-card.k-card-success {
  color: #030a04;
  background-color: #b4b9b5;
  border-color: #838a84;
}

.k-card.k-state-warning, .k-card.k-card-warning {
  color: #4f3000;
  background-color: #e2cfb3;
  border-color: #ceaf80;
}

.k-card.k-state-error, .k-card.k-card-error {
  color: #250000;
  background-color: #c8b3b3;
  border-color: #a48080;
}

.k-card:focus .k-card-callout.k-callout-n, .k-card.k-state-focus .k-card-callout.k-callout-n {
  box-shadow: 1px -1px 4px #0000000f;
}

.k-card:focus .k-card-callout.k-callout-e, .k-card.k-state-focus .k-card-callout.k-callout-e {
  box-shadow: 2px -1px 4px #0000000f;
}

.k-card:focus .k-card-callout.k-callout-s, .k-card.k-state-focus .k-card-callout.k-callout-s {
  box-shadow: 2px -2px 4px #0000000f;
}

.k-card:focus .k-card-callout.k-callout-w, .k-card.k-state-focus .k-card-callout.k-callout-w {
  box-shadow: 2px -1px 4px #0000000f;
}

.k-tilelayout, .k-widget.k-tilelayout {
  background-color: #f5f5f5;
}

.k-tilelayout-item:focus, .k-tilelayout-item.k-state-focus, .k-tilelayout-item.k-state-focused {
  box-shadow: 0 0 4px 2px #000000b3;
}

.k-layout-item-hint {
  background-color: #fff3;
  border-color: #dbdbdb;
}

.k-chat {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-chat .k-timestamp {
  text-transform: uppercase;
  opacity: .7;
}

.k-chat .k-author {
  font-weight: bold;
}

.k-chat .k-bubble {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  order: -1;
  transition: box-shadow .2s ease-in-out;
}

.k-chat .k-alt .k-bubble {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-chat .k-quick-reply {
  color: #7ea700;
  background-color: #0000;
  border-color: #7ea700;
}

.k-chat .k-quick-reply:hover {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-chat .k-message-box {
  border-color: inherit;
  color: #787878;
  background-color: #fff;
}

.k-chat .k-message-box .k-button:hover {
  color: #7ea700;
}

.k-chat .k-toolbar-box {
  border-color: inherit;
  color: #4c5356;
  background-color: #f4f4f4;
}

.k-chat .k-toolbar-box .k-button:hover {
  color: #7ea700;
  background: none;
}

.k-chat .k-toolbar-box .k-scroll-button {
  background-color: #f4f4f4;
  box-shadow: 0 0 20px 5px #f4f4f4;
}

.k-chat .k-toolbar-box .k-scroll-button:hover {
  background-color: #f4f4f4;
}

.k-calendar {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-calendar > .k-header {
  color: #4c5356;
  background-color: #f4f4f4;
  background-image: none;
  border-color: #dbdbdb;
}

.k-calendar > .k-header .k-button {
  color: inherit;
}

.k-calendar th {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-calendar .k-alt {
  color: #4c5356;
  background-color: #eaeaea;
  border-color: #dbdbdb;
}

.k-calendar .k-weekend {
  background-color: #0000;
}

.k-calendar .k-other-month {
  color: #ccc;
  background-color: #0000;
}

.k-calendar .k-out-of-range {
  pointer-events: none;
  visibility: hidden;
}

.k-calendar .k-today .k-link {
  color: #7ea700;
  box-shadow: inset 0 0 0 1px #7ea700;
}

.k-calendar td.k-state-hover .k-link {
  color: #fff;
  background-color: #8ebc00;
  background-image: none, linear-gradient(#8ebc00 0% 100%);
  border-color: #8ebc00;
}

.k-calendar td.k-state-selected .k-link {
  color: #fff;
  background-color: #7ea700;
  background-image: none;
  border-color: #7ea700;
}

.k-calendar td.k-state-focused .k-link {
  box-shadow: inset 0 0 0 1px #678900;
}

.k-calendar .k-footer .k-nav-today, .k-calendar .k-calendar-header .k-nav-today {
  color: #7ea700;
}

.k-calendar .k-footer .k-nav-today:hover, .k-calendar .k-calendar-header .k-nav-today:hover, .k-calendar .k-footer .k-nav-today:focus, .k-calendar .k-calendar-header .k-nav-today:focus {
  color: #6f9300;
}

.k-calendar-range {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-calendar-range th {
  color: inherit;
  text-transform: uppercase;
  background-color: #0000;
}

.k-calendar-range td.k-state-hover .k-link {
  color: #fff;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-calendar-range td.k-state-selected .k-link {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-calendar-range td.k-state-focused .k-link {
  box-shadow: inset 0 0 0 1px #678900;
}

.k-calendar-range td.k-range-start, .k-calendar-range td.k-range-mid, .k-calendar-range td.k-range-end {
  background-image: linear-gradient(#0000 1px, #7ea7004d 1px calc(100% - 1px), #0000 calc(100% - 1px));
}

.k-ie .k-calendar-range td.k-range-start, .k-ie .k-calendar-range td.k-range-mid, .k-ie .k-calendar-range td.k-range-end, .k-edge .k-calendar-range td.k-range-start, .k-edge .k-calendar-range td.k-range-mid, .k-edge .k-calendar-range td.k-range-end {
  background-color: #7ea7004d;
  background-image: none;
}

.k-calendar-range td.k-range-split-start:after {
  background-image: linear-gradient(to left, #7ea7004d, #0000);
}

.k-calendar-range td.k-range-split-end:after {
  background-image: linear-gradient(to right, #7ea7004d, #0000);
}

.k-calendar-range .k-footer .k-nav-today {
  color: #7ea700;
  background: none;
}

.k-pdf-viewer .k-toolbar .k-icon, .k-pdf-viewer .k-toolbar .k-pager-input {
  color: #000;
}

.k-pdf-viewer .k-canvas {
  background-color: #f4f4f4;
}

.k-pdf-viewer .k-page {
  color: #4c5356;
  background-color: #fff;
  border-color: #dbdbdb;
  box-shadow: 0 0 15px #dbdbdb;
}

.k-pdf-viewer .k-search-highlight {
  background-color: #4d4d4d;
}

kendo-scrollview.k-scrollview-wrap, div.k-scrollview {
  color: #4c5356;
  -webkit-tap-highlight-color: transparent;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

kendo-scrollview.k-scrollview-wrap.k-scrollview-dark .k-scrollview-pageable, div.k-scrollview.k-scrollview-dark .k-scrollview-pageable, kendo-scrollview.k-scrollview-wrap.k-scrollview-dark .k-scrollview-nav, div.k-scrollview.k-scrollview-dark .k-scrollview-nav {
  background-color: #0003;
}

kendo-scrollview.k-scrollview-wrap.k-scrollview-dark .k-scrollview-next, div.k-scrollview.k-scrollview-dark .k-scrollview-next, kendo-scrollview.k-scrollview-wrap.k-scrollview-dark .k-scrollview-prev, div.k-scrollview.k-scrollview-dark .k-scrollview-prev {
  color: #0003;
}

kendo-scrollview.k-scrollview-wrap.k-scrollview-light .k-scrollview-pageable, div.k-scrollview.k-scrollview-light .k-scrollview-pageable, kendo-scrollview.k-scrollview-wrap.k-scrollview-light .k-scrollview-nav, div.k-scrollview.k-scrollview-light .k-scrollview-nav {
  background-color: #fff3;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview:focus, .k-widget.k-scrollview .k-scrollview-wrap:focus, kendo-scrollview.k-scrollview-wrap .k-scrollview.k-state-focused, .k-widget.k-scrollview .k-scrollview-wrap.k-state-focused, kendo-scrollview.k-scrollview-wrap .k-scrollview:hover, .k-widget.k-scrollview .k-scrollview-wrap:hover, kendo-scrollview.k-scrollview-wrap .k-scrollview.k-state-hovered, .k-widget.k-scrollview .k-scrollview-wrap.k-state-hovered {
  outline: none;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview:focus ~ .k-scrollview-elements .k-scrollview-pageable, .k-widget.k-scrollview .k-scrollview-wrap:focus ~ .k-scrollview-elements .k-scrollview-pageable, kendo-scrollview.k-scrollview-wrap .k-scrollview.k-state-focused ~ .k-scrollview-elements .k-scrollview-pageable, .k-widget.k-scrollview .k-scrollview-wrap.k-state-focused ~ .k-scrollview-elements .k-scrollview-pageable, kendo-scrollview.k-scrollview-wrap .k-scrollview:hover ~ .k-scrollview-elements .k-scrollview-pageable, .k-widget.k-scrollview .k-scrollview-wrap:hover ~ .k-scrollview-elements .k-scrollview-pageable, kendo-scrollview.k-scrollview-wrap .k-scrollview.k-state-hovered ~ .k-scrollview-elements .k-scrollview-pageable, .k-widget.k-scrollview .k-scrollview-wrap.k-state-hovered ~ .k-scrollview-elements .k-scrollview-pageable, kendo-scrollview.k-scrollview-wrap .k-scrollview:focus ~ .k-scrollview-elements .k-scrollview-nav, .k-widget.k-scrollview .k-scrollview-wrap:focus ~ .k-scrollview-elements .k-scrollview-nav, kendo-scrollview.k-scrollview-wrap .k-scrollview.k-state-focused ~ .k-scrollview-elements .k-scrollview-nav, .k-widget.k-scrollview .k-scrollview-wrap.k-state-focused ~ .k-scrollview-elements .k-scrollview-nav, kendo-scrollview.k-scrollview-wrap .k-scrollview:hover ~ .k-scrollview-elements .k-scrollview-nav, .k-widget.k-scrollview .k-scrollview-wrap:hover ~ .k-scrollview-elements .k-scrollview-nav, kendo-scrollview.k-scrollview-wrap .k-scrollview.k-state-hovered ~ .k-scrollview-elements .k-scrollview-nav, .k-widget.k-scrollview .k-scrollview-wrap.k-state-hovered ~ .k-scrollview-elements .k-scrollview-nav {
  opacity: 1;
}

.k-scrollview-next, .k-scrollview-prev {
  color: #fff;
  text-shadow: 0 0 15px #0000004d;
  opacity: .7;
  -webkit-tap-highlight-color: transparent;
  background-color: #0000;
  outline-width: 0;
}

.k-scrollview-next:focus, .k-scrollview-prev:focus, .k-scrollview-next.k-state-focused, .k-scrollview-prev.k-state-focused {
  color: #fff;
  opacity: 1;
}

.k-scrollview-next:focus .k-icon:before, .k-scrollview-prev:focus .k-icon:before, .k-scrollview-next.k-state-focused .k-icon:before, .k-scrollview-prev.k-state-focused .k-icon:before {
  background-color: #0000;
  box-shadow: 0 0 0 2px #00000021;
}

.k-scrollview-next:hover, .k-scrollview-prev:hover, .k-scrollview-next.k-state-hovered, .k-scrollview-prev.k-state-hovered {
  color: #fff;
  opacity: 1;
}

.k-scrollview-next:hover .k-icon:before, .k-scrollview-prev:hover .k-icon:before, .k-scrollview-next.k-state-hovered .k-icon:before, .k-scrollview-prev.k-state-hovered .k-icon:before {
  background-color: #0000;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable, .k-scrollview .k-scrollview-nav {
  opacity: .7;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable:focus-within, .k-scrollview .k-scrollview-nav:focus-within, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable:focus, .k-scrollview .k-scrollview-nav:focus, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable.k-state-focused, .k-scrollview .k-scrollview-nav.k-state-focused, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable:hover, .k-scrollview .k-scrollview-nav:hover, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable.k-state-hovered, .k-scrollview .k-scrollview-nav.k-state-hovered {
  opacity: 1;
  background-color: #0000;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button, .k-scrollview .k-scrollview-nav > .k-link {
  background: #fff padding-box padding-box;
  border: #dbdbdb;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button.k-primary, .k-scrollview .k-scrollview-nav > .k-link.k-primary {
  background: #7ea700;
  border: #7ea700;
}

kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button:focus, .k-scrollview .k-scrollview-nav > .k-link:focus, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button.k-state-focused, .k-scrollview .k-scrollview-nav > .k-link.k-state-focused, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button:hover, .k-scrollview .k-scrollview-nav > .k-link:hover, kendo-scrollview.k-scrollview-wrap .k-scrollview-pageable > .k-button.k-state-hovered, .k-scrollview .k-scrollview-nav > .k-link.k-state-hovered {
  box-shadow: 0 0 0 2px #00000021;
}

.k-switch-container {
  box-shadow: null;
}

.k-switch-handle {
  background-clip: border-box;
}

.k-switch-on {
  box-shadow: null;
}

.k-switch-on .k-switch-container {
  color: #7ea700;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-switch-on .k-switch-handle {
  color: #7ea700;
  box-shadow: null;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-switch-on:focus, .k-switch-on.k-state-focused {
  box-shadow: null;
}

.k-switch-on:focus .k-switch-container, .k-switch-on.k-state-focused .k-switch-container {
  color: #7ea700;
  background-color: #fff;
  border-color: #7ea700;
}

.k-switch-on:focus .k-switch-handle, .k-switch-on.k-state-focused .k-switch-handle {
  color: #7ea700;
  background-color: #7ea700;
  border-color: #8dbb00;
}

.k-switch-on:hover .k-switch-container, .k-switch-on.k-state-hover .k-switch-container {
  color: #7ea700;
  background-color: #fff;
  border-color: #8ebc00;
}

.k-switch-on:hover .k-switch-handle, .k-switch-on.k-state-hover .k-switch-handle {
  color: #7ea700;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-switch-on .k-switch-label-off {
  color: #0000;
}

.k-switch-off {
  box-shadow: null;
}

.k-switch-off .k-switch-container {
  color: #787878;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-switch-off .k-switch-handle {
  color: #787878;
  box-shadow: null;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-switch-off:focus, .k-switch-off.k-state-focused {
  box-shadow: null;
}

.k-switch-off:focus .k-switch-container, .k-switch-off.k-state-focused .k-switch-container {
  color: #787878;
  background-color: #fff;
  border-color: #7ea700;
}

.k-switch-off:focus .k-switch-handle, .k-switch-off.k-state-focused .k-switch-handle {
  color: #787878;
  background-color: #fff;
  border-color: #8dbb00;
}

.k-switch-off:hover .k-switch-container, .k-switch-off.k-state-hover .k-switch-container {
  color: #787878;
  background-color: #fff;
  border-color: #8ebc00;
}

.k-switch-off:hover .k-switch-handle, .k-switch-off.k-state-hover .k-switch-handle {
  color: #787878;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-switch-off .k-switch-label-on {
  color: #0000;
}

.k-switch {
  box-shadow: none;
  background-color: #0000;
}

.k-switch:focus .k-switch-container, .k-switch.k-state-focused .k-switch-container {
  box-shadow: null;
  outline: none;
}

.k-switch:hover .k-switch-container, .k-switch.k-state-hover .k-switch-container {
  box-shadow: null;
}

.k-switch.k-state-disabled {
  cursor: default;
}

.k-switch.k-state-disabled:focus, .k-switch.k-state-disabled.k-state-focused {
  box-shadow: null;
}

.k-switch[aria-readonly="true"] {
  pointer-events: none;
}

.k-pane-wrapper .k-pane > .k-view > .k-content {
  background-color: #f4f4f4;
  background-position: 50%;
}

.k-pane-wrapper .k-pane .k-grid-mobile .k-column-active + th.k-header {
  border-left-color: #4c5356;
}

.k-pane-wrapper .k-pane .k-grid-mobile .k-sorted .k-sort-order, .k-pane-wrapper .k-pane .k-grid-mobile .k-sorted .k-i-sort-asc-sm, .k-pane-wrapper .k-pane .k-grid-mobile .k-sorted .k-i-sort-desc-sm {
  color: #7ea700;
}

.k-pane-wrapper .k-pane .k-grid-mobile .k-grid-filter.k-state-active, .k-pane-wrapper .k-pane .k-grid-mobile .k-header-column-menu.k-state-active {
  color: #fff;
  background-color: #7ea700;
}

.k-pane-wrapper .k-pane .k-grid-mobile .k-resize-handle-inner:before {
  background-color: #fff;
}

.k-pane-wrapper .k-pane .k-scheduler-mobile .k-nav-current {
  color: #7ea700;
}

.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendadate {
  color: inherit;
}

.k-pane-wrapper .k-pane .k-scheduler-mobile .k-time-period, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-mobile-scheduler-agendaweekday {
  color: #fff;
}

.k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-times, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-wrap, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-datecolumn, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-groupcolumn, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-group-cell, .k-pane-wrapper .k-pane .k-scheduler-mobile .k-scheduler-header-all-day {
  background-color: #f4f4f4;
}

.k-pane-wrapper .k-pane .k-scheduler-mobile .k-other-month {
  background-color: #f5f5f5;
}

.k-pane-wrapper .k-pane .k-grid-edit-form .k-header, .k-pane-wrapper .k-pane .k-grid-column-menu .k-header, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-header, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-header {
  background-color: #7ea700;
}

.k-pane-wrapper .k-pane .k-grid-edit-form .k-header, .k-pane-wrapper .k-pane .k-grid-column-menu .k-header, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-header, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-header, .k-pane-wrapper .k-pane .k-grid-edit-form .k-header .k-link, .k-pane-wrapper .k-pane .k-grid-column-menu .k-header .k-link, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-header .k-link, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-header .k-link {
  color: #fff;
}

.k-pane-wrapper .k-pane .k-grid-edit-form .k-item, .k-pane-wrapper .k-pane .k-grid-column-menu .k-item, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-item, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-item, .k-pane-wrapper .k-pane .k-grid-edit-form .k-link, .k-pane-wrapper .k-pane .k-grid-column-menu .k-link, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-link, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-link {
  color: #4c5356;
}

.k-pane-wrapper .k-pane .k-grid-edit-form .k-clear, .k-pane-wrapper .k-pane .k-grid-column-menu .k-clear, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-clear, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-clear, .k-pane-wrapper .k-pane .k-grid-edit-form .k-select-all, .k-pane-wrapper .k-pane .k-grid-column-menu .k-select-all, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-select-all, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-select-all, .k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-delete, .k-pane-wrapper .k-pane .k-grid-column-menu .k-scheduler-delete, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-delete, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-delete, .k-pane-wrapper .k-pane .k-grid-edit-form .k-scheduler-resetSeries, .k-pane-wrapper .k-pane .k-grid-column-menu .k-scheduler-resetSeries, .k-pane-wrapper .k-pane .k-grid-filter-menu .k-scheduler-resetSeries, .k-pane-wrapper .k-pane .k-scheduler-edit-form .k-scheduler-resetSeries {
  color: #7ea700;
}

.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-state-selected {
  color: #7ea700;
  background: none;
}

.k-pane-wrapper .k-pane .k-column-menu .k-listgroup-item.k-state-selected .k-link {
  color: inherit;
}

.k-filter .k-filter-preview-field {
  color: #7ea700;
}

.k-filter .k-filter-preview-operator {
  color: #78787899;
}

.k-filter .k-filter-item:before, .k-filter .k-filter-toolbar:before, .k-filter .k-filter-lines .k-filter-item:last-child > .k-filter-toolbar:after {
  background-color: #dbdbdb;
}

.k-drawer {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-drawer-wrapper {
  scrollbar-color: #9c9c9cb3 #dedede;
}

.k-drawer-wrapper::-webkit-scrollbar-track {
  background: #dedede;
}

.k-drawer-wrapper::-webkit-scrollbar-thumb {
  background: #9c9c9cb3;
}

.k-drawer-wrapper::-webkit-scrollbar-thumb:hover {
  background: #9c9c9c;
}

.k-drawer-item:hover, .k-drawer-item.k-state-hover {
  color: #fff;
  background-color: #8ebc00;
  background-image: none, linear-gradient(#8ebc00 0% 100%);
}

.k-drawer-item:focus, .k-drawer-item.k-state-focused {
  background-color: #f4f4f4;
  box-shadow: inset 0 0 0 1px #8ebc00;
}

.k-drawer-item:focus:hover, .k-drawer-item.k-state-focused:hover, .k-drawer-item:focus.k-state-hover, .k-drawer-item.k-state-focused.k-state-hover {
  color: #fff;
  background-color: #8ebc00;
}

.k-drawer-item.k-state-selected {
  color: #fff;
  background-color: #7ea700;
}

.k-drawer-item.k-state-selected:hover, .k-drawer-item.k-state-selected.k-state-hover {
  color: #fff;
  background-color: #6b8e00;
}

.k-drawer-separator {
  background-color: #dbdbdb;
}

.k-rating.k-widget {
  background-color: #0000;
}

.k-rating:focus, .k-rating.k-state-focused {
  box-shadow: none;
}

.k-rating:focus .k-rating-item > .k-icon, .k-rating.k-state-focused .k-rating-item > .k-icon {
  text-shadow: 0 2px 3.6px #0000001a;
}

.k-rating:focus .k-rating-item.k-state-selected > .k-icon, .k-rating.k-state-focused .k-rating-item.k-state-selected > .k-icon {
  text-shadow: 0 2px 3.6px #7ea70080;
}

.k-rating-item {
  color: #dbdbdb;
}

.k-rating-item.k-state-selected {
  color: #7ea700;
  box-shadow: none;
  background: none;
}

.k-rating-item.k-state-selected:focus, .k-rating-item.k-state-selected.k-state-focused {
  color: #7ea700;
}

.k-rating-item:hover, .k-rating-item.k-state-hover {
  color: #7ea700;
  cursor: pointer;
  box-shadow: none;
  background: none;
}

.k-rating-item:hover.k-state-selected, .k-rating-item.k-state-hover.k-state-selected {
  box-shadow: none;
}

.k-rating-item:focus, .k-rating-item.k-state-focused {
  box-shadow: none;
  background: none;
}

.k-rating-item:focus.k-state-selected, .k-rating-item.k-state-focused.k-state-selected {
  box-shadow: none;
}

.k-timeline .k-timeline-flag {
  color: #fff;
  background-color: #7ea700;
}

.k-timeline .k-timeline-circle {
  background-color: #7ea700;
}

.k-timeline .k-timeline-card .k-card-header {
  color: #4c5356;
  background-color: #f4f4f4;
}

.k-timeline .k-timeline-card .k-card-body {
  scrollbar-color: #dbdbdb #f4f4f4;
}

.k-timeline .k-timeline-card .k-card-body::-webkit-scrollbar-track {
  background: #f4f4f4;
}

.k-timeline .k-timeline-card .k-card-body::-webkit-scrollbar-thumb {
  background: #dbdbdb;
}

.k-timeline .k-timeline-card .k-card-body::-webkit-scrollbar-thumb:hover {
  background: #8ebc00;
}

.k-timeline .k-timeline-date {
  color: #787878;
}

.k-timeline .k-timeline-arrow.k-button:not(.k-flat):not(.k-bare):not(.k-outline):not(.k-overflow-button).k-state-disabled {
  opacity: 1;
  color: #ccc;
  background-color: #fff;
  border-color: #e6e6e6;
}

.k-timeline-vertical:after, .k-timeline-horizontal .k-timeline-track-wrap:after {
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-timeline-horizontal .k-timeline-flag:after {
  background-color: #7ea700;
}

.k-timeline-track-item.k-state-focus .k-timeline-circle {
  box-shadow: 0 3px 4px #0000000f;
}

.k-breadcrumb:focus, .k-breadcrumb.k-state-focused {
  box-shadow: inset 0 0 0 1px #8ebc00;
}

.k-breadcrumb .k-breadcrumb-link {
  color: #7ea700;
  background-color: #0000;
  border-color: #0000;
}

.k-breadcrumb .k-breadcrumb-link:hover, .k-breadcrumb .k-breadcrumb-link.k-state-hover {
  color: #6f9300;
  background-color: #00000014;
  border-color: #0000;
}

.k-breadcrumb .k-breadcrumb-link:focus, .k-breadcrumb .k-breadcrumb-link.k-state-focused {
  border-color: false;
  color: #7ea700;
  background-color: false;
  box-shadow: inset 0 0 0 2px #0000001f;
}

.k-breadcrumb .k-breadcrumb-root-link {
  border-color: false;
  color: #000;
  background-color: false;
}

.k-breadcrumb .k-breadcrumb-root-link:hover, .k-breadcrumb .k-breadcrumb-root-link.k-state-hover {
  border-color: false;
  color: false;
  background-color: #00000014;
}

.k-breadcrumb .k-breadcrumb-root-link:focus, .k-breadcrumb .k-breadcrumb-root-link.k-state-focused {
  border-color: false;
  color: false;
  background-color: false;
  box-shadow: inset 0 0 0 2px #0000001f;
}

.k-breadcrumb .k-breadcrumb-root-link, .k-breadcrumb .k-breadcrumb-delimiter-icon {
  color: inherit;
}

.k-listgroup {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
  border-radius: 0;
}

.k-listgroup-flush {
  border-radius: 0;
}

.k-messagebox-primary {
  color: #425700;
  background-color: #dbe6b8;
  border-color: #e5edcc;
}

.k-messagebox-secondary {
  color: #858585;
  background-color: #fff;
  border-color: #fff;
}

.k-messagebox-tertiary {
  color: #195124;
  background-color: #c5e3cb;
  border-color: #d6ebda;
}

.k-messagebox-info {
  color: #063e51;
  background-color: #bbd9e3;
  border-color: #cee4eb;
}

.k-messagebox-success {
  color: #16471f;
  background-color: #c4dec8;
  border-color: #d5e7d8;
}

.k-messagebox-warning {
  color: #855c1d;
  background-color: #ffe9c7;
  border-color: #ffefd7;
}

.k-messagebox-error {
  color: #760000;
  background-color: #f7b8b8;
  border-color: #f9cccc;
}

.k-messagebox-dark {
  color: #000;
  background-color: #b8b8b8;
  border-color: #ccc;
}

.k-messagebox-light {
  color: #858585;
  background-color: #fff;
  border-color: #fff;
}

.k-messagebox-inverse {
  color: #000;
  background-color: #b8b8b8;
  border-color: #ccc;
}

.k-badge-solid.k-badge-primary {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-badge-solid.k-badge-secondary {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.k-badge-solid.k-badge-tertiary {
  color: #fff;
  background-color: #309b46;
  border-color: #309b46;
}

.k-badge-solid.k-badge-info {
  color: #fff;
  background-color: #0c779b;
  border-color: #0c779b;
}

.k-badge-solid.k-badge-success {
  color: #fff;
  background-color: #2b893c;
  border-color: #2b893c;
}

.k-badge-solid.k-badge-warning {
  color: #000;
  background-color: #ffb137;
  border-color: #ffb137;
}

.k-badge-solid.k-badge-error {
  color: #fff;
  background-color: #e20000;
  border-color: #e20000;
}

.k-badge-solid.k-badge-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.k-badge-solid.k-badge-light {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.k-badge-solid.k-badge-inverse {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.k-badge-outline {
  background-color: #f4f4f4;
  border-color: currentColor;
}

.k-badge-outline.k-badge-primary {
  color: #7ea700;
}

.k-badge-outline.k-badge-secondary {
  color: #fff;
}

.k-badge-outline.k-badge-tertiary {
  color: #309b46;
}

.k-badge-outline.k-badge-info {
  color: #0c779b;
}

.k-badge-outline.k-badge-success {
  color: #2b893c;
}

.k-badge-outline.k-badge-warning {
  color: #ffb137;
}

.k-badge-outline.k-badge-error {
  color: #e20000;
}

.k-badge-outline.k-badge-dark {
  color: #000;
}

.k-badge-outline.k-badge-light {
  color: #fff;
}

.k-badge-outline.k-badge-inverse {
  color: #000;
}

.k-badge-border-cutout {
  box-shadow: 0 0 0 2px #f4f4f4;
}

.k-checkbox {
  color: #7ea700;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 0;
}

.k-checkbox:checked:before {
  border-radius: 0;
}

.k-checkbox.k-invalid, .k-checkbox.k-state-invalid {
  border-color: #ccc;
}

.k-checkbox:hover {
  color: #7ea700;
  background-color: #fff;
  border-color: #ccc;
}

.k-checkbox:focus {
  box-shadow: none;
  border-color: #7ea700;
}

.k-checkbox:indeterminate, .k-checkbox.k-state-indeterminate, .k-checkbox:checked {
  color: #7ea700;
  background-color: #fff;
  border-color: #ccc;
}

.k-checkbox:checked:focus {
  box-shadow: none;
  border-color: #7ea700;
}

.k-radio {
  color: #7ea700;
  background-color: #fff;
  border-color: #ccc;
  border-radius: 50%;
}

.k-radio:before {
  border-radius: 50%;
}

.k-radio:hover {
  color: #7ea700;
  background-color: #fff;
  border-color: #ccc;
}

.k-radio:focus {
  box-shadow: none;
  border-color: #ccc;
}

.k-radio:checked {
  color: #7ea700;
  background-color: #fff;
  border-color: #ccc;
}

.k-radio:checked:focus {
  box-shadow: none;
  border-color: #ccc;
}

.k-filemanager {
  border-color: false;
  color: false;
  background-color: false;
}

.k-filemanager-toolbar, .k-filemanager-navigation {
  color: false;
  background-color: false;
  border-color: #dbdbdb;
}

.k-filemanager-breadcrumb {
  color: false;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-filemanager .k-dropzone-hovered {
  border-color: false;
  color: inherit;
  background-color: inherit;
}

.k-filemanager-listview, .k-filemanager-listview .k-item {
  border-color: false;
  color: false;
  background-color: false;
}

.k-filemanager-listview .k-item.k-state-selected .k-icon {
  border-color: false;
  color: #fff;
  background-color: false;
}

.k-filemanager-listview .k-item .k-icon {
  border-color: false;
  color: #c7c7c7;
  background-color: false;
}

.k-filemanager-grid {
  border-color: false;
  color: false;
  background-color: false;
}

.k-filemanager-preview {
  color: false;
  background-color: false;
  border-color: #dbdbdb;
}

.k-filemanager-preview .k-file-preview-wrapper .k-icon {
  border-color: false;
  color: #c7c7c7;
  background-color: false;
}

.k-filemanager-preview .k-file-size {
  color: inherit;
}

.k-filemanager-drag-hint {
  border-color: false;
  color: #fff;
  background-color: #7ea700;
}

.k-filemanager-drag-hint .k-icon {
  border-color: false;
  color: #fff;
  background-color: false;
}

.k-toolbar {
  color: #4c5356;
  box-shadow: null;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-overflow-container .k-button-group .k-button, .k-overflow-container .k-split-button .k-button {
  border-radius: 0;
}

.k-window {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
  border-radius: 0;
  box-shadow: 1px 1px 7px 1px #0000001f;
}

.k-window.k-state-focused {
  border-color: #dbdbdb;
  box-shadow: 1px 1px 7px 1px #00000040;
}

.k-window-titlebar {
  color: #4c5356;
  background-color: #f4f4f4;
  background-image: linear-gradient(false);
  border-color: #dbdbdb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.k-window-action {
  border-radius: 0;
}

.k-window-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.k-window-titleless .k-window-content {
  border-radius: 0;
}

.k-dialog-buttongroup {
  border-color: #dbdbdb;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.k-stepper {
  border-color: false;
  color: false;
  background-color: false;
}

.k-stepper .k-step:hover .k-step-indicator, .k-stepper .k-step.k-step-hover .k-step-indicator {
  border-color: false;
  color: false;
  background-color: #dbdbdb;
}

.k-stepper .k-step.k-step-disabled .k-step-indicator, .k-stepper .k-step:disabled .k-step-indicator {
  color: #94989a;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-stepper .k-step-indicator {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-stepper .k-step-indicator:after {
  box-shadow: inset 0 0 0 2px #f4f4f4;
}

.k-stepper .k-step-label:only-child {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-stepper .k-step-done .k-step-indicator {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-stepper .k-step-done:hover .k-step-indicator, .k-stepper .k-step-done.k-step-hover .k-step-indicator {
  border-color: false;
  color: false;
  background-color: #587400;
}

.k-stepper .k-step-done.k-step-disabled .k-step-indicator, .k-stepper .k-step-done:disabled .k-step-indicator {
  color: #fff;
  background-color: #adc662;
  border-color: #adc662;
}

.k-stepper .k-step-current .k-step-indicator {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-stepper .k-step-current:hover .k-step-indicator, .k-stepper .k-step-current.k-step-hover .k-step-indicator {
  border-color: false;
  color: false;
  background-color: #587400;
}

.k-stepper .k-step-current.k-step-disabled .k-step-indicator, .k-stepper .k-step-current:disabled .k-step-indicator {
  color: #fff;
  background-color: #adc662;
  border-color: #adc662;
}

.k-stepper .k-step-label {
  color: #4c5356;
}

.k-stepper .k-step-success .k-step-label .k-icon {
  color: #061509;
}

.k-stepper .k-step-error .k-step-label {
  color: #ff7c7c;
}

.k-stepper .k-step-disabled .k-step-label {
  color: #ccc;
}

.k-stepper .k-step-focus .k-step-label:only-child, .k-stepper .k-step-link:focus .k-step-label:only-child {
  box-shadow: inset 0 0 0 2px #dbdbdb;
}

.k-stepper .k-step-optional .k-step-label-optional {
  opacity: .35;
}

.k-widget.k-form {
  background: none;
}

.k-form-error {
  color: #e20000;
}

.k-form-legend {
  border-color: #dbdbdb;
}

.k-tooltip, .k-tooltip.k-widget, .k-tooltip.k-popup {
  color: #fff;
  background-color: #92c100;
  border-color: #92c100;
  border-radius: 0;
  box-shadow: 0 2px 2px #0003;
}

.k-tooltip-primary {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-tooltip-secondary {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.k-tooltip-tertiary {
  color: #fff;
  background-color: #309b46;
  border-color: #309b46;
}

.k-tooltip-info {
  color: #fff;
  background-color: #0c779b;
  border-color: #0c779b;
}

.k-tooltip-success {
  color: #fff;
  background-color: #2b893c;
  border-color: #2b893c;
}

.k-tooltip-warning {
  color: #000;
  background-color: #ffb137;
  border-color: #ffb137;
}

.k-tooltip-error {
  color: #fff;
  background-color: #e20000;
  border-color: #e20000;
}

.k-tooltip-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.k-tooltip-light {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.k-tooltip-inverse {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.k-chart-tooltip {
  color: #fff;
}

.k-chart-tooltip-inverse {
  color: #000;
}

.k-wizard-step:focus, .k-wizard-step.k-state-focused {
  outline-color: #656565;
}

.k-ie .k-wizard-step:focus, .k-ie .k-wizard-step.k-state-focused {
  outline: none;
  box-shadow: inset 0 0 0 2px #dbdbdb;
}

.k-textarea {
  color: #787878;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-textarea::placeholder, .k-textarea > .k-input::placeholder {
  color: #78787880;
  opacity: 1;
  -webkit-user-select: none;
  user-select: none;
}

.k-textarea::selection, .k-textarea .k-input::selection {
  color: #fff;
  background-color: #7ea700;
}

.k-textarea:focus, .k-textarea:focus-within, .k-textarea.k-state-focus, .k-textarea.k-state-focused {
  color: #787878;
  box-shadow: none;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-textarea:hover, .k-textarea.k-state-hover {
  color: #787878;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-textarea.k-invalid, .k-textarea.k-state-invalid, .k-textarea.ng-invalid.ng-touched, .k-textarea.ng-invalid.ng-dirty {
  border-color: #e2000080;
}

.k-textarea.k-invalid:focus, .k-textarea.k-state-invalid:focus, .k-textarea.ng-invalid.ng-touched:focus, .k-textarea.ng-invalid.ng-dirty:focus, .k-textarea.k-invalid:focus-within, .k-textarea.k-state-invalid:focus-within, .k-textarea.ng-invalid.ng-touched:focus-within, .k-textarea.ng-invalid.ng-dirty:focus-within, .k-textarea.k-invalid.k-state-focus, .k-textarea.k-state-invalid.k-state-focus, .k-textarea.ng-invalid.ng-touched.k-state-focus, .k-textarea.ng-invalid.ng-dirty.k-state-focus, .k-textarea.k-invalid.k-state-focused, .k-textarea.k-state-invalid.k-state-focused, .k-textarea.ng-invalid.ng-touched.k-state-focused, .k-textarea.ng-invalid.ng-dirty.k-state-focused {
  box-shadow: null;
}

.k-textarea:disabled::selection, .k-textarea[disabled]::selection, .k-textarea.k-state-disabled::selection {
  color: #787878;
  background-color: #0000;
}

.k-floating-label-container.k-textarea-container > .k-label, .k-textbox-container.k-textarea-container > .k-label {
  color: #787878;
}

:is(.k-ie .k-textarea :-ms-input-placeholder, .k-ie .k-textarea:-ms-input-placeholder) {
  color: #78787880;
}

.k-appbar {
  box-shadow: 0 1px 1px #00000029;
}

.k-appbar.k-appbar-bottom {
  box-shadow: 0 -1px 1px #00000029;
}

.k-appbar-primary {
  color: #fff;
  background-color: #7ea700;
}

.k-appbar-secondary {
  color: #000;
  background-color: #fff;
}

.k-appbar-tertiary {
  color: #fff;
  background-color: #309b46;
}

.k-appbar-info {
  color: #fff;
  background-color: #0c779b;
}

.k-appbar-success {
  color: #fff;
  background-color: #2b893c;
}

.k-appbar-warning {
  color: #000;
  background-color: #ffb137;
}

.k-appbar-error {
  color: #fff;
  background-color: #e20000;
}

.k-appbar-dark {
  color: #fff;
  background-color: #000;
}

.k-appbar-light {
  color: #000;
  background-color: #fff;
}

.k-appbar-inverse {
  color: #fff;
  background-color: #000;
}

.k-loader-primary .k-loader-segment:after {
  background-color: #7ea700;
}

.k-loader-secondary .k-loader-segment:after {
  background-color: #fff;
}

.k-loader-tertiary .k-loader-segment:after {
  background-color: #309b46;
}

.k-loader-info .k-loader-segment:after {
  background-color: #0c779b;
}

.k-loader-success .k-loader-segment:after {
  background-color: #2b893c;
}

.k-loader-warning .k-loader-segment:after {
  background-color: #ffb137;
}

.k-loader-error .k-loader-segment:after {
  background-color: #e20000;
}

.k-loader-dark .k-loader-segment:after {
  background-color: #000;
}

.k-loader-light .k-loader-segment:after {
  background-color: #fff;
}

.k-loader-inverse .k-loader-segment:after {
  background-color: #000;
}

.k-task-delayed.k-task-milestone {
  background-image: repeating-linear-gradient(90deg, #fff, #fff 2px, #e20000 2px 4px);
  border-color: #0000;
}

.k-task-delayed.k-task-summary {
  background-color: #e83333;
  border-color: #e83333;
}

.k-task-delayed.k-task-summary .k-task-summary-complete {
  background-color: #e20000;
  border-color: #e20000;
}

.k-task-delayed.k-task-summary.k-state-selected .k-task-summary-complete {
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-task-delayed.k-task-single {
  color: #fff;
  background-color: #e83333;
}

.k-task-delayed.k-task-single.k-state-selected {
  color: #fff;
  background-color: #99cb00;
}

.k-task-delayed .k-task-complete {
  background-color: #e20000;
}

.k-task-delayed.k-state-selected .k-task-complete {
  background-color: #7ea700;
}

.k-task-advanced.k-task-milestone {
  background-color: #2b893c;
  border-color: #0000;
}

.k-task-advanced.k-task-summary {
  background-color: #55a163;
  border-color: #55a163;
}

.k-task-advanced.k-task-summary .k-task-summary-complete {
  background-color: #2b893c;
  border-color: #2b893c;
}

.k-task-advanced.k-task-summary.k-state-selected .k-task-summary-complete {
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-task-advanced.k-task-single {
  color: #fff;
  background-color: #55a163;
}

.k-task-advanced.k-task-single.k-state-selected {
  color: #fff;
  background-color: #99cb00;
}

.k-task-advanced .k-task-complete {
  background-color: #2b893c;
}

.k-task-advanced.k-state-selected .k-task-complete {
  background-color: #7ea700;
}

.k-task-planned .k-task-moment {
  border-color: #7ea700;
}

.k-task-planned .k-task-duration, .k-task-planned:hover .k-task-moment {
  background-color: #7ea700;
}

.k-task-offset {
  background-image: repeating-linear-gradient(135deg, #fff, #fff 2px, #e20000 2px 4px);
}

.k-task-offset .k-resize-handle:after {
  background-color: #000;
}

.k-task-offset:hover {
  background-image: repeating-linear-gradient(135deg, #fff, #fff 2px, #e83333 2px 4px);
}

.k-task-offset .k-task-actions .k-link {
  color: #000;
}

.k-task-offset-wrap .k-task-summary-complete:after, .k-task-offset-wrap .k-task-summary:after {
  border-right-color: #0000;
}

.k-rtl .k-task-offset-wrap .k-task-summary-complete:after, .k-rtl .k-task-offset-wrap .k-task-summary:after {
  border-right-color: inherit;
}

.k-rtl .k-task-offset-wrap .k-task-summary-complete:before, .k-rtl .k-task-offset-wrap .k-task-summary:before {
  border-left-color: #0000;
}

.k-summary-wrap .k-task-offset:after {
  border-color: #0000 #e20000 #0000 #0000;
}

.k-rtl .k-summary-wrap .k-task-offset:before {
  border-color: #0000 #0000 #0000 #e20000;
}

.k-rtl .k-summary-wrap .k-task-offset:after {
  border-right-color: #0000;
}

.k-pdf-export .k-task-offset {
  background-color: #e20000;
}

.k-offset-tooltip-delayed {
  color: #fff;
  background-color: #e20000;
}

.k-planned-tooltip {
  color: #fff;
  background-color: #7ea700;
}

.k-imageeditor {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-imageeditor-content {
  border-color: false;
  color: false;
  background-color: #bbb;
}

.k-imageeditor-canvas {
  transform-origin: 0 0 0;
  box-shadow: none;
  text-shadow: none;
  background-color: #fff;
  background-image: linear-gradient(45deg, #00000017 25%, #0000 25% 75%, #00000017 75% 0), linear-gradient(45deg, #00000017 25%, #0000 25% 75%, #00000017 75% 0), none;
  background-position: 0 0, 5px 5px;
  background-repeat: repeat, repeat;
  background-size: 10px 10px, 10px 10px;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  transition: none;
  transform: scaleX(1)scaleY(1)scaleZ(1);
}

.k-imageeditor-action-pane {
  color: false;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-imageeditor-crop {
  color: false;
  background-color: false;
  border-color: #fff;
}

.k-imageeditor-crop-overlay {
  background-color: #0000004d;
}

.k-imageeditor-crop .k-resize-handle {
  color: false;
  background-color: false;
  border-color: #fff;
}

.k-expander {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-expander-header {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #4c5356;
  outline: none;
}

.k-expander-header:focus, .k-expander-header.k-state-focused {
  box-shadow: inset 0 0 0 2px #00000021;
}

.k-expander-title {
  color: #7ea700;
}

.k-expander-sub-title {
  opacity: .65;
}

.k-fab {
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003;
}

.k-fab.k-fab-primary {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-fab.k-fab-secondary {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.k-fab.k-fab-tertiary {
  color: #fff;
  background-color: #309b46;
  border-color: #309b46;
}

.k-fab.k-fab-info {
  color: #fff;
  background-color: #0c779b;
  border-color: #0c779b;
}

.k-fab.k-fab-success {
  color: #fff;
  background-color: #2b893c;
  border-color: #2b893c;
}

.k-fab.k-fab-warning {
  color: #000;
  background-color: #ffb137;
  border-color: #ffb137;
}

.k-fab.k-fab-error {
  color: #fff;
  background-color: #e20000;
  border-color: #e20000;
}

.k-fab.k-fab-dark {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.k-fab.k-fab-light {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.k-fab.k-fab-inverse {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.k-state-hover.k-fab-primary, .k-fab.k-fab-primary:hover {
  background-color: #6b8e00;
  border-color: #6b8e00;
}

.k-state-hover.k-fab-secondary, .k-fab.k-fab-secondary:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.k-state-hover.k-fab-tertiary, .k-fab.k-fab-tertiary:hover {
  background-color: #2a883d;
  border-color: #2a883d;
}

.k-state-hover.k-fab-info, .k-fab.k-fab-info:hover {
  background-color: #0a6583;
  border-color: #0a6583;
}

.k-state-hover.k-fab-success, .k-fab.k-fab-success:hover {
  background-color: #257633;
  border-color: #257633;
}

.k-state-hover.k-fab-warning, .k-fab.k-fab-warning:hover {
  background-color: #ffa71e;
  border-color: #ffa71e;
}

.k-state-hover.k-fab-error, .k-fab.k-fab-error:hover {
  background-color: #c90000;
  border-color: #c90000;
}

.k-state-hover.k-fab-dark, .k-fab.k-fab-dark:hover {
  background-color: #000;
  border-color: #000;
}

.k-state-hover.k-fab-light, .k-fab.k-fab-light:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

.k-state-hover.k-fab-inverse, .k-fab.k-fab-inverse:hover {
  background-color: #000;
  border-color: #000;
}

.k-fab.k-state-focus, .k-fab:focus {
  box-shadow: 0 1px 10px #00000038, 0 4px 5px #0000003d, 0 2px 4px #0003;
}

.k-state-active.k-fab-primary, .k-state-selected.k-fab-primary, .k-fab-primary:active {
  background-color: #587400;
  border-color: #587400;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #7ea70066;
}

.k-state-active.k-fab-secondary, .k-state-selected.k-fab-secondary, .k-fab-secondary:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #fff6;
}

.k-state-active.k-fab-tertiary, .k-state-selected.k-fab-tertiary, .k-fab-tertiary:active {
  background-color: #247434;
  border-color: #247434;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #309b4666;
}

.k-state-active.k-fab-info, .k-state-selected.k-fab-info, .k-fab-info:active {
  background-color: #08536c;
  border-color: #08536c;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #0c779b66;
}

.k-state-active.k-fab-success, .k-state-selected.k-fab-success, .k-fab-success:active {
  background-color: #1f622b;
  border-color: #1f622b;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #2b893c66;
}

.k-state-active.k-fab-warning, .k-state-selected.k-fab-warning, .k-fab-warning:active {
  background-color: #ff9d04;
  border-color: #ff9d04;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #ffb13766;
}

.k-state-active.k-fab-error, .k-state-selected.k-fab-error, .k-fab-error:active {
  background-color: #af0000;
  border-color: #af0000;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #e2000066;
}

.k-state-active.k-fab-dark, .k-state-selected.k-fab-dark, .k-fab-dark:active {
  background-color: #000;
  border-color: #000;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #0006;
}

.k-state-active.k-fab-light, .k-state-selected.k-fab-light, .k-fab-light:active {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #fff6;
}

.k-state-active.k-fab-inverse, .k-state-selected.k-fab-inverse, .k-fab-inverse:active {
  background-color: #000;
  border-color: #000;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #0006;
}

.k-fab.k-state-disabled, .k-fab:disabled {
  opacity: 1;
  box-shadow: 0 4px 5px #0000000f, 0 1px 10px #0000000f, 0 2px 4px #00000014;
}

.k-state-disabled.k-fab-primary, .k-fab-primary:disabled {
  background-color: #bfd380;
}

.k-state-disabled.k-fab-secondary, .k-fab-secondary:disabled {
  background-color: #fff;
}

.k-state-disabled.k-fab-tertiary, .k-fab-tertiary:disabled {
  background-color: #98cda3;
}

.k-state-disabled.k-fab-info, .k-fab-info:disabled {
  background-color: #86bbcd;
}

.k-state-disabled.k-fab-success, .k-fab-success:disabled {
  background-color: #95c49e;
}

.k-state-disabled.k-fab-warning, .k-fab-warning:disabled {
  background-color: #ffd89b;
}

.k-state-disabled.k-fab-error, .k-fab-error:disabled {
  background-color: #f18080;
}

.k-state-disabled.k-fab-dark, .k-fab-dark:disabled {
  background-color: gray;
}

.k-state-disabled.k-fab-light, .k-fab-light:disabled {
  background-color: #fff;
}

.k-state-disabled.k-fab-inverse, .k-fab-inverse:disabled {
  background-color: gray;
}

.k-fab-item-text {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003;
}

.k-fab-item-icon {
  color: #000;
  background-color: #ebebeb;
  border-color: #ebebeb;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003;
}

.k-fab-item.k-state-hover {
  color: inherit;
  background-image: none;
  background-color: inherit;
  border-color: inherit;
  box-shadow: none;
}

.k-fab-item.k-state-hover .k-fab-item-icon, .k-fab-item:hover .k-fab-item-icon {
  background-color: #dedede;
  border-color: #dedede;
}

.k-fab-item:focus .k-fab-item-text, .k-fab-item:focus .k-fab-item-icon, .k-fab-item.k-state-focus .k-fab-item-text, .k-fab-item.k-state-focus .k-fab-item-icon {
  box-shadow: 0 1px 10px #00000038, 0 4px 5px #0000003d, 0 2px 4px #0003;
}

.k-fab-item.k-state-active .k-fab-item-icon, .k-fab-item:active .k-fab-item-icon {
  background-color: #d2d2d2;
  border-color: #d2d2d2;
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px #0003, 0 3px 4px #ebebeb66;
}

.k-fab-item.k-state-disabled, .k-fab-item:disabled {
  opacity: 1;
}

.k-fab-item.k-state-disabled .k-fab-item-text, .k-fab-item:disabled .k-fab-item-text, .k-fab-item.k-state-disabled .k-fab-item-icon, .k-fab-item:disabled .k-fab-item-icon {
  color: #dbdddd;
  opacity: 1;
  background-color: #f6f6f6;
  box-shadow: 0 4px 5px #0000000f, 0 1px 10px #0000000f, 0 2px 4px #00000014;
}

.k-popup.k-fab-popup {
  box-shadow: none;
}

.k-actionsheet {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f;
}

.k-actionsheet-header {
  box-shadow: null;
}

.k-actionsheet-action:hover, .k-actionsheet-action.k-state-hover {
  box-shadow: null;
  background-color: #8ebc00;
}

.k-actionsheet-action:focus, .k-actionsheet-action.k-state-focus {
  box-shadow: none;
}

.k-actionsheet-action:disabled, .k-actionsheet-action.k-state-disabled {
  box-shadow: null;
}

.k-bottom-nav-solid.k-bottom-nav-primary {
  color: #d2e0a6;
  background-color: #7ea700;
}

.k-bottom-nav-solid.k-bottom-nav-primary .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-primary .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-primary .k-bottom-nav-item:active {
  color: #fff;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-primary .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-primary .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-primary .k-bottom-nav-item:focus {
  background-color: #d2e0a633;
}

.k-bottom-nav-solid.k-bottom-nav-secondary {
  color: #595959;
  background-color: #fff;
}

.k-bottom-nav-solid.k-bottom-nav-secondary .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-secondary .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-secondary .k-bottom-nav-item:active {
  color: #000;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-secondary .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-secondary .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-secondary .k-bottom-nav-item:focus {
  background-color: #59595933;
}

.k-bottom-nav-solid.k-bottom-nav-tertiary {
  color: #b7dcbe;
  background-color: #309b46;
}

.k-bottom-nav-solid.k-bottom-nav-tertiary .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-tertiary .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-tertiary .k-bottom-nav-item:active {
  color: #fff;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-tertiary .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-tertiary .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-tertiary .k-bottom-nav-item:focus {
  background-color: #b7dcbe33;
}

.k-bottom-nav-solid.k-bottom-nav-info {
  color: #aacfdc;
  background-color: #0c779b;
}

.k-bottom-nav-solid.k-bottom-nav-info .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-info .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-info .k-bottom-nav-item:active {
  color: #fff;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-info .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-info .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-info .k-bottom-nav-item:focus {
  background-color: #aacfdc33;
}

.k-bottom-nav-solid.k-bottom-nav-success {
  color: #b5d6bb;
  background-color: #2b893c;
}

.k-bottom-nav-solid.k-bottom-nav-success .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-success .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-success .k-bottom-nav-item:active {
  color: #fff;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-success .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-success .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-success .k-bottom-nav-item:focus {
  background-color: #b5d6bb33;
}

.k-bottom-nav-solid.k-bottom-nav-warning {
  color: #593e13;
  background-color: #ffb137;
}

.k-bottom-nav-solid.k-bottom-nav-warning .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-warning .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-warning .k-bottom-nav-item:active {
  color: #000;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-warning .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-warning .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-warning .k-bottom-nav-item:focus {
  background-color: #593e1333;
}

.k-bottom-nav-solid.k-bottom-nav-error {
  color: #f5a6a6;
  background-color: #e20000;
}

.k-bottom-nav-solid.k-bottom-nav-error .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-error .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-error .k-bottom-nav-item:active {
  color: #fff;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-error .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-error .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-error .k-bottom-nav-item:focus {
  background-color: #f5a6a633;
}

.k-bottom-nav-solid.k-bottom-nav-dark {
  color: #a6a6a6;
  background-color: #000;
}

.k-bottom-nav-solid.k-bottom-nav-dark .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-dark .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-dark .k-bottom-nav-item:active {
  color: #fff;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-dark .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-dark .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-dark .k-bottom-nav-item:focus {
  background-color: #a6a6a633;
}

.k-bottom-nav-solid.k-bottom-nav-light {
  color: #595959;
  background-color: #fff;
}

.k-bottom-nav-solid.k-bottom-nav-light .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-light .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-light .k-bottom-nav-item:active {
  color: #000;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-light .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-light .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-light .k-bottom-nav-item:focus {
  background-color: #59595933;
}

.k-bottom-nav-solid.k-bottom-nav-inverse {
  color: #a6a6a6;
  background-color: #000;
}

.k-bottom-nav-solid.k-bottom-nav-inverse .k-bottom-nav-item.k-state-selected, .k-bottom-nav-solid.k-bottom-nav-inverse .k-bottom-nav-item.k-state-active, .k-bottom-nav-solid.k-bottom-nav-inverse .k-bottom-nav-item:active {
  color: #fff;
  background-color: inherit;
  box-shadow: none;
  background-image: none;
}

.k-bottom-nav-solid.k-bottom-nav-inverse .k-bottom-nav-item.k-state-focus, .k-bottom-nav-solid.k-bottom-nav-inverse .k-bottom-nav-item.k-state-focused, .k-bottom-nav-solid.k-bottom-nav-inverse .k-bottom-nav-item:focus {
  background-color: #a6a6a633;
}

.k-bottom-nav-solid .k-bottom-nav-item.k-state-disabled {
  color: inherit;
}

.k-bottom-nav-flat {
  color: #4c5356;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-bottom-nav-flat.k-bottom-nav-primary .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-primary .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-primary .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #7ea700;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-secondary .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-secondary .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-secondary .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #fff;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-tertiary .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-tertiary .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-tertiary .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #309b46;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-info .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-info .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-info .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #0c779b;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-success .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-success .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-success .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #2b893c;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-warning .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-warning .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-warning .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #ffb137;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-error .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-error .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-error .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #e20000;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-dark .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-dark .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-dark .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #000;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-light .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-light .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-light .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #ccc;
  background-image: none;
}

.k-bottom-nav-flat.k-bottom-nav-inverse .k-bottom-nav-item.k-state-selected, .k-bottom-nav-flat.k-bottom-nav-inverse .k-bottom-nav-item.k-state-active, .k-bottom-nav-flat.k-bottom-nav-inverse .k-bottom-nav-item:active {
  box-shadow: none;
  background-color: inherit;
  color: #000;
  background-image: none;
}

.k-bottom-nav-flat .k-bottom-nav-item.k-state-focus, .k-bottom-nav-flat .k-bottom-nav-item.k-state-focused, .k-bottom-nav-flat .k-bottom-nav-item:focus, .k-bottom-nav-flat .k-bottom-nav-item.k-state-focus.k-state-selected, .k-bottom-nav-flat .k-bottom-nav-item.k-state-focused.k-state-selected, .k-bottom-nav-flat .k-bottom-nav-item.k-state-selected:focus {
  background-color: #4c53560d;
}

.k-ie .k-bottom-nav-item {
  background-color: #0000;
}

.k-searchbox, .k-searchbox:hover, .k-searchbox.k-state-hover {
  color: #787878;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-searchbox:focus, .k-searchbox.k-state-focus, .k-searchbox:focus-within {
  color: #787878;
  box-shadow: none;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-searchbox:disabled, .k-searchbox.k-state-disabled {
  cursor: default;
  opacity: .6;
  filter: grayscale(.1);
  pointer-events: none;
  box-shadow: none;
  outline: none;
}

.k-skeleton {
  background-color: #0003;
}

.k-skeleton-wave .k-skeleton:after, .k-skeleton-wave.k-skeleton:after {
  background-image: linear-gradient(to right, #0000, #0000000a, #0000);
}

.k-taskboard-column {
  color: #4c5356;
  background-color: #f5f5f5;
  border-color: #0000;
}

.k-taskboard-column.k-state-focus, .k-taskboard-column:focus {
  border-color: #afafaf;
}

.k-taskboard-column-header-text {
  color: #4c5356;
}

.k-taskboard-pane, .k-taskboard-card {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-taskboard-card .k-card-header, .k-taskboard-card .k-card-footer {
  background-color: #f4f4f4;
}

.k-taskboard-card .k-card-title {
  color: #7ea700;
}

.k-taskboard-card .k-card-title:focus, .k-taskboard-card .k-card-title.k-state-focus {
  color: #4c6500;
}

.k-taskboard-card .k-card-title:hover, .k-taskboard-card .k-card-title.k-state-hover {
  color: #b0e900;
}

.k-taskboard-card:focus, .k-taskboard-card.k-state-focus {
  box-shadow: none;
  border-color: #b4b4b4;
}

.k-taskboard-card:hover, .k-taskboard-card.k-state-hover {
  border-color: #c5c5c5;
}

.k-taskboard-card.k-state-selected {
  color: #4c5356;
  background-color: #f4f4f4;
  background-image: none;
  border-color: #b0e900;
}

.k-taskboard-drag-placeholder {
  background-color: #fff3;
  border-color: #dbdbdb;
}

.k-scheduler-yearview .k-calendar {
  background-color: #fff;
}

.k-scheduler-yearview .k-month-header {
  color: #4c5356;
}

.k-scheduler-yearview .k-day-indicator {
  background-color: #7ea700;
}

.k-scheduler-yearview .k-state-selected .k-day-indicator {
  background-color: #fff;
}

.k-tooltip.k-scheduler-tooltip .k-tooltip-title .k-day {
  color: #787878;
}

.k-widget.k-captcha {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-captcha-validation-message.k-text-success {
  color: #2b893c;
}

.k-widget.k-popover {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
  box-shadow: 1px 1px 7px 1px #0000001f;
}

.k-popover-header {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-popover-body {
  background-color: inherit;
}

.k-popover-callout {
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-popover-callout.k-callout-n {
  box-shadow: 1px -1px 4px #0000000f;
}

.k-popover-callout.k-callout-e {
  box-shadow: 2px -1px 4px #0000000f;
}

.k-popover-callout.k-callout-s {
  box-shadow: 2px -2px 4px #0000000f;
}

.k-popover-callout.k-callout-w {
  box-shadow: 2px -1px 4px #0000000f;
}

.k-widget.k-orgchart {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-orgchart-card .k-card-body {
  border-color: #0000;
}

.k-orgchart-card:focus, .k-orgchart-card.k-state-focus {
  box-shadow: 0 3px 4px #0000000f;
}

.k-orgchart-node-group-container {
  color: #4c5356;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.k-orgchart-node-group-container:focus, .k-orgchart-node-group-container.k-state-focus, .k-orgchart-node-group-container.k-state-focused {
  box-shadow: 0 3px 4px #0000000f;
}

.k-orgchart-line-h, .k-orgchart-line-v {
  color: #dbdbdb;
}

.k-pivotgrid {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-pivotgrid-column-headers, .k-pivotgrid-row-headers, .k-pivotgrid-empty-cell {
  color: #4c5356;
  background-color: #f5f5f5;
  border-color: #dbdbdb;
}

.k-pivotgrid-empty-cell {
  border-color: #afafaf;
}

.k-pivotgrid-row-headers .k-pivotgrid-row .k-pivotgrid-cell:last-child {
  border-right-color: #afafaf;
}

.k-pivotgrid-column-headers .k-pivotgrid-column-total:nth-last-of-type(2) .k-pivotgrid-cell:not(.k-pivotgrid-expanded), .k-pivotgrid-column-headers .k-pivotgrid-row:last-child {
  border-bottom-color: #afafaf;
}

.k-pivotgrid-header-total, .k-pivotgrid-total {
  color: #4c5356;
  background-color: #dcdcdc;
  border-color: #dbdbdb;
}

.k-pivotgrid-row-headers tbody > .k-pivotgrid-row.k-state-hover, .k-pivotgrid-row-headers tbody > .k-pivotgrid-row:hover, .k-pivotgrid-column-headers tbody > .k-pivotgrid-row.k-state-hover, .k-pivotgrid-column-headers tbody > .k-pivotgrid-row:hover, .k-pivotgrid-values tbody > .k-pivotgrid-row.k-state-hover, .k-pivotgrid-values tbody > .k-pivotgrid-row:hover {
  background-color: #e2e2e2;
}

.k-pivotgrid-cell.k-state-focus, .k-pivotgrid-cell:focus, .k-master-row > .k-pivotgrid-cell:focus, .k-grouping-row > .k-pivotgrid-cell:focus, .k-detail-row > .k-pivotgrid-cell:focus, .k-group-footer > .k-pivotgrid-cell:focus {
  box-shadow: inset 0 0 0 1px #8ebc00;
}

.k-pivotgrid-cell.k-state-selected, .k-pivotgrid-row.k-state-selected > .k-pivotgrid-cell {
  background-color: #7ea70040;
}

.k-pivotgrid-configurator-button, .k-pivotgrid-configurator-panel {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-pivotgrid-configurator-header {
  color: #4c5356;
}

.k-pivotgrid-configurator-content .k-fields-list-wrapper {
  border-color: #dbdbdb;
}

.k-calculated-field {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-calculated-field-header {
  color: #4c5356;
}

.k-filter-menu .k-calculated-item, .k-pivotgrid-configurator-content .k-form-field-wrapper {
  border-color: #dbdbdb;
}

.k-flex-row .k-pivotgrid-configurator-overlay {
  box-shadow: -3px 0 6px #00000029;
}

.k-flex-row-reverse .k-pivotgrid-configurator-overlay {
  box-shadow: 3px 0 6px #00000029;
}

.k-flex-col .k-pivotgrid-configurator-overlay {
  box-shadow: 0 -3px 6px #00000029;
}

.k-flex-col-reverse .k-pivotgrid-configurator-overlay {
  box-shadow: 0 3px 6px #00000029;
}

.k-pivotgrid-column-menu .k-actions {
  border-color: #dbdbdb;
}

.k-selection-multiple .k-chip-has-icon.k-selected .k-selected-icon-wrapper {
  color: #fff;
  background-color: #00000070;
}

.k-chip-solid {
  color: #000;
  background-color: #00000014;
  border-color: #0000004d;
}

.k-chip-solid:hover, .k-chip-solid.k-hover {
  background-color: #00000029;
}

.k-chip-solid:focus, .k-chip-solid.k-focus {
  background-color: #00000014;
  box-shadow: 0 0 0 2px #00000029;
}

.k-chip-solid.k-selected {
  background-color: #0000003d;
}

.k-chip-solid.k-chip-success {
  color: #2b893c;
  background-color: #2b893c14;
  border-color: #2b893c4d;
}

.k-chip-solid.k-chip-success:hover, .k-chip-solid.k-chip-success.k-hover {
  background-color: #2b893c29;
}

.k-chip-solid.k-chip-success.k-selected {
  background-color: #2b893c3d;
}

.k-chip-solid.k-chip-warning {
  color: #ffb137;
  background-color: #ffb13714;
  border-color: #ffb1374d;
}

.k-chip-solid.k-chip-warning:hover, .k-chip-solid.k-chip-warning.k-hover {
  background-color: #ffb13729;
}

.k-chip-solid.k-chip-warning.k-selected {
  background-color: #ffb1373d;
}

.k-chip-solid.k-chip-error {
  color: #e20000;
  background-color: #e2000014;
  border-color: #e200004d;
}

.k-chip-solid.k-chip-error:hover, .k-chip-solid.k-chip-error.k-hover {
  background-color: #e2000029;
}

.k-chip-solid.k-chip-error.k-selected {
  background-color: #e200003d;
}

.k-chip-solid.k-chip-info {
  color: #0c779b;
  background-color: #0c779b14;
  border-color: #0c779b4d;
}

.k-chip-solid.k-chip-info:hover, .k-chip-solid.k-chip-info.k-hover {
  background-color: #0c779b29;
}

.k-chip-solid.k-chip-info.k-selected {
  background-color: #0c779b3d;
}

.k-chip-outline {
  color: #000;
  background-color: #f4f4f4;
  border-color: #000;
}

.k-chip-outline:hover, .k-chip-outline.k-hover {
  color: #fff;
  background-color: #000;
}

.k-chip-outline:focus, .k-chip-outline.k-focus {
  box-shadow: 0 0 0 2px #00000014;
}

.k-chip-outline.k-selected {
  color: #fff;
  background-color: #000;
}

.k-chip-outline.k-chip-success {
  color: #2b893c;
  border-color: #2b893c;
}

.k-chip-outline.k-chip-success:hover, .k-chip-outline.k-chip-success.k-hover {
  color: #fff;
  background-color: #2b893c;
}

.k-chip-outline.k-chip-success:focus, .k-chip-outline.k-chip-success.k-focus {
  background-color: nul;
}

.k-chip-outline.k-chip-success.k-selected {
  color: #fff;
  background-color: #2b893c;
}

.k-chip-outline.k-chip-warning {
  color: #ffb137;
  border-color: #ffb137;
}

.k-chip-outline.k-chip-warning:hover, .k-chip-outline.k-chip-warning.k-hover {
  color: #000;
  background-color: #ffb137;
}

.k-chip-outline.k-chip-warning:focus, .k-chip-outline.k-chip-warning.k-focus {
  background-color: nul;
}

.k-chip-outline.k-chip-warning.k-selected {
  color: #000;
  background-color: #ffb137;
}

.k-chip-outline.k-chip-error {
  color: #e20000;
  border-color: #e20000;
}

.k-chip-outline.k-chip-error:hover, .k-chip-outline.k-chip-error.k-hover {
  color: #fff;
  background-color: #e20000;
}

.k-chip-outline.k-chip-error:focus, .k-chip-outline.k-chip-error.k-focus {
  background-color: nul;
}

.k-chip-outline.k-chip-error.k-selected {
  color: #fff;
  background-color: #e20000;
}

.k-chip-outline.k-chip-info {
  color: #0c779b;
  border-color: #0c779b;
}

.k-chip-outline.k-chip-info:hover, .k-chip-outline.k-chip-info.k-hover {
  color: #fff;
  background-color: #0c779b;
}

.k-chip-outline.k-chip-info:focus, .k-chip-outline.k-chip-info.k-focus {
  background-color: nul;
}

.k-chip-outline.k-chip-info.k-selected {
  color: #fff;
  background-color: #0c779b;
}

.k-chip-info:focus, .k-chip-info.k-focus {
  box-shadow: 0 0 0 2px #0c779b29;
}

.k-chip-success:focus, .k-chip-success.k-focus {
  box-shadow: 0 0 0 2px #2b893c29;
}

.k-chip-warning:focus, .k-chip-warning.k-focus {
  box-shadow: 0 0 0 2px #ffb13729;
}

.k-chip-error:focus, .k-chip-error.k-focus {
  box-shadow: 0 0 0 2px #e2000029;
}

.k-panelbar {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
  color: #4c5356;
  background-color: #f4f4f4;
}

.k-panelbar > .k-item > .k-link .k-icon, .k-panelbar > .k-panelbar-header > .k-link .k-icon, .k-panelbar > .k-item > .k-link .k-panelbar-item-icon, .k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
  color: #4c5356;
}

.k-panelbar > .k-item > .k-link:hover, .k-panelbar > .k-panelbar-header > .k-link:hover, .k-panelbar > .k-item > .k-link.k-state-hover, .k-panelbar > .k-panelbar-header > .k-link.k-state-hover {
  background-color: #7ea700;
}

.k-panelbar > .k-item > .k-link:focus, .k-panelbar > .k-panelbar-header > .k-link:focus, .k-panelbar > .k-item > .k-link.k-state-focus, .k-panelbar > .k-panelbar-header > .k-link.k-state-focus, .k-panelbar > .k-item > .k-link.k-state-focused, .k-panelbar > .k-panelbar-header > .k-link.k-state-focused {
  box-shadow: inset 0 0 0 1px #8ebc00;
}

.k-panelbar > .k-item > .k-link.k-state-selected, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected {
  color: #fff;
  background-color: #7ea700;
}

.k-panelbar > .k-item > .k-link.k-state-selected .k-icon, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected .k-icon, .k-panelbar > .k-item > .k-link.k-state-selected .k-panelbar-item-icon, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected .k-panelbar-item-icon {
  color: inherit;
}

.k-panelbar > .k-item > .k-link.k-state-selected:hover, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected:hover, .k-panelbar > .k-item > .k-link.k-state-selected.k-state-hover, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected.k-state-hover {
  background-color: #719600;
}

.k-panelbar .k-panelbar-content {
  color: #4c5356;
  background-color: #f4f4f4;
}

.k-panelbar .k-group > .k-item > .k-link:hover, .k-panelbar .k-panelbar-group > .k-item > .k-link:hover, .k-panelbar .k-group > .k-item > .k-link.k-state-hover, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-hover, .k-panelbar .k-group > .k-panelbar-item > .k-link:hover, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:hover, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-hover, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-hover {
  background-color: #7ea700;
}

.k-panelbar .k-group > .k-item > .k-link:focus, .k-panelbar .k-panelbar-group > .k-item > .k-link:focus, .k-panelbar .k-group > .k-item > .k-link.k-state-focus, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-focus, .k-panelbar .k-group > .k-item > .k-link.k-state-focused, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-focused, .k-panelbar .k-group > .k-panelbar-item > .k-link:focus, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link:focus, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-focus, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-focus, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-focused, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-focused {
  box-shadow: inset 0 0 0 1px #8ebc00;
}

.k-panelbar .k-group > .k-item > .k-link.k-state-selected, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-selected, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-selected, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-selected {
  color: #fff;
  background-color: #7ea700;
  background-image: none;
}

.k-panelbar .k-group > .k-item > .k-link.k-state-selected:hover, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-selected:hover, .k-panelbar .k-group > .k-item > .k-link.k-state-selected.k-state-hover, .k-panelbar .k-panelbar-group > .k-item > .k-link.k-state-selected.k-state-hover, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-selected:hover, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-selected:hover, .k-panelbar .k-group > .k-panelbar-item > .k-link.k-state-selected.k-state-hover, .k-panelbar .k-panelbar-group > .k-panelbar-item > .k-link.k-state-selected.k-state-hover {
  background-color: #719600;
}

.k-colorpalette-tile:focus, .k-colorpalette-tile.k-state-focus, .k-colorpalette-tile.k-focus {
  box-shadow: 0 0 3px 1px #0000004d, inset 0 0 0 1px #ffffff80;
}

.k-colorpalette-tile:hover, .k-colorpalette-tile.k-state-hover, .k-colorpalette-tile.k-hover {
  box-shadow: 0 0 3px 1px #0000004d, inset 0 0 0 1px #fffc;
}

.k-colorpalette-tile.k-state-selected, .k-colorpalette-tile.k-state-selected:hover {
  box-shadow: 0 1px 3px 1px #0000004d, inset 0 0 0 1px #fff;
}

.k-colorgradient {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-colorgradient:focus, .k-colorgradient.k-state-focus, .k-colorgradient.k-focus {
  box-shadow: 1px 1px 7px 1px #0000004d;
}

.k-colorgradient-canvas .k-hsv-gradient {
  background: linear-gradient(#0000, #000), linear-gradient(to right, #fff, #fff0);
}

.k-colorgradient .k-draghandle {
  background-color: #0000;
  border-color: #fffc;
  box-shadow: 0 1px 4px #00000080;
}

.k-colorgradient .k-draghandle:focus, .k-colorgradient .k-draghandle.k-state-focus, .k-colorgradient .k-draghandle.k-focus, .k-colorgradient .k-draghandle:active, .k-colorgradient .k-draghandle:hover, .k-colorgradient .k-draghandle.k-state-hover, .k-colorgradient .k-draghandle.k-hover {
  background-color: #0000;
  border-color: #fff;
  box-shadow: 0 1px 4px #000;
}

.k-colorgradient .k-draghandle.k-state-selected:hover {
  color: inherit;
  background-color: #0000;
  border-color: #fff;
}

.k-colorgradient .k-draghandle.k-state-focused.k-state-selected {
  color: inherit;
  background-color: #0000;
  border-color: #fff;
  box-shadow: 0 1px 4px #000;
}

.k-colorgradient-slider.k-hue-slider.k-slider-horizontal .k-slider-track {
  background: linear-gradient(to right, red, #ff0, #0f0, #0ff, #00f, #f0f, red);
}

.k-colorgradient-slider.k-hue-slider.k-slider-vertical .k-slider-track {
  background: linear-gradient(to top, red, #ff0, #0f0, #0ff, #00f, #f0f, red);
}

.k-colorgradient-slider.k-alpha-slider .k-slider-track:before {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC") center;
}

.k-colorgradient-input-label {
  color: #ccc;
}

.k-colorgradient .k-text-success {
  color: #2b893c;
}

.k-color-preview {
  border-color: #dbdbdb;
}

.k-color-preview:before {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAuSURBVHgBxYyxDQAwDMJIL+YT+DjtzFRliUfLcklqBCRT4eCTxbD6kdL2/LgYXqpvCbs3kBv/AAAAAElFTkSuQmCC") 0 / contain;
}

.k-color-preview:hover, .k-color-preview.k-state-hover, .k-color-preview.k-hover {
  border-color: #8ebc00;
}

.k-no-color:after {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2220%22%20height%3D%2220%22%20version%3D%221.1%22%3E%3Cline%20x1%3D%220%22%20x2%3D%2220%22%20y1%3D%220%22%20y2%3D%2220%22%20stroke%3D%22%23e20000%22%20stroke-width%3D%221%22/%3E%3C/svg%3E");
  background-size: 100% 100%;
}

.k-coloreditor {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-coloreditor:focus, .k-coloreditor.k-state-focus, .k-coloreditor.k-focus {
  box-shadow: 1px 1px 7px 1px #0000004d;
}

.k-in, .k-item {
  border-color: #0000;
}

.k-splitbar .k-resize-handle {
  background-color: #787878;
}

.k-block, .k-widget {
  background-color: #f4f4f4;
}

.k-block, .k-widget, .k-input, .k-group, .k-content, .k-header, .k-filter-row > th, .k-separator, .k-autocomplete, .k-dropdown-wrap, .k-toolbar, .k-group-footer td, .k-grid-footer, .k-footer-template td, .k-state-default, .k-state-default .k-select, .k-state-disabled, .k-grid-header, .k-grid-header-wrap, .k-grid-header-locked, .k-grid-footer-locked, .k-grid-content-locked, .k-grid td, .k-grid td.k-state-selected, .k-grid-footer-wrap, .k-grouping-header, .k-grouping-header .k-group-indicator, .k-panelbar > .k-item > .k-link, .k-panel > .k-item > .k-link, .k-panelbar .k-panel, .k-panelbar .k-content, .k-treemap-tile, .k-slider-track, .k-splitbar, .k-dropzone-active, .k-upload-files, .k-upload .k-action-buttons, .k-popup.k-align .k-list .k-item:last-child, .k-maskedtextbox.k-state-disabled > .k-textbox:hover, .k-dateinput.k-state-disabled > .k-textbox:hover {
  border-color: #dbdbdb;
}

.k-group, .k-grouping-header, .k-group-footer td, .k-grid-footer, .k-footer-template td, .k-widget .k-status, .k-dropzone-hovered, .k-popup {
  background-color: #fff;
}

.k-grouping-row td, td.k-group-cell, .k-resize-handle-inner {
  background-color: #f4f4f4;
}

.k-list-container {
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-content, .k-panelbar > li.k-item, .k-panel > li.k-item {
  background-color: #fff;
}

.k-alt, .k-separator, .k-resource.k-alt, .k-pivot-layout > tbody > tr:first-child > td:first-child {
  background-color: #f5f5f5;
}

.k-pivot-rowheaders .k-alt .k-alt, .k-header.k-alt {
  background-color: contrst(#787878, #e0e0e0, #fff, .5);
}

.k-textbox, .k-dropdown-wrap.k-state-active, .k-picker-wrap.k-state-active, .k-numeric-wrap.k-state-active {
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-textbox > input, .k-autocomplete .k-input, .k-dropdown-wrap .k-input, .k-textbox.k-state-focused, .k-autocomplete.k-state-focused .k-input, .k-dropdown-wrap.k-state-focused .k-input, .k-picker-wrap.k-state-focused .k-input, .k-numeric-wrap.k-state-focused .k-input {
  border-color: #dbdbdb;
}

.k-input, .k-textbox, .k-textbox > input, .k-multiselect-wrap {
  color: #787878;
  background-color: #fff;
}

.k-input-label {
  color: #787878;
}

.k-text-error {
  color: #e20000;
}

.k-input[readonly] {
  color: #787878;
  background-color: #fff;
}

.k-block, .k-widget, .k-popup, .k-content, .k-toolbar, .k-dropdown .k-input {
  color: #4c5356;
}

.k-inverse {
  color: #fff;
}

.k-block {
  color: #4c5356;
}

.k-link {
  color: #787878;
}

.k-tabstrip {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-tabstrip > .k-content {
  border-color: #7ea700;
}

.k-tabstrip .k-tabstrip-items-wrapper .k-tabstrip-items .k-item {
  border-radius: 0;
}

.k-tabstrip-items .k-link, .k-panelbar > li > .k-link, .k-header, .k-menu, .k-tabstrip, .k-drag-clue, .k-treemap-title, .k-grid-header .k-header > .k-link {
  color: #4c5356;
}

.k-header, .k-grid-header, .k-menu, .k-tabstrip, .k-drag-clue, .k-toolbar, .k-dropdown-wrap, .k-picker-wrap, .k-numeric-wrap, .k-grouping-header, .k-progressbar, .k-draghandle, .k-autocomplete, .k-state-highlight, .k-tabstrip-items .k-item, .k-panelbar .k-tabstrip-items .k-item {
  background-color: #f4f4f4;
  background-position: 50%;
}

.k-block, .k-treemap-tile {
  background-color: #f4f4f4;
}

.k-mediaplayer-toolbar {
  background: #f4f4f4d9;
}

.k-hr {
  border-color: #dbdbdb;
}

.k-icon:hover, .k-state-hover .k-icon, .k-state-selected .k-icon, .k-state-focused .k-icon, .k-column-menu .k-state-hover .k-sprite, .k-column-menu .k-state-active .k-sprite, .k-icon, .k-state-disabled .k-icon, .k-column-menu .k-sprite {
  opacity: 1;
}

.k-clear-value {
  color: #787878;
}

.k-i-loading {
  background-image: url("loading.85c22921.gif");
}

.k-loading-image {
  background-image: url("loading-image.993b42d1.gif");
}

.k-loading-color {
  background-color: #fff;
}

.k-draghandle {
  background-color: #8ebc00;
  border-color: #8ebc00;
  box-shadow: 0 1px 1px #5f5f5f4d;
}

.k-draghandle:hover {
  background-color: #7ea700;
  border-color: #7ea700;
  box-shadow: 0 1px 1px #5f5f5f4d;
}

.k-drop-hint-line {
  background-color: #7ea700;
}

.k-drop-hint-h .k-drop-hint-start {
  border-left-color: #7ea700;
}

.k-drop-hint-h .k-drop-hint-end {
  border-right-color: #7ea700;
}

.k-drop-hint-v .k-drop-hint-start {
  border-top-color: #7ea700;
}

.k-drop-hint-v .k-drop-hint-end {
  border-bottom-color: #7ea700;
}

.k-scheduler {
  color: #fff;
  background-color: #fff;
}

.k-scheduler-layout {
  color: #4c5356;
}

.k-scheduler-datecolumn, .k-scheduler-groupcolumn {
  color: #4c5356;
  background-color: #fff;
}

.k-scheduler-times tr, .k-scheduler-times th, .k-scheduler-table td, .k-scheduler-header th, .k-scheduler-header-wrap, .k-scheduler-times {
  border-color: #dbdbdb;
}

.k-scheduler-table .k-today, .k-today > .k-scheduler-datecolumn, .k-today > .k-scheduler-groupcolumn {
  background-color: #fafafa;
}

.k-scheduler-table .k-nonwork-hour {
  background-color: #f5f5f5;
}

.k-gantt .k-nonwork-hour {
  background-color: #00000005;
}

.k-gantt .k-header.k-nonwork-hour {
  background-color: #0003;
}

.k-scheduler-now-arrow {
  border-left-color: #e20000;
}

.k-scheduler-now-line {
  background-color: #e20000;
}

.k-event, .k-task-complete {
  color: #fff;
  background: #7ea700;
  border-color: #7ea700;
}

.k-event-inverse {
  color: #000;
}

.k-event.k-state-selected {
  background-position: 0 0;
  box-shadow: 0 0 0 2px #787878;
}

.k-event .k-resize-handle:after, .k-task-single .k-resize-handle:after {
  background-color: #787878;
}

.k-scheduler-marquee:before, .k-scheduler-marquee:after {
  border-color: #7ea700;
}

.k-panelbar .k-content, .k-panelbar .k-panel, .k-panelbar .k-item {
  color: #787878;
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-panelbar > li > .k-link {
  color: #787878;
}

.k-panelbar > .k-item > .k-link {
  border-color: #dbdbdb;
}

.k-panel > li.k-item {
  background-color: #fff;
}

.k-state-active, .k-state-active:hover, .k-active-filter, .k-header-column-menu.k-state-active {
  color: #7ea700;
  background-color: #fff;
  border-color: #7ea700;
}

.k-fieldselector .k-list-container {
  background-color: #fff;
}

.k-tabstrip-scrollable .k-button.k-flat:hover {
  background: #8ebc00 !important;
}

.k-menu .k-state-hover > .k-state-active {
  background-color: #0000;
}

.k-mediaplayer-toolbar .k-button.k-flat:active, .k-mediaplayer-toolbar .k-button.k-flat.k-state-active, .k-mediaplayer-toolbar .k-button.k-flat.k-state-active:hover {
  color: #7ea700;
}

.k-menu .k-state-selected > .k-link {
  color: #fff;
  background-color: #7ea700;
  background-image: none;
  border-color: #7ea700;
}

.k-menu .k-link.k-state-active {
  color: #000;
  background-color: #fff;
  border-color: #7ea700;
}

.k-menu .k-state-hover > .k-link, .k-menu .k-menu-scroll-button:hover {
  color: #fff;
  background-color: #8ebc00;
  background-image: none, linear-gradient(#8ebc00 0% 100%);
  border-color: #8ebc00;
}

.k-state-highlight {
  color: #000;
  background: #fff;
}

.k-state-focused, .k-grouping-row .k-state-focused {
  border-color: #8ebc00;
}

.k-calendar-container.k-group {
  border-color: #dbdbdb;
}

.k-state-selected, .k-state-selected:link, .k-state-selected:visited, .k-list > .k-state-selected, .k-list > .k-state-highlight, .k-panel > .k-state-selected, .k-ghost-splitbar-vertical, .k-ghost-splitbar-horizontal, .k-draghandle.k-state-selected:hover, .k-scheduler .k-today.k-state-selected, .k-marquee-color {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-virtual-item.k-first, .k-group-header + .k-list > .k-item.k-first, .k-static-header + .k-list > .k-item.k-first, .k-group-header + div > .k-list > .k-item.k-first:before {
  border-top-color: #8ebc00;
}

.k-popup > .k-group-header, .k-popup > .k-virtual-wrap > .k-group-header {
  color: #fff;
  background: #8ebc00;
}

.k-popup .k-list .k-item > .k-group {
  color: #fff;
  background: #8ebc00;
  border-bottom-left-radius: 0;
}

.k-marquee-text {
  color: #fff;
}

.k-state-focused, .k-list > .k-state-focused, .k-listview > .k-state-focused, .k-grid-header th.k-state-focused, td.k-state-focused {
  box-shadow: inset 0 0 0 1px #8ebc00;
}

.k-state-focused.k-state-selected, .k-list > .k-state-focused.k-state-selected, .k-listview > .k-state-focused.k-state-selected, td.k-state-focused.k-state-selected {
  box-shadow: inset 0 0 3px 1px #445b00;
}

.k-list-optionlabel.k-state-selected.k-state-focused {
  box-shadow: none;
}

.k-state-selected > .k-link, .k-panelbar > li > .k-state-selected, .k-panelbar .k-item .k-link.k-state-selected, .k-panelbar > li.k-state-default > .k-link.k-state-selected {
  color: #fff;
}

.k-state-hover, .k-splitbar-horizontal-hover, .k-splitbar-vertical-hover, .k-list > .k-state-hover, .k-dropdown .k-state-focused, .k-filebrowser-dropzone {
  color: #fff;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-state-hover > .k-select, .k-state-focused > .k-select {
  border-color: #8ebc00;
}

.k-state-hover, .k-other-month.k-state-hover .k-link, div.k-filebrowser-dropzone em, .k-draghandle:hover, .k-listbox .k-item:hover:not(.k-state-disabled) {
  background-image: none, linear-gradient(#8ebc00 0% 100%);
}

.k-pager-wrap {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-pager-wrap .k-link {
  border-color: #dbdbdb;
}

.k-pager-wrap .k-link:hover {
  color: #fff;
  background-color: #8ebc00;
  border-color: #8ebc00;
}

.k-pager-wrap .k-link.k-state-disabled {
  color: #787878;
  border-color: #dbdbdb;
}

.k-pager-wrap .k-link.k-state-selected {
  color: #fff;
  background-color: #7ea700;
  border-color: #7ea700;
}

.k-pager-wrap .k-link:focus {
  box-shadow: inset 0 0 0 1px #8ebc00;
}

.k-pager-wrap .k-pager-numbers .k-link, .k-pager-wrap .k-pager-refresh {
  border-color: #0000;
}

.k-autocomplete.k-state-active, .k-picker-wrap.k-state-active, .k-numeric-wrap.k-state-active, .k-dropdown-wrap.k-state-active, .k-state-active, .k-state-active:hover, .k-state-active > .k-link, .k-panelbar > .k-item > .k-state-focused, .k-state-selected, .k-draghandle.k-state-selected:hover {
  background-image: none;
}

.k-draghandle.k-state-selected:hover {
  background-position: 50%;
}

.k-state-hover > .k-link, .k-other-month.k-state-hover .k-link, div.k-filebrowser-dropzone em {
  color: #fff;
}

.k-autocomplete.k-state-hover, .k-autocomplete.k-state-focused, .k-picker-wrap.k-state-hover, .k-picker-wrap.k-state-focused, .k-numeric-wrap.k-state-hover, .k-numeric-wrap.k-state-focused, .k-dropdown-wrap.k-state-hover, .k-dropdown-wrap.k-state-focused {
  color: #fff;
  background-color: #8ebc00;
  background-image: none, linear-gradient(#8ebc00 0% 100%);
  background-position: 50%;
  border-color: #dbdbdb;
}

.k-dropdown .k-state-hover .k-input, .k-dropdown .k-state-focused .k-input {
  color: #fff;
}

.k-state-error {
  color: #454545;
  background-color: #e20000;
  border-color: #e20000;
}

.k-state-disabled {
  opacity: .6;
}

.k-loading-mask.k-state-selected {
  background-color: #0000;
  background-image: none;
  border-width: 0;
}

.k-state-disabled, .k-state-disabled .k-link, .k-other-month, .k-other-month .k-link, .k-dropzone em, .k-slider .k-draghandle {
  color: #ccc;
}

.k-dropzone .k-upload-status, .k-file .k-upload-status {
  color: #787878;
}

.k-upload-status .k-button:hover .k-i-delete, .k-upload-status .k-button:hover .k-i-delete {
  background-color: #7ea700;
}

.k-progressbar-indeterminate {
  background: url("indeterminate.b17e5ee2.gif");
}

.k-progressbar-indeterminate .k-progress-status-wrap, .k-progressbar-indeterminate .k-state-selected {
  display: none;
}

.k-progressbar-horizontal > .k-state-selected, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected, .k-progressbar-horizontal.k-progressbar-reverse > .k-state-selected, .k-rtl .k-progressbar-horizontal > .k-state-selected, .k-progressbar-vertical > .k-state-selected, .k-progressbar-vertical.k-progressbar-reverse > .k-state-selected, .k-progressbar > .k-state-selected.k-complete, .k-rtl .k-progressbar > .k-state-selected.k-complete, .k-progressbar-horizontal .k-first, .k-rtl .k-progressbar-horizontal .k-last, .k-rtl .k-progressbar-horizontal.k-progressbar-reverse .k-last, .k-progressbar-horizontal .k-last, .k-rtl .k-progressbar-horizontal .k-first, .k-progressbar-vertical .k-first, .k-progressbar-vertical .k-last {
  border-radius: 0;
}

.k-slider-track {
  background-color: #dbdbdb;
}

.k-slider-selection {
  background-color: #7ea700;
}

.k-slider-horizontal .k-tick {
  background-image: url("slider-h.ab34df59.gif");
}

.k-slider-vertical .k-tick {
  background-image: url("slider-v.15f619e8.gif");
}

.k-chart-crosshair-tooltip, .k-chart-shared-tooltip {
  color: #fff;
  background-color: #92c100;
  border-color: #92c100;
}

.k-splitbar {
  background-color: #dbdbdb;
}

.k-restricted-size-vertical, .k-restricted-size-horizontal {
  background-color: #454545;
}

.k-file {
  border-color: #dbdbdb;
}

.k-file:last-child {
  border-radius: 0;
}

.k-file-progress .k-progress {
  background-color: #0c779b;
}

.k-file-success .k-file-validation-message, .k-file-success .k-upload-pct {
  color: #2b893c;
}

.k-file-success .k-progress {
  background-color: #2b893c;
}

.k-file-error .k-file-validation-message, .k-file-invalid .k-file-validation-message, .k-file-error .k-upload-pct, .k-file-invalid .k-upload-pct {
  color: #e20000;
}

.k-file-error .k-progress, .k-file-invalid .k-progress {
  background-color: #e20000;
}

.k-file-extension-wrapper, .k-multiple-files-extension-wrapper, .k-file-group-wrapper, .k-multiple-files-group-wrapper, .k-file-invalid-group-wrapper {
  color: #ccc;
  border-color: #ccc;
}

.k-file-extension-wrapper:before, .k-multiple-files-extension-wrapper:before {
  background-color: #fff;
  border-color: #0000 #0000 #ccc #ccc;
}

.k-multiple-files-extension-wrapper:after {
  border-top-color: #ccc;
  border-left-color: #ccc;
}

.k-file-size, .k-file-information, .k-file-validation-message {
  color: #ccc;
}

.k-textbox:hover {
  border-color: #8ebc00;
}

.k-leaf, .k-leaf.k-state-hover {
  color: #fff;
}

.k-leaf.k-inverse, .k-leaf.k-inverse.k-state-hover {
  color: #000;
}

.k-widget {
  box-shadow: null;
}

.k-slider, .k-treeview, .k-upload, .k-state-hover, .k-textbox:focus, .k-textbox.k-state-focused, .k-autocomplete.k-state-focused, .k-dropdown-wrap.k-state-focused, .k-picker-wrap.k-state-focused, .k-numeric-wrap.k-state-focused, .k-state-selected, .k-state-active {
  box-shadow: none;
}

.k-popup, .k-menu .k-menu-group, .k-grid .k-filter-options, .k-time-popup, .k-datepicker-calendar, .k-autocomplete.k-state-border-down, .k-autocomplete.k-state-border-up, .k-dropdown-wrap.k-state-active, .k-picker-wrap.k-state-active, .k-multiselect.k-state-focused, .k-filebrowser .k-image {
  box-shadow: 0 2px 2px #0003;
}

.k-treemap-tile.k-state-hover {
  box-shadow: inset 0 0 0 3px #dbdbdb;
}

.k-shadow {
  box-shadow: 0 1px 2px #0003;
}

.k-inset {
  box-shadow: inset 0 1px 1px #0003;
}

.k-editor-inline ::selection {
  text-shadow: none;
  color: #fff;
  background-color: #7ea700;
}

.k-editor-inline ::selection {
  text-shadow: none;
  color: #fff;
  background-color: #7ea700;
}

.k-window-content.editorToolbarWindow {
  color: #4c5356;
  background-color: #f4f4f4;
}

.k-notification {
  border-radius: 0;
}

.k-notification-info {
  color: #fff;
  background-color: #0c779b;
  border-color: #0c779b;
}

.k-notification-success {
  color: #fff;
  background-color: #2b893c;
  border-color: #2b893c;
}

.k-notification-warning {
  color: #000;
  background-color: #ffb137;
  border-color: #ffb137;
}

.k-notification-error {
  color: #fff;
  background-color: #e20000;
  border-color: #e20000;
}

.k-gantt .k-treelist {
  background: #f5f5f5;
}

.k-gantt .k-treelist .k-alt {
  background-color: #dbdbdb;
}

.k-gantt .k-treelist tr:hover {
  background-color: #8ebc00;
}

.k-gantt .k-treelist .k-state-selected, .k-gantt .k-treelist .k-state-selected td, .k-gantt .k-treelist .k-alt.k-state-selected, .k-gantt .k-treelist .k-alt.k-state-selected > td {
  background-color: #7ea700;
}

.k-gantt .k-treelist .k-state-selected:hover, .k-gantt .k-treelist .k-state-selected:hover td {
  background-color: #8ebc00;
}

.k-task-dot:after {
  background-color: #4c5356;
  border-color: #4c5356;
}

.k-task-dot:hover:after {
  background-color: #fff;
}

.k-task-summary {
  background: #101212;
  border-color: #101212;
}

.k-task-milestone, .k-task-summary-complete {
  background: #4c5356;
  border-color: #4c5356;
}

.k-state-selected.k-task-summary {
  background: #1e2800;
  border-color: #1e2800;
}

.k-state-selected.k-task-milestone, .k-state-selected .k-task-summary-complete {
  background: #7ea700;
  border-color: #7ea700;
}

.k-task-single {
  color: #fff;
  background-color: #99cb00;
  border-color: #7ea700;
}

.k-state-selected.k-task-single {
  border-color: #7ea700;
}

.k-gantt-line {
  color: #4c5356;
  background-color: #4c5356;
}

.k-state-selected.k-gantt-line {
  color: #7ea700;
  background-color: #7ea700;
}

.k-resource {
  background-color: #f4f4f4;
}

.k-block, .k-textbox, .k-drag-clue, .k-touch-scrollbar, .k-inline-block, .k-grid .k-filter-options, .k-grouping-header .k-group-indicator, .k-autocomplete, .k-multiselect, .k-combobox, .k-dropdown, .k-dropdown-wrap, .k-datepicker, .k-timepicker, .k-colorpicker, .k-datetimepicker, .k-numerictextbox, .k-picker-wrap, .k-numeric-wrap, .k-list-container, .k-calendar-container, .k-treeview .k-in, .k-editor-inline, .k-slider-track, .k-slider-selection, .k-upload, .k-calendar-container.k-state-border-up, .k-list-container.k-state-border-up, .k-autocomplete.k-state-border-up, .k-multiselect.k-state-border-up, .k-dropdown-wrap.k-state-border-up, .k-picker-wrap.k-state-border-up, .k-numeric-wrap.k-state-border-up, .k-filter-menu, .k-autocomplete.k-state-border-up .k-input, .k-dropdown-wrap.k-state-border-up .k-input, .k-picker-wrap.k-state-border-up .k-input, .k-picker-wrap.k-state-border-up .k-selected-color, .k-numeric-wrap.k-state-border-up .k-input, .k-multiselect.k-state-border-up .k-multiselect-wrap, .k-block > .k-header, .k-tabstrip-items .k-item, .k-panelbar .k-tabstrip-items .k-item, .k-tabstrip-items .k-link, .k-calendar-container.k-state-border-down, .k-list-container.k-state-border-down, .k-autocomplete.k-state-border-down, .k-multiselect.k-state-border-down, .k-dropdown-wrap.k-state-border-down, .k-picker-wrap.k-state-border-down, .k-numeric-wrap.k-state-border-down, .k-dropdown-wrap .k-input, .k-picker-wrap .k-input, .k-numeric-wrap .k-input, .k-rtl .k-dropdown-wrap .k-input, .k-rtl .k-picker-wrap .k-input, .k-rtl .k-numeric-wrap .k-input, .k-numeric-wrap .k-link, .k-numeric-wrap .k-link + .k-link, .k-colorpicker .k-selected-color, .k-rtl .k-colorpicker .k-selected-color, .k-autocomplete.k-state-border-down .k-input, .k-dropdown-wrap.k-state-border-down .k-input, .k-picker-wrap.k-state-border-down .k-input, .k-picker-wrap.k-state-border-down .k-selected-color, .k-numeric-wrap.k-state-border-down .k-input {
  border-radius: 0;
}

.k-numeric-wrap .k-link.k-state-selected {
  background-color: #7ea700;
}

.k-multiselect.k-state-border-down .k-multiselect-wrap, .k-dropdown-wrap .k-select, .k-picker-wrap .k-select, .k-numeric-wrap .k-select, .k-datetimepicker .k-select + .k-select, .k-list-container.k-state-border-right, .k-rtl .k-dropdown-wrap .k-select, .k-rtl .k-picker-wrap .k-select, .k-rtl .k-numeric-wrap .k-select, .k-rtl .k-datetimepicker .k-select + .k-select, .k-rtl .k-list-container.k-state-border-right, .k-numeric-wrap.k-expand-padding .k-input, .k-textbox > input, .k-autocomplete .k-input, .k-multiselect-wrap, .k-list .k-state-hover, .k-list .k-state-focused, .k-list .k-state-highlight, .k-list .k-state-selected, .k-fieldselector .k-list .k-item, .k-list-optionlabel, .k-dropzone, .k-listbox .k-item {
  border-radius: 0;
}

.k-slider .k-button, .k-grid .k-slider .k-button {
  border-radius: 13px;
}

.k-draghandle, .k-more-events, .k-event, .k-task-single, .k-task-complete, .k-event .k-link {
  border-radius: 0;
}

.k-scheduler-mobile .k-event {
  border-radius: -1px;
}

.k-autocomplete, .k-picker-wrap.k-state-default, .k-numeric-wrap.k-state-default, .k-dropdown-wrap.k-state-default, .k-colorpicker .k-picker-wrap {
  background-color: #fff;
  background-position: 50%;
  border-color: #dbdbdb;
}

.k-autocomplete.k-state-hover, .k-picker-wrap.k-state-hover, .k-numeric-wrap.k-state-hover, .k-dropdown-wrap.k-state-hover {
  background-color: #8ebc00;
  background-image: none, linear-gradient(#8ebc00 0% 100%);
  background-position: 50%;
  border-color: #dbdbdb;
}

.k-multiselect-wrap {
  border-color: #dbdbdb;
}

.k-multiselect-wrap.k-state-hover, .k-state-hover > .k-multiselect-wrap, .k-multiselect-wrap.k-state-hover {
  color: #787878;
  background: #fff;
  border-color: #dbdbdb;
}

.k-autocomplete.k-state-focused, .k-picker-wrap.k-state-focused, .k-numeric-wrap.k-state-focused, .k-dropdown-wrap.k-state-focused {
  box-shadow: none;
  background-color: #8ebc00;
  background-image: none, linear-gradient(#8ebc00 0% 100%);
  background-position: 50%;
  border-color: #dbdbdb;
}

.k-multiselect-wrap.k-state-focused, .k-state-focused > .k-multiselect-wrap {
  box-shadow: none;
  border-color: #dbdbdb;
}

.k-list-container {
  color: #787878;
}

.k-nodata {
  color: #ccc;
}

.k-dropdown .k-input, .k-menu .k-popup {
  color: #787878;
}

.k-state-default > .k-select, .k-state-hover > .k-select, .k-state-focused > .k-select {
  border-color: #dbdbdb;
}

.k-tabstrip:focus {
  box-shadow: none;
}

.k-tabstrip-items .k-state-default .k-link, .k-panelbar > li.k-state-default > .k-link {
  color: #787878;
}

.k-tabstrip-items .k-state-hover .k-link, .k-panelbar > li > .k-link.k-state-hover, .k-panelbar > li.k-state-hover > .k-link, .k-panelbar > li.k-state-default > .k-link.k-state-hover {
  color: #fff;
}

.k-panelbar .k-state-focused.k-state-hover {
  box-shadow: none;
  background: #8ebc00;
}

.k-tabstrip-items .k-state-default {
  border-color: #dbdbdb;
}

.k-tabstrip-items .k-state-hover {
  border-color: #8ebc00;
}

.k-tabstrip-items .k-state-active, .k-panelbar .k-tabstrip-items .k-state-active {
  background-color: #fff;
  background-image: none;
  border-color: #7ea700;
}

.k-tabstrip-top > .k-tabstrip-items .k-state-active, .k-panelbar .k-tabstrip-top > .k-tabstrip-items .k-state-active {
  border-bottom-color: #fff;
}

.k-tabstrip .k-content.k-state-active {
  color: #4c5356;
  background-color: #fff;
}

.k-tabstrip .k-content:focus, .k-tabstrip .k-content.k-state-focused {
  outline-color: #656565;
}

.k-menu, .k-menu .k-item, .k-column-menu, .k-column-menu .k-item, .k-grid-columnmenu-popup, .k-columnmenu-item-wrapper {
  border-color: #dbdbdb;
}

.k-column-menu .k-separator {
  background-color: #0000;
  border-color: #dbdbdb;
}

.k-column-menu-group-header-text {
  color: #787878;
  border-color: #787878;
}

.k-menu .k-group {
  border-color: #dbdbdb;
}

.k-grid-filter.k-state-active {
  background-color: #f0f0f0;
}

.k-grouping-row td, .k-group-footer td, .k-grid-footer td {
  color: #787878;
  border-color: #dbdbdb;
  font-weight: bold;
}

.k-grouping-header {
  color: #787878;
}

.k-grid tr:hover, .k-grid td.k-state-selected:hover {
  color: #fff;
  background-color: #8ebc00;
}

.k-grid .k-filter-row:hover, .k-pivot-rowheaders .k-grid tr:hover {
  color: #4c5356;
  background: none;
}

.k-grid td.k-state-focused {
  box-shadow: inset 0 0 0 1px #779d00;
}

.k-grid tr:hover .k-state-focused, .k-grid tr .k-state-focused.k-state-selected, .k-grid tr.k-state-selected td.k-state-focused, .k-grid tr:hover .k-state-focused.k-state-selected, .k-grid tr.k-state-selected:hover td.k-state-focused {
  box-shadow: inset 0 0 0 1px #2e3d00;
}

.k-header, .k-grid-header-wrap, .k-grid .k-grouping-header, .k-grid-header, .k-grouping-header .k-group-indicator, .k-gantt-toolbar .k-state-default {
  border-color: #dbdbdb;
}

.k-treeview .k-in {
  border-color: #0000;
}

.k-treeview .k-icon, .k-scheduler-table .k-icon, .k-grid .k-hierarchy-cell .k-icon {
  background-color: #0000;
  border-radius: 0;
}

.k-scheduler-table .k-state-hover .k-icon {
  background-color: #0000;
}

.k-chart .k-mask {
  opacity: .7;
  background-color: #fff;
}

.k-chart .k-selection {
  border-color: #c7c7c7;
  transition: box-shadow .2s linear, border-color .2s linear;
  box-shadow: inset 0 1px 12px #0000000d;
}

.k-chart .k-selection:hover {
  border-color: #8ebc00;
  box-shadow: inset 0 0 20px #8ebc0080;
}

.k-chart .k-handle {
  background-color: #c7c7c7;
  width: 1px;
}

.k-chart .k-handle:hover {
  background-color: #8ebc00;
  border-radius: 0;
  width: 3px;
}

.k-chart .k-navigator-hint .k-tooltip {
  color: #fff;
  background: #8ebc00;
  border: 0;
  border-radius: 0;
  box-shadow: 0 1px 3px #0000004d;
}

.k-chart .k-navigator-hint .k-scroll {
  background: #8ebc0080;
  border-radius: 0;
  height: 4px;
}

.k-chart-crosshair-tooltip, .k-chart-shared-tooltip {
  border-radius: 0;
}

.k-map {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-map .k-navigator {
  color: #000;
  background-color: #fff;
  background-position: 50%;
  border-color: #dbdbdb;
  box-shadow: 0 1px 2px #0003;
}

.k-map .k-zoom-control {
  box-shadow: 0 1px 2px #0003;
}

.k-map .k-marker {
  color: #7ea700;
}

.k-spreadsheet-row-header, .k-spreadsheet-column-header {
  background-color: #fff;
}

.k-spreadsheet-top-corner, .k-spreadsheet-row-header, .k-spreadsheet-column-header {
  color: #000;
  background-color: #fff;
  background-image: none;
  border-color: #ccc;
}

.k-spreadsheet-top-corner {
  border-color: #ccc;
}

.k-spreadsheet-top-corner:after {
  border-color: #0000 #ccc #ccc #0000;
}

.k-spreadsheet-pane {
  border-color: #ccc;
}

.k-spreadsheet-pane .k-spreadsheet-vaxis, .k-spreadsheet-pane .k-spreadsheet-haxis {
  border-color: #e6e6e6;
}

.k-spreadsheet-pane .k-spreadsheet-column-header, .k-spreadsheet-pane .k-spreadsheet-row-header {
  border-color: #ccc;
}

.k-spreadsheet-pane .k-spreadsheet-merged-cell {
  background-color: #fff;
}

.k-spreadsheet-pane .k-selection-partial, .k-spreadsheet-pane .k-selection-full {
  background-color: #7ea70033;
  border-color: #7ea70033;
}

.k-spreadsheet-pane .k-filter-range {
  border-color: #7ea700;
}

.k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-partial, .k-spreadsheet-pane .k-spreadsheet-column-header .k-selection-full {
  border-bottom-color: #7ea700;
}

.k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-partial, .k-spreadsheet-pane .k-spreadsheet-row-header .k-selection-full {
  border-right-color: #7ea700;
}

.k-auto-fill, .k-spreadsheet-selection {
  border-color: #7ea700;
  box-shadow: inset 0 0 0 1px #7ea700;
}

.k-spreadsheet-selection {
  background-color: #7ea70033;
}

.k-spreadsheet-active-cell {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #7ea700;
}

.k-spreadsheet-active-cell.k-right {
  box-shadow: inset 0 0 0 1px #7ea700, inset -1px 0 0 1px #7ea700;
}

.k-spreadsheet-active-cell.k-bottom {
  box-shadow: inset 0 0 0 1px #7ea700, inset 0 -1px 0 1px #7ea700;
}

.k-spreadsheet-active-cell.k-bottom.k-right {
  box-shadow: inset 0 0 0 1px #7ea700, inset -1px -1px 0 1px #7ea700;
}

.k-spreadsheet-active-cell.k-single {
  color: #787878;
  background-color: #fff;
}

.k-spreadsheet .k-spreadsheet-action-bar {
  background-color: #fff;
  border-color: #dbdbdb;
}

.k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-name-editor, .k-spreadsheet .k-spreadsheet-action-bar .k-spreadsheet-formula-bar > .k-i-formula-fx {
  border-color: #ccc;
}

.k-spreadsheet .k-spreadsheet-formula-input {
  color: #787878;
  background-color: #fff;
}

.k-spreadsheet .k-resize-handle, .k-spreadsheet .k-resize-hint-handle, .k-spreadsheet .k-resize-hint-marker, .k-spreadsheet .k-resize-hint-vertical .k-resize-hint-handle, .k-spreadsheet .k-resize-hint-vertical .k-resize-hint-marker {
  background-color: #7ea700;
}

.k-spreadsheet .k-single-selection:after {
  background-color: #7ea700;
  border-color: #fff;
}

.k-spreadsheet .k-auto-fill-punch {
  background-color: #ffffff80;
}

.k-spreadsheet .k-single-selection.k-dim-auto-fill-handle:after {
  background-color: #7ea70080;
}

.k-spreadsheet .k-spreadsheet-cell-comment {
  color: #fff;
  background-color: #92c100;
  border-color: #92c100;
}

.k-spreadsheet .k-spreadsheet-has-comment:after {
  border-color: #7ea700 #7ea700 #0000 #0000;
}

.k-spreadsheet .k-dirty {
  border-color: #7c0000 #0000 #0000 #7c0000;
}

.k-spreadsheet-format-cells .k-spreadsheet-preview {
  border-color: #dbdbdb;
}

.k-spreadsheet-filter {
  background-color: #fff;
  border-radius: 0;
  box-shadow: inset 0 0 0 1px #e6e6e6;
}

.k-spreadsheet-filter.k-state-active {
  color: #fff;
  background-color: #7ea700;
}

.k-spreadsheet-filter:hover {
  color: #fff;
  background: #8ebc00;
  border-color: #6f9300;
}

.k-action-window .k-action-buttons {
  background: #f4f4f4;
  border-color: #dbdbdb;
}

.k-spreadsheet-sample {
  color: #c4c4c4;
}

.k-state-selected .k-spreadsheet-sample {
  color: inherit;
}

.k-spreadsheet-window .k-list {
  border-color: #dbdbdb;
  border-radius: 0;
}

.k-spreadsheet-popup {
  border-radius: 0;
}

.k-spreadsheet-popup .k-separator {
  background-color: #dbdbdb;
}

.k-spreadsheet-popup .k-button {
  background-color: #0000;
}

.k-spreadsheet-popup .k-button:hover {
  background-color: #8ebc00;
}

.k-spreadsheet-popup .k-state-active {
  color: #000;
  background-color: #7ea700;
}

.k-spreadsheet-popup .k-state-active:hover {
  background-color: #587400;
}

.k-spreadsheet-filter-menu .k-details {
  border-color: #dbdbdb;
}

.k-spreadsheet-filter-menu .k-details-content .k-space-right {
  background-color: #fff;
}

.k-spreadsheet-filter-menu .k-spreadsheet-value-treeview-wrapper {
  background-color: #fff;
  border-color: #dbdbdb;
  border-radius: 0;
}

.k-syntax-ref {
  color: #f82;
}

.k-syntax-num {
  color: #09f;
}

.k-syntax-func {
  font-weight: bold;
}

.k-syntax-str {
  color: #38b714;
}

.k-syntax-error {
  color: red;
}

.k-syntax-bool {
  color: #a9169c;
}

.k-syntax-startexp {
  font-weight: bold;
}

.k-syntax-paren-match {
  background-color: #caf200;
}

.k-series-a {
  background-color: #8ebc0026;
  border-color: #8ebc00;
}

.k-series-b {
  background-color: #309b4626;
  border-color: #309b46;
}

.k-series-c {
  background-color: #25a0da26;
  border-color: #25a0da;
}

.k-series-d {
  background-color: #ff690026;
  border-color: #ff6900;
}

.k-series-e {
  background-color: #e61e2626;
  border-color: #e61e26;
}

.k-series-f {
  background-color: #d8e40426;
  border-color: #d8e404;
}

.k-spreadsheet-sheets-remove:hover .k-icon {
  color: #c22;
}

.k-spreadsheet-formula-list .k-state-focused {
  color: #fff;
  background-color: #7ea700;
}

.k-spreadsheet-insert-image-dialog {
  border-color: #dbdbdb;
  border-radius: 0;
}

.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image:hover, .k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image.k-state-hovered {
  border-radius: 0;
  box-shadow: inset 0 0 0 2000px #00000080;
}

.k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image:hover div, .k-spreadsheet-insert-image-dialog .k-spreadsheet-has-image.k-state-hovered div {
  color: #fff;
}

.k-spreadsheet-drawing.k-spreadsheet-active-drawing {
  outline-color: #7ea700;
}

.k-spreadsheet-drawing .k-spreadsheet-drawing-handle {
  background-color: #7ea700;
  border-color: #fff;
  border-radius: 50%;
}

.k-spreadsheet-drawing-anchor-cell {
  background: #7ea70033;
}

.k-rtl .k-spreadsheet .k-spreadsheet-has-comment:after {
  border-color: #7ea700 #0000 #0000 #7ea700;
}

.k-rtl .k-spreadsheet .k-dirty {
  border-color: #7c0000 #7c0000 #0000 #0000;
}

.k-numeric-wrap .k-i-warning {
  color: #7c0000;
  width: 1.9em;
  position: absolute;
  top: 0;
  right: 1.9em;
}

.k-numeric-wrap.k-state-invalid {
  border-color: #7c0000;
}

.k-numeric-wrap.k-state-invalid input {
  color: #7c0000;
}

.k-rtl .k-numeric-wrap.k-state-invalid .k-i-warning {
  left: 1.9em;
  right: auto;
}

.k-maskedtextbox.k-state-invalid .k-textbox {
  color: #7c0000;
  border-color: #7c0000;
}

.k-maskedtextbox.k-state-invalid .k-i-warning {
  color: #7c0000;
}

.k-dateinput.k-state-invalid .k-textbox {
  color: #7c0000;
  border-color: #7c0000;
}

.k-dateinput.k-state-invalid .k-i-warning {
  color: #7c0000;
  margin-left: 0;
  margin-right: .6em;
}

.k-rtl .k-dateinput .k-i-warning {
  margin-left: .6em;
  margin-right: 0;
}

.k-datepicker .k-picker-wrap.k-state-invalid, .k-timepicker .k-picker-wrap.k-state-invalid {
  border-color: #7c0000;
}

.k-datepicker .k-picker-wrap.k-state-invalid .k-input, .k-timepicker .k-picker-wrap.k-state-invalid .k-input {
  color: #7c0000;
}

.k-datepicker .k-picker-wrap .k-i-warning, .k-timepicker .k-picker-wrap .k-i-warning {
  color: #7c0000;
  margin-left: 0;
  margin-right: 2.1em;
}

.k-rtl .k-datepicker .k-picker-wrap .k-i-warning, .k-rtl .k-timepicker .k-picker-wrap .k-i-warning {
  margin-left: 2.1em;
  margin-right: 0;
}

.k-datetimepicker .k-picker-wrap.k-state-invalid {
  border-color: #7c0000;
}

.k-datetimepicker .k-picker-wrap.k-state-invalid .k-input {
  color: #7c0000;
}

.k-datetimepicker .k-picker-wrap .k-i-warning {
  color: #7c0000;
  margin-left: 0;
  margin-right: 4.3em;
}

.k-rtl .k-datetimepicker .k-picker-wrap .k-icon.k-i-warning {
  margin-left: 4.3em;
  margin-right: 0;
}

.k-time-header .k-time-now {
  color: #7ea700;
  background: none;
}

.k-time-header .k-time-now:hover, .k-time-header .k-time-now:focus {
  color: #6f9300;
}

.k-time-list-wrapper {
  background-color: #fff;
}

.k-time-list-wrapper .k-title {
  color: #ccc;
  background: #fff;
  border-color: #dbdbdb;
}

.k-time-list-wrapper.k-state-focused .k-title {
  color: #000;
  opacity: 1;
}

.k-time-list-wrapper.k-state-focused:before, .k-time-list-wrapper.k-state-focused:after {
  background-color: #0000000a;
}

.k-time-list:before, .k-time-list:after {
  box-shadow: 0 0 3em 1.5em #fff;
}

.k-time-list .k-item:hover {
  color: #7ea700;
}

.k-time-container {
  background: none;
}

.k-time-highlight {
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-datetime-container .k-date-tab .k-datetime-buttongroup, .k-datetime-container .k-date-tab .k-datetime-selector {
  background-color: #f4f4f4;
}

.k-listbox .k-list-scroller {
  background-color: #fff;
  border-color: #dbdbdb;
  padding: 2px;
}

.k-listbox .k-item:hover:not(.k-state-disabled) {
  color: #fff;
  background-color: #8ebc00;
  border-color: #0000;
}

.k-listbox .k-drop-hint {
  border-top: 1px solid #7ea700;
  height: 0;
}

.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order {
  color: #7ea700;
}

.k-dropdowngrid-popup, .k-dropdowngrid-popup .k-header, .k-dropdowngrid-popup .k-group-header {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-dropdowngrid-popup .k-cell {
  border-color: #dbdbdb;
}

.k-dropdowngrid-popup .k-item:nth-child(2n) {
  background-color: #f5f5f5;
}

.k-dropdowngrid-popup .k-footer {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #dbdbdb;
}

.k-dropdowngrid-popup .k-item.k-state-hover {
  color: #fff;
  background-color: #8ebc00;
  border-color: #dbdbdb;
}

.k-dropdowngrid-popup .k-item.k-state-selected {
  color: #fff;
  background-color: #7ea700;
  border-color: #dbdbdb;
}

.k-dropdowngrid-popup .k-group-cell span {
  color: #f4f4f4;
  background-color: #4c5356;
}

.k-grid-list > .k-item.k-last > .k-cell, .k-grid-list > .k-item.k-last > .k-group-cell, .k-grid-list > .k-item.k-last > .k-spacer-cell {
  border-bottom-color: #4c5356;
}

.k-calendar .k-header .k-link, .k-calendar .k-content td, .k-calendar .k-content .k-link {
  border-radius: 0;
}

.k-master-row .k-grid-content-sticky {
  background-color: #f4f4f4;
}

.k-master-row.k-alt .k-grid-content-sticky {
  background-color: #f5f5f5;
}

.k-master-row.k-state-selected .k-grid-content-sticky {
  background-color: #7ea700;
  background-position: 50%;
}

.k-master-row:hover .k-grid-content-sticky, .k-master-row.k-state-hover .k-grid-content-sticky {
  background-color: #8ebc00;
}

.k-master-row.k-state-selected:hover .k-grid-content-sticky, .k-master-row.k-state-selected.k-state-hover .k-grid-content-sticky {
  background-color: null;
  background-image: none;
}

.k-grid-header .k-grid-header-sticky, .k-master-row .k-grid-content-sticky, .k-grouping-row .k-grid-content-sticky, .k-footer-template .k-grid-footer-sticky {
  border-left-color: #8f8f8f;
  border-right-color: #8f8f8f;
}

.k-grid-header .k-filter-row .k-grid-header-sticky {
  background-color: #f4f4f4;
}

.k-grid-header-locked, .k-grid-content-locked, .k-grid-header-locked .k-header, .k-grid-content-locked td {
  border-left-color: #8f8f8f;
  border-right-color: #8f8f8f;
}

.k-check-all-wrap {
  border-color: #dbdbdb;
}

@-ms-viewport {
  width: device-width;
  user-zoom: fixed;
  max-zoom: 1;
  min-zoom: 1;
}

@media (orientation: landscape) {
  .km-tablet .km-on-ios.km-horizontal.km-web:not(.km-ios-chrome) {
    position: fixed;
    bottom: 0;
  }
}

.km-root {
  font-size: .92em;
}

.km-root.km-retina input, .km-root.km-retina select, .km-root.km-retina textarea {
  font-size: 1em;
}

.km-root a:not(.k-button) {
  color: inherit;
}

.km-tablet {
  font-size: 1em;
}

.km-root :focus {
  outline-width: 0;
}

.km-root, .km-pane, .km-pane-wrapper {
  -ms-touch-action: none;
  -ms-content-zooming: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.km-pane-wrapper {
  position: absolute;
}

.km-pane, .km-shim {
  font-family: sans-serif;
}

.km-pane {
  position: relative;
  overflow-x: hidden;
}

.km-vertical .km-collapsible-pane {
  transition: transform .35s ease-out;
  position: absolute;
  transform: translateX(-100%);
  z-index: 2 !important;
}

.km-vertical .km-expanded-splitview .km-collapsible-pane {
  transform: translateX(0);
}

.km-expanded-pane-shim {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.km-expanded-splitview .km-expanded-pane-shim {
  z-index: 1;
  display: block;
}

.km-root > * {
  margin: 0;
  padding: 0;
}

.km-root * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
}

.km-content {
  display: block;
}

.km-view, .km-split-content {
  vertical-align: top;
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.k-ff .km-view, .k-ff .km-pane {
  overflow: hidden;
}

.k-ff18 .km-view, .k-ff18 .km-pane, .k-ff19 .km-view, .k-ff19 .km-pane, .k-ff20 .km-view, .k-ff20 .km-pane, .k-ff21 .km-view, .k-ff21 .km-pane {
  position: relative;
}

.k-ff .km-view {
  display: inline-flex;
}

.km-content {
  flex-align: stretch;
  flex: 1;
  width: auto;
  min-height: 1px;
  position: relative;
  overflow: hidden;
}

.km-content p, .km-content h1, .km-content h2, .km-content h3, .km-content h4, .km-content h5, .km-content h6 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.km-header, .km-footer {
  flex-direction: column;
  width: 100%;
  display: -moz-box;
}

.km-header {
  padding: 0;
}

.km-footer {
  background: #1a1a1a;
}

[data-role="layout"] {
  display: none;
}

[data-role="view"], .km-drawer, kendo-mobile-view, kendo-mobile-split-view, kendo-mobile-drawer {
  visibility: hidden;
}

.km-view, .k-view {
  visibility: visible;
}

.km-header, .km-footer {
  z-index: 1;
  position: relative;
}

@media (-webkit-min-device-pixel-ratio: 10000), not all and (-webkit-min-device-pixel-ratio: 0) {
  .km-view {
    display: table;
  }

  .km-header, .km-footer, .km-content {
    display: table-row;
  }

  .km-header, .km-footer {
    height: 1px;
  }
}

.km-root .k-toolbar, .km-navbar, .km-button, .km-buttongroup, .km-tabstrip, .km-blackberry li.km-actionsheet-cancel > a {
  -webkit-appearance: none;
  appearance: none;
  background-origin: border-box;
  margin: .1rem;
  padding: .4em .7em;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.km-tabstrip, .km-root .k-toolbar, .km-navbar {
  box-sizing: border-box;
  border-width: 0;
  width: 100%;
  margin: 0;
  padding: .8em;
  display: block;
}

.km-native-scroller {
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: pan-x pan-y;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -ms-scroll-snap-type: proximity;
  overflow: auto;
}

.km-default-content {
  padding: 1em;
}

.km-shim {
  z-index: 10001;
  box-sizing: border-box;
  background: #0009;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100% !important;
}

.km-root .km-shim {
  position: absolute;
}

.km-shim:before {
  content: " ";
  vertical-align: middle;
  width: 0;
  height: 100%;
  display: inline-block;
}

.km-shim .k-animation-container {
  box-shadow: none;
  border: 0;
  width: auto;
}

.km-loader {
  z-index: 100000;
  box-sizing: border-box;
  background-color: #00000080;
  width: 180px;
  height: 130px;
  margin-top: -70px;
  margin-left: -90px;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.km-loader h1 {
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-size: 1rem;
}

.km-loader .km-loading, .km-load-more .km-icon, .km-scroller-refresh .km-icon {
  width: 35px;
  height: 35px;
  margin: 0 auto;
  font-size: 35px;
  animation: 1s linear infinite km-spin;
  display: block;
}

.km-loader .km-loading:after, .km-load-more .km-icon:after {
  color: #ccc;
}

.km-loading-left, .km-loading-right {
  display: none;
}

@-webkit-keyframes km-spin {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes km-spin {
  from {
    -moz-transform: rotate(0);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}



@-o-keyframes km-spin {
  from {
    -o-transform: rotate(0);
  }

  to {
    -o-transform: rotate(360deg);
  }
}

@-webkit-keyframes km-ios-spin {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes km-ios-spin1 {
  from {
    -webkit-transform: rotate(-135deg);
  }

  to {
    -webkit-transform: rotate(225deg);
  }
}

@-moz-keyframes km-ios-spin {
  from {
    -moz-transform: rotate(0);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes km-ios-spin1 {
  from {
    -moz-transform: rotate(-135deg);
  }

  to {
    -moz-transform: rotate(225deg);
  }
}

@keyframes km-ios-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes km-ios-spin1 {
  from {
    transform: rotate(-135deg);
  }

  to {
    transform: rotate(225deg);
  }
}

.km-stretched-view {
  display: flex;
}

.km-stretched-view > * {
  width: 100%;
}

.km-stretched-view > .km-pane-wrapper, .km-stretched-view > .km-pane-wrapper > .km-pane {
  position: static;
}

.km-overlay {
  z-index: 100000;
  background: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.km-root.km-native-scrolling, .km-root.km-native-scrolling .km-view, .km-root.km-native-scrolling .km-splitview .km-pane {
  height: auto;
  min-height: 100%;
  overflow-x: visible;
  -webkit-transform: none;
}

.km-native-scrolling, .km-native-scrolling .km-pane, .km-native-scrolling .km-view {
  -ms-touch-action: auto;
}

.km-native-scrolling .km-pane, .km-native-scrolling .km-view {
  display: block;
}

.km-native-scrolling .km-content {
  -ms-flex: auto;
}

.km-native-scrolling .km-blackberry .km-content {
  min-height: auto;
}

.km-native-scrolling .km-splitview {
  position: absolute;
}

.km-native-scrolling .km-header {
  position: fixed;
  top: 0;
}

.km-native-scrolling .km-android .km-header {
  top: auto;
  bottom: 0;
}

.km-native-scrolling .km-footer {
  position: fixed;
  bottom: 0;
}

.km-native-scrolling .km-android .km-footer {
  top: 0;
  bottom: auto;
}

.km-native-scrolling .km-badge {
  z-index: auto;
}

.km-native-scrolling .km-splitview .km-header, .km-native-scrolling .km-splitview .km-footer, .km-native-scrolling .km-popup.km-pane .km-header, .km-native-scrolling .km-popup.km-pane .km-footer {
  position: absolute;
}

.km-native-scrolling .km-modalview .km-header, .km-native-scrolling .km-modalview .km-footer {
  position: relative;
}

.km-native-scrolling .km-content {
  width: 100%;
}

.km-native-scrolling .km-shim, .km-native-scrolling .km-popup-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  height: auto !important;
}

.km-native-scrolling .km-drawer {
  -webkit-overflow-scrolling: touch;
  position: fixed;
  top: 0;
  height: 100% !important;
  overflow: auto !important;
}

.km-native-scrolling > .km-pane > .km-loader {
  position: fixed;
}

.km-native-scrolling .km-header, .km-native-scrolling .km-footer {
  z-index: 2;
}

.km-state-disabled {
  opacity: .5;
}

.km-badge, .km-detail {
  vertical-align: middle;
  text-align: center;
  z-index: 1;
  text-shadow: none;
  height: 2em;
  font-size: .6rem;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  overflow: hidden;
}

.km-badge {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: .9em;
  margin-left: .5em;
  padding: 0 .55em;
  line-height: 2em;
  top: -1em;
  right: -1em;
}

.km-tabstrip .km-badge {
  margin-left: -1em;
  top: -.2em;
  right: auto;
}

.km-detail {
  float: right;
  box-sizing: border-box;
  width: 1.3rem;
  height: 1.3rem;
  margin-top: -.7rem;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  right: .8rem;
}

.km-widget .km-detaildisclose {
  font-size: .8em;
}

.k-ff .km-detail {
  width: 1rem;
  height: 1rem;
}

.km-detail .km-text {
  display: none;
}

.km-widget .km-rowinsert:after, .km-widget .km-rowdelete:after, .km-widget .km-contactadd:after, .km-widget .km-detaildisclose:after, .km-widget .km-rowinsert:before, .km-widget .km-rowdelete:before, .km-widget .km-contactadd:before, .km-widget .km-detaildisclose:before, .km-detail .km-icon {
  font-size: 1em;
  line-height: 1em;
  position: absolute;
  top: .15em;
  left: .15em;
}

.km-widget .km-detaildisclose:after {
  text-align: center;
  font-weight: bold;
  top: .25em;
  left: .1em;
}

.km-button {
  cursor: pointer;
  text-align: center;
  outline: 0;
}

button.km-button {
  font: inherit;
  display: inline-block;
}

.km-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.km-ios .km-state-disabled .km-button, .km-android .km-state-disabled .km-button, .km-blackberry .km-state-disabled .km-button, .km-meego .km-state-disabled .km-button {
  color: #aaa;
  text-shadow: none;
}

.km-root .km-pane .k-button:focus, .km-root .km-pane .k-button:active, .km-root .km-pane .k-button:focus:active {
  box-shadow: none;
}

.km-buttongroup {
  -webkit-margin-collapse: separate;
  margin-collapse: separate;
  margin: .5em auto;
  padding: .4rem .7rem;
}

.km-widget.km-buttongroup {
  white-space: nowrap;
  background: none;
  border-color: #0000;
  padding: 0;
  display: table;
}

.km-buttongroup > .km-button {
  display: table-cell;
}

.km-buttongroup .km-badge {
  z-index: 1;
}

.km-widget.km-buttongroup .km-button {
  border-width: 1px 0 1px 1px;
  margin: 0;
  padding: .48em .9em .44em;
}

.km-tablet .km-buttongroup .km-button {
  padding: .4em .8em .34em;
}

.km-widget.km-navbar .km-buttongroup {
  height: 1.5em;
  margin: 0 0 .2em;
  font-size: .95rem;
  line-height: 1em;
  display: inline-block;
  top: -2px;
}

.k-toolbar .km-buttongroup {
  margin: 0;
  display: inline-block;
}

.km-tablet .km-navbar .km-buttongroup {
  top: -1px;
}

.km-widget.km-navbar .km-buttongroup > .km-button {
  text-align: center;
  min-width: 4rem;
  font-size: 1em;
}

.km-tablet .km-navbar .km-buttongroup > .km-button {
  min-width: 6rem;
}

.km-view .km-buttongroup .km-button:last-child {
  border-right-width: 1px;
}

.km-ios .km-buttongroup .km-button {
  font-size: 1.2em;
  font-weight: bold;
}

.km-hide-title {
  display: none;
}

.km-show-title:after {
  content: " ";
  height: 0;
  display: block;
}

.km-fill-title:after {
  height: auto;
}

.km-footer .km-show-title:after {
  display: inline-block;
}

.km-view-title, .km-dialog-title {
  visibility: visible;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.4em;
  line-height: 2.3em;
  position: relative;
}

.km-view-title:before, .km-dialog-title:before {
  content: "";
  width: 0;
  display: inline-block;
}

.km-horizontal .km-view-title {
  line-height: 2em;
}

.km-root .k-toolbar, .km-navbar {
  background-color: #fff;
  border-width: 0 0 1px;
  flex: 1;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.k-ff.km-root .k-toolbar, .k-ff .km-navbar {
  overflow: visible;
}

.km-navbar .km-button {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.km-navbar .km-no-title {
  padding-top: .7rem;
  padding-bottom: .7rem;
}

.km-horizontal .km-navbar .km-button {
  margin-top: .3rem;
  margin-bottom: .3rem;
}

.km-horizontal .km-navbar .km-no-title {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.km-tablet.km-root .km-no-title {
  padding-top: .55rem;
  padding-bottom: .55rem;
}

.km-tablet .km-navbar .km-button {
  margin-top: .45rem;
  margin-bottom: .45rem;
}

.km-root .km-pane .km-navbar .km-no-title {
  visibility: visible;
  line-height: 0;
}

.km-on-ios.km-black-translucent-status-bar.km-app .km-header .km-navbar {
  background-clip: border-box;
  padding-top: 1.4em;
}

.km-on-ios.km-ios5.km-cordova .km-header .km-navbar, .km-on-ios.km-ios6.km-cordova .km-header .km-navbar {
  padding-top: 0;
}

.km-leftitem, .km-rightitem {
  z-index: 1;
  position: absolute;
  right: .5em;
}

.km-popup .km-rightitem {
  right: 0;
}

.km-leftitem {
  left: .5em;
  right: auto;
}

.km-popup .km-leftitem {
  left: 0;
}

.km-leftitem, .km-rightitem {
  height: 100%;
}

.km-on-ios.km-black-translucent-status-bar.km-app .km-leftitem, .km-on-ios.km-black-translucent-status-bar.km-app .km-rightitem {
  height: auto;
}

.km-leftitem > *, .km-rightitem > * {
  vertical-align: middle;
  display: inline-block;
}

.km-leftitem:before, .km-rightitem:before {
  content: " ";
  vertical-align: middle;
  width: 0;
  height: 100%;
  display: inline-block;
}

.km-tabstrip {
  padding: .4rem .7rem;
}

.km-horizontal .km-tabstrip {
  padding: .2rem .7rem;
}

.km-tabstrip {
  flex-align: start;
  text-align: center;
  word-spacing: -1em;
  flex-direction: row;
  -moz-box-pack: start;
  padding: 0;
}

.km-tabstrip .km-button {
  word-spacing: normal;
  box-shadow: none;
  vertical-align: bottom;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .km-tabstrip {
    word-spacing: normal;
    width: 100%;
  }
}

.km-tabstrip .km-button {
  color: #a8a8a8;
  text-align: center;
  background: none;
  border-width: 0;
  border-color: #0000;
  margin: 0;
  padding: .4em .8em;
  font-family: Arial, Helvetica, sans-serif;
}

.km-tabstrip .km-button:first-child {
  border-left: 0;
}

.km-tabstrip .km-button:last-child {
  border-right: 0;
}

.km-switch input[type="checkbox"] {
  display: none;
}

.km-switch, .km-checkbox {
  text-align: left;
  width: 6.4rem;
  height: 2rem;
  font-size: 1rem;
  line-height: 2rem;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.km-switch-wrapper, .km-slider-wrapper {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.km-switch-background, .km-slider-background {
  width: 200%;
  height: 100%;
  margin: 0 1px 1px -5em;
  display: block;
}

.km-switch-container {
  box-sizing: border-box;
  background: none;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.km-checkbox {
  width: 1.8rem;
  height: 1.8rem;
}

.km-checkbox-checked:after {
  content: " ";
  width: 100%;
  height: 100%;
  display: block;
}

.km-switch-handle {
  background-color: #000;
  width: 2.72em;
  height: 100%;
  margin: -1px 0 0 -1px;
  display: inline-block;
  top: 0;
  left: 0;
}

.km-switch-label-on, .km-switch-label-off {
  text-align: center;
  text-transform: uppercase;
  width: 130%;
  font-size: 1em;
  line-height: 2em;
  display: block;
  position: absolute;
}

.km-switch-label-on {
  text-shadow: 0 -1px #0000004d;
  left: -134%;
}

.km-list .km-switch {
  margin-top: -1rem;
  position: absolute;
  top: 50%;
  right: .8rem;
}

.km-listview-link:after {
  content: " ";
  vertical-align: middle;
  border-style: solid;
  border-width: .24rem .24rem 0 0;
  width: .5rem;
  height: .5rem;
  margin-left: -.2rem;
  display: inline-block;
  transform: rotate(45deg);
}

.km-listview-wrapper > ul:not(.km-listview) {
  margin: 0 auto;
}

.km-list, .km-listview {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.km-listinset, .km-listgroupinset {
  margin: 1em;
}

.k-ff .km-listinset:after, .k-ff .km-listgroupinset:after {
  content: " ";
  height: 0;
  display: block;
}

.km-listinset, .km-listgroupinset .km-list {
  overflow: hidden;
}

.km-listview .km-switch {
  margin-top: -.95rem;
  position: absolute;
  top: 50%;
  right: .8rem;
}

.km-listview .km-list {
  text-indent: 0;
}

.km-list > li, .km-widget .km-listview-link, .km-widget .km-listview-label {
  vertical-align: middle;
  box-sizing: border-box;
  margin: 0;
  padding: .5em .7em;
  list-style-type: none;
  display: block;
  position: relative;
}

.km-list > li {
  line-height: 1.6em;
  overflow: hidden;
}

.km-virtual-list {
  width: 100%;
  position: relative;
  -webkit-transform: translateZ(0);
}

.km-virtual-list > li {
  width: 100%;
  position: absolute;
  top: 0;
  -webkit-transform: translateZ(0);
}

.km-widget.km-list .km-load-more, .km-widget .km-list .km-load-more {
  border-bottom: 0;
}

.km-list > li > * {
  line-height: normal;
}

.km-group-title {
  text-indent: .8em;
  padding: .2em 0;
  font-weight: bold;
  display: block;
}

.km-listgroupinset .km-group-title {
  margin-top: .65em;
  line-height: 2em;
}

.km-list:not(.km-virtual-list) > li:first-child {
  border-top-width: 0;
}

.km-list:not(.km-virtual-list) > li:last-child {
  border-bottom-width: 0;
}

.km-widget .km-listview-link, .km-widget .km-listview-label {
  line-height: inherit;
  margin: -.5em -.7em;
  text-decoration: none;
}

.km-listview-link:after, .km-listview-label:after {
  content: " ";
  border-color: #777;
  margin-top: -.32rem;
  display: block;
  position: absolute;
  top: 50%;
  right: 1rem;
}

.km-filter-form {
  border: 1px solid #0000;
  border-width: 1px 0;
  width: 100%;
  padding: .5em 0;
  transform: translateZ(0);
}

.km-filter-wrap {
  border: 1px solid #0000;
  margin: 0 .7em;
  padding: .2em .4em;
  position: relative;
}

.km-widget .km-filter-wrap:before {
  vertical-align: middle;
  content: "";
  color: inherit;
  width: 1em;
  height: 1em;
  margin-right: -1em;
  font-size: 1.6em;
  display: inline-block;
}

.km-tablet .km-filter-wrap {
  max-width: 24em;
  margin: 0 auto;
}

.km-filter-wrap > input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.km-filter-wrap input {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  vertical-align: middle;
  background: none;
  border: 0;
  width: 100%;
  padding: 0 1.4em;
}

.km-filter-reset {
  vertical-align: middle;
  text-align: center;
  z-index: 1;
  height: 100%;
  margin-left: -1.6em;
  text-decoration: none;
  display: inline-block;
}

.km-filter-reset .km-clear {
  width: 1em;
  height: 1em;
  font-size: 1.6em;
  display: block;
}

.km-filter-reset > .km-text {
  position: absolute;
  top: -3333px;
  left: -3333px;
}

.km-load-more {
  text-align: center;
  height: 3.2em;
  padding: .3em 0 1.2em;
  display: block;
}

.km-list .km-listview-item-badge.km-badge, .km-list .km-listview-link > .km-badge {
  top: 50%;
  right: 4em;
  transform: translateY(-50%);
}

.km-scrollview {
  white-space: nowrap;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.km-scrollview > div > * {
  -webkit-transform: translateZ(0);
}

.km-scrollview > div > [data-role="page"] {
  vertical-align: top;
  min-height: 1px;
  display: inline-block;
}

.km-scrollview .km-virtual-page {
  min-height: 1px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.k-ff18 .km-scrollview > div, .k-ff19 .km-scrollview > div, .k-ff20 .km-scrollview > div, .k-ff21 .km-scrollview > div {
  width: 0;
}

.km-pages {
  text-align: center;
  height: 1.5em;
  margin: 0;
  padding: .6em 0 0;
}

.km-pages li {
  width: .5em;
  height: .55em;
  margin: 0 .3em;
  display: inline-block;
}

.km-item label:before, .km-item label.km-item-checked:after {
  content: " ";
  width: 36px;
  height: 36px;
  margin-top: -18px;
  display: block;
  position: absolute;
  top: 50%;
  left: .6em;
}

.km-widget .k-slider {
  vertical-align: middle;
  text-align: center;
  line-height: .6em;
  display: inline-block;
  position: relative;
}

.km-widget .k-slider-horizontal {
  width: 50%;
  height: .6em;
  line-height: .6em;
}

.km-list .k-slider {
  margin-top: -.5em;
  position: absolute;
  top: 50%;
  right: 0;
}

.km-root .k-slider-track {
  border: .5em solid #0000;
  border-width: .5em 0;
  height: 100%;
  display: block;
  position: absolute;
  right: 1em;
  left: 1em !important;
}

.km-widget .k-slider-horizontal .k-slider-track {
  width: auto !important;
}

.km-widget .k-slider .k-slider-track {
  background-clip: padding-box;
}

.km-widget .k-slider-track, .km-widget .k-slider-selection {
  border-radius: 5px;
  margin-top: 0;
  box-shadow: inset 0 0 1px #0000004d;
}

.km-widget .k-slider-horizontal .k-slider-selection {
  height: 100%;
  top: 0;
}

.km-widget .k-slider-items {
  margin: 0;
}

.km-widget .k-slider .k-draghandle {
  text-indent: -3333px;
  width: 1.2em;
  height: 1.2em;
  display: block;
  position: absolute;
  left: 0;
}

.km-widget .k-slider-vertical .k-draghandle {
  left: -5px;
}

.km-widget .k-slider-tooltip {
  display: none;
}

.km-dialog {
  min-width: 19em;
  max-width: 25em;
  position: absolute;
  overflow: hidden;
}

.km-dialog-title {
  float: none;
  height: 2.6em;
  margin-top: -2.6em;
  font-size: 1.22em;
  line-height: 3em;
  position: static;
}

.km-dialog:before {
  content: " ";
  opacity: .2;
  width: 100%;
  height: 3em;
  display: block;
  overflow: visible;
}

.km-dialog-content {
  text-align: center;
  min-height: 2em;
  font-weight: normal;
}

.km-dialog .km-button {
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  margin: .4em;
  padding: .44em;
  font-size: 1.3em;
  display: block;
}

.km-list input[type="text"]:not(.k-input), .km-list input[type="password"], .km-list input[type="search"], .km-list input[type="number"], .km-list input[type="tel"], .km-list input[type="url"], .km-list input[type="email"], .km-list input[type="file"], .km-list input[type="month"], .km-list input[type="color"], .km-list input[type="week"], .km-list input[type="date"], .km-list input[type="time"], .km-list input[type="datetime"], .km-list input[type="datetime-local"], .km-list select:not([multiple]), .km-list .k-dropdown, .km-list textarea {
  box-sizing: border-box;
  z-index: 1;
  width: 50%;
  margin-top: -1em;
  font-size: 1.2em;
  line-height: normal;
  position: absolute;
  top: 50%;
  right: 0;
}

.km-widget .k-slider .k-tick, .km-widget .k-slider .k-label, .km-widget .k-slider .k-button {
  display: none;
}

.km-list textarea {
  width: -webkit-calc(50% + .7em);
  width: -moz-calc(50% + .7em);
  width: calc(50% + .7em);
  margin-right: -.7em;
  position: relative;
}

.km-list input, .km-list select, .km-list textarea, .km-list input[type="checkbox"], .km-list input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
}

.km-list input[type="checkbox"], .km-list input[type="radio"] {
  background: none;
  margin-top: -.5em;
  position: absolute;
  top: 50%;
  right: .7em;
}

.km-widget input, .km-widget textarea {
  -webkit-user-select: text;
  user-select: text;
}

.km-widget input[readonly], .km-widget input[type="image"], .km-widget select:not([multiple]) {
  -webkit-user-select: none;
  user-select: none;
}

.km-list textarea {
  top: 0;
}

.km-list .k-dropdown {
  line-height: 1.4em;
}

.km-list .k-dropdown, .km-list .k-dropdown .k-input {
  background-color: #0000;
}

.km-list .k-dropdown-wrap {
  display: inline-block;
}

.km-list .km-listview-label:after, .km-list input ~ .km-listview-link:after, .km-list textarea ~ .km-listview-link:after, .km-list select ~ .km-listview-link:after, .km-list .k-dropdown ~ .km-listview-link:after, .km-list .k-dropdown select, .km-list .k-dropdown .k-select {
  display: none;
}

.km-widget .km-list textarea {
  float: right;
  margin-top: 0;
  font-family: inherit;
  position: relative;
}

.km-listview-label input[type="radio"], .km-listview-label input[type="checkbox"] {
  font-size: inherit;
  border: 0;
  width: 1em;
  height: .9em;
}

.km-list label.km-required:after {
  content: "*";
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.km-list .km-label-above {
  width: 100%;
  display: block;
}

.km-list .km-icon-label:before {
  width: 1em;
  height: 1em;
  margin-right: .89em;
  font: 1em / 1em Kendo UI;
  display: inline-block;
}

.km-list .km-label-above input[type="text"]:not(.k-input), .km-list .km-label-above input[type="password"], .km-list .km-label-above input[type="search"], .km-list .km-label-above input[type="number"], .km-list .km-label-above input[type="tel"], .km-list .km-label-above input[type="url"], .km-list .km-label-above input[type="email"], .km-list .km-label-above input[type="file"], .km-list .km-label-above input[type="month"], .km-list .km-label-above input[type="color"], .km-list .km-label-above input[type="week"], .km-list .km-label-above input[type="date"], .km-list .km-label-above input[type="time"], .km-list .km-label-above input[type="datetime"], .km-list .km-label-above input[type="datetime-local"], .km-list .km-label-above select:not([multiple]), .km-list .km-label-above .k-dropdown, .km-list .km-label-above textarea {
  width: 100%;
  padding-left: 0;
  right: 0;
  transform: translateY(0);
}

.km-list textarea, .km-list fieldset input[type="password"], .km-list fieldset input[type="search"], .km-list fieldset input[type="number"], .km-list fieldset input[type="tel"], .km-list fieldset input[type="url"], .km-list fieldset input[type="email"], .km-list fieldset input[type="month"], .km-list fieldset input[type="color"], .km-list fieldset input[type="week"], .km-list fieldset input[type="date"], .km-list fieldset input[type="time"], .km-list fieldset input[type="datetime"], .km-list fieldset input[type="datetime-local"], .km-list fieldset input[type="text"]:not(.k-input), .km-list fieldset select:not([multiple]), .km-list fieldset .k-dropdown-wrap {
  width: 100%;
  position: relative;
  right: auto;
  transform: translateY(0);
}

.km-list fieldset textarea {
  width: 100%;
  margin-right: 0;
  padding-left: 0;
  transform: translateY(0);
}

.km-list .km-legend-button {
  text-decoration: none;
  display: block;
}

.km-list .km-inline-field {
  white-space: nowrap;
  width: calc(50% - 1.61em);
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.km-list .km-inline-field input[type="text"]:not(.k-input), .km-list .km-inline-field input[type="password"], .km-list .km-inline-field input[type="search"], .km-list .km-inline-field input[type="number"], .km-list .km-inline-field input[type="tel"], .km-list .km-inline-field input[type="url"], .km-list .km-inline-field input[type="email"], .km-list .km-inline-field input[type="file"], .km-list .km-inline-field input[type="month"], .km-list .km-inline-field input[type="color"], .km-list .km-inline-field input[type="week"], .km-list .km-inline-field input[type="date"], .km-list .km-inline-field input[type="time"], .km-list .km-inline-field input[type="datetime"], .km-list .km-inline-field input[type="datetime-local"], .km-list .km-inline-field select:not([multiple]), .km-list .km-inline-field .k-dropdown, .km-list .km-inline-field textarea {
  width: 100%;
  position: absolute;
  left: 0;
}

.km-list .km-full-width-slider {
  width: auto;
  display: block;
  position: relative;
}

.km-root input.km-checkbox, .km-root input.km-radio {
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  display: inline;
}

.km-root .km-checkbox-label, .km-root .km-radio-label {
  vertical-align: middle;
  display: block;
  position: relative;
}

.km-root .km-checkbox-label:before, .km-root .km-checkbox-label:after {
  content: "";
  position: absolute;
  top: 0;
}

.km-root input.km-checkbox:checked + .km-checkbox-label:after {
  content: "";
  font-family: Kendo UI;
}

.km-root .km-radio-label {
  vertical-align: middle;
  position: relative;
}

.km-root .km-radio-label:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.km-root input.km-radio:checked + .km-radio-label:after {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: .25rem;
  transform: translateY(-50%);
}

.km-root .km-invalid-msg {
  text-align: center;
  border-width: 1px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 11px;
  font-size: 1.2rem;
  display: inline-block;
  position: static;
}

.km-root .km-icon-label.k-i-arrow-e:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrow-n:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrow-s:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrow-w:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrow-ne:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrow-nw:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrow-se:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrow-sw:before {
  content: "";
}

.km-root .km-icon-label.k-i-seek-e:before {
  content: "";
}

.km-root .km-icon-label.k-i-seek-n:before {
  content: "";
}

.km-root .km-icon-label.k-i-seek-s:before {
  content: "";
}

.km-root .km-icon-label.k-i-seek-w:before {
  content: "";
}

.km-root .km-icon-label.k-si-arrow-e:before, .km-root .km-icon-label.k-i-sarrow-e:before {
  content: "";
}

.km-root .km-icon-label.k-si-arrow-n:before, .km-root .km-icon-label.k-i-sarrow-n:before {
  content: "";
}

.km-root .km-icon-label.k-si-arrow-s:before, .km-root .km-icon-label.k-i-sarrow-s:before {
  content: "";
}

.km-root .km-icon-label.k-si-arrow-w:before, .km-root .km-icon-label.k-i-sarrow-w:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrowhead-e:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrowhead-n:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrowhead-s:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrowhead-w:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrowhead-ew:before {
  content: "";
}

.km-root .km-icon-label.k-i-arrowhead-ns:before {
  content: "";
}

.km-root .km-icon-label.k-i-move:before {
  content: "";
}

.km-root .km-icon-label.k-i-resize:before {
  content: "";
}

.km-root .km-icon-label.k-i-resize-45:before {
  content: "";
}

.km-root .km-icon-label.k-i-resize-135:before {
  content: "";
}

.km-root .km-icon-label.k-i-resize-h:before {
  content: "";
}

.km-root .km-icon-label.k-i-resize-v:before {
  content: "";
}

.km-root .km-icon-label.k-i-refresh:before {
  content: "";
}

.km-root .km-icon-label.k-i-refresh-rev:before {
  content: "";
}

.km-root .km-icon-label.k-si-refresh:before, .km-root .km-icon-label.k-i-srefresh:before {
  content: "";
}

.km-root .km-icon-label.k-si-refresh-rev:before, .km-root .km-icon-label.k-i-srefresh-rev:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-en:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-es:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-ne:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-nw:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-se:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-sw:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-wn:before {
  content: "";
}

.km-root .km-icon-label.k-i-turn-ws:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-e:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-e-rev:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-n:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-n-rev:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-s:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-s-rev:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-w:before {
  content: "";
}

.km-root .km-icon-label.k-i-uturn-w-rev:before {
  content: "";
}

.km-root .km-icon-label.k-i-tick-sign:before {
  content: "";
}

.km-root .km-icon-label.k-i-close-sign:before {
  content: "";
}

.km-root .km-icon-label.k-i-plus-sign:before {
  content: "";
}

.km-root .km-icon-label.k-i-minus-sign:before {
  content: "";
}

.km-root .km-icon-label.k-si-plus-sign:before, .km-root .km-icon-label.k-i-splus-sign:before {
  content: "";
}

.km-root .km-icon-label.k-si-minus-sign:before, .km-root .km-icon-label.k-i-sminus-sign:before {
  content: "";
}

.km-root .km-icon-label.k-i-cancel:before {
  content: "";
}

.km-root .km-icon-label.k-i-tick:before {
  content: "";
}

.km-root .km-icon-label.k-i-close:before {
  content: "";
}

.km-root .km-icon-label.k-i-plus:before {
  content: "";
}

.km-root .km-icon-label.k-i-minus:before {
  content: "";
}

.km-root .km-icon-label.k-si-plus:before, .km-root .km-icon-label.k-i-splus:before {
  content: "";
}

.km-root .km-icon-label.k-si-minus:before, .km-root .km-icon-label.k-i-sminus:before {
  content: "";
}

.km-root .km-icon-label.k-i-stop:before {
  content: "";
}

.km-root .km-icon-label.k-i-pause:before {
  content: "";
}

.km-root .km-icon-label.k-i-eject:before {
  content: "";
}

.km-root .km-icon-label.k-i-volume-off:before {
  content: "";
}

.km-root .km-icon-label.k-i-volume-low:before {
  content: "";
}

.km-root .km-icon-label.k-i-volume-high:before {
  content: "";
}

.km-root .km-icon-label.k-i-earphones:before {
  content: "";
}

.km-root .km-icon-label.k-i-heart:before {
  content: "";
}

.km-root .km-icon-label.k-i-heart-empty:before {
  content: "";
}

.km-root .km-icon-label.k-i-heart-half:before {
  content: "";
}

.km-root .km-icon-label.k-i-star-empty:before {
  content: "";
}

.km-root .km-icon-label.k-i-star-half:before {
  content: "";
}

.km-root .km-icon-label.k-i-star-half-empty:before {
  content: "";
}

.km-root .km-icon-label.k-i-chart-column:before {
  content: "";
}

.km-root .km-icon-label.k-i-chart-bar:before {
  content: "";
}

.km-root .km-icon-label.k-i-chart-pie:before {
  content: "";
}

.km-root .km-icon-label.k-i-chart-line:before {
  content: "";
}

.km-root .km-icon-label.k-i-chart-area:before {
  content: "";
}

.km-root .km-icon-label.k-i-chart-donut:before {
  content: "";
}

.km-root .km-icon-label.k-i-align-left:before {
  content: "";
}

.km-root .km-icon-label.k-i-align-center:before {
  content: "";
}

.km-root .km-icon-label.k-i-align-right:before {
  content: "";
}

.km-root .km-icon-label.k-i-align-justify:before {
  content: "";
}

.km-root .km-icon-label.k-i-align-clear:before {
  content: "";
}

.km-root .km-icon-label.k-i-bold:before {
  content: "";
}

.km-root .km-icon-label.k-i-bold-sans:before {
  content: "";
}

.km-root .km-icon-label.k-i-italic:before {
  content: "";
}

.km-root .km-icon-label.k-i-italic-sans:before {
  content: "";
}

.km-root .km-icon-label.k-i-underline:before {
  content: "";
}

.km-root .km-icon-label.k-i-underline-sans:before {
  content: "";
}

.km-root .km-icon-label.k-i-strikethrough:before {
  content: "";
}

.km-root .km-icon-label.k-i-strikethrough-sans:before {
  content: "";
}

.km-root .km-icon-label.k-i-font-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-font-a-sans:before {
  content: "";
}

.km-root .km-icon-label.k-i-font-t:before {
  content: "";
}

.km-root .km-icon-label.k-i-font-t-sans:before {
  content: "";
}

.km-root .km-icon-label.k-i-brightness:before {
  content: "";
}

.km-root .km-icon-label.k-i-brightness-contrast:before {
  content: "";
}

.km-root .km-icon-label.k-i-contrast:before {
  content: "";
}

.km-root .km-icon-label.k-i-crop:before {
  content: "";
}

.km-root .km-icon-label.k-i-mirror:before {
  content: "";
}

.km-root .km-icon-label.k-i-flip-h:before {
  content: "";
}

.km-root .km-icon-label.k-i-flip-v:before {
  content: "";
}

.km-root .km-icon-label.k-i-rotate:before {
  content: "";
}

.km-root .km-icon-label.k-i-rotate-rev:before {
  content: "";
}

.km-root .km-icon-label.k-i-filter:before {
  content: "";
}

.km-root .km-icon-label.k-i-filter-clear:before {
  content: "";
}

.km-root .km-icon-label.k-i-sort-asc:before {
  content: "";
}

.km-root .km-icon-label.k-i-sort-desc:before {
  content: "";
}

.km-root .km-icon-label.k-i-sort-clear:before {
  content: "";
}

.km-root .km-icon-label.k-i-indent:before {
  content: "";
}

.km-root .km-icon-label.k-i-outdent:before {
  content: "";
}

.km-root .km-icon-label.k-i-hyperlink:before {
  content: "";
}

.km-root .km-icon-label.k-i-hyperlink-clear:before {
  content: "";
}

.km-root .km-icon-label.k-i-hyperlink-ext:before {
  content: "";
}

.km-root .km-icon-label.k-si-hyperlink-ext:before {
  content: "";
}

.km-root .km-icon-label.k-i-ul:before {
  content: "";
}

.km-root .km-icon-label.k-si-ul:before {
  content: "";
}

.km-root .km-icon-label.k-i-paint:before {
  content: "";
}

.km-root .km-icon-label.k-i-paste:before {
  content: "";
}

.km-root .km-icon-label.k-i-pencil:before {
  content: "";
}

.km-root .km-icon-label.k-i-image:before {
  content: "";
}

.km-root .km-icon-label.k-i-image-add:before {
  content: "";
}

.km-root .km-icon-label.k-i-print:before {
  content: "";
}

.km-root .km-icon-label.k-i-zoom:before {
  content: "";
}

.km-root .km-icon-label.k-i-zoom-in:before {
  content: "";
}

.km-root .km-icon-label.k-i-zoom-out:before {
  content: "";
}

.km-root .km-icon-label.k-i-asterisk:before {
  content: "";
}

.km-root .km-icon-label.k-i-clip:before {
  content: "";
}

.km-root .km-icon-label.k-i-clip-45:before {
  content: "";
}

.km-root .km-icon-label.k-i-qrcode:before {
  content: "";
}

.km-root .km-icon-label.k-i-book:before {
  content: "";
}

.km-root .km-icon-label.k-i-bookmark:before {
  content: "";
}

.km-root .km-icon-label.k-i-briefcase:before {
  content: "";
}

.km-root .km-icon-label.k-i-calendar:before {
  content: "";
}

.km-root .km-icon-label.k-i-camera-still:before {
  content: "";
}

.km-root .km-icon-label.k-i-camera-video:before {
  content: "";
}

.km-root .km-icon-label.k-i-certificate:before {
  content: "";
}

.km-root .km-icon-label.k-i-clock:before {
  content: "";
}

.km-root .km-icon-label.k-i-cloud:before {
  content: "";
}

.km-root .km-icon-label.k-i-collapse:before {
  content: "";
}

.km-root .km-icon-label.k-i-columns:before {
  content: "";
}

.km-root .km-icon-label.k-i-comment:before {
  content: "";
}

.km-root .km-icon-label.k-i-comment-empty:before {
  content: "";
}

.km-root .km-icon-label.k-i-comments:before {
  content: "";
}

.km-root .km-icon-label.k-i-comments-empty:before {
  content: "";
}

.km-root .km-icon-label.k-i-credit-card:before {
  content: "";
}

.km-root .km-icon-label.k-i-download:before {
  content: "";
}

.km-root .km-icon-label.k-i-draghandle:before {
  content: "";
}

.km-root .km-icon-label.k-si-draghandle:before {
  content: "";
}

.km-root .km-icon-label.k-i-envelop:before {
  content: "";
}

.km-root .km-icon-label.k-i-envelop-open:before {
  content: "";
}

.km-root .km-icon-label.k-i-eye:before {
  content: "";
}

.km-root .km-icon-label.k-i-file:before {
  content: "";
}

.km-root .km-icon-label.k-i-file-add:before {
  content: "";
}

.km-root .km-icon-label.k-i-film:before {
  content: "";
}

.km-root .km-icon-label.k-i-flag:before {
  content: "";
}

.km-root .km-icon-label.k-i-folder-add:before {
  content: "";
}

.km-root .km-icon-label.k-i-folder:before {
  content: "";
}

.km-root .km-icon-label.k-i-folder-open:before {
  content: "";
}

.km-root .km-icon-label.k-i-folder-up:before {
  content: "";
}

.km-root .km-icon-label.k-i-gear:before {
  content: "";
}

.km-root .km-icon-label.k-si-gear:before {
  content: "";
}

.km-root .km-icon-label.k-i-transmit:before {
  content: "";
}

.km-root .km-icon-label.k-i-beer:before {
  content: "";
}

.km-root .km-icon-label.k-i-cocktail:before {
  content: "";
}

.km-root .km-icon-label.k-i-coffee:before {
  content: "";
}

.km-root .km-icon-label.k-i-wine:before {
  content: "";
}

.km-root .km-icon-label.k-i-grid:before {
  content: "";
}

.km-root .km-icon-label.k-i-thumbs:before {
  content: "";
}

.km-root .km-icon-label.k-i-split-h:before {
  content: "";
}

.km-root .km-icon-label.k-i-split-v:before {
  content: "";
}

.km-root .km-icon-label.k-i-home:before {
  content: "";
}

.km-root .km-icon-label.k-i-inbox:before {
  content: "";
}

.km-root .km-icon-label.k-i-key:before {
  content: "";
}

.km-root .km-icon-label.k-i-login:before {
  content: "";
}

.km-root .km-icon-label.k-i-logout:before {
  content: "";
}

.km-root .km-icon-label.k-i-place:before {
  content: "";
}

.km-root .km-icon-label.k-i-megaphone:before {
  content: "";
}

.km-root .km-icon-label.k-i-note:before {
  content: "";
}

.km-root .km-icon-label.k-i-pin:before {
  content: "";
}

.km-root .km-icon-label.k-i-unpin:before {
  content: "";
}

.km-root .km-icon-label.k-i-power:before {
  content: "";
}

.km-root .km-icon-label.k-i-progress-bars:before {
  content: "";
}

.km-root .km-icon-label.k-i-road:before {
  content: "";
}

.km-root .km-icon-label.k-i-rss:before {
  content: "";
}

.km-root .km-icon-label.k-i-floppy:before {
  content: "";
}

.km-root .km-icon-label.k-i-sitemap:before {
  content: "";
}

.km-root .km-icon-label.k-i-tag-45:before {
  content: "";
}

.km-root .km-icon-label.k-i-tag-h:before {
  content: "";
}

.km-root .km-icon-label.k-i-thunderbolt:before {
  content: "";
}

.km-root .km-icon-label.k-i-tooltip:before {
  content: "";
}

.km-root .km-icon-label.k-i-trash:before {
  content: "";
}

.km-root .km-icon-label.k-i-trophy:before {
  content: "";
}

.km-root .km-icon-label.k-i-ungroup:before {
  content: "";
}

.km-root .km-icon-label.k-i-upload:before {
  content: "";
}

.km-root .km-icon-label.k-i-window:before {
  content: "";
}

.km-root .km-icon-label.k-i-tiles:before {
  content: "";
}

.km-root .km-icon-label.k-i-wrench:before {
  content: "";
}

.km-root .km-icon-label.k-i-action:before {
  content: "";
}

.km-root .km-icon-label.k-i-add:before {
  content: "";
}

.km-root .km-icon-label.k-i-add-inv:before {
  content: "";
}

.km-root .km-icon-label.k-i-armchair:before {
  content: "";
}

.km-root .km-icon-label.k-i-battery:before {
  content: "";
}

.km-root .km-icon-label.k-i-book-open:before {
  content: "";
}

.km-root .km-icon-label.k-i-camera:before {
  content: "";
}

.km-root .km-icon-label.k-i-cart:before {
  content: "";
}

.km-root .km-icon-label.k-i-time:before {
  content: "";
}

.km-root .km-icon-label.k-i-coffee-card:before {
  content: "";
}

.km-root .km-icon-label.k-i-download-arrow:before {
  content: "";
}

.km-root .km-icon-label.k-i-edit:before {
  content: "";
}

.km-root .km-icon-label.k-i-faves:before {
  content: "";
}

.km-root .km-icon-label.k-i-star:before {
  content: "";
}

.km-root .km-icon-label.k-i-featured:before {
  content: "";
}

.km-root .km-icon-label.k-i-forward:before {
  content: "";
}

.km-root .km-icon-label.k-i-cog:before {
  content: "";
}

.km-root .km-icon-label.k-i-globe:before {
  content: "";
}

.km-root .km-icon-label.k-i-globe-inv:before {
  content: "";
}

.km-root .km-icon-label.k-i-house:before {
  content: "";
}

.km-root .km-icon-label.k-i-share:before {
  content: "";
}

.km-root .km-icon-label.k-i-more-h:before {
  content: "";
}

.km-root .km-icon-label.k-i-more-v:before {
  content: "";
}

.km-root .km-icon-label.k-i-next:before {
  content: "";
}

.km-root .km-icon-label.k-i-pause-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-user:before {
  content: "";
}

.km-root .km-icon-label.k-i-play-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-refresh-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-reset:before {
  content: "";
}

.km-root .km-icon-label.k-i-rewind:before {
  content: "";
}

.km-root .km-icon-label.k-i-search-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-stop-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-tiles-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-trash-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-undo:before {
  content: "";
}

.km-root .km-icon-label.k-i-redo:before {
  content: "";
}

.km-root .km-icon-label.k-i-volume-a:before {
  content: "";
}

.km-root .km-icon-label.k-i-wifi:before {
  content: "";
}

.km-root .km-icon-label.k-i-more-lines:before {
  content: "";
}

.km-root .km-icon-label.k-i-pull-to-ref:before {
  content: "";
}

.km-root .km-icon-label.k-i-loading-android:before {
  content: "";
}

.km-root .km-icon-label.k-i-loading-blackberry:before {
  content: "";
}

.km-root .km-icon-label.k-i-loading-meego:before {
  content: "";
}

.km-root .km-icon-label.k-i-loading-custom:before {
  content: "";
}

.km-root .km-icon-label.k-i-loading-ios:before {
  content: "";
}

.km-root .km-icon-label.k-i-bug:before {
  content: "";
}

.km-root .km-icon-label.k-i-info:before {
  content: "";
}

.km-root .km-icon-label.k-i-warning:before {
  content: "";
}

.km-root .km-icon-label.k-i-question:before {
  content: "";
}

.km-root .km-icon-label.k-i-insert-n:before {
  content: "";
}

.km-root .km-icon-label.k-i-insert-m:before {
  content: "";
}

.km-root .km-icon-label.k-i-insert-s:before {
  content: "";
}

.km-root .km-icon-label.k-i-lock:before {
  content: "";
}

.km-root .km-icon-label.k-i-unlock:before {
  content: "";
}

.km-root .km-icon-label.k-i-phone:before {
  content: "";
}

.km-root .km-icon-label.k-i-tablet:before {
  content: "";
}

.km-root .km-icon-label.k-i-ol:before {
  content: "";
}

.km-root .km-icon-label.k-i-barcode:before {
  content: "";
}

.km-root .km-icon-label.k-i-html5:before {
  content: "";
}

.km-root .km-icon-label.k-i-css3:before {
  content: "";
}

.km-root .km-icon-label.k-i-kendoui:before {
  content: "";
}

.km-root .km-icon-label.k-i-telerik:before {
  content: "";
}

.km-root .km-icon-label.k-i-icenium:before {
  content: "";
}

.km-root .km-icon-label.k-i-sitefinity:before {
  content: "";
}

.km-root .km-icon-label.k-i-twitter:before {
  content: "";
}

.km-root .km-icon-label.k-i-linkedin:before {
  content: "";
}

.km-root .km-icon-label.k-i-facebook:before {
  content: "";
}

.km-root .km-icon-label.k-i-pinterest:before {
  content: "";
}

.km-root .km-icon-label.k-i-youtube:before {
  content: "";
}

.km-root .km-icon-label.k-i-vimeo:before {
  content: "";
}

.km-root .km-icon-label.k-i-behance:before {
  content: "";
}

.km-root .km-icon-label.k-i-dribbble:before {
  content: "";
}

.km-root .km-icon-label.k-i-googleplus:before {
  content: "";
}

.km-root .km-icon-label.k-i-minimize:before {
  content: "";
}

.km-root .km-icon-label.k-i-html:before {
  content: "";
}

.km-root .km-icon-label.k-i-group:before {
  content: "";
}

.km-root .km-icon-label.k-i-subscript:before {
  content: "";
}

.km-root .km-icon-label.k-i-superscript:before {
  content: "";
}

.km-root .km-icon-label.k-i-drophere:before {
  content: "";
}

.km-root .km-popup .k-item, .km-widget.km-actionsheet > li {
  padding: inherit 1em;
  box-sizing: border-box;
  border-bottom: 1px solid #555;
  list-style-type: none;
}

.km-widget.km-actionsheet > li {
  border-bottom: 0;
  line-height: 2em;
}

.km-widget.km-actionsheet > li > a {
  text-align: left;
  background: none;
  line-height: 1.5em;
}

.km-root .km-popup .k-list, .km-widget.km-actionsheet {
  margin: 0;
  padding: 0;
}

.km-root .km-popup .k-item:last-child, .km-widget.km-actionsheet > li:last-child {
  border: 0;
}

.km-widget.km-actionsheet-wrapper {
  box-shadow: none;
  border: 0;
  width: 100%;
}

.km-actionsheet-root.km-shim .k-animation-container {
  width: 100% !important;
  height: 100% !important;
}

.km-tablet .km-pane div.km-actionsheet-phone {
  background: none;
}

.km-tablet .km-actionsheet-phone li.km-actionsheet-title, .km-tablet div.km-actionsheet-phone li.km-actionsheet-cancel {
  display: block;
}

.km-popover-root .km-popup-wrapper {
  position: relative !important;
}

.km-popup-wrapper, .km-modalview-wrapper {
  z-index: 10001;
  box-shadow: none;
  background: none;
  border: 0;
  position: relative;
}

.km-popup-overlay {
  z-index: 10002;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.km-popup-arrow, .km-popup-arrow:after, .km-popup-arrow:before {
  z-index: 2;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.km-left .km-popup-arrow, .km-right .km-popup-arrow {
  margin-top: -8px;
}

.km-up .km-popup-arrow, .km-down .km-popup-arrow {
  margin-left: -8px;
}

.km-popup-arrow:after, .km-popup-arrow:before {
  content: " ";
  width: 0;
  height: 0;
  display: block;
}

.km-up .km-popup-arrow {
  top: auto;
  bottom: 0;
}

.km-left .km-popup-arrow {
  left: auto;
  right: 0;
}

.km-popup.km-pane {
  box-sizing: border-box;
  background: none;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.km-popover-root .km-view {
  position: relative;
}

.km-popover-root .km-content {
  -ms-flex: auto;
}

.km-drawer {
  width: 250px;
  top: 0;
  left: auto;
}

.km-drawer .km-header, .km-drawer .km-footer {
  z-index: 0;
}

.km-left-drawer {
  left: 0;
}

.km-right-drawer {
  right: 0;
}

.km-modalview-root {
  text-align: center;
}

.km-modalview-root > .k-animation-container {
  text-align: left;
  vertical-align: middle;
  display: inline-block !important;
  position: relative !important;
  top: auto !important;
  left: auto !important;
}

.km-modalview, .km-modalview-wrapper:before {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.km-modalview .km-content {
  box-flex: 1;
}

.km-auto-height .km-content {
  -ms-flex: auto;
}

.km-native-scrolling .km-view.km-modalview {
  display: inline-flex;
}

.km-modalview-root:before, .km-modalview-wrapper:before {
  vertical-align: middle;
  content: " ";
  width: 0;
  height: 100%;
  margin-left: -1px;
  display: inline-block;
}

.km-scroller-pull {
  text-align: center;
  width: 100%;
  font-size: 1.4em;
  line-height: 3em;
  display: block;
  position: absolute;
  transform: translate3d(0, -3em, 0);
}

.km-scroller-pull .km-template {
  text-align: left;
  min-width: 200px;
  display: inline-block;
}

.km-load-more .km-icon, .km-widget .km-scroller-pull .km-icon {
  vertical-align: middle;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  font-size: 2rem;
  transition: transform .3s linear;
  display: inline-block;
  transform: rotate(0);
}

.km-widget .km-scroller-release .km-icon {
  transform: rotate(180deg);
}

.km-widget .km-scroller-refresh .km-icon {
  transition: none;
}

.km-touch-scrollbar {
  visibility: hidden;
  z-index: 200000;
  opacity: 0;
  transform-origin: 0 0;
  background-color: #333;
  width: .4em;
  height: .4em;
  transition: opacity .3s linear;
  position: absolute;
}

.km-vertical-scrollbar {
  height: 100%;
  top: 0;
  right: 2px;
}

.km-horizontal-scrollbar {
  width: 100%;
  bottom: 2px;
  left: 0;
}

.km-scrollview, .km-scroll-container {
  -webkit-user-select: none;
  user-select: none;
  -moz-user-select: -moz-none;
  -webkit-margin-collapse: separate;
  margin-collapse: separate;
}

.km-scroll-wrapper {
  position: relative;
}

.km-scroll-header {
  z-index: 1001;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

div.km-splitview > .km-content, kendo-mobile-split-view.km-splitview > .km-content {
  flex-direction: row;
}

div.km-split-vertical > .km-content, kendo-mobile-split-view.km-split-vertical > .km-content {
  flex-direction: column;
}

div.km-split-content > .km-pane {
  flex: 2;
  width: auto;
  height: auto;
}

div.km-split-content > .km-pane:first-child {
  flex: 1;
}

div.km-split-horizontal > .km-content > .km-pane {
  top: 0;
  bottom: 0;
}

.km-split-vertical > .km-content > .km-pane > .km-view {
  display: -webkit-box;
}

.km-on-wp .km-view, .km-on-wp .km-header, .km-on-wp .km-footer, .km-on-wp .km-slider, .km-on-wp .km-switch, .km-on-wp .km-filter-reset, .km-on-wp .km-shim .k-animation-container, .km-on-wp .km-scroll-container {
  transform: translateZ(0);
}

.km-ios, .km-blackberry, .km-on-ios .km-view, .km-on-ios .km-header, .km-on-ios .km-footer, .km-on-ios .km-slider, .km-on-ios .km-switch, .km-on-ios .km-group-title, .km-on-ios .km-filter-reset, .km-on-ios .km-shim .k-animation-container, .km-on-ios .km-scroll-container, .km-on-blackberry .km-view, .km-on-blackberry .km-content, .km-on-blackberry .km-header, .km-on-blackberry .km-footer, .km-on-blackberry .km-icon, .km-on-blackberry .km-switch, .km-on-blackberry .km-popup .k-item, .km-on-blackberry .km-actionsheet-wrapper, .km-on-android.km-4 .k-slider {
  -webkit-transform: translateZ(0);
}

.km-on-android.km-4 .km-switch, .km-on-android.km-4 .km-listview-wrapper, .km-on-android.km-4 .km-content, .km-on-android.km-4 .km-switch-handle, .km-android.km-4.km-on-android .km-switch-wrapper, .km-on-android.km-4 .km-scroll-container, .km-on-meego .km-content, .km-on-meego .km-switch, .km-on-meego .km-icon, .km-on-meego .km-header, .km-on-meego .km-footer, .km-on-meego .km-content, .km-on-meego .km-switch-handle, .km-on-meego .km-switch-wrapper {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}

.km-android4.km-ios-chrome .km-listview-wrapper {
  -webkit-transform: none;
}

.km-native-scrolling .km-header, .km-native-scrolling .km-footer, .km-native-scrolling .km-shim, .km-native-scrolling .km-popup-overlay, .km-native-scrolling .km-drawer, .km-native-scrolling > .km-pane > .km-loader, .km-on-android.km-4 .km-scroller-pull .km-icon {
  -webkit-backface-visibility: hidden;
}

.km-on-android.km-4 input {
  -webkit-user-modify: read-write-plaintext-only;
}

.km-wp .km-view .km-absolute, .km-meego .km-view .km-absolute {
  position: absolute;
}

.km-on-ios [contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.km-widget {
  font-size: 1em;
}

.km-root .km-pane, .km-root .km-view, .km-root .km-slider, .km-root .km-switch, .km-root .km-group-title, .km-root .km-filter-reset, .km-root .km-shim .k-animation-container {
  transform: none;
}

.km-widget, .km-widget * {
  box-sizing: border-box;
  background-clip: border-box;
}

.km-toolbar, .km-navbar, .km-tabstrip, .km-tabstrip .km-button {
  border-radius: 0;
}

.km-touch-scrollbar {
  box-sizing: border-box;
  border: 0;
  border-radius: 1em;
}

.km-popup .k-popup {
  font-size: 1em !important;
}

.km-popup-wrapper.k-popup {
  box-shadow: none;
  background: none;
}

.km-popup .k-item, .km-actionsheet > li > a {
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
  padding: .5em 0 .5em .6em;
  text-decoration: none;
}

.km-actionsheet-wrapper, .km-popup .k-list-container {
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
  bottom: 0;
}

.km-popup {
  box-sizing: border-box;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

.km-popup .k-list-container {
  max-height: 80%;
  width: 100% !important;
  height: auto !important;
}

.km-shim .k-animation-container {
  top: auto;
  bottom: 0;
  left: 0;
}

.km-loader:before, .km-scroller-refresh.km-load-more, .km-scroller-pull {
  border-radius: 20em;
  overflow: visible;
}

.km-loader:before {
  content: " ";
  border-radius: 5em;
  width: 4em;
  height: 4em;
  margin-top: -2em;
  margin-left: -2em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.km-loader {
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  left: 0;
}

.km-loader h1 {
  width: 200%;
  font-size: 1em;
  display: none;
  position: absolute;
  top: 55%;
  left: -50%;
}

.km-loading, .km-loader .km-loading-left, .km-loader .km-loading-right, .km-load-more.km-scroller-refresh .km-icon, .km-scroller-pull.km-scroller-refresh .km-icon, .km-scroller-refresh .km-loading-left, .km-scroller-refresh .km-loading-right {
  background-clip: padding-box;
  border-radius: 1em;
  width: .36em;
  height: 1em;
  margin-top: -.5em;
  margin-left: -.8em;
  font-size: 1em;
  animation: .6s linear infinite km-webload;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.km-load-more.km-scroller-refresh .km-icon, .km-scroller-pull.km-scroller-refresh .km-icon, .km-scroller-refresh .km-loading-left, .km-scroller-refresh .km-loading-right {
  width: .25em;
  height: .6em;
  margin-top: -.3em;
  margin-left: -.6em;
  animation: .6s linear infinite km-webrefresh;
}

.km-root .km-loading-left {
  margin-left: -.2em;
  animation-delay: .2s;
}

.km-root .km-loading-right {
  margin-left: .42em;
  animation-delay: .4s;
}

@keyframes km-webload {
  0% {
    height: 1em;
    margin-top: -.5em;
  }

  33% {
    height: 2em;
    margin-top: -1em;
  }

  66% {
    height: 1em;
    margin-top: -.5em;
  }
}

@keyframes km-webrefresh {
  0% {
    height: .6em;
    margin-top: -.3em;
  }

  33% {
    height: 1.2em;
    margin-top: -.6em;
  }

  66% {
    height: .6em;
    margin-top: -.3em;
  }
}

.km-detail .km-icon, .km-button .km-icon, .km-list .km-icon, .km-ios .km-button .km-icon {
  vertical-align: baseline;
  background-size: auto 100%;
  width: 1em;
  height: 1em;
  margin-left: -.3em;
  margin-right: .3em;
  font-size: 1em;
  display: inline-block;
}

html .km-widget .km-view .km-notext {
  margin-left: 0;
  margin-right: 0;
}

.km-buttongroup .km-button .km-icon {
  width: 1em;
  height: 1em;
  margin: .05em .16em 0 0;
  font-size: 1em;
}

.km-tabstrip .km-button .km-icon {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 2.5rem;
}

.km-tabstrip .km-image, .km-tabstrip .km-button .km-icon {
  margin: 0 auto .1em;
  display: inline-block;
}

.km-tabstrip .km-text {
  display: block;
}

.km-phone .km-tabstrip .km-icon {
  width: 2.2rem;
  height: 2.2rem;
  font-size: 2.2rem;
}

.km-phone .km-horizontal .km-tabstrip .km-icon {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}

@font-face {
  font-family: Kendo UI;
  src: url("kendoui.8368ed88.woff") format("woff"), url("kendoui.f193d06f.ttf") format("truetype"), url("kendoui.e29707dd.svg#kendoui") format("svg");
}

body:before {
  content: " ";
  z-index: -1;
  width: 0;
  height: 0;
  font-family: Kendo UI;
  font-size: 0;
  position: absolute;
}

.km-root .km-pane .km-view .km-icon {
  background-size: 0 0;
  -webkit-background-clip: text;
}

.km-icon {
  position: relative;
}

.km-icon:after, .km-icon:before, .km-contactadd:after, .km-contactadd:before, .km-rowdelete:after, .km-rowdelete:before, .km-rowinsert:after, .km-rowinsert:before, .km-detaildisclose:after, .km-detaildisclose:before, .km-loading:after, .km-filter-wrap:before {
  content: " ";
  text-align: left;
  vertical-align: middle;
  background-size: auto;
  width: 100%;
  height: 100%;
  font: 1em / 1em Kendo UI;
  display: block;
  position: relative;
}

.km-icon:before, .km-contactadd:before, .km-rowdelete:before, .km-rowinsert:before, .km-detaildisclose:before {
  color: #000000b3;
  margin-top: 1px;
  display: none;
  position: absolute;
}

.km-state-active .km-icon:before, .km-state-active .km-contactadd:before, .km-state-active .km-rowdelete:before, .km-state-active .km-rowinsert:before, .km-state-active .km-detaildisclose:before {
  display: block;
}

.km-ios7 .km-detaildisclose:after {
  font-family: serif;
}

.km-ios7 .km-icon:before, .km-ios7 .km-contactadd:before, .km-ios7 .km-rowdelete:before, .km-ios7 .km-rowinsert:before, .km-ios7 .km-detaildisclose:before {
  display: none;
}

.k-webkit .km-ios:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after, .k-webkit .km-blackberry:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after, .k-safari .km-ios:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after, .k-safari .km-blackberry:not(.km-android):not(.km-blackberry6):not(.km-blackberry7) .km-icon:after {
  background-image: inherit;
  background-repeat: inherit;
  background-position: inherit;
  -webkit-text-fill-color: transparent;
  background-color: currentColor;
  -webkit-background-clip: text;
}

.km-root .km-pane.km-on-blackberry.km-blackberry6 .km-view .km-icon:after, .km-root .km-pane.km-on-blackberry.km-blackberry7 .km-view .km-icon:after, .km-root .km-pane.km-pane.km-on-android .km-view .km-icon:after, .km-root .km-pane.km-pane.km-on-meego .km-view .km-icon:after {
  -webkit-text-fill-color: inherit;
  background: none;
}

.km-contactadd:after, .km-contactadd:before, .km-rowinsert:after, .km-rowinsert:before {
  content: "";
}

.km-rowdelete:after, .km-rowdelete:before {
  content: "";
}

.km-detaildisclose:after, .km-detaildisclose:before {
  content: "";
}

.km-action:after, .km-action:before {
  content: "";
}

.km-add:after, .km-add:before {
  content: "";
}

.km-arrow-n:after, .km-arrow-n:before {
  content: "";
}

.km-arrow-s:after, .km-arrow-s:before {
  content: "";
}

.km-battery:after, .km-battery:before {
  content: "";
}

.km-bookmarks:after, .km-bookmarks:before {
  content: "";
}

.km-camera:after, .km-camera:before {
  content: "";
}

.km-cart:after, .km-cart:before {
  content: "";
}

.km-edit:after, .km-compose:after, .km-edit:before, .km-compose:before {
  content: "";
}

.km-contacts:after, .km-contacts:before {
  content: "";
}

.km-trash:after, .km-delete:after, .km-trash:before, .km-delete:before {
  content: "";
}

.km-details:after, .km-details:before {
  content: "";
}

.km-download:after, .km-downloads:after, .km-download:before, .km-downloads:before {
  content: "";
}

.km-fastforward:after, .km-fastforward:before {
  content: "";
}

.km-toprated:after, .km-favorites:after, .km-toprated:before, .km-favorites:before {
  content: "";
}

.km-featured:after, .km-featured:before {
  content: "";
}

.km-globe:after, .km-globe:before {
  content: "";
}

.km-history:after, .km-history:before {
  content: "";
}

.km-home:after, .km-home:before {
  content: "";
}

.km-info:after, .km-about:after, .km-info:before, .km-about:before {
  content: "";
}

.km-minus:after, .km-minus:before {
  content: "";
}

.km-more:after, .km-more:before {
  content: "";
}

.km-mostrecent:after, .km-mostrecent:before {
  content: "";
}

.km-mostviewed:after, .km-mostviewed:before {
  content: "";
}

.km-organize:after, .km-organize:before {
  content: "";
}

.km-pause:after, .km-pause:before {
  content: "";
}

.km-play:after, .km-play:before {
  content: "";
}

.km-plus:after, .km-plus:before {
  content: "";
}

.km-recents:after, .km-recents:before {
  content: "";
}

.km-refresh:after, .km-refresh:before {
  content: "";
}

.km-reply:after, .km-reply:before {
  content: "";
}

.km-rewind:after, .km-rewind:before {
  content: "";
}

.km-search:after, .km-search:before {
  content: "";
}

.km-settings:after, .km-settings:before {
  content: "";
}

.km-share:after, .km-share:before {
  content: "";
}

.km-sounds:after, .km-volume:after, .km-sounds:before, .km-volume:before {
  content: "";
}

.km-stop:after, .km-stop:before {
  content: "";
}

.km-wifi:after, .km-wifi:before {
  content: "";
}

.km-drawer-icon:after, .km-drawer-icon:before, .km-hamburger:before, .km-hamburger:after {
  content: "";
}

.km-root .km-pane .km-icon.km-check {
  -webkit-background-clip: initial;
}

.km-root .km-pane .km-check:checked:after, .km-widget .km-check:checked:after {
  content: "";
}

.km-android .km-more:after, .km-android .km-more:before {
  content: "";
}

.km-meego .km-more:after, .km-meego .km-more:before {
  content: "";
}

.km-wp .km-loading:after, .km-wp .km-load-more .km-icon:after, .km-wp .km-scroller-refresh .km-icon:after, .km-meego .km-loading:after, .km-meego .km-load-more .km-icon:after, .km-meego .km-scroller-refresh .km-icon:after, .km-root .km-android .km-loading:after, .km-android .km-load-more .km-icon:after, .km-root .km-android .km-scroller-refresh .km-icon:after {
  content: "";
}

.km-scroller-pull .km-icon:after {
  content: "";
}

.km-icon.km-phone:after, .km-ios7 .km-state-active .km-phone:after {
  content: "";
}

.km-ios7 .km-detaildisclose:after {
  content: "i";
}

.km-ios7 .km-action:after {
  content: "";
}

.km-ios7 .km-add:after {
  content: "";
}

.km-ios7 .km-mostrecent:after {
  content: "";
}

.km-ios7 .km-battery:after {
  content: "";
}

.km-ios7 .km-bookmarks:after {
  content: "";
}

.km-ios7 .km-camera:after {
  content: "";
}

.km-ios7 .km-cart:after {
  content: "";
}

.km-ios7 .km-recents:after {
  content: "";
}

.km-ios7 .km-download:after, .km-ios7 .km-downloads:after {
  content: "";
}

.km-ios7 .km-edit:after {
  content: "";
}

.km-ios7 .km-mostviewed:after {
  content: "";
}

.km-ios7 .km-toprated:after, .km-ios7 .km-favorites:after {
  content: "";
}

.km-ios7 .km-featured:after {
  content: "";
}

.km-ios7 .km-fastforward:after {
  content: "";
}

.km-ios7 .km-settings:after {
  content: "";
}

.km-ios7 .km-globe:after {
  content: "";
}

.km-ios7 .km-home:after {
  content: "";
}

.km-ios7 .km-info:after, .km-ios7 .km-about:after {
  content: "";
}

.km-ios7 .km-share:after {
  content: "";
}

.km-ios7 .km-more:after {
  content: "";
}

.km-ios7 .km-details:after {
  content: "";
}

.km-ios7 .km-pause:after {
  content: "";
}

.km-ios7 .km-contacts:after {
  content: "";
}

.km-ios7 .km-play:after {
  content: "";
}

.km-ios7 .km-refresh:after {
  content: "";
}

.km-ios7 .km-history:after {
  content: "";
}

.km-ios7 .km-rewind:after {
  content: "";
}

.km-ios7 .km-search:after {
  content: "";
}

.km-ios7 .km-stop:after {
  content: "";
}

.km-ios7 .km-organize:after {
  content: "";
}

.km-ios7 .km-trash:after, .km-ios7 .km-delete:after {
  content: "";
}

.km-ios7 .km-reply:after {
  content: "";
}

.km-ios7 .km-forward:after {
  content: "";
}

.km-ios7 .km-sounds:after, .km-ios7 .km-volume:after {
  content: "";
}

.km-ios7 .km-wifi:after {
  content: "";
}

.km-ios7 .km-phone:after {
  content: "";
}

.km-ios7 .km-state-active .km-action:after {
  content: "";
}

.km-ios7 .km-state-active .km-add:after {
  content: "";
}

.km-ios7 .km-state-active .km-mostrecent:after {
  content: "";
}

.km-ios7 .km-state-active .km-battery:after {
  content: "";
}

.km-ios7 .km-state-active .km-bookmarks:after {
  content: "";
}

.km-ios7 .km-state-active .km-camera:after {
  content: "";
}

.km-ios7 .km-state-active .km-cart:after {
  content: "";
}

.km-ios7 .km-state-active .km-recents:after {
  content: "";
}

.km-ios7 .km-state-active .km-download:after, .km-ios7 .km-state-active .km-downloads:after {
  content: "";
}

.km-ios7 .km-state-active .km-edit:after {
  content: "";
}

.km-ios7 .km-state-active .km-mostviewed:after {
  content: "";
}

.km-ios7 .km-state-active .km-toprated:after, .km-ios7 .km-state-active .km-favorites:after {
  content: "";
}

.km-ios7 .km-state-active .km-featured:after {
  content: "";
}

.km-ios7 .km-state-active .km-fastforward:after {
  content: "";
}

.km-ios7 .km-state-active .km-settings:after {
  content: "";
}

.km-ios7 .km-state-active .km-globe:after {
  content: "";
}

.km-ios7 .km-state-active .km-home:after {
  content: "";
}

.km-ios7 .km-state-active .km-info:after, .km-ios7 .km-state-active .km-about:after {
  content: "";
}

.km-ios7 .km-state-active .km-share:after {
  content: "";
}

.km-ios7 .km-state-active .km-more:after {
  content: "";
}

.km-ios7 .km-state-active .km-details:after {
  content: "";
}

.km-ios7 .km-state-active .km-pause:after {
  content: "";
}

.km-ios7 .km-state-active .km-contacts:after {
  content: "";
}

.km-ios7 .km-state-active .km-play:after {
  content: "";
}

.km-ios7 .km-state-active .km-refresh:after {
  content: "";
}

.km-ios7 .km-state-active .km-history:after {
  content: "";
}

.km-ios7 .km-state-active .km-rewind:after {
  content: "";
}

.km-ios7 .km-state-active .km-search:after {
  content: "";
}

.km-ios7 .km-state-active .km-stop:after {
  content: "";
}

.km-ios7 .km-state-active .km-organize:after {
  content: "";
}

.km-ios7 .km-state-active .km-trash:after, .km-ios7 .km-state-active .km-delete:after {
  content: "";
}

.km-ios7 .km-state-active .km-reply:after {
  content: "";
}

.km-ios7 .km-state-active .km-forward:after {
  content: "";
}

.km-ios7 .km-state-active .km-sounds:after, .km-ios7 .km-state-active .km-volume:after {
  content: "";
}

.km-ios7 .km-state-active .km-wifi:after {
  content: "";
}

.km-arrowdown:after, .km-arrowdown:before {
  content: "";
}

.km-wp .km-scroller-pull .km-icon:after {
  content: "";
}

.km-on-wp.km-app .km-icon:after, .km-on-wp.km-app .km-filter-wrap:before, .km-on-wp.km-app .km-state-active .km-icon:after {
  color: #0000;
  vertical-align: middle;
  background-image: url("wp8_icons.76e4ceff.png");
  background-size: auto 100%;
  height: 1em;
  margin-top: 0;
}

.km-wp-light.km-app .km-icon:after, .km-wp-light.km-app .km-filter-wrap:before {
  background-image: url("wp8_inverseicons.147e55ba.png");
}

.km-on-wp.km-app .km-icon {
  line-height: 1em;
}

.km-on-wp.km-app .km-icon:before {
  display: none;
}

.km-on-wp.km-app .km-action:after {
  background-position-x: 20%;
}

.km-on-wp.km-app .km-add:after, .km-on-wp.km-app .km-filter-reset .km-clear:after {
  background-position-x: 22%;
}

.km-on-wp.km-app .km-battery:after {
  background-position-x: 24%;
}

.km-on-wp.km-app .km-bookmarks:after {
  background-position-x: 26%;
}

.km-on-wp.km-app .km-camera:after {
  background-position-x: 28%;
}

.km-on-wp.km-app .km-cart:after {
  background-position-x: 30%;
}

.km-on-wp.km-app .km-edit:after, .km-on-wp.km-app .km-compose:after {
  background-position-x: 32%;
}

.km-on-wp.km-app .km-contacts:after {
  background-position-x: 34%;
}

.km-on-wp.km-app .km-trash:after, .km-on-wp.km-app .km-delete:after {
  background-position-x: 36%;
}

.km-on-wp.km-app .km-details:after {
  background-position-x: 38%;
}

.km-on-wp.km-app .km-download:after, .km-on-wp.km-app .km-downloads:after {
  background-position-x: 40%;
}

.km-on-wp.km-app .km-fastforward:after {
  background-position-x: 42%;
}

.km-on-wp.km-app .km-toprated:after, .km-on-wp.km-app .km-favorites:after {
  background-position-x: 44%;
}

.km-on-wp.km-app .km-featured:after {
  background-position-x: 46%;
}

.km-on-wp.km-app .km-globe:after {
  background-position-x: 48%;
}

.km-on-wp.km-app .km-history:after {
  background-position-x: 50%;
}

.km-on-wp.km-app .km-home:after {
  background-position-x: 52%;
}

.km-on-wp.km-app .km-info:after, .km-on-wp.km-app .km-about:after {
  background-position-x: 54%;
}

.km-on-wp.km-app .km-more:after {
  background-position-x: 56%;
}

.km-on-wp.km-app .km-mostrecent:after {
  background-position-x: 58%;
}

.km-on-wp.km-app .km-mostviewed:after {
  background-position-x: 60%;
}

.km-on-wp.km-app .km-organize:after {
  background-position-x: 62%;
}

.km-on-wp.km-app .km-pause:after {
  background-position-x: 64%;
}

.km-on-wp.km-app .km-play:after {
  background-position-x: 66%;
}

.km-on-wp.km-app .km-recents:after {
  background-position-x: 68%;
}

.km-on-wp.km-app .km-refresh:after {
  background-position-x: 70%;
}

.km-on-wp.km-app .km-reply:after {
  background-position-x: 72%;
}

.km-on-wp.km-app .km-rewind:after {
  background-position-x: 74%;
}

.km-on-wp.km-app .km-search:after, .km-on-wp.km-app .km-filter-wrap:before {
  background-position-x: 76%;
}

.km-on-wp.km-app .km-settings:after {
  background-position-x: 78%;
}

.km-on-wp.km-app .km-share:after {
  background-position-x: 80%;
}

.km-on-wp.km-app .km-sounds:after, .km-on-wp.km-app .km-volume:after {
  background-position-x: 82%;
}

.km-on-wp.km-app .km-stop:after {
  background-position-x: 84%;
}

.km-on-wp.km-app .km-wifi:after {
  background-position-x: 86%;
}

.km-on-wp.km-app.km-android .km-more:after {
  background-position-x: 88%;
}

.km-on-wp.km-app.km-meego .km-more:after {
  background-position-x: 90%;
}

.km-on-wp.km-app.km-meego .km-loading:after, .km-on-wp.km-app.km-meego .km-load-more .km-icon:after, .km-on-wp.km-app.km-meego .km-scroller-refresh .km-icon:after {
  background-position-x: 94%;
}

.km-on-wp.km-app .km-scroller-pull .km-icon:after {
  background-position-x: 100%;
}

.km-on-wp.km-app .km-filter-wrap:before {
  content: " ";
  display: inline-block;
}

.km-on-wp.km-app .km-filter-reset .km-clear:after {
  transform: rotate(45deg);
}

.km-list .km-icon-label.k-i-arrow-e:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrow-n:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrow-s:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrow-w:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrow-ne:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrow-nw:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrow-se:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrow-sw:before {
  content: "";
}

.km-list .km-icon-label.k-i-seek-e:before {
  content: "";
}

.km-list .km-icon-label.k-i-seek-n:before {
  content: "";
}

.km-list .km-icon-label.k-i-seek-s:before {
  content: "";
}

.km-list .km-icon-label.k-i-seek-w:before {
  content: "";
}

.km-list .km-icon-label.k-si-arrow-e:before, .km-list .km-icon-label.k-i-sarrow-e:before {
  content: "";
}

.km-list .km-icon-label.k-si-arrow-n:before, .km-list .km-icon-label.k-i-sarrow-n:before {
  content: "";
}

.km-list .km-icon-label.k-si-arrow-s:before, .km-list .km-icon-label.k-i-sarrow-s:before {
  content: "";
}

.km-list .km-icon-label.k-si-arrow-w:before, .km-list .km-icon-label.k-i-sarrow-w:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrowhead-e:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrowhead-n:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrowhead-s:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrowhead-w:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrowhead-ew:before {
  content: "";
}

.km-list .km-icon-label.k-i-arrowhead-ns:before {
  content: "";
}

.km-list .km-icon-label.k-i-move:before {
  content: "";
}

.km-list .km-icon-label.k-i-resize:before {
  content: "";
}

.km-list .km-icon-label.k-i-resize-45:before {
  content: "";
}

.km-list .km-icon-label.k-i-resize-135:before {
  content: "";
}

.km-list .km-icon-label.k-i-resize-h:before {
  content: "";
}

.km-list .km-icon-label.k-i-resize-v:before {
  content: "";
}

.km-list .km-icon-label.k-i-refresh:before {
  content: "";
}

.km-list .km-icon-label.k-i-refresh-rev:before {
  content: "";
}

.km-list .km-icon-label.k-si-refresh:before, .km-list .km-icon-label.k-i-srefresh:before {
  content: "";
}

.km-list .km-icon-label.k-si-refresh-rev:before, .km-list .km-icon-label.k-s-srefresh-rev:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-en:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-es:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-ne:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-nw:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-se:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-sw:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-wn:before {
  content: "";
}

.km-list .km-icon-label.k-i-turn-ws:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-e:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-e-rev:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-n:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-n-rev:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-s:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-s-rev:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-w:before {
  content: "";
}

.km-list .km-icon-label.k-i-uturn-w-rev:before {
  content: "";
}

.km-list .km-icon-label.k-i-tick-sign:before {
  content: "";
}

.km-list .km-icon-label.k-i-close-sign:before {
  content: "";
}

.km-list .km-icon-label.k-i-plus-sign:before {
  content: "";
}

.km-list .km-icon-label.k-i-minus-sign:before {
  content: "";
}

.km-list .km-icon-label.k-si-plus-sign:before, .km-list .km-icon-label.k-i-splus-sign:before {
  content: "";
}

.km-list .km-icon-label.k-si-minus-sign:before, .km-list .km-icon-label.k-i-sminus-sign:before {
  content: "";
}

.km-list .km-icon-label.k-i-cancel:before {
  content: "";
}

.km-list .km-icon-label.k-i-tick:before {
  content: "";
}

.km-list .km-icon-label.k-i-close:before {
  content: "";
}

.km-list .km-icon-label.k-i-plus:before {
  content: "";
}

.km-list .km-icon-label.k-i-minus:before {
  content: "";
}

.km-list .km-icon-label.k-si-plus:before, .km-list .km-icon-label.k-i-splus:before {
  content: "";
}

.km-list .km-icon-label.k-si-minus:before, .km-list .km-icon-label.k-i-sminus:before {
  content: "";
}

.km-list .km-icon-label.k-i-stop:before {
  content: "";
}

.km-list .km-icon-label.k-i-pause:before {
  content: "";
}

.km-list .km-icon-label.k-i-eject:before {
  content: "";
}

.km-list .km-icon-label.k-i-volume-off:before {
  content: "";
}

.km-list .km-icon-label.k-i-volume-low:before {
  content: "";
}

.km-list .km-icon-label.k-i-volume-high:before {
  content: "";
}

.km-list .km-icon-label.k-i-earphones:before {
  content: "";
}

.km-list .km-icon-label.k-i-heart:before {
  content: "";
}

.km-list .km-icon-label.k-i-heart-empty:before {
  content: "";
}

.km-list .km-icon-label.k-i-heart-half:before {
  content: "";
}

.km-list .km-icon-label.k-i-star-empty:before {
  content: "";
}

.km-list .km-icon-label.k-i-star-half:before {
  content: "";
}

.km-list .km-icon-label.k-i-star-half-empty:before {
  content: "";
}

.km-list .km-icon-label.k-i-chart-column:before {
  content: "";
}

.km-list .km-icon-label.k-i-chart-bar:before {
  content: "";
}

.km-list .km-icon-label.k-i-chart-pie:before {
  content: "";
}

.km-list .km-icon-label.k-i-chart-line:before {
  content: "";
}

.km-list .km-icon-label.k-i-chart-area:before {
  content: "";
}

.km-list .km-icon-label.k-i-chart-donut:before {
  content: "";
}

.km-list .km-icon-label.k-i-align-left:before {
  content: "";
}

.km-list .km-icon-label.k-i-align-center:before {
  content: "";
}

.km-list .km-icon-label.k-i-align-right:before {
  content: "";
}

.km-list .km-icon-label.k-i-align-justify:before {
  content: "";
}

.km-list .km-icon-label.k-i-align-clear:before {
  content: "";
}

.km-list .km-icon-label.k-i-bold:before {
  content: "";
}

.km-list .km-icon-label.k-i-bold-sans:before {
  content: "";
}

.km-list .km-icon-label.k-i-italic:before {
  content: "";
}

.km-list .km-icon-label.k-i-italic-sans:before {
  content: "";
}

.km-list .km-icon-label.k-i-underline:before {
  content: "";
}

.km-list .km-icon-label.k-i-underline-sans:before {
  content: "";
}

.km-list .km-icon-label.k-i-strikethrough:before {
  content: "";
}

.km-list .km-icon-label.k-i-strikethrough-sans:before {
  content: "";
}

.km-list .km-icon-label.k-i-font-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-font-a-sans:before {
  content: "";
}

.km-list .km-icon-label.k-i-font-t:before {
  content: "";
}

.km-list .km-icon-label.k-i-font-t-sans:before {
  content: "";
}

.km-list .km-icon-label.k-i-brightness:before {
  content: "";
}

.km-list .km-icon-label.k-i-brightness-contrast:before {
  content: "";
}

.km-list .km-icon-label.k-i-contrast:before {
  content: "";
}

.km-list .km-icon-label.k-i-crop:before {
  content: "";
}

.km-list .km-icon-label.k-i-mirror:before {
  content: "";
}

.km-list .km-icon-label.k-i-flip-h:before {
  content: "";
}

.km-list .km-icon-label.k-i-flip-v:before {
  content: "";
}

.km-list .km-icon-label.k-i-rotate:before {
  content: "";
}

.km-list .km-icon-label.k-i-rotate-rev:before {
  content: "";
}

.km-list .km-icon-label.k-i-filter:before {
  content: "";
}

.km-list .km-icon-label.k-i-filter-clear:before {
  content: "";
}

.km-list .km-icon-label.k-i-sort-asc:before {
  content: "";
}

.km-list .km-icon-label.k-i-sort-desc:before {
  content: "";
}

.km-list .km-icon-label.k-i-sort-clear:before {
  content: "";
}

.km-list .km-icon-label.k-i-indent:before {
  content: "";
}

.km-list .km-icon-label.k-i-outdent:before {
  content: "";
}

.km-list .km-icon-label.k-i-hyperlink:before {
  content: "";
}

.km-list .km-icon-label.k-i-hyperlink-clear:before {
  content: "";
}

.km-list .km-icon-label.k-i-hyperlink-ext:before {
  content: "";
}

.km-list .km-icon-label.k-si-hyperlink-ext:before {
  content: "";
}

.km-list .km-icon-label.k-i-ul:before {
  content: "";
}

.km-list .km-icon-label.k-si-ul:before {
  content: "";
}

.km-list .km-icon-label.k-i-paint:before {
  content: "";
}

.km-list .km-icon-label.k-i-paste:before {
  content: "";
}

.km-list .km-icon-label.k-i-pencil:before {
  content: "";
}

.km-list .km-icon-label.k-i-image:before {
  content: "";
}

.km-list .km-icon-label.k-i-image-add:before {
  content: "";
}

.km-list .km-icon-label.k-i-print:before {
  content: "";
}

.km-list .km-icon-label.k-i-zoom:before {
  content: "";
}

.km-list .km-icon-label.k-i-zoom-in:before {
  content: "";
}

.km-list .km-icon-label.k-i-zoom-out:before {
  content: "";
}

.km-list .km-icon-label.k-i-asterisk:before {
  content: "";
}

.km-list .km-icon-label.k-i-clip:before {
  content: "";
}

.km-list .km-icon-label.k-i-clip-45:before {
  content: "";
}

.km-list .km-icon-label.k-i-qrcode:before {
  content: "";
}

.km-list .km-icon-label.k-i-book:before {
  content: "";
}

.km-list .km-icon-label.k-i-bookmark:before {
  content: "";
}

.km-list .km-icon-label.k-i-briefcase:before {
  content: "";
}

.km-list .km-icon-label.k-i-calendar:before {
  content: "";
}

.km-list .km-icon-label.k-i-camera-still:before {
  content: "";
}

.km-list .km-icon-label.k-i-camera-video:before {
  content: "";
}

.km-list .km-icon-label.k-i-certificate:before {
  content: "";
}

.km-list .km-icon-label.k-i-clock:before {
  content: "";
}

.km-list .km-icon-label.k-i-cloud:before {
  content: "";
}

.km-list .km-icon-label.k-i-collapse:before {
  content: "";
}

.km-list .km-icon-label.k-i-columns:before {
  content: "";
}

.km-list .km-icon-label.k-i-comment:before {
  content: "";
}

.km-list .km-icon-label.k-i-comment-empty:before {
  content: "";
}

.km-list .km-icon-label.k-i-comments:before {
  content: "";
}

.km-list .km-icon-label.k-i-comments-empty:before {
  content: "";
}

.km-list .km-icon-label.k-i-credit-card:before {
  content: "";
}

.km-list .km-icon-label.k-i-download:before {
  content: "";
}

.km-list .km-icon-label.k-i-draghandle:before {
  content: "";
}

.km-list .km-icon-label.k-si-draghandle:before {
  content: "";
}

.km-list .km-icon-label.k-i-envelop:before {
  content: "";
}

.km-list .km-icon-label.k-i-envelop-open:before {
  content: "";
}

.km-list .km-icon-label.k-i-eye:before {
  content: "";
}

.km-list .km-icon-label.k-i-file:before {
  content: "";
}

.km-list .km-icon-label.k-i-file-add:before {
  content: "";
}

.km-list .km-icon-label.k-i-film:before {
  content: "";
}

.km-list .km-icon-label.k-i-flag:before {
  content: "";
}

.km-list .km-icon-label.k-i-folder-add:before {
  content: "";
}

.km-list .km-icon-label.k-i-folder:before {
  content: "";
}

.km-list .km-icon-label.k-i-folder-open:before {
  content: "";
}

.km-list .km-icon-label.k-i-folder-up:before {
  content: "";
}

.km-list .km-icon-label.k-i-gear:before {
  content: "";
}

.km-list .km-icon-label.k-si-gear:before {
  content: "";
}

.km-list .km-icon-label.k-i-transmit:before {
  content: "";
}

.km-list .km-icon-label.k-i-beer:before {
  content: "";
}

.km-list .km-icon-label.k-i-cocktail:before {
  content: "";
}

.km-list .km-icon-label.k-i-coffee:before {
  content: "";
}

.km-list .km-icon-label.k-i-wine:before {
  content: "";
}

.km-list .km-icon-label.k-i-grid:before {
  content: "";
}

.km-list .km-icon-label.k-i-thumbs:before {
  content: "";
}

.km-list .km-icon-label.k-i-split-h:before {
  content: "";
}

.km-list .km-icon-label.k-i-split-v:before {
  content: "";
}

.km-list .km-icon-label.k-i-home:before {
  content: "";
}

.km-list .km-icon-label.k-i-inbox:before {
  content: "";
}

.km-list .km-icon-label.k-i-key:before {
  content: "";
}

.km-list .km-icon-label.k-i-login:before {
  content: "";
}

.km-list .km-icon-label.k-i-logout:before {
  content: "";
}

.km-list .km-icon-label.k-i-place:before {
  content: "";
}

.km-list .km-icon-label.k-i-megaphone:before {
  content: "";
}

.km-list .km-icon-label.k-i-note:before {
  content: "";
}

.km-list .km-icon-label.k-i-pin:before {
  content: "";
}

.km-list .km-icon-label.k-i-unpin:before {
  content: "";
}

.km-list .km-icon-label.k-i-power:before {
  content: "";
}

.km-list .km-icon-label.k-i-progress-bars:before {
  content: "";
}

.km-list .km-icon-label.k-i-road:before {
  content: "";
}

.km-list .km-icon-label.k-i-rss:before {
  content: "";
}

.km-list .km-icon-label.k-i-floppy:before {
  content: "";
}

.km-list .km-icon-label.k-i-sitemap:before {
  content: "";
}

.km-list .km-icon-label.k-i-tag-45:before {
  content: "";
}

.km-list .km-icon-label.k-i-tag-h:before {
  content: "";
}

.km-list .km-icon-label.k-i-thunderbolt:before {
  content: "";
}

.km-list .km-icon-label.k-i-tooltip:before {
  content: "";
}

.km-list .km-icon-label.k-i-trash:before {
  content: "";
}

.km-list .km-icon-label.k-i-trophy:before {
  content: "";
}

.km-list .km-icon-label.k-i-ungroup:before {
  content: "";
}

.km-list .km-icon-label.k-i-upload:before {
  content: "";
}

.km-list .km-icon-label.k-i-window:before {
  content: "";
}

.km-list .km-icon-label.k-i-tiles:before {
  content: "";
}

.km-list .km-icon-label.k-i-wrench:before {
  content: "";
}

.km-list .km-icon-label.k-i-action:before {
  content: "";
}

.km-list .km-icon-label.k-i-add:before {
  content: "";
}

.km-list .km-icon-label.k-i-add-inv:before {
  content: "";
}

.km-list .km-icon-label.k-i-armchair:before {
  content: "";
}

.km-list .km-icon-label.k-i-battery:before {
  content: "";
}

.km-list .km-icon-label.k-i-book-open:before {
  content: "";
}

.km-list .km-icon-label.k-i-camera:before {
  content: "";
}

.km-list .km-icon-label.k-i-cart:before {
  content: "";
}

.km-list .km-icon-label.k-i-time:before {
  content: "";
}

.km-list .km-icon-label.k-i-coffee-card:before {
  content: "";
}

.km-list .km-icon-label.k-i-download-arrow:before {
  content: "";
}

.km-list .km-icon-label.k-i-edit:before {
  content: "";
}

.km-list .km-icon-label.k-i-faves:before {
  content: "";
}

.km-list .km-icon-label.k-i-star:before {
  content: "";
}

.km-list .km-icon-label.k-i-featured:before {
  content: "";
}

.km-list .km-icon-label.k-i-forward:before {
  content: "";
}

.km-list .km-icon-label.k-i-cog:before {
  content: "";
}

.km-list .km-icon-label.k-i-globe:before {
  content: "";
}

.km-list .km-icon-label.k-i-globe-inv:before {
  content: "";
}

.km-list .km-icon-label.k-i-house:before {
  content: "";
}

.km-list .km-icon-label.k-i-share:before {
  content: "";
}

.km-list .km-icon-label.k-i-more-h:before {
  content: "";
}

.km-list .km-icon-label.k-i-more-v:before {
  content: "";
}

.km-list .km-icon-label.k-i-next:before {
  content: "";
}

.km-list .km-icon-label.k-i-pause-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-user:before {
  content: "";
}

.km-list .km-icon-label.k-i-play-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-refresh-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-reset:before {
  content: "";
}

.km-list .km-icon-label.k-i-rewind:before {
  content: "";
}

.km-list .km-icon-label.k-i-search-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-stop-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-tiles-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-trash-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-undo:before {
  content: "";
}

.km-list .km-icon-label.k-i-redo:before {
  content: "";
}

.km-list .km-icon-label.k-i-volume-a:before {
  content: "";
}

.km-list .km-icon-label.k-i-wifi:before {
  content: "";
}

.km-list .km-icon-label.k-i-more-lines:before {
  content: "";
}

.km-list .km-icon-label.k-i-pull-to-ref:before {
  content: "";
}

.km-list .km-icon-label.k-i-loading-android:before {
  content: "";
}

.km-list .km-icon-label.k-i-loading-blackberry:before {
  content: "";
}

.km-list .km-icon-label.k-i-loading-meego:before {
  content: "";
}

.km-list .km-icon-label.k-i-loading-custom:before {
  content: "";
}

.km-list .km-icon-label.k-i-loading-ios:before {
  content: "";
}

.km-list .km-icon-label.k-i-bug:before {
  content: "";
}

.km-list .km-icon-label.k-i-info:before {
  content: "";
}

.km-list .km-icon-label.k-i-warning:before {
  content: "";
}

.km-list .km-icon-label.k-i-question:before {
  content: "";
}

.km-list .km-icon-label.k-i-insert-n:before {
  content: "";
}

.km-list .km-icon-label.k-i-insert-m:before {
  content: "";
}

.km-list .km-icon-label.k-i-insert-s:before {
  content: "";
}

.km-list .km-icon-label.k-i-lock:before {
  content: "";
}

.km-list .km-icon-label.k-i-unlock:before {
  content: "";
}

.km-list .km-icon-label.k-i-phone:before {
  content: "";
}

.km-list .km-icon-label.k-i-tablet:before {
  content: "";
}

.km-list .km-icon-label.k-i-ol:before {
  content: "";
}

.km-list .km-icon-label.k-i-barcode:before {
  content: "";
}

.km-list .km-icon-label.k-i-html5:before {
  content: "";
}

.km-list .km-icon-label.k-i-css3:before {
  content: "";
}

.km-list .km-icon-label.k-i-kendoui:before {
  content: "";
}

.km-list .km-icon-label.k-i-telerik:before {
  content: "";
}

.km-list .km-icon-label.k-i-icenium:before {
  content: "";
}

.km-list .km-icon-label.k-i-sitefinity:before {
  content: "";
}

.km-list .km-icon-label.k-i-twitter:before {
  content: "";
}

.km-list .km-icon-label.k-i-linkedin:before {
  content: "";
}

.km-list .km-icon-label.k-i-facebook:before {
  content: "";
}

.km-list .km-icon-label.k-i-pinterest:before {
  content: "";
}

.km-list .km-icon-label.k-i-youtube:before {
  content: "";
}

.km-list .km-icon-label.k-i-vimeo:before {
  content: "";
}

.km-list .km-icon-label.k-i-behance:before {
  content: "";
}

.km-list .km-icon-label.k-i-dribbble:before {
  content: "";
}

.km-list .km-icon-label.k-i-googleplus:before {
  content: "";
}

.km-list .km-icon-label.k-i-minimize:before {
  content: "";
}

.km-list .km-icon-label.k-i-html:before {
  content: "";
}

.km-list .km-icon-label.k-i-group:before {
  content: "";
}

.km-list .km-icon-label.k-i-subscript:before {
  content: "";
}

.km-list .km-icon-label.k-i-superscript:before {
  content: "";
}

.km-list .km-icon-label.k-i-drophere:before {
  content: "";
}

.km-button {
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: .3em .8em;
}

.km-button:hover {
  text-decoration: none;
}

.km-badge {
  border: 0;
  border-radius: 5em;
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
}

.km-detail {
  border-style: solid;
  border-width: 1px;
  border-radius: 5em;
  min-width: 1.4em;
  height: 1.4em;
  line-height: 1.4em;
}

.km-detaildisclose {
  min-width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
}

.km-detaildisclose:after, .km-detaildisclose:before {
  top: .35em;
  left: .1em;
}

.km-detail .km-icon:before {
  display: none;
}

.km-buttongroup {
  vertical-align: middle;
  background: none;
  margin: 0;
  padding: 0;
}

.km-buttongroup > * {
  padding: .36em .8em;
}

.km-buttongroup > :not(:first-child):not(:last-child) {
  border-radius: 0;
}

.km-widget.km-buttongroup > :last-child, .km-widget.km-buttongroup > .km-state-active {
  border-right-width: 1px;
}

.km-widget.km-buttongroup > .km-state-active + * {
  border-left-width: 0;
}

.km-navbar {
  border-style: solid;
  border-width: 1px;
}

.km-header .km-navbar {
  border-width: 0 0 1px;
}

.km-footer .km-navbar {
  border-width: 1px 0 0;
}

.km-tabstrip {
  table-layout: fixed;
  padding: 0;
  display: table;
}

.km-tabstrip .km-button {
  border: 0;
  font-size: .7em;
  display: table-cell;
}

.km-tabstrip .km-icon:before {
  display: none;
}

.km-switch {
  vertical-align: middle;
  width: 4.2em;
  height: 1.9em;
  line-height: 1.76em;
  overflow: hidden;
}

.km-switch-wrapper {
  overflow: hidden;
}

.km-switch-background {
  background: currentColor;
  width: 118%;
  margin-left: 0;
  position: relative;
  left: -95%;
}

.km-switch .km-switch-background {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.km-switch-container {
  border-width: 0;
  padding: 1px 0 1px 1px;
}

.km-switch-handle {
  background-clip: padding-box;
  border: 1px solid #0000;
  width: 1.6em;
  margin: 0 4px 0 0;
  box-shadow: 0 1px 0 1px, 0 -1px 0 1px;
}

.km-switch-label-off {
  left: 101%;
}

.km-switch-label-on {
  left: -171%;
}

.km-switch-label-on, .km-switch-label-off {
  text-shadow: none;
  vertical-align: middle;
  width: 170%;
  font-size: 1em;
  line-height: 1.76em;
  top: -2px;
}

.km-switch-container, .km-switch-wrapper {
  box-sizing: border-box;
  background-clip: content-box;
  border-style: solid;
  border-width: 1px;
}

.km-list > li {
  border-style: solid;
  border-width: 0 0 1px;
}

.km-listinset > li:first-child, .km-listgroupinset .km-list > li:first-child {
  border-style: solid;
  border-width: 1px;
}

.km-listinset > li, .km-listgroupinset .km-list > li {
  border-width: 0 1px 1px;
}

.km-listinset > li:last-child, .km-listgroupinset .km-list > li:last-child {
  border-style: solid;
  border-width: 0 1px 1px;
}

.km-listinset > li:first-child:last-child, .km-listgroupinset .km-list > li:first-child:last-child {
  border-width: 1px;
}

.km-listview-link:after {
  border-width: .2rem .2rem 0 0;
  border-color: currentColor;
}

.km-pane:not(.km-on-android) .km-listview-link:after {
  border-width: 0;
  width: .66rem;
  height: .64rem;
  box-shadow: inset -.2rem .2rem;
}

.km-listinset li:first-child > .km-listview-link, .km-listgroupinset li:first-child > .km-listview-link, .km-listinset li:first-child > .km-listview-label, .km-listgroupinset li:first-child > .km-listview-label {
  border-radius: 2px 2px 0 0;
}

.km-listinset li:last-child > .km-listview-link, .km-listgroupinset li:last-child > .km-listview-link, .km-listinset li:last-child > .km-listview-label, .km-listgroupinset li:last-child > .km-listview-label {
  border-radius: 0 0 2px 2px;
}

.km-listinset li:first-child:last-child > .km-listview-link, .km-listgroupinset li:first-child:last-child > .km-listview-link, .km-listinset li:first-child:last-child > .km-listview-label, .km-listgroupinset li:first-child:last-child > .km-listview-label {
  border-radius: 2px;
}

.km-group-title {
  border-style: solid;
  border-width: 1px 0;
}

.km-scroll-header .km-group-title {
  border-width: 0 0 1px;
}

.km-listgroupinset .km-group-title {
  background: none;
  border: 0;
}

.km-listview .km-switch {
  margin-top: -.9rem;
}

.km-listview-wrapper form .km-filter-wrap > input {
  padding: .3em 1.8em;
  font-size: 1.2em;
}

.km-widget .km-filter-wrap:before {
  margin: .05em -1.2em 0 .2em;
}

.km-filter-reset {
  margin: .05em 0 0 -2em;
}

.km-filter-reset .km-clear:after {
  content: "";
}

.km-filter-wrap > input {
  border-style: solid;
  border-width: 1px;
}

.km-load-more {
  height: 3.4em;
}

.km-load-more .km-button {
  margin: 0 .8em;
  display: block;
}

.km-scroller-refresh.km-load-more {
  margin: auto;
  padding: 0;
  position: relative;
}

.km-scroller-refresh.km-load-more, .km-scroller-pull {
  white-space: nowrap;
  width: 2.5em;
  height: 2.5em;
  font-size: 1em;
  top: .25em;
}

.km-scroller-pull {
  margin: 0 0 0 -90px;
  left: 50%;
}

.km-scroller-pull .km-template {
  min-width: 0;
  font-size: 1.2em;
  line-height: 2em;
  position: absolute;
  top: 0;
  left: 3em;
}

.km-scroller-pull .km-icon {
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.km-scroller-pull .km-icon:after {
  content: "";
  margin-left: -3px;
}

.km-scroller-refresh .km-icon:after {
  display: none;
}

.km-scroller-refresh .km-loading-left {
  margin-left: -.18em;
}

.km-scroller-refresh .km-loading-right {
  margin-left: .28em;
}

.km-pages {
  padding-top: .5em;
}

.km-pages li {
  border-radius: 1em;
}

.km-list input[type="password"], .km-list input[type="search"], .km-list input[type="number"], .km-list input[type="tel"], .km-list input[type="url"], .km-list input[type="email"], .km-list input[type="month"], .km-list input[type="color"], .km-list input[type="week"], .km-list input[type="date"], .km-list input[type="time"], .km-list input[type="datetime"], .km-list input[type="datetime-local"], .km-list input[type="text"]:not(.k-input), .km-list select:not([multiple]), .km-list .k-dropdown-wrap, .km-list textarea {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  outline: none;
  min-width: 6em;
  padding: .4em;
  font-size: 1.1rem;
}

.km-list .k-dropdown-wrap {
  padding: .2em;
}

.km-list .k-dropdown {
  margin-top: -1.05em;
  font-weight: normal;
}

.km-list input[type="color"], .km-list input[type="week"], .km-list input[type="date"], .km-list input[type="time"], .km-list input[type="month"], .km-list input[type="datetime"], .km-list input[type="datetime-local"], .km-list .k-dropdown {
  text-align: left;
}

.km-list .k-dropdown .k-dropdown-wrap {
  box-shadow: none;
  background: none;
  border-radius: 0;
  display: block;
}

.km-listview-label input[type="checkbox"] {
  margin-top: -.7em;
}

.km-listview-label input[type="radio"], .km-listview-label input[type="checkbox"] {
  border-style: solid;
  border-width: 1px;
  width: 1.4em;
  height: 1.4em;
}

.km-listview-label input[type="radio"] {
  border-radius: 1em;
  width: 1.2em;
  height: 1.2em;
}

.km-listview-label input[type="checkbox"]:after {
  content: " ";
  transform-origin: 10%;
  width: 90%;
  height: 76%;
  display: block;
  transform: scale(.9, 1);
}

.km-listview-label input[type="checkbox"]:checked:after {
  font-size: 1.4em;
}

.km-listview-label input[type="radio"]:after {
  color: #0000;
}

.km-actionsheet > li > a {
  text-align: center;
  font-size: 1.4em;
  font-weight: normal;
}

.km-actionsheet {
  max-height: 80%;
}

.km-actionsheet > li > a {
  display: block;
}

.km-actionsheet > li:last-child > a {
  border: 0;
}

.km-shim li.km-actionsheet-title, .km-popup li.km-actionsheet-title {
  display: none;
}

.km-popup-wrapper {
  padding: 15px;
}

.km-popover-root .km-popup {
  border: 5px solid #0000;
}

.km-popover-root .km-popup .km-view, .km-popover-root .km-popup .km-navbar, .km-popover-root .km-popup .km-toolbar, .km-popover-root .km-popup .km-tabstrip {
  background: none;
}

.km-popover-root .km-popup .km-header {
  -webkit-margin-collapse: separate;
  margin: -5px -5px 2px;
  padding: 0 5px;
}

.km-popup-arrow:after, .km-popup-arrow:before {
  border-style: solid;
  border-width: 0 15px 15px;
}

.km-down .km-popup-arrow:before {
  margin-top: -1px;
}

.km-up .km-popup-arrow:after, .km-up .km-popup-arrow:before {
  border-width: 15px 15px 0;
}

.km-left .km-popup-arrow:after, .km-left .km-popup-arrow:before {
  border-width: 15px 0 15px 15px;
}

.km-right .km-popup-arrow:after, .km-right .km-popup-arrow:before {
  border-width: 15px 15px 15px 0;
}

.km-buttongroup > :first-child {
  border-radius: 3px 0 0 3px;
}

.km-buttongroup > :last-child {
  border-radius: 0 3px 3px 0;
}

.km-listinset > li:first-child, .km-popover-root .km-popup .km-header, .km-listgroupinset .km-list > li:first-child {
  border-radius: 3px 3px 0 0;
}

.km-listinset > li:last-child, .km-listgroupinset .km-list > li:last-child {
  border-radius: 0 0 3px 3px;
}

.km-button, .km-switch-handle, .km-filter-wrap > input, .km-popover-root .km-popup, .km-actionsheet-wrapper.km-popup, .km-listview-label input[type="radio"], .km-listview-label input[type="checkbox"], .km-listinset > li:first-child:last-child, .km-listgroupinset .km-list > li:first-child:last-child {
  border-radius: 3px;
}

.km-actionsheet-wrapper.km-popup {
  padding: 3px 0;
  box-shadow: 0 0 10px #0006;
}

.km-widget, .km-content, .km-popover-root .km-content .km-widget, .km-navbar {
  color: #4c5356;
  background: #f4f4f4;
}

.km-view {
  background: none;
}

.km-navbar {
  border-color: #4c535633;
}

.km-button {
  background: #f4f4f433;
  border-color: #4c535633;
}

.km-state-active[style*="background"] {
  box-shadow: inset 0 0 0 1000px #80808066;
}

.km-detail {
  color: #4c5356;
  background-color: #f4f4f4;
  border-color: #4c535633;
}

.km-detail:active, .km-state-active .km-detail {
  box-shadow: inset 0 0 0 1000px #f4f4f433;
}

.km-switch-wrapper {
  background-color: #bababa;
}

.km-switch-background {
  background-color: currentColor;
  background-image: none, linear-gradient(#bababa, #bababa);
  background-position: 4.3em 0;
  background-repeat: no-repeat;
}

.km-switch-handle {
  background: #f4f4f4;
}

.km-switch-off .km-switch-handle {
  box-shadow: 0 1px 0 1px #bababa, 0 -1px 0 1px #bababa;
}

.km-switch-wrapper, .km-switch-container, .km-switch-background {
  border-radius: 4px;
}

.km-switch-handle {
  border-radius: 3px;
}

.km-switch-container, .km-switch-wrapper {
  border-color: #dbdbdb;
}

.km-list > li {
  background: #e7e7e7;
  border-color: #4c535633;
  box-shadow: inset 0 1px #f4f4f433;
}

.km-listinset > li:first-child, .km-listgroupinset .km-list > li:first-child, .km-listinset > li:last-child, .km-listgroupinset .km-list > li:last-child {
  border-color: #4c535633;
}

.km-listview-link:after {
  color: #97a0a4;
}

.km-group-title {
  background: #f4f4f4;
  border-color: #4c535633;
}

.km-pages li {
  background: #4c535633;
}

.km-filter-wrap:before, .km-filter-reset .km-clear {
  color: #4c5356;
}

.km-filter-wrap > input {
  color: #4c5356;
  border-color: #4c535633;
}

.km-list input[type="password"], .km-list input[type="search"], .km-list input[type="number"], .km-list input[type="tel"], .km-list input[type="url"], .km-list input[type="email"], .km-list input[type="month"], .km-list input[type="color"], .km-list input[type="week"], .km-list input[type="date"], .km-list input[type="time"], .km-list input[type="datetime"], .km-list input[type="datetime-local"], .km-list input[type="text"]:not(.k-input), .km-list select:not([multiple]), .km-list .k-dropdown-wrap, .km-list textarea, .km-list .k-dropdown-wrap .k-input {
  color: #4c5356;
}

.km-list select:not([multiple]) option {
  color: #333;
}

.km-list .km-state-active input[type="password"], .km-list .km-state-active input[type="search"], .km-list .km-state-active input[type="number"], .km-list .km-state-active input[type="tel"], .km-list .km-state-active input[type="url"], .km-list .km-state-active input[type="email"], .km-list .km-state-active input[type="month"], .km-list .km-state-active input[type="color"], .km-list .km-state-active input[type="week"], .km-list .km-state-active input[type="date"], .km-list .km-state-active input[type="time"], .km-list .km-state-active input[type="datetime"], .km-list .km-state-active input[type="datetime-local"], .km-list .km-state-active input[type="text"]:not(.k-input), .km-list .km-state-active select:not([multiple]), .km-list .km-state-active textarea {
  color: #fff;
}

.km-listview-label input[type="radio"], .km-listview-label input[type="checkbox"] {
  background: #f4f4f4;
  border-color: #4c535633;
}

.km-listview-label input[type="checkbox"]:checked:after {
  color: #eee;
}

.km-shim {
  background: #0006;
}

.km-popup .k-item, .km-widget.km-actionsheet-wrapper, .km-widget.km-actionsheet > li > a {
  background: #333;
  border-bottom-color: #f4f4f433;
}

.km-actionsheet-cancel > a {
  color: #bababa;
  box-shadow: inset 0 0 300px #8080804d;
}

.km-popup {
  background: #0006;
}

.km-actionsheet-wrapper, .km-popup .k-list-container {
  background: #0006;
  border-top-color: #f4f4f433;
}

.km-popup.km-pane {
  background-color: #333;
  border-color: #333;
}

.km-popup-arrow:after, .km-popup-arrow:before {
  border-color: #00000080 #0000;
}

.km-popup-arrow:after {
  border-color: #333 #0000;
}

.km-left .km-popup-arrow:after, .km-right .km-popup-arrow:after {
  border-color: #0000 #333;
}

.km-down .km-popup-arrow:after {
  border-color: #333 #0000;
}

.km-loader {
  background: #0000000d;
}

.km-loader h1 {
  color: #4c5356;
}

.km-tabstrip {
  background: #333;
}

.km-badge, .km-rowinsert, .km-rowdelete, html .km-state-active, .km-switch-label-on, .km-switch-label-off, .km-tabstrip .km-button, .km-popup .k-item, .km-actionsheet > li > a, .km-tabstrip .km-state-active, .km-scroller-pull .km-icon, .km-popover-root .km-widget, .km-popup .k-state-hover, .km-popup .k-state-focused, .km-popup .k-state-selected, .km-actionsheet > li > a:active, .km-actionsheet > li > a:hover, li.km-state-active .km-listview-link, li.km-state-active .km-listview-label, .km-state-active .km-listview-link:after {
  color: #fff;
}

.km-loader > :not(h1), .km-filter-wrap > input, .km-root .km-scroller-refresh span:not(.km-template), .km-switch-handle.km-state-active {
  background-color: #fff;
}

.km-switch-handle, .km-switch-background {
  color: #7ea700;
}

.km-rowinsert, .km-state-active, .km-scroller-pull, .km-loader:before, .km-touch-scrollbar, .km-pages .km-current-page, .km-tabstrip .km-state-active, .km-scroller-refresh.km-load-more, .km-popup .k-state-hover, .km-popup .k-state-focused, .km-popup .k-state-selected, .km-actionsheet > li > a:active, .km-actionsheet > li > a:hover, li.km-state-active .km-listview-link, li.km-state-active .km-listview-label, .km-listview-label input[type="radio"]:checked, .km-listview-label input[type="checkbox"]:checked {
  background: #7ea700;
}

.km-filter-wrap > input:focus {
  border-color: #7ea700;
}

.km-badge, .km-rowdelete {
  background: #da2228;
}

/*# sourceMappingURL=styles.css.map */
