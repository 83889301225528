/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Grid

.k-grid {
    position: relative;
}

.k-grid table
{
    width: 100%;
    margin: 0; // override CSS libraries
    max-width: none;
    border-collapse: separate;
    border-spacing: 0;
    empty-cells: show;
    border-width: 0;
    outline: none;
}

.k-grid-header th.k-header,
.k-filter-row th
{
    overflow: hidden;
    border-style: solid;
    border-width: 0 0 1px 1px;
    padding: @grid-th-padding;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
}

.k-grid-header th.k-header {
    vertical-align: bottom;
    cursor: default;
}

.k-grid-header th.k-header .k-checkbox {
    vertical-align: top;
}

.k-filtercell,
.k-filtercell > span,
.k-filtercell .k-widget
{
    display: block;
    width: auto;
}

.k-filtercell > span
{
    padding-right: 4.8em;
    position: relative;
    min-height: 2em;
    line-height: 2em;
}

.k-filtercell > .k-operator-hidden
{
    padding-right: 2.3em;
}

.k-filtercell > span > .k-button,
.k-filter-row .k-dropdown-operator {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

.k-filter-row .k-dropdown-operator {
    width: 2.1em;
    right: 2.5em;
}

.k-filtercell > span > label
{
    vertical-align: middle;
}

.k-filter-row label > input[type="radio"]
{
    vertical-align: middle;
    position: relative;
    bottom: 2px;
}

.k-ff .k-grid .k-filter-row .k-i-filter-clear {
    top: 1px;
}

.k-ie10 .k-grid-header a:active
{
    background-color: transparent; // remove gray background
}

.k-grid-header th.k-header > .k-link
{
    display: block;
    min-height: 18px;
    line-height: 18px; // due to sorting icons
    margin: -.5em -.6em -.4em 0;
    padding: .5em .6em .4em 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.k-grid-header th.k-with-icon .k-link
{
    margin-right: 18px;
}

.k-grid-header th > .k-link > .k-icon
{
    vertical-align: text-top;
}

.k-grid .k-state-hover
{
    cursor: pointer;
}

.k-grid-column-resizing {
    &,
    .k-grid-filter,
    .k-link,
    .k-button,
    .k-grid-toolbar
    {
        cursor: col-resize;
    }
}

.k-grid td
{
    border-style: solid;
    border-width: 0 0 0 1px;
    padding: .4em .6em;
    overflow: hidden;
    line-height: 1.6em;
    vertical-align: middle;
    text-overflow: ellipsis;
}

.k-grid .k-grouping-row td,
.k-grid .k-hierarchy-cell
{
    overflow: visible;
}

.k-grid-edit-row td
{
    text-overflow: clip;
}

.k-grid-edit-row .k-textbox,
.k-grid-edit-row .text-box
{
    // reset default webkit styles
    margin-top:0;
    margin-bottom:0;
}

.k-grid-header-wrap,
.k-grid-footer-wrap
{
    position: relative;
    width: 100%;
    overflow: hidden;
    border-style: solid;
    border-width: 0 1px 0 0;
    zoom: 1;
}

div.k-grid-header,
div.k-grid-footer
{
    padding-right: 17px; // scrollbar width; may vary; can be calculated
    border-bottom-style: solid;
    border-bottom-width: 1px;
    zoom: 1;
}

.k-grid-header-wrap > table,
.k-grid-header-locked > table
{
    margin-bottom: -1px;
}

.k-grid-content
{
    position: relative;
    width: 100%;
    overflow: auto;
    overflow-x: auto;
    overflow-y: scroll;
    zoom: 1;
    min-height: 0%; // IE9 bug workaround - expanding Grid on hover
}

.k-mobile .k-grid tbody
{
    -webkit-backface-visibility: hidden;
}

.k-mobile .k-grid-backface tbody
{
    -webkit-backface-visibility: visible;
}

.k-grid-content-expander
{
    position: absolute;
    visibility: hidden;
    height: 1px;
}

.k-grid-norecords {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
}

.k-grid-norecords-template {
    width: 20em;
    height: 4em;
    line-height: 4em;
    vertical-align: middle;
    margin: 0 auto;
}

.k-grid-content > .k-grid-norecords > .k-grid-norecords-template {
    top: 50%;
    left: 50%;
    margin-left: -10em;
    margin-top: -2em;
    position: absolute;
}

@media print
{
    .k-grid
    {
        height: auto !important;
    }

    .k-grid-header
    {
        padding: 0 !important;
    }

    .k-grid-header-wrap,
    .k-grid-content
    {
        overflow: visible;
        height: auto !important;
    }
}

.k-grid .k-scrollbar
{
    -ms-overflow-style: scrollbar;
}

.k-virtual-scrollable-wrap
{
    height: 100%;
    overflow-y: hidden;
    position:relative;
}

.k-grid-header table,
.k-grid-content table,
.k-grid-footer table,
.k-grid-content-locked > table
{
    table-layout: fixed;
}

// Grid :: locked columns

.k-grid-lockedcolumns
{
    white-space: nowrap;
}

.k-grid-content-locked,
.k-grid-content,
.k-pager-wrap
{
    white-space: normal;
}

.k-grid-header-locked,
.k-grid-content-locked,
.k-grid-footer-locked
{
    display: inline-block;
    vertical-align: top;
    overflow: hidden;  // generally uneeded
    position: relative;
    border-style: solid;
    border-width: 0 1px 0 0;
}

.k-grid-header-locked + .k-grid-header-wrap,
.k-grid-content-locked + .k-grid-content,
.k-grid-footer-locked + .k-grid-footer-wrap
{
    display: inline-block;
    vertical-align: top;
}

.k-grid-toolbar {
    border-width: 0 0 1px;
    border-color: inherit;
    flex-shrink: 0;
}

.k-grid-header th.k-header:first-child,
.k-grid tbody td:first-child,
.k-grid tfoot td:first-child,
.k-filter-row > th:first-child
{
    border-left-width: 0;
}

.k-grid-header th.k-header.k-first
{
    border-left-width: 1px;
}

// Grid :: footer

.k-footer-template td
{
    border-style: solid;
    border-width: 1px 0 0 1px;
}

.k-group-footer td
{
    border-style: solid;
    border-width: 1px 0;
}

.k-group-footer .k-group-cell + td
{
    border-left-width: 1px;
}

.k-grid-footer
{
    border-style: solid;
    border-width: 1px 0 0;
}

.k-grid-footer td
{
    border-top-width: 0;
}

.k-grid-footer > td
{
    border-top-width: 1px;
}

// Grid :: paging
.k-grid-pager {
    border-width: 1px 0 0;
}
.k-grid-pager-top {
    border-width: 0 0 1px;
}

// Grid :: filtering

.k-header > .k-grid-filter,
.k-header > .k-header-column-menu
{
    float: right;
    margin: -.5em -.6em -.4em 0;
    padding: .5em .2em .4em;
    position: relative;
    z-index: 1; // mvc site.css
    color: inherit;
}

.k-grid .k-animation-container
{
    position: absolute;
}

.k-filter-menu
{
    padding: .5em;
}

.k-list-filter {
    display: block;
}

form.k-filter-menu .k-widget,
form.k-filter-menu .k-textbox
{
    display: block;
}

form.k-filter-menu .k-textbox
{
    width: 100%;
    margin-bottom: 3px;
}

.k-column-menu,
.k-filter-menu {
    font-size: 14px;
}

.k-filter-help-text,
.k-filter-menu .k-widget,
.k-filter-menu .k-textbox
{
    margin: .19em 0 0;
}

.k-filter-menu span.k-filter-and
{
    width: 6em;
    margin: .5em 0 .5em;
}

.k-filter-menu .k-action-buttons {
    padding: 0;
    border-width: 0;
    text-align: initial;
}

.k-filter-menu .k-action-buttons .k-button
{
    width:48%;
    margin: .5em 4% 0 0;
    min-width: 0;
}

.k-filter-menu .k-action-buttons .k-button+.k-button
{
    margin-right: 0;
}

.k-filter-menu .k-filter-selected-items {
    font-weight: bold;
    margin: .5em;
}

.k-multicheck-wrap
{
    overflow: auto;
    white-space: nowrap;
    max-height: 300px;
}

.k-multicheck-wrap .k-item {
    line-height: 2.2em;
}

// Grid :: grouping

.k-grouping-row .k-icon
{
    margin: -3px 4px 0 2px;
}

.k-grouping-row p
{
    display: inline-block;
    vertical-align: middle;
    margin-left: -.6em;
    padding: 0 .6em;
}

.k-grouping-row + tr td
{
    border-top-width: 1px;
}

.k-grouping-row .k-group-cell,
.k-grouping-row+tr .k-group-cell
{
    border-top-width: 0;
    text-overflow: clip;
}

.k-grid .k-hierarchy-cell+td
{
    border-left-width: 0;
}

.k-grid .k-group-col,
.k-grid .k-hierarchy-col
{
    width: 27px;
}

.k-grouping-header
{
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.k-grouping-header
{
    line-height: 2;
    position: relative;
    white-space: normal;
}

.k-grouping-dropclue
{
    position: absolute;
    top: 3px;
    width: 6px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: -165px -148px;
}
.k-grouping-dropclue:before,
.k-grouping-dropclue:after {
    content: "";
    width: 0;
    height: 0;
    border: 3px solid;
    border-left-color: transparent;
    border-right-color: transparent;
    position: absolute;
    left: 0;
}
.k-grouping-dropclue:before {
    top: 0;
    border-bottom: 0;
}
.k-grouping-dropclue:after {
    bottom: 0;
    border-top: 0;
}

// Grouping indicator
.k-group-indicator {
    padding: .15em .15em .15em .4em;
    border-width: 1px;
    border-style: solid;
    line-height: 1.5em;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    vertical-align: top;

    // Workaround grouppable test
    .k-grouping-header & {
        margin: 0 3px;
    }

    .k-link,
    .k-button.k-flat {
        padding: 0;
        border-width: 0;
        display: inline-flex;
        align-items: center;
    }

    .k-link .k-icon {
        margin-right: 4px;
    }

    .k-button.k-flat {
        margin-left: 8px;
        padding: 0;
        width: auto;
        height: auto;
        opacity: .5;

        &::before,
        &::after {
            display: none;
        }

        &:hover {
            opacity: 1;
        }
    }
}

.k-no-flexbox .k-group-indicator {
    display: inline-block;
}
.k-no-flexbox .k-group-indicator .k-link,
.k-no-flexbox .k-group-indicator .k-button {
    display: inline-block;
    vertical-align: middle;
}

// Grid :: editing

.k-dirty-cell:before
{
    content:"\a0";
    display: inline-block;
    width: 0;
    float: left;
}

.k-dirty-cell {
    position: relative;

    &.k-edit-cell {
        position: static;
    }

    .k-dirty {
        margin: 0;
        top: 0;
        left: 0;
    }
}

.k-dirty
{
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 3px;
    border-color: #f00 transparent transparent #f00;
    margin: -0.45em 0 0 -0.6em;
    padding: 0;
    overflow: hidden;
    vertical-align: top;
}

.k-grouping-header {
    margin: 0;
    padding: @grid-header-padding;
    cursor: default;
}

.k-grid .k-edit-container
{
    padding: 0;
}

.k-grid .field-validation-error
{
    display: block;
}

.k-grid .input-validation-error
{
    border-style: ridge;
    border-color: #f00;
    background-color: #ffc0cb;
}

.k-grid-actions
{
    display: inline-block;
}

.k-grid .k-button {
    margin: .16em;
}

@grid-button-min-width: 64px;

.k-grid tbody .k-button
{
    min-width: @grid-button-min-width;
}

.k-grid tbody button.k-button
{
    min-width: (@grid-button-min-width + 14); // for all except IE8
}

.k-grid tbody .k-button.k-button-icon
{
    width: auto;
    min-width: 0;
}

.k-detail-row
{
    position: relative;
}

.k-grid .k-detail-cell
{
    overflow: visible;
}

.k-grid .k-edit-cell
{
    padding: 0 .3em;
    white-space: nowrap;
}

.k-edit-cell,
.k-grid-edit-row > td {
    > .k-textbox,
    > .k-widget:not(.k-switch),
    > .text-box {
        width: 100%;
    }
}

.k-edit-cell input[type="checkbox"]
{
    margin-left: .6em;
}


// Grid :: resizing

.k-grid-resize-indicator
{
    position: absolute;
    width: 2px;
    background-color: #aaa;
}

.k-grid-header .k-resize-handle,
.k-grid > .k-resize-handle
{
    position: absolute;
    height: 25px;
    cursor: col-resize;
    z-index: 2;
}

.k-marquee
{
    position: absolute;
    z-index: 100000; // above windows
}

.k-marquee-color,
.k-marquee-text
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.k-marquee-color
{
    opacity: .6;
}

.k-ie9 .k-column-menu
{
    width: 160px; // fix flicker on item hover
}

.k-column-menu
{
    min-width: 160px;
}

.k-column-menu .k-sprite
{
    margin-right:10px;
}

.k-column-menu > .k-menu
{
    border-width: 0;
}

.k-column-menu .k-calendar .k-link {
    white-space: normal;
}

.k-columns-item .k-group
{
    max-height: 200px;
    overflow: auto;
}

.k-filter-menu.k-popup,
.k-grid-filter-popup.k-popup {
    padding: @grid-column-menu-popup-padding-y @grid-column-menu-popup-padding-x;

    .k-filter-menu-container {
        width: 230px;
    }

    .k-multicheck-wrap {
        padding: 0 3px; //Checkbox focus shadow

        .k-item {
            padding: @grid-column-menu-list-item-padding-y 0;
        }

        .k-check-all-wrap {
            padding-bottom: (@grid-column-menu-list-item-padding-y * 2);
            border-bottom-width: 1px;
            border-bottom-style: solid;
        }
    }
}

.k-filter-menu {

    .k-filter-menu-container {
        box-sizing: border-box;
    }
    &.k-popup .k-filter-menu-container {
        padding: @grid-column-menu-filter-container-padding-y @grid-column-menu-filter-container-padding-x;
    }

    .k-searchbox {
        margin-bottom: 8px;
        width: 100%;
    }
}

.k-grid-columnmenu-popup {
    margin: 0;
    width: 230px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    font-size: @font-size;
    line-height: @line-height;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &.k-popup {
        padding: @grid-column-menu-popup-padding-y @grid-column-menu-popup-padding-x;
    }

    .k-filter-menu {
        padding: 0;
        border-radius: 0;

        .k-filter-menu-container {
            padding: @grid-column-menu-popup-padding-y @grid-column-menu-popup-padding-x;
        }

        .k-widget,
        .k-radio-list,
        .k-textbox {
            margin: (@spacer / 2) 0;
            width: 100%;
            display: block;
        }

        .k-widget.k-filter-and {
            width: 6em;
            margin: @spacer 0;
        }

        .k-widget.k-button-group {
            width: auto;
            margin: 0;
            display: inline-flex;
        }

        .k-actions,
        .k-action-buttons,
        .k-columnmenu-actions {
            margin-left: -@spacer;
            margin-right: -@spacer;
            margin-bottom: -@spacer;
            padding: 0 @spacer @spacer;

            .k-button {
                flex: 1 1 50%;
            }
        }

        .k-actions {
            &.k-actions-start,
            &.k-actions-center,
            &.k-actions-end {
                .k-button {
                    flex: 0 1 auto;
                }
            }
        }
    }

    .k-multicheck-wrap {
        max-height: 300px;
        overflow: auto;
        overflow-x: hidden;
        white-space: nowrap;
    }
    .k-filter-selected-items {
        font-weight: bold;
        line-height: normal;
        white-space: nowrap;
        margin: @spacer-y 0 (@spacer / 2);
    }
}

.k-column-list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.k-column-chooser-title,
.k-column-list-item {
    padding: @grid-column-menu-list-item-padding-y @grid-column-menu-list-item-padding-x;
}

.k-column-list-item {
    position: relative;
    display: block;
    margin: 0;
    cursor: pointer;
}

.k-columnmenu-item {
    padding: @grid-column-menu-item-padding-y @grid-column-menu-item-padding-x;
    outline: 0;
    cursor: pointer;

    > .k-icon {
        margin-right: @icon-spacing;
    }
}

.k-columnmenu-item-wrapper + .k-columnmenu-item-wrapper {
    border-top-width: 1px;
    border-top-style: solid;
}

.k-columnmenu-item-content {
    overflow: hidden;

    .k-filter-menu .k-filter-menu-container {
        padding: @grid-column-menu-filter-container-padding-y @grid-column-menu-filter-container-padding-x;
    }
}

.k-columnmenu-actions {
    padding: @grid-column-menu-item-padding-y @grid-column-menu-item-padding-x;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: flex-end;
    justify-content: flex-end;
    clear: both;

    .k-button + .k-button {
        margin-left: 8px;
        flex-shrink: 0;

        .k-rtl &,
        [dir="rtl"] & {
            margin-left: 0;
            margin-right: 8px;
        }
    }
}

.k-column-list-wrapper,
.k-columnmenu-item-content {

    .k-actions,
    .k-action-buttons,
    .k-columnmenu-actions {
        .k-button {
            flex: 1 1 50%;
        }
    }

    .k-actions {
        &.k-actions-start,
        &.k-actions-center,
        &.k-actions-end {
            .k-button {
                flex: 0 1 auto;
            }
        }
    }
}

.k-column-menu {
    .k-menu:not(.k-context-menu) {
        font-weight: 400;
    }

    .k-expander {
        border: 0;
        background: inherit;

        .k-columnmenu-item {
            display: flex;
            align-items: center;
        }
    }
}

[dir = "rtl"],
.k-rtl {
    .k-columnmenu-item  > .k-icon {
        margin-left: @icon-spacing;
        margin-right: 0;
    }
}

// Column menu Group Header
.k-column-menu-group-header {
    display: flex;
    flex: 0 0 auto;
    position: relative;
    user-select: none;
}

.k-column-menu-group-header-text {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-size: 12px;
    line-height: @line-height;
    font-weight: bold;
    text-transform: uppercase;
    flex: 1 1 auto;
}

// Remove Grid scrollbar during built-in export
.k-pdf-export-shadow .k-grid {
    float: left;
    width: auto !important;
}

// Remove scrollbar from Grid with virtual scrolling
.k-pdf-export-shadow .k-grid-content.k-auto-scrollable {
    padding-right: 0 !important;
}

// Remove all sizes and scrolling
.k-pdf-export-shadow .k-grid,
.k-pdf-export-shadow .k-grid-content,
.k-pdf-export-shadow .k-grid-content-locked {
    height: auto !important;
    overflow: visible;
}

.k-pdf-export-shadow .k-grid-header-locked + .k-grid-header-wrap,
.k-pdf-export-shadow .k-grid-content-locked + .k-grid-content,
.k-pdf-export-shadow .k-grid-footer-locked + .k-grid-footer-wrap {
    width: auto !important;
}

// Remove empty space reserved above the scrollbar
.k-pdf-export-shadow .k-grid-header,
.k-pdf-export-shadow .k-grid[data-role="grid"] .k-grid-footer {
    padding: 0 !important;
}

.k-loading-pdf-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}

.k-loading-pdf-mask .k-loading-color {
    opacity: 0.5;
}

.k-loading-pdf-mask .k-loading-pdf-progress {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.k-pdf-export {

    .k-loading-pdf-mask {
        display: none;
    }

    .k-grid-filter {
        text-decoration: none;
     }
}

// responsive styles

.k-autofitting
{
    width: auto !important;
    table-layout: auto !important
}

.k-autofitting th.k-header,
.k-autofitting td
{
    white-space: nowrap !important;
}

.k-autofitting .k-detail-row
{
    display: none !important;
}

.k-grid .k-grouping-row {
    td {
        border-bottom-width: 0;
    }

    & + tr td {
        border-top-width: 1px;
    }

    & + tr td.k-group-cell {
        border-top-width: 0;
    }
}

.k-grid .k-group-cell {
    border-bottom-width: 0;
    border-top-width: 0;
}

.k-grid-header {
    .k-i-sort-asc-sm,
    .k-i-sort-desc-sm {
        margin-left: 7px;
    }

    .k-sort-order {
        display: inline-block;
        height: 16px;
        line-height: 16px;
        margin-top: 1px;
        margin-left: -1px;
        vertical-align: text-top;
        font-size: 12px;
    }

    .k-link .k-icon {
        &.k-i-sort-asc-sm,
        &.k-i-sort-desc-sm {
            margin-top: 1px;
            vertical-align: text-top;
        }
    }
}


// Grid search panel styles
.k-grid .k-grid-search {
    width: @grid-search-width;
    .flexbox();
}

// Sticky columns
.k-grid {

    .k-grid-header .k-header.k-grid-header-sticky,
    .k-grid-header .k-filter-row .k-grid-header-sticky,
    .k-grid-content-sticky,
    .k-grid-footer-sticky {
        position: sticky;
        z-index: 2;

        &.k-edit-cell {
            overflow: visible;
            z-index: 3;
        }
    }

    .k-hierarchy-cell + td.k-grid-content-sticky {
        border-left-width: 1px;
    }

    th.k-grid-header-sticky,
    td.k-grid-content-sticky,
    td.k-grid-footer-sticky {
        border-right-width: 1px;

        &:not([style*='display: none']) + td,
        &:not([style*='display: none']) + th {
            border-left-width: 0;
        }
    }

    .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
        border-left-width: 0;
    }

    &[dir = "rtl"],
    .k-rtl & {
        td.k-grid-content-sticky,
        td.k-grid-footer-sticky,
        .k-grid-header th.k-grid-header-sticky,
        th.k-grid-header-sticky:last-child {
            border-left-width: 1px;
        }

        th.k-grid-header-sticky:not([style*='display: none']) + td,
        th.k-grid-header-sticky:not([style*='display: none']) + th,
        td.k-grid-content-sticky:not([style*='display: none']) + td,
        td.k-grid-content-sticky:not([style*='display: none']) + th {
            border-left-width: 1px;
            border-right-width: 0;
        }

        .k-grid-header-sticky.k-header.k-first,
        .k-grid-header-sticky.k-header:first-child {
            border-left-width: 1px;
        }

        .k-grid-header-sticky.k-header.k-grid-no-left-border.k-first {
            border-left-width: 1px;
            border-right-width: 0;
        }
    }
}
