/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// ImageBrowser

.k-toolbar-wrap {
    float: left;
}

.k-toolbar-wrap .k-dropzone em,
.k-toolbar-wrap .k-upload-files
{
    display: none;
}

.k-toolbar-wrap .k-dropzone
{
    border: 0;
    padding: 0;
}

.k-toolbar-wrap .k-dropzone-active
{
    text-align: center;
}

.k-toolbar-wrap .k-dropzone-active em
{
    display: inline;
    margin:0;
    font-size: 5em;
    font-style: normal;
}

.k-toolbar-wrap .k-dropzone-active .k-upload-button
{
    display: none;
}

.k-filebrowser-dropzone
{
    z-index: 10010;
    opacity: .4;
    position: fixed;
}

.k-search-wrap {
    padding: 0;
    width: 20%;
    position: relative;
}

.k-search-wrap label
{
    position: absolute;
    top: 0;
    left: 4px;
    line-height: 20px;
    font-style: italic;
}

.k-search-wrap input.k-input
{
    padding-left: 0;
    padding-right: 0;
}

.k-search-wrap .k-search
{
    position: absolute;
    top: 50%;
    right: 2px;
    margin:0;
    margin-top: -8px;
}

.k-filebrowser {

    .k-filebrowser-toolbar {
        border-style: solid;
        border-width: 1px;
        margin: 8px 0 0;
        padding: .25em;
        line-height: 23px;
        white-space: nowrap; // required by WebKit
    }

    .k-tiles-arrange
    {
        float: right;

        .k-dropdown
        {
            width: 5.3em;
        }
    }

    .k-upload {
        border-width: 0;
        background-color: transparent;

        .k-upload-status,
        .k-upload-files {
            display: none;
        }

        .k-upload-button {
            margin: 0;
            width: auto;
            min-width: auto;
        }
    }

    .k-breadcrumb {
        position: relative;
        float: left;
        width: 79%;
        margin: 0;
    }
}

.k-filebrowser .k-filemanager-listview {
    height: 390px;
    max-height: 50vh;
    margin-bottom: 2em;
}

.k-filebrowser .k-filemanager-listview .k-listview-content {
    height: 100%;
}

.k-filebrowser .k-form-text-row
{
    text-align: right;
}

.k-filebrowser .k-form-text-row label
{
    width: 14%;
}

.k-filebrowser .k-form-text-row input
{
    width: 80%;
}
