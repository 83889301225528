/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-rating {
    &.k-widget {
        background-color: transparent;
    }

    &:focus,
    &.k-state-focused {
        box-shadow: none;

        .k-rating-item {
            > .k-icon {
                text-shadow: @rating-icon-focused-shadow;
            }

            &.k-state-selected > .k-icon {
                text-shadow: @rating-icon-focused-selected-shadow;
            }
        }
    }
}

.k-rating-item {
    color: @rating-icon-text;

    &.k-state-selected {
        color: @rating-icon-selected-text;
        background: none;
        box-shadow: none;

        &:focus,
        &.k-state-focused {
            color: @rating-icon-focused-text;
        }
    }

    &:hover,
    &.k-state-hover {
        color: @rating-icon-hover-text;
        cursor: pointer;
        background: none;
        box-shadow: none;

        &.k-state-selected {
            box-shadow: none;
        }
    }

    &:focus,
    &.k-state-focused {
        background: none;
        box-shadow: none;

        &.k-state-selected {
            box-shadow: none;
        }
    }
}
