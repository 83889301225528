/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Basic layout
.k-hbox,
.k-hstack {
    display: flex;
    flex-flow: row nowrap;
}

.k-vbox,
.k-vstack {
    display: flex;
    flex-flow: column nowrap;
}

.k-flex,
.k-spacer {
    flex: 1 0 0%;
}


// Stack layout
.k-stack-layout {}


// Flex layout
.k-flex-layout {
    display: flex;
}


// Grid layout
.k-grid-layout {
    display: grid;
}


// Separator
.k-separator {
    width: auto;
    height: 0;
    border-width: 1px 0 0;
    border-style: solid;
    border-color: inherit;
    display: block;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    align-self: stretch;
}
.k-separator-horizontal,
.k-vstack > .k-separator {
    width: auto;
    height: 0;
    border-width: 1px 0 0;
}
.k-separator-vertical,
.k-hstack > .k-separator {
    width: 0;
    height: auto;
    border-width: 0 0 0 1px;
}

hr.k-separator {
    margin: 0;
    padding: 0;
}
