/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Chat
.k-chat {
    border-color: @chat-border;
    color: @chat-text;
    background-color: @chat-bg;


    // Message meta
    .k-timestamp {
        text-transform: uppercase;
        opacity: .7;
    }
    .k-author {
        font-weight: bold;
    }


    // Bubbles
    .k-bubble {
        // .box-shadow( 0 1px 2px rgba( 0, 0, 0, .08) );
        border-color: @chat-bubble-border;
        color: @chat-bubble-text;
        background-color: @chat-bubble-bg;
        transition: box-shadow .2s ease-in-out;
        order: -1;
    }
    .k-bubble:hover {
        // .box-shadow( 0 1px 2px rgba( 0, 0, 0, .16) );
    }
    .k-state-selected .k-bubble {
        // .box-shadow( 0 3px 10px rgba( 0, 0, 0, .16) );
    }

    .k-alt .k-bubble {
        // .box-shadow( 0 1px 2px rgba( ~"@{chat-alt-bubble-bg}", .2 ) );
        border-color: @chat-alt-bubble-border;
        color: @chat-alt-bubble-text;
        background-color: @chat-alt-bubble-bg;
    }
    .k-alt .k-bubble:hover {
        // .box-shadow( 0 1px 2px rgba( ~"@{chat-alt-bubble-bg}", .2 ) );
    }
    .k-alt .k-state-selected .k-bubble {
        // .box-shadow( 0 3px 10px rgba( ~"@{chat-alt-bubble-bg}", .4 ) );
    }


    // Quick replies
    .k-quick-reply {
        border-color: @chat-quick-reply-color;
        color: @chat-quick-reply-color;
        background-color: transparent;
    }
    .k-quick-reply:hover {
        border-color: @chat-quick-reply-color;
        color: contrast(@chat-quick-reply-color);
        background-color:@chat-quick-reply-color;
    }


    // Message box
    .k-message-box {
        border-color: inherit;
        color: @input-text;
        background-color: @input-bg;
    }
    .k-message-box .k-button:hover {
        color: @accent;
    }


    // Toolbar
    .k-toolbar-box {
        border-color: inherit;
        color: @toolbar-text;
        background-color: @toolbar-bg;

        .k-button:hover {
            color: @accent;
            background: none;
        }

        .k-scroll-button {
            background-color: @chat-bg;
            box-shadow: 0 0 20px 5px @chat-bg;
        }
        .k-scroll-button:hover {
            background-color: @chat-bg;
        }
    }

}
