/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-listgroup {
    // .border-radius( @listgroup-border-radius );
    margin: 0;
    padding: 0;
    border-width: @listgroup-border-width;
    border-style: solid;
    // font-size: @listgroup-font-size;
    // line-height: @listgroup-line-height;
    font: inherit;
    line-height: (20 / 14);
    list-style: none;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
    }

    // Borders of items
    .k-listgroup-item + .k-listgroup-item {
        border-top-width: @listgroup-item-border-width;
    }
}
.k-listgroup-flush {
    border-left-width: 0;
    border-right-width: 0;
}


.k-listgroup-item {
    padding: @listgroup-item-padding-y @listgroup-item-padding-x;
    border-width: 0;
    border-style: solid;
    border-color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 auto;
    position: relative;

    > .k-link {
        margin: (-@listgroup-item-padding-y) (-@listgroup-item-padding-x);
        padding: @listgroup-item-padding-y @listgroup-item-padding-x;
        color: inherit;
        text-decoration: none;
        outline: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1 1 auto;
        position: relative;

        > .k-select {
            padding: @listgroup-item-padding-y;
            display: flex;
            align-items: center;
            flex: 0 0 auto;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
}
.k-listgroup-item-segmented {
    align-items: stretch;

    > .k-link {
        margin-right: 0;
    }

    > .k-select {
        margin: (-@listgroup-item-padding-y) (-@listgroup-item-padding-x) (-@listgroup-item-padding-y) 0;
        padding: @listgroup-item-padding-y;
        border-width: 0 0 0 1px;
        border-style: solid;
        border-color: inherit;
        cursor: pointer;
    }
}


// Forms in listgroup
.k-listgroup-form-row {
    margin: (-@listgroup-item-padding-y) (-@listgroup-item-padding-x);
    padding: @listgroup-item-padding-y @listgroup-item-padding-x;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    position: relative;

    .k-listgroup-form-field-label {
        width: 40%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .k-listgroup-form-field-wrapper {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        text-align: end;
        position: relative;

        select,
        input[type="text"],
        input[type="password"],
        input[type="date"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="number"] {
            width: 100%;
            box-sizing: border-box;
            flex: 1 1 100%;
        }
    }
}
.k-listgroup-item.k-listgroup-form-row {
    margin: 0;
}




.k-rtl,
[dir="rtl"] {

    .k-listgroup-item {

        > .k-link {

            > .k-select {
                right: auto;
                left: 0;

                .k-icon {
                    transform: scaleX(-1);
                }
            }
        }
    }
    .k-listgroup-item-segmented {

        > .k-link {
            margin-right: -@listgroup-item-padding-x;
            margin-left: 0;
        }

        > .k-select {
            margin-right: 0;
            margin-left: -@listgroup-item-padding-x;
            border-right-width: 1px;
            border-left-width: 0;

            .k-icon {
                transform: scaleX(-1);
            }
        }
    }
}




.k-noflexbox {
    .k-listgroup,
    .k-listgroup > ul
    .k-listgroup-item,
    .k-listgroup-item > .k-link {
        display: block;
    }
}
