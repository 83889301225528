/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-expander {
    border-width: @expander-border-width;
    border-style: solid;
    box-sizing: border-box;
    overflow: hidden;
    outline: 0;
    font-family: @expander-font-family;
    font-size: @expander-font-size;
    line-height: @expander-line-height;
    display: flex;
    background-clip: padding-box;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
    background-clip: padding-box;

    .k-rtl &,
    &.k-rtl,
    &[dir = "rtl"] {
        // Expand / collapse icon
        .k-expander-indicator {
            margin-left: 0;
            margin-right: @expander-indicator-margin-x;
        }
    }
}

// Header
.k-expander-header {
    padding: @expander-header-padding-y @expander-header-padding-x;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

// Expander spacer
.k-expander-spacer {
    flex: 1 1 auto;
}

// Title
.k-expander-title {
    font-weight: 500;
    text-transform: uppercase;
}

// Expand / collapse icon
.k-expander-indicator {
    margin-left: @expander-indicator-margin-x;
}

// Expander content
.k-expander-content {
    padding: @expander-content-padding-y @expander-content-padding-x;
    box-sizing: border-box;
}

// Multiple expanders
.k-expander + .k-expander.k-expanded,
.k-expander.k-expanded + .k-expander {
    margin-top: @expander-spacing-y;
}

.k-expander:not(.k-expanded) + .k-expander:not(.k-expanded) {
    border-top-width: 0;
}
