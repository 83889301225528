/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Popover
.k-popover {
    border-radius: @popover-border-radius;
    border-width: @popover-border-width;
    border-style: @popover-border-style;
    box-sizing: border-box;
    outline: 0;
    font-size: @popover-font-size;
    font-family: @popover-font-family;
    line-height: @popover-line-height;
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    z-index: 12000;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Header
.k-popover-header {
    border-top-left-radius: @popover-border-radius;
    border-top-right-radius: @popover-border-radius;
    padding: @popover-header-padding-y @popover-header-padding-x;
    border-width: 0 0 @popover-header-border-width;
    border-style: @popover-header-border-style;
    z-index: 1;
}

// Body
.k-popover-body {
    padding: @popover-body-padding-y @popover-body-padding-x;
    z-index: 1;
}

// Actions
.k-popover-actions.k-actions {
    margin: 0;
    padding: @actions-padding-y @actions-padding-x;
    border-width: @popover-actions-border-width 0 0;
}

// Callout
.k-popover-callout {
    margin: 0;
    width: @popover-callout-width;
    height: @popover-callout-height;
    border-width: @popover-callout-border-width @popover-callout-border-width 0 0;
    border-style: @popover-callout-border-style;
    position: absolute;

    &.k-callout-n {
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &.k-callout-e {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%) rotate(135deg) scaleX(-1);
    }

    &.k-callout-s {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(135deg);
    }

    &.k-callout-w {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%) rotate(-135deg);
    }
}

