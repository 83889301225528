/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Planned metrics

.k-gantt-planned .k-gantt-rows tr,
.k-gantt-planned .k-gantt-tasks tr,
.k-gantt.k-gantt-planned .k-grid-content tr {
    height: @gantt-planned-row-height;
}

.k-gantt-planned {
    .k-gantt-dependencies {
        margin-top: @gantt-planned-dependency-margin-y;
    }

    .k-task-dot {
        margin-top: @gantt-planned-dot-margin-y;
    }

    .k-resources-wrap {
        margin-top: @gantt-planned-resource-margin-y;
    }

    .k-milestone-wrap {
        .k-task-dot {
            margin-top: @gantt-planned-dot-summary-milestone-margin-y;
        }

        .k-task-dot.k-task-start {
            margin-left: @gantt-planned-dot-start-milestone-margin-x;
        }
    }

    .k-summary-wrap .k-task-dot {
        margin-top: @gantt-planned-dot-summary-milestone-margin-y;
    }

}

// Planned element

.k-task-planned {
    margin: 0 0 @gantt-planned-margin-y;
    line-height: @gantt-planned-line-height;
    display: flex;
    align-items: center;

    .k-task-moment {
        border-width: @gantt-planned-border-width;
        border-radius: @gantt-planned-moment-border-radius;
        border-style: solid;
        height: @gantt-planned-moment-height;
        width: @gantt-planned-moment-width;

        &.k-moment-left {
            margin-left: @gantt-planned-moment-margin-x;
        }
    }

    .k-task-duration {
        height: @gantt-planned-duration-height;
    }

    &:hover .k-task-duration {
        height: @gantt-planned-duration-hover-height;
    }
}

.k-milestone-wrap {
    .k-task-planned {
        margin-bottom: @gantt-planned-milestone-margin-y;
    }
}

.k-summary-wrap {
    .k-task-planned {
        margin-bottom: @gantt-planned-summary-margin-y;
    }
}

// Planned Tooltip

.k-planned-tooltip .k-task-content {
    display: block;
}

// Delay offset element

.k-task-offset-wrap {

    .k-task {
        display: inline-block;

        .k-task-content .k-resize-e {
            display: none;
        }
    }

    .k-task-single + .k-task-offset {
        height: @gantt-offset-height;
    }
}

.k-task-offset {
    display: inline-flex;
    flex-direction: row-reverse;
    line-height: @gantt-task-offset-line-height;
    vertical-align: top;

    .k-resize-handle {
        margin-right: 1.5em;
        top: 0;
        bottom: 0;
        width: .4em;
        position: absolute;
        visibility: hidden;
        z-index: 2;
        height: auto;
    }

    .k-resize-handle::after {
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -.55em;
        height: 1.4em;
        width: 1px;
    }
}

.k-summary-wrap .k-task-offset {
    height: 10px;
    margin: 3px 0 0;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px;
        right: 1.4em;
    }
}

.k-rtl  {
    .k-summary-wrap .k-task-offset::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px;
    }

    .k-task-offset-wrap .k-task .k-task-content {
        .k-resize-e {
            display: block;
        }

        .k-resize-w {
            display: none;
        }
    }

    .k-milestone-wrap {
        margin-left: -1.8em;
    }

    .k-task-milestone {
        margin-right: -.15em;
    }
}

.k-ie {
    .k-summary-wrap .k-task-offset {
        &:after {
            right: 20px;
        }
    }

    .k-rtl .k-summary-wrap .k-task-offset:before {
        left: 20px;
    }
}

.k-task-offset .k-task-actions {
    visibility: hidden;
}

.k-task-single:hover + .k-task-offset .k-task-actions,
.k-task-offset:hover .k-task-actions,
.k-task-single:hover + .k-task-offset .k-resize-handle,
.k-task-offset:hover .k-resize-handle {
    visibility: visible;
}

