/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@utils-order: {
    first: -9999;
    last: 9999;
    none: 0;
    1: 1;
    2: 2;
    3: 3;
    4: 4;
    5: 5;
    6: 6;
    7: 7;
    8: 8;
    9: 9;
    10: 10;
    11: 11;
    12: 12
}

each( @utils-order, {
    .k-order-@{key} { order: @value !important; }
});
