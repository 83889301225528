/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Actionsheet
.k-actionsheet {
    .fill(
        @actionsheet-text,
        @actionsheet-bg,
        @actionsheet-border
    );
    // .box-shadow( @actionsheet-shadow );
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, .2), 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12);
}


// Actionsheet header
.k-actionsheet-header {
    .fill(
        @actionsheet-header-text,
        @actionsheet-header-bg,
        @actionsheet-header-border,
        @actionsheet-header-gradient
    );
    box-shadow: @actionsheet-header-shadow;
}


// Actionsheet items
.k-actionsheet-items {}


// Actionsheet item
.k-actionsheet-item {}


// Actionsheet action
.k-actionsheet-action {


    // Hover state
    &:hover,
    &.k-state-hover {
        .fill(
            @actionsheet-item-hover-text,
            @actionsheet-item-hover-bg,
            @actionsheet-item-hover-border,
            @actionsheet-item-hover-gradient
        );
        .box-shadow( @actionsheet-item-hover-shadow );
    }


    // Focus state
    &:focus,
    &.k-state-focus {
        .fill(
            @actionsheet-item-focus-text,
            @actionsheet-item-focus-bg,
            @actionsheet-item-focus-border,
            @actionsheet-item-focus-gradient
        );
        .box-shadow( @actionsheet-item-focus-shadow );
    }


    // Disabed state
    &:disabled,
    &.k-state-disabled {
        .fill(
            @actionsheet-item-disabled-text,
            @actionsheet-item-disabled-bg,
            @actionsheet-item-disabled-border,
            @actionsheet-item-disabled-gradient
        );
        .box-shadow( @actionsheet-item-disabled-shadow );
    }
}
