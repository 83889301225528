/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@utils-text-align: left, right, center, justify;
@utils-text-transform: lowercase, uppercase, capitalize;
@utils-font-weight-light: 300;
@utils-font-weight-normal: 400;
@utils-font-weight-bold: 700;

// Wrapping
.k-text-nowrap                  { white-space: nowrap !important; }
.k-text-ellipsis                { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

// Align
each( @utils-text-align, {
    .k-text-@{value} { text-align: @value !important; }
});

// Transform
each( @utils-text-transform, {
    .k-text-@{value} { text-transform: @value !important; }
});

// Named font-weight
.k-font-weight-light            { font-weight: @utils-font-weight-light !important; }
.k-font-weight-normal           { font-weight: @utils-font-weight-normal !important; }
.k-font-weight-bold             { font-weight: @utils-font-weight-bold !important; }
