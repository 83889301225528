/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../mobile/common/forms.less";
@import "base.less";

// Forms

.km-list input[type=password],
.km-list input[type=search],
.km-list input[type=number],
.km-list input[type=tel],
.km-list input[type=url],
.km-list input[type=email],
.km-list input[type=month],
.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list input[type=text]:not(.k-input),
.km-list select:not([multiple]),
.km-list .k-dropdown-wrap,
.km-list textarea
{
    .appearance(none);
    font-size: 1.1rem;
    min-width: 6em;
    border: 0;
    padding: .4em;
    outline: none;
    background: transparent;
}

.km-list .k-dropdown-wrap
{
    padding: .2em;
}

.km-list .k-dropdown
{
    margin-top: -1.05em;
    font-weight: normal;
}

.km-list input[type=color],
.km-list input[type=week],
.km-list input[type=date],
.km-list input[type=time],
.km-list input[type=month],
.km-list input[type=datetime],
.km-list input[type=datetime-local],
.km-list .k-dropdown
{
    text-align: left;
}

.km-list .k-dropdown .k-dropdown-wrap
{
    display: block;
    border-radius: 0;
    background: transparent;
    .box-shadow(none);
}

// Checkboxes and Radios

.km-listview-label input[type=checkbox]
{
    margin-top: -.7em;
}

.km-listview-label input[type=radio],
.km-listview-label input[type=checkbox]
{
    border-width: @mobile-border-width;
    border-style: solid;
    width: 1.4em;
    height: 1.4em;
}

.km-listview-label input[type=radio]
{
    width: 1.2em;
    height: 1.2em;
    border-radius: 1em;
}

.km-listview-label input[type=checkbox]:after
{
    content: "\a0";
    display: block;
    width: 90%;
    height: 76%;
    .transform(scale(.9,1));
    .transform-origin(10% 50%);
}

.km-listview-label input[type=checkbox]:checked:after
{
    font-size: 1.4em;
}

.km-listview-label input[type=radio]:after
{
    color: transparent;
}
