/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-theme-test-class,
.ktb-theme-id-@{theme-name} {
    opacity: 0;
}

// serialize original values
.ktb-var-accent              { color: @accent; }
.ktb-var-base                { color: @base; }
.ktb-var-background          { color: @background; }

.ktb-var-border-radius       { border-radius: @border-radius; }

.ktb-var-normal-background   { color: @normal-background; }
.ktb-var-normal-gradient     { .composite-background(@normal-gradient); }
.ktb-var-normal-text-color   { color: @normal-text-color; }

.ktb-var-hover-background    { color: @hover-background; }
.ktb-var-hover-gradient      { .composite-background(@hover-gradient); }
.ktb-var-hover-text-color    { color: @hover-text-color; }

.ktb-var-selected-background { color: @selected-background; }
.ktb-var-selected-gradient   { .composite-background(@selected-gradient); }
.ktb-var-selected-text-color { color: @selected-text-color; }

.ktb-var-is-dark-theme       { opacity: if( @is-dark-theme, 1, 0); }

.ktb-var-primary             { color: @primary; }
.ktb-var-secondary           { color: @secondary; }
.ktb-var-tertiary            { color: @tertiary; }
.ktb-var-error               { color: @error; }
.ktb-var-warning             { color: @warning; }
.ktb-var-success             { color: @success; }
.ktb-var-info                { color: @info; }
.ktb-var-dark                { color: @dark; }
.ktb-var-light               { color: @light; }
.ktb-var-inverse             { color: @inverse; }

.ktb-var-series-a            { color: @series-a; }
.ktb-var-series-b            { color: @series-b; }
.ktb-var-series-c            { color: @series-c; }
.ktb-var-series-d            { color: @series-d; }
.ktb-var-series-e            { color: @series-e; }
.ktb-var-series-f            { color: @series-f; }

.k-grid-norecords-template {
    background-color: @widget-background-color;
    border: 1px solid @widget-border-color;
}
