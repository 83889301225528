/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Popover
.k-widget.k-popover {
    .box-shadow( @popover-shadow );

    .fill(
        @popover-text,
        @popover-bg,
        @popover-border
    );
}

// Header
.k-popover-header {
    .fill(
        @popover-header-text,
        @popover-header-bg,
        @popover-header-border
    );
}

// Body
.k-popover-body {
    .fill( @bg: inherit );
}

// Callout
.k-popover-callout {
    .fill(
        @bg: @popover-callout-bg,
        @border: @popover-callout-border
    );

    &.k-callout-n { .box-shadow( @popover-callout-shadow-n ); }
    &.k-callout-e { .box-shadow( @popover-callout-shadow-e ); }
    &.k-callout-s { .box-shadow( @popover-callout-shadow-s ); }
    &.k-callout-w { .box-shadow( @popover-callout-shadow-w ); }
}
