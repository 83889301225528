/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../mobile/common/icons.less";
@import "../../mobile/common/buttongroup.less";
@import "base.less";

// ButtonGroup

.km-buttongroup
{
    vertical-align: middle;
    background: transparent;
    padding: 0;
    margin: 0;
}

.km-buttongroup > *
{
    padding: .36em .8em;
}

.km-buttongroup > *:not(:first-child):not(:last-child)
{
    border-radius: 0;
}

.km-widget.km-buttongroup > *:last-child,
.km-widget.km-buttongroup > *.km-state-active
{
    border-right-width: @mobile-border-width;
}

.km-widget.km-buttongroup > *.km-state-active + *
{
    border-left-width: 0;
}
