/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Window
.k-window {
    .border-radius(@window-border-radius);
    .fill(
        @window-text,
        @window-bg,
        @window-border
    );
    box-shadow: @window-shadow;
}
.k-window.k-state-focused {
    border-color: @window-border;
    box-shadow: @window-focus-shadow;
}


// Titlebar
.k-window-titlebar {
    .border-top-radius(@window-border-radius);
    .fill(
        @window-titlebar-text,
        @window-titlebar-bg,
        @window-titlebar-border,
        @window-titlebar-gradient
    );
}
.k-window-title {}


// Actions
.k-window-actions {}
.k-window-action {
    .border-radius(@window-action-border-radius);
}


// Content
.k-window-content {
    .border-bottom-radius(@border-radius);
}
.k-window-titleless .k-window-content {
    .border-radius(@border-radius);
}
