/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// TaskBoard
.k-taskboard {
    .fill(
        @taskboard-text,
        @taskboard-bg,
        @taskboard-border
    );
}

// Toolbar
.k-taskboard-toolbar {
    .fill(
        @taskboard-toolbar-text,
        @taskboard-toolbar-bg,
        @taskboard-toolbar-border
    );
}

// Columns
.k-taskboard-column {
    .fill(
        @taskboard-column-text,
        @taskboard-column-bg,
        @taskboard-column-border
    );
}
.k-taskboard-column.k-state-focus,
.k-taskboard-column:focus  {
    .fill(
        @taskboard-column-focus-text,
        @taskboard-column-focus-bg,
        @taskboard-column-focus-border
    );
}

.k-taskboard-column-header-text {
    .fill( @text: @taskboard-column-header-text );
}

// Preview/Edit Pane
.k-taskboard-pane {
    .fill (
        @taskboard-pane-text,
        @taskboard-pane-bg,
        @taskboard-pane-border
    )
}

// Cards
.k-taskboard-card {
    .fill(
        @taskboard-card-text,
        @taskboard-card-bg,
        @taskboard-card-border
    );

    .k-card-header {
        .fill(
            @taskboard-card-header-text,
            @taskboard-card-header-bg,
            @taskboard-card-header-border
        );
    }

    .k-card-footer {
        .fill(
            @taskboard-card-footer-text,
            @taskboard-card-footer-bg,
            @taskboard-card-footer-border
        );
    }

    .k-card-title {
        .fill( @text: @taskboard-card-title-text );

        &:focus,
        &.k-state-focus {
            .fill( @text: @taskboard-card-title-focus-text );
        }

        &:hover,
        &.k-state-hover {
            .fill( @text: @taskboard-card-title-hover-text );
        }
    }

    &:focus,
    &.k-state-focus {
        .box-shadow( none );
        .fill( @border: @taskboard-card-focus-border );
    }

    &:hover,
    &.k-state-hover {
        .fill( @border: @taskboard-card-hover-border );
    }

    &.k-state-selected {
        .fill(
            @taskboard-card-selected-text,
            @taskboard-card-selected-bg,
            @taskboard-card-selected-border
        );
        background-image: none;
    }

}

// Card Drag Placeholder
.k-taskboard-drag-placeholder {
    .fill(
        @bg: @taskboard-drag-placeholder-bg,
        @border: @taskboard-drag-placeholder-border
    );
}


