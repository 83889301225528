/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// ModalView

.km-modalview-root
{
    text-align: center;
}

.km-modalview-root > .k-animation-container
{
    text-align: left;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    display: inline-block !important;
    vertical-align: middle;
}

.km-modalview,
.km-modalview-wrapper:before
{
    overflow: hidden;
    position: relative;
    .flexbox(inline);
    width: 100%;
    height: 100%;
    vertical-align: middle;
    max-height: 100%;
}

.km-modalview .km-content
{
    box-flex: 1;
}

.km-auto-height .km-content
{
    -ms-flex: auto;
}

.km-native-scrolling .km-view.km-modalview {
    display: -webkit-inline-flex;
    display: inline-flex;
}

.km-modalview-root:before,
.km-modalview-wrapper:before
{
    vertical-align: middle;
    height: 100%;
    margin-left: -1px;
    content: "\a0";
    width: 0px;
    display: inline-block;
}
