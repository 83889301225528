/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// responsive panel
.translateX(@offset) {
    -webkit-transform: translateX(@offset) translateZ(0);
    -ms-transform: translateX(@offset) translateZ(0);
    transform: translateX(@offset) translateZ(0);
}

.k-rpanel-left {
    .translateX(-100%);
    left: 0;
}

.k-rpanel-right {
    .translateX(100%);
    right: 0;
}

.k-rpanel-left,
.k-rpanel-right {
    position: fixed;
    display: block;
    overflow: auto;
    min-width: 320px;
    height: 100%;
    top: 0;

    &.k-rpanel-expanded {
        .translateX(0);
    }

    & + * {
        overflow: auto;
    }
}

.k-ie9 {
    .k-rpanel-left {
        left: -100%;

        &.k-rpanel-expanded {
            left: 0;
        }
    }
}

.k-rpanel-top {
    position: static;
    max-height: 0;

    &.k-rpanel-expanded {
        max-height: 568px;
        overflow: visible !important;
    }
}

.k-rpanel-toggle {
    touch-action: manipulation;
}
