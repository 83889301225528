/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Image Editor
.k-imageeditor {
    .fill(
        @imageeditor-text,
        @imageeditor-bg,
        @imageeditor-border
    );
}

// Content
.k-imageeditor-content {
    .fill(
        @imageeditor-content-text,
        @imageeditor-content-bg,
        @imageeditor-content-border
    );
}

// Canvas
.k-imageeditor-canvas {
    .checkerboard-gradient()
}

// Action Pane
.k-imageeditor-action-pane {
    .fill(
        @imageeditor-action-pane-text,
        @imageeditor-action-pane-bg,
        @imageeditor-action-pane-border
    );
}

// Crop Tool
.k-imageeditor-crop {
    .fill(
        @imageeditor-crop-text,
        @imageeditor-crop-bg,
        @imageeditor-crop-border
    );
}

// Crop Overlay
.k-imageeditor-crop-overlay {
    .fill(
        @bg: @imageeditor-crop-overlay-bg
    );
}

// Resize Handle
.k-imageeditor-crop .k-resize-handle {
    .fill(
        @imageeditor-resize-handle-text,
        @imageeditor-resize-handle-bg,
        @imageeditor-resize-handle-border
    );
}
