/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Menu

.k-menu
{
    cursor: default;
}

.k-menu .k-link {
    white-space: nowrap;
}
.k-menu .k-item > .k-link {
    color: inherit;
}

.k-menu,
.k-menu-scroll-wrapper .k-menu-group,
.k-popups-wrapper .k-menu-group,
.k-menu .k-menu-group
{
    list-style: none;
    margin: 0;
    padding: 0;
    zoom: 1;
}

.k-menu:after // clear fix to expand content
{
    content: '';
    display: block;
    width: 99%;
    height: 0;
    float: inherit;
    clear: both;
}

.k-menu-scroll-wrapper .k-item,
.k-popups-wrapper .k-item,
.k-menu .k-item
{
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    user-select: none;
}

.k-popups-wrapper .k-item .k-item,
.k-popups-wrapper.vertical > .k-item,
.k-menu-scroll-wrapper .k-item .k-item,
.k-menu-scroll-wrapper.vertical > .k-item,
.k-menu .k-item .k-item,
ul.k-menu-vertical > .k-item
{
    display: block;
    float: none;
    border-width: 0;
}

.k-menu-scroll-wrapper .k-item > .k-link > .k-icon,
.k-menu-scroll-wrapper .k-image,
.k-menu-scroll-wrapper .k-sprite,
.k-popups-wrapper .k-item > .k-link > .k-icon,
.k-popups-wrapper .k-image,
.k-popups-wrapper .k-sprite,
.k-menu .k-item > .k-link > .k-icon,
.k-menu .k-image,
.k-menu .k-sprite
{
    margin: -2px 4px 0 -4px;
    vertical-align: middle;
}

.k-menu-scroll-wrapper .k-item > .k-link,
.k-popups-wrapper .k-item > .k-link,
.k-menu .k-item > .k-link
{
    display: block;
    padding: @menu-link-padding;
    line-height: 1.34em;
    .prohibit-selection();
}

.k-menu-vertical > .k-menu-item > .k-menu-link {
    padding-right: @menu-vertical-link-padding;
}

.k-menu-scroll-wrapper .k-menu-group,
.k-popups-wrapper .k-menu-group,
.k-menu .k-menu-group
{
    display: none;
    border-style: solid;
    border-width: 1px;
    overflow: visible;
    white-space: nowrap;
}

.k-menu-scroll-wrapper .k-menu-group > .k-item,
.k-popups-wrapper .k-menu-group > .k-item,
.k-menu .k-menu-group > .k-item
{
    display: block;
    border-width: 0;
}

.k-menu-scroll-wrapper .k-item,
.k-menu-scroll-wrapper.horizontal > .k-item,
.k-popups-wrapper .k-item,
.k-popups-wrapper.horizontal > .k-item,
.k-menu .k-item,
.k-widget.k-menu-horizontal > .k-item
{
    position: relative;
    float: left;
    border-style: solid;
    border-width: 0 1px 0 0;
    vertical-align: top;
    zoom: 1;
    .box-sizing(content-box);
}

.k-column-menu-group-header,
.k-menu-scroll-wrapper .k-menu-group .k-item > .k-link,
.k-popups-wrapper.vertical > .k-item > .k-link,
.k-context-menu.k-menu-vertical > .k-item > .k-link,
.k-menu .k-menu-group .k-item > .k-link {
    padding: .28em 1.8em .38em .9em;
}

.k-popups-wrapper.horizontal > .k-separator,
.k-context-menu.k-menu-horizontal > .k-separator
{
    display: none;
}

.k-popups-wrapper.horizontal > .k-item,
.k-context-menu.k-menu-horizontal > .k-item
{
    .box-sizing(border-box);
}

.k-popups-wrapper.horizontal > .k-last,
.k-context-menu.k-menu-horizontal > .k-last
{
    border: 0;
}

.k-popups-wrapper .k-item > .k-link > .k-menu-expand-arrow,
.k-menu-horizontal > .k-item > .k-link > .k-menu-expand-arrow {
    margin: -2px 0 0;
}

.k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-down,
.k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-down,
.k-menu .k-item > .k-link > .k-i-arrow-60-down
{
    margin-right: -8px;
}

.k-menu-scroll-wrapper .k-item > .k-link > .k-i-arrow-60-right,
.k-popups-wrapper .k-item > .k-link > .k-i-arrow-60-right,
.k-menu-group .k-item > .k-link > .k-menu-expand-arrow,
.k-menu-vertical .k-item > .k-link > .k-menu-expand-arrow {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: .2rem;
}

.k-menu-scroll-wrapper .k-animation-container,
.k-popups-wrapper .k-animation-container,
.k-menu .k-animation-container
{
    border: 0;
}

.k-menu-scroll-wrapper .k-animation-container,
.k-menu-scroll-wrapper .k-menu-group,
.k-popups-wrapper .k-animation-container,
.k-popups-wrapper .k-menu-group,
.k-menu .k-animation-container,
.k-menu .k-menu-group
{
    position: absolute;
    left: 0;
}

.k-menu-scroll-wrapper .k-animation-container .k-animation-container,
.k-menu-scroll-wrapper .k-menu-group .k-menu-group,
.k-menu-scroll-wrapper.vertical .k-animation-container,
.k-menu-scroll-wrapper.vertical .k-menu-group,
.k-popups-wrapper .k-animation-container .k-animation-container,
.k-popups-wrapper .k-menu-group .k-menu-group,
.k-popups-wrapper.vertical .k-animation-container,
.k-popups-wrapper.vertical .k-menu-group,
.k-menu .k-animation-container .k-animation-container,
.k-menu .k-menu-group .k-menu-group,
.k-menu-vertical .k-animation-container,
.k-menu-vertical .k-menu-group
{
    top: 0;
    left: 0;
}

.k-menu-scroll-wrapper .k-animation-container .k-menu-group,
.k-popups-wrapper .k-animation-container .k-menu-group,
.k-menu .k-animation-container .k-menu-group
{
    top: auto;
    left: auto;
    margin-left: -1px;
}

.k-menu .k-animation-container,
.k-menu-scroll-wrapper .k-animation-container,
.k-popups-wrapper .k-animation-container,
.k-popup .k-animation-container
{
    margin-top: -1px;
    padding-left: 1px;
}

.k-ie .k-menu .k-animation-container,
.k-ie .k-menu-scroll-wrapper .k-animation-container,
.k-ie .k-popups-wrapper .k-animation-container,
.k-ie .k-popup .k-animation-container
{
    margin-top: -2px;
}

.k-popup .k-animation-container .k-popup
{
    margin-left: -1px;
}

.k-menu-scroll-wrapper .k-separator,
.k-popups-wrapper .k-separator,
ul.k-menu .k-separator
{
    padding: 0.25em 0;
    height: 100%;
    width: 1px;
    font-size: 0;
    line-height: 0;
    border-width: 0 1px 0 0;
}

.k-menu-scroll-wrapper.vertical .k-separator,
.k-menu-scroll-wrapper.vertical .k-menu-group .k-separator,
.k-popups-wrapper.vertical .k-separator,
.k-popups-wrapper.vertical .k-menu-group .k-separator,
ul.k-menu-vertical .k-separator,
.k-menu .k-menu-group .k-separator
{
    padding: 0;
    height: 1px;
    width: 100%;
    border-width: 1px 0 0;
}

.k-popups-wrapper .k-widget.k-menu,
.k-menu-scroll-wrapper .k-widget.k-menu {
    white-space: nowrap;
    overflow: hidden;
}

.k-popups-wrapper.vertical .k-menu.k-menu-vertical,
.k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical {
    height: 100%;
    box-sizing: border-box;
}

.k-popups-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item,
.k-menu-scroll-wrapper.horizontal .k-menu.k-menu-horizontal > .k-item {
    display: inline-block;
    overflow: hidden;
    float: none;
}

.k-popups-wrapper.vertical .k-menu.k-menu-vertical > .k-item,
.k-menu-scroll-wrapper.vertical .k-menu.k-menu-vertical > .k-item {
    overflow: hidden;
}

.k-popups-wrapper,
.k-menu-scroll-wrapper {
    position: relative;
    border: 0;
    margin: 0;
    padding: 0;
}

.k-popups-wrapper.vertical,
.k-menu-scroll-wrapper.vertical {
    height: 100%;
}

.k-popups-wrapper > .k-animation-container .k-item,
.k-menu-scroll-wrapper > .k-animation-container .k-item {
    float: none;
}

.k-popups-wrapper,
.k-menu-scroll-wrapper {
    .k-menu-scroll-button {
        border-radius: 0;
        position: absolute;
        display: none;
    }

    .k-scroll-up {
        top: 0;
        left: 0;
    }

    .k-scroll-down {
        bottom: 0;
        left: 0;
    }

    .k-scroll-up,
    .k-scroll-down {
        width: 100%;
        height: 16px;
        padding: 0em 0.7em;
        line-height: 0.2em;
    }

    .k-scroll-left {
        top: 0;
        left: 0;
    }

    .k-scroll-right {
        top: 0;
        right: 0;
    }

    .k-scroll-left,
    .k-scroll-right {
        width: 16px;
        height: 100%;
        padding-left: 0;
        padding-right: 0;

        .k-icon {
            margin-top: -.5em;
            position: absolute;
            top: 50%;
            left: 0;
        }
    }
}


.k-rtl,
[dir="rtl"] {
    .k-menu .k-item,
    .k-widget.k-menu-horizontal > .k-item {
        border-width: 0 0 0 1px;
    }

    .k-menu-scroll-wrapper,
    .k-popups-wrapper,
    .k-menu {
        .k-item > .k-link > .k-icon,
        .k-image,
        .k-sprite {
            margin-right: -4px;
            margin-left: 4px;
        }

        .k-menu-group > .k-item {
            border: 0;
        }
    }

    .k-column-menu-group-header,
    .k-menu-scroll-wrapper .k-menu-group .k-item > .k-link,
    .k-popups-wrapper.vertical > .k-item > .k-link,
    .k-context-menu.k-menu-vertical > .k-item > .k-link,
    .k-menu .k-menu-group .k-item > .k-link {
        padding: .28em .9em .38em 1.8em;
    }

    .k-menu-vertical > .k-menu-item > .k-menu-link {
        padding: @menu-link-padding;
        padding-left: @menu-vertical-link-padding;
    }
}
// Context Menu

.k-context-menu
{
    border: 0;
    user-select: none;
}
