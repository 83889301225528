/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.km-badge,
.km-detail
{
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-align: center;
    position: absolute;
    z-index: 1;
    height: 2em;
    font-size: .6rem;
    text-shadow: none;
}

.km-badge
{
    top: -1em;
    right: -1em;
    line-height: 2em;
    margin-left: .5em;
    min-width: .9em;
    padding: 0 .55em;
    -webkit-background-clip: padding-box;
    .background-clip(padding-box);
}

.km-tabstrip .km-badge
{
    top: -.2em;
    right: auto;
    margin-left: -1em;
}

// DetailButtons
.km-detail
{
    position: absolute;
    float: right;
    right: .8rem;
    top: 50%;
    margin-top: -.7rem;
    width: 1.3rem;
    height: 1.3rem;
    font-size: 1rem;
    .box-sizing(border-box);
}

.km-widget .km-detaildisclose
{
    font-size: .8em;
}

.k-ff .km-detail
{
    width: 1rem;
    height: 1rem;
}

.km-detail .km-text {
    display: none;
}

.km-widget .km-rowinsert:after,
.km-widget .km-rowdelete:after,
.km-widget .km-contactadd:after,
.km-widget .km-detaildisclose:after,
.km-widget .km-rowinsert:before,
.km-widget .km-rowdelete:before,
.km-widget .km-contactadd:before,
.km-widget .km-detaildisclose:before,
.km-detail .km-icon
{
    left: .15em;
    top: .15em;
    line-height: 1em;
    font-size: 1em;
    position: absolute;
}

.km-widget .km-detaildisclose:after
{
    left: .1em;
    top: .25em;
    text-align: center;
    font-weight: bold;
}

// Buttons
.km-button
{
    cursor: pointer;
    outline: 0;
    text-align: center;
}

button.km-button
{
    display: inline-block;
    font: inherit;
}

.km-button:hover
{
    text-decoration: none;
}

.km-button::-moz-focus-inner
{
    padding: 0;
    border: 0;
}

.km-ios .km-state-disabled .km-button,
.km-android .km-state-disabled .km-button,
.km-blackberry .km-state-disabled .km-button,
.km-meego .km-state-disabled .km-button
{
    color: #aaa;
    text-shadow: none;
}

.km-root .km-pane .k-button:focus,
.km-root .km-pane .k-button:active,
.km-root .km-pane .k-button:focus:active
{
    .box-shadow(none);
}
