/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-imageeditor {
    margin: @imageeditor-margin-y @imageeditor-margin-x;
    padding: @imageeditor-padding-y @imageeditor-padding-x;
    border-width: @imageeditor-border-width;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: @imageeditor-font-family;
    font-size: @imageeditor-font-size;
    line-height: @imageeditor-line-height;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
}

// Content
.k-imageeditor-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

// Canvas
.k-imageeditor-canvas-container {
    flex: 1 1 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
}

.k-imageeditor-canvas {
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
}

// Action Pane
.k-imageeditor-action-pane {
    border-width: @imageeditor-content-border-width;
    border-top-width: 0;
    border-style: solid;
    flex-shrink: 0;
    overflow-y: auto;
}
.k-imageeditor-action-pane > .k-form {
    padding: 0 @imageeditor-action-pane-padding-x @imageeditor-action-pane-padding-y @imageeditor-action-pane-padding-x;
}

// Crop Tool
.k-imageeditor-crop-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.k-imageeditor-crop {
    width: 100%;
    height: 100%;
    border-width: @imageeditor-crop-border-width;
    border-style: @imageeditor-crop-border-style;
    box-sizing: border-box;
    position: absolute;

    .k-resize-handle {
        margin: @imageeditor-resize-handle-margin-y @imageeditor-resize-handle-margin-x;
        border-style: solid;
        border-width: @imageeditor-resize-handle-border-width;
    }

    .k-resize-sw,
    .k-resize-se,
    .k-resize-nw,
    .k-resize-ne {
        width: @imageeditor-resize-handle-size;
        height: @imageeditor-resize-handle-size;
    }

    .k-resize-n,
    .k-resize-s {
        left: calc( 50% - (@imageeditor-resize-handle-size / 2) );
        width: @imageeditor-resize-handle-size;
        height: 0;
    }

    .k-resize-w,
    .k-resize-e {
        top: calc( 50% - (@imageeditor-resize-handle-size / 2) );
        width: 0;
        height: @imageeditor-resize-handle-size;
    }

    .k-resize-ne {
        transform: rotate(-90deg);
    }

    .k-resize-nw {
        transform: rotate(-180deg);
    }


    .k-resize-sw {
        transform: rotate(90deg);
    }

    .k-resize-n,
    .k-resize-nw,
    .k-resize-ne {
        top: 0;
    }

    .k-resize-s,
    .k-resize-sw,
    .k-resize-se {
        bottom: 0;
    }

    .k-resize-w,
    .k-resize-nw,
    .k-resize-sw {
        left: 0;
    }

    .k-resize-e,
    .k-resize-ne,
    .k-resize-se {
        right: 0;
    }
}

// IE
.k-ie {
    .k-imageeditor-canvas {
        flex-direction: row;
        align-self: flex-start;
    }

    .k-imageeditor-action-pane {
        min-width: 200px;
        flex: 0 0 auto;
    }
}
