/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-filemanager {

    .fill(
        @filemanager-text,
        @filemanager-bg,
        @filemanager-border
    );

}

.k-filemanager-toolbar {

    .fill(
        @filemanager-toolbar-text,
        @filemanager-toolbar-bg,
        @filemanager-toolbar-border
    );

}

.k-filemanager-navigation {

    .fill(
        @filemanager-navigation-text,
        @filemanager-navigation-bg,
        @filemanager-navigation-border
    );

}

.k-filemanager-breadcrumb {

    .fill(
        @filemanager-breadcrumb-text,
        @filemanager-breadcrumb-bg,
        @filemanager-breadcrumb-border
    );

}

.k-filemanager .k-dropzone-hovered {

    .fill(
        @filemanager-view-dropzone-hovered-text,
        @filemanager-view-dropzone-hovered-bg,
        @filemanager-view-dropzone-hovered-border
    );

}

.k-filemanager-listview {

    .fill(
        @filemanager-listview-text,
        @filemanager-listview-bg,
        @filemanager-listview-border
    );

    .k-item {
        .fill(
            @filemanager-listview-item-text,
            @filemanager-listview-item-bg,
            @filemanager-listview-item-border
        );


        &.k-state-selected .k-icon {
            .fill(
                @filemanager-listview-item-icon-selected-text,
                @filemanager-listview-item-icon-selected-bg,
                @filemanager-listview-item-icon-selected-border
            );
        }

        .k-icon {
            .fill(
                @filemanager-listview-item-icon-text,
                @filemanager-listview-item-icon-bg,
                @filemanager-listview-item-icon-border
            );
        }
    }

}

.k-filemanager-grid {

    .fill(
        @filemanager-grid-text,
        @filemanager-grid-bg,
        @filemanager-grid-border
    );

}

.k-filemanager-preview {

    .fill(
        @filemanager-preview-text,
        @filemanager-preview-bg,
        @filemanager-preview-border
    );

    .k-file-preview-wrapper .k-icon {
        .fill(
            @filemanager-preview-icon-text,
            @filemanager-preview-icon-bg,
            @filemanager-preview-icon-border
        );
    }

    .k-file-size {
        color: inherit;
    }

}

// Drag Hint
.k-filemanager-drag-hint {

    .fill(
        @filemanager-drag-hint-text,
        @filemanager-drag-hint-bg,
        @filemanager-drag-hint-border
    );

    .k-icon {
        .fill(
            @filemanager-drag-hint-icon-text,
            @filemanager-drag-hint-icon-bg,
            @filemanager-drag-hint-icon-border
        );
    }

}
