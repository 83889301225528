/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-expander {
    .fill(
        @expander-text,
        @expander-bg,
        @expander-border
    );
}

.k-expander-header {
    .fill(
        @expander-header-text,
        @expander-header-bg,
        @expander-header-border
    );
    outline: none;

    &:hover,
    &.k-state-hover {
        .fill(
            @bg: @expander-header-hover-bg
        );
    }

    &:focus,
    &.k-state-focused {
        .fill(
            @bg: @expander-header-focused-bg
        );
        .box-shadow(@expander-header-focused-shadow);
    }
}

.k-expander-title {
    color: @expander-title-text;
}


.k-expander-sub-title {
    opacity: @expander-header-sub-title-opacity;
}
