/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-pane-wrapper .k-pane {

    > .k-view > .k-content {
        .composite-background(@widget-gradient);
        background-position: 50% 50%;
        background-color: @adaptive-content-bg;
    }

    .k-grid-mobile {

        .k-column-active + th.k-header {
            border-left-color: @widget-text-color;
        }

        .k-sorted {

            .k-sort-order,
            .k-i-sort-asc-sm,
            .k-i-sort-desc-sm {
                color: @adaptive-grid-sort-text;
            }
        }

        .k-grid-filter.k-state-active,
        .k-header-column-menu.k-state-active {
            color: @background;
            background-color: @adaptive-content-text;
        }

        .k-resize-handle-inner::before {
            background-color: @background;
        }
    }

    .k-scheduler-mobile {

        .k-nav-current {
            color: @adaptive-scheduler-current-text;
        }

        .k-scheduler-times,
        .k-scheduler-header,
        .k-scheduler-groupcolumn,
        .k-mobile-scheduler-agendadate {
            color: @adaptive-scheduler-base-text;
        }

        .k-time-period,
        .k-mobile-scheduler-agendaweekday {
            color: lighten(@normal-text-color, 55%);
        }

        .k-scheduler-times,
        .k-scheduler-header,
        .k-scheduler-header-wrap,
        .k-scheduler-datecolumn,
        .k-scheduler-groupcolumn,
        .k-scheduler-group-cell,
        .k-scheduler-header-all-day {
            background-color: @adaptive-content-bg;
        }

        .k-other-month {
            background-color: @scheduler-nonwork-background-color;
        }
    }

    .k-grid-edit-form,
    .k-grid-column-menu,
    .k-grid-filter-menu,
    .k-scheduler-edit-form {

        .k-header {
            background-color: @adaptive-menu-bg;

            &,
            .k-link {
                color: @adaptive-menu-text;
            }
        }

        .k-item,
        .k-link {
            color: @widget-text-color;
        }

        .k-clear,
        .k-select-all,
        .k-scheduler-delete,
        .k-scheduler-resetSeries {
            color: @adaptive-menu-clear-text;
        }
    }

    .k-column-menu {

        .k-listgroup-item.k-state-selected {
            color: @adaptive-grid-sort-text;
            background: none;
        }
        .k-listgroup-item.k-state-selected .k-link {
            color: inherit;
        }
    }

}
