/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.k-notification {
    border-radius: @notification-border-radius;
}
.k-notification when (@theme-name = default) {
    .linear-gradient( @notification-gradient );
}
.k-notification-info {
    background-color: @notification-info-bg;
    color: @notification-info-text;
    border-color: @notification-info-border;
}
.k-notification-success {
    background-color: @notification-success-bg;
    color: @notification-success-text;
    border-color: @notification-success-border;
}
.k-notification-warning {
    background-color: @notification-warning-bg;
    color: @notification-warning-text;
    border-color: @notification-warning-border;
}
.k-notification-error {
    background-color: @notification-error-bg;
    color: @notification-error-text;
    border-color: @notification-error-border;
}
