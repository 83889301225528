/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
// Searchbox
.k-searchbox {
    .border-radius( 4px );
    border-width: 1px;
    border-style: solid;
    font-family: @font-family;
    font-size: @font-size;
    line-height: @line-height;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    flex-flow: row nowrap;
    position: relative;
    overflow: hidden;
    background-clip: padding-box;

    > .k-input {
        padding: @textarea-padding-y  @textarea-padding-x;
    }
}
