/*!
 * Copyright 2021 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@utils-float: left, right, none;
@utils-clear: left, right, both, none;

each( @utils-float, {
    .k-float-@{value} { float: @value !important; }
});

each( @utils-clear, {
    .k-clear-@{value} { float: @value !important; }
});

// Float wrap
.k-floatwrap::after {
    content: "";
    display: block;
    clear: both;
}
